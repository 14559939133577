import {
   Avatar,
   Box,
   Button,
   Card,
   CardActions,
   CardContent,
   CircularProgress,
   Collapse,
   Divider,
   IconButton,
   ImageList,
   ImageListItem,
   Modal,
   Paper,
   Stack,
   Typography,
} from "@mui/material";
import ClearIcon from "@mui/icons-material/Clear";
import { useContext, useEffect, useState } from "react";
import { Users } from "../../../../types/BaseTypes";
import { getGoberningBodiesByUserId, getMessagesWithLink } from "../../../../lib/gobCorpBEClient";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import DescriptionIcon from "@mui/icons-material/Description";
import AttachmentIcon from "@mui/icons-material/Attachment";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { ChatContext } from "../../../../context/governanceContext/ChatContext";
import { VerifyChatFolderByName, getAllInFolderUrlS3GC } from "../../../../lib/usersBEClient";
import { Close } from "@mui/icons-material";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";
import TextSnippetIcon from "@mui/icons-material/TextSnippet";
import LinkIcon from "@mui/icons-material/Link";
import { IConversation } from "../../../../types/governance.types";
import { UserContext } from "../../../../context/userContext";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";
export interface IUserInformationDrawer {
   setOpenDrawer: Function;
   user: Users;
   conversation: IConversation;
   profilePic: string;
}

function stringAvatar(name: string) {
   return {
      sx: { color: "#152C44", bgcolor: "#F4F9FD", width: 90, height: 90 },
      children: (
         <Typography fontSize={35} fontWeight={700}>
            {`${name.split(" ")[0][0]}${name.split(" ")[1][0]}`}
         </Typography>
      ),
   };
}

function stringAvatarName(name: string, lastName: string) {
   return {
      sx: { color: "#152C44", bgcolor: "#F4F9FD", width: 90, height: 90 },
      children: (
         <Typography fontSize={35} fontWeight={700}>
            {`${name.slice(0, 1)}${lastName.slice(0, 1)}`}
         </Typography>
      ),
   };
}

const useGetGoverningBodys = (
   userId: string,
   openImages: boolean,
   openFiles: boolean,
   currPage,
   setIsLoadingImages,
   openLinks,
   messagesWithLink,
   setMessagesWithLink
) => {
   const [governingBodys, setGoverningBodys] = useState(null);
   const [isLoading, setIsLoading] = useState(false);
   const [filesUrls, setFilesUrls] = useState([]);
   const { currentChat } = useContext(ChatContext);
   const { user } = useContext(UserContext);
   const { mutate } = VerifyChatFolderByName();
   const [wasLastList, setWasLastList] = useState(false);
   const { companySelected } = useContext(GovernanceContext);

   useEffect(() => {
      const fetchGov = async () => {
         if (!userId) return;
         try {
            setIsLoading(true);
            const govBodysData = await getGoberningBodiesByUserId(userId, companySelected);
            setGoverningBodys(govBodysData);
            setIsLoading(false);
         } catch (error) {
            console.log(error);
            setIsLoading(false);
         }
      };
      if (userId) fetchGov();
   }, [userId]);

   useEffect(() => {
      const fetchImages = async () => {
         setIsLoading(true);
         mutate(
            {
               name: currentChat.title,
               userId: user.id,
            },
            {
               onError: async (error) => {
                  console.error(error);
               },
               onSuccess: async (data) => {
                  let allowedFileTypes;
                  if (openImages) allowedFileTypes = ["png", "jpg", "jpeg", "svg", "mp4"];
                  if (openFiles) allowedFileTypes = ["noImages"];
                  const files = await getAllInFolderUrlS3GC("files-lecosy", data._id, allowedFileTypes, currPage);
                  if (!files.length) {
                     setWasLastList(true);
                     setIsLoadingImages(false);
                  }
                  const contactedArray = filesUrls.concat(files);
                  setFilesUrls(contactedArray);
                  setIsLoadingImages(false);
               },
            }
         );
         setIsLoading(false);
      };
      if ((openImages || openFiles) && !wasLastList) fetchImages();
   }, [openImages, openFiles, currPage]);

   useEffect(() => {
      const fetchMessagesWithLink = async () => {
         const fetchMessagesWithLinks = await getMessagesWithLink(currentChat._id, currPage);
         if (!fetchMessagesWithLinks.length) {
            setWasLastList(true);
            setIsLoadingImages(false);
         }
         const contactedArray = messagesWithLink.concat(fetchMessagesWithLinks);
         setMessagesWithLink(contactedArray);
         setIsLoadingImages(false);
      };
      if (openLinks && !wasLastList) fetchMessagesWithLink();
   }, [openLinks, currPage]);

   return { governingBodys, isLoading, filesUrls, wasLastList, setWasLastList, setFilesUrls };
};
export const UserInformationDrawer = (props: IUserInformationDrawer) => {
   const [openImages, setOpenImages] = useState(false);
   const [openFiles, setOpenFiles] = useState(false);
   const [openLinks, setOpenLinks] = useState(false);
   const [openImageModal, setOpenImageModal] = useState(false);
   const [url, setUrl] = useState("");
   const [currPage, setCurrPage] = useState(1);
   const [prevPage, setPrevPage] = useState(0);
   const [isLoadingImages, setIsLoadingImages] = useState(false);
   const [messagesWithLink, setMessagesWithLink] = useState([]);
   const { governingBodys, isLoading, filesUrls, wasLastList, setWasLastList, setFilesUrls } = useGetGoverningBodys(
      props.user._id,
      openImages,
      openFiles,
      currPage,
      setIsLoadingImages,
      openLinks,
      messagesWithLink,
      setMessagesWithLink
   );

   const onScroll = (e) => {
      if (e.target) {
         const { scrollTop, scrollHeight, clientHeight } = e.target;
         if (scrollTop + clientHeight === scrollHeight) {
            if (!isLoadingImages) {
               setPrevPage(currPage);
               setCurrPage(currPage + 1);
            }
            if (!wasLastList) setIsLoadingImages(true);
         }
      }
   };

   const handleClick = (url) => {
      setUrl(url);
      if (openImages) setOpenImageModal(true);
      if (openFiles) window.open(url);
   };

   if (isLoading) return <CircularProgress />;
   return (
      <>
         <Stack sx={{ width: 400, height: "72vh", display: "flex" }} direction={"column"}>
            {openImages || openFiles || openLinks ? (
               <>
                  {openImages && (
                     <>
                        <Box pl={1} display={"flex"} alignContent={"center"}>
                           <IconButton
                              sx={{ bgcolor: "#F4F9FD", borderRadius: 2, mr: 1, mt: 1.5 }}
                              size="small"
                              onClick={() => {
                                 setFilesUrls([]);
                                 setOpenImages(false);
                                 setCurrPage(1);
                                 setPrevPage(currPage);
                                 setWasLastList(false);
                              }}
                           >
                              <ArrowBackIcon sx={{ color: "#152C44" }} />
                           </IconButton>
                           <Typography
                              fontSize={22}
                              fontWeight={700}
                              sx={{
                                 color: "#0A1629",
                                 px: 2,
                                 mt: 1.5,
                              }}
                           >
                              Fotos
                           </Typography>
                        </Box>
                        <Divider sx={{ my: 1 }} />
                        <ImageList
                           onScroll={onScroll}
                           sx={{
                              width: "100%",
                              height: "100%",
                              px: 1,
                              overflowY: "auto",
                           }}
                           cols={3}
                           rowHeight={121}
                        >
                           {filesUrls.map((image, index) => (
                              <ImageListItem
                                 key={index}
                                 sx={{
                                    border: 2,
                                    borderColor: "silver",
                                    borderRadius: 1,
                                 }}
                              >
                                 {image.fileType === "mp4" ? (
                                    <Box
                                       alignItems="center"
                                       justifyContent="center"
                                       display="flex"
                                       onClick={() => {
                                          handleClick(image.fileUrl);
                                       }}
                                    >
                                       <PlayCircleOutlineIcon sx={{ mt: "25%", fontSize: 60 }} />
                                    </Box>
                                 ) : (
                                    <img
                                       srcSet={image.fileUrl}
                                       src={`${image.fileUrl} w=164&h=164&fit=crop&auto=format`}
                                       alt={image.fileName}
                                       loading="lazy"
                                       onClick={() => {
                                          handleClick(image.fileUrl);
                                       }}
                                    />
                                 )}
                              </ImageListItem>
                           ))}
                           {isLoadingImages && (
                              <ImageListItem cols={3} sx={{ display: "flex", alignItems: "center", maxHeight: "50px" }}>
                                 <CircularProgress />
                              </ImageListItem>
                           )}
                        </ImageList>
                     </>
                  )}
                  {openFiles && (
                     <>
                        <Box pl={1} display={"flex"} alignContent={"center"}>
                           <IconButton
                              sx={{ bgcolor: "#F4F9FD", borderRadius: 2, mr: 1, mt: 1.5 }}
                              size="small"
                              onClick={() => {
                                 setFilesUrls([]);
                                 setOpenFiles(false);
                                 setCurrPage(1);
                                 setPrevPage(currPage);
                                 setWasLastList(false);
                              }}
                           >
                              <ArrowBackIcon sx={{ color: "#152C44" }} />
                           </IconButton>
                           <Typography
                              fontSize={22}
                              fontWeight={700}
                              sx={{
                                 color: "#0A1629",
                                 px: 2,
                                 mt: 1.5,
                              }}
                           >
                              Documentos
                           </Typography>
                        </Box>
                        <Divider sx={{ my: 1 }} />
                        <Stack spacing={2} paddingX={4} sx={{ overflowY: "auto" }} onScroll={onScroll}>
                           {filesUrls.map((file, index) => (
                              <Card
                                 sx={{
                                    display: "flex",
                                    bgcolor: "#F9F9F9",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    mt: 0.5,
                                    boxShadow: 0,
                                    border: 1,
                                    borderColor: "#E2E2E2",
                                    flexShrink: 0,
                                 }}
                                 key={index}
                              >
                                 <CardContent>
                                    <Typography sx={{ fontSize: 14, flexWrap: "nowrap" }}>{file.fileName}</Typography>
                                 </CardContent>
                                 <CardActions>
                                    <IconButton
                                       onClick={() => {
                                          handleClick(file.fileUrl);
                                       }}
                                    >
                                       <TextSnippetIcon />
                                    </IconButton>
                                 </CardActions>
                              </Card>
                           ))}
                           {isLoadingImages && (
                              <Box alignSelf={"center"} alignItems={"center"}>
                                 <CircularProgress />
                              </Box>
                           )}
                        </Stack>
                     </>
                  )}
                  {openLinks && (
                     <>
                        <Box pl={1} display={"flex"} alignContent={"center"}>
                           <IconButton
                              sx={{ bgcolor: "#F4F9FD", borderRadius: 2, mr: 1, mt: 1.5 }}
                              size="small"
                              onClick={() => {
                                 setOpenLinks(false);
                                 setCurrPage(1);
                                 setPrevPage(currPage);
                                 setWasLastList(false);
                              }}
                           >
                              <ArrowBackIcon sx={{ color: "#152C44" }} />
                           </IconButton>
                           <Typography
                              fontSize={22}
                              fontWeight={700}
                              sx={{
                                 color: "#0A1629",
                                 px: 2,
                                 mt: 1.5,
                              }}
                           >
                              Enlaces
                           </Typography>
                        </Box>
                        <Divider sx={{ my: 1, mb: 2 }} />
                        <Stack paddingX={3} sx={{ overflowY: "auto" }} onScroll={onScroll} spacing={1}>
                           {messagesWithLink.map((message, index) => (
                              <Paper
                                 variant="outlined"
                                 sx={{
                                    p: 1,
                                    backgroundColor: "#FAFAFA",
                                    borderRadius: 2,
                                 }}
                                 key={index}
                              >
                                 <Typography
                                    fontSize="14px"
                                    sx={{
                                       maxWidth: "100%",
                                       wordWrap: "break-word",
                                       overflowWrap: "break-word",
                                       wordBreak: "break-word",
                                    }}
                                 >
                                    {message.content}
                                 </Typography>
                                 <Button
                                    sx={{
                                       mt: 1,
                                       bgcolor: "#E6EDF5",
                                    }}
                                    onClick={() => {
                                       window.open(message.url);
                                    }}
                                 >
                                    <LinkIcon sx={{ mr: 1 }} />
                                    <Typography
                                       fontWeight={700}
                                       color={"#113460"}
                                       fontSize="16px"
                                       sx={{
                                          pr: 1,
                                       }}
                                    >
                                       {message.pageName}
                                    </Typography>
                                 </Button>
                              </Paper>
                           ))}
                           {isLoadingImages && <CircularProgress />}
                        </Stack>
                     </>
                  )}
               </>
            ) : (
               <>
                  <Stack
                     direction={"row"}
                     sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "last baseline",
                        flex: { xl: 0.67, lg: 1.06 },
                     }}
                  >
                     <Typography
                        fontSize={22}
                        fontWeight={700}
                        sx={{
                           color: "#0A1629",
                           px: 2,
                           display: "flex",
                           alignItems: "baseline",
                        }}
                     >
                        {props.conversation.type === "GROUP" ? "Información del grupo" : "Información del contacto"}
                     </Typography>
                     <IconButton
                        sx={{ bgcolor: "#F4F9FD", borderRadius: 2, alignSelf: "baseline", mr: 1, mt: 1 }}
                        size="small"
                        onClick={() => props.setOpenDrawer(false)}
                     >
                        <ClearIcon sx={{ color: "#152C44" }} />
                     </IconButton>
                  </Stack>
                  <Divider sx={{ my: 2 }} />
                  <Stack sx={{ overflowY: "auto", display: "flex", flex: 7 }}>
                     <Stack
                        sx={{
                           alignItems: "center",
                           display: "flex",
                           flexDirection: "column",
                           flex: 2,
                        }}
                     >
                        <Avatar
                           src={props.conversation.type === "GROUP" ? "" : props.profilePic}
                           {...(props.conversation.type === "GROUP"
                              ? stringAvatar(props.conversation.title)
                              : stringAvatarName(props.user.firstName, props.user.lastName))}
                        />
                        <Typography fontSize={20} fontWeight={600} sx={{ mt: 2 }}>
                           {props.conversation.type === "GROUP"
                              ? props.conversation.title
                              : props.user.firstName + " " + props.user.lastName}
                        </Typography>
                        {props.conversation.type !== "GROUP" && (
                           <>
                              <Typography fontSize={16} fontWeight={400} sx={{ mt: 2 }}>
                                 Correo electrónico:
                              </Typography>
                              <Typography fontSize={14}>{props.user.email}</Typography>
                           </>
                        )}
                     </Stack>
                     <Divider sx={{ my: 2 }} />
                     <Stack
                        direction={"row"}
                        sx={{
                           display: "flex",
                           justifyContent: "space-between",
                           px: 8,
                           flex: props.conversation.type === "GROUP" && 10,
                        }}
                     >
                        <IconButton
                           size="medium"
                           sx={{
                              backgroundColor: "#F7F9FB",
                              borderRadius: 2,
                              boxShadow: 2,
                              height: 40,
                           }}
                           onClick={() => {
                              setWasLastList(false);
                              setOpenImages(true);
                           }}
                        >
                           <InsertPhotoIcon sx={{ color: "#152C44" }} />
                        </IconButton>
                        <IconButton
                           size="medium"
                           sx={{
                              backgroundColor: "#F7F9FB",
                              borderRadius: 2,
                              boxShadow: 2,
                              height: 40,
                           }}
                           onClick={() => {
                              setWasLastList(false);
                              setOpenFiles(true);
                           }}
                        >
                           <DescriptionIcon sx={{ color: "#152C44" }} />
                        </IconButton>
                        <IconButton
                           size="medium"
                           sx={{
                              backgroundColor: "#F7F9FB",
                              borderRadius: 2,
                              boxShadow: 2,
                              height: 40,
                           }}
                           onClick={() => {
                              setWasLastList(false);
                              setOpenLinks(true);
                           }}
                        >
                           <AttachmentIcon sx={{ color: "#152C44" }} />
                        </IconButton>
                     </Stack>
                     <Divider sx={{ my: 2 }} />
                     {props.conversation.type !== "GROUP" && (
                        <>
                           <Stack
                              spacing={4}
                              sx={{ display: "flex", flex: 3, alignItems: "center", flexDirection: "column" }}
                           >
                              <Typography>Órganos de gobierno pertenecientes:</Typography>
                              <Stack spacing={1}>
                                 {governingBodys &&
                                    governingBodys.map((gov, index) => (
                                       <Box
                                          key={index}
                                          sx={{
                                             px: 6,
                                             py: 1,
                                             bgcolor: "#F7F9FB",
                                             border: "1px #CBD5E0 solid",
                                             borderRadius: 3,
                                          }}
                                       >
                                          <Typography fontSize={16}>
                                             {gov?.title !== "PANEL DE USUARIO"
                                                ? gov.title.charAt(0).toUpperCase() + gov.title.slice(1).toLowerCase()
                                                : null}
                                          </Typography>
                                       </Box>
                                    ))}
                              </Stack>
                           </Stack>
                        </>
                     )}
                  </Stack>
               </>
            )}
         </Stack>
         <Modal open={openImageModal && url !== ""}>
            <Box sx={{ overflow: "auto", maxHeight: "100vh" }}>
               <Collapse unmountOnExit in={openImageModal && url !== ""}>
                  <Box sx={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: 20, top: 0 }}>
                     <IconButton onClick={() => setOpenImageModal(false)}>
                        <Close sx={{ color: "white" }} />
                     </IconButton>
                  </Box>
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        my: 20,
                     }}
                  >
                     {/* <ExcelViewer excelLink={url} style={{ height: 500 }} />
                      */}
                     <Box sx={{ bgcolor: "white" }}>
                        <img srcSet={url} src={url} alt={"imagen"} loading="lazy" />
                     </Box>
                     {/* <iframe
                        src={url}
                        style={{
                           height: "auto",
                           width: "auto",
                        }}
                     ></iframe> */}
                  </Box>
               </Collapse>
            </Box>
         </Modal>
      </>
   );
};
