import { Avatar, Box, Button, Divider, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { options } from "./ShareFile";
import { KeyboardArrowDown } from "@mui/icons-material";
import { useContext, useState } from "react";
import {
   ChangeAccessShareFileById,
   ChangeAccessShareFolderById,
   RemoveShareFileById,
   RemoveShareFolderById,
   useGetResourceSelected,
} from "../../../../lib/usersBEClient";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { ConfirmationModal } from "../../../ConfirmationModal";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";

export const UserMap = ({ userData, owner, permission, fileId, setOpen, setFileDetails, folder }) => {
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const { showSnackBar } = useContext(SnackBarContext);
   const { companySelected } = useContext(GovernanceContext);
   const resourceId = useGetResourceSelected();
   const { mutate: removeAccess } = RemoveShareFileById(fileId, resourceId, companySelected);
   const { mutate: removeAccessFolder } = RemoveShareFolderById(fileId, resourceId, companySelected);
   const { mutate: changeAccess } = ChangeAccessShareFileById(fileId, resourceId, companySelected);
   const { mutate: changeAccessFolder } = ChangeAccessShareFolderById(fileId, resourceId, companySelected);
   const open = Boolean(anchorEl);
   const [openModal, setOpenModal] = useState(false);
   const [message, setMessage] = useState("");
   const [title, setTitle] = useState("");
   const [permissionData, setPermission] = useState(0);
   const [direct, setDirect] = useState(false);
   const [isLoading, setIsLoading] = useState(false);

   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleRemoveAccess = (userId: string) => {
      setIsLoading(true);
      if (folder) {
         removeAccessFolder(
            { user: userId },
            {
               onError: (error: any) => {
                  showSnackBar("Error al quitar el colaborador.", true);
               },
               onSuccess: () => {
                  showSnackBar("El colaborador fue quitado correctamente.", false);
                  setIsLoading(false);
                  setOpen(false);
               },
            }
         );
      } else {
         removeAccess(
            {
               user: userId,
            },
            {
               onError: (error: any) => {
                  showSnackBar("Error al quitar el colaborador.", true);
               },
               onSuccess: () => {
                  showSnackBar("El colaborador fue quitado correctamente.", false);
                  setIsLoading(false);
                  setOpen(false);
               },
            }
         );
      }
   };

   const handleChangeAccess = (userId: string) => {
      setIsLoading(true);

      if (folder) {
         changeAccessFolder(
            {
               user: userId,
               permission: permissionData,
            },
            {
               onError: (error: any) => {
                  showSnackBar("Error al modificar el colaborador.", true);
               },
               onSuccess: (data) => {
                  setFileDetails(data[0]);
                  showSnackBar("El colaborador fue modificado correctamente.", false);
                  setIsLoading(false);
                  setOpenModal(false);
                  handleClose();
               },
            }
         );
      } else {
         changeAccess(
            {
               user: userId,
               permission: permissionData,
            },
            {
               onError: (error: any) => {
                  showSnackBar("Error al modificar el colaborador.", true);
               },
               onSuccess: (data) => {
                  setFileDetails(data);
                  showSnackBar("El colaborador fue modificado correctamente.", false);
                  setIsLoading(false);
                  setOpenModal(false);
                  handleClose();
               },
            }
         );
      }
   };

   return (
      <Box sx={{ display: "flex", columnGap: 2, py: 1 }}>
         <Avatar
            sx={{
               fontSize: 16,
               bgcolor: "#162c44",
               //    bgcolor: `#` + Math.floor(Math.random() * 16777215).toString(16),
            }}
         >
            {userData.firstName.charAt(0)}
         </Avatar>
         <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%" }}>
            <Stack>
               <Box sx={{ display: "flex", columnGap: 1 }}>
                  <Typography variant="subtitle2" fontWeight={600}>
                     {userData.firstName}
                  </Typography>
                  <Typography variant="subtitle2" fontWeight={600}>
                     {userData.lastName}
                  </Typography>
               </Box>
               <Typography variant="subtitle2">{userData.email}</Typography>
            </Stack>
            {owner ? (
               <Typography variant="subtitle2">Propietario</Typography>
            ) : (
               <Button endIcon={<KeyboardArrowDown />} onClick={handleClick} sx={{ m: 0, px: 0 }}>
                  {options[permission - 1]}
               </Button>
            )}
         </Box>
         <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            anchorOrigin={{
               vertical: "top",
               horizontal: "left",
            }}
            transformOrigin={{
               vertical: "top",
               horizontal: "left",
            }}
         >
            <MenuItem
               onClick={() => {
                  setOpenModal(true);
                  setMessage("¿Está seguro que desea modificar el acceso al archivo?");
                  setTitle("Modificar colaborador de archivo");
                  setPermission(1);
                  setDirect(true);
               }}
               disabled={options[permission - 1] === "Consultor"}
               sx={options[permission - 1] === "Consultor" && { bgcolor: "whitesmoke" }}
            >
               Consultor
            </MenuItem>
            <MenuItem
               onClick={() => {
                  setOpenModal(true);
                  setMessage("¿Está seguro que desea modificar el acceso al archivo?");
                  setTitle("Modificar colaborador de archivo");
                  setPermission(2);
                  setDirect(true);
               }}
               disabled={options[permission - 1] === "Editor"}
               sx={options[permission - 1] === "Editor" && { bgcolor: "whitesmoke" }}
            >
               Editor
            </MenuItem>
            {/* <MenuItem
               onClick={() => {
                  setOpenModal(true);
                  setMessage("¿Está seguro que desea modificar el acceso al archivo?");
                  setTitle("Modificar colaborador de archivo");
                  setPermission(3);
                  setDirect(true);
               }}
               disabled={options[permission - 1] === "All Access"}
               sx={options[permission - 1] === "All Access" && { bgcolor: "whitesmoke" }}
            >
               All Access
            </MenuItem> */}
            <Divider />
            <MenuItem
               onClick={() => {
                  setOpenModal(true);
                  setMessage("¿Está seguro que desea quitar el acceso al archivo?");
                  setTitle("Quitar colaborador de archivo");
                  setDirect(false);
               }}
            >
               Quitar acceso
            </MenuItem>
         </Menu>
         <ConfirmationModal
            open={openModal}
            setOpen={setOpenModal}
            isLoading={isLoading}
            onConfirm={() => {
               direct ? handleChangeAccess(userData._id) : handleRemoveAccess(userData._id);
            }}
            body={
               <Box sx={{ pt: 2 }}>
                  <Typography align="center">{message}</Typography>
               </Box>
            }
            title={title}
         />
      </Box>
   );
};
