import * as React from "react";
import Box from "@mui/material/Box";
import Popper, { PopperPlacementType } from "@mui/material/Popper";
import Fade from "@mui/material/Fade";
import Paper from "@mui/material/Paper";

interface IPopperProps {
   children: React.ReactNode;
   placement: PopperPlacementType;
   anchorEl: HTMLElement | null;
   setAnchorEl: Function;
}

export default function PositionedPopper(props: IPopperProps) {
   const open = Boolean(props.anchorEl);

   return (
      <Box>
         <Popper open={open} anchorEl={props.anchorEl} placement={props.placement} transition>
            {({ TransitionProps }) => (
               <Fade {...TransitionProps} timeout={350}>
                  <Paper>{props.children}</Paper>
               </Fade>
            )}
         </Popper>
      </Box>
   );
}
