import { Box, Typography, Button, Modal, IconButton } from "@mui/material";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { getUrlS3, getUrlS3Token } from "../../../../lib/usersBEClient";
import Sample from "../../PDFVisualizer/PDFViewer";
import { useContext, useState } from "react";
import { Close, Download } from "@mui/icons-material";
import { GovernanceSessionContext } from "../../../../context/governanceContext/governanceSessionContext";

export const SessionFile = ({ file, session, token = null }) => {
   const { hasPermissions, isShareholder } = useContext(GovernanceSessionContext);
   const [fileUrl, setFileUrl] = useState(null);
   const [openFile, setOpenFile] = useState(false);

   const handleClick = async () => {
      let documentUrl = "";
      if (!token)
         documentUrl = await getUrlS3(
            "files-lecosy",
            { folder: `gc/companies/${session.company}/governing-body/${session._id}` },
            file.fileName
         );
      else
         documentUrl = await getUrlS3Token(
            "files-lecosy",
            { folder: `gc/companies/${session.company}/governing-body/${session._id}`, token: token },
            file.fileName
         );
      setFileUrl(documentUrl);
      setOpenFile(true);
   };

   const downloadFile = () => {
      fetch(fileUrl, {
         method: "GET",
         headers: {
            "Content-Type": "application/pdf",
         },
      })
         .then((response) => response.blob())
         .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", file.fileName.substring(file.fileName.indexOf("_") + 1) + `.` + "pdf");
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
         });
   };

   return (
      <>
         <Box
            sx={{
               display: "flex",
               borderBottom: 0.65,
               borderColor: "#E0E0E0",
               p: 2,
               maxWidth: 500,
               justifyContent: "space-between",
            }}
         >
            <Box sx={{ display: "flex", gap: 1, alignItems: "center", maxWidth: "70%" }}>
               <PictureAsPdfIcon sx={{ color: "#E81919", fontSize: 20 }} />
               <Typography noWrap sx={{ fontSize: { xs: 14, lg: 12 } }}>
                  {file.fileName.substring(file.fileName.indexOf("_") + 1)}
               </Typography>
            </Box>
            <Button
               variant="contained"
               onClick={() => {
                  handleClick();
               }}
               sx={{ bgcolor: "#486685", p: 0, borderRadius: 2.5 }}
            >
               <Typography sx={{ fontSize: { xs: 12, lg: 10 }, color: "#E8E8E8" }}>Abrir</Typography>
            </Button>
         </Box>
         <Modal open={openFile}>
            <Box sx={{ overflow: "auto", maxHeight: "100vh" }}>
               <Box
                  sx={{
                     display: "flex",
                     justifyContent: "flex-end",
                     position: "absolute",
                     right: 10,
                     top: 0,
                     zIndex: 500,
                  }}
               >
                  {!token && (isShareholder || hasPermissions) && (
                     <IconButton onClick={() => downloadFile()}>
                        <Download sx={{ color: "white", fontSize: "37px" }} />
                     </IconButton>
                  )}
                  <IconButton onClick={() => setOpenFile(false)}>
                     <Close sx={{ color: "white", fontSize: "37px" }} />
                  </IconButton>
               </Box>
               <Sample file={fileUrl} />
            </Box>
         </Modal>
      </>
   );
};
