import {
   Box,
   Button,
   CircularProgress,
   Divider,
   IconButton,
   List,
   ListItem,
   Modal,
   Stack,
   Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { UserContext } from "../../../../context/userContext";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";
import { useContext, useEffect, useMemo, useState } from "react";
import { getAllResources, getLogsByUserAndResource, getUserByUserId } from "../../../../lib/usersBEClient";
import { getDateFromISODate } from "../../../../const/globalConst";
import { CustomModalComponent } from "../../../CustomModalComponent";
import { getChargeById } from "../../../../lib/gobCorpBEClient";

interface LastAddedMemberModalProps {
   open: boolean;
   setOpen: Function;
   isLoading: boolean;
   users: { title: string; name: string; charge: string; createdAt: Date }[];
}

const LastAddedMemberModal = (props: LastAddedMemberModalProps) => {
   const { open, setOpen, isLoading, users } = props;

   const handleClose = () => setOpen(false);

   return (
      <CustomModalComponent open={open} setOpen={setOpen} title="Últimos miembros agregados">
         <Stack p={2} spacing={2}>
            <List>
               {!isLoading && users.length > 0 ? (
                  users.map((user, i) => (
                     <Box key={i}>
                        <ListItem sx={{ color: "#162c44" }}>
                           <Stack direction={"column"} width={"100%"}>
                              <Typography fontWeight={600}>Miembro: {user.name}</Typography>
                              <Typography variant="caption">Cargo: {user.charge}</Typography>
                              <Typography variant="caption">
                                 Fecha de modificación: {getDateFromISODate(user.createdAt)}
                              </Typography>
                           </Stack>
                        </ListItem>
                        <Divider />
                     </Box>
                  ))
               ) : isLoading ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                     <CircularProgress />
                  </Box>
               ) : (
                  <Typography textAlign={"center"}>No se han registrado nuevos miembros</Typography>
               )}
            </List>

            <Box display={"flex"} justifyContent={"flex-end"}>
               <Button
                  onClick={handleClose}
                  sx={{ bgcolor: "#162c44", color: "white", ":hover": { bgcolor: "#162c44" }, px: 5 }}
               >
                  <Typography>Cerrar</Typography>
               </Button>
            </Box>
         </Stack>
      </CustomModalComponent>
   );
};

export default LastAddedMemberModal;
