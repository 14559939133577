import { useRef, useContext } from "react";
import { SnackBarContext } from "../../context/snackBarContext";
import { IconButton, Box, Button, Typography } from "@mui/material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { LocaleDateString } from "../../const/globalConst";

interface InputFileProps {
   setFile: Function;
   file: {
      file: FileList;
      lastDateModified: string;
   };
   onChange?: Function;
   keyName?: string;
   numberOfFiles?: number;
}

export const InputFile = (props: InputFileProps) => {
   const inputRef: any = useRef<any>();
   const { showSnackBar } = useContext(SnackBarContext);
   const fileData = props.file?.file;

   const handleUploadFile = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files.length === 0) {
         inputRef.current.value = null;
         return showSnackBar("Favor de adjuntar un documento", true);
      }
      if (event.target.files[0].type !== "application/pdf") {
         inputRef.current.value = null;
         return showSnackBar("Archivo invalido", true);
      }
      if (event.target.files[0].size > 20000000) {
         inputRef.current.value = null;
         return showSnackBar("El archivo no puede pesar más de 20MB", true);
      }
      props.setFile({ file: event.target.files, lastDateModified: LocaleDateString(new Date()) });
      if (props.onChange) props.onChange({ file: event.target.files[0], fileType: "pdf", name: props.keyName });
   };
   return (
      <Button
         variant="outlined"
         component="span"
         sx={{ p: 1, ml: 1, maxHeight: "35px", maxWidth: "300px", borderColor: "#C4C4C4" }}
      >
         {!fileData ? (
            <input
               accept="application/pdf"
               id="policies-button-file"
               multiple={props.numberOfFiles === undefined || props.numberOfFiles > 1}
               ref={inputRef}
               type="file"
               onChange={(e) => {
                  handleUploadFile(e);
               }}
            />
         ) : (
            <>
               <Box
                  sx={{ ml: 1 }}
                  onClick={() => {
                     if (fileData[0]) {
                        window.open(URL.createObjectURL(fileData[0]), "_blank", "noopener,noreferrer");
                     }
                  }}
               >
                  <Typography fontSize={13}>
                     {fileData.length === 1
                        ? fileData[0].name.length > 30
                           ? `${fileData[0].name.substring(0, 25)}...pdf`
                           : `${fileData[0].name}`
                        : `Hay ${fileData.length} archivos seleccionados.`}
                  </Typography>
               </Box>
               <IconButton
                  size="small"
                  sx={{ ml: 1 }}
                  onClick={() => {
                     props.setFile(null);
                  }}
               >
                  <CloseRoundedIcon sx={{ color: "#162c44", fontSize: 15 }} />
               </IconButton>
            </>
         )}
      </Button>
   );
};
