import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { Box, CircularProgress } from "@mui/material";
import { useContext, useState } from "react";
import { ChatContext } from "../../../../context/governanceContext/ChatContext";
import { Contacts } from "./Contacts";
import { UserContext } from "../../../../context/userContext";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(
   ({ theme }) => ({
      border: "0px",
      "&:not(:last-child)": {
         borderBottom: 0,
      },
      "&:before": {
         display: "none",
      },
   })
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
   <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: 12, color: "#024F6C" }} />} {...props} />
))(({ theme }) => ({
   backgroundColor: "transparent",
   border: "0px solid 0px",
   flexDirection: "row-reverse",
   "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
   },
   "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
   },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
   padding: theme.spacing(2),
   borderTop: "0px solid rgba(0, 0, 0, .125)",
}));

export const CreateConversation = () => {
   const [expanded, setExpanded] = useState<string | false>("panel1");
   const { contacts, isUserChatsLoading, createChatNew, allUsers } = useContext(ChatContext);
   const { user } = useContext(UserContext);
   const { companySelected } = useContext(GovernanceContext);

   const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
   };
   if (isUserChatsLoading) return <CircularProgress />;
   return (
      <Box>
         <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
               <Typography color={"#024F6C"} fontSize={14} fontWeight={600}>
                  Contactos
               </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{ p: "0 !important", minHeight: "54vh", maxHeight: "54vh", overflowY: "auto" }}>
               {contacts &&
                  contacts
                     .filter((c) => c._id !== user.id)
                     ?.map((u) => (
                        <Box
                           key={u._id}
                           sx={{
                              "&:hover": { cursor: "pointer" },
                           }}
                           onClick={() => {
                              createChatNew(u, user, contacts, allUsers, u._id, companySelected);
                           }}
                        >
                           <Contacts user={u} />
                        </Box>
                     ))}
            </AccordionDetails>
         </Accordion>
      </Box>
   );
};
