import {
   ArrowDropDown,
   ArrowDropUp,
   Logout,
   Notifications,
   PermIdentity,
   Settings,
   SupportAgent,
} from "@mui/icons-material";
import {
   AppBar,
   Avatar,
   Badge,
   Box,
   Divider,
   IconButton,
   ListItemIcon,
   Menu,
   MenuItem,
   Stack,
   Toolbar,
   Tooltip,
   Typography,
} from "@mui/material";
import { logo_Isotipo_Blanco, logo_Lecosy } from "../../assets/icons/DrawerAppScreenIcons";
import { useNavigate } from "react-router-dom";
import { useContext, useState } from "react";
import { SupportContext } from "../../context/supportContext";
import { ConfirmationModal } from "../ConfirmationModal";
import { ComplaintContext } from "../../context/complaintContext";
import { UserContext } from "../../context/userContext";
import { removeHtmlTags } from "../../const/globalConst";

export const AppBarComponent = () => {
   const {
      user,
      isCompanyAdmin,
      logoutUser,
      companies,
      setPath,
      userProfilePicture,
      notifications,
      socket,
      appBarLogo,
      isImplementationUser,
      setCompanySelected,
   } = useContext(UserContext);

   const { logout: logoutComplaint } = useContext(ComplaintContext);
   const { setSupportOpen, supportOpen } = useContext(SupportContext);
   const [logoutModalOpen, setLogoutModalOpen] = useState(false);
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [anchorEl2, setAnchorEl2] = useState<null | HTMLElement>(null);
   const [loadingLogout, setisLoadingLogout] = useState(false);

   const openMenu = Boolean(anchorEl);
   const openMenu2 = Boolean(anchorEl2);
   const navigate = useNavigate();

   const handleLogOutModalOpen = () => setLogoutModalOpen(true);
   const handleClose = () => {
      setAnchorEl2(null);
      setAnchorEl(null);
   };
   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };
   const handleClickNotification = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl2(event.currentTarget);
   };
   const onLogoutButtonPress = async () => {
      setisLoadingLogout(true);
      await logoutUser();
      logoutComplaint();
      setLogoutModalOpen(false);
      navigate("/login");
      setisLoadingLogout(false);
   };

   const handleMiLecosy = () => {
      const sameCompanies = (c1: string[], c2: string[]): boolean =>
         c1.length === c2.length &&
         c1
            .slice()
            .sort()
            .every((value, index) => value === c2.slice().sort()[index]);
      setCompanySelected(null);
      if (
         user.group &&
         sameCompanies(
            user.group?.companies,
            companies.map((c) => c._id)
         )
      ) {
         setPath("/mi-lecosy/grupo/inicio");
      } else if (companies.length > 1) {
         setPath("/mi-lecosy/empresas/inicio");
      } else {
         setPath("/mi-lecosy/empresa/inicio");
      }
      handleClose();
   };

   return (
      <>
         <AppBar position="fixed" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, bgcolor: "#162C44", boxShadow: 0 }}>
            <Toolbar>
               <Box sx={{ display: "flex", justifyContent: "space-between", minWidth: "100%" }}>
                  <Box
                     component={"img"}
                     sx={{
                        ml: -1,
                        alignSelf: "center",
                        maxWidth: 42,
                        maxHeight: 42,
                        "&:hover": {
                           cursor: "pointer",
                        },
                     }}
                     alt="Logo Lecosy"
                     src={logo_Isotipo_Blanco}
                     onClick={() => setPath("inicio")}
                  />

                  <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                     <IconButton onClick={handleClickNotification}>
                        <Badge
                           badgeContent={
                              notifications.filter((notification) => {
                                 return !notification.users?.find((userF) => userF.userId === user.id)?.read;
                              }).length
                           }
                           color="error"
                           sx={{ color: "white" }}
                        >
                           <Notifications sx={{ color: "white" }} />
                        </Badge>
                     </IconButton>
                     <Tooltip title="Soporte">
                        <IconButton
                           onClick={() => {
                              setSupportOpen(!supportOpen);
                           }}
                        >
                           <SupportAgent sx={{ color: "white" }} />
                        </IconButton>
                     </Tooltip>
                     <Divider
                        orientation="vertical"
                        flexItem
                        color="white"
                        sx={{ my: 2, mr: 2, ml: 1, opacity: 0.2 }}
                     />
                     <Stack
                        direction={"row"}
                        display={"flex"}
                        justifyContent={"space-between"}
                        bgcolor={"white"}
                        borderRadius={2}
                        spacing={4}
                        pl={2}
                        sx={{ ":hover": { cursor: "pointer" } }}
                        aria-controls={openMenu ? "account-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={openMenu ? "true" : undefined}
                        onClick={handleClick}
                     >
                        <Box
                           sx={{
                              width: { xs: "40px" },
                              backgroundImage: `url(${appBarLogo?.url || logo_Lecosy})`,
                              backgroundSize: "contain",
                              backgroundPosition: "center center",
                              backgroundRepeat: "no-repeat",
                              aspectRatio: "1/1",
                           }}
                        />
                        <Tooltip title={`${user.firstName} ${user.lastName}`}>
                           <Stack direction={"row"} alignItems={"center"}>
                              <Avatar
                                 src={userProfilePicture}
                                 sx={{ my: 1, bgcolor: userProfilePicture ? "white" : "#BDBDBD" }}
                                 imgProps={{ sx: { objectFit: "cover" } }}
                              >
                                 <Typography>{`${user.firstName.charAt(0)}${user.lastName.charAt(0)}`}</Typography>
                              </Avatar>
                              <ArrowDropDown
                                 fontSize="medium"
                                 sx={{ color: "black", transform: `rotate(${!openMenu ? "0deg" : "180deg"})` }}
                              />
                           </Stack>
                        </Tooltip>
                     </Stack>
                  </Box>
               </Box>
            </Toolbar>
            <Menu
               anchorEl={anchorEl}
               id="account-menu"
               open={openMenu}
               onClose={handleClose}
               onClick={handleClose}
               sx={{ m: 1 }}
               transformOrigin={{ horizontal: "right", vertical: "top" }}
               anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
               {(isCompanyAdmin || isImplementationUser()) && (
                  <MenuItem onClick={handleMiLecosy}>
                     <ListItemIcon>
                        <PermIdentity sx={{ color: "#162c44" }} />
                     </ListItemIcon>
                     Mi Lecosy
                  </MenuItem>
               )}
               <MenuItem
                  onClick={() => {
                     setPath("/configuracion");
                     handleClose();
                  }}
               >
                  <ListItemIcon>
                     <Settings fontSize="small" sx={{ color: "#162c44" }} />
                  </ListItemIcon>
                  Configuración
               </MenuItem>
               <MenuItem
                  onClick={() => {
                     handleLogOutModalOpen();
                     handleClose();
                  }}
               >
                  <ListItemIcon>
                     <Logout fontSize="small" sx={{ color: "#162c44" }} />
                  </ListItemIcon>
                  Cerrar sesión
               </MenuItem>
               <Typography fontSize={"10px"} textAlign={"center"} mt={1.5}>
                  {appBarLogo?.name || "Lecosy"}
               </Typography>
            </Menu>

            <Menu
               anchorEl={anchorEl2}
               id="account-menu"
               open={openMenu2}
               onClose={handleClose}
               onClick={handleClose}
               sx={{ m: 1 }}
               transformOrigin={{ horizontal: "right", vertical: "top" }}
               anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
            >
               <Stack sx={{ width: 300 }}>
                  <Typography
                     align="right"
                     sx={{ textDecoration: "underline", pr: 1, cursor: "pointer" }}
                     variant="caption"
                     onClick={() =>
                        socket.emit("mark_all_as_read", {
                           userId: user.id,
                        })
                     }
                  >
                     Marcar todo como leído
                  </Typography>
                  <Typography align="left" variant="h6" fontWeight={600} sx={{ pl: 2 }}>
                     Notificaciones
                  </Typography>
                  <Divider sx={{ my: 1 }} />
                  <Box sx={{ maxHeight: 320, overflowY: "auto" }}>
                     {notifications.map((notification, i) => {
                        return (
                           <Box key={"notification" + i}>
                              <Stack
                                 sx={{
                                    p: 2,
                                    cursor: "pointer",
                                    "&:hover": {
                                       bgcolor: "whitesmoke",
                                    },
                                    bgcolor: notification.users?.find((userF) => userF.userId === user.id)?.["read"]
                                       ? "#F7F7F7"
                                       : "white",
                                 }}
                                 onClick={() => {
                                    if (!notification.users.find((userF) => userF.userId === user.id)?.["read"]) {
                                       socket.emit("read_notification", {
                                          userId: user.id,
                                          notificationId: notification._id,
                                       });
                                    }
                                    navigate(notification.redirectTo);
                                 }}
                              >
                                 <Typography fontWeight={600}>{notification.title}</Typography>
                                 <Typography variant="body2">{removeHtmlTags(notification.description)}</Typography>
                              </Stack>
                              <Divider />
                           </Box>
                        );
                     })}
                  </Box>
                  <Box width={"100%"} mt={1} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                     <Typography sx={{ cursor: "pointer" }} onClick={() => navigate("/notificaciones")}>
                        Ver todo
                     </Typography>
                  </Box>
               </Stack>
            </Menu>
         </AppBar>
         <ConfirmationModal
            open={logoutModalOpen}
            setOpen={setLogoutModalOpen}
            onConfirm={onLogoutButtonPress}
            isLoading={loadingLogout}
            title={"Cerrar sesión"}
            body={"¿Estás seguro que quieres cerrar sesión?"}
         />
      </>
   );
};
