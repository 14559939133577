import { CssBaseline, ThemeProvider, createTheme } from "@mui/material";
import * as locales from "@mui/material/locale";
import { esES } from "@mui/x-date-pickers";
import React from "react";

type ThemeProps = {
   children: JSX.Element | JSX.Element[];
};

// BTN_PRIMARY = "#46566A",
enum themePalette {
   BG = "#FFFFFF",
   // main = "#162C44",
   BTN_PRIMARY = "#162C44",
   BTN_PRIMARY_LECOSY = "#2D4357",
}

const theme = createTheme(
   {
      components: {
         MuiTableSortLabel: {
            styleOverrides: {
               root: {
                  "&.Mui-active": {
                     color: "#1A1A1A",
                  },
               },
            },
         },
      },
      palette: {
         mode: "light",
         background: {
            default: themePalette.BG,
         },
         primary: {
            main: themePalette.BTN_PRIMARY,
         },
         secondary: {
            main: "#8a95a0",
         },
      },
      // components: {

      // },
      typography: {
         button: {
            textTransform: "none",
         },
         fontFamily: ["'Open Sans'"].join(","),
      },
   },
   locales["esES"],
   esES
);

export const SystemThemeConfig: React.FC<ThemeProps> = ({ children }) => {
   return (
      <ThemeProvider theme={theme}>
         <CssBaseline />
         {children}
      </ThemeProvider>
   );
};
