import { Close } from "@mui/icons-material";
import { Box, IconButton, TextField, Tooltip } from "@mui/material";

interface SearchBarProps {
   value: string;
   setValue: Function;
}

export const SearchBarMembers = (props: SearchBarProps) => {
   return (
      <>
         <Box sx={{ mb: 3 }}>
            <TextField
               type="text"
               label="Buscar"
               fullWidth
               sx={{ borderColor: "white" }}
               size="small"
               value={props.value}
               onChange={(e) => {
                  props.setValue(e.target.value);
               }}
               InputProps={{
                  endAdornment: (
                     <IconButton>
                        <Tooltip
                           title=""
                           onClick={() => {
                              props.setValue("");
                           }}
                        >
                           <Close />
                        </Tooltip>
                     </IconButton>
                  ),
               }}
            />
         </Box>
      </>
   );
};
