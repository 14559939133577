import { FiberManualRecord } from "@mui/icons-material";
import { Stack, Typography } from "@mui/material";

export const GraphLabels = () => {
   return (
      <Stack direction={"row"} columnGap={1} justifyContent={"center"} py={2}>
         <Stack direction={"row"} columnGap={1} alignItems={"center"}>
            <FiberManualRecord sx={{ color: "#A3A3A3" }} />
            <Typography variant="body2">Deficiente</Typography>
         </Stack>
         <Stack direction={"row"} columnGap={1} alignItems={"center"}>
            <FiberManualRecord sx={{ color: "#868FA0" }} />
            <Typography variant="body2">Regular</Typography>
         </Stack>
         <Stack direction={"row"} columnGap={1} alignItems={"center"}>
            <FiberManualRecord sx={{ color: "#162c44" }} />
            <Typography variant="body2">Sobresaliente</Typography>
         </Stack>
      </Stack>
   );
};
