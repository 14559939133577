import { createContext, useCallback, useContext, useEffect, useState } from "react";
import {
   getBeneficiaryByCompanyId,
   getPendingRequestsByBeneficiaryId,
   VerifyFolderByNameNoMutate,
   verifyImplementationUser,
} from "../../lib/usersBEClient";
import { UserContext } from "../userContext";
import { createUserBasicInformation, getUserDetailsByUserId } from "../../lib/gobCorpBEClient";
import { useParams } from "react-router-dom";
import { ILaboralExperience, IProfesionalData, IProfesionalStudies } from "../../types/governance.types";
import { GovernanceContext } from "../governanceContext/governanceContext";

interface ICreateProfileContext {
   openProfileModal: boolean;
   setOpenProfileModal: Function;
   steps: string[];
   skipped: Set<number>;
   setSkipped: Function;
   isStepSkipped: Function;
   activeStep: number;
   setActiveStep: Function;
   handleNext: Function;
   disableButton: boolean;
   setDisableButton: Function;
   personalFilesId: string;
   setPersonalFilesId: Function;
   setNewUserDetails: Function;
   newUserDetails: any;
   handleSubmitCreate: Function;
   isVerified: boolean;
   setIsVerified: Function;
   fileToDelete: any;
   setFileToDelete: Function;
   laboralExperience: ILaboralExperience[];
   setLaboralExperience: Function;
   professionalStudies: IProfesionalStudies[];
   setProfessionalStudies: Function;
   postgraduate: IProfesionalStudies[];
   setPostgraduate: Function;
   certificates: IProfesionalStudies[];
   setCertificates: Function;
   profesionalData: IProfesionalData[];
   setProfesionalData: Function;
   beneficiaryDetails: any;
   setBeneficiaryDetails: Function;
   requestModal: boolean;
   setRequestModal: Function;
   pendingRequests: { requestsIds: string[]; files: string[] };
}

export const CreateProfileContext = createContext<ICreateProfileContext>({
   openProfileModal: false,
   setOpenProfileModal: () => {},
   steps: [],
   skipped: new Set<number>(),
   setSkipped: () => {},
   isStepSkipped: () => {},
   activeStep: 0,
   setActiveStep: () => {},
   handleNext: () => {},
   disableButton: false,
   setDisableButton: () => {},
   personalFilesId: null,
   setPersonalFilesId: () => {},
   setNewUserDetails: () => {},
   newUserDetails: null,
   handleSubmitCreate: () => {},
   isVerified: false,
   setIsVerified: () => {},
   fileToDelete: null,
   setFileToDelete: () => {},
   laboralExperience: null,
   setLaboralExperience: () => {},
   professionalStudies: null,
   setProfessionalStudies: () => {},
   postgraduate: null,
   setPostgraduate: () => {},
   certificates: null,
   setCertificates: () => {},
   profesionalData: null,
   setProfesionalData: () => {},
   beneficiaryDetails: null,
   setBeneficiaryDetails: () => {},
   requestModal: false,
   setRequestModal: () => {},
   pendingRequests: null,
});

export const CreateProfileProvider = ({ children }) => {
   const { user } = useContext(UserContext);
   const [skipped, setSkipped] = useState(new Set<number>());
   const [activeStep, setActiveStep] = useState(0);
   const [disableButton, setDisableButton] = useState(false);
   const [openProfileModal, setOpenProfileModal] = useState(false);
   const [requestModal, setRequestModal] = useState(false);
   const [pendingRequests, setPendingRequests] = useState(null);
   const [personalFilesId, setPersonalFilesId] = useState(null);
   const [newUserDetails, setNewUserDetails] = useState(null);
   const [isVerified, setIsVerified] = useState(false);
   const [fileToDelete, setFileToDelete] = useState(null);
   const [laboralExperience, setLaboralExperience] = useState([]);
   const [professionalStudies, setProfessionalStudies] = useState([]);
   const [postgraduate, setPostgraduate] = useState([]);
   const [certificates, setCertificates] = useState([]);
   const [profesionalData, setProfesionalData] = useState([]);
   const [beneficiaryDetails, setBeneficiaryDetails] = useState(null);
   const { companyId } = useParams();
   const { setUserDetails, setIsUserVerified } = useContext(GovernanceContext);
   const [steps, setSteps] = useState(["Documentación", "Información básica", "Información profesional"]);

   const getUserDetailsData = async (userId, companyId) => {
      try {
         const userDetails = await getUserDetailsByUserId(userId);
         if (userDetails) {
            setUserDetails(userDetails);
            setIsUserVerified(true);
            return;
         }
         const implementationRole = await verifyImplementationUser(userId, companyId);
         if (implementationRole) {
            handleSubmitCreate();
            setOpenProfileModal(false);
         } else setOpenProfileModal(true);
      } catch (error) {
         console.log(error);
      }
   };

   useEffect(() => {
      if (user?.id && companyId) getUserDetailsData(user.id, companyId);
   }, [user?.id, companyId]);

   const isStepSkipped = (step: number) => {
      return skipped.has(step);
   };

   const handleNext = () => {
      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
         newSkipped = new Set(newSkipped.values());
         newSkipped.delete(activeStep);
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
   };

   const handleSubmitCreate = async () => {
      try {
         setDisableButton(true);
         const folderNames = ["Mis documentos", "Mis chats", "Insights", "Archivos compartidos"];
         const verifyFoldersPromises = folderNames.map((name) => VerifyFolderByNameNoMutate({ userId: user.id, name }));
         const [myDocumentFolder, chatsFolder, insightFolder, sharedFolder] = await Promise.all(verifyFoldersPromises);

         const bodyUserBasicInformation = {
            profesionalData: profesionalData,
            personalFiles: personalFilesId,
            userDetails: newUserDetails,
            company: companyId,
            myDocumentsFolderId: myDocumentFolder._id,
            myChatsFolderId: chatsFolder._id,
            myInsights: insightFolder._id,
            sharedFolder: sharedFolder._id,
         };
         const createdUserDetails = await createUserBasicInformation(user.id, bodyUserBasicInformation);
         if (createdUserDetails) {
            setIsUserVerified(true);
            setUserDetails(createdUserDetails);
            setOpenProfileModal(false);
         }
      } catch (error) {
         setDisableButton(false);
         console.error("Failed to create a user details", error);
      }
   };

   const fetchBeneficiaries = useCallback(async () => {
      try {
         const beneficiary = await getBeneficiaryByCompanyId(companyId);
         if (!beneficiary) return;
         const userFound = beneficiary.data.find((b) => b.user?._id === user.id);
         if (!userFound) return;
         const { legalPerson, effectiveControl } = userFound;
         const stepsTemp = ["Documentación", "Información básica", "Información profesional"];
         if (legalPerson === "Accionista") stepsTemp.push("Accionista");
         else if (effectiveControl) stepsTemp.push("Control efectivo");
         else stepsTemp.push("Beneficiario controlador");
         setSteps(stepsTemp);
         setBeneficiaryDetails(userFound);
      } catch (error) {
         console.log(error);
      }
   }, [companyId, user.id]);

   useEffect(() => {
      if (companyId && user?.id) fetchBeneficiaries();
   }, [user?.id, companyId, fetchBeneficiaries]);

   const getPendingBeneficiaryRequests = useCallback(async () => {
      try {
         const requestsResponse = await getPendingRequestsByBeneficiaryId(beneficiaryDetails._id);
         if (requestsResponse?.files?.length > 0) {
            setPendingRequests(requestsResponse);
            setRequestModal(true);
         }
      } catch (error) {
         console.log(error);
      }
   }, [beneficiaryDetails]);

   useEffect(() => {
      if (beneficiaryDetails) getPendingBeneficiaryRequests();
   }, [getPendingBeneficiaryRequests, beneficiaryDetails]);

   return (
      <CreateProfileContext.Provider
         value={{
            steps,
            openProfileModal,
            setOpenProfileModal,
            skipped,
            setSkipped,
            activeStep,
            setActiveStep,
            isStepSkipped,
            handleNext,
            disableButton,
            setDisableButton,
            personalFilesId,
            setPersonalFilesId,
            newUserDetails,
            setNewUserDetails,
            handleSubmitCreate,
            isVerified,
            setIsVerified,
            fileToDelete,
            setFileToDelete,
            laboralExperience,
            setLaboralExperience,
            professionalStudies,
            setProfessionalStudies,
            postgraduate,
            setPostgraduate,
            certificates,
            setCertificates,
            profesionalData,
            setProfesionalData,
            beneficiaryDetails,
            setBeneficiaryDetails,
            requestModal,
            setRequestModal,
            pendingRequests,
         }}
      >
         {children}
      </CreateProfileContext.Provider>
   );
};
