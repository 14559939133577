import { Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

export const ProclamationTemplate = (
   companyLogo,
   governingBody,
   order,
   day,
   hour,
   place,
   provider: { name; id },
   president,
   billCode,
   group
) => {
   const date = new Date();

   return (
      <Document>
         <Page style={styles.body}>
            <View
               style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  marginBottom: "30",
               }}
            >
               <Image style={{ width: 60, marginHorizontal: "10" }} src={companyLogo} />
               <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Text style={{ fontSize: "11" }}>CONVOCATORIA DE SESIÓN</Text>
                  <Text
                     style={{
                        fontSize: "11",
                        marginLeft: "8",
                        backgroundColor: "#8A95A0",
                        borderRadius: "2",
                        padding: "2",
                     }}
                  >
                     {billCode}
                  </Text>
               </View>
            </View>
            <Text style={styles.title}>{governingBody.toUpperCase()}</Text>
            <View
               style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  marginVertical: "10",
               }}
            >
               <Text style={{ fontSize: "10" }}>Hermosillo Sonora,</Text>
               <Text style={{ fontSize: "10" }}>
                  a{" "}
                  {date.toLocaleDateString("es-MX", {
                     day: "numeric",
                     month: "long",
                     year: "numeric",
                  })}
                  {/* a {date.getDay()} del mes de{" "}
                  {date.toLocaleDateString("es-MX", {
                     month: "long",
                  })}{" "}
                  del{" "}
                  {date.toLocaleDateString("es-MX", {
                     year: "numeric",
                  })} */}
               </Text>
            </View>
            <Text style={styles.text}>{`Estimados miembros del ${governingBody}`},</Text>
            <Text style={[styles.text, { marginBottom: "10" }]}>
               Por medio de la presente, les convocó a la próxima sesión del {governingBody}, a realizarse en la
               siguiente fecha, hora y lugar:
            </Text>
            <View style={{ display: "flex", flexDirection: "row", borderBottom: 1 }}>
               <Text style={[styles.title, { flex: 2 }]}>FECHA</Text>
               <Text style={[styles.title, { flex: 2 }]}>HORA</Text>
               {place && <Text style={[styles.title, { flex: 3 }]}>LUGAR</Text>}
               {provider.name && <Text style={[styles.title, { flex: 3 }]}>PROVEEDOR</Text>}
               {provider.id && <Text style={[styles.title, { flex: 3 }]}>CÓDIGO</Text>}
            </View>
            <View
               style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#EFEFEF",
                  paddingVertical: "3",
                  marginBottom: "10",
               }}
            >
               <Text style={{ fontSize: "10", flex: 2, textAlign: "center", backgroundColor: "#EFEFEF" }}>
                  {day.toLocaleDateString("es-MX")}
               </Text>
               <Text
                  style={{
                     fontSize: "10",
                     flex: 2,
                     textAlign: "center",
                     backgroundColor: "#EFEFEF",
                     borderWidth: 1,
                     borderColor: "#EFEFEF",
                     borderRightColor: "white",
                     borderLeftColor: "white",
                  }}
               >
                  {hour.toLocaleTimeString("es-MX")}
               </Text>
               {place && (
                  <Text
                     style={{
                        fontSize: "10",
                        flex: 3,
                        textAlign: "center",
                        backgroundColor: "#EFEFEF",
                        borderColor: "#EFEFEF",
                        borderRightColor: "white",
                        borderWidth: 1,
                     }}
                  >{`${place}`}</Text>
               )}
               {provider.name && (
                  <Text
                     style={{
                        fontSize: "10",
                        flex: 3,
                        textAlign: "center",
                        backgroundColor: "#EFEFEF",
                        borderColor: "#EFEFEF",
                        borderRightColor: "white",
                        borderWidth: 1,
                     }}
                  >{`${provider.name}`}</Text>
               )}
               {provider.id && (
                  <Text
                     style={{
                        fontSize: "10",
                        flex: 3,
                        textAlign: "center",
                        backgroundColor: "#EFEFEF",
                        borderColor: "#EFEFEF",
                        borderRightColor: "white",
                        borderWidth: 1,
                     }}
                  >{`${provider.id}`}</Text>
               )}
            </View>
            <Text style={[styles.text, { marginBottom: "10" }]}>
               El propósito de la sesión es tratar los siguientes temas que se encuentran en el orden del día:
            </Text>

            <View
               style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#A9A9A9",
                  paddingVertical: 3,
                  borderTop: "3px solid #162c44",
               }}
            >
               <Text
                  style={{
                     fontSize: "11",
                     flex: 1,
                     textAlign: "center",
                     borderRight: "1px solid #ffffff",
                     color: "white",
                     fontWeight: "bold",
                     marginLeft: "15px",
                  }}
               >
                  ASUNTO
               </Text>
               <Text style={{ fontSize: "11", flex: 2, textAlign: "center", color: "white", fontWeight: "bold" }}>
                  DESCRIPCIÓN DEL ASUNTO
               </Text>
               {group && (
                  <Text
                     style={{
                        fontSize: "11",
                        flex: 1,
                        textAlign: "center",
                        color: "white",
                        borderLeft: "1px solid #ffffff",
                        fontWeight: "bold",
                     }}
                  >
                     EMPRESA(S)
                  </Text>
               )}
            </View>
            {order.map((affair) => (
               <View
                  style={{
                     display: "flex",
                     flexDirection: "row",
                     backgroundColor: "#EFEFEF",
                     paddingVertical: 3,
                     marginBottom: 3,
                     fontSize: "11",
                  }}
               >
                  <Text
                     style={{
                        fontSize: "11",
                        flex: 1,
                        textAlign: "center",
                        borderRight: "1px solid #ffffff",
                        padding: 15,
                     }}
                  >
                     {affair.affair}
                  </Text>
                  <Text style={{ fontSize: "11", flex: 2, textAlign: "center", padding: 15 }}>
                     {affair.description}
                  </Text>
                  {group && (
                     <View style={{ flex: 1, padding: 8, borderLeft: "1px solid #ffffff" }}>
                        {affair.companies.map((company, index) => (
                           <Text
                              key={index}
                              style={{
                                 fontSize: 11,
                                 textAlign: "center",
                              }}
                           >
                              {company}
                           </Text>
                        ))}
                     </View>
                  )}
               </View>
            ))}

            <Text style={[styles.text, { marginBottom: "10", marginTop: "10" }]}>
               Se solicita a todos los miembros del {governingBody.includes("Consejo") ? "Consejo" : "Comité"} que
               asistan puntualmente y estén preparados para discutir cada uno de los puntos mencionados en el orden del
               día. Les pedimos que revisen los documentos adjuntos previamente, para facilitar el desarrollo de la
               reunión.
            </Text>
            <Text style={[styles.text, { marginBottom: "10" }]}>
               En caso de no poder asistir, les pedimos que notifiquen su ausencia con antelación, para tomar las
               medidas necesarias.
            </Text>
            <Text style={[styles.text, { marginBottom: "10" }]}>
               Agradecemos su compromiso y participación en esta importante sesión. Su contribución es fundamental para
               el éxito de nuestra organización.
            </Text>

            <Text style={{ fontSize: "10", textAlign: "center", marginTop: "30" }}>ATENTAMENTE</Text>
            <Text
               style={{
                  fontSize: "10",
                  textAlign: "center",
                  marginTop: "60",
                  borderBottom: 1,
                  marginHorizontal: "130",
               }}
            ></Text>
            <Text style={{ fontSize: "10", textAlign: "center", marginTop: "5" }}>{president}</Text>
            <Text
               style={{
                  fontSize: "8",
                  textAlign: "center",
                  marginTop: "5",
                  fontFamily: "open-sans-bold",
                  fontWeight: "bold",
               }}
            >
               {`PRESIDENTE DE ${governingBody.toUpperCase()}`}
            </Text>

            <Text
               style={{ fontSize: "10", textAlign: "center", marginTop: "30", borderBottom: 1, marginHorizontal: "20" }}
            ></Text>
            <Text style={{ fontSize: "10", textAlign: "right", marginTop: "5", marginHorizontal: "20" }}>LECOSY</Text>
         </Page>
      </Document>
   );
};

Font.register({
   family: "open-sans",
   src: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-Regular.ttf",
});

Font.register({
   family: "open-sans-bold",
   src: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-Bold.ttf",
});

const styles = StyleSheet.create({
   body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 75,
      fontFamily: "open-sans",
   },
   title: {
      fontSize: 11,
      fontFamily: "open-sans-bold",
      fontWeight: "bold",
      textAlign: "center",
   },
   text: {
      fontSize: 10,
      textAlign: "justify",
   },
   image: {
      marginVertical: 15,
      marginHorizontal: 100,
   },
   pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
   },
});
