import { Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { getContrastYIQ } from "../../../const/globalConst";

Font.register({
   family: "open-sans",
   src: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-Regular.ttf",
});

Font.register({
   family: "open-sans-bold",
   src: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-Bold.ttf",
});

export const shareHolderRequestTemplate = (
   companyLogo,
   companyName,
   primaryColor,
   userName,
   presidentName,
   municipality,
   state
) => {
   const styles = StyleSheet.create({
      body: {
         paddingTop: 100,
         paddingBottom: 65,
         paddingHorizontal: 75,
         fontFamily: "open-sans",
      },
      logo: {
         height: 40,
         width: "auto",
         position: "absolute",
         top: 50,
         left: 65,
      },
      title: {
         fontSize: 12,
         fontFamily: "open-sans-bold",
         fontWeight: "bold",
         textAlign: "center",
         paddingBottom: 20,
      },
      text: {
         fontSize: 11,
         textAlign: "justify",
         lineHeight: 1.8,
      },
      headerTextRight: {
         textAlign: "right",
         fontSize: 11,
         paddingBottom: 20,
      },
      headerTextLeft: {
         textAlign: "left",
         fontSize: 11,
         paddingBottom: 20,
      },
      indentText: {
         paddingBottom: 10,
         textIndent: 30,
      },
      tableHeader: {
         display: "flex",
         flexDirection: "row",
         backgroundColor: primaryColor,
      },
      tableHeaderText: {
         fontSize: 12,
         flex: 1,
         textAlign: "center",
         color: getContrastYIQ(primaryColor),
         fontWeight: "bold",
         paddingVertical: 5,
         lineHeight: 1.8,
      },
      tableHeaderTextWithBorder: {
         fontSize: 12,
         flex: 1,
         textAlign: "center",
         color: "white",
         fontWeight: "bold",
         paddingVertical: 5,
         borderRight: "1px solid #ffffff",
         lineHeight: 1.8,
      },
      tableRow: {
         display: "flex",
         flexDirection: "row",
         backgroundColor: "#EFEFEF",
      },
      tableCell: {
         paddingVertical: 5,
         paddingHorizontal: 10,
         fontSize: 11,
         flex: 1,
         textAlign: "justify",
         lineHeight: 1.8,
      },
      tableCellWithBorder: {
         paddingVertical: 5,
         paddingHorizontal: 10,
         fontSize: 11,
         flex: 1,
         textAlign: "justify",
         borderRight: "1px solid #d8d8d8",
         lineHeight: 1.8,
      },
      tableCellTopBorder: {
         borderTop: "1px solid #d8d8d8",
      },
   });

   const fecha = new Date();
   const day = fecha.getDate();
   const month = fecha.toLocaleString("es-ES", { month: "long" });
   const year = fecha.getFullYear();
   const hours24 = fecha.getHours();
   const hours12 = hours24 % 12 || 12;
   const minutes = fecha.getMinutes().toString().padStart(2, "0");
   const amPm = hours24 >= 12 ? "PM" : "AM";

   return (
      <Document>
         <Page style={styles.body}>
            <Image style={styles.logo} fixed src={companyLogo} />
            <Text style={styles.title}>SOLICITUD DE DOCUMENTACIÓN E INFORMACIÓN ACCIONISTAS</Text>
            <Text style={[styles.text, styles.headerTextRight]}>
               {`${municipality}, ${state} a ${day} de ${month} del ${year}, ${hours12}:${minutes} ${amPm}`}
            </Text>
            <Text style={[styles.text, styles.headerTextLeft]}>Estimado, {userName}</Text>
            <Text style={[styles.text, styles.indentText]}>
               Por medio de la presente, hacemos la solicitud de documentación e información correspondiente para
               generar su expediente como accionista de la sociedad {companyName}. Por lo que se le solicita la
               siguiente documentación:
            </Text>
            <View style={styles.tableHeader}>
               <Text style={styles.tableHeaderTextWithBorder}>Documentación</Text>
               <Text style={styles.tableHeaderText}>Finalidad</Text>
            </View>
            <View style={styles.tableRow}>
               <Text style={styles.tableCellWithBorder}>&#x2022; Credencial de elector</Text>
               <Text style={styles.tableCell}>
                  Extracción de:
                  {"\n"} &#x2022; Nombres y apellidos completos.
                  {"\n"} &#x2022; Fecha de nacimiento.
                  {"\n"} &#x2022; Sexo.
                  {"\n"} &#x2022; Tipo y número o clave de la identificación oficial.
               </Text>
            </View>

            <View style={styles.tableRow}>
               <Text style={[styles.tableCellWithBorder, styles.tableCellTopBorder]}>
                  &#x2022; Acta de nacimiento y/o certificado de nacionalidad del país que corresponda, en caso de tener
                  más de una nacionalidad.
               </Text>
               <Text style={[styles.tableCell, styles.tableCellTopBorder]}>
                  Extracción de:
                  {"\n"} &#x2022; País de origen.
                  {"\n"} &#x2022; Nacionalidad.
               </Text>
            </View>

            <View style={styles.tableRow}>
               <Text style={[styles.tableCellWithBorder, styles.tableCellTopBorder]}>
                  &#x2022; Credencial de elector o pasaporte del país correspondiente.
               </Text>
               <Text style={[styles.tableCell, styles.tableCellTopBorder]}>
                  Extracción de:
                  {"\n"} &#x2022; CURP o su equivalente, tratándose de otros países o jurisdicciones.
               </Text>
            </View>

            <View style={styles.tableRow}>
               <Text style={[styles.tableCellWithBorder, styles.tableCellTopBorder]}>
                  &#x2022; Constancia de situación fiscal.
               </Text>
               <Text style={[styles.tableCell, styles.tableCellTopBorder]}>
                  Extracción de:
                  {"\n"} &#x2022; País o jurisdicción de residencia para efectos fiscales.
                  {"\n"} &#x2022; Clave en el RFC o número de identificación fiscal, o su equivalente, en caso de ser
                  residente en el extranjero, para efectos fiscales.
                  {"\n"} &#x2022; Domicilio fiscal.
               </Text>
            </View>

            <View style={styles.tableRow}>
               <Text style={[styles.tableCellWithBorder, styles.tableCellTopBorder]}>
                  &#x2022; Comprobante de domicilio no mayor a 3 meses.
               </Text>
               <Text style={[styles.tableCell, styles.tableCellTopBorder]}>
                  Extracción de:
                  {"\n"} &#x2022; Domicilio particular
               </Text>
            </View>

            <View style={styles.tableRow}>
               <Text style={[styles.tableCellWithBorder, styles.tableCellTopBorder]}>&#x2022; Títulos accionarios</Text>
               <Text style={[styles.tableCell, styles.tableCellTopBorder]}>
                  Extracción de:
                  {"\n"} &#x2022; Nombre, nacionalidad y domicilio del accionista.
                  {"\n"} &#x2022; Denominación, domicilio y duración de la sociedad.
                  {"\n"} &#x2022; Fecha de la constitución de la sociedad y los datos de su inscripción en el Registro
                  Público de Comercio;
                  {"\n"} &#x2022; Importe del capital social, el número total y el valor nominal de las acciones.
               </Text>
            </View>

            <Text style={[styles.text, { paddingVertical: 20 }]}>
               Le informamos que cuenta con un plazo de 5 días naturales a partir de la recepción de esta notificación
               para proporcionar la documentación e información requerida. Agradecemos de antemano su pronta
               colaboración y quedamos atentos a cualquier duda o aclaración que pudiera surgir al respecto.
            </Text>
            <Text style={styles.text}>
               Atentamente
               {"\n"}
               {presidentName}
            </Text>
         </Page>
      </Document>
   );
};
