import ListComponent from "../../../../ListComponent";

const GoverningBodyList = ({ selectedSession }) => {
   return (
      <ListComponent
         headers={[
            { headerTitle: "Atendió", type: "check", bodyPosition: "center" },
            { headerTitle: "Puesto", type: "text", bodyPosition: "center" },
         ]}
         rows={
            (selectedSession.involved.length > 0 &&
               selectedSession.involved
                  .map((user) => {
                     if (user === undefined) return;
                     return {
                        attended: { attended: user.attended, name: user.fullName },
                        charge: user.chargeName,
                     };
                  })
                  .filter((user) => user)) ||
            []
         }
         rowsColumnProps={{
            px: 2,
         }}
         rowProps={(row, i) => {
            const attended = row.attended.attended;
            return {
               bgcolor: "#F5F5F5",
               borderLeft: 7,
               borderBottomLeftRadius: 4,
               borderLeftColor: attended ? "#5E77F9" : "#CECECE",
               py: 1,
               my: 0.5,
            };
         }}
      />
   );
};

export default GoverningBodyList;
