import { Box } from "@mui/material";
import { SessionBase } from "./SessionBase";
import { CustomModalComponent } from "../../../CustomModalComponent";

export const SessionAssembly = (props) => {
   return (
      <CustomModalComponent open={props.open} setOpen={props.setOpen} title="Convocatoria a asamblea" timeStamp>
         <Box sx={{ maxWidth: 700 }}>
            <SessionBase
               setOpen={props.setOpen}
               assembly
               scheduler={props.scheduler}
               edit={props.edit}
               initialValues={props.initialValues}
            />
         </Box>
      </CustomModalComponent>
   );
};
