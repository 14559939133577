import dayjs from "dayjs";
import { getTrimesterDates } from "../../../const/globalConst";
import { useGetCommitmentsInfo } from "./useGetCommitmentsInfo";
import { useContext, useMemo } from "react";
import useGetGovernmentBodyUsersInfo from "./useGetGovernmentBodyUsersInfo";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";

type trimesterCommitmentInfo = {
   name: string;
   assigned: string[];
   status: boolean;
   limitDate: Date;
};

type trimesterCommitments = {
   trimester1: trimesterCommitmentInfo[];
   trimester2: trimesterCommitmentInfo[];
   trimester3: trimesterCommitmentInfo[];
   trimester4: trimesterCommitmentInfo[];
};

const getFormattedInfo = (commitment, membersInfo) => {
   const formattedCommitment: trimesterCommitmentInfo = {
      name: commitment.title,
      assigned:
         membersInfo?.usersData
            .filter((member) => commitment.users.includes(member.user))
            .map((member) => `${member.firstName} ${member.lastName}`) || [],
      status: commitment.status === "COMPLETE",
      limitDate: new Date(commitment.dueDate),
   };
   return formattedCommitment;
};
export const useGetCommitmentTrimesterInfo = () => {
   const { selectedGovernance } = useContext(GovernanceContext);
   const { commitmentsByGovernance } = useGetCommitmentsInfo({});
   const { members } = useGetGovernmentBodyUsersInfo();
   const trimesterDates = getTrimesterDates();
   const getCommitmentsInfo = () => {
      const commitmentsList: trimesterCommitments = {
         trimester1: [],
         trimester2: [],
         trimester3: [],
         trimester4: [],
      };
      const selectedMembers = members.find((govBody) => govBody.bodiesData._id === selectedGovernance._id);
      for (let i = 0; i < trimesterDates.length; i++) {
         let selectedTrimesterKey = Object.keys(commitmentsList)[i];
         for (const commitment of commitmentsByGovernance) {
            if (
               (commitment.status === "COMPLETE" || commitment.status === "EXPIRED") &&
               dayjs(commitment.dueDate) >= dayjs(trimesterDates[i]) &&
               dayjs(commitment.dueDate) < dayjs(trimesterDates[i + 1])
            )
               commitmentsList[selectedTrimesterKey].push(getFormattedInfo(commitment, selectedMembers));
         }
      }
      return commitmentsList;
   };

   const trimesterCommitments = useMemo(getCommitmentsInfo, [
      commitmentsByGovernance,
      members,
      selectedGovernance._id,
      trimesterDates,
   ]);
   return trimesterCommitments;
};
