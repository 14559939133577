import { useRef, useContext } from "react";
import { SnackBarContext } from "../../context/snackBarContext";
import { IconButton, Box } from "@mui/material";
import { Add } from "@mui/icons-material";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { LocaleDateString } from "../../const/globalConst";

interface InputImageProps {
   setImage: Function;
   image: File | string;
   onChange?: Function;
   onClick?: Function;
   keyName: string;
}

export const InputImage = (props: InputImageProps) => {
   const inputRef: any = useRef(null);
   const { showSnackBar } = useContext(SnackBarContext);

   const handleUploadLogo = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.files.length === 0) {
         inputRef.current.value = null;
         return showSnackBar("Favor de adjuntar un documento", true);
      }
      if (
         event.target.files[0].type !== "image/png" &&
         event.target.files[0].type !== "image/jpg" &&
         event.target.files[0].type !== "image/jpeg"
      ) {
         inputRef.current.value = null;
         return showSnackBar("El archivo no es una imagen", true);
      }
      if (event.target.files[0].size > 1000000) {
         inputRef.current.value = null;
         return showSnackBar("El archivo no puede pesar más de 1MB", true);
      }
      props.setImage({ file: event.target.files[0], lastDateModified: new Date() });
      if (props.onChange) props.onChange({ file: event.target.files[0], fileType: "png", name: props.keyName });
   };

   return (
      <>
         {!props.image ? (
            <label style={{ cursor: "pointer" }}>
               <Box
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     backgroundColor: "#DCDCDC",
                     cursor: "pointer",
                     borderRadius: 2,
                     height: "90px",
                     width: "120px",
                     ":hover": { bgcolor: "#DCDCDCcc" },
                  }}
               >
                  <input
                     type="file"
                     ref={inputRef}
                     style={{ display: "none", cursor: "pointer" }}
                     accept="image/*"
                     onChange={(e) => handleUploadLogo(e)}
                  />
                  <Add fontSize="medium" />
               </Box>
            </label>
         ) : (
            <Box>
               <Box
                  sx={{
                     position: "relative",
                     display: "flex",
                     marginLeft: "105px",
                     bgcolor: "#162c44",
                     borderRadius: 10,
                     ":hover": { bgcolor: "#162c44cc" },
                  }}
               >
                  <IconButton
                     size="small"
                     onClick={() => {
                        props.setImage(null);
                     }}
                  >
                     <CloseRoundedIcon sx={{ color: "#DCDCDC", fontSize: 15 }} />
                  </IconButton>
               </Box>
               <Box
                  sx={{
                     display: "flex",
                     justifyContent: "center",
                     border: "none",
                     borderRadius: 5,
                     height: "50px",
                     width: "120px",
                     marginBottom: "15px",
                  }}
               >
                  <Box
                     component="img"
                     src={typeof props.image === "string" ? props.image : URL.createObjectURL(props.image)}
                     maxHeight={80}
                     maxWidth={120}
                     onClick={() => props.onClick && props.onClick()}
                     sx={{
                        alignSelf: "center",
                        borderRadius: 1,
                        ":hover": { cursor: props.onClick ? "pointer" : "default" },
                     }}
                  />
               </Box>
            </Box>
         )}
      </>
   );
};
