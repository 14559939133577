import { TableCell, Typography, Box } from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

interface EmptyDataTableCellProps {
   text: string;
}

export const EmptyDataTableCell = (props: EmptyDataTableCellProps) => {
   return (
      <TableCell colSpan={8}>
         <Box display={"flex"} sx={{ justifyContent: "center", alignItems: "center", color: "#2D4357" }}>
            <ErrorIcon sx={{ width: "18px", color: "gray" }} />
            <Typography sx={{ pl: 1, color: "gray" }} variant="subtitle2">
               {props.text}
            </Typography>
         </Box>
      </TableCell>
   );
};
