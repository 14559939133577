import { Box, Grid, Button, Typography, Modal, Stack } from "@mui/material";
import { Formik, FormikProps, Form } from "formik";
import { useState, useEffect, useContext } from "react";
import { PersonalInformationSchema } from "../../../../lib/validations/inputSchemas";
import { InputTextField } from "../../../Inputs/InputTextField";
import { ModalAddStudyComponent } from "../../ProfileScreen/SubComponents/ModalAddStudyComponent";
import { StudyItemComponent } from "../../ProfileScreen/SubComponents/StudyItemComponent";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { CreateProfileContext } from "../../../../context/beneficiaryContext/createProfileContext";

const gridRow = {
   display: "flex",
   alignItems: "center",
};

export const NewUserProfesionalInformation = () => {
   const {
      laboralExperience,
      professionalStudies,
      postgraduate,
      certificates,
      disableButton,
      handleNext,
      handleSubmitCreate,
      setDisableButton,
      setProfesionalData,
      activeStep,
      steps,
   } = useContext(CreateProfileContext);
   const [seed, setSeed] = useState<number>(0);
   const [open, setOpen] = useState<boolean>(false);
   const [degree, setDegree] = useState<string>("");
   const [edit, setEdit] = useState<boolean>(false);
   const [data, setData] = useState(null);
   const [experienceRecord, setExperienceRecord] = useState<boolean>(false);

   const handleSubmit = async (values) => {
      const studyRecord = professionalStudies.concat(postgraduate, certificates);
      const data = {
         company: values.company,
         studyRecord: studyRecord,
         experienceRecord: laboralExperience,
         positions: { name: values.actualPosition, startTime: values.startDate.$d },
      };
      setProfesionalData(data);
      if (activeStep === steps.length - 1) {
         setDisableButton(true);
         handleSubmitCreate();
      } else {
         handleNext();
      }
   };

   return (
      <>
         <Formik
            initialValues={{
               company: "",
               actualPosition: "",
               startDate: null,
            }}
            onSubmit={handleSubmit}
            validationSchema={PersonalInformationSchema}
         >
            {(formProps: FormikProps<any>) => (
               <Form>
                  <Grid
                     container
                     rowSpacing={4}
                     columnSpacing={2}
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        px: 6,
                        overflowY: "auto",
                        height: "60vh",
                     }}
                  >
                     <Grid item xs={4} sx={gridRow}>
                        Empresa
                     </Grid>
                     <Grid item xs={8}>
                        <InputTextField
                           id="company"
                           name="company"
                           type="string"
                           size={"small"}
                           fullWidth
                           sx={{ maxWidth: 500 }}
                        />
                     </Grid>
                     <Grid item xs={4} sx={gridRow}>
                        <Typography> Puesto actual</Typography>
                     </Grid>
                     <Grid item xs={4.4}>
                        <InputTextField
                           id="actualPosition"
                           name="actualPosition"
                           type="string"
                           size={"small"}
                           fullWidth
                           sx={{ maxWidth: 500 }}
                        />
                     </Grid>
                     <Grid item xs={3.6} sx={gridRow}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                           <DatePicker
                              name="startDate"
                              maxDate={dayjs(new Date())}
                              label={"Fecha de inicio"}
                              format="DD/MM/YYYY"
                              value={formProps.values["startDate"]}
                              onChange={(event: any) => formProps.setFieldValue("startDate", dayjs(event.$d))}
                              slotProps={{ textField: { variant: "outlined", size: "small" } }}
                           />
                        </LocalizationProvider>
                     </Grid>
                     <Grid item xs={4} sx={gridRow}>
                        Experiencia profesional
                     </Grid>
                     <Grid item xs={8}>
                        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }} key={seed}>
                           {laboralExperience &&
                              laboralExperience.map((e, index) => (
                                 <StudyItemComponent
                                    data={e}
                                    key={index}
                                    setOpen={setOpen}
                                    setEdit={setEdit}
                                    setData={setData}
                                    experienceRecord={true}
                                    setExperienceRecord={setExperienceRecord}
                                 />
                              ))}
                        </Box>
                        <Button
                           onClick={() => {
                              setEdit(false);
                              setOpen(true);
                              setDegree("Experiencia profesional");
                              setExperienceRecord(true);
                           }}
                        >
                           <Typography fontSize={12} color="#969696">
                              Agregar nuevo
                           </Typography>
                        </Button>
                     </Grid>
                     <Grid item xs={4} sx={gridRow}>
                        Estudios profesionales
                     </Grid>
                     <Grid item xs={8}>
                        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }} key={seed}>
                           {professionalStudies.map((e, index) => {
                              return (
                                 <StudyItemComponent
                                    data={e}
                                    key={index}
                                    setOpen={setOpen}
                                    setEdit={setEdit}
                                    setData={setData}
                                    experienceRecord={false}
                                    setExperienceRecord={setExperienceRecord}
                                 />
                              );
                           })}
                        </Box>
                        <Button
                           onClick={() => {
                              setEdit(false);
                              setOpen(true);
                              setExperienceRecord(false);
                              setDegree("Estudios profesionales");
                           }}
                        >
                           <Typography fontSize={12} color="#969696">
                              Agregar nuevo
                           </Typography>
                        </Button>
                     </Grid>
                     <Grid item xs={4} sx={gridRow}>
                        Posgrado
                     </Grid>
                     <Grid item xs={8}>
                        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }} key={seed}>
                           {postgraduate.map((e, index) => (
                              <StudyItemComponent
                                 data={e}
                                 key={index}
                                 setOpen={setOpen}
                                 setEdit={setEdit}
                                 setData={setData}
                                 experienceRecord={false}
                                 setExperienceRecord={setExperienceRecord}
                              />
                           ))}
                        </Box>
                        <Grid item xs={8}>
                           <Button
                              onClick={() => {
                                 setOpen(true);
                                 setDegree("Posgrado");
                                 setEdit(false);
                                 setExperienceRecord(false);
                              }}
                           >
                              <Typography fontSize={12} color="#969696">
                                 Agregar nuevo
                              </Typography>
                           </Button>
                        </Grid>
                     </Grid>

                     <Grid item xs={4} sx={gridRow}>
                        Diplomados, cursos y certificaciones
                     </Grid>
                     <Grid item xs={8}>
                        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1 }} key={seed}>
                           {certificates.map((e, index) => (
                              <StudyItemComponent
                                 data={e}
                                 key={index}
                                 setOpen={setOpen}
                                 setEdit={setEdit}
                                 setData={setData}
                                 experienceRecord={false}
                                 setExperienceRecord={setExperienceRecord}
                              />
                           ))}
                        </Box>
                        <Grid item xs={8}>
                           <Button
                              onClick={() => {
                                 setOpen(true);
                                 setEdit(false);
                                 setDegree("Diplomados, cursos y certificaciones");
                                 setExperienceRecord(false);
                              }}
                           >
                              <Typography fontSize={12} color="#969696">
                                 Agregar nuevo
                              </Typography>
                           </Button>
                        </Grid>
                     </Grid>
                  </Grid>
                  <Stack
                     sx={{
                        alignItems: "center",
                        py: 2,
                     }}
                  >
                     <Button
                        variant="contained"
                        onClick={() => formProps.submitForm()}
                        disabled={disableButton}
                        sx={{ width: 300 }}
                     >
                        Continuar
                     </Button>
                  </Stack>
                  <ModalAddStudyComponent
                     open={open}
                     setOpen={setOpen}
                     degree={degree}
                     edit={edit}
                     data={data}
                     experienceRecord={experienceRecord}
                     profDataId={""}
                     setSeed={setSeed}
                     isNewUser
                  />
               </Form>
            )}
         </Formik>
      </>
   );
};
