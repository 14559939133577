import WorkspacePremiumOutlinedIcon from "@mui/icons-material/WorkspacePremiumOutlined";
import { Box, Divider, IconButton, Typography } from "@mui/material";
import { getDateFromISODate } from "../../../../const/globalConst";
import EditIcon from "@mui/icons-material/Edit";
import WorkOutlineOutlinedIcon from "@mui/icons-material/WorkOutlineOutlined";

interface StudyItemProp {
   data: any;
   setOpen: Function;
   setEdit: Function;
   setData: Function;
   setExperienceRecord?: Function;
   experienceRecord?: boolean;
}
export const StudyItemComponent = (props: StudyItemProp) => {
   return (
      <>
         <Box display={"flex"} flexDirection={"row"} columnGap={3} alignItems={"flex-start"}>
            {props.experienceRecord ? (
               <WorkOutlineOutlinedIcon sx={{ color: "#969696" }} />
            ) : (
               <WorkspacePremiumOutlinedIcon sx={{ color: "#969696" }} />
            )}
            <Box display={"flex"} flexDirection={"column"} justifyContent={"flex-start"} flex={1}>
               <Typography fontSize={15} fontWeight={"400"}>
                  {props.data.title}
               </Typography>
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "row",
                     justifyContent: "space-between",
                     flex: 1,
                     alignItems: "center",
                     maxWidth: 450,
                  }}
               >
                  <Box sx={{ display: "flex", flexDirection: "row", flex: 1 }}>
                     <Typography fontSize={12} color={"#969696"}>
                        {props.data.institution}
                     </Typography>
                     <Divider orientation="vertical" sx={{ mx: 1 }} flexItem />
                     <Typography fontSize={12} color={"#969696"}>{`${
                        getDateFromISODate(props.data.startTime).split("/")[2]
                     } - ${getDateFromISODate(props.data.endTime).split("/")[2]}`}</Typography>
                  </Box>
                  <IconButton
                     onClick={() => {
                        props.setOpen(true);
                        props.setEdit(true);
                        props.setData(props.data);
                        props.experienceRecord ? props.setExperienceRecord(true) : props.setExperienceRecord(false);
                     }}
                  >
                     <EditIcon sx={{ color: "#E8E8E8" }} />
                  </IconButton>
               </Box>
            </Box>
         </Box>
      </>
   );
};
