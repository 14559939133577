import { useState, useRef, useContext, useMemo, useEffect } from "react";
import { UserContext } from "../../context/userContext";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import {
   Box,
   Grid,
   Typography,
   FormControl,
   InputLabel,
   Select,
   MenuItem,
   Button,
   Container,
   TextField,
   CircularProgress,
} from "@mui/material";
import categoryItems from "../../const/denounceCategories.json";
import SearchIcon from "@mui/icons-material/Search";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { NotificationBar } from "../../components/notificationsBar";
import { CompaniesStatisticsTable } from "../../containers/companiesStatisticsTable";
import { capitalizeFirstLetter, getDateFromISODate } from "../../const/globalConst";
import { specificStatisticReportTemplate2 } from "../../lib/documentsTemplates/statisticReportTemplate";
import { CompaniesCarousel } from "../../components/CompaniesCarousel";
import InputAdornment from "@mui/material/InputAdornment";
import { DatePicker } from "@mui/x-date-pickers";
import { SnackBarContext } from "../../context/snackBarContext";
import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";
import { ComplaintContext } from "../../context/complaintContext";
import { UseGetCompaniesNames } from "../../hooks/companies-branches/getCompaniesNames";
import GetFilteredComplaints from "../../hooks/complaints/getFilteredComplaints";
import GetComplaintsByBranch, { getCompanyByBranch } from "../../hooks/complaints/getComplaintsByBranch";
import UseCreateCharts from "../../hooks/useCreateCharts";
import dayjs from "dayjs";
import VerifyUserSession from "../../hooks/verifyUserSession";
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export const StadisticsScreen = () => {
   VerifyUserSession();
   const overallChartRef: any = useRef();
   const typeChartRef: any = useRef();
   const anonymousChartRef: any = useRef();
   const calificationChartRef: any = useRef();
   const clasificationChartRef: any = useRef();
   const fOverallChartRef: any = useRef();
   const fTypeChartRef: any = useRef();
   const fAnonymousChartRef: any = useRef();
   const fCalificationChartRef: any = useRef();
   const fClasificationChartRef: any = useRef();
   const clasification1ChartRef: any = useRef();
   const clasification2ChartRef: any = useRef();
   const clasification3ChartRef: any = useRef();

   const today = dayjs(new Date());
   const { user, companies } = useContext(UserContext);
   const { complaints, branches, loadingComplaints } = useContext(ComplaintContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [seed, setSeed] = useState(0);
   const [companyNameFilter, setCompanyNameFilter] = useState<string>("");
   const [complaintFilter, setComplaintFilter] = useState("todos");
   const [loadingCharts, setLoadingCharts] = useState<boolean>(true);
   const complaintsByStatus: any = useMemo(
      () => GetFilteredComplaints(complaints, complaintFilter),
      [complaints, complaintFilter]
   );
   const [startDate, setStartDate] = useState<dayjs.Dayjs>(today);
   const [finishDate, setFinishDate] = useState<dayjs.Dayjs>(today);
   const [selectedCompany, setSelectedCompany] = useState<any>();
   const complaintsByBranch = GetComplaintsByBranch({
      selectedCompany: selectedCompany,
      totalComplaints: complaintsByStatus,
      startDate: startDate,
      finishDate: finishDate,
   });
   const [barChartValues, setBarChartValues] = useState({
      //Used for graphics and and complaint count
      overall: {
         xAxis: [""],
         yAxis: [0],
      },
      type: {
         xAxis: [""],
         yAxis: [[0]],
      },
      anonymous: {
         xAxis: [""],
         yAxis: [[0]],
      },
      calification: {
         xAxis: [""],
         yAxis: [[0]],
      },
      clasification: {
         xAxis: [""],
         yAxis: [[0]],
      },
   });
   const [charts, setCharts] = useState({
      mainChart: {
         title: "Denuncias totales",
         chart: <Box />,
         change: true,
      },
      sidechart1: {
         title: "Tipos de denuncia",
         chart: <Box />,
         change: true,
      },
      sidechart2: {
         title: "Denuncias anónimas",
         chart: <Box />,
         change: true,
      },
      sidechart3: {
         title: "Denuncias por calificación",
         chart: <Box />,
         change: true,
      },
      sidechart4: {
         title: "Denuncias por clasificación",
         chart: <Box />,
         change: true,
      },
   });

   const [filteredBarChartValues, setFilteredBarChartValues] = useState({
      //Used for filtered graphics and and complaint count
      overall: {
         xAxis: [""],
         yAxis: [0],
      },
      type: {
         xAxis: [""],
         yAxis: [[0]],
      },
      anonymous: {
         xAxis: [""],
         yAxis: [[0]],
      },
      calification: {
         xAxis: [""],
         yAxis: [[0]],
      },
      clasification: {
         xAxis: [""],
         yAxis: [[0]],
      },
      clasification1: {
         xAxis: [""],
         yAxis: [[0]],
      },
      clasification2: {
         xAxis: [""],
         yAxis: [[0]],
      },
      clasification3: {
         xAxis: [""],
         yAxis: [[0]],
      },
   });
   const [filteredCharts, setFilteredCharts] = useState({
      mainChart: {
         title: "Denuncias totales",
         chart: <Box />,
         change: true,
      },
      sidechart1: {
         title: "Tipos de denuncia",
         chart: <Box />,
         change: true,
      },
      sidechart2: {
         title: "Denuncias anónimas",
         chart: <Box />,
         change: true,
      },
      sidechart3: {
         title: "Denuncias por calificación",
         chart: <Box />,
         change: true,
      },
      sidechart4: {
         title: "Denuncias por clasificación",
         chart: <Box />,
         change: true,
      },
      mainClasChart1: {
         title: "Comisión derechos humanos",
         chart: <Box />,
         change: true,
      },
      sideClasChart1: {
         title: "Violación derechos humanos",
         chart: <Box />,
         change: true,
      },
      sideClasChart2: {
         title: "Violación políticas y directrices",
         chart: <Box />,
         change: true,
      },
   });

   const [filteredcomplaints, setFilteredcomplaints] = useState([
      {
         companyBranch: {
            name: "",
            company: {
               name: "",
            },
         },
         offenderDetails: "",
         complaintType: "",
         complainterType: "",
         clasification: "",
         category: "",
         date: new Date(),
         status: "",
      },
   ]);
   const [filteredcomplaintsByCompany, setFilteredcomplaintsByCompany] = useState([
      {
         companyBranch: {
            name: "",
            company: {
               name: "",
            },
         },
         offenderDetails: "",
         complaintType: "",
         complainterType: "",
         clasification: "",
         category: "",
         date: new Date(),
         status: "",
      },
   ]);

   const statusFilter = ["en proceso", "en valoración", "resuelto", "improcedente", "todos"];

   function handleCreateColors(labels) {
      const colors: string[] = [];
      //eslint-disable-next-line
      for (const _i of labels) {
         let color = "#";
         for (let j = 0; j < 6; j++) {
            color += Math.floor(Math.random() * 10).toString();
         }
         colors.push(color);
      }
      return colors;
   }

   function violationClas(clasification: string) {
      if (clasification.includes("comisión") || clasification.includes("comision")) return "comisión";
      if (clasification.includes("violacion derechos") || clasification.includes("violación de derechos"))
         return "violación de derechos";
      return "violación de políticas";
   }
   function categoryClas(clasification) {
      switch (clasification) {
         case "comisión":
            return categoryItems.a;
         case "violación de derechos":
            return categoryItems.b;
         default:
            return categoryItems.c;
      }
   }

   const getFilteredClasificationCharts = (eValues: any, clasification) => {
      const complaintNumbers: number[][] = []; //bidimensional
      const categories = categoryClas(clasification);
      const complaint = eValues.filter(
         (newComplaint) =>
            newComplaint.complaint.clasification &&
            violationClas(newComplaint.complaint.clasification) === clasification
      );

      for (const element of categories) {
         let i = 0;
         const count: number[] = [];
         count.push(0);
         const categoryComplaint = complaint.filter((complaint) => complaint.complaint.category === element);
         for (const element of branches) {
            if (element.company._id === selectedCompany?._id) {
               const branchComplaint = categoryComplaint.filter(
                  (newComplaint) => newComplaint.branch._id === element._id
               );
               count[i] = branchComplaint.length;
               i++;
            }
         }
         complaintNumbers.push(count);
      }
      return complaintNumbers;
   };

   const handleCreateSpecificReport = () => {
      if (filteredcomplaintsByCompany.length > 0) {
         const element1 = fOverallChartRef.current.canvas.toDataURL("image:png");
         const element2 = fTypeChartRef.current.canvas.toDataURL("image:png");
         const element3 = fAnonymousChartRef.current.canvas.toDataURL("image:png");
         const element4 = fCalificationChartRef.current.canvas.toDataURL("image:png");
         const element5 = clasification1ChartRef.current.canvas.toDataURL("image:png");
         const element6 = clasification2ChartRef.current.canvas.toDataURL("image:png");
         const element7 = clasification3ChartRef.current.canvas.toDataURL("image:png");
         const dd = specificStatisticReportTemplate2({
            clientName: `${user.firstName} ${user.lastName}`,
            startDate: startDate.toDate(),
            finishDate: finishDate.toDate(),
            selectedBranch: selectedCompany,
            overallBarChart: element1,
            typeBarChart: element2,
            anonymousBarChart: element3,
            calificationBarChart: element4,
            clasification1BarChart: element5,
            clasification2BarChart: element6,
            clasification3BarChart: element7,
            companiesFound: companies,
            branchesFound: branches.filter((branch) => branch.company?._id === selectedCompany?._id),
            totalComplaints: filteredcomplaintsByCompany,
            barChartValues: filteredBarChartValues,
         });
         pdfMake.createPdf(dd, null).open();
      } else {
         showSnackBar("Datos insuficientes para generar documento", true);
      }
   };
   const handleCreateReport = () => {
      if (filteredcomplaints.length > 0) {
         const element1 = overallChartRef.current.canvas.toDataURL("image:png");
         const element2 = typeChartRef.current.canvas.toDataURL("image:png");
         const element3 = anonymousChartRef.current.canvas.toDataURL("image:png");
         const element4 = calificationChartRef.current.canvas.toDataURL("image:png");
         const element5 = clasificationChartRef.current.canvas.toDataURL("image:png");
         const dd = specificStatisticReportTemplate2({
            clientName: `${user.firstName} ${user.lastName}`,
            startDate: startDate.toDate(),
            finishDate: finishDate.toDate(),
            overallBarChart: element1,
            typeBarChart: element2,
            anonymousBarChart: element3,
            calificationBarChart: element4,
            clasificationBarChart: element5,
            companiesFound: UseGetCompaniesNames(companies),
            totalComplaints: filteredcomplaints,
            barChartValues: barChartValues,
         });
         pdfMake.createPdf(dd).open();
      } else {
         showSnackBar("Datos insuficientes para generar documento", true);
      }
   };

   //MAIN CHARTS
   useEffect(() => {
      if (loadingComplaints) return;
      const complaintCount: any = [];
      const complaintNumbers: number[] = []; //unidimensional
      const complaintTypeNumbers: number[][] = [[], []]; //bidimensional
      const complaintAnonymousNumbers: number[][] = [[], []]; //bidimensional
      const complaintCalificationNumbers: number[][] = [[], []]; //bidimensional
      const complaintClasificationNumbers: number[][] = [[], [], []]; //bidimensional

      const companiesNames = UseGetCompaniesNames(companies);
      companiesNames.forEach((i) => {
         let companyCount = 0;
         const count: number[] = [0, 0, 0, 0];
         const count2: number[] = [0, 0, 0, 0];
         let count3 = 0;
         const prefilteredComplaints = complaintsByStatus.filter((e) => {
            return (
               (complaintFilter === "todos" || complaintFilter === e.complaint.status) &&
               getCompanyByBranch(companies, e.branch?.company._id || e.company._id).person_details.comercialName ===
                  i &&
               ((dayjs(new Date(e.complaint.createdAt)) >= startDate &&
                  dayjs(new Date(e.complaint.createdAt)) <= finishDate) ||
                  getDateFromISODate(startDate.toDate()) === getDateFromISODate(finishDate.toDate()))
            );
         });
         prefilteredComplaints.forEach((e) => {
            complaintCount.push(e);
            companyCount++;
            e.complaint.category !== "Quejas" ? count[0]++ : count2[0]++;
            e.complaint.complaintType === "anonima" ? count[1]++ : count2[1]++;
            if (
               e.complaint.status === "en proceso" ||
               e.complaint.status === "en valoración" ||
               e.complaint.status === "resuelto"
            )
               count[2]++;
            else if (e.complaint.status === "improcedente") count2[2]++;
            if (e.complaint.clasification) {
               switch (violationClas(e.complaint.clasification)) {
                  case "comisión":
                     count[3]++;
                     break;
                  case "violación de derechos":
                     count2[3]++;
                     break;
                  default:
                     count3 -= -1;
                     break;
               }
            }
         });
         complaintNumbers.push(companyCount);
         complaintTypeNumbers[0].push(count[0]);
         complaintTypeNumbers[1].push(count2[0]);
         complaintAnonymousNumbers[0].push(count[1]);
         complaintAnonymousNumbers[1].push(count2[1]);
         complaintCalificationNumbers[0].push(count[2]);
         complaintCalificationNumbers[1].push(count2[2]);
         complaintClasificationNumbers[0].push(count[3]);
         complaintClasificationNumbers[1].push(count2[3]);
         complaintClasificationNumbers[2].push(count3);
      });
      setFilteredcomplaints(complaintCount);
      setBarChartValues({
         ...barChartValues,
         overall: {
            xAxis: companiesNames,
            yAxis: complaintNumbers,
         },
         type: {
            xAxis: companiesNames,
            yAxis: complaintTypeNumbers,
         },
         anonymous: {
            xAxis: companiesNames,
            yAxis: complaintAnonymousNumbers,
         },
         calification: {
            xAxis: companiesNames,
            yAxis: complaintCalificationNumbers,
         },
         clasification: {
            xAxis: companiesNames,
            yAxis: complaintClasificationNumbers,
         },
      });
      setCharts({
         ...charts,
         mainChart: {
            title: "Denuncias totales",
            chart: UseCreateCharts({
               scores: complaintNumbers,
               labels: companiesNames,
               colors: handleCreateColors(companiesNames),
               chartRef: overallChartRef,
            }),
            change: true,
         },
         sidechart1: {
            title: "Tipos de denuncia",
            chart: UseCreateCharts({
               type: "bar",
               scores: complaintTypeNumbers,
               labels: companiesNames,
               labelNames: ["Denuncias", "Quejas"],
               colors: handleCreateColors(companiesNames),
               chartRef: typeChartRef,
            }),
            change: true,
         },
         sidechart2: {
            title: "Denuncias anónimas",
            chart: UseCreateCharts({
               type: "bar",
               scores: complaintAnonymousNumbers,
               labels: companiesNames,
               labelNames: ["Denuncias", "Quejas"],
               colors: handleCreateColors(companiesNames),
               chartRef: anonymousChartRef,
            }),
            change: true,
         },
         sidechart3: {
            title: "Denuncias por calificación",
            chart: UseCreateCharts({
               type: "bar",
               scores: complaintCalificationNumbers,
               labels: companiesNames,
               labelNames: ["Procedente", "Improcedente"],
               colors: handleCreateColors(companiesNames),
               chartRef: calificationChartRef,
            }),
            change: true,
         },
         sidechart4: {
            title: "Denuncias por clasificación",
            chart: UseCreateCharts({
               type: "stack",
               scores: complaintClasificationNumbers,
               labels: companiesNames,
               labelNames: [
                  "Comisión derechos humanos",
                  "Violación derechos humanos",
                  "violación políticas y directrices",
               ],
               colors: handleCreateColors(["", "", ""]),
               chartRef: clasificationChartRef,
            }),
            change: true,
         },
      });
      setLoadingCharts(false);
   }, [complaintsByStatus, loadingComplaints]);

   //SECONDARY CHARTS
   useEffect(() => {
      if (loadingComplaints) return;
      const complaintCount: any = [];
      const complaintNumbers: number[] = []; //unidimensional
      const complaintTypeNumbers: number[][] = [[], []]; //bidimensional
      const complaintAnonymousNumbers: number[][] = [[], []]; //bidimensional
      const complaintCalificationNumbers: number[][] = [[], []]; //bidimensional
      const complaintClasificationNumbers: number[][] = [[], [], []]; //bidimensional
      const companies: string[] = [];
      for (const branch of branches) {
         if (branch.company._id === selectedCompany?._id) companies.push(branch.name);
      }
      branches.forEach((i) => {
         let companyCount = 0;
         const count: number[] = [0, 0, 0, 0];
         const count2: number[] = [0, 0, 0, 0];
         let count3 = 0;
         if (i.company._id === selectedCompany?._id) {
            const prefilteredComplaints = complaintsByStatus.filter(
               (e) =>
                  (complaintFilter === "todos" || complaintFilter === e.complaint.status) &&
                  e.branch?._id === i._id &&
                  ((dayjs(new Date(e.complaint.createdAt)) >= startDate &&
                     dayjs(new Date(e.complaint.createdAt)) <= finishDate) ||
                     getDateFromISODate(startDate.toDate()) === getDateFromISODate(finishDate.toDate()))
            );
            prefilteredComplaints.forEach((e) => {
               complaintCount.push(e);
               companyCount++;
               e.complaint.category !== "Quejas" ? count[0]++ : count2[0]++;
               e.complaint.complaintType === "anonima" ? count[1]++ : count2[1]++;
               if (
                  e.complaint.status === "en proceso" ||
                  e.complaint.status === "en valoración" ||
                  e.complaint.status === "resuelto"
               ) {
                  count[2]++;
               } else if (e.complaint.status === "improcedente") {
                  count2[2]++;
               }
               if (e.complaint.clasification) {
                  switch (violationClas(e.complaint.clasification)) {
                     case "comisión":
                        count[3]++;
                        break;
                     case "violación de derechos":
                        count2[3]++;
                        break;
                     default:
                        count3 -= -1;
                        break;
                  }
               }
            });
            complaintNumbers.push(companyCount);
            complaintTypeNumbers[0].push(count[0]);
            complaintTypeNumbers[1].push(count2[0]);
            complaintAnonymousNumbers[0].push(count[1]);
            complaintAnonymousNumbers[1].push(count2[1]);
            complaintCalificationNumbers[0].push(count[2]);
            complaintCalificationNumbers[1].push(count2[2]);
            complaintClasificationNumbers[0].push(count[3]);
            complaintClasificationNumbers[1].push(count2[3]);
            complaintClasificationNumbers[2].push(count3);
         }
      });
      const complaintClasificationType1Numbers = getFilteredClasificationCharts(complaintCount, "comisión");
      const complaintClasificationType2Numbers = getFilteredClasificationCharts(
         complaintCount,
         "violación de derechos"
      );
      const complaintClasificationType3Numbers = getFilteredClasificationCharts(
         complaintCount,
         "violación de políticas"
      );
      setFilteredcomplaintsByCompany(complaintCount);
      setFilteredBarChartValues({
         ...filteredBarChartValues,
         overall: {
            xAxis: companies,
            yAxis: complaintNumbers,
         },
         type: {
            xAxis: companies,
            yAxis: complaintTypeNumbers,
         },
         anonymous: {
            xAxis: companies,
            yAxis: complaintAnonymousNumbers,
         },
         calification: {
            xAxis: companies,
            yAxis: complaintCalificationNumbers,
         },
         clasification: {
            xAxis: companies,
            yAxis: complaintClasificationNumbers,
         },
         clasification1: {
            xAxis: companies,
            yAxis: complaintClasificationType1Numbers,
         },
         clasification2: {
            xAxis: companies,
            yAxis: complaintClasificationType2Numbers,
         },
         clasification3: {
            xAxis: companies,
            yAxis: complaintClasificationType3Numbers,
         },
      });
      setFilteredCharts({
         ...filteredCharts,
         mainChart: {
            title: "Denuncias totales",
            chart: UseCreateCharts({
               scores: complaintNumbers,
               labels: companies,
               colors: handleCreateColors(companies),
               chartRef: fOverallChartRef,
            }),
            change: true,
         },
         sidechart1: {
            title: "Tipos de denuncia",
            chart: UseCreateCharts({
               type: "bar",
               scores: complaintTypeNumbers,
               labels: companies,
               labelNames: ["Denuncias", "Quejas"],
               colors: handleCreateColors(companies),
               chartRef: fTypeChartRef,
            }),
            change: true,
         },
         sidechart2: {
            title: "Denuncias anónimas",
            chart: UseCreateCharts({
               type: "bar",
               scores: complaintAnonymousNumbers,
               labels: companies,
               labelNames: ["Anónimo", "No anónimo"],
               colors: handleCreateColors(companies),
               chartRef: fAnonymousChartRef,
            }),
            change: true,
         },
         sidechart3: {
            title: "Denuncias por calificación",
            chart: UseCreateCharts({
               type: "bar",
               scores: complaintCalificationNumbers,
               labels: companies,
               labelNames: ["Procedente", "Improcedente"],
               colors: handleCreateColors(companies),
               chartRef: fCalificationChartRef,
            }),
            change: true,
         },
         sidechart4: {
            title: "Denuncias por clasificación",
            chart: UseCreateCharts({
               type: "stack",
               scores: complaintClasificationNumbers,
               labels: companies,
               labelNames: [
                  "Comisión derechos humanos",
                  "Violación derechos humanos",
                  "violación políticas y directrices",
               ],
               colors: handleCreateColors(companies),
               chartRef: fClasificationChartRef,
            }),
            change: true,
         },
         mainClasChart1: {
            title: "comisión derechos humanos",
            chart: UseCreateCharts({
               type: companies.length !== 1 ? "line" : "bar",
               scores: complaintClasificationType1Numbers,
               labels: companies,
               labelNames: categoryItems.a,
               colors: handleCreateColors(categoryItems.a),
               chartRef: clasification1ChartRef,
            }),
            change: true,
         },
         sideClasChart1: {
            title: "violación derechos humanos",
            chart: UseCreateCharts({
               type: companies.length !== 1 ? "line" : "bar",
               scores: complaintClasificationType2Numbers,
               labels: companies,
               labelNames: categoryItems.b,
               colors: handleCreateColors(categoryItems.b),
               chartRef: clasification2ChartRef,
            }),
            change: true,
         },
         sideClasChart2: {
            title: "violación políticas y directrices",
            chart: UseCreateCharts({
               type: companies.length !== 1 ? "line" : "bar",
               scores: complaintClasificationType3Numbers,
               labels: companies,
               labelNames: categoryItems.c,
               colors: handleCreateColors(categoryItems.c),
               chartRef: clasification3ChartRef,
            }),
            change: true,
         },
      });
   }, [selectedCompany, complaintsByStatus, loadingComplaints]);

   function exchangeCharts(mainChart, selectedChart, chartName) {
      if (mainChart.change === selectedChart.change) {
         setCharts({
            ...charts,
            mainChart: {
               title: selectedChart.title,
               chart: selectedChart.chart,
               change: !mainChart.change,
            },
            [chartName]: {
               title: mainChart.title,
               chart: mainChart.chart,
               change: !selectedChart.change,
            },
         });
      }
   }
   function exchangeFilteredCharts(mainChart, selectedChart, mainChartName, chartName) {
      if (mainChart.change === selectedChart.change) {
         setFilteredCharts({
            ...filteredCharts,
            [mainChartName]: {
               title: selectedChart.title,
               chart: selectedChart.chart,
               change: !mainChart.change,
            },
            [chartName]: {
               title: mainChart.title,
               chart: mainChart.chart,
               change: !selectedChart.change,
            },
         });
      }
   }
   const chartContainer = (selectedChart, selectedChartName, mainChart, mainChartName?) => {
      return (
         <Box
            sx={{
               my: 0.5,
               px: 1,
               display: "flex",
               flex: 1,
               width: 250,
               bgcolor: "#ffffff",
               borderRadius: 3,
               boxShadow: 2,
            }}
         >
            <Grid
               container
               onClick={() =>
                  !mainChartName
                     ? exchangeCharts(mainChart, selectedChart, selectedChartName)
                     : exchangeFilteredCharts(mainChart, selectedChart, mainChartName, selectedChartName)
               }
            >
               <Grid item xs={12}>
                  <Typography color={"#2d4357"} variant="subtitle2" fontWeight={700} sx={{ px: 4, py: 1 }}>
                     {`${selectedChart.title}`}
                  </Typography>
               </Grid>
               <Grid item xs={12}>
                  {selectedChart.chart}
               </Grid>
            </Grid>
         </Box>
      );
   };
   if (loadingComplaints)
      return (
         <Box py={2}>
            <NotificationBar head="Reportes y estadísticas" content={<></>} />
            <Container maxWidth="xl">
               <Box width={"100%"} display={"flex"} justifyContent={"center"}>
                  <CircularProgress size={26} />
               </Box>
            </Container>
         </Box>
      );
   return (
      <Box sx={{ py: 2 }}>
         <NotificationBar head="Reportes y estadísticas" content={<></>} />
         {!loadingCharts ? (
            <Container maxWidth="xl">
               <>
                  <Typography variant="h5" fontWeight={"700"} sx={{ p: 2, color: "#2d4357" }}>
                     Información estadística general de tus empresas
                  </Typography>
                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: { xs: "column-reverse", lg: "row" },
                        justifyContent: "space-between",
                     }}
                  >
                     <FormControl sx={{ maxWidth: "250px", mx: 1, px: 3 }} fullWidth>
                        <InputLabel size="small" id="demo-simple-select-label">
                           Estatus
                        </InputLabel>
                        <Select
                           labelId="demo-simple-select-label"
                           id="demo-simple-select"
                           value={complaintFilter}
                           onChange={(event: any) => setComplaintFilter(event.target.value)}
                           label="Estatus"
                           size="small"
                           variant="standard"
                        >
                           {statusFilter.map((e) => {
                              return (
                                 <MenuItem key={e} value={e}>
                                    {capitalizeFirstLetter(e)}
                                 </MenuItem>
                              );
                           })}
                        </Select>
                     </FormControl>
                     <Box sx={{ px: 2 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                           <DatePicker
                              maxDate={finishDate}
                              label={"Fecha de inicio"}
                              format="DD/MM/YYYY"
                              value={startDate}
                              onChange={(event: any) => setStartDate(dayjs(event.$d))}
                              slotProps={{ textField: { variant: "standard", sx: { px: 2 } } }}
                           />
                           <DatePicker
                              minDate={startDate}
                              label={"Fecha de fin"}
                              format="DD/MM/YYYY"
                              value={finishDate}
                              onChange={(event: any) => {
                                 setFinishDate(dayjs(event.$d));
                              }}
                              slotProps={{ textField: { variant: "standard", sx: { px: 2 } } }}
                           />
                        </LocalizationProvider>
                     </Box>
                  </Box>
                  {complaintsByStatus?.length > 0 ? (
                     <Box>
                        <Box sx={{ display: "flex", flexDirection: "row", maxHeight: "70vh", py: 4 }}>
                           <Box sx={sideChartsProps}>
                              {chartContainer(charts.sidechart1, "sidechart1", charts.mainChart)}
                              {chartContainer(charts.sidechart2, "sidechart2", charts.mainChart)}
                           </Box>
                           <Box sx={sideChartsProps}>
                              {chartContainer(charts.sidechart3, "sidechart3", charts.mainChart)}
                              {chartContainer(charts.sidechart4, "sidechart4", charts.mainChart)}
                           </Box>
                           <Box flex={3}>
                              <Box
                                 sx={{
                                    display: "flex",
                                    height: "100%",
                                    backgroundColor: "#ffffff",
                                    borderRadius: 3,
                                    px: 2,
                                    boxShadow: 2,
                                 }}
                              >
                                 <Grid container>
                                    <Grid item xs={12}>
                                       <Typography
                                          color={"#2d4357"}
                                          variant="h6"
                                          fontWeight={700}
                                          sx={{ px: 4, py: 1 }}
                                       >
                                          {`${charts.mainChart.title}`}
                                       </Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ py: 2 }}>
                                       {charts.mainChart.chart}
                                    </Grid>
                                 </Grid>
                              </Box>
                           </Box>
                        </Box>
                        <Box
                           sx={{
                              display: "flex",
                              justifyContent: "flex-end",
                              px: 2,
                              py: { xs: 2, lg: 0 },
                           }}
                        >
                           <Button
                              onClick={handleCreateReport}
                              sx={{
                                 backgroundColor: "#162c44",
                                 color: "#162c44",
                                 "&:hover": { backgroundColor: "#162c44" },
                              }}
                           >
                              <InsertDriveFileIcon sx={{ color: "#ffffff", mr: 1 }} />
                              <Typography color={"#ffffff"}>Generar reporte general</Typography>
                           </Button>
                        </Box>
                        <Box>
                           <Typography variant="h5" fontWeight={"700"} sx={{ p: 2, color: "#2d4357" }}>
                              Información estadística específica por empresa
                           </Typography>
                           <Box
                              sx={{
                                 display: "flex",
                                 flexDirection: "row",
                                 justifyContent: "space-between",
                                 py: 1,
                                 px: 7,
                              }}
                           >
                              <Typography variant="h6" fontWeight={"700"} sx={{ color: "#2d4357" }}>
                                 {"Selecciona una empresa"}
                              </Typography>
                              <TextField
                                 label={"Buscar"}
                                 variant="outlined"
                                 size="small"
                                 value={companyNameFilter}
                                 InputProps={{
                                    endAdornment: (
                                       <InputAdornment position="end">
                                          <SearchIcon />
                                       </InputAdornment>
                                    ),
                                 }}
                                 onChange={(e) => {
                                    setCompanyNameFilter(e.target.value);
                                    setSeed(seed + 1);
                                 }}
                              />
                           </Box>
                           <Box width={"100%"} justifyContent={"center"} mx={"15%"}>
                              <Box
                                 sx={{
                                    maxWidth: "70%",
                                    display: "flex",
                                    justifyContent: "center",
                                    py: 2,
                                 }}
                              >
                                 <CompaniesCarousel
                                    key={seed}
                                    selectedCompany={setSelectedCompany}
                                    filterName={companyNameFilter}
                                    orientation={false}
                                    maxComponentNumber={5}
                                 />
                              </Box>
                           </Box>
                           {selectedCompany ? (
                              (complaintsByBranch.length > 0 && (
                                 <>
                                    <Box
                                       sx={{
                                          width: "100%",
                                          display: "flex",
                                          justifyContent: "center",
                                       }}
                                    >
                                       <Box
                                          sx={{
                                             display: "flex",
                                             width: "60%",
                                             flexDirection: "column",
                                             backgroundColor: "#ffffff",
                                             p: 2,
                                             my: 2,
                                             borderRadius: 2,
                                             boxShadow: 1,
                                          }}
                                       >
                                          <Typography
                                             variant="h6"
                                             fontWeight={"bold "}
                                             textAlign={"left"}
                                             sx={{ px: 5, py: 1 }}
                                          >
                                             {selectedCompany.person_details.comercialName}
                                          </Typography>
                                          <CompaniesStatisticsTable complaintsByBranch={complaintsByBranch} />
                                       </Box>
                                    </Box>
                                    <Box sx={sideChartContainerProps}>
                                       <Box sx={sideChartsProps}>
                                          {chartContainer(
                                             filteredCharts.sidechart1,
                                             "sidechart1",
                                             filteredCharts.mainChart,
                                             "mainChart"
                                          )}
                                          {chartContainer(
                                             filteredCharts.sidechart2,
                                             "sidechart2",
                                             filteredCharts.mainChart,
                                             "mainChart"
                                          )}
                                       </Box>
                                       <Box sx={sideChartsProps}>
                                          {chartContainer(
                                             filteredCharts.sidechart3,
                                             "sidechart3",
                                             filteredCharts.mainChart,
                                             "mainChart"
                                          )}
                                          {chartContainer(
                                             filteredCharts.sidechart4,
                                             "sidechart4",
                                             filteredCharts.mainChart,
                                             "mainChart"
                                          )}
                                       </Box>
                                       <Box flex={3}>
                                          <Box
                                             sx={{
                                                display: "flex",
                                                height: "100%",
                                                backgroundColor: "#ffffff",
                                                borderRadius: 3,
                                                px: 3,
                                                boxShadow: 2,
                                             }}
                                          >
                                             <Grid container>
                                                <Grid item xs={12}>
                                                   <Typography
                                                      color={"#2d4357"}
                                                      variant="h6"
                                                      fontWeight={700}
                                                      sx={{ px: 4, py: 1 }}
                                                   >
                                                      {`${filteredCharts.mainChart.title}`}
                                                   </Typography>
                                                </Grid>
                                                <Grid item xs={12} sx={{ py: 2 }}>
                                                   {filteredCharts.mainChart.chart}
                                                </Grid>
                                             </Grid>
                                          </Box>
                                       </Box>
                                    </Box>
                                    <Typography variant="h5" fontWeight={"700"} sx={{ p: 2, color: "#2d4357" }}>
                                       Información estadística por categoría de la clasificación
                                    </Typography>
                                    <Box sx={sideChartContainerProps}>
                                       <Box
                                          sx={{
                                             alignItems: "center",
                                             flex: 1,
                                             display: "flex",
                                             flexDirection: "column",
                                             mx: 1,
                                          }}
                                       >
                                          {chartContainer(
                                             filteredCharts.sideClasChart1,
                                             "sideClasChart1",
                                             filteredCharts.mainClasChart1,
                                             "mainClasChart1"
                                          )}
                                          {chartContainer(
                                             filteredCharts.sideClasChart2,
                                             "sideClasChart2",
                                             filteredCharts.mainClasChart1,
                                             "mainClasChart1"
                                          )}
                                       </Box>
                                       <Box flex={2}>
                                          <Box
                                             sx={{
                                                display: "flex",
                                                backgroundColor: "#ffffff",
                                                borderRadius: 3,
                                                px: 2,
                                                boxShadow: 2,
                                                minHeight: "100%",
                                             }}
                                          >
                                             <Grid container>
                                                <Grid item xs={12}>
                                                   <Typography
                                                      color={"#2d4357"}
                                                      variant="h6"
                                                      fontWeight={700}
                                                      sx={{ px: 4, py: 1 }}
                                                   >
                                                      {`${filteredCharts.mainClasChart1.title}`}
                                                   </Typography>
                                                </Grid>
                                                <Grid item xs={12}>
                                                   {filteredCharts.mainClasChart1.chart}
                                                </Grid>
                                             </Grid>
                                          </Box>
                                       </Box>
                                    </Box>
                                    <Box
                                       sx={{
                                          display: "flex",
                                          justifyContent: "flex-end",
                                          py: 2,
                                       }}
                                    >
                                       <Button
                                          onClick={handleCreateSpecificReport}
                                          sx={{
                                             backgroundColor: "#162c44",
                                             color: "white",
                                             "&:hover": { backgroundColor: "#162c44" },
                                          }}
                                       >
                                          <InsertDriveFileIcon sx={{ mr: 1 }} />
                                          <Typography>Generar reporte específico</Typography>
                                       </Button>
                                    </Box>
                                 </>
                              )) || (
                                 <Box
                                    sx={{
                                       display: "flex",
                                       flexDirection: "column",
                                       justifyContent: "center",
                                       textAlign: "center",
                                    }}
                                 >
                                    Favor de seleccionar una empresa que contenga denuncias o quejas.
                                 </Box>
                              )
                           ) : (
                              <></>
                           )}
                        </Box>
                     </Box>
                  ) : (
                     <Box display={"flex"} justifyContent={"center"}>
                        <Typography variant="h6" textAlign={"center"} sx={{ py: 5 }}>
                           No existen denuncias interpuestas
                        </Typography>
                     </Box>
                  )}
               </>
            </Container>
         ) : (
            <Box display={"flex"} width={"100%"} justifyContent={"center"}>
               <CircularProgress size={24} />
            </Box>
         )}
      </Box>
   );
};
const sideChartContainerProps = { display: "flex", flexDirection: "row", maxHeight: "70vh", py: 2 };
const sideChartsProps = { display: "flex", flex: 1, flexDirection: "column", mx: 1 };
