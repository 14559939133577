import { Typography, Box, Avatar } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { ChatContext } from "../../../../context/governanceContext/ChatContext";
import { IMessage } from "../../../../types/governance.types";
import { useNavigate } from "react-router-dom";
import { getFilteredUsers } from "../../../../lib/usersBEClient";

const useFetchMessageData = (message: IMessage) => {
   const [author, setAuthorName] = useState(null);
   useEffect(() => {
      const fetchAuthorName = async () => {
         try {
            const author = await getFilteredUsers([message.authorId]);
            setAuthorName(author[0]);
         } catch (error) {
            console.error(error);
         }
      };
      fetchAuthorName();
   }, []);
   return { author };
};

interface IFoundMessage {
   index: number;
   message: IMessage;
}

export const FoundMessage = (props: IFoundMessage) => {
   const { conversations, currentChat, setCurrentChat, isLoadingMessages, setSelectedMessage } =
      useContext(ChatContext);

   const navigate = useNavigate();

   const { author } = useFetchMessageData(props.message);

   const date = new Date(props.message.createdAt);

   let isAMorPM = "";

   const formatHour = () => {
      let returnHour = "";
      const hours = date.getHours().toString();
      if (Number(hours) > 12) {
         isAMorPM = "PM";
         returnHour = (Number(hours) % 12 || 12).toString();
      } else {
         isAMorPM = "AM";
         returnHour = hours;
      }
      if (returnHour.length === 1) return 0 + returnHour;
      else return returnHour;
   };

   const horas = formatHour();
   const minutos = date.getUTCMinutes().toString().padStart(2, "0");

   const horaFormateada = `${horas}:${minutos}`;

   function stringAvatar(name: string, lastName: string) {
      return {
         sx: {
            bgcolor: "#162c44",
            fontWeight: "bold",
            fontSize: 16,
         },
         children: `${name.toUpperCase().slice(0, 1)}${lastName.toUpperCase().slice(0, 1)}`,
      };
   }

   return (
      <Box
         sx={{ ":hover": { cursor: "pointer" } }}
         key={props.index}
         onClick={() => {
            if (isLoadingMessages && currentChat) return;
            setCurrentChat(conversations.find((conversation) => conversation._id === props.message.conversation));
            navigate(`/gobierno-corporativo/chat/${props.message.conversation}`);
            setSelectedMessage(props.message);
         }}
      >
         {author?.firstName !== undefined && (
            <Box
               sx={{
                  display: "flex",
                  p: 1,
                  px: 2.5,
                  alignItems: "center",
               }}
            >
               <Avatar {...stringAvatar(author?.firstName, author?.lastName)} />
               <Box sx={{ width: "45%", mx: 2 }}>
                  <Typography sx={{ fontSize: 14, fontWeight: 700, mb: 0.25 }}>
                     {author?.firstName + " " + author?.lastName}
                  </Typography>
                  <Typography sx={{ fontSize: 13, color: "#9E9E9E" }}>{props.message.content}</Typography>
               </Box>
               <Typography
                  sx={{
                     color: "#9E9E9E",
                     alignSelf: "flex-start",
                     fontWeight: 600,
                     fontSize: 12,
                     width: "30%",
                     textAlign: "right",
                     mr: 0.5,
                  }}
               >
                  {horaFormateada + " " + isAMorPM}
               </Typography>
            </Box>
         )}
      </Box>
   );
};
