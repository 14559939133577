import { Box, Grid, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { GovernanceSessionContext } from "../../../../context/governanceContext/governanceSessionContext";
import { InputCheckBox } from "../../../Inputs/InputCheckBox";
import { getContrastYIQ } from "../../../../const/globalConst";
import { useFormikContext } from "formik";

export const GetShareholderCommittee = () => {
   const {
      membersWithCharge,
      governingBody,
      setAttendedPercentage,
      setGoverningBody,
      usersOnline,
      valuesFromBill,
      setValuesFromBill,
   } = useContext(GovernanceSessionContext);
   const { setFieldValue } = useFormikContext();
   const [totalCompanyActions, setTotalCompanyActions] = useState(0);
   const [totalCompanyCapital, setTotalCompanyCapital] = useState(0);
   const [companyPercentageAttendance, setCompanyPercentageAttendance] = useState(null);

   let interUsers = useMemo(() => {
      return membersWithCharge?.filter(
         (member) =>
            member.firstName &&
            !member.memberCharge.some(
               (charge) =>
                  (charge.toLowerCase().includes("coordinador") && member.series.length === 0) ||
                  (charge.toLowerCase().includes("implementacion") && member.series.length === 0)
            )
      );
   }, [membersWithCharge]);

   const attendancePercentage = useMemo(() => {
      if (usersOnline?.length === 0) return;
      return usersOnline.length * interUsers.length === 0 ? 0 : 100 / (usersOnline.length * interUsers.length);
   }, [usersOnline]);

   useEffect(() => {
      setAttendedPercentage(attendancePercentage);
   }, [attendancePercentage]);

   //GET ACTIONS BY USER AND SERIES
   const relevantActions = useMemo(() => {
      return governingBody?.users
         .flatMap((member) => member.actions.filter((action) => action.title && action.sharesAmount > 0))
         .map((action) => ({
            ...action,
            serie: governingBody.series.find((serie) => serie.title === action.title),
         }));
   }, [governingBody]);

   const totalCompanyCapitalBySerie = useMemo(() => {
      if (!relevantActions) return;
      let totalCompanyCapitalBySerieLet = {};
      let totalCompanyActionsLet = 0;
      //SET THE TOTAL OF ACTIONS BY SERIES IN GOVERNING BODY
      relevantActions.forEach((action: any) => {
         if (action.serie) {
            totalCompanyCapitalBySerieLet[action.serie.title] =
               (totalCompanyCapitalBySerieLet[action.serie.title]
                  ? totalCompanyCapitalBySerieLet[action.serie.title]
                  : 0) + action.sharesAmount;
            totalCompanyActionsLet += action.sharesAmount;
         }
      });
      setTotalCompanyActions(totalCompanyActionsLet);
      return totalCompanyCapitalBySerieLet;
   }, [relevantActions]);

   useEffect(() => {
      if (!governingBody) return;
      let governingBodyTemp = { ...governingBody };
      setGoverningBody({
         ...governingBody,
         totalCompanyCapitalBySerie: totalCompanyCapitalBySerie || 0,
      });
      governingBodyTemp["totalCompanyCapitalBySerie"] = totalCompanyCapitalBySerie || 0;
      //SET THE TOTAL OF ACTION MULTIPLIED BY THE NOMINAL VALUE
      let totalCompanyCapitalLet = 0;
      for (const serie of governingBody.series) {
         totalCompanyCapitalLet +=
            (governingBodyTemp.totalCompanyCapitalBySerie[serie.title]
               ? governingBodyTemp.totalCompanyCapitalBySerie[serie.title]
               : 0) * serie.nominalValue;
      }
      setTotalCompanyCapital(totalCompanyCapitalLet);
   }, [totalCompanyCapitalBySerie]);

   useEffect(() => {
      if (!governingBody) return;
      setGoverningBody({
         ...governingBody,
         totalCompanyCapital: totalCompanyCapital,
         totalCompanyActions: totalCompanyActions,
      });
      // governingBody["totalCompanyCapital"] = totalCompanyCapital;
      // governingBody["totalCompanyActions"] = totalCompanyActions;
   }, [totalCompanyCapital, totalCompanyActions]);

   useEffect(() => {
      if (!governingBody) return;
      const membersList = [];
      let companyPercentageAttendanceLet = 0;
      for (const userInGov of governingBody.users) {
         const user: any = userInGov;
         const initialValue = 0;

         //GET THE USER TOTAL ACTIONS
         const totalActions = userInGov.actions.reduce(
            (accumulator, action) => (accumulator + action.sharesAmount ? action.sharesAmount : 0),
            initialValue
         );

         let socialCapital = 0;

         //GET THE USER TOTAL ACTIONS BY THE NOMINAL VALUE
         for (const serie of governingBody.series) {
            const userSerie = userInGov.actions.find((action) => action.title === serie.title);
            if (userSerie) socialCapital += userSerie.sharesAmount * serie.nominalValue;
         }

         let actionPercentage = 0;
         //GET THE PERCENTAGE OF ACTIONS
         if (totalCompanyActions !== 0) actionPercentage = (totalActions * 100) / totalCompanyActions;

         //PUSH THE USER TO THE FORMATTED ARRAY OF USERS
         membersList.push({
            id: user.user,
            percentage: Math.round(actionPercentage * 10) / 10,
            totalActions: totalActions,
            socialCapital: socialCapital,
            series: userInGov.actions,
         });
      }
      const initialValueSocialCapital = 0;
      const membersOnline = membersList.filter((memberOnline) =>
         usersOnline.some((userOnline) => userOnline === memberOnline.id)
      );
      companyPercentageAttendanceLet = membersOnline.reduce(
         (accumulator, memberSocialCapital) => accumulator + memberSocialCapital.socialCapital,
         initialValueSocialCapital
      );
      companyPercentageAttendanceLet =
         governingBody.totalCompanyCapital !== 0
            ? ((companyPercentageAttendanceLet || 0) * 100) / governingBody.totalCompanyCapital
            : 0;
      setCompanyPercentageAttendance(Math.round(companyPercentageAttendanceLet * 100) / 100);
   }, [totalCompanyActions, governingBody, usersOnline]);

   useEffect(() => {
      const onlineUsersInBill = Object.keys(valuesFromBill)
         .filter((key) => key.includes("officialID"))
         .reduce((obj, key) => {
            obj[key] = valuesFromBill[key];
            return obj;
         }, {});
      for (const onlineUser of Object.keys(onlineUsersInBill)) {
         const userId = onlineUser.split("ID")[1];
         setFieldValue(onlineUser, usersOnline.includes(userId));
         const tempBill = valuesFromBill;
         tempBill[onlineUser] = usersOnline.includes(userId);
         setValuesFromBill(tempBill);
      }
   }, [usersOnline]);

   return { interUsers, companyPercentageAttendance };
};

export const ShareholderCommittee = () => {
   const { governingBody, session, colors, externalMemberUsers, isLoadingGoverningBody, membersWithCharge } =
      useContext(GovernanceSessionContext);
   const { companyPercentageAttendance, interUsers } = GetShareholderCommittee();

   if (isLoadingGoverningBody && !governingBody) return;

   return (
      <Stack>
         <Typography
            sx={{ color: getContrastYIQ(colors.primary), textAlign: "center", bgcolor: colors.primary, fontSize: 12 }}
         >
            CONVOCADOS A ASAMBLEA
         </Typography>
         <Grid container rowSpacing={0.2}>
            <Grid container item columnSpacing={0.2}>
               <Grid item xs={4.5}>
                  <Typography
                     sx={{
                        color: "#434343",
                        textAlign: "center",
                        bgcolor: "#EFEFEF",
                        fontSize: 11,
                        fontWeight: 800,
                        p: 0.3,
                     }}
                  >
                     ACCIONISTA
                  </Typography>
               </Grid>
               <Grid item xs={4.5}>
                  <Typography
                     sx={{
                        color: "#434343",
                        textAlign: "center",
                        bgcolor: "#EFEFEF",
                        fontSize: 11,
                        fontWeight: 800,
                        p: 0.3,
                     }}
                  >
                     PORCENTAJE DE ACCIONES
                  </Typography>
               </Grid>
               <Grid item xs={3}>
                  <Typography
                     sx={{
                        color: "#434343",
                        textAlign: "center",
                        bgcolor: "#EFEFEF",
                        fontSize: 11,
                        fontWeight: 800,
                        p: 0.3,
                     }}
                  >
                     {session.type === "Ordinaria" ? "DOCUMENTACIÓN" : "ID OFICIAL"}
                  </Typography>
               </Grid>
            </Grid>
            {interUsers.map((member, index) => (
               <Grid container item columnSpacing={0.2} sx={{}} key={"Committee" + index}>
                  <Grid item xs={4.5}>
                     <Typography
                        sx={{
                           color: "#434343",
                           textAlign: "center",
                           bgcolor: "#EFEFEF",
                           fontSize: 10,
                           p: 0.3,
                           height: "100%",
                        }}
                     >
                        {member.firstName && member.lastName && member.firstName + " " + member.lastName}
                     </Typography>
                  </Grid>
                  <Grid item xs={4.5}>
                     <Typography
                        sx={{
                           color: "#434343",
                           textAlign: "center",
                           bgcolor: "#EFEFEF",
                           fontSize: 10,
                           p: 0.3,
                           height: "100%",
                        }}
                     >
                        {(governingBody.totalCompanyCapital !== 0
                           ? (member?.totalCapital * 100 || 0) / governingBody.totalCompanyCapital
                           : 0
                        ).toFixed(2)}
                        %
                     </Typography>
                  </Grid>
                  <Grid item xs={3} sx={{ display: "flex", justifyContent: "center", alignItem: "center" }}>
                     <Box
                        sx={{
                           bgcolor: "#EFEFEF",
                           display: "flex",
                           justifyContent: "center",
                           alignItem: "center",
                           width: "100%",
                           py: 0.5,
                        }}
                     >
                        <InputCheckBox
                           id={"officialID" + member._id}
                           label={""}
                           name={"officialID" + member._id}
                           sx={{ p: 0, ml: 3, fontSize: 15 }}
                           disabled={true}
                           size="small"
                        />
                     </Box>
                  </Grid>
               </Grid>
            ))}
            <Grid container spacing={0.2} sx={{ borderTop: 2, borderColor: colors.primary, borderBottom: 1 }}>
               <Grid item xs={9} sx={{ bgcolor: colors.secondary }}>
                  <Typography
                     sx={{ textAlign: "right", fontSize: 10, p: 0.3, pr: 1, color: getContrastYIQ(colors.secondary) }}
                  >
                     {session.type === "Ordinaria" ? "QUORUM" : "% DE CAPITAL SOCIAL EN ASAMBLEA"}
                  </Typography>
               </Grid>
               <Grid item xs={3} sx={{ bgcolor: "#EFEFEF" }}>
                  <Typography sx={{ fontSize: 10, p: 0.3, pl: 1 }}>{companyPercentageAttendance} %</Typography>
               </Grid>
            </Grid>
         </Grid>

         {externalMemberUsers.length > 0 && (
            <Typography
               sx={{
                  color: getContrastYIQ(colors.secondary),
                  textAlign: "center",
                  bgcolor: colors.secondary,
                  fontSize: 12,
                  mt: 2,
               }}
            >
               INVITADOS
            </Typography>
         )}
         <Grid container>
            {[
               ...membersWithCharge.filter(
                  (user) =>
                     user.memberCharge.some((charge) => {
                        return charge.toLowerCase().includes("coordinador");
                     }) && user.series.length === 0
               ),
               ...externalMemberUsers,
            ].map((member: any, index) => (
               <Grid item container spacing={0.2} pb={0.2} key={"externalUser" + index}>
                  <Grid item xs={6}>
                     <Typography
                        sx={{
                           color: "#434343",
                           textAlign: "center",
                           bgcolor: "#EFEFEF",
                           fontSize: 12,
                           p: 0.3,
                        }}
                     >
                        {member.firstName ? `${member.firstName} ${member.lastName}` : member?.name || member?.email}
                     </Typography>
                  </Grid>
                  <Grid item xs={6}>
                     <Typography
                        sx={{
                           color: "#434343",
                           textAlign: "center",
                           bgcolor: "#EFEFEF",
                           fontSize: 12,
                           p: 0.3,
                        }}
                     >
                        {member.memberCharge
                           ? "Coordinador de gobierno corporativo"
                           : `Usuario Externo - ${member.specialty || "Sin expecialidad"}`}
                     </Typography>
                  </Grid>
               </Grid>
            ))}
         </Grid>
      </Stack>
   );
};
