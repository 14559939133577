import { ClientTypeModal } from "../../../ClientTypeModal";
import { Box, Button, CircularProgress, Stack, TextField } from "@mui/material";
import { CreateFolder, ModifyFoldersById, useGetResourceSelected } from "../../../../lib/usersBEClient";
import { useContext, useState } from "react";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { UpdateFilesByUserId } from "../../../../lib/gobCorpBEClient";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";
import { useParams } from "react-router-dom";

interface CreateFolderModalProps {
   create: boolean;
   openModal: boolean;
   setOpenModal: Function;
   selected: string;
   value?: string;
   setName: Function;
   reloadFiles: boolean;
   setReloadFiles: Function;
   main?: boolean;
   setOpenMenu?: Function;
}

export const CreateFolderModal = ({
   create,
   openModal,
   setOpenModal,
   selected,
   value,
   setName,
   reloadFiles,
   setReloadFiles,
   setOpenMenu,
}: CreateFolderModalProps) => {
   const { id, companyId } = useParams();
   const { showSnackBar } = useContext(SnackBarContext);
   const { personalSpace, setRefetch, refetch } = useContext(GovernanceContext);
   const { mutate } = CreateFolder();
   const { mutate: addFolderToSpace } = UpdateFilesByUserId(personalSpace ? personalSpace._id : "");
   const resourceId = useGetResourceSelected();
   const { mutate: modifyFolderName } = ModifyFoldersById(selected, resourceId, companyId);
   const [isLoadingCreate, setIsLoadingCreate] = useState(false);

   const handleCreateFolder = () => {
      setIsLoadingCreate(true);
      mutate(
         {
            name: value,
            parent: selected ? selected : undefined,
            governance: id,
            companyId,
         },
         {
            onError: (error: any) => {
               showSnackBar("Error al agregar carpeta.", true);
            },
            onSuccess: async (data) => {
               if (!selected) {
                  addFolderToSpace(
                     {
                        files: [data._id],
                     },
                     {
                        onError: (error: any) => {
                           showSnackBar("Error al agregar carpeta.", true);
                        },
                        onSuccess: async () => {
                           showSnackBar("La carpeta se creo correctamente.", false);
                           if (setOpenMenu) {
                              setOpenMenu();
                           } else {
                              setRefetch(!refetch);
                           }
                           setIsLoadingCreate(false);
                           handleClose();
                        },
                     }
                  );
               } else {
                  showSnackBar("La carpeta se creo correctamente.", false);
                  if (setOpenMenu) {
                     setOpenMenu();
                  } else {
                     setReloadFiles(!reloadFiles);
                  }
                  setIsLoadingCreate(false);

                  handleClose();
               }
            },
         }
      );
   };

   const handleModifyName = () => {
      setIsLoadingCreate(true);

      modifyFolderName(
         {
            name: value,
         },
         {
            onError: (error: any) => {
               showSnackBar("Error al modificar la carpeta.", true);
            },
            onSuccess: async (data) => {
               showSnackBar("La carpeta fue modificada correctamente.", false);
               setRefetch(!refetch);
               setReloadFiles(!reloadFiles);
               setIsLoadingCreate(false);
               setOpenModal(false);
            },
         }
      );
   };

   const handleClose = () => {
      setOpenModal(false);
      setName("");
   };

   return (
      <ClientTypeModal
         body={
            <Stack sx={{ p: 2, minWidth: 300 }} rowGap={2}>
               <TextField
                  fullWidth
                  label="Nombre de la carpeta"
                  onChange={(e) => setName(e.target.value)}
                  value={value}
               />
               <Box sx={{ display: "flex", columnGap: 1 }}>
                  <Button variant="contained" fullWidth onClick={handleClose}>
                     Cancelar
                  </Button>
                  <Button
                     variant="contained"
                     fullWidth
                     onClick={() => (create ? handleCreateFolder() : handleModifyName())}
                  >
                     {isLoadingCreate ? <CircularProgress sx={{ color: "white" }} /> : create ? "Crear" : "Modificar"}
                  </Button>
               </Box>
            </Stack>
         }
         onConfirm={() => {}}
         open={openModal}
         setOpen={setOpenModal}
         title={create ? "Crear carpeta" : "Modificar nombre de la carpeta"}
         blue
      />
   );
};
