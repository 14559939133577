import { Box } from "@mui/material";
import { Filters } from "./SubComponents/Filters";
import { Folders } from "./SubComponents/Folders";

export const MyFilesComponentNew = () => {
   return (
      <Box sx={{ flex: 3.5 }}>
         <Filters />
         <Folders />
      </Box>
   );
};
