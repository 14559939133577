import { Avatar, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { getUrlS3 } from "../../../../lib/usersBEClient";

function stringAvatar(name: string, lastName: string) {
   return {
      sx: {
         bgcolor: "#162c44",
         fontWeight: "bold",
         fontSize: 16,
      },
      children: `${name.toUpperCase().slice(0, 1)}${lastName.toUpperCase().slice(0, 1)}`,
   };
}

interface IContacts {
   user;
}

export const Contacts = (props: IContacts) => {
   const [profilePic, setProfilePic] = useState("");

   useEffect(() => {
      const getPicture = async () => {
         const url = await getUrlS3("files-lecosy", { folder: `users/${props.user._id}` }, "profile.png");
         setProfilePic(url);
      };
      getPicture();
   }, [props.user]);

   return (
      <Stack
         direction={"row"}
         sx={{
            alignItems: "center",
            justifyContent: "left",
            display: "flex",
            bgcolor: "white",
            ":hover": { bgcolor: "#F4F9FD" },
            px: 2,
            mx: 1,
            py: 1,
            borderRadius: 2.5,
         }}
         spacing={2}
      >
         <Avatar src={profilePic} {...stringAvatar(props.user?.firstName, props.user?.lastName)} />
         <Typography sx={{ color: "#32465B" }} fontSize={14} fontWeight={700} noWrap>
            {props.user?.firstName + " " + props.user?.lastName}
         </Typography>
      </Stack>
   );
};
