import { Box, Divider, Stack, Tab, Tabs, Typography } from "@mui/material";
import { TabPanel } from "../../../screens/Mi Lecosy/ClientInfoScreenNew";
import CommitmentEvaluationChart from "./SubComponents/PerformanceGraphs/CommitmentsEvaluationChart";
import { CommitmentsGraph } from "./SubComponents/CommitmentsGraph";
import { useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useGetCommitmentTrimesterInfo } from "../../../hooks/gob-corp/governmentBody/useGetCommitmentTrimesterInfo";
import EffectivenessCommitmentsList from "./SubComponents/EffectivenessCommitmentsList";
import { useGetCommitmentsInfo } from "../../../hooks/gob-corp/governmentBody/useGetCommitmentsInfo";

interface EffectivenessSectionProps {
   chartRef?: any;
}

const EffectivenessSection = (props: EffectivenessSectionProps) => {
   const [selectedCommitment, setSelectedCommitment] = useState();
   const commiteeInfo = useGetCommitmentTrimesterInfo();
   const { commitmentsByGovernance } = useGetCommitmentsInfo({});
   const { chartRef } = props;
   return (
      <Box bgcolor={"white"} borderColor={"#B5B5B5"} borderRadius={3}>
         <Tabs value={"1"} textColor="primary">
            <Tab label="Evaluación de efectividad" value={"1"} />
         </Tabs>
         <Divider sx={{ borderBottomWidth: 2 }} />
         <TabPanel index={1} value={1}>
            <Stack direction={"row"} p={2} spacing={2} maxHeight={"400px"}>
               <Box flex={2}>
                  <CommitmentEvaluationChart setSelectedCommitment={setSelectedCommitment} commiteInfo={commiteeInfo} />
               </Box>
               <Box flex={4} alignSelf={"center"}>
                  {selectedCommitment ? (
                     <EffectivenessCommitmentsList selectedTrimester={selectedCommitment} />
                  ) : (
                     <Stack alignItems={"center"} width={"100%"}>
                        <InfoOutlinedIcon sx={{ color: "#4B5563", fontSize: "80px" }} />
                        <Typography textAlign={"center"} maxWidth={"300px"}>
                           Selecciona el periodo en la gráfica izquierda para visualizar la información
                        </Typography>
                     </Stack>
                  )}
               </Box>
               <Box flex={2}>
                  <CommitmentsGraph
                     chartRef={chartRef}
                     hasNoShadow
                     commitmentCount={commitmentsByGovernance.length}
                     filteredCommitments={commitmentsByGovernance}
                     isLoading={false}
                  />
               </Box>
            </Stack>
         </TabPanel>
      </Box>
   );
};

export default EffectivenessSection;
