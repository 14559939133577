import { Box, Button, CircularProgress, Divider, Paper, Stack, Typography } from "@mui/material";
import { LogsMap } from "./LogsMap";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../context/userContext";
import { getLogsByUserAndResourceAndFile } from "../../../../lib/usersBEClient";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";

const useGetLogsByFile = (id: string) => {
   const { user, selectedResources } = useContext(UserContext);
   const { companySelected } = useContext(GovernanceContext);
   const [logs, setLogs] = useState([]);
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      const fetchInfo = async () => {
         setIsLoading(true);
         const response = await getLogsByUserAndResourceAndFile(user.id, selectedResources._id, id, companySelected);
         setLogs(response);
         setIsLoading(false);
      };
      fetchInfo();
   }, [id, selectedResources]);

   return { logs, isLoading };
};

export const FilesLogs = ({ fileSelected, setOpenModal }) => {
   const { logs, isLoading } = useGetLogsByFile(fileSelected);
   if (isLoading) {
      return (
         <Paper sx={{ display: "flex", justifyContent: "center", py: 2, minWidth: 500 }}>
            <CircularProgress sx={{ color: "black" }} />
         </Paper>
      );
   }
   if (logs.length === 0) {
      return (
         <Paper sx={{ display: "flex", justifyContent: "center", p: 2, minWidth: 500 }}>
            <Typography>Sin registros de actividad en el archivo.</Typography>
         </Paper>
      );
   }
   return (
      <Paper
         sx={{
            p: 2,
            minWidth: 500,
            display: "flex",
            alignItems: "flex-start",
            flexDirection: "column",
            rowGap: 2,
            pt: 4,
         }}
      >
         <Stack sx={{ maxHeight: 400, overflowY: "auto" }}>
            {logs.map((log, i) => {
               return (
                  <Box sx={{ width: "100%", px: 2 }}>
                     <Box>
                        <LogsMap log={log} key={i} />
                     </Box>
                     <Divider />
                  </Box>
               );
            })}
         </Stack>
         <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", py: 1 }}>
            <Button variant="contained" onClick={() => setOpenModal(false)}>
               Cerrar
            </Button>
         </Box>
      </Paper>
   );
};
