import { Box } from "@mui/material";
import { BarChart } from "../../../../charts/barChartSingleColor";
import { GraphLabels } from "../GraphLabels";

export const ConstantActualization = ({ answers }) => {
   const value1 = answers.filter((answer) => answer.rangeAnswer === 1 || answer.rangeAnswer === 2).length;
   const value2 = answers.filter((answer) => answer.rangeAnswer === 3 || answer.rangeAnswer === 4).length;
   const value3 = answers.filter((answer) => answer.rangeAnswer === 5 || answer.rangeAnswer === 6).length;
   return (
      <>
         <Box sx={{ height: 240, pt: 2 }}>
            <BarChart
               scores={[value1, value2, value3]}
               labels={["Deficiente", "Regular", "Sobresaliente"]}
               colors={["#A3A3A3", "#868FA0", "#162c44"]}
               chartRef={undefined}
            />
         </Box>
         <Box sx={{ pt: 0.5 }}>
            <GraphLabels />
         </Box>
      </>
   );
};
