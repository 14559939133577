import { Box, Grid, Stack, Typography } from "@mui/material";
import { useContext } from "react";
import { ExternalGovernanceSessionContext } from "../../../../context/governanceContext/externalSessionContext";
import { InputCheckBox } from "../../../Inputs/InputCheckBox";

export const BillOrderDayExternal = () => {
   const { affairsArray, colors, session, groupCompaniesInSession } = useContext(ExternalGovernanceSessionContext);

   if (!affairsArray) return;

   if (!affairsArray) return;
   return (
      <Stack>
         <Typography sx={{ textAlign: "center", fontSize: 12, fontWeight: 600 }}>ORDEN DEL DÍA</Typography>
         <Grid container rowSpacing={0.2} sx={{ borderTop: 2, borderColor: colors.primary }}>
            {affairsArray.map((order, key) => (
               <Grid container item key={"orderDay-" + order.orderId}>
                  <Grid item xs={1} sx={{ bgcolor: "#EFEFEF", height: "100%" }}>
                     <Typography sx={{ fontSize: 12, p: 0.5, pl: 2, fontWeight: 600, textAlign: "right" }}>
                        {key + 1 + "."}
                     </Typography>
                  </Grid>
                  <Grid
                     item
                     xs={session.group ? 3 : 5}
                     sx={{ bgcolor: "#EFEFEF", height: "100%", borderLeft: 1, borderColor: "white" }}
                  >
                     <Typography sx={{ fontSize: 12, bgcolor: "#EFEFEF", p: 0.5, pl: 2 }}>{order.title}</Typography>
                  </Grid>
                  <Grid item xs={5} sx={{ bgcolor: "#EFEFEF", height: "100%", borderLeft: 1, borderColor: "white" }}>
                     <Typography sx={{ fontSize: 12, bgcolor: "#EFEFEF", p: 0.5 }}>{order.description}</Typography>
                  </Grid>
                  {session.group && groupCompaniesInSession && (
                     <Grid item xs={2} sx={{ bgcolor: "#EFEFEF", height: "100%", borderLeft: 1, borderColor: "white" }}>
                        <Stack spacing={0.5} sx={{ alignItems: "center", justifyContent: "center", height: "100%" }}>
                           {order.companies.map((c) => {
                              const companyName =
                                 groupCompaniesInSession.find((com) => com._id === c)?.person_details?.comercialName ||
                                 "";
                              return (
                                 <Typography
                                    variant="caption"
                                    key={`selectedC-${c}`}
                                    sx={{
                                       bgcolor: "#f0f0f0",
                                       borderRadius: 2,
                                       px: 2,
                                       fontSize: 12,
                                       textAlign: "center",
                                    }}
                                 >
                                    {companyName.length > 15 ? `${companyName.slice(0, 15)}...` : companyName || ""}
                                 </Typography>
                              );
                           })}
                        </Stack>
                     </Grid>
                  )}

                  <Grid item xs={1}>
                     <Box
                        sx={{
                           bgcolor: "#EFEFEF",
                           display: "flex",
                           justifyContent: "center",
                           alignItem: "center",
                           width: "100%",
                           height: "100%",
                           borderLeft: 1,
                           borderColor: "white",
                        }}
                     >
                        <InputCheckBox
                           id={"orderDay-" + order.orderId}
                           label={""}
                           name={"orderDay-" + order.orderId}
                           sxformcontrol={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              mt: 0.3,
                              p: 0,
                           }}
                           sx={{
                              p: 0,
                           }}
                           size="small"
                           disabled={true}
                           disablemargin
                        />
                     </Box>
                  </Grid>
               </Grid>
            ))}
         </Grid>
      </Stack>
   );
};
