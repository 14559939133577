import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { UserContext } from "../../context/userContext";

type ProtectedRouteProps = {
   section: string;
   module?: boolean;
};

export const PrivateRoute = (props: ProtectedRouteProps) => {
   const { isLoadingUser, verifyAccess, isLoadingModules, userModules } = useContext(UserContext);
   if (
      !isLoadingUser &&
      !isLoadingModules &&
      userModules &&
      userModules.length > 0 &&
      !verifyAccess(props.section, props.module)
   ) {
      return <Navigate to={"/inicio"} replace />;
   }
   return <Outlet />;
};
