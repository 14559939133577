import { FiberManualRecord } from "@mui/icons-material";
import { Box, Divider, Grid, Typography } from "@mui/material";

export const TableGrid = ({ data, headers }: { data: any[]; headers?: any[] }) => {
   const getTypeColumn = ({ type, text, color }) => {
      switch (type) {
         case "Dot":
            return (
               <Grid item xs={6}>
                  <Box sx={{ display: "flex", columnGap: 1, alignItems: "center", pl: 2 }}>
                     <FiberManualRecord sx={{ color: color, fontSize: 18 }} />
                     <Typography align="center" variant="caption">
                        {text}
                     </Typography>
                  </Box>
               </Grid>
            );
         case "%":
            return (
               <Grid item xs={3}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                     <Typography align="center" fontWeight={600} variant="caption">
                        {text}%
                     </Typography>
                  </Box>
               </Grid>
            );
         default:
            return (
               <Grid item xs={3}>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                     <Typography align="center" fontWeight={600} variant="caption">
                        {text}
                     </Typography>
                  </Box>
               </Grid>
            );
      }
   };

   return (
      <Box>
         <Grid container spacing={2}>
            <Grid item xs={6}>
               <Typography align="center" sx={{ color: "#8a95a0", fontWeight: 600 }}>
                  {headers ? headers[0] : "Estado"}
               </Typography>
            </Grid>
            <Grid item xs={3}>
               <Typography align="center" sx={{ color: "#8a95a0", fontWeight: 600 }}>
                  {headers ? headers[1] : "Totales"}
               </Typography>
            </Grid>
            <Grid item xs={3}>
               <Typography align="center" sx={{ color: "#8a95a0", fontWeight: 600 }}>
                  {headers ? headers[2] : "%"}
               </Typography>
            </Grid>
         </Grid>
         <Divider sx={{ my: 1 }} />
         <Grid container spacing={1}>
            {data.map((column) => getTypeColumn(column))}
         </Grid>
      </Box>
   );
};
