import { Avatar, Box, Stack, Tooltip, Typography } from "@mui/material";
import { getDateAndTimeFromISODate } from "../../../../const/globalConst";

export const LogsMap = ({ log }) => {
   return (
      <Box sx={{ display: "flex", columnGap: 2, alignItems: "center", pb: 2 }}>
         <Box>
            <Avatar>
               <Typography>{log.details.user.firstName.charAt(0) + log.details.user.lastName.charAt(0)}</Typography>
            </Avatar>
         </Box>
         <Stack sx={{ maxWidth: 600 }}>
            <Tooltip title={log.details.user.firstName + " " + log.details.user.lastName + " - " + log.title}>
               <Typography noWrap>
                  {log.details.user.firstName + " " + log.details.user.lastName + " - " + log.title}
               </Typography>
            </Tooltip>
            <Typography>{getDateAndTimeFromISODate(log.createdAt)}</Typography>
         </Stack>
      </Box>
   );
};
