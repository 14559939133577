import { Box, Stack, Typography } from "@mui/material";
import UseCreateCharts from "../../../../../hooks/useCreateCharts";
import { GraphLabels } from "../GraphLabels";

export const ResultsGraph = ({
   answers1,
   answers2,
   answers3,
   answers4,
}: {
   answers1: any[];
   answers2: any[];
   answers3: any[];
   answers4?: any[];
}) => {
   return (
      <Box>
         <Box sx={{ height: 200 }}>
            {UseCreateCharts({
               type: "stack",
               scores: [
                  [
                     answers1.filter((answer) => answer.rangeAnswer === 1 || answer.rangeAnswer === 2).length,
                     answers2.filter((answer) => answer.rangeAnswer === 1 || answer.rangeAnswer === 2).length,
                     answers3.filter((answer) => answer.rangeAnswer === 1 || answer.rangeAnswer === 2).length,
                     answers4
                        ? answers4.filter((answer) => answer.rangeAnswer === 1 || answer.rangeAnswer === 2).length
                        : undefined,
                  ],
                  [
                     answers1.filter((answer) => answer.rangeAnswer === 3 || answer.rangeAnswer === 4).length,
                     answers2.filter((answer) => answer.rangeAnswer === 3 || answer.rangeAnswer === 4).length,
                     answers3.filter((answer) => answer.rangeAnswer === 3 || answer.rangeAnswer === 4).length,
                     answers4
                        ? answers4.filter((answer) => answer.rangeAnswer === 3 || answer.rangeAnswer === 4).length
                        : undefined,
                  ],
                  [
                     answers1.filter((answer) => answer.rangeAnswer === 5 || answer.rangeAnswer === 6).length,
                     answers2.filter((answer) => answer.rangeAnswer === 5 || answer.rangeAnswer === 6).length,
                     answers3.filter((answer) => answer.rangeAnswer === 5 || answer.rangeAnswer === 6).length,
                     answers4
                        ? answers4.filter((answer) => answer.rangeAnswer === 5 || answer.rangeAnswer === 6).length
                        : undefined,
                  ],
               ],
               // labels: [
               //    "Participación activa en reuniones y actividades",
               //    "Fomento de comunicación",
               //    "Colaboración y trabajo en equipo",
               // ],
               labels: ["", "", "", ""],
               labelNames: ["Deficiente", "Regular", "Sobresaliente"],
               colors: ["#A3A3A3", "#868FA0", "#152C44"],
               chartRef: undefined,
               borderRadius: true,
               barThickness: 40,
               removeMaxValue: true,
            })}
         </Box>
         <Stack direction={"row"} columnGap={4} pb={0.5}>
            <Typography align="center" variant="caption">
               Participación activa en reuniones y actividades
            </Typography>
            <Typography align="center" variant="caption">
               Fomento de comunicación
            </Typography>
            <Typography align="center" variant="caption">
               Colaboración y trabajo en equipo
            </Typography>
            {answers4 ? (
               <Typography align="center" variant="caption">
                  Calificación de transparencia
               </Typography>
            ) : null}
         </Stack>
         <GraphLabels />
      </Box>
   );
};
