import { LineChart } from "../components/charts/LineChart";
import { FilteredBarChart } from "../components/charts/barChart";
import { DoughnutChart } from "../components/charts/doughnutChart";
import { PieChart } from "../components/charts/pieChart";
import { StackBarChart } from "../components/charts/stackBarChart";
import _ from "lodash";

interface UseCreateChartsProps {
   scores: number[] | number[][];
   labels: string[];
   labelNames?: string[];
   chartRef: any;
   colors: string[];
   type?: "line" | "bar" | "stack" | "doughnut";
   maxY?: number;
   percentage?: boolean;
   gridColor?: string;
   pointColor?: boolean;
   tension?: number;
   onClick?: Function;
   borderRadius?: boolean;
   barThickness?: number;
   pointRadius?: number;
   customTooltip?: boolean;
   intersection?: boolean;
   tooltipColorBox?: boolean;
   xTitle?: string;
   yTitle?: string;
   fraction?: boolean;
   textCenterProp?: any;
   key?: any;
   disableTicks?: boolean;
   indexAxis?: string;
   removeXLables?: boolean;
   removeMaxValue?: boolean;
   borderRadiusValue?: number;
   cutout?: number;
}

const getOrCreateTooltip = (chart) => {
   let tooltipEl = chart.canvas.parentNode.querySelector("div");

   if (!tooltipEl) {
      tooltipEl = document.createElement("div");
      tooltipEl.style.background = "rgba(255, 255, 255, 1)";
      tooltipEl.style.boxShadow = " 5px 5px 10px rgba(0,0,0,0.15)";
      tooltipEl.style.border = "1px solid rgba(217, 217, 217)";
      tooltipEl.style.borderRadius = "10px";
      tooltipEl.style.color = "#162C44";
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = "none";
      tooltipEl.style.position = "absolute";
      tooltipEl.style.marginLeft = "130px";
      tooltipEl.style.zIndex = "10";
      tooltipEl.style.transform = "translate(-50%, 0)";
      tooltipEl.style.transition = "all .1s ease";

      const table = document.createElement("table");
      table.style.margin = "0px";

      tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
   }

   return tooltipEl;
};

export const externalTooltipHandler = (context, coloredBox) => {
   // Tooltip Element
   const { chart, tooltip } = context;
   const tooltipEl = getOrCreateTooltip(chart);
   // Hide if no tooltip
   if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
   }

   // Set Text
   if (tooltip.body) {
      const titleLines = tooltip.title || [];
      const bodyLines = tooltip.body.map((b) => b.lines);

      const tableHead = document.createElement("thead");

      titleLines.forEach((title) => {
         const tr = document.createElement("tr");
         tr.style.borderWidth = "0";

         const th = document.createElement("th");
         th.style.borderWidth = "0";
         const text = document.createTextNode(title);

         th.appendChild(text);
         tr.appendChild(th);
         tableHead.appendChild(tr);
      });

      const tableBody = document.createElement("tbody");
      bodyLines.forEach((body, i) => {
         const colors = tooltip.labelColors[i];

         const span = document.createElement("span");
         span.style.background = colors.backgroundColor;
         span.style.borderColor = colors.borderColor;
         span.style.borderWidth = "2px";
         span.style.marginRight = "10px";
         span.style.height = "10px";
         span.style.width = "10px";
         span.style.display = "inline-block";

         const tr = document.createElement("tr");
         tr.style.backgroundColor = "inherit";
         tr.style.borderWidth = "0";

         const td = document.createElement("td");
         td.style.borderWidth = "0";

         const text = document.createTextNode(body);

         if (coloredBox) td.appendChild(span);
         td.appendChild(text);
         tr.appendChild(td);
         tableBody.appendChild(tr);
      });

      const tableRoot = tooltipEl.querySelector("table");

      // Remove old children
      while (tableRoot.firstChild) {
         tableRoot.firstChild.remove();
      }

      // Add new children
      tableRoot.appendChild(tableHead);
      tableRoot.appendChild(tableBody);
   }

   const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

   // Display, position, and set styles for font
   tooltipEl.style.opacity = 1;
   tooltipEl.style.left = positionX + tooltip.caretX + "px";
   tooltipEl.style.top = positionY + tooltip.caretY + "px";
   tooltipEl.style.font = tooltip.options.bodyFont.string;
   tooltipEl.style.padding = tooltip.options.padding + "px " + tooltip.options.padding + "px";
};

const UseCreateCharts = (props: UseCreateChartsProps) => {
   props.customTooltip = props.customTooltip ?? true;
   props.tooltipColorBox = props.tooltipColorBox ?? true;
   props.intersection = props.intersection ?? false;
   props.fraction = props.fraction ?? false;

   switch (props.type) {
      case "stack":
         return <StackBarChart {...props} />;
      case "bar":
         return <FilteredBarChart {...props} />;
      case "line":
         return <LineChart {...props} />;
      case "doughnut":
         return <DoughnutChart {...props} />;
      default:
         return <PieChart {...props} />;
   }
};

export default UseCreateCharts;
