import { Box, Typography } from "@mui/material";
import UseCreateCharts from "../../../../hooks/useCreateCharts";
import { useContext, useMemo } from "react";
import { UserContext } from "../../../../context/userContext";

export const YearlyPerformanceGraph = ({ sessions, commitments, chartRef }) => {
   const { user } = useContext(UserContext);

   const graphData = useMemo(() => {
      let trimester = {
         "1": 0,
         "2": 0,
         "3": 0,
         "4": 0,
      };
      if (sessions.length > 0) {
         for (const iterator of sessions) {
            const date = new Date(iterator.date);
            const month = date.getMonth();
            const trimesterData = Math.floor(month / 3) + 1;
            for (const commit of iterator.commitments) {
               if (commit.users.includes(user.id) && commit.status === "COMPLETE") {
                  trimester[trimesterData] = trimester[trimesterData] + 1;
               }
            }
         }
      }
      return trimester;
   }, [sessions, user.id]);

   return (
      <Box sx={{ flex: 2, p: 1, boxShadow: 1, borderRadius: 2, px: 2 }}>
         <Typography fontWeight={600} sx={{ pb: 1 }}>
            Resumen anual de desempeño
         </Typography>

         <Box sx={{ minHeight: 300 }}>
            {UseCreateCharts({
               pointColor: true,
               type: "line",
               chartRef: chartRef,
               colors: ["#162c44", "#5E77F9"],
               labels: ["Inicio", "Trimestre 1", "Trimestre 2", "Trimestre 3", "Trimestre 4"],
               labelNames: ["Acuerdos completados"],
               tension: 0.3,
               scores: [
                  [
                     0,
                     graphData[1] / commitments,
                     graphData[2] / commitments,
                     graphData[3] / commitments,
                     graphData[4] / commitments,
                  ],
               ],
               maxY: 1,
               percentage: true,
            })}
         </Box>
      </Box>
   );
};
