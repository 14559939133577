import { useContext, useEffect, useState } from "react";
import { GetUserSession } from "../lib/usersBEClient";
import { SessionContext } from "../context/sessionContext";

const VerifyUserSession = () => {
   const { expireSession } = useContext(SessionContext);
   const [validSession, setValidSession] = useState<boolean>(true);

   useEffect(() => {
      const getUserSession = async () => {
         try {
            await GetUserSession();
         } catch (err) {
            expireSession();
            setValidSession(false);
         }
      };
      getUserSession();
   }, [expireSession]);

   return validSession;
};

export default VerifyUserSession;
