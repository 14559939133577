import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.fonts = {
   Roboto: {
      normal: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-Regular.ttf",
      bold: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-Bold.ttf",
      italic: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-Italic.ttf",
      boldItalic: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-BoldItalic.ttf",
   },
};

pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default pdfMake;
