import { Box } from "@mui/material";
import { MyFilesComponentNew } from "../../components/Gobierno corporativo/MyFilesScreen/MyFilesComponentNew";
import { useState } from "react";
import { CreateFolderModal } from "../../components/Gobierno corporativo/MyFilesScreen/SubComponents/CreateFolderModal";
import FoldersSidebar from "../../components/Gobierno corporativo/MyFilesScreen/FoldersSidebar";

export const MyFilesScreen = () => {
   const [openModal, setOpenModal] = useState(false);
   const [name, setName] = useState("");

   return (
      <>
         <Box sx={{ display: "flex", columnGap: 4 }}>
            <FoldersSidebar />
            <MyFilesComponentNew />
         </Box>
         <CreateFolderModal
            create={false}
            openModal={openModal}
            setOpenModal={setOpenModal}
            selected={null}
            value={name}
            setName={setName}
            reloadFiles={false}
            setReloadFiles={() => {}}
            main={true}
         />
      </>
   );
};
