import { Box, Button, CircularProgress, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { ConfirmationModal } from "../../../ConfirmationModal";
import { useContext, useState } from "react";
import {
   CopyFile,
   CopyFolder,
   DeleteFile,
   DeleteFolder,
   ModifyFile,
   ModifyFoldersById,
   useGetResourceSelected,
} from "../../../../lib/usersBEClient";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { ClientTypeModal } from "../../../ClientTypeModal";
import { Form, Formik, FormikProps } from "formik";
import { InputTextField } from "../../../Inputs/InputTextField";
import { ListOfTexFields } from "../../Inputs/ListOfTexFields";
import { ShareFile } from "./ShareFile";
import { MoveFile } from "./MoveFile";
import { FilesContext } from "../../../../context/governanceContext/filesContext";
import { FilesLogs } from "./FilesLogs";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";
import { useParams } from "react-router-dom";
import { CreateFolderModal } from "./CreateFolderModal";

export const FileMenu = ({ anchorEl, open, setOpenModal, handleClose, selectedFile, reload, setReload }) => {
   const { id, companyId } = useParams();
   const { permissions } = useContext(FilesContext);
   const { companySelected } = useContext(GovernanceContext);
   const fileAccess = permissions(selectedFile ? selectedFile : null);
   const [openModalDelete, setOpenModalDelete] = useState(false);
   const [openModalCopy, setOpenModalCopy] = useState(false);
   const [openModifyModal, setOpenModifyModal] = useState(false);
   const [openShareModal, setOpenShareModal] = useState(false);
   const [openMoveModal, setOpenMoveModal] = useState(false);
   const [openActivity, setOpenActivity] = useState(false);
   const [openCreate, setOpenCreate] = useState(false);
   const resourceId = useGetResourceSelected();
   const [name, setName] = useState("");
   const { mutate, isLoading: isLoadingDelete } = DeleteFile(selectedFile ? selectedFile._id : "", companyId);
   const { mutate: deleteFolder, isLoading: isLoadingDeleteFolder } = DeleteFolder(
      selectedFile ? selectedFile._id : "",
      companyId
   );
   const { mutate: modifyFolder, isLoading: isLoadingModifyFolder } = ModifyFoldersById(
      selectedFile ? selectedFile._id : "",
      resourceId,
      companyId
   );
   const { mutate: copyFile, isLoading: isLoadingCopy } = CopyFile(
      selectedFile ? selectedFile._id : "",
      resourceId,
      id
   );
   const { mutate: copyFolder, isLoading: isLoadingCopyFolder } = CopyFolder(
      selectedFile ? selectedFile._id : "",
      resourceId
   );
   const { mutate: modifyFile, isLoading: isLoadingModify } = ModifyFile(
      selectedFile ? selectedFile._id : "",
      resourceId,
      companySelected,
      id
   );
   const { showSnackBar } = useContext(SnackBarContext);

   const handleSuccess = (message: string) => {
      showSnackBar(message, false);
      setReload(!reload);
      handleClose();
   };

   const handleDeleteFile = async () => {
      if (selectedFile.type === "carpeta") {
         deleteFolder(null, {
            onError: (error: any) => {
               showSnackBar("Error al eliminar carpeta.", true);
            },
            onSuccess: () => {
               handleSuccess("La carpeta se eliminó correctamente.");
               setOpenModalDelete(false);
            },
         });
      } else {
         mutate(null, {
            onError: (error: any) => {
               showSnackBar("Error al eliminar archivo.", true);
            },
            onSuccess: () => {
               handleSuccess("El archivo se eliminó correctamente.");
               setOpenModalDelete(false);
            },
         });
      }
   };

   const handleDCopyFile = async () => {
      if (selectedFile.type === "carpeta") {
         copyFolder(null, {
            onError: (error: any) => {
               showSnackBar("Error al carpeta archivo.", true);
            },
            onSuccess: () => {
               handleSuccess("El carpeta fue copiado correctamente.");
               setOpenModalCopy(false);
            },
         });
      } else {
         copyFile(null, {
            onError: (error: any) => {
               showSnackBar("Error al copiar archivo.", true);
            },
            onSuccess: () => {
               handleSuccess("El archivo fue copiado correctamente.");
               setOpenModalCopy(false);
            },
         });
      }
   };

   const handleModifyFile = async (values) => {
      if (selectedFile.type === "carpeta") {
         modifyFolder(
            {
               name: values.name,
            },
            {
               onError: (error: any) => {
                  showSnackBar("Error al modificar carpeta.", true);
               },
               onSuccess: () => {
                  handleSuccess("La carpeta fue modificado correctamente.");
                  setOpenModifyModal(false);
               },
            }
         );
      } else {
         const name = values.name + "." + selectedFile.type.split("/").pop();
         modifyFile(
            {
               name: name,
               metadata: values.order,
            },
            {
               onError: (error: any) => {
                  showSnackBar("Error al modificar archivo.", true);
               },
               onSuccess: () => {
                  handleSuccess("El archivo fue modificado correctamente.");
                  setOpenModifyModal(false);
               },
            }
         );
      }
   };

   return (
      <>
         {fileAccess >= 2 ? (
            <>
               <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                     "aria-labelledby": "basic-button",
                  }}
               >
                  {selectedFile && selectedFile.type === "carpeta" && fileAccess >= 2 ? (
                     <MenuItem onClick={() => setOpenCreate(true)}>Crear sub carpeta</MenuItem>
                  ) : null}
                  {fileAccess >= 2 ? <MenuItem onClick={() => setOpenModalCopy(true)}>Crear una copia</MenuItem> : null}
                  {fileAccess === 3 ? <MenuItem onClick={() => setOpenMoveModal(true)}>Mover a</MenuItem> : null}
                  {fileAccess === 3 ? <MenuItem onClick={() => setOpenShareModal(true)}>Compartir</MenuItem> : null}
                  {fileAccess >= 2 ? (
                     <MenuItem
                        onClick={() => {
                           setOpenModifyModal(true);
                        }}
                     >
                        Modificar
                     </MenuItem>
                  ) : null}
                  {fileAccess === 3 ? <MenuItem onClick={() => setOpenModalDelete(true)}>Eliminar</MenuItem> : null}
                  {fileAccess >= 2 ? <MenuItem onClick={() => setOpenActivity(true)}>Registros</MenuItem> : null}
               </Menu>
               <ConfirmationModal
                  open={openModalDelete}
                  setOpen={setOpenModalDelete}
                  onConfirm={() => handleDeleteFile()}
                  body={
                     <Box sx={{ pt: 2 }}>
                        <Typography align="center">
                           {selectedFile && selectedFile.type === "carpeta"
                              ? "¿Está seguro que desea eliminar la carpeta?"
                              : "¿Está seguro que desea eliminar el archivo?"}
                        </Typography>
                     </Box>
                  }
                  isLoading={isLoadingDelete || isLoadingDeleteFolder}
                  title={selectedFile && selectedFile.type === "carpeta" ? "Eliminar carpeta" : "Eliminar archivo"}
               />
               <ConfirmationModal
                  open={openModalCopy}
                  setOpen={setOpenModalCopy}
                  onConfirm={() => handleDCopyFile()}
                  body={
                     <Box sx={{ pt: 2 }}>
                        <Typography align="center">
                           {selectedFile && selectedFile.type === "carpeta"
                              ? "¿Está seguro que desea copiar la carpeta?"
                              : "¿Está seguro que desea copiar el archivo?"}
                        </Typography>
                     </Box>
                  }
                  isLoading={isLoadingCopy || isLoadingCopyFolder}
                  title={
                     selectedFile && selectedFile.type === "carpeta"
                        ? `Copiar carpeta ${selectedFile.originalName}`
                        : "Copiar archivo"
                  }
               />
               <ConfirmationModal
                  open={openModalCopy}
                  setOpen={setOpenModalCopy}
                  onConfirm={() => handleDCopyFile()}
                  body={
                     <Box sx={{ pt: 2 }}>
                        <Typography align="center">
                           {selectedFile && selectedFile.type === "carpeta"
                              ? "¿Está seguro que desea copiar la carpeta?"
                              : "¿Está seguro que desea copiar el archivo?"}
                        </Typography>
                     </Box>
                  }
                  isLoading={isLoadingCopy || isLoadingCopyFolder}
                  title={
                     selectedFile && selectedFile.type === "carpeta"
                        ? `Copiar carpeta ${selectedFile.originalName}`
                        : "Copiar archivo"
                  }
               />
               <ClientTypeModal
                  body={
                     <Box>
                        {openMoveModal && (
                           <MoveFile
                              selectedFile={selectedFile}
                              setOpenModal={setOpenMoveModal}
                              reload={reload}
                              setReload={setReload}
                              handleClose={handleClose}
                           />
                        )}
                     </Box>
                  }
                  onConfirm={() => {}}
                  open={openMoveModal}
                  setOpen={setOpenMoveModal}
                  title={
                     <Box sx={{ py: 1 }}>
                        <Typography variant="h5" fontWeight={600}>
                           Mover {selectedFile && selectedFile.type === "carpeta" ? "carpeta" : "archivo"} -{" "}
                           {openMoveModal ? selectedFile.originalName : null}
                        </Typography>

                        <Typography>Selecciona la nueva ubicación del archivo</Typography>
                     </Box>
                  }
                  blue
               />

               <ClientTypeModal
                  body={
                     <>
                        {openShareModal && (
                           <ShareFile
                              fileId={selectedFile._id}
                              setOpen={setOpenShareModal}
                              reload={reload}
                              setReload={setReload}
                              folder={selectedFile && selectedFile.type === "carpeta"}
                              handleClose={handleClose}
                           />
                        )}
                     </>
                  }
                  onConfirm={() => {}}
                  open={openShareModal}
                  setOpen={setOpenShareModal}
                  title={
                     selectedFile && selectedFile.type === "carpeta"
                        ? `Compartir carpeta ${selectedFile.originalName}`
                        : "Compartir archivo"
                  }
                  blue
               />
               <ClientTypeModal
                  body={
                     <>{openActivity && <FilesLogs fileSelected={selectedFile._id} setOpenModal={setOpenActivity} />}</>
                  }
                  onConfirm={() => {}}
                  open={openActivity}
                  setOpen={setOpenActivity}
                  title={`Actividad reciente`}
                  blue
               />
               <CreateFolderModal
                  create={true}
                  openModal={openCreate}
                  setOpenModal={setOpenCreate}
                  selected={selectedFile._id}
                  value={name}
                  setName={setName}
                  reloadFiles={reload}
                  setReloadFiles={setReload}
                  setOpenMenu={handleClose}
               />
               <ClientTypeModal
                  body={
                     <>
                        {openModifyModal && (
                           <Box sx={{ p: 2, minWidth: 400 }}>
                              <Formik
                                 initialValues={{
                                    order: selectedFile && selectedFile.type === "carpeta" ? [] : selectedFile.metadata,
                                    name: selectedFile.originalName,
                                 }}
                                 onSubmit={handleModifyFile}
                              >
                                 {(formProps: FormikProps<any>) => {
                                    return (
                                       <Form>
                                          <Stack rowGap={1}>
                                             <InputTextField
                                                label="Nombre"
                                                id="name"
                                                name="name"
                                                type="text"
                                                fullWidth
                                             />
                                             {selectedFile && selectedFile.type === "carpeta" ? null : (
                                                <>
                                                   <Typography variant="body2" fontWeight={600}>
                                                      Metadatos
                                                   </Typography>
                                                   <ListOfTexFields
                                                      listOfData={formProps.values.order}
                                                      setListOfData={formProps.setFieldValue}
                                                      inputProps={{
                                                         id: "order",
                                                         name: "order",
                                                      }}
                                                   />
                                                </>
                                             )}
                                             <Box sx={{ display: "flex", columnGap: 1 }}>
                                                <Button
                                                   variant="contained"
                                                   fullWidth
                                                   onClick={() => setOpenModifyModal(false)}
                                                >
                                                   Cancelar
                                                </Button>
                                                <Button variant="contained" fullWidth type="submit">
                                                   {isLoadingModify || isLoadingModifyFolder ? (
                                                      <CircularProgress />
                                                   ) : (
                                                      "Modificar"
                                                   )}
                                                </Button>
                                             </Box>
                                          </Stack>
                                       </Form>
                                    );
                                 }}
                              </Formik>
                           </Box>
                        )}
                     </>
                  }
                  onConfirm={() => {}}
                  open={openModifyModal}
                  setOpen={setOpenModifyModal}
                  title={selectedFile && selectedFile.type === "carpeta" ? "Modificar carpeta" : "Modificar archivo"}
                  blue
               />
            </>
         ) : (
            <Menu open={open} anchorEl={anchorEl} onClose={handleClose}>
               <Typography sx={{ p: 1 }}>Sin permisos para modificar el archivo</Typography>
            </Menu>
         )}
      </>
   );
};
