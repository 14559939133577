import { Box, Button, Stack, Typography } from "@mui/material";
import { InputTextField } from "../../Inputs/InputTextField";
import { Form, Formik } from "formik";
import { useContext, useEffect } from "react";
import { createVoteQuestion } from "../../../lib/validations/inputSchemas";
import { CreateVotes, UpdateVotes } from "../../../lib/gobCorpBEClient";
import { GovernanceSessionContext } from "../../../context/governanceContext/governanceSessionContext";

const GetCreateVoteModalData = (vote, refreshHandler) => {
   useEffect(() => {
      if (vote) {
         refreshHandler(vote);
      }
   }, []);

   return {};
};

export const CreateVoteModal = ({
   votationOnCourse,
   setVotationOnCourse,
   setOpenModal,
   setVote,
   vote,
   refreshHandler,
}) => {
   const { mutate } = CreateVotes();
   const { session, socket, membersWithCharge, usersOnline, additionalVotesSeed, additionalVotes } =
      useContext(GovernanceSessionContext);
   const membersWithVotes = membersWithCharge.filter((member) => {
      return !member.memberCharge.some((charge) => charge.toLowerCase().includes("coordinador"));
   });
   const membersWithVotesOnline = membersWithVotes.filter((member) => usersOnline.some((user) => user === member._id));

   useEffect(() => {
      if (membersWithVotesOnline.length - (vote ? vote.agreed + vote.disagreed + vote.nullVote : 0) === 0) {
         setVotationOnCourse(false);
      }
   }, [membersWithVotesOnline]);

   useEffect(() => {
      if (!vote) return;
      const foundVoteIndex = additionalVotes.findIndex((v) => v._id === vote._id);
      setVote(additionalVotes[foundVoteIndex]);
   }, [additionalVotesSeed]);

   GetCreateVoteModalData(vote, refreshHandler);
   const { mutate: CancelVote } = UpdateVotes();

   const createVoteFunction = async (values) => {
      mutate(values, {
         onError: (error: any) => {
            console.error(error);
         },
         onSuccess: (data) => {
            setVote(data);
            socket.emit("send-votation", { data, sessionId: session._id });
            setVotationOnCourse(true);
         },
      });
   };

   const handleSubmit = (values) => {
      if (!vote)
         createVoteFunction({
            ...values,
            session: session._id,
            agreed: 0,
            disagreed: 0,
            nullVote: 0,
            totalVotes: 0,
            totalVotesUsed: 0,
            positiveVotes: 0,
            votes: session.usersRegistry
               .map((user) => {
                  if (!user.attended) return;
                  const foundOnlineUser = membersWithVotes.find((voteUser) => voteUser._id === user.user);
                  if (foundOnlineUser)
                     return {
                        userId: foundOnlineUser._id,
                        userName: `${foundOnlineUser.firstName} ${foundOnlineUser.lastName}`,
                        vote: "pending",
                     };
               })
               .filter((v) => v),
         });
      else {
         socket.emit("end-vote", session._id);
         setVotationOnCourse(false);
         setVote(null);
         setOpenModal(false);
      }
   };

   const handleCancelVotation = () => {
      if (!vote) {
         return setOpenModal(false);
      }
      CancelVote(
         { votationId: vote._id, voteData: { canceled: true } },
         {
            onError: (error) => {
               console.error(error);
            },
            onSuccess: () => {
               socket.emit("cancel-vote", session._id, vote._id);
            },
         }
      );
   };

   return (
      <Formik
         initialValues={{ title: vote ? vote.title : "" }}
         validationSchema={createVoteQuestion}
         onSubmit={handleSubmit}
      >
         <Form>
            <Stack spacing={1.5}>
               <Stack spacing={1}>
                  <Typography>Título de la pregunta</Typography>
                  <InputTextField
                     id={"title"}
                     name={"title"}
                     type={"text"}
                     placeholder="Descripción"
                     size={"small"}
                     sx={{ bgcolor: "#F5F5F5" }}
                     multiline
                     maxRows={3}
                  />
               </Stack>
               <Stack spacing={1}>
                  <Typography>Respuestas:</Typography>
                  <Stack spacing={0.5}>
                     <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                        <Typography>De acuerdo: </Typography>
                        <Typography>{vote?.agreed || 0}</Typography>
                     </Box>
                     <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                        <Typography>En desacuerdo: </Typography>
                        <Typography>{vote?.disagreed || 0}</Typography>
                     </Box>
                     <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                        <Typography>Se abstuvo de votar: </Typography>
                        <Typography>{vote?.nullVote || 0}</Typography>
                     </Box>
                     <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                        <Typography sx={{ fontWeight: 600 }}>Votos faltantes: </Typography>
                        <Typography sx={{ fontWeight: 600 }}>
                           {vote ? vote.votes.length - (vote.agreed + vote.disagreed + vote.nullVote) : 0}
                        </Typography>
                     </Box>
                  </Stack>
               </Stack>
               <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                  <Button type="submit" variant="contained" sx={{ bgcolor: "#2D4357", color: "white", width: "33%" }}>
                     {votationOnCourse || (vote ? vote.agreed + vote.disagreed + vote.nullVote : 0) > 0
                        ? "Terminar"
                        : "Compartir"}
                  </Button>
                  <Button
                     type="button"
                     onClick={handleCancelVotation}
                     sx={{
                        bgcolor: "#E1E1E1",
                        border: 0.65,
                        borderColor: "#EFEFEF",
                        width: "33%",
                        color: "black",
                     }}
                  >
                     Cancelar
                  </Button>
               </Box>
            </Stack>
         </Form>
      </Formik>
   );
};
