import { Box, Button, Modal, Stack, Typography } from "@mui/material";
import { SessionFile } from "./SubComponents/SessionFile";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useContext, useEffect, useState } from "react";
import { GovernanceSessionContext } from "../../../context/governanceContext/governanceSessionContext";
import { UploadFileToSession } from "./SubComponents/UploadFileToSession";

export const SessionFiles = () => {
   const { hasPermissions, fileArray, isShareholder, session } = useContext(GovernanceSessionContext);
   const [openSessionFileModal, setOpenSessionFileModal] = useState(false);
   const { socket, setFileArray } = useContext(GovernanceSessionContext);
   const socketHandler = (valuesFromSocket) => {
      setFileArray((current) => [...current, valuesFromSocket]);
   };

   useEffect(() => {
      if (socket === null) return;
      socket.on("fetch-file", socketHandler);

      return () => {
         socket.off("fetch-file", socketHandler);
      };
   }, [socket]);

   const handleClose = () => {
      setOpenSessionFileModal(false);
   };

   return (
      <Box>
         <Box sx={{ bgcolor: "#F8F8F9", border: 1, borderColor: "#E0E0E0", p: 1 }}>
            <Typography sx={{ fontWeight: 600 }}>Archivos</Typography>
            <Typography sx={{ fontSize: 9, color: "#64748B" }}>
               Documentación generada para analizar en la sesión
            </Typography>
         </Box>
         {(isShareholder || hasPermissions) && (
            <Stack
               sx={{
                  bgcolor: "#FFFFFF",
                  borderLeft: 1,
                  borderRight: 1,
                  borderBottom: 1,
                  borderColor: "#E0E0E0",
                  py: 2,
                  alignItems: "center",
               }}
            >
               <Button
                  onClick={() => {
                     setOpenSessionFileModal(true);
                  }}
                  sx={{ display: "flex", gap: 1, alignContent: "center", ml: -1, width: "55%" }}
               >
                  <AddCircleIcon sx={{ color: "#64748B", fontSize: 16 }} />
                  <Typography sx={{ color: "#64748B", fontSize: 10, fontWeight: 600 }}>Agregar archivo</Typography>
               </Button>
               <Typography sx={{ fontSize: 10, color: "#D9D9D9", textAlign: "center" }}>
                  Los archivos serán compartidos en la sesión actual.
               </Typography>
            </Stack>
         )}
         <Box
            sx={{
               bgcolor: "white",
               borderBottom: 0.65,
               borderLeft: 0.65,
               borderRight: 0.65,
               borderColor: "#E0E0E0",
               height: isShareholder || hasPermissions ? 250 : 325,
               overflowY: "auto",
            }}
         >
            {fileArray.map((file, i) => (
               <SessionFile key={`file-${i}`} file={file} session={session} />
            ))}
         </Box>
         <Modal
            open={openSessionFileModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
         >
            <UploadFileToSession setOpenSessionFileModal={setOpenSessionFileModal} />
         </Modal>
      </Box>
   );
};
