import { FormHelperText, Box, InputBase, Tooltip } from "@mui/material";
import { useField } from "formik";
import InfoIcon from "@mui/icons-material/Info";

interface TextFieldInterfaceProps {
   label?: string;
   fullWidth?: boolean;
   rows?: number;
   multiline?: boolean;
   variant?: any;
   size?: any;
   id: string;
   name: string;
   placeholder?: string;
   type: string;
   disabled?: boolean;
   sx?: object;
   InputProps?: any;
   InputLabelProps?: any;
   icon: any;
   infoicon?: string;
   eyeicon?: any;
}

export const InputBaseMod = (props: TextFieldInterfaceProps) => {
   const [field, meta] = useField(props);
   return (
      <>
         <Box sx={{ display: "flex", flexDirection: "column", minWidth: "100%" }}>
            <Box sx={{ display: "flex", minHeight: 55, minWidth: "100%" }}>
               <Box
                  sx={{
                     bgcolor: "#162c44",
                     minWidth: 40,
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     borderTopLeftRadius: 5,
                     borderBottomLeftRadius: 5,
                     boxShadow: "1px 1.5px 2px grey",
                     minHeight: "100%",
                  }}
               >
                  {props.icon}
               </Box>
               <InputBase
                  {...field}
                  {...props}
                  error={meta.error && meta.touched ? true : false}
                  endAdornment={
                     <>
                        {props.infoicon ? (
                           <Tooltip title={props.infoicon}>
                              <InfoIcon fontSize="small" color="action" sx={{ alignSelf: "center" }} />
                           </Tooltip>
                        ) : (
                           <></>
                        )}
                        {props.eyeicon}
                     </>
                  }
               />
            </Box>
            <FormHelperText error={meta.error && meta.touched ? true : false}>
               {meta.error && meta.touched ? meta.error : null}
            </FormHelperText>
         </Box>
      </>
   );
};
