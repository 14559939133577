import { Box, CircularProgress, Popover, Stack, Typography, Card } from "@mui/material";
import { useState } from "react";
import { getDateFromISODate } from "../../../const/globalConst";
import { AssignationComponent, PriorityComponent, StatusComponent } from "./SubComponents/ComponentsForTaskList";
import _ from "lodash";
import { TaskModalComponent } from "./SubComponents/Modals/TaskModalComponent";
import { CommitmentModal } from "./SubComponents/Modals/CommitmentModal";

export const AssignedTaskComponent = ({ tasks, allUsers, isLoadingUsers }) => {
   const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
   const [isHovered, setIsHovered] = useState(null);
   const [openTask, setOpenTask] = useState(false);
   const [openCommitment, setOpenCommitment] = useState(false);
   const [selectedTask, setSelectedTask] = useState(null);

   const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };

   const handlePopoverClose = () => {
      setIsHovered(null);
      setAnchorEl(null);
   };

   const formatDate = (dateStr: string): string => {
      const parts = dateStr.split("/");
      const thirdNumber = parseInt(parts[2], 10);
      return thirdNumber > 2999 ? "Indeterminado" : dateStr;
   };

   return (
      <>
         <Card sx={{ display: "flex", px: 4, py: 2, textAlign: "center" }}>
            <Typography fontSize={14} fontWeight={700} flex={1} sx={{ textAlign: "left" }}>
               Nombre
            </Typography>
            <Typography fontSize={14} fontWeight={700} flex={1}>
               Fecha límite
            </Typography>
            <Typography fontSize={14} fontWeight={700} flex={1.5}>
               Asignación
            </Typography>
            <Typography fontSize={14} fontWeight={700} flex={1}>
               Prioridad
            </Typography>
            <Typography fontSize={14} fontWeight={700} flex={1}>
               Estatus
            </Typography>
         </Card>
         {tasks?.length > 0 && allUsers?.length > 0 ? (
            tasks.map((e, i) => (
               <Card
                  key={i}
                  sx={{
                     my: 1,
                     display: "flex",
                     px: 4,
                     py: 2,
                     textAlign: "center",
                     alignItems: "center",
                     ":hover": { cursor: "pointer", bgcolor: "#F9F9F9" },
                  }}
                  onClick={() => {
                     setSelectedTask(e);
                     if (e.dueDate) setOpenCommitment(true);
                     else setOpenTask(true);
                  }}
               >
                  <Stack sx={{ flex: 1, textAlign: "left" }}>
                     <Typography fontSize={12} color={"#91929E"}>
                        Nombre
                     </Typography>
                     {e.title.length > 50 ? (
                        <Box onMouseEnter={() => setIsHovered(`${e.title}`)}>
                           <Typography
                              aria-owns={isHovered === `${e.title}` ? `${e.title}` : undefined}
                              aria-haspopup="true"
                              onMouseEnter={handlePopoverOpen}
                              onMouseLeave={handlePopoverClose}
                              fontSize={14}
                           >
                              {e.title.substring(0, 49).concat("...")}
                           </Typography>
                        </Box>
                     ) : (
                        <Typography fontSize={14}>{e.title}</Typography>
                     )}
                     <Popover
                        id={`${e.title}`}
                        sx={{
                           pointerEvents: "none",
                           boxShadow: "none",
                        }}
                        open={isHovered === `${e.title}`}
                        anchorEl={anchorEl}
                        anchorOrigin={{
                           vertical: "bottom",
                           horizontal: "left",
                        }}
                        transformOrigin={{
                           vertical: "top",
                           horizontal: "left",
                        }}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                     >
                        <Typography fontSize={14} sx={{ p: 1, maxWidth: 400 }}>
                           {e.title}
                        </Typography>
                     </Popover>
                  </Stack>
                  <Stack sx={{ flex: 1 }}>
                     <Typography fontSize={12} color={"#91929E"}>
                        Fecha límite
                     </Typography>
                     <Typography fontSize={14}>
                        {`${e.limitDate ? formatDate(getDateFromISODate(e.limitDate)) : ""}${
                           e.dueDate ? formatDate(getDateFromISODate(e.dueDate)) : ""
                        }`}
                     </Typography>
                  </Stack>
                  <Stack sx={{ flex: 1.5, alignItems: "center" }}>
                     <Typography fontSize={12} color={"#91929E"}>
                        Asignación
                     </Typography>
                     <AssignationComponent
                        users={allUsers
                           .filter((usersInfo) => e.users.some((u) => u === usersInfo.id) || e.owner === usersInfo.id)
                           .map((u) => u.user)}
                     />
                  </Stack>
                  <Stack sx={{ flex: 1 }}>
                     <Typography fontSize={12} color={"#91929E"}>
                        Prioridad
                     </Typography>
                     <PriorityComponent priority={e.priority} />
                  </Stack>
                  <Stack sx={{ flex: 1, alignItems: "center" }}>
                     <Typography fontSize={12} color={"#91929E"}>
                        Estatus
                     </Typography>
                     <StatusComponent status={e.status} />
                  </Stack>
               </Card>
            ))
         ) : isLoadingUsers ? (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
               <CircularProgress />
            </Box>
         ) : (
            <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
               <Typography>No se encontraron actividades</Typography>
            </Box>
         )}

         <TaskModalComponent users={allUsers} open={openTask} setOpen={setOpenTask} task={selectedTask} />
         <CommitmentModal
            users={allUsers}
            open={openCommitment}
            setOpen={setOpenCommitment}
            commitment={selectedTask}
         />
      </>
   );
};
