import ListComponent from "../../../ListComponent";

const EffectivenessCommitmentsList = ({ selectedTrimester }) => {
   return (
      <ListComponent
         headers={[
            { headerTitle: "Nombre", type: "text" },
            { headerTitle: "Fecha límite", type: "date" },
            { headerTitle: "Asignados", type: "list" },
            { headerTitle: "Estado", type: "punctual", headerPosition: "center" },
         ]}
         rows={selectedTrimester.map((com) => ({
            text: com.name,
            limitDate: com.limitDate,
            list: com.assigned,
            punctual: com.status,
         }))}
         rowsColumnProps={{ px: 2 }}
      />
   );
};

export default EffectivenessCommitmentsList;
