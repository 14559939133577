import { useEffect, useState } from "react";
import { getUrlForDocumentsList } from "../../lib/usersBEClient";

interface UseGetDocumentationListProps {
   selected: any;
   subject: any;
   reportNumber: string;
   isLoading?: boolean;
   open?: boolean;
}

const UseGetDocumentationList = (props: UseGetDocumentationListProps) => {
   const [documentList, setDocumentList] = useState<string[]>([]);
   useEffect(() => {
      const fetchList = async () => {
         const involvedEvidences = await getUrlForDocumentsList("files-lecosy", {
            folder: props.selected?._id
               ? `evidences/${props.reportNumber}/${props.subject}/${props.selected._id}`
               : `evidences/${props.reportNumber}/${props.subject}/anonimo`,
         });
         const files: string[] = [];
         if (involvedEvidences?.Contents) {
            for (const file of involvedEvidences.Contents) {
               files.push(file.Key.split("/").slice(-1));
            }
         }
         setDocumentList(files);
      };
      if (props.reportNumber && props.selected && props.subject) fetchList();
      // eslint-disable-next-line
   }, [props.open]);

   return documentList;
};

export default UseGetDocumentationList;
