import { useCallback, useState } from "react";
import { useResizeObserver } from "@wojtekmaj/react-hooks";
import { pdfjs, Document, Page } from "react-pdf";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

import "./Styles.css";

import type { PDFDocumentProxy } from "pdfjs-dist";

pdfjs.GlobalWorkerOptions.workerSrc = new URL("pdfjs-dist/build/pdf.worker.min.js", import.meta.url).toString();

const options = {
   cMapUrl: "/cmaps/",
   standardFontDataUrl: "/standard_fonts/",
};

const resizeObserverOptions = {};

const maxWidth = 800;

export default function Sample({ file }) {
   const [numPages, setNumPages] = useState<number>();
   const [containerRef, setContainerRef] = useState<HTMLElement | null>(null);
   const [containerWidth, setContainerWidth] = useState<number>();

   const onResize = useCallback<ResizeObserverCallback>((entries) => {
      const [entry] = entries;

      if (entry) {
         setContainerWidth(entry.contentRect.width);
      }
   }, []);

   useResizeObserver(containerRef, resizeObserverOptions, onResize);

   function onDocumentLoadSuccess({ numPages: nextNumPages }: PDFDocumentProxy): void {
      setNumPages(nextNumPages);
   }

   return (
      <div className="Example">
         <div className="Example__container">
            <div className="Example__container__document" ref={setContainerRef}>
               <Document file={file} onLoadSuccess={onDocumentLoadSuccess} options={options}>
                  {Array.from(new Array(numPages), (el, index) => (
                     <Page
                        key={`page_${index + 1}`}
                        pageNumber={index + 1}
                        width={containerWidth ? Math.min(containerWidth, maxWidth) : maxWidth}
                     />
                  ))}
               </Document>
            </div>
         </div>
      </div>
   );
}
