import { useState } from "react";
import axios from "axios";
import * as XLSX from "xlsx";

export const ExcelPDF = ({ excelLink }) => {
   const [generatedPdf, setGeneratedPdf] = useState(null);
   const handleFileChange = async () => {
      try {
         axios.defaults.withCredentials = false;
         const response = await axios.get(excelLink, { responseType: "arraybuffer" });
         const data = new Uint8Array(response.data);
         const workbook = XLSX.read(data, { type: "array" });
         const sheetName = workbook.SheetNames[0];
         const worksheet = workbook.Sheets[sheetName];
         const htmlTable = XLSX.utils.sheet_to_html(worksheet);
         setGeneratedPdf(htmlTable);
      } catch (error) {
         console.error("Error al cargar el archivo desde la URL", error);
      }
   };

   const handleDownload = () => {
      if (generatedPdf) {
         const blob = new Blob([generatedPdf], { type: "application/pdf" });
         const url = URL.createObjectURL(blob);
         const a = document.createElement("a");
         a.href = url;
         a.download = "output.pdf";
         document.body.appendChild(a);
         a.click();
         document.body.removeChild(a);
         URL.revokeObjectURL(url);
      }
   };
   return (
      <div>
         <button onClick={() => handleFileChange()}>Convertir a PDF</button>
         <button onClick={handleDownload} disabled={!generatedPdf}>
            Descargar PDF
         </button>
         {generatedPdf}
      </div>
   );
};
