import { KeyboardArrowLeft, KeyboardArrowRight, ChatRounded } from "@mui/icons-material";
import {
   Box,
   Card,
   IconButton,
   Stack,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableFooter,
   TablePagination,
   TableRow,
   Tooltip,
   Typography,
   useTheme,
} from "@mui/material";
import { useContext, useState } from "react";
import { ChatContext } from "../../../context/governanceContext/ChatContext";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/userContext";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";

interface TablePaginationActionsProps {
   count: number;
   page: number;
   rowsPerPage: number;
   onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: Readonly<TablePaginationActionsProps>) {
   const theme = useTheme();
   const { count, page, rowsPerPage, onPageChange } = props;

   const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1);
   };

   const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page + 1);
   };

   return (
      <Box sx={{ flexShrink: 0, ml: 1 }}>
         <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
            {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
         </IconButton>
         <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
         >
            {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
         </IconButton>
      </Box>
   );
}

interface MembersPaginationProps {
   users: any;
   setState: Function;
   setUserId: Function;
   setMemberData: Function;
}

export const MembersPagination = (props: MembersPaginationProps) => {
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(4);
   const { user } = useContext(UserContext);
   const { conversations, createChatNew, contacts, allUsers, setCurrentChat } = useContext(ChatContext);
   const { companySelected, setSelectedSection } = useContext(GovernanceContext);
   const navigate = useNavigate();

   const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
   };

   const handleChat = async (targetUser) => {
      setSelectedSection("Chat");
      const participants = conversations.map((c) => c.participants[0]?._id === targetUser.user);
      for (let i = 0; i < participants.length; i++) {
         const element = participants[i];
         if (element) {
            const conversationId = conversations[i]?._id;
            setCurrentChat(conversations[i]);
            return navigate(`/gobierno-corporativo/${companySelected}/mi-cuenta/chat/${conversationId}`);
         }
      }
      const newConversation = await createChatNew(
         targetUser,
         user,
         contacts,
         allUsers,
         targetUser.user,
         companySelected
      );

      navigate(`/gobierno-corporativo/${companySelected}/mi-cuenta/chat/${newConversation}`);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };
   return (
      <TableContainer sx={{ height: 550 }}>
         <Table>
            <TableBody sx={{ height: 488 }}>
               {(rowsPerPage > 0
                  ? props.users.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : props.users
               ).map((u, index) => (
                  <TableRow key={index} sx={{ display: "flex", justifyContent: "center" }}>
                     <TableCell sx={{ border: 0 }}>
                        <Card
                           key={index}
                           sx={{
                              bgcolor: "#E4E4E445",
                              width: 200,
                              height: 90,
                              p: 2,
                              borderRadius: 3,
                              border: 1,
                              borderColor: "#EEEEEF",
                              alignItems: "center",
                              ":hover": {
                                 cursor: "pointer",
                                 bgcolor: "#E4E4E4",
                              },
                           }}
                           onClick={() => {
                              props.setState(true);
                              props.setUserId(u.user);
                              props.setMemberData({
                                 email: u.email,
                                 firstName: u.firstName,
                                 lastName: u.lastName,
                                 chargeName: u.chargeName,
                                 characters: u.characters,
                              });
                           }}
                        >
                           <Stack direction={"column"} spacing={user.id === u.user ? 0 : -2}>
                              <Box sx={{ display: "flex", alignSelf: "flex-end" }}>
                                 {user.id === u.user ? null : (
                                    <Tooltip
                                       title="Enviar mensaje"
                                       sx={{
                                          color: "#6A7886",
                                       }}
                                    >
                                       <IconButton
                                          sx={{ p: 0 }}
                                          onClick={(e) => {
                                             handleChat(u);
                                             e.stopPropagation();
                                          }}
                                       >
                                          <ChatRounded sx={{ fontSize: 18 }} />
                                       </IconButton>
                                    </Tooltip>
                                 )}
                              </Box>
                              <Box
                                 sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center",
                                 }}
                              >
                                 <Typography
                                    fontWeight={600}
                                    fontSize={"13px"}
                                    color={"#3F5063"}
                                    sx={{
                                       alignSelf: "center",
                                       textAlign: "center",
                                       maxWidth: 120,
                                       overflow: "hidden",
                                       textOverflow: "ellipsis",
                                       whiteSpace: "nowrap",
                                    }}
                                 >
                                    {u.firstName}{" "}
                                    {u?.lastName?.length <= 10 ? u?.lastName : `${u?.lastName?.slice(0, 10)}...`}
                                 </Typography>

                                 <Typography
                                    fontSize={"13px"}
                                    color={"#3F5063"}
                                    sx={{ alignSelf: "center", textAlign: "center" }}
                                 >
                                    {u.chargeName}
                                 </Typography>
                              </Box>
                           </Stack>
                        </Card>
                     </TableCell>
                  </TableRow>
               ))}
            </TableBody>
            <TableFooter sx={{ position: "sticky", bottom: 0 }}>
               <TableRow>
                  <TablePagination
                     sx={{ border: 0, display: "flex", justifyContent: "center" }}
                     count={props.users.length}
                     rowsPerPage={rowsPerPage}
                     page={page}
                     rowsPerPageOptions={[]}
                     onPageChange={handleChangePage}
                     onRowsPerPageChange={handleChangeRowsPerPage}
                     ActionsComponent={TablePaginationActions}
                  />
               </TableRow>
            </TableFooter>
         </Table>
      </TableContainer>
   );
};
