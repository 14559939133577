import { Box, Button, Card, CircularProgress, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { GovernanceContext } from "../../context/governanceContext/governanceContext";
import { useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { ArrowBack, Close } from "@mui/icons-material";
import { FilterComponent } from "../../components/FilterComponent";
import { GetGroupById, getUrlS3 } from "../../lib/usersBEClient";
import { GovernanceTheSequelContext } from "../../context/governanceContext/governanceTheSequelContext";

interface GovernanceSelectionModalProps {
   state: { open: boolean; title: string };
   setState: Function;
}

interface CompanyCard {
   _id: string;
   logo: JSX.Element;
   name: string;
}

export const GovernanceSelectionModal = (props: GovernanceSelectionModalProps) => {
   const {
      companySelected,
      consultiveGroup,
      consultiveGroupCompanies,
      isLoadingConsultiveGroup,
      setSelectedGovernance,
      setSelectedSection,
      gobernanceBody,
   } = useContext(GovernanceContext);
   const {
      isImplementationUser,
      companies: companiesContext,
      getCompanyDetails,
      setCompanySelected,
   } = useContext(UserContext);
   const [filteredCompanies, setFilteredCompanies] = useState([]);
   const [companyString, setCompanyString] = useState("");
   const [isLoading, setIsLoading] = useState(false);
   const [showCase, setShowCase] = useState(false);
   const [companies, setCompanies] = useState([]);
   const { myGovBody } = useContext(GovernanceTheSequelContext);
   const { id, companyId } = useParams();
   const navigate = useNavigate();

   const getDataS3 = async (companyId: string) => {
      const logoUrl: string = await getUrlS3("images-lecosy", { folder: companyId }, "logo.png");
      return logoUrl;
   };

   useEffect(() => {
      const formatCompanies = async () => {
         setIsLoading(true);
         let companyData = [];
         if (consultiveGroup && consultiveGroupCompanies) {
            const groupGovernance = myGovBody.some(
               (gb) => gb.bodiesData.company === consultiveGroup._id && gb.bodiesData.title === props.state.title
            );

            if (groupGovernance) {
               const logo = await getDataS3(`group/${consultiveGroup._id}`);
               companyData.push({
                  _id: consultiveGroup._id,
                  name: consultiveGroup.name,
                  governance: groupGovernance,
                  logo: (
                     <img
                        defaultValue={logo}
                        src={logo}
                        alt="logo"
                        style={{ maxWidth: "120px", maxHeight: "90px", alignSelf: "center" }}
                     />
                  ),
               });
            }
            for (const company of consultiveGroupCompanies) {
               const governanceFound = myGovBody.some(
                  (gb) => gb.bodiesData.company === company._id && gb.bodiesData.title === props.state.title
               );
               if (!governanceFound) continue;
               companyData.push({
                  _id: company._id,
                  name: company.person_details.comercialName,
                  governance: governanceFound,
                  logo: (
                     <img
                        defaultValue={company.logo}
                        src={company.logo}
                        alt="logo"
                        style={{ maxWidth: "120px", maxHeight: "90px", alignSelf: "center" }}
                     />
                  ),
               });
            }
            if (props.state.title === "Consejo de administración" && consultiveGroup._id === companyId) {
               companyData = companyData.filter((c) => !consultiveGroup.companies.includes(c._id));
            }
         }
         setCompanies(companyData);
         setFilteredCompanies(companyData);
         setIsLoading(false);
      };
      if (companiesContext?.length > 0 && !isLoadingConsultiveGroup) formatCompanies();
   }, [consultiveGroup, companiesContext, isLoadingConsultiveGroup]);

   const theCompany: CompanyCard = useMemo(() => {
      return companies.find((c) => c._id === companyString);
   }, [companyString, showCase]);

   const handleClose = () => {
      if (companySelected) return props.setState(false);
      navigate("/inicio");
   };

   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            bgcolor: "white",
            width: 700,
            borderRadius: 2,
         }}
      >
         <Box
            sx={{
               bgcolor: "#152C44",
               display: "flex",
               alignItems: "center",
               justifyContent: "space-between",
               p: 2,
               borderTopLeftRadius: 5,
               borderTopRightRadius: 5,
            }}
         >
            <Typography fontWeight={600} variant="h6" sx={{ color: "white" }}>
               {isImplementationUser() ? "Empresas disponibles" : "Grupo empresarial"}
            </Typography>
            <IconButton sx={{ color: "#FAFAFA" }} onClick={handleClose}>
               <Close />
            </IconButton>
         </Box>
         {!isLoading && !isLoadingConsultiveGroup ? (
            <>
               <Box
                  sx={{
                     display: "flex",
                     justifyContent: "center",
                     flexDirection: "column",
                     px: 2,
                     py: 1,
                  }}
               >
                  {showCase && theCompany ? (
                     <>
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                           <IconButton onClick={() => setShowCase(false)}>
                              <ArrowBack />
                           </IconButton>
                           <Typography fontWeight={600} variant="subtitle1">
                              Confirmar selección:
                           </Typography>
                        </Box>
                        <Stack
                           spacing={2}
                           sx={{
                              width: "100%",
                              justifyContent: "center",
                              alignItems: "center",
                              display: "flex",
                              my: 2,
                           }}
                        >
                           <Card
                              sx={{
                                 width: "30%",
                                 height: 160,
                                 display: "flex",
                                 flexDirection: "column",
                                 border: 1,
                                 borderColor: "#EEEEEF",
                                 "box-shadow": "0px 0px 2px 0px rgba(233,233,233,1)",
                                 bgcolor: "#C4CBD625",
                              }}
                           >
                              <Box
                                 sx={{
                                    flex: 3,
                                    display: "flex",
                                    justifyContent: "center",
                                    width: "100%",
                                 }}
                              >
                                 {theCompany.logo}
                              </Box>
                              <Box
                                 sx={{
                                    flex: 1,
                                    display: "flex",
                                    justifyContent: "center",
                                    bgcolor: "white",
                                 }}
                              >
                                 <Typography
                                    fontWeight={600}
                                    fontSize={"15px"}
                                    sx={{
                                       display: "flex",
                                       textAlign: "center",
                                       color: "#46566A",
                                       alignItems: "center",
                                    }}
                                 >
                                    {theCompany.name}
                                 </Typography>
                              </Box>
                           </Card>
                           <Typography fontSize={14} sx={{ alignSelf: "center", fontStyle: "italic" }}>
                              Al confirmar, se mostrara el gobierno corporativo seleccionado.
                           </Typography>
                        </Stack>
                     </>
                  ) : (
                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: "column",
                           width: "100%",
                        }}
                     >
                        <Box sx={{ px: 2, pt: 2 }}>
                           <Typography fontWeight={600} variant="subtitle1">
                              Selecciona una empresa para mostrar información:
                           </Typography>
                           <FilterComponent
                              originaltableOneRows={companies}
                              tableOneFilteredRows={filteredCompanies}
                              setTableOneFilteredRows={setFilteredCompanies}
                              filters={[]}
                              fullWidth
                              searchBar={<></>}
                              disableRefresh
                           />
                        </Box>
                        <Grid container sx={{ rowGap: 2, mb: 2, maxHeight: 350, minHeight: 150, overflowY: "auto" }}>
                           {filteredCompanies.length === 0 ? (
                              <Typography
                                 sx={{
                                    width: "100%",
                                    textAlign: "center",
                                    my: 2,
                                 }}
                              >
                                 No se encontraron empresas
                              </Typography>
                           ) : (
                              filteredCompanies.map((company, index) => (
                                 <Grid key={index} xs={4} sx={{ px: 2 }}>
                                    <Card
                                       sx={{
                                          width: "100%",
                                          height: 160,
                                          display: "flex",
                                          flexDirection: "column",
                                          ":hover": { cursor: "pointer" },
                                          pointerEvents: company.governance === id ? "none" : undefined,
                                          opacity: company.governance === id ? 0.7 : 1,
                                          border: 1,
                                          borderColor: "#EEEEEF",
                                          "box-shadow": "0px 0px 2px 0px rgba(233,233,233,1)",
                                          bgcolor: "#C4CBD625",
                                       }}
                                       onClick={() => {
                                          setCompanyString(company._id);
                                          setShowCase(true);
                                       }}
                                    >
                                       {company.governance === id && (
                                          <Typography
                                             fontWeight={600}
                                             fontSize={14}
                                             sx={{ alignSelf: "center", color: "#152C44" }}
                                          >
                                             Selección actual
                                          </Typography>
                                       )}
                                       <Box
                                          sx={{
                                             flex: 3,
                                             display: "flex",
                                             justifyContent: "center",
                                             width: "100%",
                                          }}
                                       >
                                          {company.logo}
                                       </Box>
                                       <Box
                                          sx={{
                                             flex: 1,
                                             display: "flex",
                                             justifyContent: "center",
                                             bgcolor: "white",
                                          }}
                                       >
                                          <Typography
                                             fontWeight={600}
                                             fontSize={"15px"}
                                             sx={{
                                                display: "flex",
                                                textAlign: "center",
                                                color: "#46566A",
                                                alignItems: "center",
                                             }}
                                          >
                                             {company.name}
                                          </Typography>
                                       </Box>
                                    </Card>
                                 </Grid>
                              ))
                           )}
                        </Grid>
                     </Box>
                  )}
               </Box>
            </>
         ) : (
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%", my: 7 }}>
               <CircularProgress sx={{ alignSelf: "center" }} />
            </Box>
         )}
         <Box
            sx={{
               display: "flex",
               justifyContent: "space-evenly",
            }}
         >
            <Button
               disabled={showCase}
               sx={{
                  display: "flex",
                  justifyContent: "center",
                  flex: 1,
                  height: 40,
                  bgcolor: "#F7F9FB",
                  ":hover": { bgcolor: "#F7F9FB" },
               }}
               onClick={handleClose}
            >
               Cancelar
            </Button>
            {showCase && theCompany && (
               <Button
                  sx={{
                     display: "flex",
                     justifyContent: "center",
                     height: 40,
                     flex: 1,
                     bgcolor: "#F7F9FB",
                     ":hover": { bgcolor: "#F7F9FB" },
                  }}
                  onClick={() => {
                     setShowCase(false);
                     props.setState(false);
                     if (companyString !== consultiveGroup._id) getCompanyDetails(companyString);
                     else setCompanySelected(null);
                     const governanceFound = gobernanceBody.find(
                        (gb) => gb.company === companyString && gb.title === props.state.title
                     );
                     setSelectedGovernance(governanceFound);
                     setSelectedSection("Dashboard");
                     navigate(`${companySelected}/${governanceFound._id}/dashboard`);
                  }}
               >
                  Confirmar
               </Button>
            )}
         </Box>
      </Box>
   );
};
