import {
   Box,
   Button,
   Chip,
   CircularProgress,
   Collapse,
   Divider,
   FormControl,
   FormHelperText,
   IconButton,
   InputLabel,
   MenuItem,
   Select,
   Stack,
   TextField,
   Toolbar,
   Typography,
} from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useContext, useEffect, useMemo, useReducer, useState } from "react";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
   UpdateSubUserRolesById,
   addUserRole,
   deleteGovernRol,
   getCompaniesById,
   getCompanyById,
   getGroupUsers,
   getRoleByCharge,
} from "../../lib/usersBEClient";
import { SnackBarContext } from "../../context/snackBarContext";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Close, DeleteForever } from "@mui/icons-material";
import { UserContext } from "../../context/userContext";
import { ConfirmationModal } from "../ConfirmationModal";
import { getGoberningBodiesByCompany, updateUserCharges } from "../../lib/gobCorpBEClient";
import { CargoSelectorComponent } from "../MiLecosy/CargoSelectorComponent";

interface PropsModifyUserRoles {
   userInfo: any;
   modulesAndRoles: any[];
   isLoadingModules: boolean;
   setisLoadingModules: Function;
   open: boolean;
   setOpen: Function;
   addModule: boolean;
   fetchData: Function;
   companySelected?: any;
   handleUpdateInfo?: Function;
}
const userLimitDefault = {
   cd: 3,
   pc: 12,
};

export const ModifyUserRoles = (props: PropsModifyUserRoles) => {
   const { user } = useContext(UserContext);
   function handleOpen() {
      props.setisLoadingModules(true);
      props.setOpen(!props.open);
   }
   const [values, setValues] = useState<any>({
      service: { serviceName: props.userInfo.module },
   });
   const [rolValues, setRolValues] = useState("");
   const [originalRoles, setOriginalRoles] = useState(!props.isLoadingModules ? props.userInfo.generalRoles : []);
   const [branchValue, setBranchValue] = useState("");
   const [branchesToDisplay, setBranchesToDisplay] = useState([]);
   const [rolesSelected, setRolesSelected] = useState({});
   const [error, setError] = useState(null);
   const [rolesToDisplay, setRolesToDisplay] = useState([]);
   const [branchesSelected, setBranchesSelected] = useState([]);
   const [moduleSelected, setModuleSelected] = useState(!props.isLoadingModules ? props.userInfo.module : null);
   const { mutate, isLoading } = UpdateSubUserRolesById(!props.isLoadingModules ? props.userInfo.uid : "");
   const { showSnackBar } = useContext(SnackBarContext);
   const [openUserDeleteCollapse, setOpenUserDeleteCollapse] = useState(false);
   const [groupUsers, setGroupUsers] = useState(null);
   const [openSubUserDeleteConfirmModal, setOpenSubUserDeleteConfirmModal] = useState(false);
   const [openChangeRoles, setOpenChangeRoles] = useState(false);

   const handleClose = () => {
      props.handleUpdateInfo?.();
      props.setOpen(false);
   };

   useEffect(() => {
      if (!props.isLoadingModules && props.modulesAndRoles.length > 0) {
         getRolesByModule(props.userInfo.module);
         const module = getModule(props.userInfo.module);
         setRolesSelected({ [module]: props.userInfo.roles });
         setBranchesToDisplay(
            props.modulesAndRoles.filter((module) => module.service.serviceName === props.userInfo.module)[0].branches
         );
         setBranchesSelected(
            props.userInfo.branches
               ? props.userInfo.branches.filter((branch) => branch.company === props.userInfo.company)
               : []
         );
      }
      const fetchGroupUsers = async () => {
         if (user.group) {
            const users = await getGroupUsers(user.group._id);
            setGroupUsers(users);
         } else {
            const users = user.companies.filter((company) => company.id === props.userInfo.company)[0].company_details
               .users;
            setGroupUsers(users);
         }
      };
      fetchGroupUsers();
      // eslint-disable-next-line
   }, [props.modulesAndRoles, props.isLoadingModules]);

   const compareRoles = (role: string, value: any) => {
      return (
         value.name === role &&
         rolesToDisplay.filter((rolCompany) => {
            return rolCompany.roles.some((rol) => rol.name === role);
         })
      );
   };

   const getUserCountByModule = (company, Groupusers) => {
      const processedData = [];
      for (const users of Groupusers) {
         for (const role of users.role) {
            processedData.push(role);
         }
      }
      const filteredRoles = processedData.filter((rol) => rol.company?._id === company?._id);
      const processedData2 = [];
      for (const users of filteredRoles) {
         for (const role of users.roles) {
            processedData2.push(role);
         }
      }
      const rolesByCD = processedData2.filter(
         (rol) => rol.name === "Auditor" || rol.name === "Director" || rol.name === "Oficial de cumplimiento"
      );
      return {
         cd: Array.isArray(rolesByCD) ? rolesByCD.length : 0,
      };
   };

   const getModule = (module) => {
      if (module === "Canal de denuncias") return "cd";
      if (module === "Programa de cumplimiento") return "pc";
      if (module === "Gobierno corporativo") return "gc";
      return "";
   };

   const getRolesByModule = (moduleService) => {
      const module = getModule(moduleService);
      let rolesFiltered = [];
      for (const module of props.modulesAndRoles) {
         if (module.service.serviceName === moduleService) {
            rolesFiltered = module.service.roles;
         }
      }
      setRolesToDisplay(rolesFiltered);
      setModuleSelected(module);
   };

   const handleOnChange = async (event) => {
      const value = event.target.value;
      let moduleSelected2 = "";
      if (value.company && value.access) {
         moduleSelected2 = value.service.serviceName;
         getRolesByModule(moduleSelected2);
         const module = getModule(moduleSelected2);
         if (rolesSelected[module] && rolesSelected[module].some((rol) => rol.name === value.name)) return;
         setError(null);
         setValues(value);
      } else if (!value.access && !value.disabled) {
         if (branchesSelected.includes(value)) return;
         for (const branch of branchesSelected) {
            if (branch._id === value._id) return;
         }
         setBranchValue(value);
         setBranchesSelected([...branchesSelected, value]);
      } else {
         const company = await getCompanyById(props.userInfo.company);
         const userLimit = company.data.company_details.servicesDetails;
         const userPerModule = getUserCountByModule(props.userInfo.company, groupUsers);
         let validation = false;
         for (const module of userLimit) {
            if (
               module.serviceId.service === "Canal de denuncias" &&
               moduleSelected === "cd" &&
               module.userLimit === userPerModule.cd &&
               props.userInfo.roles.length === 0
            ) {
               validation = true;
               setError("No cuentas con suficientes licencias para continuar.");
            }
         }

         const module = moduleSelected;
         if (module === "cd") {
            if (userLimitDefault["cd"] === values.userLimit) {
               if (compareRoles("Auditor", value).length > 0) {
                  setError("Rol ya en uso por otro usuario.");
                  return;
               }
               if (compareRoles("Director", value).length > 0) {
                  setError("Rol ya en uso por otro usuario.");
                  return;
               }
               if (compareRoles("Oficial de cumplimiento", value).length > 0) {
                  setError("Rol ya en uso por otro usuario.");
                  return;
               }
            }

            if (
               rolesSelected[module] &&
               rolesSelected[module].some(
                  (rol) => rol.name === "Auditor" || rol.name === "Director" || rol.name === "Oficial de cumplimiento"
               )
            )
               return;
         }
         if (module === "pc" && userLimitDefault["pc"] === 12) {
            //pendientes validaciones
         }
         if (!validation) {
            if (rolesSelected[module]) {
               setRolesSelected({ ...rolesSelected, [module]: [...rolesSelected[module], value] });
            }
            setRolesSelected({ ...rolesSelected, [module]: [value] });
            setRolValues(value);
         }
      }
   };

   const handleOnDelete = (_id: string, branches: boolean) => {
      if (branches) {
         const branches = branchesSelected;
         const filteredBranches = branches.filter((branch) => branch._id !== _id);
         setBranchValue("");
         setBranchesSelected(filteredBranches);
         return;
      }
      const originalNewValues = originalRoles.filter((role) => role._id !== _id);
      setOriginalRoles(originalNewValues);
      const roles = rolesSelected;
      Object.keys(roles).forEach((module) => {
         const newRoles = rolesSelected[module].filter((rol) => rol._id !== _id);
         if (newRoles.length > 0) {
            setRolesSelected({ ...rolesSelected, [module]: newRoles });
            return;
         }
         delete roles[module];
         return setRolesSelected({ ...roles });
      });
      setError(null);
   };

   const handleSubmitInfo = async () => {
      if (moduleSelected === "cd" && branchesSelected.length === 0) {
         setError("Selecciona almenos 1 sucursal.");
         return;
      }
      if (!rolesSelected[moduleSelected]) {
         setError("Selecciona almenos 1 rol.");
         return;
      }
      const coincidences = [];
      for (const iterator of originalRoles) {
         for (const iterator2 of rolesSelected[moduleSelected]) {
            if (iterator._id === iterator2._id) {
               coincidences.push(iterator);
            }
         }
      }
      let newRoles = [];
      let rolesIds = [];
      let branchesId = [];
      let remove = false;
      const branchesFromOtherCompanies = props.userInfo.branches.filter(
         (branch) => branch.company !== props.userInfo.company
      );
      const newBranches = branchesSelected.concat(branchesFromOtherCompanies);
      if (coincidences.length > 0) {
         branchesId = newBranches.map((role) => role._id);
         remove = true;
      } else {
         newRoles = [...originalRoles, ...rolesSelected[moduleSelected]];
         rolesIds = newRoles.map((role) => role._id);
         branchesId = newBranches.map((role) => role._id);
      }
      mutate(
         {
            company: props.userInfo.company,
            roles: rolesIds,
            branches: branchesId,
            remove: remove,
         },
         {
            onError: (error: any) => {
               showSnackBar("Error al agregar rol al usuario.", true);
            },
            onSuccess: async () => {
               props.fetchData();
               showSnackBar("El usuario fue agregado correctamente.", false);
               handleClose();
            },
         }
      );
   };

   const handleDeleteUserRole = () => {
      const rolesFiltered = [];
      const branchesFromOtherCompanies = props.userInfo.branches.filter(
         (branch) => branch.company !== props.userInfo.company
      );
      const branchesId = branchesFromOtherCompanies.map((role) => role._id);
      for (const iterator of props.userInfo.generalRoles) {
         for (const iterator2 of props.userInfo.roles) {
            if (iterator._id !== iterator2._id) {
               rolesFiltered.push(iterator);
            }
         }
      }
      const rolesIds = rolesFiltered.map((role) => role._id);
      mutate(
         {
            company: props.userInfo.company,
            roles: rolesIds,
            branches: branchesId,
            remove: true,
         },
         {
            onError: (error: any) => {
               showSnackBar("Error al eliminar rol.", true);
            },
            onSuccess: async () => {
               props.fetchData();
               showSnackBar("El rol fue eliminado correctamente.", false);
               handleClose();
            },
         }
      );
   };

   return (
      <>
         <Toolbar />
         <Box sx={{ width: 450, display: "flex", maxHeight: "100vh", height: "100%" }} role="presentation">
            <Box
               display="flex"
               alignItems="center"
               onClick={handleOpen}
               sx={{
                  "&:hover": {
                     bgcolor: "#F7F7F7",
                  },
               }}
            >
               <ChevronRightIcon fontSize="small" />
            </Box>
            <Stack mt={2} mr={2} flex={1} spacing={1}>
               <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                  <Typography variant="h5">Modificar roles del cliente</Typography>
                  {!props.isLoadingModules && props.userInfo.generalRoles.length > 0 && (
                     <IconButton onClick={() => setOpenUserDeleteCollapse(!openUserDeleteCollapse)}>
                        {!openUserDeleteCollapse ? <MoreVertIcon /> : <Close />}
                     </IconButton>
                  )}
               </Box>
               <Divider />
               <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                  <Collapse in={openUserDeleteCollapse}>
                     <Button
                        color="error"
                        onClick={() => {
                           setOpenSubUserDeleteConfirmModal(true);
                        }}
                        variant="outlined"
                     >
                        <Typography>Eliminar rol(es)</Typography>
                        <DeleteForeverIcon />
                     </Button>
                  </Collapse>
               </Box>

               <Box sx={{ py: 1, my: 2 }}>
                  <TextField value={props.userInfo.name} disabled size="small" label="Empresa" fullWidth />
               </Box>
               {props.isLoadingModules ? (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                     <CircularProgress />
                  </Box>
               ) : (
                  <>
                     <MapField
                        onChange={handleOnChange}
                        selectData={props.modulesAndRoles}
                        text="Módulo"
                        value={!props.isLoadingModules ? values : ""}
                        disabled={!props.addModule}
                     />
                     <Collapse in={getModule(props.userInfo.module) === "gc"}>
                        <GCModule
                           userRoles={rolesSelected[moduleSelected]}
                           userInfo={props.userInfo}
                           companySelected={props.companySelected}
                           handleClose={handleClose}
                        />
                     </Collapse>
                     <Collapse in={rolesToDisplay.length > 0 && getModule(props.userInfo.module) !== "gc"}>
                        <MapField
                           onChange={handleOnChange}
                           selectData={rolesToDisplay}
                           text="roles"
                           value={rolValues}
                           disabled={rolesSelected[moduleSelected] && rolesSelected[moduleSelected].length > 0}
                        />
                        <Box>
                           <Stack flexWrap={"wrap"} direction="row" rowGap={2}>
                              {Object.keys(rolesSelected).map((module, index) => {
                                 return (
                                    <Box key={rolesSelected[module]}>
                                       {rolesSelected[module].map((rol) => (
                                          <Chip
                                             sx={{
                                                bgcolor: "#D2D2D2",
                                                color: "#162c44",
                                             }}
                                             key={rol._id}
                                             label={
                                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                                   <Typography fontSize={13} fontWeight={500}>
                                                      {rol.length > 1 ? rol.name?.substring(0, 15) + "..." : rol.name}
                                                   </Typography>
                                                   <IconButton onClick={() => handleOnDelete(rol._id, false)}>
                                                      <DeleteForeverIcon sx={{ color: "#162c44" }} />
                                                   </IconButton>
                                                </Box>
                                             }
                                          />
                                       ))}
                                    </Box>
                                 );
                              })}
                           </Stack>
                        </Box>
                     </Collapse>
                     <Collapse in={getModule(props.userInfo.module) === "cd" && branchesToDisplay.length > 0}>
                        <MapField
                           onChange={handleOnChange}
                           selectData={branchesToDisplay}
                           text="sucursales"
                           value={branchValue}
                        />
                        {!props.addModule && getModule(props.userInfo.module) === "cd" ? (
                           <Box>
                              <Stack spacing={1} flexWrap={"wrap"} direction="row" rowGap={2} mt={1} mb={error ? 0 : 2}>
                                 {branchesSelected.map((rol) => (
                                    <Chip
                                       key={rol.name}
                                       sx={{
                                          bgcolor: "#D2D2D2",
                                          color: "#162c44",
                                       }}
                                       label={
                                          <Box sx={{ display: "flex", alignItems: "center" }}>
                                             <Typography fontSize={13} fontWeight={500}>
                                                {rol.length > 1 ? rol.name?.substring(0, 15) + "..." : rol.name}
                                             </Typography>
                                             <IconButton onClick={() => handleOnDelete(rol._id, true)}>
                                                <DeleteForeverIcon sx={{ color: "#162c44" }} />
                                             </IconButton>
                                          </Box>
                                       }
                                    />
                                 ))}
                              </Stack>
                           </Box>
                        ) : null}
                     </Collapse>
                     {!props.isLoadingModules && error ? (
                        <FormHelperText error={error} sx={{ py: 2 }}>
                           {error}
                        </FormHelperText>
                     ) : null}
                     {getModule(props.userInfo.module) !== "gc" && (
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                           <Button variant="contained" color="primary" onClick={() => setOpenChangeRoles(true)}>
                              {!isLoading ? "Confirmar" : <CircularProgress color="success" size={22} />}
                           </Button>
                        </Box>
                     )}
                  </>
               )}
            </Stack>
         </Box>
         <ConfirmationModal
            isLoading={isLoading}
            open={openSubUserDeleteConfirmModal}
            setOpen={setOpenSubUserDeleteConfirmModal}
            title="Eliminar roles del módulo"
            body={
               <>
                  <Typography>¿Estás seguro de eliminar los roles del usuario?</Typography>
                  <Typography variant="body2">
                     En cualquier momento se podran asignar nuevos roles, mientras se tengan licencias suficientes.
                  </Typography>
               </>
            }
            onConfirm={handleDeleteUserRole}
         />
         <ConfirmationModal
            isLoading={isLoading}
            open={openChangeRoles}
            setOpen={setOpenChangeRoles}
            generic
            onConfirm={handleSubmitInfo}
         />
      </>
   );
};

interface PropsMapField {
   selectData: any[];
   value: string;
   onChange: (event) => void;
   text: string;
   disabled?: boolean;
}

export const MapField = (props: PropsMapField) => {
   return (
      <Box>
         <Box sx={{ display: "flex", alignItems: "center" }}>
            <FormControl sx={{ my: 1, minWidth: 120 }} size="small" fullWidth disabled={props.disabled}>
               <InputLabel id="demo-select-small-label">Seleccionar {props.text}</InputLabel>
               <Select
                  labelId="demo-select-small-label"
                  id="demo-select-small"
                  label={`Seleccionar ${props.text}`}
                  value={props.value}
                  onChange={props.onChange}
                  renderValue={(selected: any) => {
                     return <Typography>{selected.name ? selected.name : selected.service.serviceName}</Typography>;
                  }}
               >
                  {props.selectData.map((rol: any) => (
                     <MenuItem key={rol.name ? rol.name : rol.service.serviceName} value={rol}>
                        {rol.name ? rol.name : rol.service.serviceName}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
         </Box>
      </Box>
   );
};

const GCModule = ({ userRoles, userInfo, companySelected, handleClose }) => {
   interface UserCharges {
      module: any;
      charge: { _id: string; chargeName: string };
   }

   const [isLoading, setIsLoading] = useState(true);
   const [openConfirmation, setOpenConfirmation] = useState(false);
   const [governingBodies, setGoverningBodies] = useState([]);
   const [selectedGovernance, setSelectedGovernance] = useState(null);
   const [seed, setSeed] = useState(0);
   const { showSnackBar } = useContext(SnackBarContext);

   useEffect(() => {
      const fetchGoverningBodies = async () => {
         const response = await getGoberningBodiesByCompany(companySelected.company);
         setGoverningBodies(response);
         setIsLoading(false);
      };
      fetchGoverningBodies();
   }, [companySelected]);

   function getUserCharges() {
      const userCharges: UserCharges[] = [];
      if (userRoles.length === 0) return userCharges;
      for (const govBody of governingBodies) {
         const foundUser = govBody.users.find((user) => user.user === userInfo._id);
         if (!foundUser) continue;
         const chargeIndex = userRoles.findIndex((role) => role.charges.includes(foundUser.charge));
         userCharges.push({
            charge: { _id: userRoles[chargeIndex].charges[0], chargeName: userRoles[chargeIndex].chargeName[0] },
            module: govBody,
         });
      }
      return userCharges;
   }

   function reducer(state, action) {
      switch (action.type) {
         case "initialState": {
            return getUserCharges();
         }
         case "addGoverningBody": {
            return [...state, { module: selectedGovernance, charge: "" }];
         }
         case "deleteGoverningBody": {
            if (selectedGovernance?._id === action.selectedGovBody._id) setSelectedGovernance(null);
            return [...state.filter((gov) => gov.module.title !== action.selectedGovBody.title)].flat();
         }
         case "addCharge": {
            const govBodiesArray = state.map((gov) => gov.module._id);
            const govIndex = govBodiesArray.indexOf(selectedGovernance._id);
            if (govIndex === -1) return state;
            const tempArray = state;
            tempArray[govIndex].charge = action.charge;
            return tempArray;
         }
         case "deleteCharge": {
            const govBodiesArray = state.map((gov) => gov.module._id);
            const govIndex = govBodiesArray.indexOf(selectedGovernance._id);
            if (govIndex === -1) return state;
            const tempArray = state;
            tempArray[govIndex].charge = undefined;
            return tempArray;
         }
      }
   }
   const [state, dispatch] = useReducer(reducer, []);

   useEffect(() => {
      if (governingBodies.length > 0) dispatch({ type: "initialState" });
   }, [governingBodies]);

   const existingCharge = useMemo(() => {
      if (state.length === 0 || !selectedGovernance) return "";
      return state.find((gov) => gov.module?._id === selectedGovernance._id).charge;
   }, [selectedGovernance, seed]);

   function selectGoverningBody(selectedGovernance) {
      const govNames = state.map((gov) => gov.module.title);
      if (!govNames.includes(selectedGovernance.title)) dispatch({ type: "addGoverningBody" });
      const selectedGoverning = governingBodies.find((gov) => gov._id === selectedGovernance._id);
      setSelectedGovernance(selectedGoverning);
   }

   const handleOnConfirm = async () => {
      const response = await updateUserCharges(userInfo._id, state);
      if (!response) {
         showSnackBar("Error al actualizar cargos", true);
         return handleClose();
      }
      const rolesIds = [];
      const initialState = getUserCharges();
      const userCharges = state.map((state) => state.charge._id);
      const initialCharges = initialState?.map((gov) => ({
         charge: gov.charge?._id,
         governId: gov.module?._id,
      }));
      const diferentialCharges = initialCharges?.filter((chargeId) => !userCharges.includes(chargeId.charge));

      for (const gov of state) {
         if (initialCharges.map((gov) => gov.charge).includes(gov.charge._id)) continue;
         const roleId = await getRoleByCharge(gov.charge._id);
         if (roleId) rolesIds.push(roleId._id);
      }
      if (diferentialCharges?.length > 0) {
         for (const charge of diferentialCharges) {
            await deleteGovernRol(userInfo._id, charge.charge, companySelected.company, charge.governId);
         }
      }
      if (rolesIds.length > 0) await addUserRole(userInfo._id, { companyId: companySelected.company, roles: rolesIds });
      showSnackBar("Los cargos han sido actualizados correctamente", false);
      return handleClose();
   };

   return !isLoading && governingBodies?.length > 0 ? (
      <Stack spacing={2} sx={{ display: "flex" }} direction={"column"}>
         <FormControl sx={{ my: 1, minWidth: 120 }} size="small" fullWidth>
            <InputLabel id="demo-select-small-label">Seleccionar el órgano de gobierno</InputLabel>
            <Select
               defaultValue={""}
               labelId="demo-select-small-label"
               id="demo-select-small"
               label={`Seleccionar el órgano de gobierno`}
               onChange={(event) => selectGoverningBody(event.target.value)}
               value={selectedGovernance || ""}
               renderValue={(value) => (value ? value.title : null)}
            >
               {governingBodies?.map((rol: any) => (
                  <MenuItem value={rol} key={rol.title}>
                     {rol.title}
                  </MenuItem>
               )) || null}
            </Select>
            {state.map((govBody) => (
               <Box key={govBody.module.title} m={0.5}>
                  <Chip
                     sx={{
                        bgcolor: "#D2D2D2",
                        color: "#162c44",
                        ":hover": { cursor: "pointer" },
                     }}
                     label={
                        <Box
                           sx={{ display: "flex", alignItems: "center" }}
                           onClick={(e) => selectGoverningBody(govBody.module)}
                        >
                           <Typography fontSize={13} fontWeight={500}>
                              {govBody.module.title}
                           </Typography>
                           <IconButton
                              onClick={(e) => {
                                 e.stopPropagation();
                                 dispatch({ type: "deleteGoverningBody", selectedGovBody: govBody.module });
                              }}
                           >
                              <DeleteForever sx={{ color: "#162c44" }} />
                           </IconButton>
                        </Box>
                     }
                  />
               </Box>
            ))}
         </FormControl>
         <Collapse in={selectedGovernance} unmountOnExit key={selectedGovernance?._id || "none"}>
            {selectedGovernance ? (
               <Box sx={{ mb: 2 }}>
                  <CargoSelectorComponent
                     governData={selectedGovernance}
                     governUsers={[]}
                     companyUsers={[]}
                     isLoading={false}
                     label="Cargo a desempeñar"
                     setSelectedRole={(charge) => {
                        dispatch({ type: "addCharge", charge: charge });
                        setSeed((s) => s + 1);
                     }}
                     disableClearable
                     chargeName="Cargo a desempeñar"
                  />
               </Box>
            ) : null}

            <Collapse in={selectedGovernance && existingCharge} unmountOnExit>
               {
                  <Chip
                     sx={{
                        bgcolor: "#D2D2D2",
                        color: "#162c44",
                     }}
                     label={
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                           <Typography fontSize={13} fontWeight={500}>
                              {existingCharge?.chargeName}
                           </Typography>
                           <IconButton
                              onClick={() => {
                                 dispatch({ type: "deleteCharge" });
                                 setSeed((s) => s + 1);
                              }}
                           >
                              <DeleteForever sx={{ color: "#162c44" }} />
                           </IconButton>
                        </Box>
                     }
                  />
               }
            </Collapse>
         </Collapse>
         <Box display={"flex"} justifyContent={"flex-end"}>
            <Button
               onClick={() => setOpenConfirmation(true)}
               sx={{ bgcolor: "#162c44", color: "white", ":hover": { bgcolor: "#162c44" } }}
            >
               <Typography>Confirmar</Typography>
            </Button>
         </Box>
         <ConfirmationModal
            isLoading={isLoading}
            open={openConfirmation}
            setOpen={setOpenConfirmation}
            generic
            onConfirm={handleOnConfirm}
         />
      </Stack>
   ) : (
      <Typography>Sin órganos de gobierno</Typography>
   );
};
