import { useState, useEffect, useContext } from "react";
import { IGovernanceBody } from "../../../types/governance.types";
import { getUsersDataByGoverningBody } from "../../../lib/usersBEClient";
import { UserContext } from "../../../context/userContext";

function sleep(duration: number): Promise<void> {
   return new Promise<void>((resolve) => {
      setTimeout(() => {
         resolve();
      }, duration);
   });
}

const tagsMock = [
   {
      _id: "1",
      title: "test 1",
      color: "green",
   },
   {
      _id: "2",
      title: "test 2",
      color: "red",
   },
   {
      _id: "3",
      title: "test 3",
      color: "blue",
   },
];

export const useGetTags = () => {
   const [tags, setTags] = useState([]);
   const [loading, setLoading] = useState(true);

   useEffect(() => {
      (async () => {
         await sleep(1e3); // For demo purposes.

         setTags([...tagsMock]);

         setLoading(false);
      })();
   }, []);

   return { loading, tags };
};

export const useGetUsersByGovernance = (gobernanceBodyId: any | null) => {
   const { user } = useContext(UserContext);
   const [users, setUsers] = useState([]);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      if (gobernanceBodyId) {
         (async () => {
            setLoading(true);
            let governUsers = gobernanceBodyId.users.filter((e) => e.user !== user.id);
            if (governUsers.length > 0) {
               const ids = governUsers.map((u) => u.user);
               const userData = await getUsersDataByGoverningBody(ids);
               setUsers(userData);
               setLoading(false);
            } else {
               setUsers([]);
               setLoading(false);
            }
         })();
      }
   }, [gobernanceBodyId]);

   return { loading, users };
};

export const useGetUsersByGovernance2 = (gobernanceBodyId: any | null) => {
   const [users, setUsers] = useState([]);
   const [loading, setLoading] = useState(false);

   useEffect(() => {
      if (gobernanceBodyId) {
         (async () => {
            setLoading(true);
            const ids = gobernanceBodyId.users.map((u) => u.user);
            const userData = await getUsersDataByGoverningBody(ids);
            setUsers(userData);
            setLoading(false);
         })();
      }
   }, [gobernanceBodyId]);

   return { loading, users };
};
