import { Box, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import SearchIcon from "@mui/icons-material/Search";

export const TextFilterComponent = ({ setFilterText }) => {
   const [text, setText] = useState("");
   return (
      <Box sx={{ flex: 4 }}>
         <TextField
            placeholder="Buscar..."
            value={text}
            onChange={(e) => {
               setText(e.currentTarget.value);
               setFilterText(e.currentTarget.value);
            }}
            sx={{ bgcolor: "white" }}
            fullWidth
            size="small"
            InputProps={{
               startAdornment: (
                  <InputAdornment position="start">
                     <SearchIcon />
                  </InputAdornment>
               ),
            }}
         />
      </Box>
   );
};
