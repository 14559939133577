import { useNavigate } from "react-router-dom";
import { HeadCell } from "../components/TableComponent";
import GetTablesFilters from "../hooks/tables/getTablesFilters";
import { capitalizeFirstLetter, getDateFromISODate } from "../const/globalConst";
import GetTableComponent from "../hooks/tables/getTableComponent";
import { useContext } from "react";
import { ComplaintContext } from "../context/complaintContext";
import GetFilteredComplaints from "../hooks/complaints/getFilteredComplaints";

const columns: HeadCell[] = [
   { field: "reportNumber", headerName: "No. reporte", type: "string" },
   { field: "company", headerName: "Empresa/Sucursal", type: "string" },
   { field: "complainerType", headerName: "Denuncia", type: "string" },
   { field: "complaintType", headerName: "Tipo", type: "string" },
   { field: "category", headerName: "Clasificación", type: "string" },
   { field: "updatedAt", headerName: "Fecha de finalización", type: "string" },
   { field: "status", headerName: "Estatus", type: "string" },
];

export const QueryBankTable = () => {
   const navigate = useNavigate();
   const { loadingComplaints, complaints } = useContext(ComplaintContext);
   const filteredComplaints = GetFilteredComplaints(complaints, "cerrada");
   const branchesFilter = GetTablesFilters((complaints as any).get("cerrada"), "branches");

   const rows =
      !loadingComplaints &&
      filteredComplaints?.map((complaint) => ({
         reportNumber: complaint.complaint.reportNumber,
         complaintType: capitalizeFirstLetter(complaint.complaint.type || "denuncia"),
         complainerType: complaint.complaint.complainerType,
         category: complaint.complaint.category,
         updatedAt: getDateFromISODate(complaint.complaint.updatedAt),
         sentDate: complaint.complaint.sentDate || complaint.complaint.createdAt,
         status: capitalizeFirstLetter(complaint.complaint.status),
         company: complaint.branch?.name || complaint.company.person_details.comercialName,
      }));

   const filters = [
      { allOption: "Todas", data: branchesFilter, id: "company", label: "Sucursales", type: "select" },
      { allOption: "", data: [], id: "reportNumber", label: "No. de reporte", type: "number" },
      {
         allOption: "Todos",
         data: ["Cerrada", "Improcedente"],
         id: "status",
         label: "Estatus",
         type: "select",
      },
   ];
   const filterInitialState = {
      company: "Todas",
      reportNumber: "",
      status: "Todos",
   };

   return GetTableComponent({
      defaultOrder: "date",
      defaultText: "No hay denuncias en este momento",
      tableHeaders: columns,
      tableLoader: loadingComplaints,
      tableRow: rows,
      selectedRow: (e, row) => navigate(row.reportNumber),
      tableFilters: filters,
      tableInitialState: filterInitialState,
   });
};
