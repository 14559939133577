import { Stack, Typography } from "@mui/material";

export const SessionCommitment = ({ commitment }) => {
   return (
      <Stack sx={{ px: 2, py: 2.5, gap: 1, borderBottom: 1, borderColor: "#E0E0E0" }}>
         <Typography sx={{ fontWeight: 500, fontSize: 13 }}>{commitment.activity}</Typography>
         <Typography sx={{ color: "#9E9E9E", fontSize: 9 }}>
            Acuerdo y/o compromiso creado durante la sesión.
         </Typography>
      </Stack>
   );
};
