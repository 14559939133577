import { ProcessedEvent } from "@aldabil/react-scheduler/types";
import { Box, Typography } from "@mui/material";
import VideoChatIcon from "@mui/icons-material/VideoChat";
import { getContrastYIQ } from "../../../../const/globalConst";
import { UserContext } from "../../../../context/userContext";
import { useContext } from "react";

interface EventRendererProps {
   event: ProcessedEvent;
   props: any;
}
export const EventRenderer = (propsComponent: EventRendererProps) => {
   const { companySelected } = useContext(UserContext);
   const { event, props } = propsComponent;
   const getDayFromDate = (date: Date) => {
      const day = date
         .toLocaleTimeString("es-MX", {
            weekday: "long",
         })
         .split(" ")[0]
         .slice(0, 3);
      return day.charAt(0).toUpperCase() + day.slice(1);
   };

   const contrastColor = getContrastYIQ(companySelected?.company_details?.primaryColor || "#162c44");
   return (
      <Box
         {...props}
         sx={{
            display: "flex",
            bgcolor: event.color,
            flexDirection: "column",
            height: "100%",
            border: 1,
            borderColor: event.color,
            borderRadius: 2,
            p: 0.5,
            justifyContent: "space-between",
         }}
         // className="event"
         color={contrastColor}
      >
         <Typography variant="caption" fontSize={12} fontWeight={600}>
            {getDayFromDate(event.start) === getDayFromDate(event.end)
               ? event.title.length > 19
                  ? event.title.slice(0, 19) + " ..."
                  : event.title
               : event.title}
         </Typography>
         {/* {event.meetingURL && (
            <Box
               display={"flex"}
               alignSelf={"center"}
               alignItems={"center"}
               gap={1}
               bgcolor={"white"}
               border={1}
               borderRadius={2}
               borderColor={event.color}
               p={0.5}
               onClick={() => {
                  window.open(event.meetingURL);
               }}
            >
               <VideoChatIcon sx={{ color: "#162C44", fontSize: 20 }} />
               <Typography fontSize={12} color={"#162C44"}>
                  Reunión
               </Typography>
            </Box>
         )} */}

         <Box
            paddingBottom={1}
            sx={{
               display: getDayFromDate(event.start) !== getDayFromDate(event.end) && "flex",
               justifyContent: getDayFromDate(event.start) !== getDayFromDate(event.end) && "space-between",
            }}
         >
            <Box sx={{ display: "flex" }}>
               <Typography variant="caption">{getDayFromDate(event.start)}</Typography>
               {getDayFromDate(event.start) === getDayFromDate(event.end) ? null : (
                  <Typography variant="caption">{getDayFromDate(event.end)}</Typography>
               )}
            </Box>

            <Typography variant="caption">
               {event.start.toLocaleTimeString("es-MX", {
                  timeStyle: "short",
                  hour12: true,
               })}
            </Typography>
         </Box>
      </Box>
   );
};
