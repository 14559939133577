import { Box, Typography } from "@mui/material";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { IconButton } from "@mui/material";
import { ComplaintStepperContext } from "../../context/complaintStepperContext";
import { useContext, useState } from "react";
import { CustomModalComponent } from "../CustomModalComponent";
import { capitalizeFirstLetter, tableStatusColors } from "../../const/globalConst";
import CircleIcon from "@mui/icons-material/Circle";
import { CommonDetails } from "./CommonDetails";

export const ClosingFormComplaint = () => {
   const [openModal, setOpenModal] = useState(false);
   const { complaint } = useContext(ComplaintStepperContext);

   return (
      <>
         <Box
            sx={{
               bgcolor: "white",
               minHeight: "100%",
               p: 2,
               boxShadow: 3,
               display: "flex",
               justifyContent: "space-between",
               flexWrap: "wrap",
            }}
         >
            <Box sx={{ display: "flex" }}>
               <Typography
                  variant="h6"
                  sx={{ pl: 3, pr: 1, fontWeight: "600", display: "flex", alignItems: "center", fontSize: "1.1rem" }}
                  align="center"
               >
                  Expediente del caso
               </Typography>
               <IconButton
                  sx={{ mt: 0.5 }}
                  onClick={() => {
                     setOpenModal(true);
                  }}
               >
                  <RemoveRedEyeIcon />
               </IconButton>
            </Box>
            <Box sx={{ display: "flex" }}>
               <Typography
                  variant="h6"
                  sx={{
                     pl: 3,
                     pr: 1,
                     fontWeight: "600",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     fontSize: "1.1rem",
                  }}
                  align="center"
               >
                  Número de reporte:&nbsp; <span style={{ fontWeight: "400" }}>{complaint.reportNumber}</span>
               </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
               <Typography
                  variant="h6"
                  sx={{ pl: 3, fontWeight: "600", display: "flex", alignItems: "center", fontSize: "1.1rem" }}
                  align="center"
               >
                  Estatus:&nbsp; <span style={{ fontWeight: "400" }}>{capitalizeFirstLetter(complaint.status)}</span>
               </Typography>
               <CircleIcon
                  sx={{
                     width: 10,
                     color: tableStatusColors(complaint.status),
                     ml: 1,
                     mt: 0.5,
                     mr: 4,
                  }}
               />
            </Box>
         </Box>
         <CustomModalComponent
            open={openModal}
            setOpen={setOpenModal}
            title={`Expediente de ${capitalizeFirstLetter(complaint?.type || "denuncias")}`}
         >
            <Box
               sx={{
                  height: "500px", // Ajusta la altura según tus necesidades
                  overflowY: "auto",
               }}
            >
               <CommonDetails />
            </Box>
         </CustomModalComponent>
      </>
   );
};
