import { Box, Typography, CircularProgress, Stack, Button } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../context/userContext";
import { FilesInformationComponent } from "../../ProfileScreen/SubComponents/FilesInformationComponent";
import { DeleteAFilesByUserId, getFilesByUserId } from "../../../../lib/gobCorpBEClient";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import HourglassTopIcon from "@mui/icons-material/HourglassTop";
import { deletePdfEvidence } from "../../../../lib/usersBEClient";
import { useParams } from "react-router-dom";
import { CreateProfileContext } from "../../../../context/beneficiaryContext/createProfileContext";

const useGetFiles = (seed: number, setPersonalFilesId: Function, isShareholder: boolean) => {
   const [files, setFiles] = useState<any>(null);
   const [isLoading, setIsLoading] = useState<boolean>(true);
   const { user } = useContext(UserContext);
   const { fileToDelete } = useContext(CreateProfileContext);
   const { mutate } = DeleteAFilesByUserId(files ? files._id : "");
   const [refreshFiles, setRefreshFiles] = useState(false);
   const { companyId } = useParams();

   useEffect(() => {
      setIsLoading(true);
      const fetch = async () => {
         const data = await getFilesByUserId(user.id, companyId, isShareholder);
         if (data) {
            setPersonalFilesId(data._id);
            setFiles(data);
         }
         setIsLoading(false);
      };
      if (user.id.length > 0) fetch();
   }, [seed, user, refreshFiles]);

   useEffect(() => {
      if (files && fileToDelete) {
         const key = () => {
            for (const key in files) {
               if (Object.prototype.hasOwnProperty.call(files, key)) {
                  const document = files[key];
                  if (document) if (document.name === fileToDelete.name) return key;
               }
            }
         };
         const newFiles = { ...files, [key()]: null };
         const updateFiles = () => {
            mutate(newFiles, {
               onError: (error) => {
                  console.error(error);
               },
               onSuccess: async (data) => {
                  setRefreshFiles(!refreshFiles);
                  await deletePdfEvidence(
                     "files-lecosy",
                     { folder: `gc/users/${user.id}/files/${files._id}` },
                     fileToDelete.name
                  );
               },
            });
         };

         updateFiles();
      }
   }, [fileToDelete]);

   return { files, isLoading };
};

export const DocumentationUpload = () => {
   const [seed, setSeed] = useState(0);
   const { activeStep, disableButton, handleNext, setPersonalFilesId } = useContext(CreateProfileContext);
   const { user } = useContext(UserContext);
   const isShareholder = user?.role?.includes("N1");
   const { files, isLoading } = useGetFiles(seed, setPersonalFilesId, isShareholder);

   // const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
   // const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
   // const handlePopoverClose = () => setAnchorEl(null);
   // const open = Boolean(anchorEl);

   const fileArray = [
      { label: "Curriculum vitae o semblanza personal", name: "cv" },
      { label: "Copia de título profesional", name: "tituloProfesional" },
      { label: "Cédula profesional", name: "cedulaProfesional" },
      { label: "Historial en buró de crédito", name: "historialCrediticio" },
      { label: "Carta de recomendación 1", name: "cartaRecomendacion1" },
      { label: "Carta de recomendación 2", name: "cartaRecomendacion2" },
      { label: "Carta de recomendación 3", name: "cartaRecomendacion3" },
      { label: "Acta de nacimiento", name: "actaNacimiento" },
      { label: "Credencial de elector", name: "credencialElector" },
      { label: "Firma", name: "firmaEscaneada" },
      { label: "Comprobante de domicilio", name: "comprobanteDomicilio" },
      { label: "Buró Legal", name: "buroLegal" },
   ];

   if (isShareholder) fileArray.push({ label: "Titulo accionario", name: "tituloAccionario" });
   if (isLoading)
      return (
         <Box sx={{ display: "flex", justifyContent: "center", py: 2 }}>
            <CircularProgress />
         </Box>
      );

   return (
      <>
         <Stack spacing={2} key={seed} sx={{ overflowY: "auto", height: "50vh", py: 2, px: 4 }}>
            {fileArray.map((fileNames, index) => (
               <Box
                  key={`file-${index}`}
                  sx={{ display: "flex", justifyContent: "center", alignItems: "center", columnGap: 1 }}
               >
                  {files[`${fileNames.name}`] ? (
                     files[`${fileNames.name}`].verified === undefined ? (
                        <HourglassTopIcon />
                     ) : files[`${fileNames.name}`].verified ? (
                        <CheckIcon />
                     ) : (
                        <CloseIcon />
                     )
                  ) : (
                     <CloseIcon sx={{ color: "white" }} />
                  )}
                  {/* <Typography sx={{ fontWeight: 600, fontSize: 13, color: "#585757" }}>{fileNames.label}</Typography>
                  {fileNames.name === "firmaEscaneada" && (
                     <>
                        <Popover
                           id="mouse-over-popover"
                           sx={{
                              pointerEvents: "none",
                           }}
                           open={open}
                           anchorEl={anchorEl}
                           anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "left",
                           }}
                           transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                           }}
                           onClose={handlePopoverClose}
                           disableRestoreFocus
                        >
                           <Typography sx={{ p: 1, fontSize: 14, width: 400 }}>
                              La imagen de la firma deberá ser legible, de color azul y en formato JPG, PNG. El uso de
                              la firma será para las sesiones que se convoquen dentro del sistema.
                           </Typography>
                        </Popover>
                        <Box onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                           <InfoIcon sx={{ color: "#7D8592", fontSize: 18 }} />
                        </Box>
                     </>
                  )} */}

                  <FilesInformationComponent
                     file={files ? files[`${fileNames.name}`] : null}
                     fileName={fileNames.label}
                     fileNameToUpload={fileNames.name}
                     id={files ? files._id : null}
                     setSeed={setSeed}
                     notDisplaySize
                     isVerfied={
                        files &&
                        files[`${fileNames.name}`] &&
                        (files[`${fileNames.name}`].verified !== undefined
                           ? files[`${fileNames.name}`].verified
                           : false)
                     }
                  />
               </Box>
            ))}
         </Stack>
         <Stack
            spacing={2}
            sx={{
               alignItems: "center",
               py: 2,
            }}
         >
            {activeStep === 0 && (
               <Typography sx={{ fontSize: 14, fontWeight: 400, textAlign: "center", color: "#969696" }}>
                  El sistema enviará a verificación los documentos.
               </Typography>
            )}
            <Button variant="contained" onClick={() => handleNext()} disabled={disableButton} sx={{ width: 300 }}>
               Continuar
            </Button>
         </Stack>
      </>
   );
};
