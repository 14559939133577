import { Box, Button, Checkbox, FormControlLabel, MenuItem, Select, Stack, TextField, Typography } from "@mui/material";
import { BeneficiaryDocument } from "./BeneficiaryConfiguration";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Form, Formik, FormikProps } from "formik";
import "dayjs/locale/es";
import { updateBeneficiaryApplicationConfig } from "../../../lib/usersBEClient";
import { useParams } from "react-router-dom";
import { useContext, useState } from "react";
import { SnackBarContext } from "../../../context/snackBarContext";
import { ConfirmationModal } from "../../ConfirmationModal";
dayjs.locale("es");

const EditDocumentPeriodModal = (props: {
   document: BeneficiaryDocument;
   setState: Function;
   refreshTable: Function;
}) => {
   const { document, setState, refreshTable } = props;
   const [isLoading, setIsLoading] = useState(false);
   const [openConfirmation, setOpenConfirmation] = useState(false);
   const { showSnackBar } = useContext(SnackBarContext);
   const { companyId } = useParams();

   const handleSubmit = async (values) => {
      setIsLoading(true);
      try {
         const data = {
            fileName: document.fileName,
            updateData: {
               fileName: document.fileName,
               fileType: "PDF",
               maxFileSize: values.docSize * (1024 * 1024),
               requestDate: values.requestDate.$d,
               periodType: values.period === "custom" ? values.customConfig.type : values.period,
               periodFrequency: values.period === "custom" ? values.customConfig.cuantity : null,
               endOn: values.customConfig.withOutEndDate ? null : values.customConfig.endOn.$d,
            },
         };
         await updateBeneficiaryApplicationConfig(companyId, data);
         showSnackBar("Solicitud de documento guardada correctamente", false);
         refreshTable();
         setState(false);
      } catch (error) {
         console.log(error);
      }
      setIsLoading(false);
   };

   return (
      <Formik
         initialValues={{
            docSize: document.maxFileSize ?? 10,
            requestDate: document.requestDate ? dayjs(document.requestDate) : dayjs(new Date()),
            period: document.periodFrequency ? "custom" : document.periodType ?? "none",
            customConfig: {
               cuantity: document.periodFrequency ?? 1,
               type: document.periodFrequency ? document.periodType : "daily",
               endOn: document.endOn ? dayjs(document.endOn) : dayjs(new Date()),
               withOutEndDate: !document.endOn ? true : false,
            },
         }}
         onSubmit={handleSubmit}
      >
         {(formProps: FormikProps<any>) => (
            <Form>
               <Stack spacing={3} sx={{ p: 4, width: 700, height: 400 }}>
                  <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                     <Typography>Nombre del documento:</Typography>
                     <Typography sx={{ fontWeight: 600 }}>{document.fileName}</Typography>
                  </Box>
                  <Box sx={{ display: "flex", columnGap: 5 }}>
                     <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                        <Typography>Tipo de archivo:</Typography>
                        <Typography
                           sx={{ p: 1, bgcolor: "#F5F5F5", borderRadius: 2, border: 1, borderColor: "#E0E0E0" }}
                        >
                           PDF
                        </Typography>
                     </Box>
                     <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                        <Typography>Tamaño:</Typography>
                        <TextField
                           variant="outlined"
                           size="small"
                           value={formProps.values.docSize}
                           onChange={(event) => {
                              if (/^\d{0,3}$/.test(event.target.value))
                                 formProps.setFieldValue("docSize", event.target.value);
                           }}
                           inputProps={{ maxLength: 3 }}
                           sx={{ bgcolor: "#F5F5F5", borderColor: "#E0E0E0", maxWidth: 60 }}
                        />
                        MB
                     </Box>
                  </Box>
                  <Box sx={{ display: "flex", columnGap: 5, alignItems: "center" }}>
                     <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                        <Typography>Fecha de solicitud:</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                           <DatePicker
                              name="period"
                              value={formProps.values.requestDate}
                              onChange={(event: any) => formProps.setFieldValue("requestDate", dayjs(event.$d))}
                              format="DD / MMMM / YYYY"
                              views={["year", "month", "day"]}
                              slotProps={{
                                 textField: {
                                    variant: "outlined",
                                    size: "small",
                                 },
                              }}
                              sx={{ bgcolor: "#F5F5F5", borderColor: "#E0E0E0", maxWidth: 235 }}
                           />
                           <Select
                              variant="outlined"
                              size="small"
                              value={formProps.values.period}
                              defaultValue="none"
                              onChange={(event: any) => formProps.setFieldValue("period", event.target.value)}
                              sx={{ bgcolor: "#F5F5F5", borderColor: "#E0E0E0", minWidth: 200 }}
                           >
                              <MenuItem value="none">No repetir</MenuItem>
                              <MenuItem value="weekly">
                                 Semanal, cada {formProps.values.requestDate.format("dddd")}
                              </MenuItem>
                              <MenuItem value="monthly">
                                 Mensual, {formProps.values.requestDate.format("DD")} del mes
                              </MenuItem>
                              <MenuItem value="annually">
                                 Anual, {formProps.values.requestDate.format("DD MMMM")}
                              </MenuItem>
                              <MenuItem value="custom">Personalizar</MenuItem>
                           </Select>
                        </LocalizationProvider>
                     </Box>
                  </Box>
                  {formProps.values.period === "custom" && (
                     <>
                        <Typography sx={{ fontWeight: 600 }}>Personalizar configuración</Typography>
                        <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                           <Typography>Repetir cada</Typography>
                           <TextField
                              variant="outlined"
                              size="small"
                              value={formProps.values.customConfig.cuantity}
                              onChange={(event) => {
                                 if (/^\d{0,3}$/.test(event.target.value))
                                    formProps.setFieldValue("customConfig.cuantity", event.target.value);
                              }}
                              inputProps={{ maxLength: 3 }}
                              sx={{ bgcolor: "#F5F5F5", borderColor: "#E0E0E0", maxWidth: 60 }}
                           />
                           <Select
                              variant="outlined"
                              size="small"
                              value={formProps.values.customConfig.type}
                              onChange={(event: any) =>
                                 formProps.setFieldValue("customConfig.type", event.target.value)
                              }
                              sx={{ bgcolor: "#F5F5F5", borderColor: "#E0E0E0", minWidth: 100 }}
                           >
                              <MenuItem value="daily">Día (s)</MenuItem>
                              <MenuItem value="weekly">Semana (s)</MenuItem>
                              <MenuItem value="monthly">Mes (es)</MenuItem>
                              <MenuItem value="annually">Año (s)</MenuItem>
                           </Select>
                           {formProps.values.customConfig.type !== "daily" && (
                              <Select
                                 disabled
                                 variant="outlined"
                                 size="small"
                                 value={formProps.values.customConfig.type}
                                 onChange={(event: any) =>
                                    formProps.setFieldValue("customConfig.type", event.target.value)
                                 }
                                 sx={{ bgcolor: "#F5F5F5", borderColor: "#E0E0E0", minWidth: 100 }}
                              >
                                 <MenuItem value="weekly">Cada {formProps.values.requestDate.format("dddd")}</MenuItem>
                                 <MenuItem value="monthly">
                                    Cada {formProps.values.requestDate.format("DD")} del mes
                                 </MenuItem>
                                 <MenuItem value="annually">{formProps.values.requestDate.format("DD MMMM")}</MenuItem>
                              </Select>
                           )}
                        </Box>
                        <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                           <Typography>Finalizar en</Typography>
                           <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es">
                              <DatePicker
                                 minDate={formProps.values.requestDate}
                                 disabled={formProps.values.customConfig.withOutEndDate}
                                 value={formProps.values.customConfig.endOn}
                                 onChange={(event: any) =>
                                    formProps.setFieldValue("customConfig.endOn", dayjs(event.$d))
                                 }
                                 format="DD / MMMM / YYYY"
                                 views={["year", "month", "day"]}
                                 slotProps={{
                                    textField: {
                                       variant: "outlined",
                                       size: "small",
                                    },
                                 }}
                                 sx={{ bgcolor: "#F5F5F5", borderColor: "#E0E0E0", maxWidth: 240 }}
                              />
                           </LocalizationProvider>
                           <FormControlLabel
                              sx={{ ml: "auto" }}
                              control={
                                 <Checkbox
                                    checked={formProps.values.customConfig.withOutEndDate}
                                    size="small"
                                    onChange={() =>
                                       formProps.setFieldValue(
                                          "customConfig.withOutEndDate",
                                          !formProps.values.customConfig.withOutEndDate
                                       )
                                    }
                                 />
                              }
                              label={<Typography>No establecer fecha final</Typography>}
                           />
                        </Box>
                     </>
                  )}
               </Stack>
               <Box sx={{ p: 1, bgcolor: "#F5F5F5", display: "flex", justifyContent: "flex-end", columnGap: 2 }}>
                  <Button variant="text" onClick={() => setState(false)} sx={{ color: "#ABAFB1" }}>
                     Cancelar
                  </Button>
                  <Button variant="text" onClick={() => setOpenConfirmation(true)} sx={{ color: "#162c44" }}>
                     Guardar
                  </Button>
               </Box>
               <ConfirmationModal
                  open={openConfirmation}
                  setOpen={setOpenConfirmation}
                  onConfirm={() => formProps.submitForm()}
                  title={"Confirmar configuración"}
                  isLoading={isLoading}
                  body={
                     <Stack spacing={1} sx={{ textAlign: "center" }}>
                        <Typography>¿Está seguro que desea guardar la configuración establecida?</Typography>
                        <Typography
                           sx={{ display: "flex", justifyContent: "left" }}
                           fontStyle={"italic"}
                           color={"red"}
                           fontSize={13}
                        >
                           Una vez concluido, se establecerá la configuración en la solicitud de los documentos.
                        </Typography>
                     </Stack>
                  }
               />
            </Form>
         )}
      </Formik>
   );
};

export default EditDocumentPeriodModal;
