import { Box, Button } from "@mui/material";
import { useContext } from "react";
import { ComplaintStepperContext } from "../../../context/complaintStepperContext";
import { DocumentsTable } from "../DocumentsTable";

export const NotificationStep = () => {
   const { complaint, setOpenCompleteDialog, checkDocuments } = useContext(ComplaintStepperContext);
   return (
      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "50vh" }}>
         <Box sx={{ flex: 2 }}>
            <DocumentsTable
               rows={[
                  {
                     document: `Notificación de resolucion (${complaint.type || "denuncia"})`,
                     documentName: `notificacion-resolucion-${complaint.type || "denuncia"}`,
                     templateLink: `notificacion/5`,
                     fileHeader:
                        complaint.category === "Quejas"
                           ? "Añadir documento de notificación de resolución (Queja)"
                           : "Añadir documento de notificación de resolución (Denuncia)",
                  },
               ]}
            />
         </Box>
         <Box sx={{ display: "flex", flexDirection: "row", mt: 2, mr: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
               disabled={!checkDocuments("notification")}
               onClick={() => setOpenCompleteDialog(true)}
               variant="outlined"
            >
               {`Completar notificación de resolución`}
            </Button>
         </Box>
      </Box>
   );
};
