import { Comment } from "@mui/icons-material";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { Comments } from "../../../../SchedulerScreen/SubComponents/Comments";
import { useState } from "react";

interface CommentsComponentProps {
   users?: any[];
   comment: any[];
   submitComment: Function;
}

export const CommentsComponent = (props: CommentsComponentProps) => {
   const [commentText, setCommentText] = useState("");
   const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      setCommentText(event.target.value);
   };
   const handleSubmitComment = () => {
      setCommentText("");
      props.submitComment(commentText);
   };
   return (
      <Stack spacing={2}>
         <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
            <Comment sx={{ fontSize: 15 }} />
            <Typography variant="body2">Comentarios ({`${props.comment?.length}`})</Typography>
         </Box>
         {props.comment?.length > 0 ? (
            <Box>
               {props.comment.map((e, i) => (
                  <Comments
                     key={i}
                     comment={e}
                     user={props.users ? props.users.find((user) => user.id === e.user) : ""}
                  />
               ))}
            </Box>
         ) : (
            <Typography variant="body2" fontWeight={600}>
               Sin comentarios
            </Typography>
         )}
         <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
            <TextField
               type="text"
               size="small"
               placeholder="Comentario"
               fullWidth
               value={commentText}
               onChange={handleCommentChange}
               sx={{ width: "90%" }}
               onKeyDown={(e) => {
                  if (e.key === "Enter") {
                     e.preventDefault();
                     handleSubmitComment();
                  }
               }}
            />
            <Button variant="text" onClick={handleSubmitComment} sx={{ width: "10%" }}>
               Enviar
            </Button>
         </Box>
      </Stack>
   );
};
