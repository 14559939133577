import {
   Box,
   Typography,
   Button,
   CircularProgress,
   Collapse,
   Card,
   CardContent,
   CardActions,
   IconButton,
} from "@mui/material";
import { useContext, useState, useCallback } from "react";
import { ComplaintStepperContext } from "../../context/complaintStepperContext";
import { SnackBarContext } from "../../context/snackBarContext";
import { uploadFilePut } from "../../lib/lecosyBackendClient";
import DeleteIcon from "@mui/icons-material/Delete";

import { useDropzone } from "react-dropzone";
import { getUrlForDocumentationUpload } from "../../lib/usersBEClient";

export const UploadFile = () => {
   const {
      header,
      setFileToUpload,
      fileToUpload,
      setSubSteps,
      fileName,
      selectedData,
      complaint,
      getComplaint,
      selectedWitness,
   } = useContext(ComplaintStepperContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [isLoadingUploadFile, setIsLoadingUploadFile] = useState(false);

   const uploadDocument = async () => {
      setIsLoadingUploadFile(true);
      if (!fileToUpload) {
         showSnackBar("Favor de añadir un archivo", true);
         setIsLoadingUploadFile(false);
         return;
      }
      if (fileToUpload.size > 5000000) {
         showSnackBar("Archivo mayor a 5 MB", true);
         setIsLoadingUploadFile(false);
         return;
      }
      if (fileToUpload.type !== "application/pdf") {
         showSnackBar("Favor de añadir solo archivos PDF", true);
         setIsLoadingUploadFile(false);
         return;
      }
      const updatedComplaint = await getUrlForDocumentationUpload(
         "files-lecosy",
         { folder: `docs/${complaint.reportNumber}` },
         `${fileName}-${
            selectedData.index !== 0
               ? selectedWitness
                  ? `${complaint.reportNumber}-${selectedData.index}-${selectedWitness.index}`
                  : `${complaint.reportNumber}-${selectedData.index}`
               : complaint.reportNumber
         }`,
         complaint.reportNumber
      );
      if (updatedComplaint) {
         await uploadFilePut(updatedComplaint, fileToUpload);
      } else {
         showSnackBar("Este documento ya fue enviado.", true);
         setIsLoadingUploadFile(false);
         setFileToUpload(null);
         return;
      }
      showSnackBar("Se subió el archivo correctamente.", false);
      setIsLoadingUploadFile(false);
      setFileToUpload(null);
      setSubSteps(0);
      getComplaint(complaint.reportNumber);
   };

   const onDrop = useCallback(
      (acceptedFiles) => {
         if (acceptedFiles.length < 1) {
            showSnackBar("Favor de seleccionar un archivo valido.", true);
         }
         if (acceptedFiles[0].type.includes("pdf") && acceptedFiles.size > 5000000) {
            showSnackBar("Archivo mayor a 5 MB", true);
            return;
         }
         setFileToUpload(acceptedFiles[0]);
      },
      [showSnackBar, setFileToUpload]
   );

   const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: {
         "application/pdf": [".pdf"],
      },
      maxFiles: 1,
   });

   return (
      <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
         <Box
            sx={{
               flex: 1,
               display: "flex",
               justifyContent: "center",
               flexDirection: "column",
               alignItems: "center",
               pt: 4,
               gap: 1,
               mb: 2,
            }}
         >
            <Typography>{header}</Typography>

            <Box sx={{ bgcolor: "whitesmoke", borderRadius: 2, p: 1 }}>
               <Typography fontSize={14}>Solo archivos PDF, no mayores a 5 MB.</Typography>
            </Box>
         </Box>

         <Box
            sx={{
               display: "flex",
               alignItems: "center",
               justifyContent: "center",
               flexDirection: "column",
               flex: 2,
            }}
         >
            <Box
               sx={{
                  flex: 1,
                  minWidth: "80%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  mb: 2,
               }}
            >
               <Box
                  sx={{
                     flex: 1,
                     border: 1,
                     borderStyle: "dashed",
                     minHeight: 150,
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                  }}
                  {...getRootProps()}
               >
                  <Typography variant="body2" color={"grey"}>
                     Arrastra y suelta dentro del recuadro los archivos
                  </Typography>
                  <input {...getInputProps()} />
               </Box>
            </Box>
            <Collapse in={fileToUpload ? true : false}>
               <Typography align="center">Archivo adjunto:</Typography>
               <Box sx={{ pb: 1, bx: 1 }}>
                  {fileToUpload ? (
                     <Card
                        key={fileToUpload.name}
                        sx={{
                           display: "flex",
                           bgcolor: "#F9F9F9",
                           flexDirection: "row",
                           justifyContent: "space-between",
                           mt: 0.5,
                           boxShadow: 0,
                           border: 1,
                           borderColor: "#E2E2E2",
                        }}
                     >
                        <CardContent>
                           <Typography sx={{ fontSize: 14, flexWrap: "nowrap" }}>{fileToUpload.name}</Typography>
                        </CardContent>
                        <CardActions>
                           <IconButton
                              onClick={() => {
                                 setFileToUpload(null);
                              }}
                           >
                              <DeleteIcon sx={{ color: "red" }} />
                           </IconButton>
                        </CardActions>
                     </Card>
                  ) : null}
               </Box>
            </Collapse>
         </Box>
         <Box
            sx={{
               display: "flex",
               flexDirection: "row",
               justifyContent: "space-between",
               pt: 6,
               px: 5,
               flex: 1,
            }}
         >
            <Button
               onClick={() => {
                  setSubSteps(0);
                  setFileToUpload(null);
               }}
               variant="outlined"
               sx={{ maxHeight: 40 }}
            >
               Volver
            </Button>
            <Button onClick={uploadDocument} variant="outlined" sx={{ maxHeight: 40 }}>
               {isLoadingUploadFile ? <CircularProgress size={24} /> : "Subir"}
            </Button>
         </Box>
      </Box>
   );
};
