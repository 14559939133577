import { Box } from "@mui/material";
import { CustomInputStyle } from "../../Inputs/InputStyled";
import { CalendarMonth, HourglassBottom, HourglassTop } from "@mui/icons-material";
import dayjs from "dayjs";
dayjs.locale("es-MX");

export const HoursComponent = ({ formProps }) => {
   return (
      <Box sx={{ display: "flex", columnGap: 1 }}>
         <CustomInputStyle
            icon={CalendarMonth}
            inputProps={{
               id: "date",
               name: "date",
               fullWidth: true,
               unstyled: true,
               format: "DD/MM/YYYY",
               minDate: dayjs(new Date()),
               disablePast: true,
               onChange: (e) => {
                  formProps.setFieldValue("date", e.$d);
               },
            }}
            type="date"
            label="Fecha"
            fullWidth
         />
         <CustomInputStyle
            icon={HourglassTop}
            inputProps={{
               id: "start",
               name: "start",
               fullWidth: true,
               unstyled: true,
               minDate: formProps.values.date,
               disableIgnoringDatePartForTimeValidation: true,
               onChange: (e) => {
                  formProps.setFieldValue("start", e.$d);
               },
            }}
            type="time"
            label="Hora inicio"
            fullWidth
         />
         <CustomInputStyle
            icon={HourglassBottom}
            inputProps={{
               id: "end",
               name: "end",
               type: "time",
               fullWidth: true,
               unstyled: true,
               minDate: formProps.values.date,
               disableIgnoringDatePartForTimeValidation: true,
               onChange: (e) => {
                  formProps.setFieldValue("end", e.$d);
               },
            }}
            type="time"
            label="Hora fin"
         />
      </Box>
   );
};
