import { TableComponentProps } from "../TableComponent";
import {
   ArrayTypeComponent,
   BooleanComponent,
   ButtonTypeComponent,
   CheckTypeComponent,
   ChipsTypeComponent,
   CollapsibleTypeComponent,
   DateTypeComponent,
   GroupTypeComponent,
   ImageTypeComponent,
   StatusTypeComponent,
   StringNumberTypeComponent,
   StringWithNumberOfChildren,
} from "./headerTypeComponent";

interface TableComponentContentProps {
   isUpRow: boolean;
   isItemSelected: boolean;
   labelId: string;
   header: any;
   setIsOn?: Function;
   onClick?: any;
   rowData?: any;
}

const TableComponentContentMap = (row, props: TableComponentProps, mapProps: TableComponentContentProps) => {
   switch (mapProps.header.type) {
      case "date":
         return DateTypeComponent(row, mapProps);
      case "string":
         if (row.collapsible && mapProps.header.field === "name") {
            return StringWithNumberOfChildren(row, mapProps);
         } else {
            return StringNumberTypeComponent(row, mapProps);
         }
      case "number":
         return StringNumberTypeComponent(row, mapProps);
      case "boolean":
         return BooleanComponent(row, mapProps);
      case "collapsible":
         return CollapsibleTypeComponent(props, row);
      case "check":
         return CheckTypeComponent(mapProps);
      case "button":
         return ButtonTypeComponent(props, mapProps, row);
      case "image":
         return ImageTypeComponent(row, mapProps, props);
      case "group":
         return GroupTypeComponent(row, props, mapProps);
      case "status":
         return StatusTypeComponent(row);
      case "array":
         return ArrayTypeComponent(row, mapProps);
      case "chips":
         return ChipsTypeComponent(row, mapProps);
      default:
         return <span>{row[mapProps.header.field]}</span>;
   }
};

export default TableComponentContentMap;
