import { createContext, useCallback, useContext, useEffect, useReducer } from "react";
import {
   getAllEvaluationNumber,
   getEvaluationConfig,
   getLastCompletedEvaluation,
   getLastCurrentEvaluation,
} from "../../lib/gobCorpBEClient";
import { GovernanceContext } from "./governanceContext";

const initialState = {
   isLoadingConfig: true,
   configInfo: null,
   lastEvaluationInfo: null,
   evaluationCurrent: null,
};

interface IEvaluationConfigContext {
   lastEvaluationInfo: any;
   configInfo: any;
   isLoadingConfig: boolean;
   setConfigInfo: Function;
   evaluationCurrent: any;
   fetchConfigInfo: Function;
}

export const EvaluationConfigContext = createContext<IEvaluationConfigContext>({
   lastEvaluationInfo: null,
   configInfo: null,
   isLoadingConfig: true,
   setConfigInfo: () => {},
   evaluationCurrent: null,
   fetchConfigInfo: () => {},
});

function evaluationConfigReducer(state, action) {
   switch (action.type) {
      case "SET_LOADING_CONFIG":
         return { ...state, isLoadingConfig: action.payload };
      case "SET_CONFIG_INFO":
         return { ...state, configInfo: action.payload };
      case "SET_LAST_EVALUATION":
         return { ...state, lastEvaluationInfo: action.payload };
      case "SET_CURRENT_EVALUATION":
         return { ...state, evaluationCurrent: action.payload };
   }
}

export const EvaluationConfigProvider = ({ children }) => {
   const [state, dispatch] = useReducer(evaluationConfigReducer, initialState);
   const { companySelected } = useContext(GovernanceContext);

   const setConfigInfo = useCallback((value) => {
      dispatch({ type: "SET_CONFIG_INFO", payload: value });
   }, []);

   const setLastEvaluation = useCallback((value) => {
      dispatch({ type: "SET_LAST_EVALUATION", payload: value });
   }, []);

   const setCurrentEvaluation = useCallback((value) => {
      dispatch({ type: "SET_CURRENT_EVALUATION", payload: value });
   }, []);

   const setIsLoadingConfig = useCallback((value) => {
      dispatch({ type: "SET_LOADING_CONFIG", payload: value });
   }, []);

   const fetchConfigInfo = useCallback(async () => {
      if (state.configInfo || companySelected === "") return;
      setIsLoadingConfig(true);
      const configFound = await getEvaluationConfig({ company: companySelected });
      const numberOfEvaluations = await getAllEvaluationNumber({ company: companySelected });
      const evaluationLastCompleted = await getLastCompletedEvaluation({ company: companySelected });
      const evaluationInCurse = await getLastCurrentEvaluation({ company: companySelected });
      setCurrentEvaluation(evaluationInCurse);
      setLastEvaluation(evaluationLastCompleted);
      setConfigInfo({ ...configFound, evaluationNumber: numberOfEvaluations });
      setIsLoadingConfig(false);
   }, [companySelected]);

   useEffect(() => {
      fetchConfigInfo();
   }, [fetchConfigInfo]);

   return (
      <EvaluationConfigContext.Provider value={{ ...state, setConfigInfo, fetchConfigInfo }}>
         {children}
      </EvaluationConfigContext.Provider>
   );
};
