import { Box, Divider, Stack, Tab, Tabs, Typography } from "@mui/material";
import { TabPanel } from "../../../screens/Mi Lecosy/ClientInfoScreenNew";
import SessionAttendanceListByTrimester from "./SubComponents/SessionAttendanceListByTrimester";
import { useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { sessionAttendance } from "../../../hooks/gob-corp/governmentBody/useGetSessionsAttendanceInfo";
import GoverningBodyList from "./SubComponents/AssistanceList/GoverningBodyList";
import AssemblyList from "./SubComponents/AssistanceList/AssemblyList";

const SessionAttendanceSection = ({ selectedBody }) => {
   const [selectedSession, setSelectedSession] = useState<sessionAttendance>(null);
   return (
      <Box bgcolor={"white"} borderColor={"#B5B5B5"} borderRadius={3}>
         <Tabs value={"1"} textColor="primary">
            <Tab label="Lista de asistencia a sesiones" value={"1"} />
         </Tabs>
         <Divider sx={{ borderBottomWidth: 2 }} />
         <TabPanel index={1} value={1}>
            <Stack direction={"row"} spacing={5} p={3}>
               <Box flex={4} p={2}>
                  <SessionAttendanceListByTrimester
                     selectedSession={selectedSession}
                     setSelectedSession={setSelectedSession}
                     selectedBody={selectedBody}
                  />
               </Box>
               <Box flex={5} alignSelf={"center"}>
                  {selectedSession ? (
                     <>
                        {selectedBody.bodiesData.title === "Asamblea de accionistas" ? (
                           <AssemblyList selectedSession={selectedSession} />
                        ) : (
                           <GoverningBodyList selectedSession={selectedSession} />
                        )}
                     </>
                  ) : (
                     <Stack alignItems={"center"} width={"100%"}>
                        <InfoOutlinedIcon sx={{ color: "#4B5563", fontSize: "80px" }} />
                        <Typography textAlign={"center"} maxWidth={"300px"}>
                           Selecciona el periodo en la gráfica izquierda para visualizar la información
                        </Typography>
                     </Stack>
                  )}
               </Box>
            </Stack>
         </TabPanel>
      </Box>
   );
};

export default SessionAttendanceSection;
