import { getPlatformInfoByCompanyId } from "../../lib/usersBEClient";

export const UseGetCompaniesNames = (userCompanies) => {
   const companiesNames: string[] = [];
   for (const company of userCompanies) companiesNames.push(company.person_details.comercialName);
   return companiesNames;
};

export const getComplaintCompany = (complaint, companies) => {
   const companyName = companies.find(
      (company) => company._id === complaint.company || complaint.companyBranch.company._id
   );
   return companyName.person_details.comercialName;
};

export const UseGetCompanySubaname = async (complaint) => {
   const platformFound = await getPlatformInfoByCompanyId(complaint.company || complaint.companyBranch.company._id);
   if (!platformFound) return;
   return platformFound.subName;
};
