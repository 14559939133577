import { Box, Stack, Typography } from "@mui/material";
import { InputTextField } from "../../../Inputs/InputTextField";
import { useContext } from "react";
import { GovernanceSessionContext } from "../../../../context/governanceContext/governanceSessionContext";
import { getContrastYIQ } from "../../../../const/globalConst";
import { useFormikContext } from "formik";

export const BillGroupNotes = () => {
   const { hasPermissions, colors, setReceivingChanges, receivingChanges, groupCompaniesInSession } =
      useContext(GovernanceSessionContext);
   const { setFieldValue } = useFormikContext();

   return (
      <Stack>
         <Stack spacing={0.5}>
            <Typography
               sx={{
                  bgcolor: colors.primary,
                  textAlign: "center",
                  fontSize: 12,
                  color: getContrastYIQ(colors.primary),
               }}
            >
               NOTAS
            </Typography>
            {groupCompaniesInSession?.length > 0 &&
               groupCompaniesInSession.map((company) => (
                  <Box key={company._id} sx={{ display: "flex", bgcolor: "#EFEFEF" }}>
                     <Stack
                        spacing={0.5}
                        sx={{
                           flex: 1,
                           justifyContent: "center",
                           alignItems: "center",
                           borderRight: `1px solid #162c4420`,
                           borderLeft: `5px solid ${colors.primary}`,
                        }}
                     >
                        <Typography fontWeight={600} sx={{ textAlign: "center", fontSize: 14 }}>
                           {company.person_details.comercialName}
                        </Typography>
                     </Stack>
                     <Box sx={{ flex: 3 }}>
                        <InputTextField
                           id={`notes${company._id}`}
                           name={`notes${company._id}`}
                           type={"text"}
                           multiline
                           rows={6}
                           sx={{
                              fontSize: 12,
                              p: 2,
                              bgcolor: "#EFEFEF",
                              ".Mui-disabled": { "-webkit-text-fill-color": "black !important" },
                              width: "100%",
                           }}
                           unstyled
                           disabled={!hasPermissions}
                           onChange={(e) => {
                              if (receivingChanges) setReceivingChanges(false);
                              setFieldValue(`notes${company._id}`, e.target.value);
                           }}
                        />
                     </Box>
                  </Box>
               ))}
         </Stack>
      </Stack>
   );
};
