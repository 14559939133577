import { Box, Button, CircularProgress, IconButton, Stack, Typography } from "@mui/material";
import { InputCheckBox } from "../../Inputs/InputCheckBox";
import { Formik, FormikProps } from "formik";
import { Form } from "react-router-dom";
import { useCallback, useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import { SnackBarContext } from "../../../context/snackBarContext";
import DeleteIcon from "@mui/icons-material/Delete";
import { uploadFileToS3, UseGetPresignedURLUploadExternal } from "../../../lib/s3Client";
import { ExternalGovernanceSessionContext } from "../../../context/governanceContext/externalSessionContext";
import { getUrlS3Token } from "../../../lib/usersBEClient";

export const ManifestoModalExternal = ({ user, setManifiestoModalOpen, session }) => {
   const [acceptWarning, setAcceptWarning] = useState(false);
   const [loadingButton, setIsLoadingButton] = useState(false);
   const { setDocumentUrl, userId, externalSessionToken, setOpenInputNameModal } = useContext(
      ExternalGovernanceSessionContext
   );
   const [file, setFile] = useState(null);
   const { showSnackBar } = useContext(SnackBarContext);

   const handleSubmit = async (values) => {
      setIsLoadingButton(true);
      if (!values["privacy"] || !file) {
         setAcceptWarning(true);
         return;
      } else {
         const urlReceipt1 = await UseGetPresignedURLUploadExternal({
            bucket: "files-lecosy",
            folder: `gc/companies/${session.company}/governing-body/${session._id}`,
            name: userId + file.name,
            token: externalSessionToken,
         });

         await uploadFileToS3(urlReceipt1.data, file);

         const documentUrl: string = await getUrlS3Token(
            "files-lecosy",
            { folder: `gc/companies/${session.company}/governing-body/${session._id}`, token: externalSessionToken },
            userId + file.name
         );
         setDocumentUrl(documentUrl);
         setManifiestoModalOpen(false);
         setOpenInputNameModal(true);
      }
      setIsLoadingButton(false);
   };

   const onDrop = useCallback(
      (acceptedFiles) => {
         if (acceptedFiles.length < 1) {
            return showSnackBar("Favor de seleccionar un archivo valido.", true);
         }
         if (
            (acceptedFiles[0].type.includes("png") ||
               acceptedFiles[0].type.includes("jpeg") ||
               acceptedFiles[0].type.includes("jpg")) &&
            acceptedFiles.size > 5000000
         ) {
            return showSnackBar("Archivo mayor a 5 MB", true);
         }
         setFile(acceptedFiles[0]);
      },
      [showSnackBar, setFile]
   );

   const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: {
         "application/image": [".png", ".jpeg", ".jpg"],
      },
      maxFiles: 1,
   });
   return (
      <Stack sx={{ maxWidth: { xs: 500, lg: 680 } }}>
         <Formik initialValues={{ privacy: false }} onSubmit={handleSubmit}>
            {(formProps: FormikProps<any>) => (
               <Form>
                  <Box
                     sx={{
                        py: 2,
                        bgcolor: "#152c44",
                        borderTopLeftRadius: 15,
                        borderTopRightRadius: 15,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                     }}
                  >
                     <Typography sx={{ color: "white", ml: 4, fontWeight: 600 }}>Manifiesto</Typography>
                  </Box>
                  <Stack
                     spacing={2}
                     sx={{
                        bgcolor: "background.paper",
                        py: 3,
                        px: 6,
                        borderBottomLeftRadius: 15,
                        borderBottomRightRadius: 15,
                     }}
                  >
                     <Typography sx={{ textAlign: "justify" }}>
                        {session.assembly
                           ? "Consiento de manera expresa e informada que se recabará mi identificación oficial, así como los títulos accionarios correspondientes para identificarme en este acto, siendo yo el titular de dichos datos personales sensibles conforme lo que establece la legislación aplicable."
                           : "Consiento de manera expresa e informada que se recabará mi identificación oficial para identificarme en este acto, siendo yo el titular de dichos datos personales sensibles conforme lo que establece la legislación aplicable."}
                     </Typography>
                     <Typography sx={{ textAlign: "justify" }}>
                        Adjunto evidencia de mi identificación, aceptando los términos de tratamiento de datos
                        personales que tiene la organización.
                     </Typography>
                     {file ? (
                        <Box
                           sx={{
                              display: "flex",
                              justifyContent: "center",
                           }}
                        >
                           <Box
                              sx={{
                                 border: 1,
                                 borderColor: "#c4c4c4",
                                 px: 5,
                                 py: 2,
                                 borderRadius: 2,
                                 display: "flex",
                                 alignItems: "center",
                                 gap: 1,
                              }}
                           >
                              <IconButton
                                 onClick={() => {
                                    setFile(null);
                                 }}
                              >
                                 <DeleteIcon sx={{ color: "#FF0030" }} />
                              </IconButton>
                              <Typography
                                 sx={{
                                    overflow: "hidden",
                                    whiteSpace: "nowrap",
                                    textOverflow: "ellipsis",
                                    maxWidth: 150,
                                 }}
                              >
                                 {file.name}
                              </Typography>
                           </Box>
                        </Box>
                     ) : (
                        <Box
                           sx={{
                              flex: 1,
                              border: 1,
                              borderColor: "#E2E6EA",
                              borderStyle: "dashed",
                              minHeight: 45,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: 2,
                              width: "66%",
                              alignSelf: "center",
                           }}
                           {...getRootProps()}
                        >
                           <input {...getInputProps()} />
                           <Typography variant="body2" color={"grey"}>
                              Subir foto
                           </Typography>
                        </Box>
                     )}

                     {acceptWarning && (
                        <Typography sx={{ textAlign: "justify", fontSize: 12, color: "red" }}>
                           {!file
                              ? "Favor de subir la foto de su identificación"
                              : "Favor de aceptar el aviso de privacidad"}
                        </Typography>
                     )}
                     <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <InputCheckBox
                           label={"Acepto aviso de privacidad"}
                           id={"privacy"}
                           name={"privacy"}
                           size="medium"
                        />
                        <Button
                           onClick={() => formProps.submitForm()}
                           sx={{ bgcolor: "#2D4357", color: "white", borderRadius: 1, px: 5, py: 0.4 }}
                           variant="contained"
                           disabled={loadingButton}
                        >
                           {!loadingButton ? `Continuar` : <CircularProgress sx={{ color: "white" }} size={24} />}
                        </Button>
                     </Box>
                  </Stack>
               </Form>
            )}
         </Formik>
      </Stack>
   );
};
