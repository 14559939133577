import { FormControl, FormControlLabel, RadioGroup, Radio, FormHelperText } from "@mui/material";
import { useField, useFormikContext } from "formik";

interface RadioButtonInterfaceProps {
   //    label: string;
   fullWidth?: boolean;
   variant?: any;
   id: string;
   name: string;
   sx?: object;
   row: boolean;
   value?: any;
   onChange?: any;
   setfile?: any;
   label1?: string;
   label2?: string;
}

export const InputRadioButton = (props: RadioButtonInterfaceProps) => {
   const { setFieldValue } = useFormikContext();
   const [field, meta] = useField(props);

   const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.name === "worksInPlace" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         setFieldValue("witnessActivity", "");
         setFieldValue("witnessTime", "");
         return;
      }
      if (event.target.name === "denouncedResponsible" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         setFieldValue("denouncedPersonName", (event.target as HTMLInputElement).value);
         setFieldValue("denouncedPersonNameAnswer", "");
         return;
      }
      if (event.target.name === "denouncedPersonName" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         setFieldValue("denouncedPersonNameAnswer", "");
         return;
      }
      if (event.target.name === "denouncedCategoryPartake" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         setFieldValue("denouncedNarration", "");
         return;
      }
      if (event.target.name === "denouncedWitness" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         setFieldValue("denouncedWitnessAnswer", "");
         return;
      }
      if (event.target.name === "trueComplaint" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         setFieldValue("knowsPeople", (event.target as HTMLInputElement).value);
         setFieldValue("witnessPeople", "");
         return;
      }
      if (event.target.name === "perceptionVariation" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         setFieldValue("witnessNarration", "");
         return;
      }
      if (event.target.name === "complainantLabors") {
         if (event.target.value === "No") {
            setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
            setFieldValue("complainantActivity", "");
            setFieldValue("complainantTime", "");
            return;
         }
         if (event.target.value === "Si") {
            setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
            setFieldValue("complainantExplanation", "");
            return;
         }
      }
      if (event.target.name === "complainantInvolved" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         setFieldValue("complainantInvolvedNarration", "");
         return;
      }
      if (event.target.name === "complainantEvidence" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         setFieldValue("complainantGivesEvidence", "No");
         return;
      }
      if (event.target.name === "complainantWitness" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         setFieldValue("complainantWitnessAnswer", "No");
         return;
      }
      if (event.target.name === "witnessEvidence" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         return;
      }
      if (event.target.name === "online" && event.target.value === "No") {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
         props.setfile(null);
      } else {
         setFieldValue(event.target.name, (event.target as HTMLInputElement).value);
      }
   };
   return (
      <>
         <FormControl component="fieldset" {...props.sx} error={meta.error && meta.touched ? true : false}>
            <RadioGroup {...props} name={field.name} onChange={handleRadioChange} value={field.value}>
               <FormControlLabel value={"Si"} control={<Radio />} label={props.label1 ? props.label1 : "Si"} />
               <FormControlLabel value={"No"} control={<Radio />} label={props.label2 ? props.label2 : "No"} />
            </RadioGroup>
            <FormHelperText>{meta.error && meta.touched ? meta.error : null}</FormHelperText>
         </FormControl>
      </>
   );
};
