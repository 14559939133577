import { Box, Divider, Typography } from "@mui/material";
import { useContext } from "react";
import Card from "@mui/material/Card";
import CarouselComponent from "../../CarouselComponent";
import InsightsComponent from "../../Gobierno corporativo/DashboardScreen/SubComponent/InsightsComponent";
import { BeneficiaryControllerContext } from "../../../context/beneficiaryContext/beneficiaryContollerContext";

export const InsightsBeneficiary = () => {
   const { insightsData } = useContext(BeneficiaryControllerContext);

   return (
      <Card sx={{ borderRadius: 2, py: 2, flex: 1 }}>
         <Box sx={{ px: 3 }}>
            <Typography color={"#162c44"} fontSize={20} fontWeight={700}>
               Latest Insights
            </Typography>
            <Divider sx={{ my: 1 }} />
         </Box>
         <CarouselComponent maxElements={1} hideSlide={insightsData?.insights?.length < 3} rows={4}>
            {insightsData?.insights?.length > 0 &&
               insightsData?.savedInsights &&
               insightsData?.insights.map((insight, index) => (
                  <InsightsComponent
                     insight={insight}
                     key={index}
                     saved={
                        insightsData?.savedInsights?.findIndex((s) => s.fileDirection === insight.link) >= 0
                           ? true
                           : false
                     }
                  />
               ))}
         </CarouselComponent>
      </Card>
   );
};
