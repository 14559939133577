import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { SystemThemeConfig } from "../config/systemTheme.config";

interface SessionEndedModalProps {
   open: boolean;
   title: string;
   body: string;
   onConfirm: Function;
}

function SessionEndedModal(props: SessionEndedModalProps) {
   return (
      <SystemThemeConfig>
         <Modal open={props.open} sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
            <Box p={2} maxWidth={400} sx={{ bgcolor: "white", borderRadius: 1 }}>
               <Typography id="modal-modal-title" variant="h6" component="h2">
                  {props.title}
               </Typography>
               <Box py={2}>
                  <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                     {props.body}
                  </Typography>
               </Box>
               <Button onClick={props.onConfirm()}>Volver a iniciar sesión</Button>
            </Box>
         </Modal>
      </SystemThemeConfig>
   );
}

export default SessionEndedModal;
