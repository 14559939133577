import {
   Box,
   Button,
   Checkbox,
   Chip,
   CircularProgress,
   Divider,
   FormControl,
   FormHelperText,
   IconButton,
   List,
   Popover,
   Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Delete } from "@mui/icons-material";
import { useField } from "formik";

interface IUsersSelector {
   label;
   icon;
   options;
   setGobernanceSelected;
   gobernanceSelected;
   users;
   selectedUsers;
   setUsersSelected;
   selectAllUsers;
   deSelectAllUsers;
   loadingUsers;
   inputProps;
   object?: boolean;
   defaultP?: boolean;
}

export const UsersSelector = ({
   label,
   icon,
   options,
   setGobernanceSelected,
   gobernanceSelected,
   users,
   selectedUsers,
   setUsersSelected,
   selectAllUsers,
   deSelectAllUsers,
   loadingUsers,
   inputProps,
   object,
   defaultP,
}: IUsersSelector) => {
   const [_field, meta] = useField(inputProps);
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const handleClick = () => {
      const element = document.getElementById("selector-users");
      setAnchorEl(anchorEl ? null : element);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };
   const open = Boolean(anchorEl);
   const id = open ? "simple-popper" : undefined;
   return (
      <React.Fragment>
         <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1, width: "100%" }}>
            <Typography variant="body2" fontWeight={600}>
               {label}
            </Typography>
            <Box sx={{ display: "flex", columnGap: 1 }}>
               <FormControl fullWidth>
                  <Box sx={{ display: "flex" }}>
                     <Box
                        sx={{
                           bgcolor: "#F6F7FB",
                           py: 1,
                           px: 2,
                           display: "flex",
                           alignItems: "center",
                           borderTopLeftRadius: 4,
                           borderBottomLeftRadius: 4,
                           border: 0.2,
                           borderColor: "#CBD5E0",
                           justifyContent: "center",
                        }}
                     >
                        {icon}
                     </Box>
                     <Box
                        sx={{
                           display: "flex",
                           alignItems: "center",
                           width: "100%",
                           border: 0.2,
                           borderLeft: 0,
                           borderColor: meta.error && meta.touched ? "red" : "#CBD5E0",
                           borderTopRightRadius: 4,
                           borderBottomRightRadius: 4,
                        }}
                     >
                        <Box
                           sx={{
                              flex: 2,
                              py: 1,
                              cursor: "pointer",
                              pl: 1,
                              width: "100%",
                           }}
                           onClick={handleClick}
                           id="selector-users"
                        >
                           {defaultP ? (
                              selectedUsers.length > 0 ? (
                                 selectedUsers.map((userF) => <Chip label={userF.firstName} />)
                              ) : (
                                 <Typography sx={{ color: "#c9c9c9" }}>Seleccionar</Typography>
                              )
                           ) : (
                              <Typography>{gobernanceSelected ? gobernanceSelected.title : "Seleccionar"}</Typography>
                           )}
                        </Box>
                     </Box>
                  </Box>
                  <FormHelperText error={meta.error && meta.touched ? true : false} sx={{ pl: 5 }}>
                     {meta.error && meta.touched ? meta.error : null}
                  </FormHelperText>
               </FormControl>

               {gobernanceSelected && !defaultP ? (
                  <Box>
                     <IconButton
                        onClick={() => {
                           setGobernanceSelected(null);
                           deSelectAllUsers();
                        }}
                     >
                        <Delete />
                     </IconButton>
                  </Box>
               ) : null}
            </Box>
            <Box sx={{ flex: 1, display: "flex" }}>
               <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                     vertical: "bottom",
                     horizontal: "left",
                  }}
                  sx={{ zIndex: 3000 }}
               >
                  <Box
                     sx={{
                        bgcolor: "white",
                        borderRadius: 2,
                        maxHeight: 150,
                        overflowY: "scroll",
                        flex: 1,
                        px: 1,
                     }}
                  >
                     <List>
                        {gobernanceSelected && !loadingUsers ? (
                           <>
                              <Box>
                                 {users.length > 0 ? (
                                    <Button
                                       sx={{
                                          display: "flex",
                                          columnGap: 0.5,
                                          alignItems: "center",
                                          width: "100%",
                                          justifyContent: "flex-start",
                                       }}
                                       onClick={() => {
                                          if (object && Object.keys(selectedUsers).length === users.length) {
                                             deSelectAllUsers();
                                             return;
                                          }
                                          if (!object && selectedUsers.length === users.length) {
                                             deSelectAllUsers();
                                             return;
                                          }
                                          selectAllUsers(users);
                                       }}
                                    >
                                       <Checkbox
                                          sx={{ p: 0 }}
                                          checked={
                                             (object && Object.keys(selectedUsers).length === users.length) ||
                                             selectedUsers.length === users.length
                                          }
                                       />
                                       <Typography>Seleccionar todos</Typography>
                                    </Button>
                                 ) : (
                                    <Typography>No se encuentran usuarios</Typography>
                                 )}
                              </Box>
                              <Divider />
                              {users.map((user, i) => (
                                 <Box key={i}>
                                    <Button
                                       sx={{
                                          display: "flex",
                                          columnGap: 0.5,
                                          alignItems: "center",
                                          width: "100%",
                                          justifyContent: "flex-start",
                                       }}
                                       onClick={() => {
                                          setUsersSelected(user);
                                       }}
                                    >
                                       <Checkbox
                                          key={i}
                                          sx={{ p: 0 }}
                                          checked={selectedUsers.some((selected) => selected._id === user._id)}
                                       />
                                       <Typography>{user.firstName}</Typography>
                                    </Button>
                                 </Box>
                              ))}
                           </>
                        ) : loadingUsers ? (
                           <CircularProgress />
                        ) : (
                           options.map((option, i) => (
                              <Box key={i}>
                                 <Button
                                    sx={{
                                       display: "flex",
                                       columnGap: 0.5,
                                       alignItems: "center",
                                       width: "100%",
                                       justifyContent: "flex-start",
                                    }}
                                    onClick={() => {
                                       setGobernanceSelected(option);
                                    }}
                                 >
                                    <Typography>{option.title}</Typography>
                                 </Button>
                              </Box>
                           ))
                        )}
                     </List>
                  </Box>
               </Popover>
            </Box>
         </Box>
      </React.Fragment>
   );
};
