import { ArrowRightAlt, CalendarToday, KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { Box, IconButton, Modal, Tooltip, Typography } from "@mui/material";

import { useContext, useState } from "react";
import { DateModal } from "./DateModal";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";

export const NoteDateFiltercomponent = () => {
   const [openModal, setOpenModal] = useState(false);
   const { startDate, finishDate, setfinishDate, setstartDate, setConfirmModal, setUpDownDates, upDownDates } =
      useContext(GovernanceContext);

   const handleClose = () => {
      setOpenModal(false);
   };

   return (
      <Box sx={{ display: "flex", columnGap: 2 }}>
         <Box
            sx={{
               display: "flex",
               justifyContent: "space-evenly",
               alignItems: "center",
               border: 1,
               borderColor: "#c4c4c4",
               color: "#F5F5F5",
               borderRadius: 1,
               height: 40,
               bgcolor: "white",
            }}
         >
            <Typography sx={{ display: "flex", alignItems: "center", color: "#868FA0", fontSize: 14, mx: 1 }}>
               {upDownDates ? "Antiguos - Recientes" : "Recientes - Antiguos"}
               <IconButton>
                  <Tooltip title="" onClick={() => setUpDownDates(!upDownDates)}>
                     {upDownDates ? <KeyboardArrowDown /> : <KeyboardArrowUp />}
                  </Tooltip>
               </IconButton>
            </Typography>
         </Box>
         <Box
            sx={{
               display: "flex",
               justifyContent: "space-evenly",
               alignContent: "center",
               border: 1,
               borderRadius: 1,
               borderColor: "#c4c4c4",
               color: "#F5F5F5",
               height: 40,
               bgcolor: "white",
            }}
         >
            <Typography
               sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "#868FA0",
                  fontSize: 14,
                  mx: 1,
               }}
            >
               Seleccionar fechas
               <IconButton>
                  <Tooltip
                     title=""
                     onClick={() => {
                        setOpenModal(true);
                        setConfirmModal(false);
                     }}
                  >
                     <CalendarToday sx={{ fontSize: 20 }} />
                  </Tooltip>
               </IconButton>
            </Typography>
         </Box>
         <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <DateModal
               setOpen={handleClose}
               finishDate={finishDate}
               setConfirmModal={setConfirmModal}
               setFinishDate={setfinishDate}
               setStartDate={setstartDate}
               startDate={startDate}
            />
         </Modal>
      </Box>
   );
};
