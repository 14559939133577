import { useEffect, useState } from "react";
import { Box, Container, Typography, Button, CircularProgress } from "@mui/material";
import { SystemThemeConfig } from "../config/systemTheme.config";
import { useNavigate } from "react-router-dom";
import { verifyActionCode } from "../lib/usersBEClient";
import { ResetPassword } from "../components/AccountAdministration/ResetPassword";
import { ChangeEmail } from "../components/AccountAdministration/ChangeEmail";
import { VerifyEmail } from "../components/AccountAdministration/VerifyEmail";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DangerousIcon from "@mui/icons-material/Dangerous";
import { UpdateUserAllUserDetails } from "../lib/gobCorpBEClient";

const AccountAdministration = () => {
   const [emailVerified, setEmailVerified] = useState(false);
   const [isLoading, setIsLoading] = useState(true);
   const [master, setMaster] = useState(false);
   const navigate = useNavigate();
   const logo = "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/logo-lecosy.png";
   const queryParameters = new URLSearchParams(window.location.search);
   const { mutate } = UpdateUserAllUserDetails();

   // Get the action to complete.
   const type = queryParameters.get("mode");
   // Get the one-time code from the query parameter.
   const code = queryParameters.get("oobCode");

   useEffect(() => {
      const verifyCode = async () => {
         if (type && code) {
            try {
               const verifiedRespense = await verifyActionCode(code, type);
               if (verifiedRespense.data.message === "email verified") {
                  setEmailVerified(true);
                  mutate({
                     userId: verifiedRespense.data.userId,
                     userDetailsData: { email: verifiedRespense.data.newEmail },
                  });
               }
               if (verifiedRespense.data.message === "email verified 2") {
                  setEmailVerified(true);
                  mutate({
                     userId: verifiedRespense.data.userId,
                     userDetailsData: { email: verifiedRespense.data.newEmail },
                  });
               }
               setMaster(verifiedRespense.data.master);
               setIsLoading(false);
            } catch (error: any) {
               setIsLoading(false);
               console.log(error);
            }
         }
      };
      verifyCode();
      // eslint-disable-next-line
   }, []);

   const redirect = () => {
      return window.location.replace(
         window.location.hostname === "test.web.lecosy.com.mx" ||
            window.location.hostname === "www.test.web.lecosy.com.mx"
            ? "https://test.admin.lecosy.com.mx/login"
            : process.env.NODE_ENV === "production"
            ? "https://admin.lecosy.com.mx/login"
            : "http://localhost:3000/login"
      );
   };

   return (
      <SystemThemeConfig>
         <Box sx={{ bgcolor: "whitesmoke", height: "100vh" }}>
            <Container
               maxWidth={"xl"}
               sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexWrap: "wrap",
                  height: "100vh",
               }}
            >
               <Box flex={1} sx={{ display: "flex", justifyContent: "center", px: 1 }}>
                  <Box component={"img"} sx={{ maxHeight: 250, maxWidth: 350 }} alt="Logo Lecosy" src={logo} />
               </Box>
               <Box
                  sx={{
                     flex: 1,
                     display: "flex",
                     justifyContent: "center",
                  }}
               >
                  <Box
                     sx={{
                        maxWidth: "500px",
                        minWidth: "500px",
                        bgcolor: "white",
                        boxShadow: 2,
                        borderRadius: 2,
                        px: 8,
                        py: 4,
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "column",
                        alignItems: "center",
                     }}
                  >
                     {isLoading ? (
                        <CircularProgress />
                     ) : (
                        <>
                           {type === "resetPassword" ? (
                              <ResetPassword code={code ? code : ""} master={master} redirect={redirect} />
                           ) : type === "recoverEmail" ? (
                              <ChangeEmail />
                           ) : type === "verifyEmail" ? (
                              <VerifyEmail success={emailVerified} master={master} redirect={redirect} />
                           ) : type === "verifyAndChangeEmail" ? (
                              <Box
                                 sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    alignItems: "center",
                                 }}
                              >
                                 {isLoading ? (
                                    <CircularProgress />
                                 ) : (
                                    <>
                                       {emailVerified ? (
                                          <>
                                             <Typography
                                                variant="h6"
                                                textAlign={"center"}
                                                sx={{ fontWeight: "bold", color: "#162c44", py: 2 }}
                                             >
                                                 Su correo electrónico fue modificado correctamente.
                                             </Typography>
                                             <CheckCircleIcon color="success" sx={{ fontSize: 80 }} />
                                             <Button
                                                variant="contained"
                                                sx={{ mt: 2 }}
                                                onClick={() => (master ? redirect() : navigate("/login"))}
                                             >
                                                Volver a Lecosy
                                             </Button>
                                          </>
                                       ) : (
                                          <>
                                             <Typography
                                                variant="h6"
                                                textAlign={"center"}
                                                sx={{ fontWeight: "bold", color: "#162c44", pt: 2 }}
                                             >
                                                El enlace proporcionado caducó, o hubo un problema al hacer el cambio.
                                             </Typography>
                                             <ErrorIcon color="error" sx={{ fontSize: 80 }} />
                                             <Typography textAlign={"center"}>
                                                Favor de reintentar o contactar a soporte.
                                             </Typography>
                                             <Button
                                                variant="contained"
                                                sx={{ mt: 2 }}
                                                onClick={() => navigate("/login")}
                                             >
                                                Volver a Lecosy
                                             </Button>
                                          </>
                                       )}
                                    </>
                                 )}
                              </Box>
                           ) : (
                              <>
                                 <Typography
                                    variant="h6"
                                    textAlign={"center"}
                                    sx={{ fontWeight: "bold", color: "#162c44", pt: 2 }}
                                 >
                                    No se encontró la ruta especificada.
                                 </Typography>
                                 <DangerousIcon color="error" sx={{ fontSize: 80 }} />
                                 <Button variant="contained" sx={{ mt: 2 }} onClick={() => navigate("/login")}>
                                    Volver a Lecosy
                                 </Button>
                              </>
                           )}
                        </>
                     )}
                  </Box>
               </Box>
            </Container>
         </Box>
      </SystemThemeConfig>
   );
};

export default AccountAdministration;
