import { Stack } from "@mui/material";
import { GovernanceSessionContext } from "../../../../context/governanceContext/governanceSessionContext";
import { useContext, useEffect, useState } from "react";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";
import { getClassificationById, getStructureByGoverningBodyId } from "../../../../lib/gobCorpBEClient";
import { capitalizeFirstLetter, getContrastYIQ } from "../../../../const/globalConst";
import { GetShareholderCommittee } from "./ShareholderCommittee";
import ListComponent from "../../../ListComponent";

const GetShareholderResolutions = () => {
   const { session, setQuorum } = useContext(GovernanceSessionContext);
   const [classification, setClassification] = useState(null);
   const [isLoading, setIsLoading] = useState(true);

   const fetchClarification = async () => {
      setIsLoading(true);
      if (!session.assembly) {
         const classificationData = await getStructureByGoverningBodyId(session.governance);
         return setClassification(classificationData);
      }
      const classificationData = await getClassificationById(session.company);
      setClassification(classificationData);
   };

   useEffect(() => {
      fetchClarification();
   }, []);

   useEffect(() => {
      if (!classification) return;
      if (!session.assembly) {
         setQuorum({ attendance: [classification.quorum], vote: [classification.resolutionVotes] });
      } else {
         classification.categories.forEach((category) => {
            if (category.title === session.type) {
               setQuorum({ attendance: category.quorumA, vote: category.quorumV });
            }
         });
      }
      setIsLoading(false);
   }, [classification, session]);

   return { isLoading };
};

export const ShareholderResolutions = () => {
   const {
      affairsArray,
      valuesFromBill,
      membersWithCharge,
      setAffairsStatus,
      session,
      colors,
      setVotes,
      quorum,
      additionalVotes,
      setAdditionalVotes,
   } = useContext(GovernanceSessionContext);

   const { isLoading } = GetShareholderResolutions();
   const { companyPercentageAttendance } = GetShareholderCommittee();

   useEffect(() => {
      if (!membersWithCharge || !affairsArray || affairsArray.length === 0) return;
      let votesInResolutionTemp = [];
      let totalVotesPerSerieTemp = {};

      membersWithCharge?.forEach((member) => {
         if (member.series.length > 0) {
            member.series.forEach((series) => {
               totalVotesPerSerieTemp = {
                  ...totalVotesPerSerieTemp,
                  [series.title]:
                     (totalVotesPerSerieTemp[series.title] > 0 ? totalVotesPerSerieTemp[series.title] : 0) +
                     series.votes,
               };
            });
         }
      });
      if (valuesFromBill) {
         const valuesFromBillKeys = Object.keys(valuesFromBill);
         valuesFromBillKeys.forEach((key) => {
            if (key.includes("vote")) {
               const voteMember = membersWithCharge?.find((member) => member._id === key.split("_")[2]);
               const voteAffair = affairsArray.find((affair: any) => affair.orderId === `${key.split("_")[4]}`);
               if (session.assembly) {
                  let votesBySerieUser = {};
                  voteMember?.series.forEach((series) => {
                     votesBySerieUser[series.title] = series.votes;
                  });
                  voteAffair?.series?.forEach((series) => {
                     if (series.faculties > 2) {
                        votesInResolutionTemp.push({
                           ...votesInResolutionTemp[key],
                           numberOfVotes: votesBySerieUser[series.title],
                           user: key.split("_")[2],
                           affair: key.split("_")[4],
                           vote: valuesFromBill[key],
                           abstention: valuesFromBill[key.replace("vote", "abstentionVote")],
                        });
                     }
                  });
               } else {
                  votesInResolutionTemp.push({
                     ...votesInResolutionTemp[key],
                     numberOfVotes: 1,
                     user: key.split("_")[2],
                     affair: key.split("_")[4],
                     vote: valuesFromBill[key],
                     abstention: valuesFromBill[key.replace("vote", "abstentionVote")],
                  });
               }
            }
         });
      }
      if (quorum) {
         affairsArray.forEach((affair: any) => {
            let totalVotesPerAffair = 0;
            if (session.assembly) {
               affair.series.forEach((series) => {
                  if (series.faculties > 1) {
                     totalVotesPerAffair += totalVotesPerSerieTemp[series.title]
                        ? totalVotesPerSerieTemp[series.title]
                        : 0;
                  }
               });
            } else {
               totalVotesPerAffair = session.usersRegistry.length;
            }
            affair["totalVotes"] = totalVotesPerAffair;
            let votesPerAffair = 0;
            let totalVotesUsed = 0;
            votesInResolutionTemp.forEach((vote) => {
               if (affair.orderId === vote.affair) {
                  if (vote.abstention) affair["totalVotes"] -= vote.numberOfVotes;
                  else if (vote.vote === true) {
                     if (vote.numberOfVotes !== undefined) {
                        votesPerAffair += vote.numberOfVotes;
                        totalVotesUsed += vote.numberOfVotes;
                     }
                  } else if (vote.vote === false)
                     if (vote.numberOfVotes !== undefined) totalVotesUsed += vote.numberOfVotes;
               }
            });
            affair["votesPerAffair"] = votesPerAffair;
            affair["totalVotesUsed"] = totalVotesUsed;
            if (
               companyPercentageAttendance <
               (session.proclamation === "Primera" ? quorum.attendance[0] : quorum.attendance[1])
            )
               affair["status"] = null;
            if (totalVotesUsed === 0) affair["status"] = "VOTACIÓN NO COMPLETADA";
            else if (
               affair.totalVotesUsed <
               ((session.proclamation === "Primera" ? quorum.vote[0] : quorum.vote[1]) * affair.totalVotes) / 100
            )
               affair["status"] = "VOTACIÓN EN PROCESO";
            else if (
               affair.totalVotesUsed >=
               ((session.proclamation === "Primera" ? quorum.vote[0] : quorum.vote[1]) * affair.totalVotes) / 100
            ) {
               const totalNegativeVotes = affair.totalVotesUsed - affair.votesPerAffair;
               affair["status"] = totalNegativeVotes > affair.votesPerAffair ? "NEGATIVA" : "POSITIVA";
            } else affair["status"] = "VOTACIÓN NO COMPLETADA";
         });
         setAffairsStatus(affairsArray);
      } else {
         affairsArray.forEach((affair) => {
            let totalVotesPerAffair = 0;
            if (session.assembly) {
               session.assembly &&
                  affair.series.forEach((series) => {
                     if (series.faculties > 1) {
                        totalVotesPerAffair += totalVotesPerSerieTemp[series.title]
                           ? totalVotesPerSerieTemp[series.title]
                           : 0;
                     }
                  });
            } else {
               totalVotesPerAffair = session.usersRegistry.length;
            }
            affair["totalVotes"] = totalVotesPerAffair;
            let votesPerAffair = 0;
            let totalVotesUsed = 0;
            votesInResolutionTemp.forEach((vote) => {
               if (affair._id === vote.affair) {
                  if (vote.abstention) affair["totalVotes"] -= vote.numberOfVotes;
                  else if (vote.vote === true) {
                     votesPerAffair += vote.numberOfVotes;
                     totalVotesUsed += vote.numberOfVotes;
                  } else if (vote.vote === false) {
                     totalVotesUsed += vote.numberOfVotes;
                  }
               }
            });
            affair["votesPerAffair"] = votesPerAffair;
            affair["totalVotesUsed"] = totalVotesUsed;
            affair["status"] = "VOTACIÓN INVÁLIDA";
         });
         setAffairsStatus(affairsArray);
      }
      setVotes(votesInResolutionTemp);
   }, [valuesFromBill, membersWithCharge, affairsArray]);

   useEffect(() => {
      if (!membersWithCharge || !additionalVotes || additionalVotes?.length === 0) return;
      const tempAdditionalVotes = additionalVotes;
      for (const addVote of tempAdditionalVotes) {
         addVote.totalVotes = 0;
         addVote.totalVotesUsed = 0;
         addVote.positiveVotes = 0;
         for (const userInVote of addVote.votes) {
            const voteMember = membersWithCharge?.find((member) => member._id === userInVote.userId);
            let votesBySerieUser = {};
            if (session.assembly) {
               voteMember?.series.forEach((series) => {
                  votesBySerieUser[series.title] = series.votes;
               });
            } else {
               votesBySerieUser["serie"] = 1;
            }
            const seriesSum = Number(
               Object.values(votesBySerieUser).reduce((sum: number, v: number) => {
                  return sum + v;
               }, 0)
            );
            if (userInVote.vote !== "nullVote") {
               addVote.totalVotes += seriesSum;
               if (userInVote.vote !== "pending") addVote.totalVotesUsed += seriesSum;
               if (userInVote.vote === "agreed") addVote.positiveVotes += seriesSum;
            }
         }
         if (quorum) {
            if (
               companyPercentageAttendance <
               (session.proclamation === "Primera" ? quorum.attendance[0] : quorum.attendance[1])
            )
               addVote.status = null;
            if (addVote.totalVotesUsed === 0) addVote.status = "VOTACIÓN NO COMPLETADA";
            else if (
               addVote.totalVotesUsed <
               ((session.proclamation === "Primera" ? quorum.vote[0] : quorum.vote[1]) * addVote.totalVotes) / 100
            )
               addVote.status = "VOTACIÓN EN PROCESO";
            else if (
               addVote.totalVotesUsed >=
               ((session.proclamation === "Primera" ? quorum.vote[0] : quorum.vote[1]) * addVote.totalVotes) / 100
            ) {
               const totalNegativeVotes = addVote.totalVotesUsed - addVote.positiveVotes;
               addVote.status = totalNegativeVotes > addVote.positiveVotes ? "NEGATIVA" : "POSITIVA";
            } else addVote.status = "VOTACIÓN NO COMPLETADA";
         }
      }
      setAdditionalVotes(tempAdditionalVotes);
   }, [additionalVotes, membersWithCharge]);

   if (isLoading || !affairsArray || affairsArray?.length === 0) return;
   return (
      <Stack>
         <ListComponent
            disableEmptyList
            title="RESOLUCIONES"
            titleTextProps={{
               textAlign: "center",
               fontSize: 12,
               fontWeight: 600,
            }}
            headers={[
               { headerTitle: "ASUNTO/VOTACIÓN", type: "text", bodyPosition: "center", headerPosition: "center" },
               {
                  headerTitle: "DESCRIPCIÓN",
                  type: "text",
                  bodyPosition: "left",
                  headerPosition: "center",
               },
               {
                  headerTitle: "RESOLUCIÓN",
                  type: "text",
                  bodyPosition: "center",
                  headerPosition: "center",
               },
            ]}
            headerProps={{}}
            headerColumnProps={() => {
               return {
                  mx: 0.1,
                  color: getContrastYIQ(colors.primary),
                  bgcolor: colors.primary,
               };
            }}
            headerTextProps={{
               fontWeight: 600,
               fontSize: 12,
            }}
            rows={affairsArray
               ?.filter((affair) => affair.totalVotesUsed && affair.totalVotesUsed > 0)
               .map((affair: any) => {
                  const totalNegativeVotes = affair.totalVotesUsed - affair.votesPerAffair;
                  const votesPercentage =
                     (Math.max(totalNegativeVotes, affair.votesPerAffair) * 100) / affair.totalVotesUsed;
                  const votesValue = affair.totalVotesUsed * (votesPercentage / 100);
                  return {
                     affair: affair.title,
                     description: affair.description,
                     vote: affair.status
                        ? ((affair.status === "POSITIVA" || affair.status === "NEGATIVA") &&
                             `${capitalizeFirstLetter(affair.status.toLowerCase())} con ${(
                                (votesValue * 100) /
                                affair.totalVotes
                             ).toFixed(2)}% de votos`) ||
                          affair.status
                        : "VOTACIÓN INVALIDA",
                  };
               })
               .concat(
                  additionalVotes
                     .map((addVote) => {
                        if (addVote.affair || addVote.canceled) return;
                        const totalNegativeVotes = addVote.totalVotesUsed - addVote.positiveVotes;
                        const votesPercentage =
                           (Math.max(totalNegativeVotes, addVote.positiveVotes) * 100) / addVote.totalVotesUsed;
                        const votesValue = addVote.totalVotesUsed * (votesPercentage / 100);
                        return {
                           affair: addVote.title,
                           description: "N/A",
                           vote:
                              ((addVote.status === "POSITIVA" || addVote.status === "NEGATIVA") &&
                                 `${capitalizeFirstLetter(addVote.status.toLowerCase())} con ${(
                                    (votesValue * 100) /
                                    addVote.totalVotes
                                 ).toFixed(2)}% de votos`) ||
                              addVote.status,
                        };
                     })
                     .filter((v) => v)
               )}
            rowProps={() => {
               return {};
            }}
            rowsColumnProps={(_row, index) => {
               return {
                  bgcolor: "#EFEFEF",
                  mx: 0.1,
                  mt: index === 0 ? 0 : 0.1,
                  px: 1,
               };
            }}
            subComponentProps={() => {
               return {
                  fontSize: "12px",
                  textAlign: "justify",
               };
            }}
         />
      </Stack>
   );
};
