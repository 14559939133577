import { useContext, useState } from "react";
import { Box, Divider, Typography, Toolbar, Button } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Form, Formik, FormikProps } from "formik";
import { InputTextField } from "../Inputs/InputTextField";
import { userInformationModifySchema } from "../../lib/validations/inputSchemas";
import { SnackBarContext } from "../../context/snackBarContext";
import { LoadingButton } from "../LoadingButton";
import { GetUserByUid, resendEmailVerification, updateUser, updateUserEmail } from "../../lib/usersBEClient";
import { ConfirmationModal } from "../ConfirmationModal";
import LoginModal from "../LoginModal";
import { UserContext } from "../../context/userContext";

interface ModifyUserInfoDrawerDrawerProps {
   open: boolean;
   setOpen: Function;
   dataToModify: any;
   refreshData: Function;
   setOpenEmail?: Function;
   refetch?: Function;
}

export const ModifyUserInfoDrawer = (props: ModifyUserInfoDrawerDrawerProps) => {
   // const { loginUser } = useContext(UserContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { setUser } = useContext(UserContext);
   const [isLoading, setIsLoading] = useState(false);
   const [isLoadingEmail, setIsLoadingEmail] = useState(false);
   const [verifyEmailModal, setVerifyEmailModal] = useState(false);
   const [modifyInfo, setModifyInfo] = useState(false);
   function handleOpen() {
      props.setOpen(!props.open);
   }

   const handleValidateEmail = async () => {
      const response = await resendEmailVerification(props.dataToModify.email);
      if (response?.status === 200) showSnackBar("Se ha enviado el correo de verificación", false);
      else showSnackBar("Error al validar el correo, intentelo de nuevo más tarde", true);
      return setVerifyEmailModal(false);
   };

   const modifyUserEmail = async (values) => {
      try {
         const response = await updateUserEmail(values.email, props.dataToModify.uid);
         if (response.status === 200) {
            showSnackBar("Correo electrónico modificado correctamente", false);
            if (props.setOpenEmail) props.setOpenEmail(true);
            setIsLoadingEmail(false);
            props.setOpen(!props.open);
            if (values.userName !== props.dataToModify.name || values.userLastName !== props.dataToModify.lastName)
               await modifyUserInfo(values);
         }
         setIsLoadingEmail(false);
      } catch (error: any) {
         if (error.response.data.message === "email already in use") showSnackBar("Correo electrónico en uso", true);
         else showSnackBar("Error, intente de nuevo", true);
         setIsLoadingEmail(false);
      }
   };

   const loginUser = async () => {
      await GetUserByUid().then(({ data }) => {
         if (!data) {
            console.log("Error");
         } else {
            setUser({ auth: true, ...data });
         }
      });
   };

   const modifyUserInfo = async (values) => {
      try {
         const response = await updateUser(
            props.dataToModify.uid,
            values.userName,
            values.userLastName,
            values.phoneNumber
         );
         if (response.data) {
            showSnackBar("Datos modificados correctamente", false);
            props.refetch ? props.refetch() : loginUser();
            props.setOpen(!props.open);
         }
         setIsLoading(false);
      } catch (error: any) {
         if (error.response.status === 400) {
            showSnackBar("Ocurrió un error.", true);
            return null;
         }
         setIsLoading(false);
      }
   };

   return (
      <Box sx={{ width: 400, display: "flex", maxHeight: "100vh", height: "100%" }} role="presentation">
         <Box
            display="flex"
            alignItems="center"
            onClick={handleOpen}
            sx={{
               "&:hover": {
                  bgcolor: "#F7F7F7",
               },
            }}
         >
            <ChevronRightIcon fontSize="small" />
         </Box>
         <Box sx={{ display: "flex", flexDirection: "column", flex: 1, mb: 1, mt: 2 }}>
            <Toolbar />
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
               <Box p={1}>
                  <Typography variant="h5">Modificar usuario</Typography>
               </Box>
            </Box>
            <Divider />
            <Box sx={{ p: 1 }}>
               <Formik
                  initialValues={{
                     userName: props.dataToModify.name || "",
                     userLastName: props.dataToModify.lastName || "",
                     email: props.dataToModify.email || "",
                     phoneNumber: props.dataToModify.phoneNumber || props.dataToModify.phone,
                  }}
                  onSubmit={() => (modifyInfo ? setIsLoading(true) : setIsLoadingEmail(true))}
                  validationSchema={userInformationModifySchema}
               >
                  {(formProps: FormikProps<any>) => (
                     <Form>
                        <Box mr={2}>
                           <InputTextField
                              sx={{ my: 2 }}
                              variant="standard"
                              multiline={true}
                              size="small"
                              name="userName"
                              id="userName"
                              type="text"
                              label="Nombre(s)"
                              fullWidth={true}
                           />
                           <InputTextField
                              sx={{ my: 2 }}
                              variant="standard"
                              multiline={true}
                              size="small"
                              name="userLastName"
                              id="userLastName"
                              type="text"
                              label="Apellidos"
                              fullWidth={true}
                           />

                           <InputTextField
                              sx={{ my: 2 }}
                              variant="standard"
                              multiline={true}
                              size="small"
                              name="phoneNumber"
                              id="phoneNumber"
                              type="text"
                              label="Teléfono"
                              fullWidth={true}
                           />
                           <InputTextField
                              sx={{ my: 2 }}
                              variant="standard"
                              multiline={true}
                              size="small"
                              name="email"
                              id="email"
                              type="email"
                              label="Correo electrónico"
                              fullWidth={true}
                           />

                           <Box
                              sx={{
                                 display: "flex",
                                 justifyContent: !props.dataToModify.validPwd ? "space-between" : "flex-end",
                              }}
                           >
                              {!props.dataToModify.validPwd && (
                                 <Typography
                                    onClick={() => {
                                       setVerifyEmailModal(true);
                                    }}
                                    variant="caption"
                                    sx={{ ":hover": { cursor: "pointer", color: "#162c4485" } }}
                                 >
                                    Verificar correo
                                 </Typography>
                              )}
                              <LoadingButton
                                 isLoading={false}
                                 label="Modificar información"
                                 onClick={() => {
                                    setModifyInfo(formProps.values.email === props.dataToModify.email);
                                 }}
                              />
                           </Box>
                        </Box>
                        <ConfirmationModal
                           open={verifyEmailModal}
                           setOpen={setVerifyEmailModal}
                           title="Enviar verificación"
                           body="Al confirmar, se reestablecerá la contraseña del usuario."
                           textJustify={"justify"}
                           onConfirm={handleValidateEmail}
                        />
                        <ConfirmationModal
                           open={isLoading}
                           setOpen={setIsLoading}
                           generic
                           onConfirm={() => modifyUserInfo(formProps.values)}
                           cancelButton={false}
                        />
                        <LoginModal
                           open={isLoadingEmail}
                           setOpen={setIsLoadingEmail}
                           onConfirm={() => modifyUserEmail(formProps.values)}
                        />
                     </Form>
                  )}
               </Formik>
            </Box>
         </Box>
      </Box>
   );
};
