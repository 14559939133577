import { useContext, useEffect, useMemo, useState } from "react";
import rolesByService from "../../const/rolesByService.json";
import { Box, Button, Drawer, Stack, Typography } from "@mui/material";
import { HeadCell, TableComponent } from "../TableComponent";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { AddUserModal } from "./AddUserModal";
import { UserContext } from "../../context/userContext";
import { ModifyUserRoles } from "../Drawers/ModifyUserRoles";
import { getAccessByServiceId, getBranchesByCompanyId, getUserByUserUid } from "../../lib/usersBEClient";
import { getMemberChargesByCompany } from "../../lib/gobCorpBEClient";
import { ModifyUserInfoDrawer } from "../Drawers/ModifyUserInfoDrawer";
import { useNavigate } from "react-router-dom";
import { CorporateDataContext } from "../../context/governanceContext/corporateDataContext";

interface PropsUserByModuleComponent {
   company: any;
   serviceName: any;
   edit?: boolean;
}

type UserDataValuesType = {
   name: string;
   lastName: string;
   email: string;
   phoneNumber: string;
   uid: string;
   validPwd: boolean;
};

export const UserByModuleComponent = (props: PropsUserByModuleComponent) => {
   const [userArray, setUserArray] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false);
   const { user, companySelected } = useContext(UserContext);
   const { consultiveGroup } = useContext(CorporateDataContext);
   const [openModifySubUserDrawer, setOpenModifySubUserDrawer] = useState(false);
   const [userInformation, setUserInformation] = useState(null);
   const [modulesAndRoles, setModulesAndRoles] = useState([]);
   const [isLoadingModules, setisLoadingModules] = useState(true);
   const [userSelected, setUserSelected] = useState(null);

   const navigate = useNavigate();
   useEffect(() => {
      const fetchUserSelected = async () => {
         await fetchRolesInfo();
      };
      if (userSelected) {
         fetchUserSelected();
      }
      // eslint-disable-next-line
   }, [userSelected]);

   const isMemberOfGroup = useMemo(() => {
      if (consultiveGroup) return consultiveGroup?.companies.some((c) => c === companySelected._id);
      else return false;
   }, [consultiveGroup]);

   const fetchRolesInfo = async () => {
      setisLoadingModules(true);
      setUserInformation({
         name: props.company.person_details.comercialName,
         module: props.serviceName.serviceId.service,
      });
      const data = await getUserByUserUid(userSelected.uid);
      let modules = {};
      for (const iterator of props.company.company_details.servicesDetails) {
         if (props.serviceName.serviceId.service === iterator.serviceId.service) {
            let branches = [];

            const { data } = await getAccessByServiceId(iterator.serviceId._id);
            const module = {
               module: iterator.serviceId.service,
               company: props.company,
               roles: [],
            };
            if (data.serviceName === "Canal de denuncias") {
               const branchesResponse = await getBranchesByCompanyId(props.company._id);
               branches = branchesResponse.data;
            }
            for (const rol of userSelected.role) {
               if (data.roles.some((role) => role._id === rol._id)) {
                  module.roles.push(rol);
               }
            }
            const provisionalData = {
               company: props.company._id,
               service: data,
               userLimit: iterator.userLimit,
               branches: branches,
            };
            modules = module;
            setModulesAndRoles([provisionalData]);
         }
      }
      setUserInformation({
         ...userInformation,
         ...modules,
         uid: data.user.uid,
         _id: data.user._id,
         name: props.company.person_details.comercialName,
         branches: data.userDetails.branches,
         generalRoles: data.user.role,
         module: props.serviceName.serviceId.service,
      });
      setisLoadingModules(false);
   };

   const usersColumns: HeadCell[] = [
      { field: "name", headerName: "Nombre", type: "string" },
      { field: "email", headerName: "Correo electrónico", type: "string" },
      { field: "roles", headerName: "Rol", type: "popover" },
   ];

   if (props.edit) {
      usersColumns.push({
         field: "modify",
         headerName: "Modificar",
         type: "button",
         width: 50,
         icon: <BorderColorIcon fontSize="small" />,
         onClick: async (e, row) => {
            e.stopPropagation();
            if (userSelected && userSelected.uid === row.uid) {
               setisLoadingModules(false);
            }
            setUserSelected(row);
            setOpenModifySubUserDrawer(true);
         },
      });
   }

   const getCompanyRoles = (roles: any[]) => {
      const rolesArray = roles.flatMap((role) =>
         role.roles
            .filter((r) => rolesByService.GC.includes(r.name))
            .map((r) => (r.name === "AdminDelSistema" ? "Administrador del Sistema" : r.name))
      );
      return Array.from(new Set(rolesArray));
   };

   useEffect(() => {
      const fetchUserData = async () => {
         setIsLoading(true);
         let checkContext: any = [];
         if (companySelected.company_details.admin === undefined) {
            checkContext = [...props.company.company_details.users];
         } else {
            checkContext = [...props.company.company_details.users, props.company.company_details?.admin];
         }
         if (props.serviceName.serviceId.service === "Canal de denuncias") {
            const usersData = (checkContext as any[]).map((user) => ({
               _id: user._id,
               uid: user.uid,
               firstName: user.firstName,
               lastName: user.lastName,
               phoneNumber: user.phoneNumber,
               name: user.firstName + " " + user.lastName,
               email: user.email,
               roles: getCDRolesNames(user.role),
               role: getCDRoles(user.role),
               generalRoles: user.role,
               status: false,
            }));
            const filteredUsers = usersData.filter(
               (userData) => userData._id !== user.id && userData.roles.some((role) => rolesByService.CD.includes(role))
            );
            setUserArray(filteredUsers);
         } else if (props.serviceName.serviceId.service === "Gobierno corporativo") {
            const usersData: any[] = (checkContext as any[]).map((user) => ({
               _id: user._id,
               uid: user.uid,
               firstName: user.firstName,
               lastName: user.lastName,
               phoneNumber: user.phoneNumber,
               name: user.firstName + " " + user.lastName,
               email: user.email,
               roles: getCompanyRoles(user.role).length > 0 ? getCompanyRoles(user.role) : ["sin datos"],
            }));
            const filteredUsers = usersData.filter(
               (userData) => userData._id !== user.id && userData.roles.some((role) => rolesByService.GC.includes(role))
            );
            const gcUserIds = filteredUsers
               .filter((user) => user.roles.some((r) => rolesByService.GC.includes(r)))
               .map((user) => user._id);

            if (gcUserIds.length > 0) {
               const GcChargesResponse = await getMemberChargesByCompany(gcUserIds, companySelected._id);
               for (const user of filteredUsers) {
                  const index = GcChargesResponse.findIndex((c) => c.user === user._id);
                  if (index >= 0) {
                     if (isMemberOfGroup) {
                        user.roles = user.roles.concat(
                           GcChargesResponse[index].charges.find((c) => c === "Accionista")
                        );
                        user.roles = user.roles.filter((r) => r === "Accionista");
                     } else {
                        user.roles = user.roles.concat(GcChargesResponse[index].charges);
                        user.roles = user.roles.filter((r) => !rolesByService.GC.includes(r));
                     }
                  }
               }
            }
            const finalUserFilter = filteredUsers.filter(
               (a) => a._id !== user.id && !a.roles.includes("Usuario de implementación") && a.roles.length > 0
            );
            setUserArray(finalUserFilter);
         }
         setIsLoading(false);
      };
      fetchUserData();
      // eslint-disable-next-line
   }, [companySelected]);

   function getCDRolesNames(roles: any[]) {
      const roleNames = roles.map((role) => role);
      const rolesArray = [];
      for (const role of roleNames)
         if (role.company === props.company._id)
            for (const r of role.roles) if (rolesByService.CD.includes(r.name)) rolesArray.push(r.name);
      return rolesArray.flat();
   }

   function getCDRoles(roles: any[]) {
      const roleNames = roles.map((role) => role);
      const rolesArray = [];
      for (const role of roleNames)
         if (role.company === props.company._id)
            for (const r of role.roles) if (rolesByService.CD.includes(r.name)) rolesArray.push(r);
      return rolesArray.flat();
   }

   return (
      <Box
         sx={{
            bgcolor: "white",
            boxShadow: 2,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            borderRadius: 1,
         }}
      >
         <Stack direction={"row"} justifyContent={"space-between"} alignContent={"center"} sx={{ px: 4, pt: 3 }}>
            <Typography variant="h6" sx={{ fontWeight: 700 }}>
               Usuarios del módulo
            </Typography>
            <Button
               variant="contained"
               sx={{ borderRadius: 1, px: 3 }}
               onClick={() => {
                  setOpenModal(true);
               }}
            >
               <AddCircleIcon sx={{ mr: 1 }} />
               Agregar usuario
            </Button>
         </Stack>
         <TableComponent
            defaultColumnToOrder="firstName"
            defaultOrder="asc"
            defaultRowsPerPage={5}
            rowsPerPageOptions={[5, 10, 20, 50]}
            headers={usersColumns}
            rows={userArray}
            onClick={(_e, row) => {
               const userId = row.uid;
               const screen = "mi-lecosy/usuarios/0/" + userId;
               return navigate(`/${screen}`);
            }}
            loader={isLoading}
            emptyDataText="No existen usuarios registrados en este módulo"
            disableBorders
         />
         <AddUserModal
            company={props.company}
            open={openModal}
            setOpen={setOpenModal}
            module={true}
            usersByModule={userArray}
            service={props.serviceName}
         />
         <Drawer
            anchor={"right"}
            open={openModifySubUserDrawer}
            hideBackdrop={true}
            onClose={() => setOpenModifySubUserDrawer(false)}
            sx={{ maxWidth: 450 }}
         >
            {userInformation && (
               <ModifyUserRoles
                  addModule={false}
                  userInfo={userInformation}
                  modulesAndRoles={modulesAndRoles}
                  open={openModifySubUserDrawer}
                  setOpen={setOpenModifySubUserDrawer}
                  isLoadingModules={isLoadingModules}
                  setisLoadingModules={setisLoadingModules}
                  fetchData={() => { }}
               />
            )}
         </Drawer>
      </Box>
   );
};
