import { Box, Grid, IconButton, List, ListItem, Stack, Typography } from "@mui/material";
import { CorporateDataContext } from "../../../../context/governanceContext/corporateDataContext";
import { Form, Formik } from "formik";
import { useContext, useState } from "react";
import { InputTextField } from "../../../Inputs/InputTextField";
import CheckIcon from "@mui/icons-material/Check";
import { classificationSchema } from "../../../../lib/validations/inputSchemas";
import { UserContext } from "../../../../context/userContext";

export const ClassificationManagment = () => {
   const { classifications } = useContext(CorporateDataContext);

   return (
      <Box width={"100%"} px={2} py={1}>
         <Grid container>
            <Grid item xs={2} alignContent={"center"} px={2}>
               <Typography fontWeight={600} color={"#162c44"} textAlign={"left"}>
                  Nombre
               </Typography>
            </Grid>
            <Grid item xs={5}>
               <Stack>
                  <Typography fontWeight={600} color={"#162c44"} textAlign={"center"}>
                     Quorum de asistencia
                  </Typography>
                  <Box sx={{ display: "flex", p: 2, justifyContent: "space-around" }}>
                     <Box sx={{ bgcolor: "#162c44", borderRadius: 2, px: 1.5, py: 0.5 }}>
                        <Typography fontWeight={600} textAlign={"center"} color={"white"} variant="body2">
                           1RA CONV.
                        </Typography>
                     </Box>
                     <Box sx={{ bgcolor: "#162c44", borderRadius: 2, px: 1.5, py: 0.5 }}>
                        <Typography fontWeight={600} textAlign={"center"} color={"white"} variant="body2">
                           2DA-U CONV.
                        </Typography>
                     </Box>
                  </Box>
               </Stack>
            </Grid>
            <Grid item xs={5}>
               <Stack>
                  <Typography fontWeight={600} color={"#162c44"} textAlign={"center"}>
                     Quorum de votación
                  </Typography>
                  <Box sx={{ display: "flex", p: 2, justifyContent: "space-around" }}>
                     <Box sx={{ bgcolor: "#162c44", borderRadius: 2, px: 1.5, py: 0.5 }}>
                        <Typography fontWeight={600} textAlign={"center"} color={"white"} variant="body2">
                           1RA CONV.
                        </Typography>
                     </Box>
                     <Box sx={{ bgcolor: "#162c44", borderRadius: 2, px: 1.5, py: 0.5 }}>
                        <Typography fontWeight={600} textAlign={"center"} color={"white"} variant="body2">
                           2DA-U CONV.
                        </Typography>
                     </Box>
                  </Box>
               </Stack>
            </Grid>
         </Grid>
         <List sx={{ maxHeight: "310px", overflowY: "auto" }} key={classifications?.length}>
            {classifications?.map((c, i) => (
               <ClassificationObject c={c} i={i} key={c.title} />
            ))}
         </List>
      </Box>
   );
};

const ClassificationObject = ({ c, i }) => {
   const { updateClassification } = useContext(CorporateDataContext);
   const { handleUpdateEditingSection } = useContext(UserContext);
   const [editable, setEditable] = useState(false);
   const handleClassifications = (values) => {
      updateClassification(
         {
            quorumA: [Number(values.quorumA0), Number(values.quorumA1)],
            quorumV: [Number(values.quorumV0), Number(values.quorumV1)],
         },
         i
      );
      setEditable(false);
      handleUpdateEditingSection("series");
   };

   return (
      <Formik
         initialValues={{
            quorumA0: c.quorumA[0],
            quorumA1: c.quorumA[1],
            quorumV0: c.quorumV[0],
            quorumV1: c.quorumV[1],
         }}
         onSubmit={handleClassifications}
         validationSchema={classificationSchema}
      >
         <Form>
            <ListItem sx={{ borderTop: 1, borderColor: "#D8D8D8", py: 1 }} onDoubleClick={() => setEditable(true)}>
               <Grid container>
                  <Grid item xs={2} alignContent={"center"}>
                     <Typography fontWeight={600} color={"#162c44"} textAlign={"left"}>
                        {c.title}
                     </Typography>
                  </Grid>
                  <Grid item xs={5} alignContent={"center"}>
                     {editable ? (
                        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                           <InputTextField
                              id="quorumA0"
                              name="quorumA0"
                              type="text"
                              size={"small"}
                              variant={"standard"}
                              centeredText
                              maxLength={3}
                              sx={{ mx: 5, alignSelf: "baseline" }}
                           />
                           <InputTextField
                              id="quorumA1"
                              name="quorumA1"
                              type="text"
                              size={"small"}
                              variant={"standard"}
                              centeredText
                              maxLength={3}
                              sx={{ mx: 5, alignSelf: "baseline" }}
                           />
                        </Box>
                     ) : (
                        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                           <Typography fontWeight={600} color={"#162c44"} textAlign={"center"}>
                              {c.quorumA[0]}
                              {"%"}
                           </Typography>
                           <Typography fontWeight={600} color={"#162c44"} textAlign={"center"}>
                              {c.quorumA[1]}
                              {"%"}
                           </Typography>
                        </Box>
                     )}
                  </Grid>
                  <Grid item xs={5} alignContent={"center"}>
                     {editable ? (
                        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                           <InputTextField
                              id="quorumV0"
                              name="quorumV0"
                              type="text"
                              size={"small"}
                              variant={"standard"}
                              centeredText
                              maxLength={3}
                              sx={{ mx: 5, alignSelf: "baseline" }}
                           />
                           <InputTextField
                              id="quorumV1"
                              name="quorumV1"
                              type="text"
                              size={"small"}
                              variant={"standard"}
                              centeredText
                              maxLength={3}
                              sx={{ mx: 5, alignSelf: "baseline" }}
                           />
                        </Box>
                     ) : (
                        <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                           <Typography fontWeight={600} color={"#162c44"} textAlign={"center"}>
                              {c.quorumV[0]}
                              {"%"}
                           </Typography>
                           <Typography fontWeight={600} color={"#162c44"} textAlign={"center"}>
                              {c.quorumV[1]}
                              {"%"}
                           </Typography>
                        </Box>
                     )}
                  </Grid>
                  {editable && (
                     <IconButton sx={{ position: "absolute", right: "0" }} type="submit">
                        <CheckIcon />
                     </IconButton>
                  )}
               </Grid>
            </ListItem>
         </Form>
      </Formik>
   );
};
