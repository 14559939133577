import { Stack, Box } from "@mui/material";
import { useContext } from "react";
import { ChatContext } from "../../../../context/governanceContext/ChatContext";
import { FoundMessage } from "./FoundMessage";

export const SearchMessage = () => {
   const { foundMessages } = useContext(ChatContext);
   return (
      <Box sx={{ mt: "5px" }}>
         {foundMessages && (
            <Stack spacing={3}>
               {foundMessages.map((message, index) => (
                  <FoundMessage key={index} index={index} message={message} />
               ))}
            </Stack>
         )}
      </Box>
   );
};
