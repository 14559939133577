import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material";
import { GovernanceSessionContext } from "../../../../context/governanceContext/governanceSessionContext";
import { useContext } from "react";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import { getContrastYIQ } from "../../../../const/globalConst";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

export const ShareholderDeliberations = () => {
   const { membersWithCharge, affairsArray, valuesFromBill, colors, additionalVotes, session } =
      useContext(GovernanceSessionContext);
   const shareholdersArray =
      membersWithCharge?.filter(
         (member) => member.firstName && !member.memberCharge.includes("Usuario de implementacion")
      ) || [];

   const deliberationVotes = affairsArray.concat(additionalVotes as any).filter((d) => d);

   if (!valuesFromBill) return;
   return (
      deliberationVotes?.length > 0 && (
         <Stack border={1} borderColor={"#EFEFEF"}>
            <Accordion disableGutters elevation={0}>
               <AccordionSummary
                  sx={{
                     bgcolor: colors.secondary,
                     height: "22px",
                     minHeight: "22px !important",
                  }}
                  expandIcon={<ArrowDownwardIcon fontSize="small" sx={{ color: getContrastYIQ(colors.secondary) }} />}
               >
                  <Typography
                     sx={{
                        width: "100%",
                        color: getContrastYIQ(colors.secondary),
                        textAlign: "center",
                        fontSize: 12,
                     }}
                  >
                     DELIBERACIONES
                  </Typography>
               </AccordionSummary>
               <AccordionDetails>
                  <Stack sx={{ display: "flex" }} direction={"column"}>
                     {deliberationVotes
                        .map((order) => {
                           if ((order as any).affair || !order.totalVotesUsed) return;
                           return (
                              <Stack borderBottom={2} borderColor={colors.secondary} key={order._id}>
                                 <Typography
                                    px={2}
                                    py={1}
                                    fontWeight={600}
                                    color={"#162c44"}
                                    width={"100%"}
                                    border={1}
                                    borderColor={"#EFEFEF"}
                                    fontSize={"13px"}
                                 >
                                    {order?.description || order.title}
                                 </Typography>
                                 {order.vote && (
                                    <Typography
                                       px={2}
                                       py={0.5}
                                       width={"100%"}
                                       border={1}
                                       borderColor={"#EFEFEF"}
                                       fontSize={"12px"}
                                    >
                                       {deliberationVotes.find((votation) => votation?._id === order.vote)?.title}
                                    </Typography>
                                 )}
                                 <Stack direction={"row"} display={"flex"} overflow={"auto"} spacing={1} rowGap={2}>
                                    {(order as any).canceled ? (
                                       <Typography
                                          textAlign={"center"}
                                          py={0.5}
                                          width={"100%"}
                                          fontWeight={600}
                                          fontSize={"14px"}
                                       >
                                          Votación cancelada
                                       </Typography>
                                    ) : (order as any)?.votes !== undefined ? (
                                       (order as any).votes?.map((addVote) => {
                                          return (
                                             <DeliberationVoteBox
                                                userName={`${addVote.userName}`}
                                                vote={addVote.vote}
                                             />
                                          );
                                       })
                                    ) : order.vote ? (
                                       (deliberationVotes.find((v) => v?._id === order.vote) as any)?.votes?.map(
                                          (orderVote) => {
                                             return (
                                                <DeliberationVoteBox
                                                   userName={`${orderVote.userName}`}
                                                   vote={orderVote.vote}
                                                />
                                             );
                                          }
                                       )
                                    ) : (
                                       shareholdersArray.map((shareholder) => {
                                          let hasAnyVote = false;
                                          if (!session.assembly) hasAnyVote = true;
                                          else
                                             for (const affair of affairsArray) {
                                                const shareholderSeries = shareholder.series.map(
                                                   (serie) => serie.title
                                                );
                                                const affairSeries = affair.series.map((serie) => {
                                                   return { [serie.title]: serie.faculties };
                                                });
                                                shareholderSeries.forEach((shareholderSerie) => {
                                                   affairSeries.forEach((affairSerie) => {
                                                      if (affairSerie[shareholderSerie] > 1) hasAnyVote = true;
                                                   });
                                                });
                                             }
                                          return (
                                             hasAnyVote && (
                                                <DeliberationVoteBox
                                                   userName={shareholder.firstName + " " + shareholder.lastName}
                                                   vote={
                                                      valuesFromBill[
                                                         `abstentionVote_user_${shareholder._id}_affair_${order.orderId}`
                                                      ]
                                                         ? "nullVote"
                                                         : valuesFromBill[
                                                              `vote_user_${shareholder._id}_affair_${order.orderId}`
                                                           ]
                                                   }
                                                />
                                             )
                                          );
                                       })
                                    )}
                                 </Stack>
                              </Stack>
                           );
                        })
                        .filter((v) => v)}
                  </Stack>
               </AccordionDetails>
            </Accordion>
         </Stack>
      )
   );
};

interface IDeliberationVoteBox {
   userName: string;
   vote: boolean | string;
}
export const DeliberationVoteBox = (props: IDeliberationVoteBox) => {
   const { userName, vote } = props;
   let voteComponent = <></>;
   if (vote === true || vote === "agreed") voteComponent = <CheckIcon sx={{ fontSize: "18px" }} />;
   if (vote === false || vote === "disagreed") voteComponent = <CloseIcon sx={{ fontSize: "18px" }} />;
   if (vote === "nullVote")
      voteComponent = (
         <Typography textAlign={"center"} fontSize={"12px"}>
            ABS
         </Typography>
      );
   if (vote === "pending" || vote === null || vote === undefined)
      voteComponent = (
         <Typography textAlign={"center"} fontSize={"12px"}>
            PEN
         </Typography>
      );
   return (
      <Stack direction={"column"}>
         <Box
            height={"100%"}
            maxWidth={"160px"}
            overflow={"auto"}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            flexDirection={"column"}
         >
            <Typography
               bgcolor={"#EFEFEF"}
               border={1}
               borderColor={"#EFEFEF"}
               textAlign={"center"}
               px={1}
               py={0.5}
               fontSize={"12px"}
               flex={1}
               fontWeight={600}
            >
               {userName}
            </Typography>
         </Box>
         <Box display={"flex"} justifyContent={"center"} border={1} borderColor={"#EFEFEF"} width={"100%"}>
            {voteComponent}
         </Box>
      </Stack>
   );
};
