import { Box, Card, CircularProgress, Divider, Modal, Typography } from "@mui/material";
import { Scheduler } from "@aldabil/react-scheduler";
import { DayHours, SchedulerRef } from "@aldabil/react-scheduler/types";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { es } from "date-fns/locale";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { EventViewer } from "../SchedulerScreen/SubComponents/EventViewer";
import { EventRenderer } from "./SubComponent/EventRenderer";
import { InsightsRender } from "./SubComponent/InsightsRender";
import { DoughnutChart } from "../../charts/doughnutChart";
import { useGetCommitmentsInfo } from "../../../hooks/gob-corp/governmentBody/useGetCommitmentsInfo";
import { IEventsS } from "../SchedulerScreen/SchedulerComponent";
import { getFilteredUsers } from "../../../lib/usersBEClient";
import { useParams } from "react-router-dom";
import { getCalendarById } from "../../../lib/gobCorpBEClient";
import { GuidesRender } from "./SubComponent/GuidesRender";
import { UserContext } from "../../../context/userContext";
import { getContrastYIQ } from "../../../const/globalConst";

const handleEvents = (data: any, companySelected) => {
   let events: IEventsS[] = [];
   for (const event of data.event) {
      const date = event.date.split("T")[0];
      events.push({
         start: new Date(date + "T" + event.startTime.split("T")[1]),
         end: new Date(date + "T" + event.finishTime.split("T")[1]),
         date: new Date(event.date),
         description: event.description,
         title: event.title,
         location: event.location,
         files: event.files,
         users: event.users,
         color: event.tags && event.tags.length > 0 ? event.tags[0].color : "#028A0F",
         textColor: getContrastYIQ(event.tags && event.tags.length > 0 ? event.tags[0].color : "#028A0F"),
         type: "event",
         event_id: event.id,
         tags: event.tags,
      });
   }
   for (const task of data.task) {
      const date = task.date.split("T")[0];
      events.push({
         start: new Date(date + "T" + task.startTime.split("T")[1]),
         end: new Date(date + "T" + task.finishTime.split("T")[1]),
         description: task.description,
         title: task.title,
         users: task.users,
         color: "red",
         textColor: getContrastYIQ("#ff0000"),
         type: "task",
         event_id: task.id,
         tags: [],
         files: [],
      });
   }
   for (const session of data.session) {
      const date = session.date.split("T")[0];
      events.push({
         start: new Date(date + "T" + session.startTime.split("T")[1]),
         end: new Date(date + "T" + session.finishTime.split("T")[1]),
         date: new Date(session.date),
         order: session.order,
         title: session.title,
         billCode: session.billCode,
         color: session.group ? "#162c44" : companySelected?.company_details?.primaryColor || "#162c44",
         textColor: session.group
            ? "#FFF"
            : getContrastYIQ(companySelected?.company_details?.primaryColor || "#162c44"),
         type: session.type,
         event_id: session._id,
         tags: session.tags,
         files: session.files,
         location: session.location,
         meetingURL: session.meetingURL,
         comments: session.comments,
         usersRegistry: session.usersRegistry,
         externs: session.externs,
         governance: session.governance?._id,
         governanceUsers: session.governance?.users,
         governanceTitle: session.governance?.title,
         assembly: session.assembly,
         provider: session.provider,
         group: session.group,
         completed: session?.completed,
      });
   }
   return events;
};

const useGetDashboardData = (setIsLoading, setUsers) => {
   let events: IEventsS[] = [];
   const { tagsFilter, selectedGovernance, gobernanceBody, setSelectedGovernance } = useContext(GovernanceContext);
   const { commitments, totalCommitments } = useGetCommitmentsInfo({ setIsLoading });
   const [specificCalendar, setSpecificCalendar] = useState(null);
   const [isLoadingEvents, setIsLoadingEvents] = useState(true);
   const { companySelected } = useContext(UserContext);
   const { id } = useParams();

   function onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
   }

   useEffect(() => {
      setIsLoadingEvents(true);
      if (gobernanceBody?.length > 0) setSelectedGovernance(gobernanceBody.find((governance) => governance._id === id));
   }, [gobernanceBody]);

   useEffect(() => {
      if (selectedGovernance?._id === "0") return;
      const fetchCalendar = async () => {
         const calendarData = await getCalendarById(selectedGovernance.calendar);
         setSpecificCalendar(calendarData);
      };
      fetchCalendar();
   }, [selectedGovernance]);

   const eventsG = useMemo(() => {
      if (!specificCalendar) return;
      events.push(...handleEvents(specificCalendar, companySelected));
      if (tagsFilter.length > 0) {
         const eventsF = eventsG.filter((ev) => ev.tags.some((tag) => tagsFilter.includes(tag._id)));
         return eventsF;
      } else {
         return events;
      }
   }, [specificCalendar]);

   useEffect(() => {
      const users = [];
      if (!eventsG) return;
      for (const event of eventsG) {
         if (event.usersRegistry) {
            users.push(...event.usersRegistry.map((user) => user.user));
         }
      }

      const unique = users.filter(onlyUnique);

      const fetchUsers = async () => {
         const usersF = await getFilteredUsers(unique);
         setUsers(usersF);
         setIsLoadingEvents(false);
      };

      fetchUsers();
   }, [eventsG]);

   return {
      eventsG,
      totalCommitments,
      commitments,
      isLoadingEvents,
   };
};

export const DashboardByGB = () => {
   const [isLoading, setIsLoading] = useState(false);
   const [users, setUsers] = useState([]);
   const { totalCommitments, eventsG, commitments, isLoadingEvents } = useGetDashboardData(setIsLoading, setUsers);
   const { selectedGovernance, savedInsights } = useContext(GovernanceContext);
   const calendarRef = useRef<SchedulerRef>(null);
   const [open, setOpen] = useState<boolean>(false);
   const [selectedEvent, setSelectedEvent] = useState<IEventsS | null>(null);
   const [startEventHour, setStartEventHour] = useState<DayHours>(9);
   const [endEventHour, setEndEventHour] = useState<DayHours>(19);

   const handleOpen = (event, type) => {
      setOpen(true);
      setSelectedEvent(event);
   };

   const handleClose = () => {
      setOpen(false);
      setSelectedEvent(null);
   };

   const textCenter = (
      firstString: {
         size;
         text;
         color?;
      },
      secondString: {
         size;
         text;
         color?;
      }
   ) => {
      if (totalCommitments.length === 0)
         return {
            id: "textCenter",
            beforeDatasetsDraw(chart, args, pluginOptiona) {
               const { ctx } = chart;
               ctx.save();
               ctx.font = `bolder ${firstString.size ? firstString.size : "25"}px sans-serif`;
               ctx.fillStyle = firstString ? firstString.color : "#162c44";
               ctx.textAlign = "center";
               ctx.textBaseline = "middle";
               ctx.fillText("Total", chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y - 20);
               ctx.font = `bolder ${secondString.size ? secondString.size : "25"}px sans-serif`;
               ctx.fillStyle = secondString ? secondString.color : "#162c44";
               ctx.fillText("0", chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y + 20);
            },
         };
      else {
         return {
            id: "textCenter",
            beforeDatasetsDraw(chart, args, pluginOptiona) {
               const { ctx } = chart;
               ctx.save();
               ctx.font = `bolder ${firstString.size ? firstString.size : "25"}px Open sans`;
               ctx.fillStyle = firstString ? firstString.color : "#162c44";
               ctx.textAlign = "center";
               ctx.textBaseline = "middle";
               ctx.fillText(
                  firstString.text,
                  chart.getDatasetMeta(0).data[0].x,
                  chart.getDatasetMeta(0).data[0].y - 20
               );
               ctx.font = `bolder ${secondString.size ? secondString.size : "25"}px Open sans`;
               ctx.fillStyle = secondString ? secondString.color : "#162c44";
               ctx.fillText(
                  secondString.text,
                  chart.getDatasetMeta(0).data[0].x,
                  chart.getDatasetMeta(0).data[0].y + 20
               );
            },
         };
      }
   };

   useEffect(() => {
      setIsLoading(true);
   }, [selectedGovernance._id]);

   return (
      <>
         {!isLoading && !isLoadingEvents ? (
            <Box
               sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(4, 1fr)",
                  gridTemplateAreas: `"commitments calendar calendar calendar"
      "footer footer footer2 footer2"`,
                  columnGap: 3,
                  rowGap: 2,
               }}
            >
               <Card
                  sx={{
                     gridArea: "calendar",
                     px: 5,
                     py: 3,
                     borderRadius: 2,
                  }}
               >
                  <Typography fontSize={22} fontWeight={700}>
                     Calendario
                  </Typography>
                  <Divider sx={{ my: 2 }} />
                  <Box
                     sx={{
                        overflowY: "auto",
                        maxHeight: 450,
                        "::-webkit-scrollbar": {
                           display: "none",
                        },
                     }}
                  >
                     <Scheduler
                        ref={calendarRef}
                        view="week"
                        deletable={false}
                        locale={es}
                        agenda={false}
                        week={{
                           weekDays: [0, 1, 2, 3, 4, 5],
                           startHour: startEventHour,
                           endHour: endEventHour,
                           weekStartOn: 1,
                           step: 60,
                        }}
                        translations={{
                           navigation: {
                              agenda: "Agenda",
                              today: "Hoy",
                              month: "Mes",
                              week: "Semana",
                              day: "Día",
                           },
                           form: {
                              addTitle: "Agregar evento",
                              editTitle: "Editar evento",
                              delete: "Borrar evento",
                              cancel: "Cancelar",
                              confirm: "Confirmar",
                           },
                           event: {
                              title: "Título",
                              start: "Inicio",
                              end: "Fin",
                              allDay: "Todo el día",
                           },
                           moreEvents: "Más...",
                           noDataToDisplay: "Sin datos",
                           loading: "Cargando...",
                        }}
                        fields={[
                           {
                              name: "users",
                              type: "select",
                           },
                        ]}
                        events={eventsG}
                        eventRenderer={({ event, ...props }) => {
                           setStartEventHour(
                              event.start.getHours() >= 9
                                 ? ((event.start.getHours() - 2) as DayHours)
                                 : (event.start.getHours() as DayHours)
                           );
                           if ((event.end.getHours() as DayHours) > endEventHour - 2)
                              setEndEventHour(
                                 event.end.getHours() <= 18
                                    ? ((event.end.getHours() + 2) as DayHours)
                                    : (event.end.getHours() as DayHours)
                              );
                           return <EventRenderer event={event} props={props} />;
                        }}
                        customViewer={(event: IEventsS, close) => {
                           return <EventViewer event={{ ...event }} handleOpenModal={handleOpen} users={users} />;
                        }}
                        editable={false}
                     />
                  </Box>
               </Card>

               <Card
                  sx={{
                     px: 5,
                     py: 3,
                     borderRadius: 2,
                     display: "flex",
                     flexDirection: "column",
                     alignItems: "center",
                  }}
               >
                  <Typography fontSize={22} fontWeight={700} color={"#152C44"} textAlign={"center"}>
                     Estado de Acuerdos y Compromisos actuales
                  </Typography>
                  {isLoading ? (
                     <CircularProgress />
                  ) : (
                     <>
                        <Box width={200} height={200} my={4}>
                           <DoughnutChart
                              scores={[
                                 commitments.expired.length || 0,
                                 commitments.complete.length || 0,
                                 commitments.review.length || 0,
                                 commitments.process.length || 0,
                                 commitments.pending.length || 0,
                              ]}
                              labels={["Vencido", "Finalizado", "En revisión", "En Proceso", "Pendiente", ""]}
                              colors={["#E91818", "#1BA929", "#F59E0B", "#3498DB", "#BBC2CC", "#C0C0C0"]}
                              chartRef={null}
                              cutout={75}
                              textCenterProp={textCenter(
                                 { size: "20", text: "Totales", color: "#737373" },
                                 {
                                    size: "46",
                                    text: totalCommitments.length,
                                    color: "#0A0A0A",
                                 }
                              )}
                           />
                        </Box>
                        <Box
                           display={"flex"}
                           flexDirection={"column"}
                           gap={0.5}
                           flexWrap={"wrap"}
                           maxHeight={100}
                           columnGap={4}
                           alignContent={"center"}
                        >
                           <Box display={"flex"} gap={0.5}>
                              <Box width={20} height={20} bgcolor={"#BBC2CC"} />
                              <Typography fontWeight={500} fontSize={16}>
                                 Pendiente
                              </Typography>
                           </Box>
                           <Box display={"flex"} gap={0.5}>
                              <Box width={20} height={20} bgcolor={"#3498DB"} />
                              <Typography fontWeight={500} fontSize={16}>
                                 En Proceso
                              </Typography>
                           </Box>
                           <Box display={"flex"} gap={0.5}>
                              <Box width={20} height={20} bgcolor={"#F59E0B"} />
                              <Typography fontWeight={500} fontSize={16}>
                                 En revisión
                              </Typography>
                           </Box>
                           <Box display={"flex"} gap={0.5}>
                              <Box width={20} height={20} bgcolor={"#1BA929"} />
                              <Typography fontWeight={500} fontSize={16}>
                                 Finalizado
                              </Typography>
                           </Box>
                           <Box display={"flex"} gap={0.5}>
                              <Box width={20} height={20} bgcolor={"#E91818"} />
                              <Typography fontWeight={500} fontSize={16}>
                                 Vencido
                              </Typography>
                           </Box>
                        </Box>
                     </>
                  )}
               </Card>
               <InsightsRender isUserPanel={false} />
               <GuidesRender isUserPanel={false} />
            </Box>
         ) : (
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
               <CircularProgress />
            </Box>
         )}

         {open && selectedEvent && (
            <Modal
               open={open}
               // setOpen={setOpen}
               onClose={handleClose}
               sx={{ display: "flex" }}
               tabIndex={0}
               // title={selectedEvent.title}
            >
               <Box sx={{ m: "auto", maxWidth: 700 }}>
                  <EventViewer
                     event={{ ...selectedEvent }}
                     users={users}
                     handleOpenModal={handleOpen}
                     handleClose={handleClose}
                     modal
                  />
               </Box>
            </Modal>
         )}
      </>
   );
};
