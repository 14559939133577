import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { Formik, FormikProps } from "formik";
import React, { useContext, useState } from "react";
import { Form } from "react-router-dom";
import { InputTextField } from "../../Inputs/InputTextField";
import { ExternalGovernanceSessionContext } from "../../../context/governanceContext/externalSessionContext";
import { SnackBarContext } from "../../../context/snackBarContext";

export const NameInputModalExternal = () => {
   const { userId, socket, session, setOpenInputNameModal, documentUrl, setIsWaitingToVerify } = useContext(
      ExternalGovernanceSessionContext
   );
   const { showSnackBar } = useContext(SnackBarContext);
   const [isLoadingSubmit, setIsLoadingButton] = useState(false);

   const handleSubmit = (values) => {
      if (!values.name) return showSnackBar("Favor de introducir un nombre valido", true);
      setIsLoadingButton(false);
      const userFound = session.externs.find((extern) => extern.user === userId);
      socket.emit("request-to-join-external", {
         ...userFound,
         name: values.name,
         folder: `gc/companies/${session.company}/governing-body/${session._id}`,
         file: documentUrl,
         request: "join",
         sessionId: session._id,
         external: true,
      });
      setIsWaitingToVerify(true);
      setOpenInputNameModal(false);
      setIsLoadingButton(false);
   };

   return (
      <Stack sx={{ width: 500 }}>
         <Formik initialValues={{ name: "" }} onSubmit={handleSubmit}>
            {(formProps: FormikProps<any>) => (
               <Form>
                  <Box
                     sx={{
                        py: 2,
                        bgcolor: "#152c44",
                        borderTopLeftRadius: 15,
                        borderTopRightRadius: 15,
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                     }}
                  >
                     <Typography sx={{ color: "white", ml: 4, fontWeight: 600 }}>{`Ingrese su nombre para entrar a la ${
                        session.assembly ? "asamblea" : "sesión"
                     }`}</Typography>
                  </Box>
                  <Stack
                     spacing={2}
                     sx={{
                        bgcolor: "background.paper",
                        py: 3,
                        px: 6,
                        borderBottomLeftRadius: 15,
                        borderBottomRightRadius: 15,
                     }}
                  >
                     <InputTextField
                        id={"name"}
                        name={"name"}
                        type={"text"}
                        fullWidth={true}
                        variant="standard"
                        sx={{ fontSize: 12 }}
                     />
                     <Box sx={{ display: "flex", justifyContent: "center", pt: 1 }}>
                        <Button
                           onClick={() => formProps.submitForm()}
                           sx={{ bgcolor: "#2D4357", color: "white", borderRadius: 1, px: 5, py: 0.4, width: "50%" }}
                           variant="contained"
                           disabled={isLoadingSubmit}
                        >
                           {!isLoadingSubmit ? `Aceptar` : <CircularProgress sx={{ color: "white" }} size={24} />}
                        </Button>
                     </Box>
                  </Stack>
               </Form>
            )}
         </Formik>
      </Stack>
   );
};
