import { Box, Button, CircularProgress, Divider, Grid, Stack, Typography } from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import { EvaluationModal } from "./EvaluationModal";
import { EvaluationContext } from "../../../context/governanceContext/evaluationContext";
import { getDateFromISODate } from "../../../const/globalConst";
import { ResultsGraph } from "./SubComponents/PerformanceGraphs/ResultsGraph";
import { CompromiseGraph } from "./SubComponents/PerformanceGraphs/CompromiseGraph";
import { ExperienceGraph } from "./SubComponents/PerformanceGraphs/ExperienceGraph";
import { GraphLabels } from "./SubComponents/GraphLabels";
import { ConstantActualization } from "./SubComponents/PerformanceGraphs/ConstantActualization";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { CustomModalComponent } from "../../CustomModalComponent";
import { useNavigate } from "react-router-dom";
import { Settings } from "@mui/icons-material";
import { UserContext } from "../../../context/userContext";
import { getNumberOfEvaluations } from "../../../lib/gobCorpBEClient";

export const PerformanceEvaluationBar = () => {
   const [openModal, setOpenModal] = useState(false);
   const { evaluation, isLoadingEvaluation, finished, isLoadingPersonalEvaluation, responsesByMember } =
      useContext(EvaluationContext);
   const { gobernanceBody, setSelectedSection } = useContext(GovernanceContext);
   const { user } = useContext(UserContext);
   const navigate = useNavigate();
   const [number, setNumber] = useState(null);
   const [permission, setPermission] = useState(false);

   const fetchPermissions = useCallback(() => {
      if (Array.isArray(user.role) && user.role.every((item) => typeof item !== "string")) return;
      const rolesToCheck = ["coordinador", "n2", "n5", "n7", "presidente", "usuario de implementación"];
      const hasRequiredRole = rolesToCheck.some((keyword) =>
         user.role.some((role) => role.toLowerCase().includes(keyword))
      );
      setPermission(hasRequiredRole);
   }, [user.role]);

   useEffect(() => {
      fetchPermissions();
   }, [fetchPermissions]);

   const fetchNumberOfEvaluations = useCallback(async () => {
      if (number || !evaluation) return;
      const response = await getNumberOfEvaluations();
      setNumber(response);
   }, [number, evaluation]);

   useEffect(() => {
      fetchNumberOfEvaluations();
   }, [fetchNumberOfEvaluations]);

   return (
      <Stack sx={{ p: 2, bgcolor: "white", borderRadius: 1, border: 1, borderColor: "#D8D8D8" }}>
         <Box sx={{ maxWidth: 200, mx: 2 }}>
            <Typography sx={{ color: "#162c44", fontWeight: 600, px: 1 }} variant="body2">
               Evaluación de desempeño
            </Typography>
         </Box>
         {finished && !isLoadingPersonalEvaluation ? (
            <>
               <Box sx={{ display: "flex", minHeight: 300, p: 4, columnGap: 2 }}>
                  <Stack sx={{ boxShadow: 1, borderRadius: 2, flex: 1, p: 2 }}>
                     <Typography textAlign={"center"} fontWeight={600}>
                        Resultados obtenidos
                     </Typography>
                     <ResultsGraph
                        answers1={responsesByMember?.filter(
                           (response) => response.question === "65c65d3760142d47e5ea92b4"
                        )}
                        answers2={responsesByMember?.filter(
                           (response) => response.question === "65c65e9560142d47e5ea92c2"
                        )}
                        answers3={responsesByMember?.filter(
                           (response) => response.question === "65c65e6860142d47e5ea92be"
                        )}
                     />
                  </Stack>
                  <Stack sx={{ boxShadow: 1, borderRadius: 2, flex: 1, rowGap: 2, p: 2 }}>
                     <Typography textAlign={"center"} fontWeight={600}>
                        Compromiso por los objectivos y valores
                     </Typography>
                     {responsesByMember?.filter((response) => response.question === "65c65dd160142d47e5ea92b6").length >
                     0 ? (
                        <CompromiseGraph
                           answers={responsesByMember?.filter(
                              (response) => response.question === "65c65dd160142d47e5ea92b6"
                           )}
                        />
                     ) : (
                        <Typography textAlign="center" variant="subtitle1">
                           {" "}
                           Sin datos
                        </Typography>
                     )}
                  </Stack>
               </Box>
               <Box sx={{ display: "flex", minHeight: 300, p: 4, columnGap: 6 }}>
                  <Stack sx={{ boxShadow: 1, borderRadius: 2, flex: 1, p: 2 }}>
                     <Typography textAlign={"center"} fontWeight={600}>
                        Aporte de conocimientos y experiencia
                     </Typography>
                     <Box sx={{ minHeight: 250, py: 2 }}>
                        <ExperienceGraph
                           answers={responsesByMember?.filter(
                              (response) => response.question === "65c65de760142d47e5ea92b8"
                           )}
                        />
                        <Box sx={{ pt: 1 }}>
                           <GraphLabels />
                        </Box>
                     </Box>
                  </Stack>
                  <Stack sx={{ boxShadow: 1, borderRadius: 2, flex: 1, p: 2 }}>
                     <Typography textAlign={"center"} fontWeight={600}>
                        Reflejo de estándares éticos
                     </Typography>
                     <Box sx={{ minHeight: 250, py: 2 }}>
                        <ExperienceGraph
                           answers={responsesByMember?.filter(
                              (response) => response.question === "65c65e5460142d47e5ea92bc"
                           )}
                        />
                        <Box sx={{ pt: 1 }}>
                           <GraphLabels />
                        </Box>
                     </Box>
                  </Stack>
                  <Stack sx={{ boxShadow: 1, borderRadius: 2, flex: 3, p: 2 }}>
                     <Typography textAlign={"center"} fontWeight={600}>
                        Actualización constante
                     </Typography>

                     <ConstantActualization
                        answers={responsesByMember?.filter(
                           (response) => response.question === "65c65e2660142d47e5ea92ba"
                        )}
                     />
                  </Stack>
               </Box>
            </>
         ) : isLoadingPersonalEvaluation ? (
            <CircularProgress />
         ) : null}
         <Divider />
         <Stack direction={"column"} sx={{ p: 2, justifyContent: "center" }}>
            {!isLoadingEvaluation && evaluation ? (
               <Grid container>
                  <Grid item container xs={8} spacing={2}>
                     <Grid item xs={6}>
                        <Stack direction={"row"} columnGap={2}>
                           <Typography sx={{ fontWeight: 600 }} variant="body2">
                              Fecha de inicio:
                           </Typography>
                           <Typography variant="body2">{getDateFromISODate(evaluation.createdAt)}</Typography>
                        </Stack>
                     </Grid>
                     <Grid item xs={6}>
                        <Stack direction={"row"} columnGap={2}>
                           <Typography sx={{ fontWeight: 600 }} variant="body2">
                              Número de evaluaciones realizadas:
                           </Typography>
                           <Typography
                              sx={{ bgcolor: "#162c44", color: "white", borderRadius: 2, px: 1.5 }}
                              variant="body2"
                           >
                              {number ? number.progress : 0}
                           </Typography>
                        </Stack>
                     </Grid>
                     <Grid item xs={6}>
                        <Stack direction={"row"} columnGap={2}>
                           <Typography sx={{ fontWeight: 600 }} variant="body2">
                              Fecha de última evaluación realizada:
                           </Typography>
                           <Typography variant="body2">
                              {number && number.progressLastFinished
                                 ? getDateFromISODate(number.progressLastFinished.updatedAt)
                                 : "Sin datos"}
                           </Typography>
                        </Stack>
                     </Grid>
                     <Grid item xs={6}>
                        <Stack direction={"row"} columnGap={2}>
                           <Typography sx={{ fontWeight: 600 }} variant="body2">
                              Fecha de próxima evaluación:
                           </Typography>
                           <Typography
                              sx={{ bgcolor: "#162c44", color: "white", borderRadius: 2, px: 1.5 }}
                              variant="body2"
                           >
                              {getDateFromISODate(
                                 `${parseInt(evaluation.createdAt.split("-")[0]) + 1}-${
                                    evaluation.createdAt.split("-")[1]
                                 }-${evaluation.createdAt.split("-")[2]}`
                              )}
                           </Typography>
                        </Stack>
                     </Grid>
                  </Grid>
                  <Grid item xs={4} justifyContent={"center"} display={"flex"} alignItems={"center"}>
                     <Stack sx={{ rowGap: 2 }}>
                        <Button
                           variant="contained"
                           fullWidth
                           sx={{ maxWidth: 250 }}
                           onClick={() => setOpenModal(true)}
                           disabled={finished || gobernanceBody.length === 1}
                        >
                           Iniciar evaluación
                        </Button>
                        {permission ? (
                           <Button
                              onClick={() => {
                                 navigate("evaluation");
                                 setSelectedSection("Configuración de la evaluación");
                              }}
                              sx={{ columnGap: 1 }}
                           >
                              Configurar evaluación <Settings />
                           </Button>
                        ) : null}
                     </Stack>
                  </Grid>
               </Grid>
            ) : isLoadingEvaluation ? (
               <CircularProgress />
            ) : (
               <>
                  <Typography>Sin evaluación pendiente</Typography>
                  {permission ? (
                     <Button
                        onClick={() => {
                           navigate("evaluation");
                           setSelectedSection("Configuración de la evaluación");
                        }}
                        sx={{ columnGap: 1 }}
                     >
                        Configurar evaluación <Settings />
                     </Button>
                  ) : null}
               </>
            )}
         </Stack>
         <CustomModalComponent title={"Evaluación de desempeño"} open={openModal} setOpen={setOpenModal} timeStamp>
            <EvaluationModal setOpenModalEv={setOpenModal} open={openModal} />
         </CustomModalComponent>
      </Stack>
   );
};
