import { getDateFromISODate, getMonthWord, imageArrow, imageLogo } from "../../const/globalConst";
import { header, signatures, textDefinition } from "../../const/templateConst";
import { IDocumentationDetails } from "../../types/BaseTypes";

const evidences = (e) => {
   return {
      text: [
         {
            text: "Evidencias otorgadas: ",
            bold: true,
            alignment: "justify",
            fontSize: 11,
         },
         {
            text: e.proof,
            alignment: "justify",
            fontSize: 11,
         },
      ],
      margin: [6, 2, 45, 2],
   };
};

const facts = (e) => {
   return {
      text: [
         {
            text: "Hechos declarados: ",
            bold: true,
            alignment: "justify",
            fontSize: 11,
         },
         {
            text: e.facts,
            alignment: "justify",
            fontSize: 11,
         },
      ],
      margin: [6, 2, 45, 2],
   };
};

export const complaintResolution = (
   reportNumber: string,
   complainantName: string,
   category: string,
   complainantFacts: string,
   complainantProof: string,
   resolution: string,
   fundaments: string,
   measures: string,
   time: string,
   company: string,
   complaintType: boolean,
   complimentOfficer: string,
   auditorIntern: string,
   generalDirection: string,
   witnesses: any[],
   offenderDetails: any[],
   type: string,
   address: string
) => {
   const date = time.split("-");
   const timeDate = date[2].split("T");

   const witnessesTable = witnesses.map((e, i) => {
      return [
         {
            text: `Testigo ${i + 1}:`,
            bold: true,
            fontSize: 12,
            alignment: "right",
            margin: [0, 2, 0, 0],
         },
         [
            {
               text: [
                  {
                     text: "Nombre: ",
                     bold: true,
                     fontSize: 11,
                  },
                  {
                     text: e.name,
                     alignment: "left",
                     fontSize: 11,
                  },
               ],
               margin: [6, 2, 15, 2],
            },
            evidences(e),
            facts(e),
         ],
      ];
   });
   const offenders = offenderDetails.map((e, i) => {
      return [
         {
            text: `Denunciado ${i + 1}:`,
            bold: true,
            fontSize: 12,
            alignment: "right",
            margin: [0, 2, 0, 0],
         },
         [
            {
               text: [
                  {
                     text: "Nombre: ",
                     bold: true,
                     fontSize: 11,
                  },
                  {
                     text: e.name,
                     // alignment: "justify",
                     fontSize: 11,
                  },
               ],
               margin: [6, 2, 15, 2],
            },
            evidences(e),
            facts(e),
         ],
      ];
   });

   const dd = {
      info: {
         title: `resolucion-${reportNumber}`,
      },
      pageSize: "A4",
      pageMargins: [70, 90, 70, 120],
      header: {
         columns: [
            {
               image: imageLogo,
               width: 100,
               absolutePosition: { x: 50, y: 30 },
            },
            {
               canvas: [
                  {
                     type: "rect",
                     x: 100,
                     y: 55,
                     w: 450,
                     h: 4,
                     color: "#152c44",
                  },
               ],
            },
            {
               text: `RESOLUCIÓN DE LA ${type.toUpperCase()}`,
               bold: true,
               fontSize: 15,
               color: "#152c44",
               absolutePosition: { x: 220, y: 35 },
            },
         ],
      },
      content: [
         {
            margin: [140, 10, 0, 10],
            table: {
               widths: [180, 250],
               heights: [1, 10, 10, 10, 1],
               body: [
                  [
                     { text: "", border: [false, false, false, false] },
                     { text: "", border: [false, false, false, false] },
                  ],
                  [
                     {
                        text: `Numero de reporte de la ${type}:`,

                        border: [false, false, false, false],
                        alignment: "right",
                        color: "#152c44",

                        bold: true,
                        fontSize: 11,
                     },
                     {
                        text: `${reportNumber}`,
                        fontSize: 11,

                        border: [false, false, false, false],
                        alignment: "left",
                     },
                  ],
                  [
                     {
                        text:
                           type !== "denuncia" && type !== "" ? "Nombre del interpositor:" : "Nombre del denunciante:",
                        border: [false, false, false, false],
                        alignment: "right",
                        color: "#152c44",

                        bold: true,
                        fontSize: 11,
                     },
                     {
                        text: `${complainantName}`,
                        fontSize: 11,

                        border: [false, false, false, false],
                        alignment: "left",
                     },
                  ],
                  [
                     {
                        text: type !== "denuncia" && type !== "" ? "" : "Calificación de la denuncia:",
                        border: [false, false, false, false],
                        alignment: "right",

                        color: "#152c44",
                        bold: true,
                        fontSize: 11,
                     },
                     {
                        text: type !== "denuncia" && type !== "" ? "" : `${category}`,
                        fontSize: 11,
                        margin: [0, 0, 60, 0],
                        border: [false, false, false, false],
                        alignment: "left",
                     },
                  ],
                  [
                     { text: "", border: [false, false, false, false] },
                     { text: "", border: [false, false, false, false] },
                  ],
               ],
            },

            layout: {
               fillColor: "#e7e9ec",
            },
         },
         {
            text: [
               {
                  text: "Hermosillo Sonora siendo las ",
               },
               { text: `${timeDate[1]} `, bold: true },
               {
                  text: "el día ",
               },
               { text: `${timeDate[0]} `, bold: true },
               {
                  text: `del mes `,
               },
               { text: `${getMonthWord(date[1])} `, bold: true },
               {
                  text: `del año `,
               },
               { text: `${date[0]} `, bold: true },
               {
                  text: "en el domicilio ",
               },
               { text: `${address} `, bold: true },
               {
                  text: "de la empresa ",
               },
               { text: `${company} `, bold: true },
               {
                  text: `el órgano interno de control emite la siguiente resolución respecto a la ${
                     type !== "denuncia" ? `${type}` : "denuncia"
                  } con número de reporte `,
               },
               { text: `${reportNumber} `, bold: true },
               {
                  text: "interpuesta por el C. ",
               },
               { text: `${complainantName}`, bold: true },
               {
                  text: ":",
               },
            ],
            fontSize: 11,
            alignment: "justify",

            margin: [45, 0, 45, 10],
            lineHeight: 1.1,
         },
         {
            text: "  RELACIÓN DE LOS HECHOS  ",
            style: {
               color: "#fff",

               bold: true,
               background: "#152c44",
               fontSize: 16,
               alignment: "center",
            },
            margin: [0, 10, 0, 10],
         },
         {
            columns: [
               { image: imageArrow, width: 12 },
               {
                  text: `Hechos declarados por ${
                     type !== "denuncia" && type !== "" ? "el interpositor" : "el denunciante"
                  }:`,
                  style: {
                     color: "#152c44",

                     bold: true,
                     fontSize: 14,
                  },
                  margin: [5, 0, 0, 5],
               },
            ],
            margin: [25, 10, 25, 10],
         },
         {
            text: `${complainantFacts}`,
            fontSize: 11,
            margin: [25, 10, 25, 10],
            alignment: "justify",
            lineHeight: 1.1,
         },
         {
            columns: [
               { image: imageArrow, width: 12 },
               {
                  text: `Pruebas otorgadas por ${
                     type !== "denuncia" && type !== "" ? "el interpositor" : "el denunciante"
                  }:`,
                  style: {
                     color: "#152c44",

                     bold: true,
                     fontSize: 14,
                  },
                  margin: [5, 0, 0, 5],
               },
            ],
            margin: [25, 10, 25, 10],
         },
         {
            text: `${complainantProof}`,
            fontSize: 11,
            alignment: "justify",
            margin: [25, 10, 25, 10],
            lineHeight: 1.1,
         },
         type !== "denuncia" && type !== ""
            ? {}
            : {
                 columns: [
                    { image: imageArrow, width: 12 },
                    {
                       text: "Hechos declarados por los testigos:",
                       style: {
                          color: "#152c44",

                          bold: true,
                          fontSize: 14,
                       },
                       margin: [5, 0, 0, 5],
                    },
                 ],
                 margin: [25, 10, 25, 10],
              },
         // complaintType
         witnesses.length === 0
            ? {}
            : {
                 layout: "noBorders",
                 table: {
                    widths: [120, 360],
                    body: witnessesTable,
                 },
              },

         type !== "denuncia" && type !== ""
            ? {}
            : {
                 columns: [
                    { image: imageArrow, width: 12 },
                    {
                       text: "Hechos declarados por el denunciado:",
                       style: {
                          color: "#152c44",

                          bold: true,
                          fontSize: 14,
                       },
                       margin: [5, 0, 0, 5],
                    },
                 ],
                 margin: [25, 10, 25, 10],
              },
         // complaintType
         offenderDetails.length === 0
            ? {}
            : {
                 layout: "noBorders",
                 table: {
                    widths: [120, 360],
                    body: offenders,
                 },
              },
         {
            text: "  RESOLUCIÓN  ",
            style: {
               color: "#fff",
               bold: true,
               background: "#152c44",
               fontSize: 16,
               alignment: "center",
            },
         },
         (type === "denuncia" && {
            text: `Con base en los hechos declarados y las pruebas aportadas por las partes y los testigos en su caso, el órgano interno de control determina que la denuncia interpuesta por el denunciante es considerada como:`,
            fontSize: 11,
            alignment: "justify",
            margin: [45, 10, 135, 10],
            lineHeight: 1.1,
         }) ||
            {},
         {
            text: `${resolution}`,
            fontSize: 11,
            alignment: "justify",
            margin: [25, 10, 25, 10],
            bold: true,
            lineHeight: 1.1,
            pageBreak: complaintType ? "after" : "",
         },
         {
            text: `Fundamentando su determinación en razón a los siguientes criterios:`,
            style: {
               color: "#152c44",
               bold: true,
               fontSize: 14,
            },
         },
         {
            text: `${fundaments}`,
            fontSize: 11,
            alignment: "justify",
            margin: [25, 10, 25, 10],
            lineHeight: 1.1,
            pageBreak: type !== "denuncia" ? "after" : "",
         },
         {
            text: "En tal orden de ideas,",
            fontSize: 11,
            alignment: "center",
            color: "#152c44",
            lineHeight: 1.1,
         },
         {
            text: "se ordena la imposición de las siguientes:",
            fontSize: 11,
            alignment: "center",
            color: "#152c44",
            lineHeight: 1.1,
            margin: [0, 0, 0, 5],
         },
         {
            text: `         MEDIDAS          `,
            bold: true,
            fontSize: 15,
            color: "#fff",
            background: "#152c44",
            alignment: "center",
         },
         {
            text: `${measures}`,
            fontSize: 11,
            alignment: "justify",
            margin: [25, 10, 25, 10],
            lineHeight: 1.1,
         },
         // {
         //    text: "Enviar el expediente de la presente denuncia a archivo de consultas jurídicas.",
         //    fontSize: 9,
         //    alignment: "right",
         //    color: "#152c44",
         //    lineHeight: 1.1,
         //    margin: [45, 0, 45, 20],
         // },
         signatures(complimentOfficer, auditorIntern, generalDirection),
         // {
         //    text: ``,
         //    fontSize: 11,
         //    alignment: "justify",
         //    margin: [45, 100, 45, 100],
         //    lineHeight: 1.1,
         // },
         // signatures(complimentOfficer, auditorIntern, generalDirection),
      ],
   };
   return dd;
};

const evidenceColumns = (evidence: string) => {
   return {
      columns: [
         { image: imageArrow, width: 12 },
         {
            text: `${evidence}`,
            fontSize: 11,
            margin: [5, 2, 0, 2],
         },
      ],
      margin: [45, 0, 45, 0],
   };
};

export const recordClousureTemplate = (
   reportNumber: string,
   complainerName: string,
   complainerAddress: string,
   complainerType: string,
   directlyAffected: boolean,
   complainerPhone: string,
   complainerEmail: string,
   anonimousComplaint: boolean,
   calification: string,
   witnesses: any[],
   complaintDate: string,
   complaintPlace: string,
   complaintDescription: string,
   complaintStatus: string,
   reportObservations: string,
   factClasification: string,
   complaintInterpositionDate: string,
   investigationProcess: string,
   documentation: IDocumentationDetails[],
   complainantResponse: boolean,
   complainerEvidence: any,
   offenderEvidence: any[],
   witnessEvidence: any[],
   evidenceObservations: string,
   resolution: string,
   measures: string[],
   measuresObservations: string,
   offendersData: any[],
   type: string
) => {
   const date = new Date(complaintDate);
   const date2 = new Date(complaintInterpositionDate);
   const deocumentationTemp: any[] = [];

   const offenderEvidences =
      offenderEvidence.length > 0
         ? offenderEvidence.map((offender) => {
              let evidenceFile =
                 offender.documents?.length > 0
                    ? offender.documents.map((evidence, i) => {
                         if (i === 0) {
                            return [
                               textDefinition(
                                  offender.offenderInfo.name + " " + offender.offenderInfo.lastName,
                                  [62, 6, 45, 0],
                                  true
                               ),
                               evidenceColumns(evidence),
                            ];
                         } else {
                            return evidenceColumns(evidence);
                         }
                      })
                    : [
                         textDefinition(
                            offender.offenderInfo.name + " " + offender.offenderInfo.lastName,
                            [62, 6, 45, 0],
                            true
                         ),
                         { text: "No presentó evidencias", fontSize: 11, margin: [45, 6, 45, 0] },
                      ];

              return evidenceFile;
           })
         : [{ text: "No existen denunciados", fontSize: 11, margin: [45, 6, 45, 0] }];

   const witnessEvidences =
      witnessEvidence.length > 0
         ? witnessEvidence.map((witness) => {
              let evidenceFile =
                 witness.documents?.length > 0
                    ? witness.documents.map((evidence, i) => {
                         if (i === 0) {
                            return [
                               textDefinition(
                                  witness.witnessInfo.name + " " + witness.witnessInfo.lastName,
                                  [62, 6, 45, 0],
                                  true
                               ),
                               evidenceColumns(evidence),
                            ];
                         } else {
                            return evidenceColumns(evidence);
                         }
                      })
                    : [
                         textDefinition(
                            witness.witnessInfo.name + " " + witness.witnessInfo.lastName,
                            [62, 6, 45, 0],
                            true
                         ),
                         { text: "No presentó evidencias", fontSize: 11, margin: [45, 6, 45, 0] },
                      ];

              return evidenceFile;
           })
         : [{ text: "No existen testigos", fontSize: 11, margin: [45, 6, 45, 0] }];
   const bigText = (header: string, text: string) => {
      return {
         text: [
            textDefinition(header, [0, 0, 0, 0], true, "#152c44"),
            {
               text: text,
               alignment: "left",
               fontSize: 11,
            },
         ],
         margin: [10, 0, 30, 2],
      };
   };

   const fetchInfo = (info: any, type: boolean, index: number) => {
      return [
         {
            text: `${type ? "Testigo" : "Denunciado"} ${index + 1}:`,
            bold: true,
            fontSize: 12,
            alignment: "right",
            margin: [0, 0, 0, 0],
         },
         [
            bigText("Nombre: ", info.name),
            bigText("Puesto: ", info.position),
            bigText("Género: ", info.gender),
            bigText("Observaciones: ", info.observations),
         ],
      ];
   };

   const witnessesTable = witnesses.map((e, i) => {
      return fetchInfo(e, true, i);
   });

   const offenders = offendersData.map((e, i) => {
      return fetchInfo(e, false, i);
   });

   documentation.forEach((doc) => {
      return deocumentationTemp.push(doc.name);
   });

   const dd = {
      info: {
         title: `cierre-${reportNumber}`,
      },
      pageSize: "A4",
      pageMargins: [40, 80, 40, 150],
      header: header("CIERRE DE EXPEDIENTE"),
      footer: {
         columns: [
            {
               margin: [132.5, 20, 132.5, 10],
               table: {
                  hegiths: [30, 50],
                  widths: [330],
                  body: [
                     (factClasification && [
                        {
                           margin: [2, 0, 0, 0],
                           text: "Clasificación de hecho:",
                           fontSize: 11,
                           bold: true,
                           fillColor: "#dddddd",
                        },
                     ]) ||
                        [],
                     (factClasification && [
                        {
                           margin: [2, 0, 0, 0],
                           text: factClasification,
                           fontSize: 11,
                           fillColor: "#dddddd",
                        },
                     ]) ||
                        [],
                     [
                        {
                           text: `Fecha de interposición de la denuncia en el canal: ${date2.getDay()}/${date2.getMonth()}/${date2.getFullYear()}`,
                           alignment: "center",
                           fontSize: 11,
                           margin: [0, 8, 0, 0],
                        },
                     ],
                  ],
               },
               layout: "noBorders",
            },
         ],
      },

      content: [
         {
            margin: [100, 10, 0, 10],
            table: {
               widths: [180, 250],
               heights: [1, 10, 10, 10, 10, 1],
               body: [
                  [
                     { text: "", border: [false, false, false, false] },
                     { text: "", border: [false, false, false, false] },
                  ],
                  [
                     {
                        text: `Número de reporte ${type !== "denuncia" ? "del interpositor" : "del denunciante"}:`,
                        border: [false, false, false, false],
                        alignment: "right",
                        color: "#152c44",
                        bold: true,
                        fontSize: 11,
                     },
                     {
                        text: `${reportNumber}`,
                        fontSize: 11,
                        border: [false, false, false, false],
                        alignment: "left",
                     },
                  ],
                  [
                     {
                        text: `Nombre ${type !== "denuncia" ? "del interpositor" : "del denunciante"}:`,
                        border: [false, false, false, false],
                        alignment: "right",
                        color: "#152c44",
                        bold: true,
                        fontSize: 11,
                     },
                     {
                        text: anonimousComplaint ? `Anónimo` : `${complainerName}`,
                        fontSize: 11,
                        border: [false, false, false, false],
                        alignment: "left",
                     },
                  ],

                  [
                     {
                        text: `${
                           type !== "denuncia" ? `${type === "queja" ? "Queja" : "Sugerencia"}` : "Denuncia"
                        } anónima:`,
                        border: [false, false, false, false],
                        alignment: "right",
                        color: "#152c44",
                        bold: true,
                        fontSize: 11,
                     },
                     {
                        text: anonimousComplaint ? `SI` : `NO`,
                        fontSize: 11,
                        border: [false, false, false, false],
                        alignment: "left",
                     },
                  ],
                  [
                     {
                        text: type === "denuncia" ? "Clasificación de la denuncia:" : "",
                        border: [false, false, false, false],
                        alignment: "right",
                        color: "#152c44",
                        bold: true,
                        fontSize: 11,
                     },
                     {
                        text: type === "denuncia" ? `${calification}` : "",
                        fontSize: 11,
                        border: [false, false, false, false],
                        alignment: "left",
                     },
                  ],
                  [
                     { text: "", border: [false, false, false, false] },
                     { text: "", border: [false, false, false, false] },
                  ],
               ],
            },
            layout: {
               fillColor: "#e7e9ec",
            },
         },
         {
            text: ` DATOS ${type !== "denuncia" ? "DEL INTERPOSITOR" : "DEL DENUNCIANTE"}: `,
            color: "#fff",
            background: "#152c44",
            fontSize: 12,

            bold: true,
            margin: [25, 4, 30, 0],
         },
         {
            text: [
               textDefinition("Nombre: ", [0, 0, 0, 0], true, "#152c44"),
               {
                  text: anonimousComplaint ? `Anónimo` : `${complainerName}`,
                  alignment: "left",
                  fontSize: 11,
               },
            ],
            margin: [45, 2, 45, 2],
         },
         {
            text: [
               {
                  text: "Domicilio: ",
                  fontSize: 11,
                  bold: true,
                  alignment: "left",
                  color: "#152c44",
               },
               {
                  text: `${complainerAddress}`,
                  alignment: "left",
                  fontSize: 11,
               },
            ],
            margin: [45, 2, 45, 2],
         },
         {
            text: [
               {
                  text: `Tipo de ${type !== "denunciante" ? "interpositor" : "denunciante"}: `,
                  fontSize: 11,
                  bold: true,
                  alignment: "left",
                  color: "#152c44",
               },
               {
                  text: `${complainerType}`,
                  alignment: "left",
                  fontSize: 11,
               },
            ],
            margin: [45, 2, 45, 2],
         },
         {
            text: [
               {
                  text: "Se le afectó directamente: ",
                  fontSize: 11,
                  bold: true,
                  alignment: "left",
                  color: "#152c44",
               },
               {
                  text: directlyAffected ? "Si" : `No`,
                  alignment: "left",
                  fontSize: 11,
               },
            ],
            margin: [45, 2, 45, 2],
         },
         {
            text: [
               {
                  text: "Número telefónico: ",
                  alignment: "left",
                  bold: true,
                  fontSize: 11,
                  color: "#152c44",
               },
               { text: `${complainerPhone}`, alignment: "left", fontSize: 11 },
            ],
            margin: [45, 2, 45, 2],
         },
         {
            text: [
               {
                  text: "Correo electrónico: ",
                  fontSize: 11,
                  bold: true,
                  alignment: "left",
                  color: "#152c44",
               },
               { text: `${complainerEmail}`, alignment: "left", fontSize: 11 },
            ],
            margin: [45, 2, 45, 2],
         },
         (type === "denuncia" && {
            text: offendersData.length > 1 ? " DATOS DE LOS DENUNCIADOS: " : " DATOS DEL DENUNCIADO: ",
            color: "#fff",
            background: "#152c44",
            fontSize: 12,
            bold: true,
            margin: [25, 6, 30, 4],
         }) ||
            {},
         (type === "denuncia" &&
            (offendersData.length > 0
               ? {
                    layout: "noBorders",
                    table: {
                       widths: [100, 350],
                       body: offenders,
                    },
                    margin: [30, 0, 30, 0],
                 }
               : {
                    text: "No aplica",
                    fontSize: 12,
                    bold: true,
                    margin: [45, 6, 45, 4],
                 })) ||
            {},
         (type === "denuncia" && {
            text: witnesses.length > 1 ? " DATOS DE LOS TESTIGOS: " : " DATOS DEL TESTIGO: ",
            color: "#fff",
            background: "#152c44",
            fontSize: 12,
            bold: true,
            margin: [25, 6, 30, 4],
         }) ||
            {},
         (type === "denuncia" &&
            (witnesses.length > 0
               ? {
                    layout: "noBorders",
                    table: {
                       widths: [100, 350],
                       body: witnessesTable,
                    },
                    margin: [30, 0, 30, 0],
                 }
               : {
                    text: "No aplica",
                    fontSize: 12,
                    bold: true,
                    margin: [45, 6, 45, 4],
                 })) ||
            {},
         {
            text: " DATOS DE LA DENUNCIA: ",
            color: "#fff",
            background: "#152c44",
            fontSize: 12,
            bold: true,
            margin: [25, 6, 30, 4],
         },
         {
            text: [
               {
                  text: "Fecha del acto: ",
                  fontSize: 11,
                  bold: true,
                  alignment: "left",
                  color: "#152c44",
               },
               {
                  text: `${getDateFromISODate(date)}`,
                  fontSize: 11,
                  alignment: "left",
               },
            ],
            margin: [45, 2, 45, 2],
         },
         {
            text: [
               {
                  text: "Donde sucedieron los hechos: ",
                  fontSize: 11,
                  bold: true,
                  alignment: "left",
                  color: "#152c44",
               },
               { text: `${complaintPlace}`, fontSize: 11, alignment: "left" },
            ],
            margin: [45, 2, 45, 2],
         },
         {
            text: `Descripción de la ${type || "denuncia"}: `,
            fontSize: 11,
            bold: true,
            alignment: "left",
            color: "#152c44",
            margin: [45, 2, 45, 2],
         },
         {
            text: `${complaintDescription}`,
            fontSize: 11,
            margin: [45, 2, 45, 2],
         },
         {
            text: " REPORTE LECOSY: ",
            color: "#fff",
            background: "#152c44",
            fontSize: 12,
            bold: true,
            margin: [25, 10, 30, 2],
         },
         {
            text: [
               {
                  text: `Estatus de la ${type || "denuncia"}: `,
                  fontSize: 11,
                  alignment: "left",
                  bold: true,
                  color: "#152c44",
               },
               { text: `${complaintStatus}`, fontSize: 11, alignment: "left" },
            ],
            margin: [45, 2, 45, 2],
         },
         {
            text: "Observaciones: ",
            fontSize: 11,
            alignment: "left",
            bold: true,
            color: "#152c44",
            margin: [45, 2, 45, 2],
         },
         {
            text: `${reportObservations ? reportObservations : "No aplica"}`,
            fontSize: 11,
            alignment: "left",
            margin: [45, 2, 45, 2],
         },

         (type === "denuncia" && {
            text: " PROCESO DE INVESTIGACIÓN DE LA DENUNCIA:  ",
            color: "#fff",
            background: "#152c44",
            fontSize: 12,
            bold: true,
            margin: [25, 5, 30, 5],
         }) ||
            {},
         {
            text: `${investigationProcess}`,
            fontSize: 11,
            alignment: "justify",
            margin: [45, 4, 45, 0],
         },
         {
            text: " DOCUMENTACIÓN: ",
            color: "#fff",
            background: "#152c44",
            fontSize: 12,
            bold: true,
            margin: [25, 6, 30, 6],
         },
         {
            text: `Se enviaron los siguientes documentos de notificación al ${
               type !== "denuncia" ? "interpositor" : "denunciante"
            }:`,
            fontSize: 11,
            bold: true,
            color: "#152c44",
            margin: [45, 4, 45, 4],
         },

         deocumentationTemp.map((doc) => {
            return evidenceColumns(doc);
         }),

         //Lista de documentos

         (type === "denuncia" && {
            text: " EXISTIÓ RESPUESTA/USO DE RÉPLICA POR EL DENUNCIANTE:  ",
            color: "#fff",
            background: "#152c44",
            fontSize: 12,
            bold: true,
            margin: [25, 6, 30, 0],
         }) ||
            {},
         (type === "denuncia" && {
            text: complainantResponse ? "Si" : `No`,
            fontSize: 11,
            alignment: "justify",
            margin: [45, 4, 45, 0],
         }) ||
            {},
         {
            text: " EVIDENCIA: ",
            color: "#fff",
            background: "#152c44",
            fontSize: 12,
            bold: true,
            margin: [25, 6, 30, 6],
         },
         {
            text: "Se brindaron otro tipo de evidencias:",
            bold: true,
            color: "#152c44",
            fontSize: 11,
            margin: [45, 4, 45, 4],
         },
         {
            text: `${type !== "denuncia" ? "Interpositor" : "Denunciante"}:`,
            bold: true,
            color: "#152c44",
            fontSize: 11,
            margin: [45, 4, 45, 0],
         },
         complainerEvidence.documents?.length > 0
            ? complainerEvidence.documents.map((evidence, i) => {
                 if (i === 0) {
                    return [
                       {
                          text: complainerEvidence.complainerInfo
                             ? complainerEvidence.complainerInfo.name + " " + complainerEvidence.complainerInfo.lastName
                             : "Anónimo",
                          fontSize: 11,
                          margin: [62, 6, 45, 0],
                          bold: true,
                       },
                       evidenceColumns(evidence),
                    ];
                 } else {
                    return evidenceColumns(evidence);
                 }
              })
            : { text: "Ninguna", fontSize: 11, margin: [45, 6, 45, 0] },
         (type === "denuncia" && {
            text: "Denunciados:",

            bold: true,
            color: "#152c44",
            fontSize: 11,
            margin: [45, 4, 45, 0],
         }) ||
            {},
         (type === "denuncia" &&
            offenderEvidences.map((evidence) => {
               return evidence;
            })) ||
            {},
         (type === "denuncia" && {
            text: "Testigos:",
            bold: true,
            color: "#152c44",
            fontSize: 11,
            margin: [45, 4, 45, 0],
         }) ||
            {},
         (type === "denuncia" &&
            witnessEvidences.map((evidence) => {
               return evidence;
            })) ||
            {},

         {
            text: "Observaciones:",
            bold: true,
            color: "#152c44",
            fontSize: 11,
            margin: [45, 10, 45, 0],
         },
         {
            text: `${evidenceObservations}`,
            fontSize: 11,
            alignment: "justify",
            margin: [45, 4, 45, 0],
         },
         (type === "denuncia" && {
            text: " RESOLUCIÓN:        ",
            color: "#fff",
            background: "#152c44",
            fontSize: 12,
            bold: true,
            margin: [25, 5, 45, 0],
         }) ||
            {},
         {
            text: `${resolution}`,
            fontSize: 11,
            alignment: "justify",
            margin: [45, 4, 45, 0],
         },
         {
            text: " MEDIDAS IMPUESTAS:  ",
            color: "#fff",
            background: "#152c44",
            fontSize: 12,
            bold: true,
            margin: [25, 6, 45, 6],
         },
         {
            text: "Se aplicaron las siguientes medidas:",
            bold: true,
            fontSize: 11,
            color: "#152c44",
            margin: [45, 4, 45, 4],
         },
         measures.map((doc) => {
            return {
               columns: [
                  { image: imageArrow, width: 12 },
                  {
                     text: `${doc}`,

                     fontSize: 11,
                     margin: [5, 2, 0, 4],
                  },
               ],
               margin: [45, 0, 45, 0],
            };
         }),
         //Medidas
         {
            text: "Observaciones:",
            bold: true,
            color: "#152c44",
            fontSize: 11,
            margin: [45, 4, 45, 0],
         },
         {
            text: `${measuresObservations}`,
            fontSize: 11,
            alignment: "justify",
            margin: [45, 4, 45, 0],
         },
      ],
   };
   return dd;
};

export const incompetenceDeclaration = (
   city: string,
   state: string,
   time: string,
   complimentOfficer: string,
   auditorIntern: string,
   generalDirection: string,
   involvedName: string,
   complaintDate: string,
   reportNumber: string,
   incompetenceType: string,
   competence?: string,
   competenceName?: string
) => {
   const date = time.split("-");
   const timeDate = date[2].split("T");
   const dd = {
      info: {
         title: `declaracion-incompetencia-${reportNumber}`,
      },
      pageSize: "A4",
      pageMargins: [40, 60, 40, 60],
      content: [
         {
            canvas: [
               {
                  type: "rect",
                  x: 105,
                  y: 70,
                  w: 300,
                  h: 20,
                  color: "#e7e9ec",
               },
               {
                  type: "rect",
                  x: 75,
                  y: 78,
                  w: 360,
                  h: 5,
                  color: "#152c44",
               },
            ],
         },
         {
            image: imageLogo,
            width: 160,
            absolutePosition: { x: 217.5, y: 40 },
         },
         {
            text:
               incompetenceType === "2"
                  ? `Declaración de incompetencia
           Miembro del órgano interno de control`
                  : `Declaración de incompetencia
           Oficial de cumplimiento`,
            bold: true,
            fontSize: 17,
            alignment: "center",
            color: "#152c44",
            margin: [0, 50, 0, 30],
         },
         {
            text: [
               {
                  text: "En la ciudad de ",
               },
               { text: `${city}, ${state} `, bold: true },
               {
                  text: "siendo las ",
               },
               { text: `${timeDate[1]} `, bold: true },
               {
                  text: `horas del día `,
               },
               { text: `${timeDate[0]} `, bold: true },
               {
                  text: `del mes `,
               },
               { text: `${getMonthWord(date[1])} `, bold: true },
               {
                  text: `del año `,
               },
               { text: `${date[0]} `, bold: true },

               {
                  text: "el C.",
               },
               { text: `${incompetenceType === "1" ? complimentOfficer : involvedName} `, bold: true },
               {
                  text: "siendo el caso que se configura la existencia un ",
               },
               { text: "conflicto de intereses ", bold: true },
               {
                  text: "para conocer y resolver de la denuncia interpuesta en fecha ",
               },
               { text: `${complaintDate} `, bold: true },
               {
                  text: "con número de reporte ",
               },
               { text: `${reportNumber} `, bold: true },
               {
                  text: "por así corresponder a las políticas del canal de denuncias que establece LECOSY S.A. DE C.V.",
               },
            ],
            fontSize: 11,
            alignment: "justify",

            margin: [45, 10, 45, 0],
            lineHeight: 1.1,
         },
         {
            text: [
               {
                  text:
                     incompetenceType === "2"
                        ? `En tal orden de ideas, se declara competente para conocer y resolver de la presente. Lo anterior con el objetivo de brindar proceso de investigación justo conforme a las políticas del canal de denuncias.`
                        : "En tal orden de ideas, se declara competente para conocer y resolver de la presente denuncia al ",
               },
               {
                  text: incompetenceType === "2" ? "" : `${competence} `,
                  bold: true,
               },
               {
                  text: incompetenceType === "2" ? "" : `el C. `,
               },
               {
                  text: incompetenceType === "2" ? "" : `${competenceName} `,
                  bold: true,
               },
               {
                  text:
                     incompetenceType === "2"
                        ? ""
                        : `, tomando así la calidad de Oficial de Cumplimiento durante el proceso de investigación de la presente denuncia. Lo anterior con el objetivo de brindar proceso de investigación justo conforme a las políticas del canal de denuncias.`,
               },
            ],
            fontSize: 11,
            alignment: "justify",

            margin: [45, 10, 45, 0],
            lineHeight: 1.1,
         },
         signatures(complimentOfficer, auditorIntern, generalDirection),
      ],
   };
   return dd;
};
