import {
   Box,
   FormControl,
   Grid,
   IconButton,
   List,
   ListItem,
   MenuItem,
   Select,
   Stack,
   Typography,
   Checkbox,
   ListItemText,
   Divider,
} from "@mui/material";
import { useCallback, useContext, useEffect, useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import { Formik, Form } from "formik";
import { InputCheckBox } from "../../../Inputs/InputCheckBox";
import {
   AffairsInterface,
   CorporateDataContext,
   AffairSeries,
} from "../../../../context/governanceContext/corporateDataContext";
import { UserContext } from "../../../../context/userContext";

interface ActionSeriesComponentProps {
   affair: AffairsInterface;
   handleUpdateAffairInfo: Function;
}
const ActionSeriesComponent = (props: ActionSeriesComponentProps) => {
   const { affair, handleUpdateAffairInfo } = props;
   const { series, handleChangedSeries, currentAffair, classifications } = useContext(CorporateDataContext);
   const { handleUpdateEditingSection } = useContext(UserContext);
   const [classification, setClassification] = useState(affair.classification || classifications[0]?.title);

   useEffect(() => {
      const localAffair = affair;
      localAffair.classification = classification;
      handleUpdateAffairInfo(localAffair);
   }, [classification, affair, handleUpdateAffairInfo]);

   //#region seriesCrud

   const handleAddSerie = (serie) => {
      handleChangedSeries([...currentAffair.series, { title: serie.title, faculties: 0 }]);
      handleUpdateEditingSection("societary");
   };

   const handleRemoveSerie = (serie) => {
      const tempSeries = currentAffair.series;
      const deleteIndex = tempSeries.map((s) => s.title).indexOf(serie.title);
      const newArr = tempSeries.slice(0, deleteIndex).concat(tempSeries.slice(deleteIndex + 1));
      handleChangedSeries(newArr);
      handleUpdateEditingSection("societary");
   };

   //#endregion

   return (
      <Box width={"100%"} px={2} py={1}>
         <ClasificationSelector classification={classification} setClassification={setClassification} />
         <FormControl size="small" sx={{ my: 2 }}>
            <Stack direction={"row"} spacing={5}>
               <Typography alignSelf={"center"} fontWeight={600} color={"#162c44"}>
                  Series
               </Typography>
               <Select
                  variant="outlined"
                  size={"small"}
                  fullWidth
                  value={currentAffair.series.map((s) => s.title)}
                  multiple
                  renderValue={() => "Seleccionar serie"}
                  sx={{ minWidth: "160px" }}
               >
                  {series.map((serie, i) => (
                     <MenuItem
                        key={serie.title}
                        value={serie.title}
                        onClick={() => {
                           handleUpdateEditingSection("societary");
                           if (!currentAffair.series.some((s) => s.title === serie.title)) handleAddSerie(serie);
                           else handleRemoveSerie(serie);
                        }}
                     >
                        <Checkbox checked={currentAffair.series.some((s) => s.title === serie.title)} />
                        <ListItemText primary={serie.title} />
                     </MenuItem>
                  ))}
               </Select>
            </Stack>
         </FormControl>
         <Stack direction={"column"} borderRight={1} borderTop={1} borderBottom={1} borderColor={"#D8D8D8"}>
            <Typography
               borderBottom={1}
               textAlign={"center"}
               bgcolor={"whiteSmoke"}
               borderColor={"#D8D8D8"}
               fontWeight={600}
               color={"#162c44"}
            >
               Series de acciones
            </Typography>
            <Grid container>
               <Grid
                  item
                  xs={3}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  borderColor={"#D8D8D8"}
                  bgcolor={"whiteSmoke"}
               >
                  <Typography>Tipo de serie</Typography>
               </Grid>
               <Grid
                  item
                  xs={3}
                  display={"flex"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  borderLeft={1}
                  borderColor={"#D8D8D8"}
                  bgcolor={"whiteSmoke"}
               >
                  <Typography>Valor nominal</Typography>
               </Grid>
               <Grid item xs={3} display={"flex"}>
                  <Stack
                     direction={"column"}
                     width={"100%"}
                     display={"flex"}
                     alignItems={"center"}
                     borderLeft={1}
                     borderColor={"#D8D8D8"}
                     bgcolor={"whiteSmoke"}
                  >
                     <Box width={"100%"} py={1} borderBottom={1} borderColor={"#D8D8D8"}>
                        <Typography textAlign={"center"}>Facultades</Typography>
                     </Box>
                     <Box width={"100%"} display={"flex"} justifyContent={"space-around"}>
                        <Typography>Voz</Typography>
                        <Typography>Voto</Typography>
                     </Box>
                  </Stack>
               </Grid>
               <Grid item xs={3} display={"flex"} justifyContent={"center"}>
                  <Stack
                     direction={"column"}
                     width={"100%"}
                     display={"flex"}
                     alignItems={"center"}
                     justifyContent={"center"}
                     borderLeft={1}
                     borderColor={"#D8D8D8"}
                     bgcolor={"whitesmoke"}
                  >
                     <Typography>Acciones</Typography>
                  </Stack>
               </Grid>
            </Grid>
            <SeriesList series={currentAffair.series} deleteSerie={handleRemoveSerie} />
         </Stack>
      </Box>
   );
};

interface ClasificationSelectorProps {
   classification: string;
   setClassification: Function;
}

const ClasificationSelector = (props: ClasificationSelectorProps) => {
   const { handleUpdateEditingSection } = useContext(UserContext);
   const { classifications } = useContext(CorporateDataContext);
   const { classification, setClassification } = props;
   const selectedClassification = classifications.find((c) => c.title === classification);
   return (
      <Stack
         direction={"row"}
         justifyContent={"space-between"}
         alignItems={"center"}
         width={"100%"}
         bgcolor={"whiteSmoke"}
         py={1}
         px={10}
         my={1}
         mb={3}
         borderRight={1}
         borderTop={1}
         borderBottom={1}
         borderColor={"#D8D8D8"}
      >
         <FormControl size="small">
            <Stack direction={"row"} spacing={5}>
               <Typography alignSelf={"center"} fontWeight={600} color={"#162c44"}>
                  Clasificación
               </Typography>
               <Select
                  sx={{ minWidth: "150px", height: "35px" }}
                  defaultValue={classification || classifications[0]?.title}
                  onChange={(e) => {
                     setClassification(e.target.value);
                     handleUpdateEditingSection("societary");
                  }}
               >
                  {classifications.map((c) => {
                     return (
                        <MenuItem value={c.title} key={c.title}>
                           {c.title}
                        </MenuItem>
                     );
                  })}
               </Select>
            </Stack>
         </FormControl>
         {selectedClassification && (
            <Stack direction={"row"} spacing={5}>
               <Stack direction={"column"} spacing={0.5}>
                  <Typography fontSize={"14px"} fontWeight={600}>
                     Quorum de asistencia
                  </Typography>
                  <Stack direction={"row"} justifyContent={"space-around"}>
                     <Typography
                        fontSize={"13px"}
                        sx={{ bgcolor: "#162c44", borderRadius: "5px", color: "white", px: 2, py: 0.5 }}
                     >
                        {selectedClassification && `${selectedClassification.quorumA[0]}%`}
                     </Typography>
                     <Typography
                        fontSize={"13px"}
                        sx={{ bgcolor: "#162c44", borderRadius: "5px", color: "white", px: 2, py: 0.5 }}
                     >
                        {selectedClassification && `${selectedClassification.quorumA[1]}%`}
                     </Typography>
                  </Stack>
               </Stack>
               <Divider orientation="vertical" flexItem />
               <Stack direction={"column"} spacing={0.5}>
                  <Typography fontSize={"14px"} fontWeight={600}>
                     Quorum de votación
                  </Typography>
                  <Stack direction={"row"} justifyContent={"space-around"}>
                     <Typography
                        fontSize={"13px"}
                        sx={{ bgcolor: "#162c44", borderRadius: "5px", color: "white", px: 2, py: 0.5 }}
                     >
                        {selectedClassification.quorumV[0]}%
                     </Typography>
                     <Typography
                        fontSize={"13px"}
                        sx={{ bgcolor: "#162c44", borderRadius: "5px", color: "white", px: 2, py: 0.5 }}
                     >
                        {selectedClassification.quorumV[1]}%
                     </Typography>
                  </Stack>
               </Stack>
            </Stack>
         )}
      </Stack>
   );
};

interface SeriesListProps {
   series: AffairSeries[];
   deleteSerie: Function;
}

const SeriesList = (props: SeriesListProps) => {
   const { series, deleteSerie } = props;
   return (
      <List dense disablePadding sx={{ maxHeight: "180px", overflowY: "auto" }} key={series.length}>
         {series.length > 0 &&
            series.map((s, i) => <SeriesObject s={s} i={i} key={s.title} deleteSerie={deleteSerie} />)}
      </List>
   );
};

const SeriesObject = ({ s, i, deleteSerie }) => {
   const { updateAffairSerie, series, currentAffair } = useContext(CorporateDataContext);
   const { handleUpdateEditingSection } = useContext(UserContext);
   const [editable, setEditable] = useState<boolean>(s.NewSerie);

   const handleSetEditable = useCallback(() => setEditable((s) => !s), []);
   const handleEnableEdition = useCallback(() => setEditable(true), []);

   const handleVotes = (values) => {
      let facultiesValue = 0;
      if (values.voice) facultiesValue++;
      if (values.votes) facultiesValue += 2;
      const formatter = {
         title: currentAffair.series[i].title,
         faculties: facultiesValue,
      };
      updateAffairSerie(formatter, i);
      handleSetEditable();
      handleUpdateEditingSection("societary");
   };
   return (
      <Formik
         initialValues={{
            voice: currentAffair.series[i].faculties % 2 !== 0,
            votes: currentAffair.series[i].faculties >= 2,
         }}
         onSubmit={handleVotes}
      >
         <Form>
            <ListItem
               disablePadding
               sx={{ borderTop: 1, borderColor: "#D8D8D8", py: 1 }}
               onDoubleClick={handleEnableEdition}
            >
               <Grid container>
                  <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                     <Typography fontWeight={600} textAlign={"center"}>
                        {s.title || "N/A"}
                     </Typography>
                  </Grid>
                  <Grid item xs={3} display={"flex"} alignItems={"center"} justifyContent={"center"}>
                     <Typography fontWeight={600} textAlign={"center"}>
                        ${series.find((ls) => ls.title === s.title)?.nominalValue || "N/A"}
                     </Typography>
                  </Grid>
                  <Grid
                     item
                     xs={3}
                     display={"flex"}
                     justifyContent={"space-around"}
                     alignItems={"center"}
                     color={"#162c44"}
                  >
                     <InputCheckBox id="voice" name="voice" disablemargin disabled={!editable} />
                     <InputCheckBox id="votes" name="votes" disablemargin disabled={!editable} />
                  </Grid>
                  <Grid item xs={3}>
                     <Stack height={"100%"} display={"flex"} justifyContent={"center"} alignItems={"center"}>
                        {editable ? (
                           <IconButton type="submit">
                              <CheckIcon sx={{ color: "#162c44" }} />
                           </IconButton>
                        ) : (
                           <IconButton onClick={() => deleteSerie(s)}>
                              <DeleteIcon sx={{ color: "#162c44" }} />
                           </IconButton>
                        )}
                     </Stack>
                  </Grid>
               </Grid>
            </ListItem>
         </Form>
      </Formik>
   );
};
export default ActionSeriesComponent;
