import { Box } from "@mui/material";
import { AdvicesScreenComponent } from "../../components/Gobierno corporativo/AdvicesScreen/AdvicesScreenComponent";

export const AdvicesScreen = () => {
   return (
      <Box
         sx={{
            width: "100%",
         }}
      >
         <AdvicesScreenComponent />
      </Box>
   );
};
