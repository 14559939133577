import { Collapse, Grid, List, ListItem, Stack, Typography } from "@mui/material";
import { LocaleDateString } from "../../../../const/globalConst";
import { useGetSessionsAttendanceInfo } from "../../../../hooks/gob-corp/governmentBody/useGetSessionsAttendanceInfo";
import { useContext } from "react";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";

const SessionAttendanceListByTrimester = (props) => {
   const { selectedSession, setSelectedSession } = props;

   const trimesterSessionsInfo = useGetSessionsAttendanceInfo({ ...props });
   return (
      <Stack direction={"column"} color={"#152C44"} sx={{ maxHeight: "290px", overflowY: "auto" }}>
         {Object.keys(trimesterSessionsInfo).map((trimester) => {
            return (
               <List key={`session-${trimester}`}>
                  <Typography sx={{ opacity: "70%" }}>{trimester.replace("trimester", "Trimestre ")}</Typography>
                  <SessionInfo
                     selectedSession={selectedSession}
                     setSelectedSession={setSelectedSession}
                     sessionList={trimesterSessionsInfo[trimester]}
                  />
               </List>
            );
         })}
      </Stack>
   );
};

const SessionInfo = (props) => {
   const { selectedSession, setSelectedSession, sessionList } = props;
   const { selectedGovernance } = useContext(GovernanceContext);
   return (
      (sessionList.length > 0 &&
         sessionList.map((session, i) => {
            return (
               <Stack direction={"column"} key={`${session.title}-${i}`}>
                  <ListItem
                     sx={{ bgcolor: "#F5F5F5", my: 1, ":hover": { bgcolor: "#D8D8D8", cursor: "pointer" } }}
                     dense
                     onClick={() =>
                        setSelectedSession({
                           _id: session._id,
                           involved: session.involved,
                           affairs: session.affairs,
                           votes: session.votes,
                        })
                     }
                  >
                     <Grid container>
                        <Grid item xs={6}>
                           <Stack direction={"column"}>
                              <Typography fontWeight={600} variant="button">
                                 {`Sesión ${i + 1}.`}
                              </Typography>
                              <Typography variant="caption">{session.title}</Typography>
                           </Stack>
                        </Grid>
                        <Grid item xs={6} display={"flex"} alignContent={"center"}>
                           <Typography alignSelf={"center"} variant="button">
                              {LocaleDateString(new Date(session.sessionDate))}
                           </Typography>
                        </Grid>
                     </Grid>
                  </ListItem>
                  {selectedGovernance.title === "Asamblea de accionistas" && (
                     <Collapse in={selectedSession?._id === session._id}>
                        <AffairsList sessionAffairs={session.affairs} />
                     </Collapse>
                  )}
               </Stack>
            );
         })) || <Typography>Sin datos</Typography>
   );
};

const AffairsList = ({ sessionAffairs = [] }) => {
   return (
      <List
         sx={{
            border: 0.5,
            borderColor: "#C8C8C8",
            px: 1,
         }}
      >
         <Typography variant="caption" fontWeight={600}>
            Temas tratados
         </Typography>
         <Stack sx={{ maxHeight: "85px", overflowY: "auto", "::-webkit-scrollbar": { display: "none" } }}>
            {(sessionAffairs.length > 0 &&
               sessionAffairs.map((affair) => (
                  <ListItem
                     key={affair.title}
                     disablePadding
                     sx={{
                        border: 0.5,
                        borderColor: "#C8C8C8",
                        borderLeft: 7,
                        borderLeftColor: "#2D4357",
                        borderBottomLeftRadius: 2,
                        py: 0.5,
                        px: 1,
                        my: 0.3,
                     }}
                  >
                     <Typography variant="caption">{affair.title}</Typography>
                  </ListItem>
               ))) || <Typography>No existen temas tratados</Typography>}
         </Stack>
      </List>
   );
};

export default SessionAttendanceListByTrimester;
