import { Autocomplete, TextField, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { getUsersDetails } from "../../../lib/usersBEClient";
import { getGoberningBodiesByCompany } from "../../../lib/gobCorpBEClient";

interface WatchmanOptionsComponentProps {
   id: string;
   name: string;
   companyId: string;
   setSelected: Function;
   currentWatchman: [];
   noError?: boolean;
}

const useProcessData = (companyId) => {
   const [options, setOptions] = useState([]);
   const { companySelected, user } = useContext(UserContext);
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      const fetchOptions = async () => {
         setIsLoading(true);
         const usersData = companySelected.company_details.users;
         const users = (usersData as any[]).map((user) => ({
            _id: user._id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phoneNumber: user.phoneNumber,
         }));
         if (companySelected.company_details.admin !== undefined) {
            users.push(companySelected.company_details.admin);
         }

         const usersIds = users.map((u) => u._id);
         const usersDetails = await getUsersDetails(usersIds);

         let usersFormat = [];
         for (let t = 0; t < users.length; t++) {
            usersFormat.push({
               ...usersDetails.find((e) => e.user === users[t]._id),
               ...users[t],
            });
         }

         const filterUsers = usersFormat.filter((u) => u.disabled === false && u.user !== user.id);
         const onlyStringUsers = filterUsers.map((u) => u.firstName + " " + u.lastName);

         const bodies = await getGoberningBodiesByCompany(companyId);
         const filteredBodies = (bodies.length > 0 && bodies.filter((b) => b.watchman === true)) || [];
         const onlyTitleBodies = filteredBodies.map((b) => b.title);

         setOptions([...onlyStringUsers, ...onlyTitleBodies]);
         setIsLoading(false);
      };
      fetchOptions();
   }, []);

   return { options, isLoading };
};

export const WatchmanOptionsComponent = (props: WatchmanOptionsComponentProps) => {
   const { options, isLoading } = useProcessData(props.companyId);

   return (
      <>
         <Typography>Órgano de vigilancia</Typography>
         <Autocomplete
            size="small"
            options={options}
            multiple
            loading={isLoading}
            value={props.currentWatchman}
            onChange={(option, value) => {
               props.setSelected(value);
            }}
            getOptionLabel={(option) => `${option}`}
            renderInput={(params) => <TextField {...params} label={""} />}
         />
      </>
   );
};
