import { GovernanceTheSequelContext } from "../../context/governanceContext/governanceTheSequelContext";
import { GovernanceContext } from "../../context/governanceContext/governanceContext";
import CarouselComponent from "../../components/CarouselComponent";
import { Box, CircularProgress } from "@mui/material";
import { useParams } from "react-router-dom";
import { useContext, useMemo } from "react";
import { SubMenu } from "./SubMenu";
import _ from "lodash";

export const TopHeader = ({ loading, goberningBody }) => {
   const { myGovBody } = useContext(GovernanceTheSequelContext);
   const { selectedGovernance } = useContext(GovernanceContext);
   const { companyId } = useParams();

   const filteredGoverningBodies = useMemo(() => {
      let uniqueBodies = _.uniqBy(goberningBody, "title");

      if (selectedGovernance?._id) {
         uniqueBodies = uniqueBodies.map((body: any) =>
            body.title === selectedGovernance.title ? selectedGovernance : body
         );
      }

      return uniqueBodies.map((access: any, index: number) => {
         const govIndex = myGovBody.findIndex((g) => g.bodiesData._id === access._id);
         if (index > 0) if (govIndex < 0) return undefined;
         return (
            <Box key={access._id} mr={3}>
               <SubMenu data={access} index={index} myGovBodyInfo={myGovBody[govIndex]} />
            </Box>
         );
      });
   }, [selectedGovernance, myGovBody, goberningBody, companyId]);

   return loading ? (
      <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
         <CircularProgress sx={{ minHeight: 30 }} />
      </Box>
   ) : (
      <CarouselComponent
         hideSlide={filteredGoverningBodies.length < 7}
         dinamicWidth={filteredGoverningBodies.length}
         slidesToScroll={2}
         maxElements={5}
         variableWidth
      >
         {filteredGoverningBodies}
      </CarouselComponent>
   );
};
