import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { useCallback, useContext, useState } from "react";
import { useDropzone } from "react-dropzone";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { SnackBarContext } from "../../../context/snackBarContext";
import { UpdateFileSession } from "../../../lib/gobCorpBEClient";
import { UseGetPresignedURLUploadExternal, uploadFileToS3 } from "../../../lib/s3Client";
import { ClientTypeModal } from "../../ClientTypeModal";
import { SelectFile } from "../Modal/SelectFile";
import { ExternalGovernanceSessionContext } from "../../../context/governanceContext/externalSessionContext";

export const UploadFileToSessionExternal = ({ setOpenSessionFileModal }) => {
   const [file, setFile] = useState(null);
   const { showSnackBar } = useContext(SnackBarContext);
   const { session, socket, setFileArray, fileArray, externalSessionToken } = useContext(
      ExternalGovernanceSessionContext
   );
   const [openSelectModal, setOpenSelectModal] = useState(false);
   const [onlyPdfFiles, setOnlyPdfFiles] = useState(false);
   const { mutate } = UpdateFileSession();
   const [disableButton, setDisableButton] = useState(false);

   const handleSubmit = async () => {
      if (file._id) {
         if (file.type.split("/")[1] !== "pdf") {
            setOnlyPdfFiles(true);
            return;
         }
      } else {
         const urlReceipt1 = await UseGetPresignedURLUploadExternal({
            bucket: "files-lecosy",
            folder: `gc/companies/${session.company}/governing-body/${session._id}`,
            name: file.originalName ? fileArray.length + "_" + file.originalName : fileArray.length + "_" + file.name,
            token: externalSessionToken,
         });
         await uploadFileToS3(urlReceipt1.data, file);
      }

      setFileArray((current) => [
         ...current,
         {
            fileName: file.originalName
               ? fileArray.length + "_" + file.originalName
               : fileArray.length + "_" + file.name,
         },
      ]);
      socket.emit("upload-file", {
         fileName: file.originalName ? fileArray.length + "_" + file.originalName : fileArray.length + "_" + file.name,
         sessionId: session._id,
      });
      mutate(
         {
            file: {
               fileName: file.originalName
                  ? fileArray.length + "_" + file.originalName
                  : fileArray.length + "_" + file.name,
            },
            sessionId: session._id,
         },
         {
            onError: (error) => {
               console.log(error);
            },
         }
      );

      setOpenSessionFileModal(false);
   };

   const onDrop = useCallback(
      (acceptedFiles) => {
         if (acceptedFiles.length < 1) {
            showSnackBar("Favor de seleccionar un archivo valido.", true);
         }
         if (acceptedFiles[0].type.includes("pdf") && acceptedFiles.size > 5000000) {
            showSnackBar("Archivo mayor a 5 MB", true);
            return;
         }
         setFile(acceptedFiles[0]);
      },
      [showSnackBar, setFile]
   );

   const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: {
         "application/pdf": [".pdf"],
      },
      maxFiles: 1,
   });

   const handleClose = () => {
      setOpenSelectModal(false);
   };

   return (
      <>
         <Stack sx={{ width: 500 }}>
            <Box
               sx={{
                  py: 1.5,
                  bgcolor: "#152c44",
                  borderTopLeftRadius: 15,
                  borderTopRightRadius: 15,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
               }}
            >
               <Typography sx={{ color: "white", ml: 4, fontWeight: 600 }}>Subir archivo a la sesión</Typography>
               <IconButton onClick={() => setOpenSessionFileModal(false)} sx={{ m: 0, mr: 2 }}>
                  <CloseIcon sx={{ color: "white" }} />
               </IconButton>
            </Box>

            <Stack
               spacing={2}
               sx={{
                  bgcolor: "background.paper",
                  py: 3,
                  px: 6,
                  borderBottomLeftRadius: 15,
                  borderBottomRightRadius: 15,
               }}
            >
               {file ? (
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "center",
                     }}
                  >
                     <Box
                        sx={{
                           border: 1,
                           borderColor: "#c4c4c4",
                           px: 5,
                           py: 2,
                           borderRadius: 2,
                           display: "flex",
                           alignItems: "center",
                           gap: 1,
                        }}
                     >
                        <IconButton
                           onClick={() => {
                              setDisableButton(false);
                              setFile(null);
                           }}
                        >
                           <DeleteIcon sx={{ color: "#FF0030" }} />
                        </IconButton>
                        <Typography
                           sx={{
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              maxWidth: 150,
                           }}
                        >
                           {file.originalName ? file.originalName : file.name}
                        </Typography>
                     </Box>
                  </Box>
               ) : (
                  <Box
                     sx={{
                        flex: 1,
                        border: 1,
                        borderColor: "#E2E6EA",
                        borderStyle: "dashed",
                        minHeight: 45,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: 2,
                        width: "90%",
                        alignSelf: "center",
                     }}
                     {...getRootProps()}
                  >
                     <input {...getInputProps()} />
                     <Typography variant="body2" color={"grey"}>
                        Subir archivo
                     </Typography>
                  </Box>
               )}
               {onlyPdfFiles && (
                  <Typography sx={{ color: "red", fontSize: 10, textAlign: "center" }}>
                     Favor de seleccionar un archivo de tipo PDF
                  </Typography>
               )}
               <Box sx={{ display: "flex", justifyContent: "center" }}>
                  <Button
                     disabled={disableButton}
                     onClick={() => {
                        if (!file) return;
                        setDisableButton(true);
                        handleSubmit();
                     }}
                     sx={{ bgcolor: "#2D4357", color: "white", borderRadius: 1, px: 5, py: 0.4, width: "50%" }}
                     variant="contained"
                  >
                     Subir
                  </Button>
               </Box>
            </Stack>
         </Stack>
         <ClientTypeModal
            body={
               <Box>
                  {openSelectModal && (
                     <SelectFile
                        selectedFile={"selectedFile"}
                        setOpenModal={setOpenSelectModal}
                        reload={null}
                        setReload={null}
                        handleClose={handleClose}
                        setFile={setFile}
                     />
                  )}
               </Box>
            }
            onConfirm={() => {}}
            open={openSelectModal}
            setOpen={setOpenSelectModal}
            title={
               <Box sx={{ py: 1 }}>
                  <Typography variant="h5" fontWeight={600}>
                     Selecciona un archivo
                  </Typography>

                  <Typography>Selecciona un archivo para enviar</Typography>
               </Box>
            }
            blue
         />
      </>
   );
};
