import { Box, Button, Divider } from "@mui/material";
import { useRef } from "react";
import Slider from "react-slick";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

export type CarouselContentType = {
   imageSrc: string;
   title?: string;
   object: any;
};
interface CarouselComponentProps {
   variableWidth?: boolean;
   vertical?: boolean;
   maxElements?: number;
   slidesToScroll?: number;
   infinite?: boolean;
   speed?: number;
   swipeable?: boolean;
   hideSlide?: boolean;
   dinamicWidth?: number;
   rows?: number;
   children: React.ReactNode;
}

const CarouselComponent = (props: CarouselComponentProps) => {
   const slider: any = useRef();
   const {
      variableWidth = false,
      vertical = false,
      maxElements = 3,
      infinite = false,
      slidesToScroll = 1,
      speed = 250,
      swipeable = false,
      hideSlide = false,
      rows = 1,
      children,
   } = props;

   const settings = {
      infinite: infinite,
      speed: speed,
      slidesToShow: maxElements,
      slideToScroll: slidesToScroll,
      vertical: vertical,
      swipe: swipeable,
   };

   return (
      <Box
         width={"100%"}
         sx={{
            display: "flex",
            flexDirection: vertical ? "column" : "row",
            gap: 1,
         }}
      >
         {/* {vertical && <Divider variant="middle" />} */}
         {!hideSlide && (
            <Button
               onClick={() => {
                  slider?.current?.slickPrev();
               }}
               sx={{ height: vertical ? 30 : "auto", minWidth: 0 }}
            >
               {vertical ? <KeyboardArrowUpIcon fontSize="large" /> : <ArrowBackIosNewIcon />}
            </Button>
         )}
         <Box
            flex={1}
            sx={{
               height: vertical ? "85%" : "auto",
               width: vertical ? "auto" : "85%",
            }}
         >
            <Slider
               ref={slider}
               centerMode={false}
               {...settings}
               focusOnSelect={false}
               rows={rows}
               nextArrow={<></>}
               prevArrow={<></>}
               centerPadding={"40px"}
               variableWidth={variableWidth}
            >
               {children}
            </Slider>
         </Box>
         {!hideSlide && (
            <Button
               onClick={() => {
                  slider?.current?.slickNext();
               }}
               sx={{ height: vertical ? 30 : "auto", minWidth: 0 }}
            >
               {vertical ? <KeyboardArrowDownIcon fontSize="large" /> : <ArrowForwardIosIcon />}
            </Button>
         )}
      </Box>
   );
};

export default CarouselComponent;
