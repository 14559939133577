import ChartDataLabels from "chartjs-plugin-datalabels";
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend,
   Filler,
   ArcElement,
} from "chart.js";
import { Pie } from "react-chartjs-2";
import { externalTooltipHandler } from "../../hooks/useCreateCharts";

ChartJS.register(
   ArcElement,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   ChartDataLabels,
   Title,
   Tooltip,
   Legend,
   Filler
);

export const PieChart = (props) => {
   const textCenter = () => {
      return {
         id: "textCenter",
         afterDraw(chart, args, pluginOptiona) {
            const { ctx } = chart;
            ctx.save();
            ctx.font = `bolder 18px sans-serif`;
            ctx.fillStyle = "#ffffff";
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            ctx.fillText(
               `${props.percentage.toFixed(2)}%`,
               chart.getDatasetMeta(0).data[0].x,
               chart.getDatasetMeta(0).data[0].y
            );
            ctx.restore();
         },
      };
   };
   return (
      <Pie
         ref={props.chartRef}
         data={{
            datasets: [
               {
                  label: "Mis datos",
                  data: props.scores,
                  backgroundColor: props.colors,
                  borderColor: props.colors ? props.colors : "#ffff",
                  hoverOffset: 3,
                  normalized: true,
               },
            ],
            labels: props.labels,
         }}
         plugins={props.textCenter ? [textCenter()] : []}
         options={{
            interaction: props.intersection ? { intersect: false, mode: "index" } : undefined,
            onClick: props.onClick,
            plugins: {
               datalabels: {
                  display: false,
                  textStrokeWidth: 1,
                  color: "white",
               },
               tooltip: {
                  enabled: !props.tooltip && !props.customTooltip,
                  position: props.customTooltip ? "nearest" : "average",
                  external: props.customTooltip
                     ? (context) => externalTooltipHandler(context, props.tooltipColorBox)
                     : undefined,
               },
               legend: { display: false },
            },

            responsive: true,
            maintainAspectRatio: false,
         }}
      />
   );
};
