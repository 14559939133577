import { Box, ButtonBase, Collapse, Stack, Tab, Tabs, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";
import { GovernanceTheSequelContext } from "../../../../context/governanceContext/governanceTheSequelContext";
import { useParams } from "react-router-dom";
import { UserContext } from "../../../../context/userContext";

export const FilterTabsComponent = ({ setFilterValue }) => {
   const { selectedGovernance, consultiveGroup, consultiveGroupCompanies } = useContext(GovernanceContext);
   const { myGovBody } = useContext(GovernanceTheSequelContext);
   const [filter, setFilter] = useState(null);
   const { id: governanceId, companyId } = useParams();
   const { user } = useContext(UserContext);
   const [checkedCompany, setCheckedCompany] = useState({ state: false, company: "" });

   const isConsultiveGroup =
      governanceId &&
      consultiveGroup?._id === companyId &&
      selectedGovernance.title !== "Asamblea de accionistas" &&
      selectedGovernance.company === consultiveGroup?._id;

   const isConsultiveGroupAndUserPanel =
      consultiveGroup?._id === companyId && selectedGovernance.title === "PANEL DE USUARIO";

   const availableFilters: any[] = useMemo(() => {
      if (!isConsultiveGroup) {
         let commitments = [{ title: "Mis actividades", _id: "1" }];
         for (const iterator of myGovBody) {
            if (iterator === "PANEL DE USUARIO") continue;
            const companyFound = consultiveGroupCompanies.find(
               (company) => company._id === iterator.bodiesData.company
            );
            commitments.push({ ...iterator.bodiesData, company: companyFound?.person_details?.comercialName });
         }
         return commitments;
      } else {
         let companyNames = consultiveGroupCompanies.map((c) => {
            return { title: c?.person_details?.comercialName, _id: c?._id };
         });

         if (selectedGovernance.title === "Consejo de administración" && consultiveGroup._id === companyId) {
            companyNames = companyNames.filter((c) => consultiveGroup.companies.includes(c._id));
         }
         return companyNames;
      }
   }, [selectedGovernance, consultiveGroupCompanies, myGovBody]);

   const groupedByCompany = useMemo(() => {
      return availableFilters.reduce((acc, item) => {
         const companyKey = item.company || consultiveGroup?.name;
         if (!acc[companyKey]) acc[companyKey] = [];
         if (item.title === "Mis actividades") return acc;
         acc[companyKey].push(item);
         return acc;
      }, {});
   }, [availableFilters, user]);

   useEffect(() => {
      let newValue;
      if (filter) newValue = filter;
      else if (isConsultiveGroup) newValue = availableFilters[0];
      else
         newValue =
            selectedGovernance.title === "PANEL DE USUARIO"
               ? "Mis actividades"
               : { title: selectedGovernance.title, _id: selectedGovernance._id };

      setFilterValue(newValue);
   }, [selectedGovernance, filter]);

   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "start",
            minHeight: 500,
         }}
      >
         {isConsultiveGroupAndUserPanel ? (
            <Stack spacing={0.5}>
               <ButtonBase onClick={() => setFilter(availableFilters[0])} sx={styles(filter, availableFilters[0])}>
                  <Typography>{availableFilters[0].title}</Typography>
               </ButtonBase>
               {Object.keys(groupedByCompany).map((company, i) => (
                  <Stack key={`company-box-${i}`} spacing={0.5}>
                     <ButtonBase
                        onClick={() => {
                           setCheckedCompany({
                              state: checkedCompany.company === company ? !checkedCompany.state : true,
                              company: company,
                           });
                        }}
                        sx={styles(company, "")}
                     >
                        <Typography sx={{ fontWeight: 700 }}>{company}</Typography>
                     </ButtonBase>
                     <Collapse in={checkedCompany.state && checkedCompany.company === company}>
                        <Stack spacing={0.5} sx={{ pl: 1 }}>
                           {groupedByCompany[company].map((e, index) => (
                              <ButtonBase
                                 key={`filter-box-${i}-${index}`}
                                 onClick={() => setFilter(e)}
                                 sx={styles(filter, e)}
                              >
                                 <Typography>{e?.title || e}</Typography>
                              </ButtonBase>
                           ))}
                        </Stack>
                     </Collapse>
                  </Stack>
               ))}
            </Stack>
         ) : (
            <Stack spacing={0.5}>
               {availableFilters.map((e, index) => (
                  <ButtonBase key={`filter-box-${index}`} onClick={() => setFilter(e)} sx={styles(filter, e)}>
                     <Typography>{e?.title || e}</Typography>
                  </ButtonBase>
               ))}
            </Stack>
         )}
      </Box>
   );
};

const styles = (filter, e) => {
   return {
      display: "flex",
      alignItems: "baseline",
      py: 1,
      px: filter === e ? 1 : 0,
      width: 250,
      justifyContent: "flex-start",
      borderLeft: filter === e ? "6px solid #3F5063" : "6px solid transparent",
      backgroundColor: filter === e ? "#D9D9D980" : "transparent",
      fontWeight: filter === e ? 700 : 500,
      textAlign: "left",
      "&:hover": {
         backgroundColor: "#D9D9D980",
      },
   };
};
