import { Box, Typography } from "@mui/material";

interface HeadersProps {
   children: string;
   colorSmall?: string;
   colorLarge?: string;
}

export const HeaderBlue = (props: HeadersProps) => {
   return (
      <>
         <Box
            sx={{
               display: "flex",
               alignItems: "center",
               width: "100%",
            }}
         >
            <Box
               sx={{
                  backgroundColor: props.colorSmall,
                  width: "8px",
                  height: "30px",
                  marginRight: "8px",
               }}
            ></Box>
            <Typography sx={{ fontWeight: "bold" }}>{props.children}</Typography>
         </Box>
         <Box
            sx={{
               backgroundColor: props.colorLarge,
               height: "4px",
               width: "100%",
               mt: 0.5,
            }}
         >
            &nbsp;
         </Box>
      </>
   );
};

export const HeaderCenter = (props: HeadersProps) => {
   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
            padding: "8px 0",
         }}
      >
         <Typography
            sx={{
               fontWeight: "bold",
            }}
         >
            {props.children}
         </Typography>
         <Box
            sx={{
               backgroundColor: props.colorLarge,
               height: "3px",
               width: "100%",
               marginTop: "3px",
            }}
         />
      </Box>
   );
};
