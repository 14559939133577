import { Box, IconButton, Modal, Stack, Typography } from "@mui/material";
import { SessionAvatar } from "./SessionAvatar";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import { useState } from "react";
import { SessionVerifyModal } from "../SessionVerifyModal";

export const SessionMemberToVerify = ({ member }) => {
   const [openModal, setOpenModal] = useState(false);
   const handleClose = () => {};

   return (
      <>
         <Box sx={{ display: "flex", alignItems: "center", p: 2, borderBottom: 1, borderColor: "#E9EDF5" }}>
            <Box sx={{ display: "flex", gap: 2, alignItems: "center", flex: 1 }}>
               <SessionAvatar
                  imgSrc={member.profilePic}
                  isOnline={true}
                  name={`${member.firstName} ${member.lastName}`}
                  noDecoration
               />
               <Stack>
                  <Typography sx={{ fontSize: 14 }}>{`${member.firstName} ${member.lastName}`}</Typography>
                  {/* <Typography sx={{ fontSize: 12, color: "#9E9E9E" }}>
                     {member.request !== "sign" ? member.memberCharge : ""}
                  </Typography> */}
                  {member.request === "sign" ? (
                     <Typography sx={{ fontSize: 12, color: "#9E9E9E" }}>
                        Este usuario requiere que se verifique su firma.
                     </Typography>
                  ) : (
                     <Typography sx={{ fontSize: 12, color: "#9E9E9E" }}>
                        Este usuario requiere que se verifique su INE.
                     </Typography>
                  )}
               </Stack>
            </Box>
            <IconButton onClick={() => setOpenModal(true)}>
               <InsertDriveFileIcon />
            </IconButton>
         </Box>
         <Modal
            open={openModal}
            onClose={handleClose}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
         >
            <SessionVerifyModal setOpenModal={setOpenModal} memberToVerify={member} isFromSign={member.request} />
         </Modal>
      </>
   );
};
