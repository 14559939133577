import { FirstPage, KeyboardArrowLeft, KeyboardArrowRight, LastPage, Sell, Today } from "@mui/icons-material";
import {
   Card,
   Divider,
   IconButton,
   Popover,
   Stack,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableFooter,
   TablePagination,
   TableRow,
   Typography,
   useTheme,
} from "@mui/material";
import { Box } from "@mui/system";
import { useContext, useState } from "react";
import StringToHTMLOutput from "../../Inputs/TextEditor/StringToHTMLOutput";
import { getDateFromISODate } from "../../../const/globalConst";
import { UserContext } from "../../../context/userContext";

interface TablePaginationActionsProps {
   count: number;
   page: number;
   rowsPerPage: number;
   onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
   const theme = useTheme();
   const { count, page, rowsPerPage, onPageChange } = props;

   const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page - 1);
   };

   const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      onPageChange(event, page + 1);
   };

   return (
      <Box sx={{ flexShrink: 0, ml: 2.5 }}>
         <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label="previous page">
            {theme.direction === "rtl" ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
         </IconButton>
         <IconButton
            onClick={handleNextButtonClick}
            disabled={page >= Math.ceil(count / rowsPerPage) - 1}
            aria-label="next page"
         >
            {theme.direction === "rtl" ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
         </IconButton>
      </Box>
   );
}

interface TableNotesComponentProps {
   rows: any;
   setOpenModal?: Function;
   rowData?: Function;
}

export const TableNotesComponent = (props: TableNotesComponentProps) => {
   const [anchorEl, setAnchorEl] = useState(null);
   const [isHovered, setIsHovered] = useState(null);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(2);
   const { user } = useContext(UserContext);

   const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };

   const handlePopoverClose = () => {
      setIsHovered(null);
      setAnchorEl(null);
   };
   return (
      <>
         <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <TableContainer sx={{ bgcolor: "transparent", width: "100%" }}>
               <Table>
                  <TableBody>
                     {(rowsPerPage > 0
                        ? props.rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        : props.rows
                     ).map((row, index) => (
                        <TableRow key={index} sx={{ display: "flex" }}>
                           {row.map((e, i) => (
                              <TableCell key={i} sx={{ borderBottom: "none", px: 2, py: 1 }}>
                                 <Card
                                    key={i}
                                    sx={{
                                       borderRadius: 2,
                                       width: 245,
                                       height: 180,
                                       p: 2,
                                       cursor: "pointer",
                                       display: "flex",
                                       flexDirection: "column",
                                       justifyContent: "space-between",
                                    }}
                                    onClick={() => {
                                       props.setOpenModal(1);
                                       props.rowData(e);
                                    }}
                                 >
                                    {e.tags && e.tags.length > 0 && (
                                       <Typography fontStyle={"normal"} fontWeight={"bold"} color={e.tags[0].color}>
                                          <Sell sx={{ fontSize: 15, mr: 1 }} />
                                          {e.tags[0].title}
                                          {e.tags.length - 1 > 0 && (
                                             <IconButton
                                                sx={{ mx: 1, p: 0 }}
                                                onMouseEnter={() => setIsHovered(`${e.tags}`)}
                                             >
                                                <Box
                                                   sx={{ display: "flex", borderRadius: 5, px: 1 }}
                                                   aria-owns={isHovered === `${e.tags}` ? `$${e.tags}` : undefined}
                                                   aria-haspopup="true"
                                                   onMouseEnter={handlePopoverOpen}
                                                   onMouseLeave={handlePopoverClose}
                                                >
                                                   <Typography fontSize={15} fontWeight={"bold"} color={"#3F5063"}>{`+${
                                                      e.tags.length - 1
                                                   }`}</Typography>
                                                   <Popover
                                                      open={isHovered === `${e.tags}`}
                                                      anchorEl={anchorEl}
                                                      sx={{
                                                         pointerEvents: "none",
                                                         boxShadow: "none",
                                                      }}
                                                      anchorOrigin={{
                                                         vertical: "bottom",
                                                         horizontal: "left",
                                                      }}
                                                      transformOrigin={{
                                                         vertical: "top",
                                                         horizontal: "left",
                                                      }}
                                                      onClose={handlePopoverClose}
                                                      disableRestoreFocus
                                                   >
                                                      {e.tags.slice(1).map((c, i) => (
                                                         <Typography key={i} m={1} fontSize={"14px"} color={c.color}>
                                                            {c.title}
                                                         </Typography>
                                                      ))}
                                                   </Popover>
                                                </Box>
                                             </IconButton>
                                          )}
                                       </Typography>
                                    )}
                                    <Box overflow={"hidden"} sx={{ height: 80 }}>
                                       <Typography variant="subtitle1" fontWeight={"bold"} noWrap>
                                          {e.title}
                                       </Typography>
                                       <StringToHTMLOutput
                                          text={e.description}
                                          sx={{
                                             fontSize: 14,
                                             p: 0,
                                             py: 1,
                                             textAlign: "justify",
                                             overflow: "hidden",
                                             pointerEvents: "none",
                                          }}
                                       />
                                    </Box>
                                    <Stack spacing={1}>
                                       <Divider sx={{ border: 1, color: "lightgray" }} />
                                       <Box
                                          sx={{
                                             display: "flex",
                                             alignItems: "center",
                                             justifyContent: "space-between",
                                          }}
                                       >
                                          <Box
                                             sx={{
                                                display: "flex",
                                                alignItems: "center",
                                                columnGap: 1,
                                             }}
                                          >
                                             <Today sx={{ fontSize: 20 }} />
                                             <Typography fontSize={12}>{getDateFromISODate(e.createdAt)}</Typography>
                                          </Box>
                                          {e.owner !== user.id && <Typography fontSize={12}>Compartida</Typography>}
                                       </Box>
                                    </Stack>
                                 </Card>
                              </TableCell>
                           ))}
                        </TableRow>
                     ))}
                  </TableBody>
                  <TableFooter>
                     <TableRow>
                        <TablePagination
                           rowsPerPageOptions={[2, 4, 6, { label: "Todas", value: -1 }]}
                           count={props.rows.length}
                           rowsPerPage={rowsPerPage}
                           page={page}
                           onPageChange={handleChangePage}
                           onRowsPerPageChange={handleChangeRowsPerPage}
                           ActionsComponent={TablePaginationActions}
                           sx={{ borderBottom: 0 }}
                        />
                     </TableRow>
                  </TableFooter>
               </Table>
            </TableContainer>
         </Box>
      </>
   );
};
