import { Box, CircularProgress, Container } from "@mui/material";
import { NotificationBar } from "../../components/notificationsBar";
import { CompletedComplaintsTable } from "../../containers/completedComplaintsTable";
import VerifyUserSession from "../../hooks/verifyUserSession";
import { useContext } from "react";
import { ComplaintContext } from "../../context/complaintContext";

export const CompletedComplaintsScreen = () => {
   VerifyUserSession();
   const { loadingComplaints } = useContext(ComplaintContext);
   return (
      <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
         <NotificationBar head="Denuncias finalizadas" content={<></>} />
         <Container maxWidth="xl">
            {!loadingComplaints ? (
               <Box sx={{ m: 1 }}>
                  <CompletedComplaintsTable />
               </Box>
            ) : (
               <Box display={"flex"} width={"100%"} justifyContent={"center"} py={2}>
                  <CircularProgress size={26} />
               </Box>
            )}
         </Container>
      </Box>
   );
};
