import { Box } from "@mui/material";
import { PieChart } from "../../../../charts/pieChart";
import { GraphLabels } from "../GraphLabels";

export const CompromiseGraph = ({ answers }) => {
   const value1 = answers.filter((answer) => answer.rangeAnswer === 1 || answer.rangeAnswer === 2).length;
   const value2 = answers.filter((answer) => answer.rangeAnswer === 3 || answer.rangeAnswer === 4).length;
   const value3 = answers.filter((answer) => answer.rangeAnswer === 5 || answer.rangeAnswer === 6).length;
   const total = value1 + value2 + value3;
   return (
      <>
         <Box
            sx={{
               display: "flex",
               justifyContent: "center",
               p: 2,
            }}
         >
            <Box
               sx={{
                  position: "relative",
               }}
            >
               <Box
                  sx={{
                     width: 160,
                     height: 160,
                     border: 1,
                     borderWidth: 4,
                     borderColor: "white",
                     borderRadius: 100,
                     ml: -8,
                  }}
               >
                  <PieChart
                     scores={[value1 === 0 ? 1 : 0, value1]}
                     labels={["Ausencias", "Asistencia"]}
                     colors={["#8a95a0", "#868FA0"]}
                     chartRef={undefined}
                     cutout={0}
                     percentage={(value1 / total) * 100}
                     textCenter
                     tooltip={true}
                  />
               </Box>
               <Box sx={{ position: "absolute", bottom: "40%", left: "55%" }}>
                  <Box
                     sx={{
                        width: 120,
                        height: 120,
                        border: 1,
                        borderColor: "white",
                        borderRadius: 50,
                        borderWidth: 4,
                     }}
                  >
                     <PieChart
                        scores={[value2 === 0 ? 1 : 0, value2]}
                        labels={["Ausencias", "Asistencia"]}
                        colors={["#162c44", "#162c44"]}
                        chartRef={undefined}
                        cutout={0}
                        percentage={(value2 / total) * 100}
                        textCenter
                        tooltip={true}
                     />
                  </Box>
               </Box>
               <Box sx={{ position: "absolute", top: "45%", bottom: "0%", left: "50%" }}>
                  <Box
                     sx={{
                        width: 95,
                        height: 95,
                        border: 1,
                        borderColor: "white",
                        borderRadius: 100,
                        borderWidth: 4,
                     }}
                  >
                     <PieChart
                        scores={[value3 === 0 ? 1 : 0, value3]}
                        labels={["Ausencias", "Asistencia"]}
                        colors={["#A3A3A3", "#A3A3A3"]}
                        chartRef={undefined}
                        cutout={0}
                        percentage={(value3 / total) * 100}
                        textCenter
                        tooltip={true}
                     />
                  </Box>
               </Box>
            </Box>
         </Box>
         <GraphLabels />
      </>
   );
};
