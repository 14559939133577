import {
   Box,
   Button,
   CircularProgress,
   Collapse,
   Container,
   FormControl,
   FormControlLabel,
   FormHelperText,
   Grid,
   Radio,
   RadioGroup,
   Stack,
   Typography,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { SystemThemeConfig } from "../../config/systemTheme.config";
import { useParams } from "react-router-dom";
import {
   createFileAsync,
   createMultipleFileAsync,
   createMultipleFolderBC,
   finishRequestChangesBeneficiary,
   getBeneficiaryById,
   getCompanyById,
   getFoldersByIdsExpedient,
   getRequestChangesBeneficiaryById,
   getUrlS3,
   getUserByRoleAndEntity,
   getYearlyParentFoldersByIds,
   updateBeneficiary,
   updateBeneficiaryDetails,
} from "../../lib/usersBEClient";
import { AttachFile, Check } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { FileSelectedCollapse } from "../../components/MiLecosy/ModalsGovernanceModule/ControllingBeneficiaryModal";
import { uploadFileToS3 } from "../../lib/s3Client";
import { SnackBarContext } from "../../context/snackBarContext";
import { pdf } from "@react-pdf/renderer";
import axios from "axios";
import { InputTextField } from "../../components/Inputs/InputTextField";
import { beneficiaryCreateSchema2 } from "../../lib/validations/GobCop/inputSchemas";
import { beneficiaryUpdatedTemplate } from "../../lib/documentsTemplates/beneficiary/beneficiaryUpdatedTemplate";
import { shareHolderUpdatedTemplate } from "../../lib/documentsTemplates/beneficiary/shareHolderUpdatedTemplate";

export const BeneficiaryControllerExternal = () => {
   const [beneficiaryInfo, setBeneficiaryInfo] = useState(null);
   const [requestChanges, setRequestChanges] = useState(null);
   const [isLoadingInfo, setIsLoadingInfo] = useState(true);
   const [initialValues, setInitialValues] = useState(null);
   const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
   const { beneficiaryId, requestChangesId, action } = useParams();
   const [finished, setFinished] = useState(false);
   const { showSnackBar } = useContext(SnackBarContext);
   const [companies, setCompanies] = useState([]);

   const fetchBeneficiaryInfo = useCallback(async () => {
      if (!beneficiaryId || beneficiaryInfo) return;
      setIsLoadingInfo(true);
      const response = await getBeneficiaryById(beneficiaryId);
      if (response.token) {
         axios.defaults.headers.token = response.token;
      }
      const response2 = await getRequestChangesBeneficiaryById(requestChangesId);
      if (response2) {
         const beneficiary = response2.beneficiary.find((benef) => benef.beneficiary === beneficiaryId);
         if (beneficiary.finished) {
            setFinished(true);
            setIsLoadingInfo(false);
            return;
         }
      }
      const companiesInfo = await Promise.all(
         response.beneficiary.companyId.map(async (company) => await getCompanyById(company))
      );
      setCompanies(companiesInfo);
      handleInitialValues(response2.files);
      setRequestChanges(response2);
      setBeneficiaryInfo(response);
      setIsLoadingInfo(false);
   }, []);

   useEffect(() => {
      fetchBeneficiaryInfo();
   }, [fetchBeneficiaryInfo]);

   // const handleWithoutModification = async (requestChangesId, files, beneficiary) => {
   //    await updateNoChangesBeneficiaryFilesByName(beneficiaryId, files);
   //    const logoUrl = await getUrlS3("images-lecosy", { folder: beneficiary.companyId }, "logo.png");
   //    const presidentes = await getUserByRoleAndEntity("651b06f1f721833c5e8724d9", beneficiary.companyId);
   //    const folderControl = await getFoldersByIdsExpedient([beneficiary.folderId]);
   //    const date = new Date();
   //    const blob = await pdf(
   //       InformacionSinModificacion(
   //          logoUrl,
   //          beneficiary.name,
   //          presidentes[0].user.firstName + " " + presidentes[0].user.lastName,
   //          "Hermosillo",
   //          "Sonora"
   //       )
   //    ).toBlob();
   //    await uploadFile(
   //       blob,
   //       `Acuse de información sin modificación - ${date.toLocaleDateString("es-MX", {
   //          day: "2-digit",
   //          month: "long",
   //          year: "numeric",
   //       })}.pdf`,
   //       null,
   //       beneficiary.companyId,
   //       folderControl[0]
   //    );
   //    await finishRequestChangesBeneficiary(requestChangesId, beneficiaryId);
   //    setFinished(true);
   // };

   const handleInitialValues = (files) => {
      let init = {};
      for (const element of files) {
         init[element] = null;
      }
      // if (action === "new") {
      //    init["alias_value"] = "";
      //    init["alias"] = "";
      //    init["place"] = "";
      //    init["relation"] = "";
      //    init["participation"] = "";
      // }
      setInitialValues(init);
   };

   const uploadFile = async (file, fileName, user, companyId, folder) => {
      try {
         const responseFile = await createFileAsync({
            name: fileName,
            owner: user,
            size: file.size,
            type: file.type,
            folder: folder,
            fileDirection: `beneficiaries/${companyId}/${folder._id}`,
         });
         await uploadFileToS3(responseFile.urlToUpload, file);
         return responseFile.createFile;
      } catch (error) {
         showSnackBar("Error al subir archivo", false);
      }
   };

   const handleSubmitChanges = async (values) => {
      setIsLoadingSubmit(true);
      let newObjectFilesOnly = {};
      // let newObject = {};
      const headersOnlyFiles = Object.keys(initialValues).filter((key) => typeof initialValues[key] !== "string");
      // const headersOnlyStrings = Object.keys(initialValues).filter((key) => typeof initialValues[key] === "string");
      // for (const key of headersOnlyStrings) {
      //    newObject[key] = values[key];
      // }
      for (const key of headersOnlyFiles) {
         newObjectFilesOnly[key] = values[key];
      }
      if (action === "new") {
         const responseInfo = await handleAddInfoNew(values);
         if (responseInfo === "error") {
            showSnackBar("Error al procesar información, vuelva a intentar.", true);
            setIsLoadingSubmit(false);
            return;
         }
      }
      await handleCreateFiles(newObjectFilesOnly);
      setIsLoadingSubmit(false);
   };

   const handleAddInfoNew = async (values) => {
      try {
         const beneficiaryDetails = await Promise.all(
            companies.map((company) =>
               updateBeneficiaryDetails(beneficiaryId, company.data._id, {
                  informationLegalRepresentative: {
                     alias: values[`${company.data._id}-alias_value`],
                  },
                  relationMoralPerson: {
                     relationWithPerson: values[`${company.data._id}-relation`],
                     porcentajeParticipation: values[`${company.data._id}-participation`],
                     placeDeposited: values[`${company.data._id}-place`],
                  },
                  company: company.data._id,
               })
            )
         );
         return "ok";
      } catch (error) {
         return "error";
      }
   };

   const handleCreateFiles = async (filesToUpload) => {
      try {
         const beneficiary = beneficiaryInfo.beneficiary;
         const filesKey = Object.keys(filesToUpload);

         const ids = [];
         for (const element of filesKey) {
            const folder = beneficiary.files.find((file) => file.fileName === element);
            if (!folder) {
               const { main } = await createMultipleFolderBC({
                  folders: [{ name: element, children: [], fileNumber: 0 }],
                  parent: beneficiary.folderId,
               });
               const files = [...beneficiary.files, { fileName: element, folderId: main[0] }];
               await updateBeneficiary(beneficiary._id, files);
               ids.push(main[0]);
            } else ids.push(folder.folderId);
         }
         const yearlyFolders = await getYearlyParentFoldersByIds(ids);

         const date = new Date();
         const files = await createMultipleFileAsync(
            filesKey.map((key, index) => {
               return {
                  name: `${key.includes("/") ? key.replace("/", "|") : key} - ${date.toLocaleDateString("es-MX", {
                     day: "2-digit",
                     month: "long",
                     year: "numeric",
                     hour: "2-digit",
                     minute: "2-digit",
                  })}.pdf`,
                  owner: null,
                  size: filesToUpload[key].size,
                  type: filesToUpload[key].type,
                  folder: yearlyFolders[index],
                  beneficiary: {
                     needUpdate: false,
                     verified: undefined,
                  },
                  fileDirection: `beneficiaries/${beneficiary.companyId[0]}/${beneficiary.folderId}`,
               };
            })
         );

         const logoUrl = await Promise.all(
            beneficiary.companyId.map(
               async (company) => await getUrlS3("images-lecosy", { folder: company }, "logo.png")
            )
         );

         const filesUploaded = await Promise.all(
            filesKey.map((element, index) => uploadFileToS3(files[index].urlToUpload, filesToUpload[element]))
         );

         const companyFound = await Promise.all(
            beneficiary.companyId.map(async (company) => await getCompanyById(company))
         );

         const folderControl = await Promise.all(
            beneficiary.companyId.map(async (company) => await getFoldersByIdsExpedient(beneficiary.folderId, company))
         );

         const presidentes = await Promise.all(
            beneficiary.companyId.map(
               async (company) => await getUserByRoleAndEntity("651b06f1f721833c5e8724d9", company)
            )
         );

         const template =
            beneficiary.legalPerson === "Accionista" ? shareHolderUpdatedTemplate : beneficiaryUpdatedTemplate;
         // const fullName = `${presidentes[0].user.firstName} ${presidentes[0].user.lastName}`;
         const fileKeys = Object.keys(filesToUpload);

         const blobs = await Promise.all(
            beneficiary.companyId.map((company, index) => {
               const fullName = `${presidentes[index][0].user.firstName} ${presidentes[index][0].user.lastName}`;
               return pdf(
                  template(
                     logoUrl[index],
                     companyFound[index].data.company_details.primaryColor,
                     beneficiary.name,
                     fullName,
                     "Hermosillo",
                     "Sonora",
                     fileKeys
                  )
               ).toBlob();
            })
         );

         const uploadedFiles = await Promise.all(
            beneficiary.companyId.map((company, index) => {
               return uploadFile(
                  blobs[index],
                  `Acuse de actualización de información - ${date.toLocaleDateString("es-MX", {
                     day: "2-digit",
                     month: "long",
                     year: "numeric",
                     hour: "2-digit",
                     minute: "2-digit",
                  })}.pdf`,
                  null,
                  beneficiary.companyId[0],
                  { _id: folderControl[index] }
               );
            })
         );

         await finishRequestChangesBeneficiary(requestChanges._id, beneficiary._id);
         setFinished(true);
         showSnackBar("Documentos enviados correctamente!", false);
      } catch (error) {
         console.log(error);
         showSnackBar("Error al subir archivos", true);
      }
   };

   if (action === "sin-modificacion") {
      return (
         <SystemThemeConfig>
            <Container
               maxWidth={"xl"}
               sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                  bgcolor: "whitesmoke",
                  flexWrap: "wrap",
               }}
            >
               {isLoadingInfo ? (
                  <CircularProgress />
               ) : finished ? (
                  <Stack
                     sx={{ bgcolor: "white", borderRadius: 2, justifyContent: "center", alignItems: "center", p: 2 }}
                  >
                     <Check color="success" sx={{ fontSize: 70 }} />
                     <Typography variant="h6">La documentación se encuentra actualizada. ¡Gracias!</Typography>
                  </Stack>
               ) : null}
            </Container>
         </SystemThemeConfig>
      );
   }

   if (action === "actualizar" || !action || action === "new") {
      return (
         <SystemThemeConfig>
            <Container
               maxWidth={"xl"}
               sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100vh",
                  bgcolor: "whitesmoke",
                  flexWrap: "wrap",
               }}
            >
               {isLoadingInfo ? (
                  <CircularProgress />
               ) : finished ? (
                  <Stack
                     sx={{ bgcolor: "white", borderRadius: 2, justifyContent: "center", alignItems: "center", p: 2 }}
                  >
                     <Check color="success" sx={{ fontSize: 70 }} />
                     <Typography variant="h6">Su documentación ya fue enviada. ¡Gracias!</Typography>
                  </Stack>
               ) : (
                  <Formik
                     initialValues={initialValues}
                     onSubmit={handleSubmitChanges}
                     validationSchema={() =>
                        beneficiaryCreateSchema2(
                           Object.keys(initialValues).filter((key) => typeof initialValues[key] !== "string"),
                           action === "new"
                        )
                     }
                  >
                     {({ setFieldValue, values, errors }) => (
                        <Form>
                           <Stack>
                              <Stack sx={{ bgcolor: "white", borderRadius: 2 }}>
                                 <Stack
                                    sx={{ bgcolor: "#162c44", p: 3, borderTopLeftRadius: 4, borderTopRightRadius: 4 }}
                                 >
                                    <Typography sx={{ color: "white" }} variant="h6">
                                       Registre la siguiente información beneficiario controlador
                                    </Typography>
                                 </Stack>

                                 <Stack sx={{ rowGap: 1, p: 4, maxHeight: 500, overflowY: "auto" }}>
                                    <Typography>Documentación</Typography>
                                    {requestChanges.files.map((file, index) => (
                                       <Grid item xs={12} key={index}>
                                          <Box
                                             sx={{
                                                borderColor: !!errors[file] ? "red" : "#D9D9D9",
                                                borderWidth: 1,
                                                borderStyle: "solid",
                                                padding: 1,
                                                marginTop: 2,
                                                borderRadius: 1,
                                                width: "100%",
                                             }}
                                          >
                                             <Grid container alignItems="center" justifyContent="space-between">
                                                <Grid item xs={6}>
                                                   <Typography>{file}</Typography>
                                                </Grid>
                                                <Grid
                                                   item
                                                   xs={6}
                                                   container
                                                   alignItems="center"
                                                   justifyContent="flex-end"
                                                >
                                                   <Button
                                                      variant="contained"
                                                      size="small"
                                                      endIcon={<AttachFile sx={{ color: "#707070" }} />}
                                                      sx={{
                                                         backgroundColor: "#F4F4F4",
                                                         borderRadius: "20px",
                                                         textTransform: "none",
                                                         color: "#707070",
                                                         paddingLeft: "12px",
                                                         paddingRight: "12px",
                                                         "&:hover": {
                                                            backgroundColor: "#E0E0E0",
                                                         },
                                                      }}
                                                      onClick={() =>
                                                         document.getElementById(`file-input-${index}`).click()
                                                      } // Trigger the file input
                                                   >
                                                      Subir archivo
                                                   </Button>
                                                   <input
                                                      type="file"
                                                      id={`file-input-${index}`}
                                                      hidden
                                                      onChange={(event) => {
                                                         const fileAd = event.currentTarget.files[0];
                                                         if (fileAd) {
                                                            if (fileAd.type !== "application/pdf") {
                                                               alert("Solo se permiten archivos PDF.");
                                                               return;
                                                            }
                                                            if (fileAd.size > 50 * 1024 * 1024) {
                                                               alert("El archivo debe ser menor de 50 MB.");
                                                               return;
                                                            }
                                                            setFieldValue(file, fileAd);
                                                         }
                                                      }}
                                                   />
                                                </Grid>
                                             </Grid>
                                          </Box>
                                          {errors[file] && (
                                             <FormHelperText error={!!errors[file]}>
                                                {errors[file].toString()}
                                             </FormHelperText>
                                          )}

                                          <Stack>
                                             <FileSelectedCollapse
                                                file={values[file]}
                                                onClick={() => {
                                                   setFieldValue(file, null);
                                                }}
                                             />
                                          </Stack>
                                       </Grid>
                                    ))}
                                    <Stack>
                                       {action === "new"
                                          ? beneficiaryInfo.beneficiary.companyId.map((companyM) => {
                                               const company = companies.find(
                                                  (companyF) => companyF.data._id === companyM
                                               );
                                               return (
                                                  <NewExternBeneficiary
                                                     setFieldValue={setFieldValue}
                                                     values={values}
                                                     companyId={companyM}
                                                     key={companyM}
                                                     companyName={company.data?.person_details?.businessName}
                                                     shareHolder={
                                                        beneficiaryInfo.beneficiary.legalPerson === "Accionista"
                                                     }
                                                  />
                                               );
                                            })
                                          : null}
                                    </Stack>
                                 </Stack>
                              </Stack>
                              <Stack direction={"row"} sx={{ justifyContent: "space-between", mt: 2, mx: 2 }}>
                                 <Button variant="contained" color="secondary">
                                    Cancelar
                                 </Button>
                                 <Button variant="contained" type="submit" disabled={isLoadingSubmit}>
                                    {isLoadingSubmit ? (
                                       <CircularProgress size={25} sx={{ color: "white" }} />
                                    ) : (
                                       "Guardar"
                                    )}
                                 </Button>
                              </Stack>
                           </Stack>
                        </Form>
                     )}
                  </Formik>
               )}
            </Container>
         </SystemThemeConfig>
      );
   }
};

export const NewExternBeneficiary = ({ setFieldValue, values, companyId, companyName, shareHolder }) => {
   return (
      <>
         <Stack sx={{ my: 1, rowGap: 1 }}>
            <Typography fontWeight={600}>{companyName}</Typography>
         </Stack>
         <Stack sx={{ my: 1, rowGap: 1 }}>
            <Typography>¿Qué relación ostenta con la persona moral?</Typography>
            <InputTextField id={companyId + "-relation"} name={companyId + "-relation"} type="text" size={"small"} />
         </Stack>
         <Stack sx={{ my: 1, rowGap: 1 }}>
            <Typography>¿Tiene algún alias?</Typography>
            <FormControl>
               <RadioGroup
                  defaultValue="No"
                  name={companyId + "-alias"}
                  id={companyId + "-alias"}
                  value={values[companyId + "-alias"]}
                  onChange={(_ev, value) => {
                     if (value === "No") {
                        setFieldValue(companyId + "-alias_value", "");
                     }
                     setFieldValue(companyId + "-alias", value);
                  }}
               >
                  <FormControlLabel value="Sí" control={<Radio />} label="Sí" />
                  <FormControlLabel value="No" control={<Radio />} label="No" />
               </RadioGroup>
            </FormControl>
            <Collapse in={values[companyId + "-alias"] === "Sí"} unmountOnExit>
               <Stack sx={{ my: 1, rowGap: 1 }}>
                  <Typography>¿Cual es su alias?</Typography>
                  <InputTextField
                     id={companyId + "-alias_value"}
                     name={companyId + "-alias_value"}
                     type="text"
                     size={"small"}
                  />
               </Stack>
            </Collapse>
            {shareHolder && (
               <Stack direction={"row"} sx={{ my: 1, columnGap: 2, alignItems: "center" }}>
                  <Typography>¿Cual es su grado de participación en la persona moral?</Typography>
                  <InputTextField
                     id={companyId + "-participation"}
                     name={companyId + "-participation"}
                     type="text"
                     size={"small"}
                  />
               </Stack>
            )}

            {shareHolder && (
               <Stack sx={{ my: 1, rowGap: 1 }}>
                  <Typography>
                     ¿Lugar donde las acciones o partes sociales se encuentran depositadas o en custodia?
                  </Typography>
                  <InputTextField id={companyId + "-place"} name={companyId + "-place"} type="text" size={"small"} />
               </Stack>
            )}
         </Stack>
      </>
   );
};
