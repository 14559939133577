import { Box, Typography } from "@mui/material";
import ListComponent from "../../../ListComponent";
import { GovernanceSessionContext } from "../../../../context/governanceContext/governanceSessionContext";
import { useContext, useMemo } from "react";
import { UserContext } from "../../../../context/userContext";

const PendingVotesModal = ({ setSelectedPendingVotation, setOpenVotationModal }) => {
   const { user } = useContext(UserContext);
   const { pendingVotes, additionalVotesSeed } = useContext(GovernanceSessionContext);

   const myPendingVotes = useMemo(() => {
      return pendingVotes.filter((vote) =>
         vote.votes.some((userVote) => userVote.userId === user.id && userVote.vote === "pending")
      );
   }, [pendingVotes.length, additionalVotesSeed]);

   return myPendingVotes.length > 0 ? (
      <ListComponent
         headers={[
            {
               headerTitle: "Selecciona una votación",
               type: "text",
               bodyPosition: "left",
               headerPosition: "left",
            },
         ]}
         rows={myPendingVotes.map((votes) => {
            return {
               title: votes.title,
            };
         })}
         headerProps={{
            pb: 1,
            px: 2,
            borderRadius: 1,
         }}
         headerTextProps={{
            fontSize: "16px",
            fontWeight: 600,
         }}
         rowProps={function () {
            return {
               bgcolor: "#F5F5F5",
               ":hover": {
                  bgcolor: "#CBD5E0",
               },
               py: 1,
               px: 2,
               mb: 1,
            };
         }}
         onClick={(_e, row) => {
            setSelectedPendingVotation(myPendingVotes.find((vote) => vote.title === row.title));
            setOpenVotationModal(true);
         }}
      />
   ) : (
      <Box width={"100%"} height={"250px"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
         <Typography>No hay votaciones pendientes</Typography>
      </Box>
   );
};

export default PendingVotesModal;
