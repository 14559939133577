import { useContext } from "react";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import { Box, Tooltip } from "@mui/material";
import { ProgressBar } from "./ProgressBar";
import { EvaluationContext } from "../../../../context/governanceContext/evaluationContext";

export const StepperProgressEvaluation = ({ steps }) => {
   const { activePersonEvaluation, dotMembersByCouncil, progressMembers, userData } = useContext(EvaluationContext);
   return (
      <Box sx={{ display: "flex" }}>
         <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
            {dotMembersByCouncil &&
               dotMembersByCouncil.map((member, index) => {
                  if (index === 0) {
                     return (
                        <Box sx={{ width: activePersonEvaluation ? "50%" : "100%" }}>
                           <ProgressBar progress={progressMembers[0] * 100} />
                        </Box>
                     );
                  }

                  return (
                     <>
                        <Tooltip
                           title={
                              userData.find((user) => user._id === member.user)?.firstName +
                              " " +
                              userData.find((user) => user._id === member.user)?.lastName
                           }
                        >
                           <FiberManualRecordIcon sx={{ fontSize: 10 }} />
                        </Tooltip>
                        <Box
                           sx={{
                              width:
                                 activePersonEvaluation && activePersonEvaluation.user === member.user ? "100%" : "50%",
                           }}
                        >
                           <ProgressBar progress={progressMembers[index] * 100} />
                        </Box>
                     </>
                  );
               })}
         </Box>
      </Box>
   );
};
