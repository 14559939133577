import { FiberManualRecord } from "@mui/icons-material";
import { Box, Divider, Grid, Typography } from "@mui/material";

interface IRecentEventComponentProps {
   date: string;
   color: string;
   title: string;
   location?: string;
}

export const RecentEventComponent = (props: IRecentEventComponentProps) => {
   return (
      <Box sx={{ mb: 1 }}>
         <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
            <FiberManualRecord sx={{ color: props.color, fontSize: 20 }} />
            <Typography variant="body1" fontWeight={600}>
               {props.title}
            </Typography>
         </Box>
         <Box sx={{ display: "flex", alignItems: "center", columnGap: 2, mx: 1 }}>
            <Divider orientation="vertical" flexItem sx={{ borderRightWidth: 3 }} />
            <Typography variant="caption" color="gray">
               {props.date}
            </Typography>
         </Box>
      </Box>
   );
};
