import { ArrowDropDown } from "@mui/icons-material";
import { Box, Button, FormHelperText, Popover, Stack, Typography } from "@mui/material";
import { useField } from "formik";
import React from "react";

export const AffairSelector = ({ options, inputProps }) => {
   const [_field, meta] = useField(inputProps);
   const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

   const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const open = Boolean(anchorEl);
   const id = open ? "simple-popover" : undefined;
   return (
      <>
         <Button
            sx={{
               border: 1,
               borderRadius: 1,
               display: "flex",
               p: 1,
               justifyContent: "space-between",
               borderColor: meta.error && meta.touched ? "red" : "#D0D0D0",
               cursor: "pointer",
               bgcolor: "white",
            }}
            onClick={handleClick}
         >
            <Typography sx={{ color: "gray" }}>Seleccionar asunto</Typography>
            <ArrowDropDown />
         </Button>
         <FormHelperText error={meta.error && meta.touched ? true : false}>
            {meta.error && meta.touched ? meta.error : null}
         </FormHelperText>
         <Popover
            id={id}
            open={open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
               vertical: "bottom",
               horizontal: "left",
            }}
         >
            <Stack
               sx={{
                  width: 600,
                  maxHeight: 200,
                  overflowY: "auto",
               }}
            >
               {options.map((opt) => opt)}
            </Stack>
         </Popover>
      </>
   );
};
