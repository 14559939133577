import { FileOpen, Person, School, Work } from "@mui/icons-material";
import { Box, Button, CircularProgress, Tab, Tabs, Tooltip } from "@mui/material";
import { useEffect, useState } from "react";
import { PersonalInfo } from "./MembersTabs/PersonalInfo";
import { StudiesData } from "./MembersTabs/StudiesData";
import { WorkData } from "./MembersTabs/WorkData";
import { Documentation } from "./MembersTabs/Documentation";
import { getGovernTitlesByUser, getSelectedMemberData } from "../../../lib/gobCorpBEClient";

interface MemberModalProps {
   state: boolean;
   setState: Function;
   dataId: string;
   memberData: any;
}

interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

function TabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`full-width-tabpanel-${index}`}
         aria-labelledby={`full-width-tab-${index}`}
         {...other}
      >
         {value === index && <Box>{children}</Box>}
      </div>
   );
}

export const MemberModal = (props: MemberModalProps) => {
   const [value, setValue] = useState(0);
   const [isloading, setIsloading] = useState(false);
   const [memberData, setMemberData] = useState();
   const [governTitles, setGovernTitles] = useState([]);

   useEffect(() => {
      const getUserData = async () => {
         setIsloading(true);
         const selectedUserData = await getSelectedMemberData(props.dataId);
         setMemberData(selectedUserData);
         const governTitles = await getGovernTitlesByUser(props.dataId);
         setGovernTitles(governTitles);
         setIsloading(false);
      };

      getUserData();
   }, [props.dataId]);

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   return (
      <>
         <Box sx={{ width: 700 }}>
            <Tabs
               value={value}
               onChange={handleChange}
               indicatorColor="primary"
               variant="fullWidth"
               sx={{ bgcolor: "#FFFFFF", width: "100%", borderBottom: 1, borderColor: "#F3F3F3" }}
            >
               <Tooltip title="Información perfil">
                  <Tab icon={<Person sx={{ fontSize: 35 }} />} />
               </Tooltip>
               <Tooltip title="Estudios">
                  <Tab icon={<School sx={{ fontSize: 35 }} />} />
               </Tooltip>
               <Tooltip title="Experiencia laboral">
                  <Tab icon={<Work sx={{ fontSize: 35 }} />} />
               </Tooltip>
               <Tooltip title="Documentación">
                  <Tab icon={<FileOpen sx={{ fontSize: 35 }} />} />
               </Tooltip>
            </Tabs>

            {isloading ? (
               <Box sx={{ display: "flex", width: "100%", justifyContent: "center", my: 2 }}>
                  <CircularProgress />
               </Box>
            ) : (
               <Box>
                  <TabPanel value={value} index={0}>
                     <PersonalInfo governData={governTitles} memberData={props.memberData} />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                     <StudiesData data={memberData} />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                     <WorkData data={memberData} />
                  </TabPanel>
                  <TabPanel value={value} index={3}>
                     <Documentation
                        data={memberData}
                        memberId={props.dataId}
                        memberCharge={props.memberData.chargeName}
                     />
                  </TabPanel>
               </Box>
            )}
            <Box
               sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  bgcolor: "#F3F3F3",
                  p: 2,
                  borderBottomRightRadius: 5,
                  borderBottomLeftRadius: 5,
               }}
            >
               <Button
                  onClick={() => {
                     props.setState(false);
                  }}
                  sx={{ color: "grey", height: 30, fontSize: 16 }}
               >
                  Cerrar
               </Button>
            </Box>
         </Box>
      </>
   );
};
