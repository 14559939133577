import { Box, Card, Divider, Stack, Tab, Tabs, Typography } from "@mui/material";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { useContext, useEffect, useState } from "react";
import { CouncilGraphComponent } from "./SubComponents/CouncilGraphComponent";
import { pdf } from "@react-pdf/renderer";
import { UserPanelReport } from "../../../lib/React-PDF/UserPanel/UserPanelReport";
import { getCompanyById } from "../../../lib/usersBEClient";
import { useParams } from "react-router-dom";
import { Companies } from "../../../types/BaseTypes";
import { TabPanel } from "../../../screens/Mi Lecosy/ClientInfoScreenNew";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingButton } from "../../LoadingButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

export const CouncilGraphs = () => {
   const { gobernanceBody } = useContext(GovernanceContext);
   const { companyId } = useParams();
   const [company, setCompany] = useState<Companies>();
   const today = dayjs(new Date());
   const [startDate, setStartDate] = useState<dayjs.Dayjs>(today);
   const [finishDate, setFinishDate] = useState<dayjs.Dayjs>(today);
   const [monthValue, setMonthValue] = useState<dayjs.Dayjs>(undefined);
   const [chartRefs, _setChartRefs] = useState([]);
   const [isLoadingReport, setIsLoadingReport] = useState(false);
   useEffect(() => {
      const getCompanyInfo = async () => {
         const company = await getCompanyById(companyId);
         setCompany(company.data);
      };
      if (companyId) getCompanyInfo();
   }, [companyId]);

   const createPDF = async () => {
      if (chartRefs && company && gobernanceBody) {
         setIsLoadingReport(true);
         const blob = await pdf(
            <UserPanelReport company={company} governanceData={gobernanceBody} chartRefs={chartRefs} />
         ).toBlob();
         setIsLoadingReport(false);
         window.open(URL.createObjectURL(blob), "_blank");
      }
   };
   return (
      <Stack rowGap={2}>
         {gobernanceBody
            .filter((governance) => governance._id !== "0")
            .map((governance, i) => (
               <CouncilGraphComponent key={i} governance={governance} chartRefs={chartRefs} />
            ))}
         {/* <Stack direction={"row"} spacing={2} width={"100%"} justifyContent={"space-between"}>
            <Card sx={{ flex: 1 }}>
               <Tabs value={"1"} textColor="primary">
                  <Tab label="Reportes automáticos (trimestre)" value={"1"} />
               </Tabs>
               <Divider sx={{ borderBottomWidth: 2 }} />
               <TabPanel index={1} value={1}>
                  <Stack
                     direction={"column"}
                     display={"flex"}
                     justifySelf={"center"}
                     alignItems={"center"}
                     justifyContent={"center"}
                     width={"100%"}
                     sx={{ opacity: 0.5 }}
                  >
                     <InfoOutlinedIcon sx={{ fontSize: "30px" }} />
                     <Typography fontWeight={600} fontSize={"16px"}>
                        No existen reportes automáticos
                     </Typography>
                  </Stack>
               </TabPanel>
            </Card>
            <Card sx={{ flex: 1 }}>
               <Tabs value={"1"} textColor="primary">
                  <Tab label="Generar reportes" value={"1"} />
               </Tabs>
               <Divider sx={{ borderBottomWidth: 2 }} />
               <TabPanel index={1} value={1}>
                  <Stack direction={"row"}>
                     <Box flex={5} alignSelf={"center"}>
                        <Stack width={"100%"} py={2} px={3} spacing={3}>
                           <Typography>
                              <strong>Personalizado:</strong>
                           </Typography>
                           <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                              <Stack direction={"row"} spacing={1} alignItems={"center"}>
                                 <Typography>Desde</Typography>
                                 <DatePicker
                                    maxDate={finishDate}
                                    label={"Fecha de inicio"}
                                    format="DD/MM/YYYY"
                                    value={startDate}
                                    onChange={(event: any) => setStartDate(dayjs(event.$d))}
                                    slotProps={{ textField: { variant: "outlined", size: "small" } }}
                                 />
                                 <Typography>Hasta</Typography>
                                 <DatePicker
                                    minDate={startDate}
                                    label={"Fecha de fin"}
                                    format="DD/MM/YYYY"
                                    value={finishDate}
                                    onChange={(event: any) => {
                                       setFinishDate(dayjs(event.$d));
                                    }}
                                    slotProps={{ textField: { variant: "outlined", size: "small" } }}
                                 />
                              </Stack>
                           </LocalizationProvider>
                           <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                              <Stack direction={"row"} spacing={1}>
                                 <Typography alignSelf={"center"}>Mensual</Typography>
                                 <DatePicker
                                    views={["year", "month"]}
                                    label="Mes y año"
                                    value={monthValue}
                                    onChange={setMonthValue}
                                    slotProps={{ textField: { variant: "outlined", size: "small" } }}
                                 />
                              </Stack>
                           </LocalizationProvider>
                        </Stack>
                     </Box>
                     <Box flex={2} alignSelf={"center"} mr={2}>
                        <LoadingButton
                           disabled={!chartRefs || !company || !gobernanceBody}
                           fullWidth
                           onClick={() => {
                              createPDF();
                           }}
                           isLoading={isLoadingReport}
                           label="Generar reporte en pdf"
                        />
                     </Box>
                  </Stack>
               </TabPanel>
            </Card>
         </Stack> */}
      </Stack>
   );
};
