import { useContext, useEffect, useState } from "react";
import { AddUserContext } from "../../../context/addUserContext";
import {
   Box,
   Checkbox,
   Chip,
   Collapse,
   Divider,
   FormControl,
   IconButton,
   InputLabel,
   ListItem,
   MenuItem,
   Select,
   Stack,
   Typography,
} from "@mui/material";
import { getGoberningBodiesByCompany } from "../../../lib/gobCorpBEClient";
import { CargoSelectorComponent } from "../CargoSelectorComponent";
import { DeleteForever } from "@mui/icons-material";

const GovernanceModule = ({ module }) => {
   const { removeModule, addModuleOrRoleGC, modules, removeRole } = useContext(AddUserContext);
   const [open, setOpen] = useState(false);
   const [governingBodies, setGoverningBodies] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [selectedGovernance, setSelectedGovernance] = useState(null);

   useEffect(() => {
      const fetchGoverningBodies = async () => {
         setIsLoading(true);
         const response = await getGoberningBodiesByCompany(module.company);
         setGoverningBodies(response);
         setIsLoading(false);
      };
      fetchGoverningBodies();
   }, []);

   return (
      <>
         <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
               checked={open}
               onChange={() => {
                  if (open) {
                     removeModule(module.service.id);
                     setSelectedGovernance(null);
                  }
                  setOpen(!open);
               }}
            />
            <ListItem>{module.service.serviceName}</ListItem>
         </Box>
         <Divider />
         <Collapse in={open}>
            {!isLoading && governingBodies.length > 0 ? (
               <Stack
                  spacing={2}
                  sx={{ p: 2, border: 1, borderColor: "#F2F2F2", display: "flex" }}
                  direction={"column"}
               >
                  <FormControl sx={{ my: 1, minWidth: 120 }} size="small" fullWidth>
                     <InputLabel id="demo-select-small-label">Seleccionar el órgano de gobierno</InputLabel>
                     <Select
                        labelId="demo-select-small-label"
                        id="demo-select-small"
                        label={`Seleccionar el órgano de gobierno`}
                        onChange={(event) => setSelectedGovernance(event.target.value)}
                        value={selectedGovernance}
                        renderValue={(value) => (value ? value.title : null)}
                     >
                        {governingBodies.map((rol: any) => (
                           <MenuItem value={rol}>{rol.title}</MenuItem>
                        ))}
                     </Select>
                  </FormControl>
                  <Collapse in={selectedGovernance ? true : false} unmountOnExit>
                     {selectedGovernance ? (
                        <Box sx={{ mb: 2 }}>
                           <CargoSelectorComponent
                              governData={selectedGovernance}
                              governUsers={[]}
                              companyUsers={[]}
                              isLoading={false}
                              label="Cargo a desempeñar"
                              setSelectedRole={(charge) => {
                                 addModuleOrRoleGC(module, charge, selectedGovernance._id);
                              }}
                              disableClearable
                              chargeName="Cargo a desempeñar"
                           />
                        </Box>
                     ) : null}

                     <Collapse
                        in={modules.some(
                           (moduleD) => moduleD.module === module.service.id && moduleD.company === module.company
                        )}
                        unmountOnExit
                     >
                        {modules.some(
                           (moduleD) => moduleD.module === module.service.id && moduleD.company === module.company
                        ) && (
                           <Chip
                              sx={{
                                 bgcolor: "#D2D2D2",
                                 color: "#162c44",
                              }}
                              label={
                                 <Box sx={{ display: "flex", alignItems: "center" }}>
                                    <Typography fontSize={13} fontWeight={500}>
                                       {
                                          modules.find(
                                             (moduleD) =>
                                                moduleD.module === module.service.id &&
                                                moduleD.company === module.company
                                          )["name"]
                                       }
                                    </Typography>
                                    <IconButton
                                       onClick={() =>
                                          removeRole(
                                             module,
                                             modules.find(
                                                (moduleD) =>
                                                   moduleD.module === module.service.id &&
                                                   moduleD.company === module.company
                                             )["role"]
                                          )
                                       }
                                    >
                                       <DeleteForever sx={{ color: "#162c44" }} />
                                    </IconButton>
                                 </Box>
                              }
                           />
                        )}
                     </Collapse>
                  </Collapse>
               </Stack>
            ) : (
               <Typography>Sin órganos de gobierno</Typography>
            )}
         </Collapse>
      </>
   );
};

export default GovernanceModule;
