import { useContext, useState } from "react";
import { Box, Button, Drawer, Grid, Typography, Container, Stack, IconButton } from "@mui/material";
import { NotificationBar } from "../../components/notificationsBar";
import { UserContext } from "../../context/userContext";
import { ModifyUserInfoDrawer } from "../../components/Drawers/ModifyUserInfoDrawer";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { ModifyPasswordDrawer } from "../../components/Drawers/ModifyPasswordDrawer";
import ReLogInModal from "../../components/ReLogInModal";
import { getDateFromISODate } from "../../const/globalConst";
import { ProfilePictureComponent } from "./ProfilePictureComponent";

type AditionalContactType = {
   name: string;
   lastName: string;
   email: string;
   phone: string;
   _id: string;
   validPwd: boolean;
};

export const UserConfiguration = () => {
   const { user } = useContext(UserContext);
   const [openDrawer, setOpenDrawer] = useState(false);
   const [openPasswordDrawer, setOpenPasswordDrawer] = useState(false);
   const [openEmail, setOpenEmail] = useState(false);
   const [modifiedPassword, setModifiedPassword] = useState(false);
   const [aditionalContactInfo, setAditionalContactInfo] = useState<AditionalContactType>({
      name: "",
      lastName: "",
      email: "",
      _id: "",
      phone: "",
      validPwd: false,
   });

   const handleModifyUserInformation = () => {
      setAditionalContactInfo({
         _id: user.id,
         email: user.email,
         name: user.firstName,
         lastName: user.lastName,
         phone: user.phoneNumber,
         validPwd: user.validPwd,
      });
      setOpenDrawer(true);
   };

   return (
      <Box p={2}>
         <NotificationBar content={<></>} head="Configuración" />
         <Container maxWidth={"xl"}>
            <Box display={"flex"}>
               <Box flex={1} p={1}>
                  <Box bgcolor={"white"} pt={2} pb={2} boxShadow={2} borderRadius={1}>
                     <Box
                        borderBottom={"1px solid #D9D9D9"}
                        sx={{ display: "flex", justifyContent: "space-between", mb: 3, px: 5, pb: 2 }}
                     >
                        <Typography fontSize={"24px"} fontWeight={500}>
                           Información general
                        </Typography>
                        <Button
                           sx={{
                              bgcolor: "#2D4357",
                              "&:hover": {
                                 bgcolor: "#2D435790",
                              },
                              color: "white",
                              px: 2,
                              boxShadow: 2,
                           }}
                           onClick={handleModifyUserInformation}
                        >
                           Modificar usuario
                        </Button>
                     </Box>
                     <Stack direction={"row"} justifyContent={"center"} sx={{ my: 4 }}>
                        <Box justifyContent={"center"} alignItems={"center"} display={"flex"} sx={{ mx: 2, flex: 1 }}>
                           <ProfilePictureComponent />
                        </Box>
                        <Grid container alignItems={"center"} sx={{ flex: 2, py: 3 }}>
                           <Grid display={"flex"} columnGap={2} item xs={7}>
                              <Typography fontWeight={600}>Nombre:</Typography>
                              <Typography>{user.firstName}</Typography>
                           </Grid>
                           <Grid display={"flex"} columnGap={2} item xs={5}>
                              <Typography fontWeight={600}>Apellido:</Typography>
                              <Typography>{user.lastName}</Typography>
                           </Grid>
                           <Grid display={"flex"} columnGap={2} item xs={7}>
                              <Typography fontWeight={600}>Correo electrónico:</Typography>
                              <Typography>{user.email}</Typography>
                           </Grid>

                           <Grid display={"flex"} alignItems={"center"} columnGap={2} item xs={5}>
                              <Typography fontWeight={600}>Contraseña:</Typography>
                              <Typography fontSize={20}>••••••••••</Typography>
                              <IconButton sx={{ color: "#2D4357" }} onClick={() => setOpenPasswordDrawer(true)}>
                                 <ModeEditIcon />
                              </IconButton>
                           </Grid>

                           <Grid display={"flex"} columnGap={2} item xs={7}>
                              <Typography fontWeight={600}>Teléfono :</Typography>
                              <Typography>{user.phoneNumber}</Typography>
                           </Grid>
                           <Grid display={"flex"} columnGap={2} item xs={5}>
                              <Typography fontWeight={600}>Fecha de creación:</Typography>
                              <Typography>{user.createdAt ? getDateFromISODate(user.createdAt) : ""}</Typography>
                           </Grid>
                        </Grid>
                     </Stack>
                  </Box>
               </Box>
            </Box>
         </Container>
         <Drawer anchor="right" open={openDrawer || openPasswordDrawer} hideBackdrop sx={{ maxWidth: 450 }}>
            {openPasswordDrawer ? (
               <ModifyPasswordDrawer
                  open={openPasswordDrawer}
                  setOpen={setOpenPasswordDrawer}
                  user={user}
                  setModifiedPassword={setModifiedPassword}
               />
            ) : (
               <ModifyUserInfoDrawer
                  open={openDrawer}
                  setOpen={setOpenDrawer}
                  dataToModify={aditionalContactInfo}
                  refreshData={() => {}}
                  setOpenEmail={setOpenEmail}
               />
            )}
         </Drawer>
         <ConfirmationModal
            open={openEmail}
            setOpen={setOpenEmail}
            title="Modificar correo electrónico"
            body={
               <>
                  <Typography>
                     Tu correo electrónico está en proceso de cambio. Por el momento no se verá reflejado.
                  </Typography>
                  <Typography>Es necesario verificar el nuevo correo electrónico para acceder a tu cuenta.</Typography>
               </>
            }
            onConfirm={() => {}}
            cancelButton={true}
         />
         <ReLogInModal open={modifiedPassword} setOpen={setModifiedPassword} />
      </Box>
   );
};
