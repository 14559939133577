import { Checkbox, FormControl, FormGroup, FormControlLabel, FormHelperText } from "@mui/material";
import { useField } from "formik";
import React from "react";

interface TextFieldInterfaceProps {
   label?: string | React.ReactNode;
   fullWidth?: boolean;
   variant?: any;
   id: string;
   name: string;
   sx?: object;
   disabled?: boolean;
   disablemargin?: boolean;
   sxformcontrol?: any;
   size?: "small" | "medium";
   onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const InputCheckBox = (props: TextFieldInterfaceProps) => {
   const [field, meta] = useField(props.name);
   return (
      <>
         {props.sxformcontrol ? (
            <FormControl
               required
               error={meta.error && meta.touched ? true : false}
               component="fieldset"
               sx={props.sxformcontrol}
               variant={props.variant}
               disabled={props.disabled}
            >
               {/* <FormLabel component="legend">Pick two</FormLabel> */}
               <FormGroup>
                  <FormControlLabel
                     sx={{ mx: props.disablemargin ? 0 : "2" }}
                     control={
                        <Checkbox
                           onChange={props.onChange ? props.onChange : field.onChange}
                           {...field}
                           {...props}
                           checked={field.value}
                        />
                     }
                     label={props.label}
                  />
               </FormGroup>
               <FormHelperText>{meta.error && meta.touched ? meta.error : null}</FormHelperText>
            </FormControl>
         ) : (
            <FormControl
               required
               error={meta.error && meta.touched ? true : false}
               component="fieldset"
               {...props.sx}
               variant={props.variant}
               disabled={props.disabled}
            >
               {/* <FormLabel component="legend">Pick two</FormLabel> */}
               <FormGroup>
                  <FormControlLabel
                     sx={{ mx: props.disablemargin ? 0 : "2" }}
                     control={
                        <Checkbox
                           onChange={props.onChange ? props.onChange : field.onChange}
                           {...field}
                           {...props}
                           checked={field.value}
                        />
                     }
                     label={props.label}
                  />
               </FormGroup>
               <FormHelperText>{meta.error && meta.touched ? meta.error : null}</FormHelperText>
            </FormControl>
         )}
      </>
   );
};
