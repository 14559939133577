import {
   Box,
   Button,
   CircularProgress,
   Collapse,
   Divider,
   Grid,
   MenuItem,
   Modal,
   Stack,
   Switch,
   Tooltip,
   Typography,
} from "@mui/material";
import React, { useCallback, useContext, useEffect, useRef, useState } from "react";
import { getDateFromISODate, textCenter } from "../../const/globalConst";
import UseCreateCharts from "../../hooks/useCreateCharts";
import {
   completeEvaluation,
   getChargeById,
   getCommentariesByGov,
   getCommentariesByMember,
   getFirstEvaluation,
   getGoberningBodiesByCompanyResponses,
   getManualEvaluation,
   getProgressByUser,
   getScoresByMember,
   verifyAndCreateEvaluationConfig,
} from "../../lib/gobCorpBEClient";
import { GovernanceContext } from "../../context/governanceContext/governanceContext";
import { PieChart } from "../../components/charts/pieChart";
import { DoughnutChart } from "../../components/charts/doughnutChart";
import { ArrowBack, FiberManualRecord, Info, Settings } from "@mui/icons-material";
import { getUsersDataByGoverningBody } from "../../lib/usersBEClient";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { EvaluationConfigContext } from "../../context/governanceContext/evaluationConfigContext";
import { Form, Formik, FormikProps } from "formik";
import { InputSelectField } from "../../components/Inputs/InputSelectField";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { CustomModalComponent } from "../../components/CustomModalComponent";
import { SnackBarContext } from "../../context/snackBarContext";

dayjs.locale("es");

const cycleOptions = ["Trimestral", "Bimestral", "Mensual", "Anual"];

export const EvaluationControlScreen = () => {
   const { isLoadingConfig, configInfo, evaluationCurrent } = useContext(EvaluationConfigContext);
   const { setSelectedSection } = useContext(GovernanceContext);

   const changeSelectedSection = useCallback(() => {
      setSelectedSection("Configuración de la evaluación");
   }, []);

   useEffect(() => {
      changeSelectedSection();
   }, []);

   if (isLoadingConfig) {
      return <CircularProgress />;
   }

   return (
      <>
         <AutomaticEvaluation />
         <ManualEvaluations />
         {!evaluationCurrent ? (
            <GraphsSection status={configInfo.automatic || configInfo.manualActive} />
         ) : (
            <CurrentEvaluationStatus />
         )}
      </>
   );
};

const CurrentEvaluationStatus = () => {
   const [governing, setGoverning] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const { companySelected } = useContext(GovernanceContext);
   const { evaluationCurrent } = useContext(EvaluationConfigContext);

   const fetchGoverningsByCompany = useCallback(async () => {
      if (!companySelected) return;
      const response = await getGoberningBodiesByCompanyResponses(companySelected, evaluationCurrent._id);
      setGoverning(response.scoreByGovernance);
      setIsLoading(false);
   }, [companySelected]);

   useEffect(() => {
      fetchGoverningsByCompany();
   }, [fetchGoverningsByCompany]);

   if (isLoading) return <CircularProgress size={24} />;
   return (
      <Stack sx={{ p: 2, bgcolor: "white", borderRadius: 1, border: 1, borderColor: "#D8D8D8" }}>
         <Box sx={{ maxWidth: 500, mx: 2, my: 1 }}>
            <Typography sx={{ color: "#162c44", fontWeight: 600, px: 1 }} variant="body2">
               Estatus de evaluación de desempeño
            </Typography>
         </Box>
         <Divider />
         <Stack sx={{ maxHeight: 400, overflowY: "auto" }}>
            {governing
               .filter((gover) => gover.title !== "Asamblea de accionistas")
               .map((gov) => (
                  <GoverningEvaluationDetails gov={gov} />
               ))}
         </Stack>
      </Stack>
   );
};

const GoverningEvaluationDetails = ({ gov }) => {
   const [openCollapse, setOpenCollapse] = useState(false);
   return (
      <Stack>
         <Button onClick={() => setOpenCollapse(!openCollapse)}>
            <Stack
               direction={"row"}
               sx={{
                  alignItems: "center",
                  columnGap: 2,
                  justifyContent: "space-between",
                  minWidth: "100%",
                  px: 2,
               }}
            >
               <Stack direction={"row"} sx={{ alignItems: "center", columnGap: 2, justifyContent: "space-between" }}>
                  <Box sx={{ width: 80, height: 80 }}>
                     <DoughnutChart
                        scores={[100, gov.score]}
                        labels={["completado"]}
                        colors={["#cbd2dd", "#162c44"]}
                        chartRef={undefined}
                        cutout={30}
                        removeBorder
                     />
                  </Box>
                  <Typography>{gov.title}</Typography>
               </Stack>
               <Stack direction={"row"}>
                  <Button variant="contained" disabled>
                     En proceso
                  </Button>
               </Stack>
            </Stack>
         </Button>
         <Divider />
         <Collapse in={openCollapse}>
            {openCollapse && (
               <Stack sx={{ px: 2, rowGap: 2, py: 2 }}>
                  <Typography>Detalles de evaluación del órgano</Typography>
                  <Grid item container xs={12}>
                     {gov.scoreByQuestion.map((score) => (
                        <ScoreComponent color={score.color} text={score.title} value={score.score} />
                     ))}
                     {gov.scoreByQuestion2.map((score) => (
                        <ScoreComponent color={score.color} text={score.title} value={score.score} />
                     ))}
                  </Grid>
                  <Divider />
                  <MembersTable members={gov.users} current />
               </Stack>
            )}
         </Collapse>
      </Stack>
   );
};

const AutomaticEvaluation = () => {
   const [openModal, setOpenModal] = useState(false);
   const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
   const [isLoadingChange, setIsLoadingChange] = useState(false);
   const { isLoadingConfig, configInfo, setConfigInfo, evaluationCurrent } = useContext(EvaluationConfigContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { companySelected } = useContext(GovernanceContext);
   const [startDate, setStartDate] = useState(null);
   const [nextDate, setNextDate] = useState(null);
   const [error, setError] = useState(null);

   const getEvaluationPeriod = (value) => {
      switch (value) {
         case "Mensual":
            return 12;
         case "Trimestral":
            return 4;
         case "Bimestral":
            return 6;
         case "Anual":
            return 1;
         default:
            break;
      }
   };

   function obtenerFechasPeriodo(tipoPeriodo, numeroPeriodo) {
      const fechaActual = new Date();
      const anioActual = fechaActual.getFullYear();
      let fechaInicio, fechaFin;

      function ultimoDiaDelMes(anio, mes) {
         return new Date(anio, mes + 1, 0).getDate();
      }

      switch (tipoPeriodo) {
         case "Trimestral":
            switch (numeroPeriodo) {
               case 1:
                  fechaInicio = new Date(anioActual, 0, 1);
                  fechaFin = new Date(anioActual, 2, ultimoDiaDelMes(anioActual, 2));
                  break;
               case 2:
                  fechaInicio = new Date(anioActual, 3, 1);
                  fechaFin = new Date(anioActual, 5, ultimoDiaDelMes(anioActual, 5));
                  break;
               case 3:
                  fechaInicio = new Date(anioActual, 6, 1);
                  fechaFin = new Date(anioActual, 8, ultimoDiaDelMes(anioActual, 8));
                  break;
               case 4:
                  fechaInicio = new Date(anioActual, 9, 1);
                  fechaFin = new Date(anioActual, 11, 31);
                  break;
               default:
                  throw new Error("Número de trimestre inválido. Debe ser un número entre 1 y 4.");
            }
            break;
         case "Bimestral":
            fechaInicio = new Date(anioActual, (numeroPeriodo - 1) * 2, 1);
            fechaFin = new Date(anioActual, numeroPeriodo * 2 - 1, ultimoDiaDelMes(anioActual, numeroPeriodo * 2 - 1));
            break;
         case "Mensual":
            fechaInicio = new Date(anioActual, numeroPeriodo - 1, 1);
            fechaFin = new Date(anioActual, numeroPeriodo - 1, ultimoDiaDelMes(anioActual, numeroPeriodo - 1));
            break;
         case "Anual":
            if (numeroPeriodo !== 1) throw new Error("Número de periodo anual inválido. Debe ser 1.");
            fechaInicio = new Date(anioActual, 0, 1);
            fechaFin = new Date(anioActual, 11, 31);
            break;
         default:
            throw new Error("Tipo de periodo inválido. Debe ser 'trimestral', 'bimestral', 'mensual' o 'anual'.");
      }

      return { fechaInicio, fechaFin };
   }

   const updateOrCreateEvaluationConfig = async (values) => {
      setIsLoadingChange(true);
      const parameters = Object.keys(values).filter((value) => value !== "cycle");
      const numberCicle = getEvaluationPeriod(values.cycle);
      const periods = [];
      const closeFunction = () => {
         setIsLoadingChange(false);
         setOpenModalConfirmation(false);
      };

      if (numberCicle * 2 !== parameters.length) {
         setError(`Favor de asignar todos los periodos`);
         closeFunction();
         return;
      }
      for (const iterator of parameters) {
         if (iterator.includes("Start")) {
            if (!values[iterator]) {
               setError(
                  `Favor de seleccionar la fecha inicial del periodo ${
                     parseInt(iterator.charAt(iterator.length - 1)) + 1
                  }`
               );
               closeFunction();
               return;
            }
            if (periods[iterator.charAt(iterator.length - 1)]) {
               periods[iterator.charAt(iterator.length - 1)] = {
                  ...periods[iterator.charAt(iterator.length - 1)],
                  startDate: values[iterator],
               };
            } else {
               periods[iterator.charAt(iterator.length - 1)] = {
                  startDate: values[iterator],
               };
            }
         } else {
            if (!values[iterator]) {
               setError(
                  `Favor de seleccionar la fecha inicial del final ${
                     parseInt(iterator.charAt(iterator.length - 1)) + 1
                  }`
               );
               closeFunction();
               return;
            }
            if (periods[iterator.charAt(iterator.length - 1)]) {
               periods[iterator.charAt(iterator.length - 1)] = {
                  ...periods[iterator.charAt(iterator.length - 1)],
                  endDate: values[iterator],
               };
            } else {
               periods[iterator.charAt(iterator.length - 1)] = {
                  endDate: values[iterator],
               };
            }
         }
      }
      setError(null);
      const createdConfig = await verifyAndCreateEvaluationConfig({
         company: companySelected,
         automatic: true,
         manualActive: false,
         cycle: values.cycle,
         period: periods,
      });
      setConfigInfo({ ...configInfo, ...createdConfig });
      setIsLoadingChange(false);
      setOpenModalConfirmation(false);
      setOpenModal(false);
      showSnackBar("Se guardaron los ajustes de la evaluación automática.", false);
   };

   const handleInitialValues = () => {
      if (configInfo._id) {
         let data = {
            cycle: configInfo.cycle,
         };
         configInfo.period.forEach((period, index) => {
            data["periodStart" + index] = period.startDate;
            data["periodEnd" + index] = period.endDate;
         });
         return data;
      } else {
         return {
            cycle: "Trimestral",
         };
      }
   };

   useEffect(() => {
      const fetchFirstEvaluation = async () => {
         if (startDate || !companySelected) return;
         const response = await getFirstEvaluation(companySelected);
         if (response) {
            setStartDate(response.startTime);
         }
         if (configInfo.period.length > 0) {
            const today = new Date();
            for (let i = 0; i < configInfo.period.length; i++) {
               const start = new Date(configInfo.period[i].startDate);
               const end = new Date(configInfo.period[i].endDate);

               if (today >= start && today <= end) {
                  if (configInfo.period[i + 1]) {
                     setNextDate(configInfo.period[i + 1].startDate);
                  }
                  return;
               }
            }
         }
      };
      fetchFirstEvaluation();
   }, [configInfo.period, companySelected]);

   if (isLoadingConfig) {
      return <CircularProgress size={24} />;
   }

   return (
      <>
         <Stack sx={{ p: 2, bgcolor: "white", borderRadius: 1, border: 1, borderColor: "#D8D8D8" }}>
            <Box sx={{ maxWidth: 500, mx: 2, my: 1 }}>
               <Typography sx={{ color: "#162c44", fontWeight: 600, px: 1 }} variant="body2">
                  Implementación de evaluación de desempeño
               </Typography>
            </Box>
            <Divider />
            <Stack direction={"column"} sx={{ p: 2, justifyContent: "center" }}>
               <Grid container xs={12} spacing={2}>
                  <Grid item xs={4}>
                     <BlockItem
                        text={"Ciclo de evaluación:"}
                        value={configInfo.cycle ? configInfo.cycle : "Sin datos"}
                     />
                  </Grid>
                  <Grid item xs={4}>
                     <BlockItem
                        text={"Fecha de inicio de evaluaciones:"}
                        value={startDate ? getDateFromISODate(startDate) : "-"}
                     />
                  </Grid>
                  <Grid item xs={4}>
                     <Stack direction={"row"} columnGap={2} alignItems={"center"}>
                        <Typography sx={{ fontWeight: 600 }} variant="body2">
                           Estatus actual de la evaluación:
                        </Typography>
                        <Box
                           sx={{
                              bgcolor: configInfo.automatic ? "#162c44" : "whitesmoke",
                              border: 1,
                              borderRadius: 2,
                              borderColor: "gray",
                              display: "flex",
                              pr: 1,
                              columnGap: 2,
                              alignItems: "center",
                           }}
                        >
                           <Switch
                              disabled={true}
                              checked={configInfo.automatic}
                              sx={{
                                 "& .MuiSwitch-track": {
                                    bgcolor: configInfo.automatic ? "white" : "",
                                 },
                              }}
                              color="default"
                           />
                           <Typography variant="body2" sx={{ color: configInfo.automatic ? "white" : "black" }}>
                              {configInfo.automatic ? "Habilitada" : "Inhabilitada"}
                           </Typography>
                        </Box>
                     </Stack>
                  </Grid>
                  <Grid item xs={4}>
                     <BlockItem text={"Evaluaciones realizadas:"} value={configInfo.evaluationNumber} />
                  </Grid>
                  <Grid item xs={4}>
                     <BlockItem
                        text={"Fecha de próxima evaluación:"}
                        value={nextDate ? getDateFromISODate(nextDate) : "-"}
                     />
                  </Grid>
                  <Grid item xs={4}>
                     <Stack direction={"row"} sx={{ justifyContent: "flex-end" }}>
                        <Button sx={{ columnGap: 1 }} onClick={() => setOpenModal(true)}>
                           Configuración de la evaluación <Settings />
                        </Button>
                     </Stack>
                  </Grid>
               </Grid>
            </Stack>
            {configInfo.automatic && evaluationCurrent ? <EvaluationInfoProgress /> : null}
         </Stack>

         <CustomModalComponent
            open={openModal}
            setOpen={setOpenModal}
            onClose={() => setOpenModal(false)}
            title="Configuración de la evaluación"
         >
            <Stack>
               <Formik initialValues={handleInitialValues()} onSubmit={updateOrCreateEvaluationConfig}>
                  {(formProps: FormikProps<any>) => (
                     <Form>
                        <Stack sx={{ p: 2, rowGap: 2 }}>
                           <Stack sx={{ p: 2, rowGap: 2 }}>
                              <Typography>Seleccione la periodicidad correspondiente para las evaluaciones</Typography>
                              <BlockItem
                                 text={"Periodo establecido para evaluar:"}
                                 component={
                                    <InputSelectField
                                       id="cycle"
                                       labelId="cycle"
                                       name="cycle"
                                       label=""
                                       size="small"
                                       sx={{ bgcolor: "whitesmoke" }}
                                    >
                                       {cycleOptions.map((e) => (
                                          <MenuItem key={e} value={e}>
                                             <Typography>{e}</Typography>
                                          </MenuItem>
                                       ))}
                                    </InputSelectField>
                                 }
                              />
                              <Typography>Establece las fechas de inicio y cierre de las evaluaciones:</Typography>
                           </Stack>
                           <Stack sx={{ maxHeight: 300, overflowY: "auto", rowGap: 2, p: 2 }}>
                              {Array.from({ length: getEvaluationPeriod(formProps.values.cycle) }).map((_, index) => {
                                 const dates = obtenerFechasPeriodo(formProps.values.cycle, index + 1);
                                 return (
                                    <>
                                       <Typography variant="caption">
                                          Evaluación {formProps.values.cycle + " " + (index + 1)}
                                       </Typography>
                                       <Divider />
                                       <Stack direction={"row"} sx={{ columnGap: 3 }}>
                                          <BlockItem
                                             text={"Fecha de inicio:"}
                                             component={
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                   <DatePicker
                                                      minDate={dayjs(dates.fechaInicio)}
                                                      maxDate={dayjs(dates.fechaFin)}
                                                      referenceDate={dayjs(dates.fechaInicio)}
                                                      format="DD/MM/YYYY"
                                                      value={dayjs(formProps.values["periodStart" + index])}
                                                      sx={{
                                                         bgcolor: "whitesmoke",
                                                         borderColor: "gray",
                                                         maxWidth: 200,
                                                      }}
                                                      onChange={(event: any) =>
                                                         formProps.setFieldValue("periodStart" + index, dayjs(event.$d))
                                                      }
                                                      slotProps={{
                                                         textField: {
                                                            variant: "outlined",
                                                            size: "small",
                                                            disabled: true,
                                                         },
                                                      }}
                                                   />
                                                </LocalizationProvider>
                                             }
                                          />
                                          <BlockItem
                                             text={"Fecha de cierre de evaluación:"}
                                             component={
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                   <DatePicker
                                                      minDate={dayjs(dates.fechaInicio)}
                                                      maxDate={dayjs(dates.fechaFin)}
                                                      referenceDate={dayjs(dates.fechaFin)}
                                                      value={dayjs(formProps.values["periodEnd" + index])}
                                                      format="DD/MM/YYYY"
                                                      sx={{
                                                         bgcolor: "whitesmoke",
                                                         borderColor: "gray",
                                                         maxWidth: 200,
                                                      }}
                                                      onChange={(event: any) =>
                                                         formProps.setFieldValue("periodEnd" + index, dayjs(event.$d))
                                                      }
                                                      slotProps={{
                                                         textField: {
                                                            variant: "outlined",
                                                            size: "small",
                                                            disabled: true,
                                                         },
                                                      }}
                                                   />
                                                </LocalizationProvider>
                                             }
                                          />
                                       </Stack>
                                    </>
                                 );
                              })}
                           </Stack>
                        </Stack>
                        <Stack direction={"row"} sx={{ justifyContent: "flex-end", p: 1 }}>
                           <Typography sx={{ color: "red" }} variant="body2">
                              {error}
                           </Typography>
                        </Stack>
                        <Stack
                           direction={"row"}
                           sx={{ justifyContent: "flex-end", bgcolor: "#f3f3f3", p: 2, columnGap: 4 }}
                        >
                           <Button onClick={() => setOpenModal(false)}>Cancelar</Button>
                           <Button sx={{ fontWeight: 600 }} onClick={() => setOpenModalConfirmation(true)}>
                              Guardar
                           </Button>
                        </Stack>
                        <ConfirmationModal
                           open={openModalConfirmation}
                           setOpen={setOpenModalConfirmation}
                           onConfirm={() => formProps.submitForm()}
                           title="Habilitar evaluación automática"
                           isLoading={isLoadingChange}
                           body={
                              <Stack>
                                 <Typography align="center">
                                    ¿Está seguro que desea habilitar la evaluación de desempeño con la información
                                    registrada anteriormente?
                                 </Typography>
                                 <Typography align="center" color={"red"} fontSize={12}>
                                    Al confirmar, si existe una evaluación manual se inhabilitará.
                                 </Typography>
                              </Stack>
                           }
                        />
                     </Form>
                  )}
               </Formik>
            </Stack>
         </CustomModalComponent>
      </>
   );
};

const ManualEvaluations = () => {
   const { companySelected } = useContext(GovernanceContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { isLoadingConfig, configInfo, setConfigInfo, evaluationCurrent, fetchConfigInfo } =
      useContext(EvaluationConfigContext);
   const [openModal, setOpenModal] = useState(false);
   const [evaluation, setEvaluation] = useState(null);
   const [openModalConfirmation, setOpenModalConfirmation] = useState(false);
   const [isLoadingChange, setIsLoadingChange] = useState(false);
   const [startDate, setStartDate] = useState(null);
   const [endDate, setEndDate] = useState(null);

   const fetchManualEvaluation = useCallback(async () => {
      if (companySelected === "" || evaluation) return;
      const response = await getManualEvaluation({ company: companySelected });
      if (response) {
         setStartDate(response.startTime);
         setEndDate(response.endTime);
      }
      setEvaluation(response);
   }, [companySelected]);

   useEffect(() => {
      fetchManualEvaluation();
   }, [fetchManualEvaluation]);

   const handleChangeManualEvaluation = async () => {
      setIsLoadingChange(true);
      const createdConfig = await verifyAndCreateEvaluationConfig({
         company: companySelected,
         automatic: false,
         manualActive: true,
         startDate,
         endDate,
      });
      setConfigInfo({ ...configInfo, ...createdConfig });
      setIsLoadingChange(false);
      showSnackBar("Se guardaron los ajustes de la evaluación manual.", false);
      setOpenModalConfirmation(false);
      fetchConfigInfo();
      setOpenModal(false);
   };

   if (isLoadingConfig) {
      return <CircularProgress />;
   }

   return (
      <Stack sx={{ p: 2, bgcolor: "white", borderRadius: 1, border: 1, borderColor: "#D8D8D8" }}>
         <Box sx={{ maxWidth: 500, mx: 2, my: 1 }}>
            <Typography sx={{ color: "#162c44", fontWeight: 600, px: 1 }} variant="body2">
               Evaluaciones manuales
            </Typography>
         </Box>
         <Divider />
         <Stack direction={"column"} sx={{ p: 2, justifyContent: "center" }}>
            <Grid container xs={12} spacing={2}>
               <Grid item xs={configInfo.manualActive && evaluationCurrent ? 4 : 6}>
                  <Stack direction={"row"} columnGap={2} alignItems={"center"}>
                     <Typography sx={{ fontWeight: 600 }} variant="body2">
                        Estatus actual de la evaluación:
                     </Typography>
                     <Box
                        sx={{
                           bgcolor: configInfo.manualActive ? "#162c44" : "whitesmoke",
                           border: 1,
                           borderRadius: 2,
                           borderColor: "gray",
                           display: "flex",
                           pr: 1,
                           columnGap: 2,
                           alignItems: "center",
                        }}
                     >
                        <Switch
                           disabled={true}
                           checked={configInfo.manualActive}
                           sx={{
                              "& .MuiSwitch-track": {
                                 bgcolor: configInfo.manualActive ? "white" : "",
                              },
                           }}
                           color="default"
                        />
                        <Typography variant="body2" sx={{ color: configInfo.manualActive ? "white" : "black" }}>
                           {configInfo.manualActive ? "Habilitada" : "Inhabilitada"}
                        </Typography>
                     </Box>
                  </Stack>
               </Grid>
               {configInfo.manualActive && evaluationCurrent ? (
                  <Grid item xs={4}>
                     <Stack direction={"row"} sx={{ justifyContent: "center" }}>
                        <BlockItem
                           text={"Fecha de inicio de evaluación:"}
                           value={getDateFromISODate(evaluationCurrent.startTime)}
                        />
                     </Stack>
                  </Grid>
               ) : null}
               <Grid item xs={configInfo.manualActive && evaluationCurrent ? 4 : 6}>
                  <Stack direction={"row"} sx={{ justifyContent: "flex-end" }}>
                     <Button sx={{ columnGap: 1 }} onClick={() => setOpenModal(true)}>
                        Configuración de la evaluación <Settings />
                     </Button>
                  </Stack>
               </Grid>
            </Grid>
         </Stack>
         {configInfo.manualActive && evaluationCurrent ? <EvaluationInfoProgress /> : null}
         <CustomModalComponent
            open={openModal}
            setOpen={setOpenModal}
            onClose={() => setOpenModal(false)}
            title="Configuración de la evaluación"
         >
            <Stack>
               <Stack sx={{ p: 4, rowGap: 2 }}>
                  <Typography>Establece las fechas de inicio y cierre de las evaluaciones:</Typography>
                  <BlockItem
                     text={"Fecha de inicio:"}
                     component={
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                           <DatePicker
                              // maxDate={dayjs(new Date())}
                              // label={"Fecha de inicio"}
                              value={dayjs(startDate)}
                              format="DD/MM/YYYY"
                              disablePast
                              sx={{
                                 bgcolor: "whitesmoke",
                                 borderColor: "gray",
                              }}
                              onChange={(event: any) => setStartDate(dayjs(event.$d))}
                              slotProps={{ textField: { variant: "outlined", size: "small" } }}
                           />
                        </LocalizationProvider>
                     }
                  />
                  <BlockItem
                     text={"Fecha de cierre:"}
                     component={
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                           <DatePicker
                              // maxDate={dayjs(new Date())}
                              // label={"Fecha de inicio"}
                              value={dayjs(endDate)}
                              format="DD/MM/YYYY"
                              disablePast
                              sx={{
                                 bgcolor: "whitesmoke",
                                 borderColor: "gray",
                              }}
                              onChange={(event: any) => setEndDate(dayjs(event.$d))}
                              slotProps={{ textField: { variant: "outlined", size: "small" } }}
                           />
                        </LocalizationProvider>
                     }
                  />
               </Stack>
               <Stack
                  direction={"row"}
                  sx={{ justifyContent: "flex-end", mt: 4, bgcolor: "#f3f3f3", p: 2, columnGap: 4 }}
               >
                  <Button onClick={() => setOpenModal(false)}>Cancelar</Button>
                  <Button sx={{ fontWeight: 600 }} onClick={() => setOpenModalConfirmation(true)}>
                     Guardar
                  </Button>
               </Stack>
            </Stack>
         </CustomModalComponent>
         <ConfirmationModal
            open={openModalConfirmation}
            setOpen={setOpenModalConfirmation}
            onConfirm={() => handleChangeManualEvaluation()}
            title="Habilitar evaluación"
            isLoading={isLoadingChange}
            body={
               <Stack>
                  <Typography align="center">
                     ¿Está seguro que desea habilitar la evaluación de desempeño con la información registrada
                     anteriormente?
                  </Typography>
                  <Typography align="center" fontSize={12} color={"red"}>
                     Al confirmar, si existe una evaluación automática se inhabilitará.
                  </Typography>
               </Stack>
            }
         />
      </Stack>
   );
};

const EvaluationInfoProgress = () => {
   const [progress, setProgress] = useState(0);
   const [isLoading, setIsLoading] = useState(true);
   const [isLoadingChange, setIsLoadingChange] = useState(false);
   const [openModal, setOpenModal] = useState(false);
   const { companySelected } = useContext(GovernanceContext);
   const { evaluationCurrent, fetchConfigInfo, configInfo } = useContext(EvaluationConfigContext);
   const { showSnackBar } = useContext(SnackBarContext);

   const fetchGoverningsByCompany = useCallback(async () => {
      if (!companySelected) return;
      const response = await getGoberningBodiesByCompanyResponses(companySelected, evaluationCurrent._id);
      setProgress(response.finalProgress);
      setIsLoading(false);
   }, [companySelected]);

   useEffect(() => {
      fetchGoverningsByCompany();
   }, [fetchGoverningsByCompany]);

   if (isLoading) {
      return <CircularProgress />;
   }

   const changeEvaluationStatus = async () => {
      setIsLoadingChange(true);
      await completeEvaluation(evaluationCurrent._id);
      if (configInfo.manualActive) {
         await verifyAndCreateEvaluationConfig({ company: companySelected, manualActive: false });
      }
      setIsLoadingChange(false);
      showSnackBar("Evaluación cerrada correctamente.", false);
      fetchConfigInfo();
   };

   return (
      <>
         <Divider />
         <Stack direction={"row"} sx={{ px: 2, pt: 2 }}>
            <Grid container>
               <Grid item xs={4}>
                  <Stack direction={"row"} sx={{ justifyContent: "space-evenly" }}>
                     <Stack direction={"row"} sx={{ columnGap: 2 }}>
                        <Typography fontWeight={600}>Avance actual:</Typography>
                        <Typography>{progress.toFixed(2) + "%"}</Typography>
                     </Stack>
                  </Stack>
               </Grid>
               <Grid item xs={4}>
                  <Stack direction={"row"} sx={{ justifyContent: "center" }}>
                     <Stack direction={"row"} sx={{ columnGap: 2 }}>
                        <Typography fontWeight={600}>Fecha de cierre de evaluación:</Typography>
                        <Typography>{getDateFromISODate(evaluationCurrent.endTime)}</Typography>
                     </Stack>
                  </Stack>
               </Grid>
               <Grid item xs={4}>
                  <Stack direction={"row"} sx={{ justifyContent: "space-evenly" }}>
                     <Button variant="contained" onClick={() => setOpenModal(true)}>
                        Finalizar evaluación
                     </Button>
                  </Stack>
               </Grid>
            </Grid>
         </Stack>
         <ConfirmationModal
            open={openModal}
            setOpen={setOpenModal}
            onConfirm={() => changeEvaluationStatus()}
            title="Confirmar cierre de la evaluación"
            isLoading={isLoadingChange}
            body={
               <Stack sx={{ justifyContent: "center", rowGap: 2 }}>
                  <Typography align="center">
                     ¿Está seguro de finalizar con la evaluación de desempeño correspondiente a el periodo{" "}
                     {getDateFromISODate(evaluationCurrent.startTime) +
                        " - " +
                        getDateFromISODate(evaluationCurrent.endTime)}
                     ?
                  </Typography>
                  <Typography align="center" fontSize={12} color={"red"}>
                     Una vez concluido, no es posible realizar modificaciones y se guardara el progreso obtenido hasta
                     el momento.
                  </Typography>
               </Stack>
            }
         />
      </>
   );
};

const BlockItem = ({ text, value, component }: { text; value?; component? }) => {
   return (
      <Stack direction={"row"} columnGap={1} alignItems={"center"}>
         <Typography sx={{ fontWeight: 600 }} variant="body2">
            {text}
         </Typography>
         {component || (
            <Box sx={{ bgcolor: "whitesmoke", border: 1, borderRadius: 1, borderColor: "gray", p: 1 }}>
               <Typography variant="body2">{value}</Typography>
            </Box>
         )}
      </Stack>
   );
};

const SelectedGoverning = ({ selected }) => {
   const [openCommentary, setOpenCommentary] = useState(false);

   return (
      <Stack direction={"row"} sx={{ columnGap: 4, py: 4 }}>
         <Stack flex={1} sx={{ justifyContent: "center", alignItems: "center", rowGap: 2 }}>
            <Box
               sx={{
                  height: 220,
               }}
            >
               <DoughnutChart
                  scores={[
                     ...selected.scoreByQuestion.map((score) => score.score.toFixed(2)),
                     ...selected.scoreByQuestion2.map((score) => score.score.toFixed(2)),
                  ]}
                  labels={[
                     ...selected.scoreByQuestion.map((score) => score.title),
                     ...selected.scoreByQuestion2.map((score) => score.title),
                  ]}
                  colors={[
                     ...selected.scoreByQuestion.map((score) => score.color),
                     ...selected.scoreByQuestion2.map((score) => score.color),
                  ]}
                  chartRef={undefined}
                  cutout={80}
                  textCenterProp={textCenter(
                     { size: "16", text: "Total", color: "#737373" },
                     {
                        size: "36",
                        text: (selected.score * 10).toFixed(2),
                        color: "#0A0A0A",
                     }
                  )}
                  removeBorder
               />
            </Box>
            <Button variant="contained" onClick={() => setOpenCommentary(true)}>
               Ver comentarios
            </Button>
         </Stack>
         <Stack direction={"row"}>
            <Stack flex={3} sx={{ alignItems: "center" }}>
               <Grid container spacing={2}>
                  <Grid item xs={9}>
                     <Typography align="center" sx={{ color: "#8a95a0", fontWeight: 600 }}>
                        Criterios
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography align="center" sx={{ color: "#8a95a0", fontWeight: 600 }}>
                        %
                     </Typography>
                  </Grid>
               </Grid>
               <Divider sx={{ my: 1 }} />
               <Grid container spacing={2}>
                  {selected.scoreByQuestion.map((crit) => (
                     <>
                        <Grid item xs={9}>
                           <Box sx={{ display: "flex", columnGap: 1, alignItems: "center", pl: 2 }}>
                              <FiberManualRecord sx={{ color: crit.color, fontSize: 18 }} />
                              <Typography align="center" variant="caption">
                                 {crit.title}
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid item xs={3}>
                           <Box sx={{ display: "flex", justifyContent: "center" }}>
                              <Typography align="center" fontWeight={600} variant="caption">
                                 {crit.score.toFixed(2)}%
                              </Typography>
                           </Box>
                        </Grid>
                     </>
                  ))}
               </Grid>
            </Stack>
            <CommentaryModal
               openCommentary={openCommentary}
               setOpenCommentary={setOpenCommentary}
               member={null}
               general={selected._id}
            />
            <Stack flex={3} sx={{ pt: 2, alignItems: "center" }}>
               <Grid container spacing={2}>
                  <Grid item xs={9}>
                     <Typography align="center" sx={{ color: "#8a95a0", fontWeight: 600 }}>
                        Criterios
                     </Typography>
                  </Grid>
                  <Grid item xs={3}>
                     <Typography align="center" sx={{ color: "#8a95a0", fontWeight: 600 }}>
                        %
                     </Typography>
                  </Grid>
               </Grid>
               <Divider sx={{ my: 1 }} />
               <Grid container spacing={2}>
                  {selected.scoreByQuestion2.map((crit) => (
                     <>
                        <Grid item xs={9}>
                           <Box sx={{ display: "flex", columnGap: 1, alignItems: "center", pl: 2 }}>
                              <FiberManualRecord sx={{ color: crit.color, fontSize: 18 }} />
                              <Typography align="center" variant="caption">
                                 {crit.title}
                              </Typography>
                           </Box>
                        </Grid>
                        <Grid item xs={3}>
                           <Box sx={{ display: "flex", justifyContent: "center" }}>
                              <Typography align="center" fontWeight={600} variant="caption">
                                 {crit.score.toFixed(2)}%
                              </Typography>
                           </Box>
                        </Grid>
                     </>
                  ))}
               </Grid>
            </Stack>
         </Stack>
      </Stack>
   );
};

const MembersTable = ({ members, current }: { members: any[]; current?: boolean }) => {
   const [membersInfo, setMembersInfo] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [open, setOpen] = useState(false);
   const [memberSelected, setMemberSelected] = useState(null);
   const { lastEvaluationInfo, evaluationCurrent } = useContext(EvaluationConfigContext);

   const loadMemberInfo = useCallback(async () => {
      if (members.length === 0 || membersInfo.length > 0) return;
      const userData = await getUsersDataByGoverningBody(members.map((member) => member.user));
      let progress = [];
      if (current) {
         progress = await getProgressByUser(
            evaluationCurrent._id,
            members.map((member) => member.user)
         );
      } else {
         progress = await getProgressByUser(
            lastEvaluationInfo._id,
            members.map((member) => member.user)
         );
      }
      let membersWithCharge = [];
      for (const iterator of userData) {
         const memberCharge = members.find((member) => member.user === iterator._id);
         membersWithCharge.push({ ...iterator, ...memberCharge });
      }
      let newMembers = [];
      for (const iterator of membersWithCharge) {
         const progressByUser = progress.find((progress) => progress.user === iterator.user);
         newMembers.push({ ...iterator, progressByUser });
      }
      setMembersInfo(newMembers);
      setIsLoading(false);
   }, [members]);

   useEffect(() => {
      loadMemberInfo();
   }, [loadMemberInfo]);

   if (isLoading) {
      <CircularProgress />;
   }

   return (
      <Stack sx={{ p: 4, rowGap: 1 }}>
         <Typography fontWeight={600}>Miembros de órgano</Typography>
         {membersInfo.map((member) => (
            <Stack
               direction={"row"}
               sx={{
                  border: 1,
                  borderColor: "whitesmoke",
                  borderRadius: 2,
                  py: 2,
                  alignItems: "center",
                  px: 4,
               }}
            >
               <Typography sx={{ flex: 1 }}>
                  {member.firstName} {member.lastName}
               </Typography>
               <Typography sx={{ flex: 1 }}>
                  Fecha de finalización:{" "}
                  {member.progressByUser
                     ? member.progressByUser.finished
                        ? getDateFromISODate(member.progressByUser.updatedAt)
                        : "Sin concluir"
                     : "Sin datos"}
               </Typography>
               <Button
                  sx={{
                     flex: 1,
                     bgcolor: "#E5E6EB",
                     color: "#152C44",
                     borderColor: "#F0F0F0",
                     "&:hover": {
                        bgcolor: "#E5E6EB",
                     },
                  }}
                  variant="contained"
                  onClick={() => {
                     setOpen(true);
                     setMemberSelected(member);
                  }}
               >
                  Ver calificación
               </Button>
            </Stack>
         ))}
         <MemberEvaluationModal member={memberSelected} open={open} setOpen={setOpen} current={current} />
      </Stack>
   );
};

const GraphsSection = ({ status }) => {
   const [selected, setSelected] = useState(null);
   const { lastEvaluationInfo } = useContext(EvaluationConfigContext);

   if (selected) {
      return (
         <Stack sx={{ p: 2, bgcolor: "white", borderRadius: 1, border: 1, borderColor: "#D8D8D8" }}>
            <Box sx={{ maxWidth: 500, mx: 2, my: 1 }}>
               <Typography sx={{ color: "#162c44", fontWeight: 600, px: 1 }} variant="body2">
                  Evaluaciones de desempeño
               </Typography>
            </Box>
            <Divider />
            <Stack direction={"row"} sx={{ pt: 2, px: 2 }}>
               <Stack direction={"row"} flex={2}>
                  <Button onClick={() => setSelected(null)}>
                     <ArrowBack />
                     {selected.title}
                  </Button>
               </Stack>
               <Stack direction={"row"} flex={2}>
                  <BlockItem text={"Estatus actual de la evaluación:"} value={status ? "Habilitada" : "Inhabilitada"} />
               </Stack>
               <Stack direction={"row"} flex={3} sx={{ justifyContent: "flex-end" }}>
                  <BlockItem text={"Fecha de evaluación:"} value={getDateFromISODate(lastEvaluationInfo.startTime)} />
               </Stack>
            </Stack>
            <SelectedGoverning selected={selected} />
            <Divider />
            <MembersTable members={selected.users} />
         </Stack>
      );
   }

   return (
      <Stack sx={{ p: 2, bgcolor: "white", borderRadius: 1, border: 1, borderColor: "#D8D8D8" }}>
         <Box sx={{ maxWidth: 500, mx: 2, my: 1 }}>
            <Typography sx={{ color: "#162c44", fontWeight: 600, px: 1 }} variant="body2">
               Evaluaciones de desempeño
            </Typography>
         </Box>
         <Divider />
         <Stack>
            <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
               <GraphAccomplishment setSelected={setSelected} />
               <Typography fontWeight={600}>Estatus actual de la evaluación:</Typography>
               <Box sx={{ bgcolor: "whitesmoke", border: 1, borderRadius: 2, borderColor: "gray", p: 1 }}>
                  <Typography variant="body2" fontWeight={600}>
                     {status ? "Habilitada" : "Inhabilitada"}
                  </Typography>
               </Box>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "flex-end", py: 2 }}>
               <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                  <Typography fontWeight={600}>Fecha de evaluación:</Typography>
                  <Typography sx={{ flex: 1 }}>Inicio</Typography>
                  <Box sx={{ bgcolor: "whitesmoke", border: 1, borderRadius: 2, borderColor: "gray", p: 1 }}>
                     <Typography variant="body2" fontWeight={600}>
                        {lastEvaluationInfo.startTime ? getDateFromISODate(lastEvaluationInfo.startTime) : "Sin datos"}
                     </Typography>
                  </Box>
                  <Typography sx={{ flex: 1 }}>Finalizado</Typography>
                  <Box sx={{ bgcolor: "whitesmoke", border: 1, borderRadius: 2, borderColor: "gray", p: 1 }}>
                     <Typography variant="body2" fontWeight={600}>
                        {lastEvaluationInfo.endTime ? getDateFromISODate(lastEvaluationInfo.endTime) : "Sin datos"}
                     </Typography>
                  </Box>
               </Box>
            </Box>
         </Stack>
      </Stack>
   );
};

const GraphAccomplishment = ({ setSelected }) => {
   const [governing, setGoverning] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const { companySelected } = useContext(GovernanceContext);
   const { lastEvaluationInfo } = useContext(EvaluationConfigContext);
   const graphRef = useRef(null);

   const fetchGoverningsByCompany = useCallback(async () => {
      if (!companySelected || !lastEvaluationInfo) return;
      setIsLoading(true);
      const response = await getGoberningBodiesByCompanyResponses(companySelected, lastEvaluationInfo._id);
      setGoverning(response.scoreByGovernance);
      setIsLoading(false);
   }, [companySelected, lastEvaluationInfo]);

   useEffect(() => {
      fetchGoverningsByCompany();
   }, [fetchGoverningsByCompany]);

   if (isLoading) {
      return <CircularProgress />;
   }

   return (
      <Stack sx={{ height: 300, maxWidth: 800, m: 2, flex: 3 }}>
         <Typography
            fontWeight={600}
            sx={{
               p: 1,
               pb: 3,
            }}
         >
            Cumplimiento de órganos que forman el Gobierno Corporativo
         </Typography>

         <Box sx={{ minHeight: 340 }}>
            {UseCreateCharts({
               pointColor: true,
               type: "bar",
               chartRef: graphRef,
               colors: ["#162c44", "#5E77F9"],
               labels: governing
                  .map((gov) => gov.title)
                  .filter((titles) => titles !== "Asamblea de accionistas") as string[],
               labelNames: ["Resultados obtenidos:"],
               customTooltip: false,
               borderRadius: true,
               yTitle: "Resultados obtenidos",
               xTitle: "Órganos de gobierno",
               gridColor: "whitesmoke",
               tension: 0.3,
               scores: [
                  governing.filter((titles) => titles.title !== "Asamblea de accionistas").map((gov) => gov.score * 10),
               ],
               maxY: 100,
               onClick: (e, i) => {
                  if (i.length === 0) {
                     return;
                  }
                  setSelected(governing.filter((gov) => gov.title !== "Asamblea de accionistas")[i[0].index]);
               },
            })}
         </Box>
      </Stack>
   );
};

const MemberEvaluationModal = ({
   member,
   open,
   setOpen,
   current,
}: {
   member: any;
   open: boolean;
   setOpen: Function;
   current?: boolean;
}) => {
   const [membersInfo, setMembersInfo] = useState(null);
   const [loadingInfo, setLoadingInfo] = useState(true);
   const [openCommentary, setOpenCommentary] = useState(false);
   const { configInfo, lastEvaluationInfo, evaluationCurrent } = useContext(EvaluationConfigContext);

   const fetchMemberData = useCallback(async () => {
      if (!member) return;
      setLoadingInfo(true);
      const charge = await getChargeById(member.charge);
      let period = null;
      let scores = null;
      if (current) {
         period = { startDate: evaluationCurrent.startTime, endDate: evaluationCurrent.endTime };
         scores = await getScoresByMember(member.user, evaluationCurrent._id);
      } else {
         period = { startDate: lastEvaluationInfo.startTime, endDate: lastEvaluationInfo.endTime };
         scores = await getScoresByMember(member.user, lastEvaluationInfo._id);
      }
      setMembersInfo({ ...member, charge: charge, period, scores });
      setLoadingInfo(false);
   }, [member]);

   useEffect(() => {
      fetchMemberData();
   }, [fetchMemberData]);

   return (
      <Modal
         open={open}
         onClose={() => setOpen(false)}
         sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
         <Stack sx={{ bgcolor: "white", borderRadius: 1, p: 2, maxWidth: 1200, rowGap: 2 }}>
            <Box sx={{ display: "flex", justifyContent: "flex-start" }}>
               <Button onClick={() => setOpen(null)}>
                  <ArrowBack />
                  Volver a evaluación
               </Button>
            </Box>
            {!loadingInfo ? (
               <>
                  <Stack sx={{ boxShadow: 1, p: 1 }}>
                     <Grid container spacing={1}>
                        <Grid item xs={12}>
                           <Typography>
                              Nombre del miembro: {membersInfo.firstName} {membersInfo.lastName}
                           </Typography>
                        </Grid>
                        <Grid item xs={6}>
                           <Typography>Cargo: {membersInfo.charge.chargeName}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                           <Typography>Evaluación de desempeño: {configInfo.evaluationNumber}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                           <Typography>Fecha de registro: {getDateFromISODate(membersInfo.createdAt)}</Typography>
                        </Grid>
                        <Grid item xs={6}>
                           <Typography>
                              Periodo evaluado:{" "}
                              {`${getDateFromISODate(membersInfo.period.startDate)} - ${getDateFromISODate(
                                 membersInfo.period.endDate
                              )}`}
                           </Typography>
                        </Grid>
                     </Grid>
                  </Stack>
                  <Stack sx={{ boxShadow: 1, p: 1 }}>
                     <Grid container spacing={3}>
                        <Grid item xs={12}>
                           <Typography fontWeight={600}>Gráfica de resultados de evaluación</Typography>
                           <Typography>
                              El diagrama muestra el resultado de puntuaciones dadas por cada miembro de evaluadores a
                              las diferentes competencias.
                           </Typography>
                        </Grid>
                        <Grid item xs={2}>
                           <PieChart
                              scores={membersInfo.scores.map((score) => score.score)}
                              labels={membersInfo.scores.map((score) => score.title)}
                              colors={membersInfo.scores.map((score) => score.color)}
                              chartRef={undefined}
                              cutout={0}
                           />
                        </Grid>
                        <Grid item container xs={10}>
                           {membersInfo.scores.map((score) => (
                              <ScoreComponent
                                 color={score.color}
                                 text={score.title}
                                 value={score.score}
                                 key={`${score.title}-${score.score}`}
                              />
                           ))}
                        </Grid>
                        <Grid item xs={12}>
                           <Box sx={{ p: 2 }}>
                              <Button
                                 sx={{
                                    bgcolor: "#E5E6EB",
                                    color: "#152C44",
                                    borderColor: "#F0F0F0",
                                    "&:hover": {
                                       bgcolor: "#E5E6EB",
                                    },
                                 }}
                                 variant="contained"
                                 onClick={() => setOpenCommentary(true)}
                                 fullWidth
                              >
                                 Comentarios adicionales
                              </Button>
                           </Box>
                        </Grid>
                     </Grid>
                     <CommentaryModal
                        openCommentary={openCommentary}
                        setOpenCommentary={setOpenCommentary}
                        member={membersInfo.user}
                        general={null}
                     />
                  </Stack>
               </>
            ) : (
               <CircularProgress />
            )}
         </Stack>
      </Modal>
   );
};

const CommentaryModal = ({ openCommentary, setOpenCommentary, member, general }) => {
   const [commentaries, setCommentaries] = useState([]);
   const { evaluationCurrent } = useContext(EvaluationConfigContext);

   const fetchCommentariesMember = useCallback(async () => {
      if (!member) return;
      const response = await getCommentariesByMember(member, evaluationCurrent._id);
      setCommentaries(response);
   }, [member]);

   useEffect(() => {
      fetchCommentariesMember();
   }, [fetchCommentariesMember]);

   const fetchCommentariesGov = useCallback(async () => {
      if (!general) return;
      const response = await getCommentariesByGov(general, evaluationCurrent._id);
      setCommentaries(response);
   }, [general]);

   useEffect(() => {
      fetchCommentariesGov();
   }, [fetchCommentariesGov]);

   return (
      <CustomModalComponent
         open={openCommentary}
         setOpen={setOpenCommentary}
         onClose={() => setOpenCommentary(false)}
         title="Comentarios"
      >
         <Stack sx={{ p: 2, maxHeight: 400, minWidth: 300, overflowY: "auto" }}>
            {commentaries && commentaries.length > 0 ? (
               commentaries.map((comment) => (
                  <>
                     <Typography>{comment.answer}</Typography>
                     <Divider />
                  </>
               ))
            ) : (
               <Typography>Sin comentarios</Typography>
            )}
         </Stack>
      </CustomModalComponent>
   );
};

const ScoreComponent = ({
   text,
   color,
   empty,
   value,
}: {
   text: string;
   color: string;
   empty?: boolean;
   value?: number;
}) => {
   return (
      <Grid xs={6}>
         <Stack sx={{ py: 0.5 }}>
            <Box sx={{ border: 1, borderColor: "whitesmoke" }}>
               <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ display: "flex", columnGap: 1, alignItems: "center", pl: 2 }}>
                     <FiberManualRecord sx={{ color: color, fontSize: 18 }} />
                     <Typography align="center" variant="caption">
                        {text}
                     </Typography>
                  </Box>

                  <Stack
                     direction={"row"}
                     sx={{ justifyContent: "center", alignItems: "center", columnGap: 1, px: 1, opacity: empty && 0 }}
                  >
                     <Box sx={{ bgcolor: "whitesmoke", border: 1, borderRadius: 2, borderColor: "gray", p: 1 }}>
                        <Typography variant="body2">{(value * 100).toFixed(2)}</Typography>
                     </Box>
                     <Tooltip title="Calificación obtenida en base a la media de las respuestas del 0 - 6.">
                        <Info fontSize="small" color="secondary" />
                     </Tooltip>
                  </Stack>
               </Box>
            </Box>
         </Stack>
      </Grid>
   );
};
