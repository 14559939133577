import { Box, Drawer } from "@mui/material";
import { HeadCell, TableComponent } from "../TableComponent";
import { useContext, useEffect, useState } from "react";
import { ModifyAditionalContact, getContactInfoByCompanyId } from "../../lib/usersBEClient";
import { AddAditionalsContacts } from "../Drawers/AddAditionalsContacts";
import { SnackBarContext } from "../../context/snackBarContext";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { UserContext } from "../../context/userContext";

export const AdditionalContacts = () => {
   const [contacts, setContacts] = useState([]);
   const [openDrawer, setOpenDrawer] = useState(false);
   const { showSnackBar } = useContext(SnackBarContext);
   const { companySelected } = useContext(UserContext);
   const [contactInfo, setContactInfo] = useState(null);
   const { mutate, isLoading } = ModifyAditionalContact(contactInfo ? contactInfo._id : "");

   const branchesColumns = (): HeadCell[] => {
      const headers: HeadCell[] = [
         { field: "firstName", headerName: "Nombre(s)", type: "string", width: 50 },
         { field: "lastName", headerName: "Apellido(s)", type: "string", width: 50 },
         { field: "email", headerName: "Correo electrónico", type: "string", width: 50 },
         { field: "phoneNumber", headerName: "Teléfono", type: "string", width: 50 },
         {
            field: "modify",
            headerName: "Modificar",
            type: "button",
            width: 50,
            icon: <BorderColorIcon fontSize="small" />,
            onClick: (e, row) => {
               setContactInfo(row);
               setOpenDrawer(true);
            },
         },
      ];

      return headers;
   };
   const fetchInfo = async () => {
      if (companySelected?._id) {
         const data = await getContactInfoByCompanyId(companySelected._id);
         setContacts(data);
      }
   };

   useEffect(() => {
      fetchInfo();
      // eslint-disable-next-line
   }, [companySelected]);

   const createAditionalContact = (values) => {
      mutate(
         {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            phoneNumber: values.phoneNumber,
         },
         {
            onError: (error: any) => {
               showSnackBar("Error al agregar contacto a la empresa.", true);
            },
            onSuccess: async () => {
               showSnackBar("El contacto fue agregado correctamente.", false);
               fetchInfo();
               setOpenDrawer(false);
            },
         }
      );
   };

   return (
      <Box sx={{ bgcolor: "white", width: "100%", borderRadius: 1 }}>
         <TableComponent
            defaultColumnToOrder="name"
            defaultOrder="asc"
            defaultRowsPerPage={5}
            emptyDataText="No hay contactos adicionales por el momento"
            rowsPerPageOptions={[5, 10, 15, 20]}
            loader={false}
            headers={branchesColumns()}
            rows={contacts}
            onClick={() => {}}
            disableBorders
         />
         <Drawer
            anchor={"right"}
            open={openDrawer}
            hideBackdrop={true}
            onClose={() => setOpenDrawer(false)}
            sx={{ maxWidth: 450 }}
         >
            <AddAditionalsContacts
               setDrawerOpen={setOpenDrawer}
               open={openDrawer}
               onConfirm={createAditionalContact}
               isLoading={isLoading}
               isModify={true}
               contactInfo={contactInfo}
            />
         </Drawer>
      </Box>
   );
};
