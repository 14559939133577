import { Box, Button, Divider, IconButton, Typography } from "@mui/material";
import { ShortEventViewer } from "./ShortEventViewer";
import { IEventsS } from "../SchedulerComponent";
import { getContrastYIQ } from "../../../../const/globalConst";
import { useContext, useState } from "react";
import { UpdateStatusUserById } from "../../../../lib/gobCorpBEClient";
import { UserContext } from "../../../../context/userContext";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { ConfirmationModal } from "../../../ConfirmationModal";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";
import { Close } from "@mui/icons-material";

interface IPropsEventViewer {
   event: IEventsS;
   handleOpenModal?: (event: IEventsS, type: number) => void;
   handleClose?: (event: IEventsS, type: number) => void;
   modal?: boolean;
   users?: any[];
}

export const EventViewer = (props: IPropsEventViewer) => {
   const [openModal, setOpenModal] = useState(false);
   const { user } = useContext(UserContext);
   const { setRefetch } = useContext(GovernanceContext);
   const [values, setValues] = useState("");
   const { showSnackBar } = useContext(SnackBarContext);
   const { mutate: modifyStatus } = UpdateStatusUserById(user.id, props.event.event_id);
   const today = new Date();
   const isInvited = props.event?.usersRegistry?.some((u) => u.user === user.id) && props?.event?.start > today;

   const handleOpenModal = (value) => {
      setValues(value);
      setOpenModal(true);
   };

   const handleModifyStatus = () => {
      modifyStatus(
         {
            status: values,
         },
         {
            onSuccess: async () => {
               showSnackBar("Su respuesta ha sido registrada", false);
               setOpenModal(false);
               props.handleClose && props.handleClose(props.event, 0);
               setRefetch((r) => !r);
            },
            onError: (_error: any) => {
               showSnackBar("Error al registrar respuesta", true);
               return null;
            },
         }
      );
   };

   return (
      <Box sx={{ bgcolor: "white" }}>
         <Divider sx={{ bgcolor: props.event.color, height: 10 }} />
         {/* {props.modal && (
            <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
               <IconButton
                  onClick={() => {
                     props.handleClose(props.event, 0);
                  }}
               >
                  <Close sx={{ color: "#162c44" }} />
               </IconButton>
            </Box>
         )} */}
         <Box
            sx={{
               px: !props.modal ? 2 : 5,
               py: !props.modal ? 1 : 2,
               display: "flex",
               flexDirection: "column",
               maxWidth: !props.modal ? 400 : "inherit",
               maxHeight: 600,
               overflowY: "auto",
            }}
         >
            <ShortEventViewer {...props} users={props.users} />
            {!props.modal && (
               <Box
                  sx={{
                     display: "flex",
                     columnGap: 2,
                     justifyContent: "flex-end",
                     py: 1,
                  }}
               >
                  <Button
                     variant="contained"
                     size="small"
                     sx={{
                        bgcolor: "#E0E0E0",
                        color: "black",
                        boxShadow: 0,
                        ":hover": { color: getContrastYIQ(props.event.color), bgcolor: props.event.color },
                     }}
                     onClick={() => props.handleOpenModal(props.event, 1)}
                  >
                     Ver más detalles
                  </Button>
               </Box>
            )}
         </Box>
         {props.modal && (
            <Box
               sx={{
                  bgcolor: "#E9EDF5",
                  p: 1,
                  px: 2,
                  display: "flex",
                  justifyContent: "center",
                  width: 700,
               }}
            >
               {isInvited && <SessionAssistance handleOpenModal={handleOpenModal} />}
            </Box>
         )}
         <ConfirmationModal
            open={openModal}
            onConfirm={handleModifyStatus}
            setOpen={setOpenModal}
            title={"Confirmación de asistencia"}
            buttonText={"Aceptar"}
            body={
               <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                  <Typography align="center">{`Al aceptar, se enviara la confirmación de ${
                     values === "APPROVED" ? "asistencia" : "inasistencia"
                  } a la ${props?.event?.assembly ? "asamblea" : "sesión"} `}</Typography>
               </Box>
            }
         />
      </Box>
   );
};

const SessionAssistance = ({ handleOpenModal }) => {
   return (
      <Box
         sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            columnGap: 2,
         }}
      >
         <Typography sx={{ fontSize: 14, fontWeight: 600 }}>¿Asistirás?</Typography>
         <Box
            sx={{
               display: "flex",
               columnGap: 2,
            }}
         >
            <Button
               onClick={() => {
                  handleOpenModal("APPROVED");
               }}
               sx={{
                  fontSize: 14,
                  maxWidth: 20,
                  maxHeight: 30,
                  "&:hover": {
                     bgcolor: "#162c44",
                     color: "white",
                     boxShadow: 0,
                  },
               }}
            >
               Sí
            </Button>
            <Button
               onClick={() => {
                  handleOpenModal("CANCELLED");
               }}
               sx={{
                  fontSize: 14,
                  maxWidth: 20,
                  maxHeight: 30,

                  "&:hover": {
                     bgcolor: "#162c44",
                     color: "white",
                     boxShadow: 0,
                  },
               }}
            >
               No
            </Button>
         </Box>
      </Box>
   );
};
