import { Box, Button, Grid, Typography, InputAdornment, Divider, Container, CircularProgress } from "@mui/material";
import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import StoreIcon from "@mui/icons-material/Store";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import LocationCityIcon from "@mui/icons-material/LocationCity";
import { resolutionNotification } from "../../../../lib/documentsTemplates/notificationTemplates";
import { NotificationDocumentsBar } from "../../../../components/notificationsDocumentsBar";
import { Form, Formik } from "formik";
import { InputTextField } from "../../../../components/Inputs/InputTextField";
import { resolutionNotificationSchema } from "../../../../lib/validations/inputSchemas";
import { createDocumentPdf } from "../../../../lib/uploadDocuments";
import { SnackBarContext } from "../../../../context/snackBarContext";
import pdfMake from "../../../../const/pdfMakeFonts";
import { toISOString } from "../../../../const/globalConst";
import { ComplaintStepperContext } from "../../../../context/complaintStepperContext";

export const ResolutionNotificaionScreen = () => {
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const { showSnackBar } = useContext(SnackBarContext);
   const { complaint } = useContext(ComplaintStepperContext);

   const date = new Date();
   const navigate = useNavigate();

   const handleSendForm = (values, actions) => {
      const necesaryDocuments = complaint.documentation.find(
         (doc) => doc.name === `notificacion-resolucion-${complaint.type || "denuncia"}-${complaint.reportNumber}.pdf`
      );
      if (!necesaryDocuments) {
         const dd = resolutionNotification(
            complaint.reportNumber,
            complaint.clasification,
            values.city,
            values.state,
            values.dateTime,
            complaint.resolution ? complaint.resolution : values.measures,
            complaint.complaintType === "Quejas" ? true : false,
            complaint.newClasification ? complaint.newClasification : "",
            complaint.type || "denuncia"
         );
         const pdfDocGenerator = pdfMake.createPdf(dd);
         pdfDocGenerator.getBlob(async (blob) => {
            await createDocumentPdf(
               complaint.reportNumber,
               `notificacion-resolucion-${complaint.type || "denuncia"}-${complaint.reportNumber}`,
               blob
            );
            showSnackBar("Documento creado y subido correctamente.", false);
            setTimeout(() => navigate(-1), 1000);
         });
      } else {
         showSnackBar("Este documento ya fue enviado.", true);
         setTimeout(() => navigate(-1), 1000);
      }
   };

   return (
      <Box p={2}>
         <NotificationDocumentsBar
            head={`Notificación de resolución de ${complaint.type || "denuncia"}`}
            isScheduleAppointment={false}
         />
         <Container maxWidth="xl">
            <Formik
               initialValues={{ city: "", state: "", measures: "", dateTime: toISOString(date) }}
               validationSchema={resolutionNotificationSchema(complaint.resolution ? false : true)}
               onSubmit={(values, action) => {
                  setIsLoading(true);
                  handleSendForm(values, action);
               }}
            >
               <Form>
                  <Box
                     display={"flex"}
                     sx={{ px: 4, py: 2, minWidth: 950, flexDirection: "column", alignItems: "center", m: 2 }}
                  >
                     <Box
                        sx={{
                           width: "100%",
                           backgroundColor: "#FFFFFF",
                           border: 1,
                           borderRadius: 2,
                           borderColor: "#8A95A0",
                        }}
                     >
                        <Grid container>
                           <Grid item xs={6}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={500} variant="body1">
                                    {`Reporte de ${complaint.type || "denuncia"}`}
                                 </Typography>
                                 <Typography variant="subtitle1">{complaint.reportNumber}</Typography>
                              </Box>
                           </Grid>
                           <Grid item xs={6}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <Typography fontWeight={500} variant="body1">
                                    {`Clasificación de la ${complaint.type || "denuncia"}`}
                                 </Typography>
                                 <Typography variant="subtitle1">
                                    {(complaint.type || "denuncia") !== "denuncia"
                                       ? "No aplica"
                                       : complaint.clasification}
                                 </Typography>
                              </Box>
                           </Grid>
                        </Grid>
                        <Divider sx={{ mx: 2 }} />

                        <Grid container>
                           <Grid item xs={3}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <InputTextField
                                    variant="standard"
                                    label="Fecha y hora"
                                    fullWidth
                                    name="dateTime"
                                    id="dateTime"
                                    type="datetime-local"
                                    InputLabelProps={{
                                       shrink: true,
                                    }}
                                    sx={{ mt: 1 }}
                                 />
                              </Box>
                           </Grid>
                           <Grid item xs={9} />
                           <Grid item xs={6}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <InputTextField
                                    id="state"
                                    name="state"
                                    type="text"
                                    variant="standard"
                                    label="Estado"
                                    fullWidth={true}
                                    InputProps={{
                                       startAdornment: (
                                          <InputAdornment position="start">
                                             <LocationOnIcon />
                                          </InputAdornment>
                                       ),
                                    }}
                                    sx={{ ml: 1 }}
                                 />
                              </Box>
                           </Grid>
                           <Grid item xs={6}>
                              <Box display={"flex"} sx={dataFieldSX}>
                                 <InputTextField
                                    id="city"
                                    name="city"
                                    type="text"
                                    variant="standard"
                                    label="Ciudad"
                                    fullWidth={true}
                                    InputProps={{
                                       startAdornment: (
                                          <InputAdornment position="start">
                                             <LocationCityIcon />
                                          </InputAdornment>
                                       ),
                                    }}
                                 />
                              </Box>
                           </Grid>
                           {complaint.resolution ? null : (
                              <Grid item xs={12}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       id="measures"
                                       name="measures"
                                       type="text"
                                       variant="standard"
                                       label="Medidas"
                                       fullWidth={true}
                                       InputProps={{
                                          startAdornment: (
                                             <InputAdornment position="start">
                                                <StoreIcon />
                                             </InputAdornment>
                                          ),
                                       }}
                                       sx={{ mx: 1 }}
                                    />
                                 </Box>
                              </Grid>
                           )}
                        </Grid>
                     </Box>
                     <Box display={"flex"} sx={{ width: "100%", justifyContent: "flex-end" }}>
                        <Button variant="contained" sx={{ mt: 2 }} type="submit">
                           {!isLoading ? "Enviar" : <CircularProgress size={24} sx={{ color: "white" }} />}
                        </Button>
                     </Box>
                  </Box>
               </Form>
            </Formik>
         </Container>
      </Box>
   );
};

const dataFieldSX = {
   alignItems: "center",
   justifyContent: "center",
   flexDirection: "column",
   py: 2,
   px: 2,
};
