import { CalendarToday } from "@mui/icons-material";
import { Box, IconButton, Modal, Tooltip, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { FilesContext } from "../../../../context/governanceContext/filesContext";
import { DateModal } from "../../NotesScreen/DateModal";

export const DateFilter = () => {
   const { endDate, setEndDate, setStartDate, startDate, setConfirmModal } = useContext(FilesContext);
   const [openModal, setOpenModal] = useState(false);

   const handleClose = () => {
      setOpenModal(false);
   };

   return (
      <>
         <Box
            sx={{
               display: "flex",
               border: 1,
               borderRadius: 1,
               borderColor: "#C4C4C4",
               color: "#F5F5F5",
               height: 40,
               bgcolor: "white",
            }}
         >
            <Typography
               sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "#868FA0",
                  fontSize: "16px",
                  columnGap: 1,
                  px: 1,
               }}
            >
               Seleccionar fechas
               <IconButton>
                  <Tooltip
                     title=""
                     onClick={() => {
                        setOpenModal(true);
                     }}
                  >
                     <CalendarToday sx={{ fontSize: 20 }} />
                  </Tooltip>
               </IconButton>
            </Typography>
         </Box>
         <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
         >
            <DateModal
               setOpen={handleClose}
               finishDate={endDate}
               setConfirmModal={setConfirmModal}
               setFinishDate={setEndDate}
               setStartDate={setStartDate}
               startDate={startDate}
            />
         </Modal>
      </>
   );
};
