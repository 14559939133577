import ReactQuill from "react-quill";
import EditorToolBarList from "./EditorToolBarList";
import { modules, formats } from "./EditorToolbar";
import "./styles.css";
import { Box } from "@mui/material";

export const TextEditor = (props: { text: any; setText: any }) => {
   const handleChange = (value) => {
      props.setText(value.toString());
   };
   return (
      <Box sx={{ bgcolor: "white", maxWidth: 700 }}>
         <div className="text-editor">
            <EditorToolBarList />
            <ReactQuill
               theme="snow"
               value={props.text}
               onChange={handleChange}
               placeholder={""}
               modules={modules}
               formats={formats}
            />
         </div>
      </Box>
   );
};

export default TextEditor;
