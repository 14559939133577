import { useState, useContext, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { addResolution } from "../../../../lib/lecosyBackendClient";
import {
   Box,
   Button,
   Grid,
   MenuItem,
   Typography,
   InputAdornment,
   Divider,
   Container,
   ListItemText,
   CircularProgress,
} from "@mui/material";
import StoreIcon from "@mui/icons-material/Store";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { complaintResolution } from "../../../../lib/documentsTemplates/acknowledgmentTemplates";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import RateReviewIcon from "@mui/icons-material/RateReview";
import { WitnessInputComponent } from "../../../../components/ComplainantAndWitnessInputs";
import { Form, Formik } from "formik";
import { resolutionScreenSchema, sugestionResolutionSchema } from "../../../../lib/validations/inputSchemas";
import { InputTextField } from "../../../../components/Inputs/InputTextField";
import { InputSelectField } from "../../../../components/Inputs/InputSelectField";
import { NotificationDocumentsBar } from "../../../../components/notificationsDocumentsBar";
import { SnackBarContext } from "../../../../context/snackBarContext";
import pdfMake from "../../../../const/pdfMakeFonts";
import { toISOString } from "../../../../const/globalConst";
import { ComplaintStepperContext } from "../../../../context/complaintStepperContext";
import { UserContext } from "../../../../context/userContext";
import { GetSubUsersByComplaintBranch } from "../../../../lib/usersBEClient";

const clasificationsOptions = [
   "Posible comisión de un hecho delictivo",
   "Violación de derechos humanos",
   "Violación a las políticas internas y códigos de ética de la organización",
   "No es violatoria a ningún hecho contrario a la ley o a las políticas de la organización",
];

interface WitnessInputType {
   name: string;
   facts: string;
   proof: string;
}

export const ResolutionScreen = () => {
   const navigate = useNavigate();
   const { showSnackBar } = useContext(SnackBarContext);
   const { complaint } = useContext(ComplaintStepperContext);
   const { user } = useContext(UserContext);
   const { data: users, isLoading: loadingUsers } = GetSubUsersByComplaintBranch(
      complaint.company || complaint.companyBranch._id,
      !!complaint.company,
      user.group?._id || undefined
   );
   const [witnessData, setWitnessData] = useState<WitnessInputType[]>([]);
   const [offenderData, setOffenderData] = useState<WitnessInputType[]>([]);
   const date = new Date();

   const handleSendForm = async (values, actions) => {
      if (complaint.witnesses.length > 0 && witnessData.length < complaint.witnesses.length) {
         showSnackBar("Faltan datos del o los testigo/s.", true);
         return null;
      }
      if (complaint.offenderDetails.length > 0 && offenderData.length < complaint.offenderDetails.length) {
         showSnackBar("Faltan datos del o los denunciado/s.", true);
         return null;
      }

      const dd = complaintResolution(
         complaint.reportNumber,
         complaint.complainerDetails
            ? complaint.complainerDetails.name + " " + complaint.complainerDetails.lastName
            : "Denunciante anónimo",
         complaint.category,
         values.complainantStatedFacts,
         values.complainantEvidence,
         values.clasificationComplaint,
         values.fundamentals,
         values.measures,
         values.dateTime,
         values.organization,
         complaint.complaintType === "Quejas",
         `${user.firstName} ${user.lastName}`,
         values.auditor,
         values.director,
         witnessData,
         offenderData,
         complaint.type || "denuncia",
         values.address
      );
      try {
         await addResolution(complaint.reportNumber, values.measures, values.clasificationComplaint);
         showSnackBar("Datos correctos", false);
         actions.resetForm();
      } catch (error) {
         showSnackBar("Hubo un error, favor de volver a intentarlo", true);
      }
      const pdfDocGenerator = pdfMake.createPdf(dd);
      pdfDocGenerator.open();
      setTimeout(() => navigate(-1), 1000);
   };

   const handleGetDirAudUsers = useCallback(
      (competent: boolean) => {
         const cumUsersnt = users.filter((user: any) => {
            const groupRole = user.role.filter((role) => role.group);
            const hasCdRole = groupRole.some((role) => {
               return role.roles.some((role) => (competent ? role.name === "Director" : role.name === "Auditor"));
            });
            if (hasCdRole) return true;
            for (const userRole of user.role) {
               if (userRole.company === complaint.company || complaint.companyBranch._id) {
                  const hasDirAudRole = userRole.roles.some((uniqueRole) => {
                     return competent ? uniqueRole.name === "Director" : uniqueRole.name === "Auditor";
                  });
                  if (hasDirAudRole) return true;
               }
            }
         });
         return cumUsersnt;
      },
      [users]
   );

   const validationSchema =
      !complaint?.type || complaint?.type === "denuncia" ? resolutionScreenSchema : sugestionResolutionSchema;
   return (
      <Box p={2}>
         <NotificationDocumentsBar
            head={`Acuse de resolución de la ${complaint.type || "denuncia"}`}
            isScheduleAppointment={false}
         />
         <Container maxWidth="xl">
            {!loadingUsers ? (
               <Formik
                  initialValues={{
                     complainantStatedFacts: "",
                     organization: "",
                     address: "",
                     complainantEvidence: "",
                     clasificationComplaint: "",
                     fundamentals: "",
                     measures: "",
                     dateTime: toISOString(date),
                     auditor: "",
                     director: "",
                  }}
                  validationSchema={validationSchema}
                  validateOnChange={false}
                  onSubmit={handleSendForm}
               >
                  <Form>
                     <Box
                        display={"flex"}
                        sx={{ px: 4, py: 2, minWidth: 950, flexDirection: "column", alignItems: "center", m: 2 }}
                     >
                        <Box
                           sx={{
                              width: "100%",
                              backgroundColor: "#FFFFFF",
                              border: 1,
                              borderRadius: 2,
                              borderColor: "#8A95A0",
                           }}
                        >
                           <Grid container sx={{ py: 2 }}>
                              <Grid item xs={3}>
                                 <Box
                                    display={"flex"}
                                    sx={{
                                       alignItems: "center",
                                       justifyContent: "center",
                                       flexDirection: "column",
                                       py: 2,
                                    }}
                                 >
                                    <Typography fontWeight={500} variant="body1">
                                       {`Reporte de ${complaint.type || "denuncia"}`}
                                    </Typography>
                                    <Typography variant="subtitle1">{complaint.reportNumber}</Typography>
                                 </Box>
                              </Grid>
                              <Grid item xs={6}>
                                 <Box
                                    display={"flex"}
                                    sx={{
                                       alignItems: "center",
                                       justifyContent: "center",
                                       flexDirection: "column",
                                       py: 2,
                                    }}
                                 >
                                    {(complaint.type || "denuncia") === "denuncia" && (
                                       <>
                                          <Typography fontWeight={500} variant="body1">
                                             Clasificación
                                          </Typography>
                                          <Typography variant="subtitle1">{complaint.clasification}</Typography>
                                       </>
                                    )}
                                 </Box>
                              </Grid>
                              <Grid item xs={3} sx={{ display: "flex", alignItems: "center", pr: 2 }}>
                                 <InputTextField
                                    variant="standard"
                                    fullWidth
                                    name="dateTime"
                                    id="dateTime"
                                    label="Fecha y hora"
                                    type="datetime-local"
                                    InputLabelProps={{
                                       shrink: true,
                                    }}
                                 />
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container>
                              <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                 <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                    Datos de la empresa
                                 </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       name="organization"
                                       label="Empresa"
                                       id="organization"
                                       type="text"
                                       fullWidth={true}
                                       InputProps={{
                                          startAdornment: (
                                             <InputAdornment position="start">
                                                <StoreIcon />
                                             </InputAdornment>
                                          ),
                                       }}
                                    />
                                 </Box>
                              </Grid>
                              <Grid item xs={6}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       name="address"
                                       label="Dirección"
                                       id="address"
                                       type="text"
                                       fullWidth={true}
                                       InputProps={{
                                          startAdornment: (
                                             <InputAdornment position="start">
                                                <LocationOnIcon />
                                             </InputAdornment>
                                          ),
                                       }}
                                    />
                                 </Box>
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container>
                              <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                 <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                    {`Relación de hechos y pruebas del ${
                                       (complaint?.type || "denuncia") !== "denuncia" ? "interpositor" : "denunciante"
                                    }`}
                                 </Typography>
                              </Grid>
                              <Grid item xs={12}>
                                 <Box display={"flex"} sx={{ px: 4, display: "flex", alignItems: "center" }}>
                                    <Typography fontWeight={500} variant="body1" sx={{ textAlign: "center" }}>
                                       Nombre:&nbsp;
                                    </Typography>
                                    <Typography variant="subtitle1">
                                       {complaint.complaintType === "anonima"
                                          ? "Anónimo"
                                          : `${complaint.complainerDetails?.name} ${complaint.complainerDetails?.lastName}`}
                                    </Typography>
                                 </Box>
                              </Grid>
                              <Grid item xs={6}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       name="complainantStatedFacts"
                                       label="Hechos declarados"
                                       id="complainantStatedFacts"
                                       type="text"
                                       fullWidth={true}
                                       InputProps={{
                                          startAdornment: (
                                             <InputAdornment position="start">
                                                <RateReviewIcon />
                                             </InputAdornment>
                                          ),
                                       }}
                                    />
                                 </Box>
                              </Grid>
                              <Grid item xs={6}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       name="complainantEvidence"
                                       label="Pruebas otorgadas"
                                       id="complainantEvidence"
                                       type="text"
                                       fullWidth={true}
                                       InputProps={{
                                          startAdornment: (
                                             <InputAdornment position="start">
                                                <StickyNote2Icon />
                                             </InputAdornment>
                                          ),
                                       }}
                                    />
                                 </Box>
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           {complaint.type === "denuncia" && (
                              <>
                                 <Grid container>
                                    <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                       <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                          Relación de hechos y pruebas del testigo
                                       </Typography>
                                    </Grid>
                                    {complaint.witnesses.map((e, i) => {
                                       return (
                                          <WitnessInputComponent
                                             e={e}
                                             data={witnessData}
                                             setData={setWitnessData}
                                             index={i}
                                          />
                                       );
                                    })}
                                 </Grid>
                                 <Divider sx={{ mx: 2 }} />
                              </>
                           )}

                           {complaint.type === "denuncia" && (
                              <>
                                 <Grid container>
                                    <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                       <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                          Relación de hechos y pruebas del denunciado
                                       </Typography>
                                    </Grid>
                                    {complaint.offenderDetails.map((e, i) => {
                                       return (
                                          <WitnessInputComponent
                                             e={e}
                                             data={offenderData}
                                             setData={setOffenderData}
                                             index={i}
                                          />
                                       );
                                    })}
                                 </Grid>
                                 <Divider sx={{ mx: 2 }} />
                              </>
                           )}
                           <Grid container>
                              <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                 <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                    Resolución
                                 </Typography>
                              </Grid>
                              {(!complaint.type || complaint.type === "denuncia") && (
                                 <Grid item xs={4}>
                                    <Box display={"flex"} sx={{ p: 2 }}>
                                       <InputSelectField
                                          labelId="state-select-label"
                                          id="clasification"
                                          name="clasificationComplaint"
                                          label="La denuncia se considera como"
                                          variant="standard"
                                          fullWidth={true}
                                       >
                                          {clasificationsOptions.map((a) => (
                                             <MenuItem value={a} key={a}>
                                                {a}
                                             </MenuItem>
                                          ))}
                                       </InputSelectField>
                                    </Box>
                                 </Grid>
                              )}
                              <Grid item xs={8} sx={{ margin: complaint.type !== "denuncia" ? "0 auto" : "initial" }}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       name="fundamentals"
                                       label="Fundamentos"
                                       id="fundamentals"
                                       type="text"
                                       fullWidth={true}
                                    />
                                 </Box>
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container>
                              <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                 <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                    Medidas
                                 </Typography>
                              </Grid>
                              <Grid item xs={2} />
                              <Grid item xs={8}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       name="measures"
                                       label="Medidas"
                                       id="measures"
                                       type="text"
                                       fullWidth={true}
                                    />
                                 </Box>
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container>
                              <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                 <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                    Auditor
                                 </Typography>
                              </Grid>
                              <Grid item xs={2} />
                              <Grid item xs={8}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputSelectField
                                       id="auditor"
                                       name="auditor"
                                       label="Nombre"
                                       labelId="auditor"
                                       variant={"standard"}
                                       fullWidth
                                    >
                                       {handleGetDirAudUsers(false).map((e: any) => (
                                          <MenuItem key={e.firstName} value={`${e.firstName} ${e.lastName}`}>
                                             <ListItemText primary={`${e.firstName} ${e.lastName}`} />
                                          </MenuItem>
                                       ))}
                                    </InputSelectField>
                                 </Box>
                              </Grid>
                           </Grid>
                           <Grid container>
                              <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                 <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                    Director
                                 </Typography>
                              </Grid>
                              <Grid item xs={2} />
                              <Grid item xs={8}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputSelectField
                                       id="director"
                                       name="director"
                                       label="Nombre"
                                       labelId="director"
                                       variant={"standard"}
                                       fullWidth
                                    >
                                       {handleGetDirAudUsers(true).map((e: any) => (
                                          <MenuItem key={e.firstName} value={`${e.firstName} ${e.lastName}`}>
                                             <ListItemText primary={`${e.firstName} ${e.lastName}`} />
                                          </MenuItem>
                                       ))}
                                    </InputSelectField>
                                 </Box>
                              </Grid>
                           </Grid>
                        </Box>
                        <Box display={"flex"} sx={{ width: "100%", justifyContent: "flex-end" }}>
                           <Button variant="contained" sx={{ mt: 2 }} type="submit">
                              Enviar
                           </Button>
                        </Box>
                     </Box>
                  </Form>
               </Formik>
            ) : (
               <Box display={"flex"} width={"100%"} justifyContent={"center"}>
                  <CircularProgress size={24} />
               </Box>
            )}
         </Container>
      </Box>
   );
};

const dataFieldSX = {
   alignItems: "center",
   justifyContent: "center",
   flexDirection: "column",
   py: 2,
   px: 4,
};
