import { Chart as ChartJS, LinearScale, BarElement, Title, Tooltip, Legend, Filler } from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { externalTooltipHandler } from "../../hooks/useCreateCharts";
import { quickSort } from "../../const/globalConst";

ChartJS.register(LinearScale, BarElement, ChartDataLabels, Title, Tooltip, Legend, Filler);

export const StackBarChart = (props) => {
   return (
      <Bar
         ref={props.chartRef}
         data={{
            datasets: props.scores.map((e: [], index) => {
               return {
                  label: props.labelNames[index],
                  data: e,
                  backgroundColor: props.colors[index],
                  borderRadius: props.borderRadius ? (props.borderRadiusValue ? props.borderRadiusValue : 4) : 0,
                  barThickness: props.barThickness ? props.barThickness : undefined,
                  hoverBackgroundColor: props.onClick ? `${props.colors[index]}80` : props.colors[index],
               };
            }),
            labels: props.labels,
         }}
         options={{
            indexAxis: props.indexAxis,
            onHover: props.onClick
               ? (event: any, chartElement) => {
                    event.native.target.style.cursor = chartElement[0] ? "pointer" : "default";
                 }
               : undefined,
            interaction: props.intersection ? { intersect: false, mode: "index" } : { mode: "point" },
            onClick: props.onClick,

            plugins: {
               datalabels: {
                  anchor: "end",
                  align: "top",
                  font: {
                     weight: "bold",
                     size: 14,
                     style: "normal",
                  },
                  color: "#8A95A0",
                  display: props.fraction,
                  formatter: FractionFormatter,
               },
               legend: {
                  display: false,
                  title: { padding: 50 },
                  labels: { padding: props.labels.length < 1 ? 30 : 15, boxWidth: 5 },
                  align: "start",
                  fullSize: true,
                  maxWidth: 220,
               },
               tooltip: {
                  enabled: !props.customTooltip,
                  position: props.customTooltip ? "nearest" : undefined,
                  external:
                     props.customTooltip !== undefined
                        ? (context) => externalTooltipHandler(context, props.tooltipColorBox)
                        : undefined,
               },
            },

            responsive: true,
            maintainAspectRatio: false,
            scales: {
               x: {
                  display: !props.removeXLables,
                  max: !props.removeMaxValue ? null : maxValueRange(props.scores),
                  title: {
                     display: false,
                     align: "center",
                     color: "#A3A3A3",
                     text: props.xTitle,
                     font: {
                        family: "Open Sans",
                        size: 16,
                        style: "normal",
                     },
                  },
                  stacked: true,
                  min: 0,
                  grid: { color: "transparent" },
                  ticks: {
                     display: false,
                  },
               },
               y: {
                  display: !props.fraction,
                  max: props.removeMaxValue ? null : maxValueRange(props.scores),
                  title: {
                     display: true,
                     align: "center",
                     color: "#A3A3A3",
                     text: props.yTitle,
                     font: {
                        family: "Open Sans",
                        size: 16,
                        style: "normal",
                     },
                  },
                  stacked: true,
                  grid: { color: "transparent" },
                  grace: "10%",
               },
            },
         }}
      />
   );
};

const FractionFormatter = (value, context) => {
   const datasetArray = [];
   context.chart.data.datasets.forEach((dataset) => {
      if (dataset.data[context.dataIndex] !== undefined) {
         datasetArray.push(dataset.data[context.dataIndex]);
      }
   });
   function totalSum(total, datapoint) {
      return total + datapoint;
   }
   let sum = datasetArray.reduce(totalSum, 0);
   return context.datasetIndex === datasetArray.length - 1 ? `${datasetArray[0]}/${sum}` : "";
};

function maxValueRange(scores) {
   const values: number[] = [];
   for (let i = 0; i < scores[0].length; i++) values.push(scores[0][i] + scores[1][i]);
   const orderedValues = quickSort(values);
   return orderedValues[orderedValues.length - 1] + 2;
}
