const getName = (name: string) => {
   let sectionString = "";
   switch (name) {
      case "Calendario":
         sectionString = "Calendar";
         break;
      case "Acuerdos y compromisos":
         sectionString = "Board";
         break;
      case "Mis notas":
         sectionString = "Notes";
         break;
      case "Mis archivos":
         sectionString = "Files";
         break;
      case "Reportes":
         sectionString = "Reports";
         break;
      case "Tablero de avisos":
         sectionString = "Tablero de avisos";
         break;
      case "Gestión documental":
         sectionString = "Gestion documental";
         break;

      default:
         break;
   }
   return sectionString;
};

export const menuOptionsMap = (resourceList) => {
   const resourceMap = new Map();
   resourceMap.set("Calendario", findResourceByName(resourceList, "Calendario"));
   resourceMap.set("Tablero de avisos", findResourceByName(resourceList, "Tablero de avisos"));
   resourceMap.set("Gestión documental", findResourceByName(resourceList, "Gestión documental"));
   resourceMap.set("Acuerdos y compromisos", findResourceByName(resourceList, "Acuerdos y compromisos"));
   resourceMap.set("Reportes", findResourceByName(resourceList, "Reportes"));
   resourceMap.set("Mis notas", findResourceByName(resourceList, "Mis notas"));
   resourceMap.set("Mis archivos", findResourceByName(resourceList, "Mis archivos"));
   return resourceMap;
};

function findResourceByName(resourceList, resourceName) {
   if (resourceList?.length > 0) {
      const foundResource = resourceList.find((resource) => resource.name === getName(resourceName));
      return foundResource;
   }
   return undefined;
}
