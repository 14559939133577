import {
   Box,
   Stack,
   Typography,
   CircularProgress,
   List,
   Tooltip,
   Divider,
   Button,
   FormHelperText,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import {
   PutNewUserRole,
   addGovernBodyRole,
   getAccessByServiceId,
   getAllResources,
   getBranchesByCompanyId,
   getGroupUsers,
   getRoleByCharge,
} from "../../../../lib/usersBEClient";
import { UserContext } from "../../../../context/userContext";
import { InputCheckBox } from "../../../Inputs/InputCheckBox";
import { ArrowBack, Warning } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { SnackBarContext } from "../../../../context/snackBarContext";
import UseModules from "../../Modules/useModules";
import { AddUserContext } from "../../../../context/addUserContext";
import { addGovernBodyUser, addUserGovernBody } from "../../../../lib/gobCorpBEClient";

interface AddCompanyUserModalProps {
   userData: any;
   setOpen: Function;
   userInformation: any;
   selectedCompanyData: any;
   fetchData: Function;
}

export const AddCompanyUserModal = (props: AddCompanyUserModalProps) => {
   const { user, companies } = useContext(UserContext);
   const [groupUsers, setGroupUsers] = useState<any[]>();
   const [isLoadingGroupUsers, setIsLoadingGroupUsers] = useState(true);
   const [isLoadingModules, setIsLoadingModules] = useState(false);
   const [initialValues, setInitialValues] = useState({});
   const [validationError, setValidationError] = useState(false);
   const [userInformation, setUserInformation] = useState(null);
   const [firstScreen, setFirstScreen] = useState(true);
   const [companyNumber, setCompanyNumber] = useState(0);
   const [modulesAndRoles, setModulesAndRoles] = useState([]);
   const [errorRoles, setErrorRoles] = useState(false);
   const [errorBranches, setErrorBranches] = useState(false);
   const { showSnackBar } = useContext(SnackBarContext);
   const [isLoadingCreate, setIsLoadingCreate] = useState(false);
   const { modules } = useContext(AddUserContext);

   useEffect(() => {
      setIsLoadingGroupUsers(true);
      const users = async () => {
         if (isLoadingGroupUsers) await fetchUsers();
      };
      users();
      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      if (user.group) {
         let initialValuesSet = {};
         for (const iterator of companies) {
            initialValuesSet = {
               [iterator._id]: false,
               ...initialValuesSet,
            };
         }
         setInitialValues({
            ...initialValuesSet,
            ...initialValues,
         });
      }

      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      const rolesPerModule = async () => {
         setIsLoadingModules(true);
         const services = [];
         for (const iterator of userInformation.companies) {
            if (
               Array.isArray(iterator.company_details.servicesDetails) &&
               iterator.company_details.servicesDetails.length > 0
            ) {
               for (const service of iterator.company_details.servicesDetails) {
                  const { data } = await getAccessByServiceId(service.serviceId);
                  let branches = [];
                  if (data.serviceName === "Canal de denuncias") {
                     const branchesResponse = await getBranchesByCompanyId(iterator._id);
                     branches = branchesResponse.data;
                  }
                  const provisionalData = {
                     company: iterator._id,
                     service: { ...data, id: service.serviceId },
                     userLimit: service.userLimit,
                     branches: branches,
                  };
                  services.push(provisionalData);
               }
            } else {
               const provisionalData = {
                  company: iterator._id,
                  service: null,
               };
               services.push(provisionalData);
            }
         }
         // if (rolesFiltered !== rolesSelected)
         //    setRolesSelected(Object.keys(rolesFiltered).length === 0 ? rolesSelected : rolesFiltered);
         const validation = [];
         for (const iterator of userInformation.companies) {
            const prov = modulesAndRoles.filter((serv) => serv.company === iterator._id);
            validation.push(...prov);
         }
         if (services.length > 0 || modulesAndRoles.length === 0) {
            setModulesAndRoles([...services]);
         } else if (validation.length > 0) {
            setModulesAndRoles([...validation]);
         } else {
            setModulesAndRoles([...modulesAndRoles]);
         }
         setIsLoadingModules(false);
      };

      if (userInformation && !firstScreen) {
         rolesPerModule();
      }
      // eslint-disable-next-line
   }, [userInformation, firstScreen]);

   const fetchUsers = async () => {
      if (user.group) {
         const users = await getGroupUsers(user.group._id);
         setGroupUsers(users);
      }
      setIsLoadingGroupUsers(false);
   };

   const getUserCountByModule = (company) => {
      const processedData = [];
      for (const users of groupUsers) {
         for (const role of users.role) {
            processedData.push(role);
         }
      }
      const filteredRoles = processedData.filter((rol) => rol.company._id === company._id);
      const processedData2 = [];
      for (const users of filteredRoles) {
         for (const role of users.roles) {
            processedData2.push(role);
         }
      }
      const rolesByCD = processedData2.filter(
         (rol) => rol.name === "Auditor" || rol.name === "Director" || rol.name === "Oficial de cumplimiento"
      );
      return {
         cd: Array.isArray(rolesByCD) ? rolesByCD.length : 0,
      };
   };

   const submitInformation = (values, action) => {
      const companiesSelected = [];
      if (user.group) {
         const validation = companies.some((company) => values[company._id] === true);
         if (!validation) {
            setValidationError(true);
            return;
         }
         const validation2 = companies.filter((company) => values[company._id] === true);
         for (const iterator of validation2) {
            companiesSelected.push(iterator);
         }
         setValidationError(false);
      }
      setInitialValues(values);
      setUserInformation({
         firstName: props.userInformation.user.firstName,
         lastName: props.userInformation.user.lastName,
         phone: props.userInformation.user.phoneNumber,
         email: props.userInformation.user.email,
         companies: companiesSelected,
         uid: props.userInformation.user.uid,
      });
      setFirstScreen(false);
   };

   const handleOnCloseModal = () => {
      setFirstScreen(true);
      props.setOpen(false);
      setUserInformation(null);
      setValidationError(false);
      setModulesAndRoles([]);
      setCompanyNumber(0);
      setErrorRoles(false);
      setInitialValues({ firstName: "", lastName: "", phone: "", email: "" });
   };

   const handleContinue = async (index) => {
      if (userInformation.companies.length > 0) {
         setIsLoadingCreate(true);
         setErrorRoles(false);
         setErrorBranches(false);
         const modulesByCompany = modules.filter((module) => module.company === index);
         if (modulesByCompany.length === 0) {
            setErrorRoles(true);
            return;
         }

         //validation CD
         const modulesCD = modules.find(
            (module) => module.module === "64e784dd978b71bd4f011ee3" && module.company === index
         );
         if (modulesCD) {
            if (!modulesCD.branches || (modulesCD.branches && modulesCD.branches.length === 0)) {
               setErrorBranches(true);
               return;
            }
         }
         //validation GC
         const modulesGC = modules.find(
            (module) => module.module === "64e7851d978b71bd4f011ee4" && module.company === index
         );
         if (modulesGC) {
         }
         if (userInformation.companies.length === companyNumber + 1) {
            setErrorRoles(false);
            let formatedRoles = [];
            let branches = [];

            for (const company of userInformation.companies) {
               let roles = [];
               const modulesByC = modules.filter((module) => module.company === company._id);
               for (const module of modulesByC) {
                  if (module.module !== "64e7851d978b71bd4f011ee4" && module.company === company._id) {
                     if (module.branches && module.branches.length > 0) {
                        branches.push(...module.branches.map((branches) => branches._id));
                     }
                     roles.push(module.role);
                  }
               }
               formatedRoles.push({
                  company: company._id,
                  roles: roles,
               });
            }
            try {
               const resourceResponse = await getAllResources();
               const resourceId = resourceResponse.find((r) => r.name === "Mi Lecosy")._id;
               if (!resourceId) return showSnackBar("Error al actualizar usuario", true);
               const userResponse = await PutNewUserRole(
                  userInformation.uid,
                  formatedRoles,
                  branches,
                  userInformation.companies.map((company) => company._id.toString())
               );
               if (userResponse) {
                  const moduleGC = modules.filter((module) => module.module === "64e7851d978b71bd4f011ee4");
                  if (moduleGC.length > 0) {
                     for (const iterator of moduleGC) {
                        const userData = {
                           user: userResponse._id,
                           charge: iterator.role,
                           characters: [],
                           addedDate: new Date(),
                        };
                        if (iterator.role === "659f11befb579847f88877ce") {
                           await addGovernBodyUser(iterator.company, userResponse._id, "659f11befb579847f88877ce");
                        }

                        await addUserGovernBody(iterator.governance, userData, iterator.company, resourceId);
                        const roleId = await getRoleByCharge(iterator.role);
                        const roleData = {
                           role: roleId._id,
                           company: iterator.company,
                           user: userResponse._id,
                        };
                        await addGovernBodyRole(roleData, iterator.governance);
                     }
                  }
               }

               setIsLoadingCreate(false);
               handleOnCloseModal();
               props.fetchData();
               showSnackBar("El rol fue agregado correctamente.", false);
            } catch (error) {
               showSnackBar("Error al agregar rol.", true);
               setIsLoadingCreate(false);
            }
         } else {
            setFirstScreen(false);
            setCompanyNumber(companyNumber + 1);
            setErrorRoles(false);
            setErrorBranches(false);
         }
      } else {
         return;
         //save
      }
   };

   return (
      <Box sx={{ width: 700, p: 4 }}>
         {!firstScreen ? (
            <>
               {userInformation.companies.map((company, index) => (
                  <>
                     {companyNumber === index ? (
                        <Stack spacing={2}>
                           <Box
                              sx={{
                                 display: "flex",
                                 alignItems: "center",
                              }}
                           >
                              <Box flex={1}>
                                 <Button
                                    onClick={() =>
                                       companyNumber === 0 ? setFirstScreen(true) : setCompanyNumber(companyNumber - 1)
                                    }
                                 >
                                    <ArrowBack />
                                    <Typography ml={1}>Regresar</Typography>
                                 </Button>
                              </Box>

                              <Box flex={4}>
                                 <Divider />
                              </Box>
                           </Box>

                           <Stack sx={{ px: 1, maxHeight: 400, overflowY: "scroll" }} spacing={2}>
                              <Box>
                                 <Typography variant="h6" fontWeight={600}>
                                    {company.person_details.comercialName}
                                 </Typography>
                                 <Typography fontWeight={550}>Asignación de módulos</Typography>
                              </Box>
                              <Box sx={{ maxHeight: 400 }}>
                                 {isLoadingModules ? (
                                    <Box sx={{ display: "flex", justifyContent: "center" }}>
                                       <CircularProgress />
                                    </Box>
                                 ) : (
                                    <>
                                       {Array.isArray(company.company_details.servicesDetails) &&
                                       company.company_details.servicesDetails.length > 0 ? (
                                          <Box>
                                             {modulesAndRoles.map((module) => {
                                                if (module.company === company._id && module.service) {
                                                   const moduleComponent = UseModules(module);
                                                   return moduleComponent;
                                                } else {
                                                   return <></>;
                                                }
                                             })}
                                          </Box>
                                       ) : (
                                          <Typography>Esta empresa no tiene ningún módulo contratado</Typography>
                                       )}
                                    </>
                                 )}
                              </Box>
                              <FormHelperText error={errorRoles || errorBranches}>
                                 {errorRoles
                                    ? "Favor de seleccionar almenos 1 rol."
                                    : errorBranches
                                    ? "Favor de seleccionar almenos 1 sucursal."
                                    : null}
                              </FormHelperText>
                           </Stack>

                           <Box
                              sx={{
                                 display: "flex",
                                 justifyContent: "space-between",
                                 pt: 1,
                              }}
                           >
                              <Button variant="contained" sx={{ opacity: 0.5 }} onClick={handleOnCloseModal}>
                                 Cancelar
                              </Button>
                              <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                                 <Typography fontSize={14}>Completar información:</Typography>
                                 <Typography fontSize={14} fontWeight={600}>
                                    empresa {index + 1} de {userInformation.companies.length}
                                 </Typography>
                              </Box>

                              <>
                                 {!isLoadingModules ? (
                                    <Button
                                       variant="contained"
                                       color="primary"
                                       disabled={
                                          Array.isArray(company.company_details.servicesDetails) &&
                                          company.company_details.servicesDetails.length <= 0
                                       }
                                       onClick={() => handleContinue(company._id)}
                                       fullWidth
                                       sx={{
                                          maxHeight: 50,
                                          maxWidth: 90,
                                       }}
                                    >
                                       {companyNumber + 1 === userInformation.companies.length ? (
                                          isLoadingCreate ? (
                                             <CircularProgress
                                                size={20}
                                                sx={{
                                                   color: "white",
                                                }}
                                             />
                                          ) : (
                                             "Guardar"
                                          )
                                       ) : (
                                          "Continuar"
                                       )}
                                    </Button>
                                 ) : (
                                    <></>
                                 )}
                              </>
                           </Box>
                        </Stack>
                     ) : null}
                  </>
               ))}
            </>
         ) : (
            <Stack>
               {user.group && !isLoadingGroupUsers && props.userData ? (
                  <>
                     <Typography fontWeight={"bold"}>Empresas que forman parte del grupo empresarial</Typography>
                     <Typography sx={{ p: 1 }}>Empresas a las que tiene acceso actualmente</Typography>
                     <Formik initialValues={initialValues} onSubmit={submitInformation}>
                        <Form>
                           <List>
                              {!isLoadingGroupUsers && user.group
                                 ? companies.map((company, index) => {
                                      const userInfo = props.userData;
                                      let validation = false;
                                      for (const module of userInfo) {
                                         if (module.id === company._id) {
                                            validation = true;
                                         }
                                      }

                                      return (
                                         <>
                                            {!validation ? null : (
                                               <>
                                                  <Box key={index} sx={{ display: "flex", alignItems: "center" }}>
                                                     <InputCheckBox
                                                        id={company._id}
                                                        label={company.person_details.comercialName}
                                                        name={company._id}
                                                        disabled={validation}
                                                        size={"medium"}
                                                     />
                                                     {!validation ? null : (
                                                        <Tooltip
                                                           placement="right-start"
                                                           title={"El usuario ya tiene acceso a esta empresa."}
                                                        >
                                                           <Warning sx={{ color: "orange" }} />
                                                        </Tooltip>
                                                     )}
                                                  </Box>
                                                  <Divider />
                                               </>
                                            )}
                                         </>
                                      );
                                   })
                                 : null}
                           </List>
                           <Typography sx={{ mt: 2 }}>Seleccione la empresa(s) a la(s) que tendrá acceso.</Typography>
                           <List>
                              {!isLoadingGroupUsers && user.group
                                 ? companies.map((company, index) => {
                                      const userInfo = props.userData;
                                      const userLimit = company.company_details.servicesDetails;
                                      const userPerModule = getUserCountByModule(company);
                                      let validationAccess = false;
                                      let validationLicenses = false;
                                      for (const module of userInfo) {
                                         if (module.id === company._id) {
                                            validationAccess = true;
                                            for (const module of userLimit) {
                                               if (
                                                  module.serviceId.service === "Canal de denuncias" &&
                                                  module.userLimit === userPerModule.cd
                                               ) {
                                                  validationLicenses = true;
                                               }
                                            }
                                         }
                                      }

                                      return (
                                         <>
                                            {validationAccess ? null : (
                                               <>
                                                  <Box sx={{ display: "flex", alignItems: "center" }}>
                                                     <InputCheckBox
                                                        id={company._id}
                                                        label={company.person_details.comercialName}
                                                        name={company._id}
                                                        disabled={validationLicenses}
                                                        size={"medium"}
                                                     />
                                                     {!validationLicenses ? null : (
                                                        <Tooltip
                                                           placement="right-start"
                                                           title={
                                                              "No cuentas con suficientes licencias en los módulos."
                                                           }
                                                        >
                                                           <Warning sx={{ color: "orange" }} />
                                                        </Tooltip>
                                                     )}
                                                  </Box>
                                                  <Divider />
                                               </>
                                            )}
                                         </>
                                      );
                                   })
                                 : null}
                           </List>
                           <FormHelperText error={validationError}>
                              {validationError ? "Favor de seleccionar 1 empresa." : null}
                           </FormHelperText>
                           <Box sx={{ display: "flex", justifyContent: "space-between", mt: 2 }}>
                              <Button
                                 variant="contained"
                                 onClick={() => {
                                    props.setOpen(false);
                                 }}
                              >
                                 Cancelar
                              </Button>
                              <Button variant="contained" type="submit">
                                 Continuar
                              </Button>
                           </Box>
                        </Form>
                     </Formik>
                  </>
               ) : (
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                     <CircularProgress />
                  </Box>
               )}
            </Stack>
         )}
      </Box>
   );
};
