import { useEffect, useState, useContext } from "react";
import {
   Box,
   Button,
   CircularProgress,
   Divider,
   FormHelperText,
   List,
   Stack,
   Tooltip,
   Typography,
} from "@mui/material";
import { HeadCell, TableComponent } from "../../TableComponent";
import { UserContext } from "../../../context/userContext";
import { AnyObject } from "yup/lib/types";
import { FilterComponent } from "../../FilterComponent";
import { AddCircle, ArrowBack, Warning } from "@mui/icons-material";
import { Form, Formik } from "formik";
import { InputTextField } from "../../Inputs/InputTextField";
import { InputCheckBox } from "../../Inputs/InputCheckBox";
import { userCreateSchemaNew } from "../../../lib/validations/inputSchemas";
import {
   addGovernBodyRole,
   getAccessByServiceId,
   getBranchesByCompanyId,
   getGroupUsers,
   getRoleByCharge,
   getUserStatus,
   useCreateUser,
   useGetResourceSelected,
} from "../../../lib/usersBEClient";
import { SnackBarContext } from "../../../context/snackBarContext";
import { Navigate, useNavigate } from "react-router-dom";
import { addUserGovernBody, getMemberChargesByCompany } from "../../../lib/gobCorpBEClient";
import UseModules from "../Modules/useModules";
import { AddUserContext } from "../../../context/addUserContext";
import rolesByService from "../../../const/rolesByService.json";
import { CustomModalComponent } from "../../CustomModalComponent";

export const GroupUsersTable = () => {
   const { user, companies, isImplementationUser } = useContext(UserContext);
   const [companyUsers, setCompanyUsers] = useState([]);
   const [filteredUsers, setFilteredUsers] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const [openModal, setOpenModal] = useState(false);
   const [initialValues, setInitialValues] = useState({
      firstName: "",
      lastName: "",
      phone: "",
      email: "",
   });
   const [userInformation, setUserInformation] = useState(null);
   const [firstSreen, setFirstSreen] = useState(true);
   const [validationError, setValidationError] = useState(false);
   const [modulesAndRoles, setModulesAndRoles] = useState([]);
   const [isLoadingModules, setIsLoadingModules] = useState(false);
   const [companyNumber, setCompanyNumber] = useState(0);
   const [rolesSelected, setRolesSelected] = useState({});
   const [errorRoles, setErrorRoles] = useState(false);
   const resourceId = useGetResourceSelected();
   const [errorBranches, setErrorBranches] = useState(false);
   const [groupUsers, setGroupUsers] = useState<any[]>();
   const [isLoadingGroupUsers, setIsLoadingGroupUsers] = useState(true);
   const { mutate, isLoading: isLoadingCreate } = useCreateUser();
   const { showSnackBar } = useContext(SnackBarContext);
   const [userStatus, setUserStatus] = useState([]);
   const { modules } = useContext(AddUserContext);

   const headCells: HeadCell[] = [
      { field: "name", headerName: "Nombre", type: "string" },
      { field: "email", headerName: "Correo electrónico", type: "string" },
      { field: "roles", headerName: "Rol", type: "popover" },
      { field: "status", headerName: "Estatus", type: "boolean" },
   ];
   const navigate = useNavigate();

   const fetchCompanyUsers = async () => {
      try {
         const filteredGroupUsers = groupUsers.filter((u) => u.role.some((r) => r.group === user.group._id));
         const usersData = filteredGroupUsers.map((user) => ({
            _id: user._id,
            uid: user.uid,
            name: user.firstName + " " + user.lastName,
            email: user.email,
            roles: getRoles(user.role).length > 0 ? getRoles(user.role) : ["sin datos"],
            status: userStatus.find((u) => u._id === user._id).disabled,
         }));
         const gcUserIds = usersData
            .filter((user) => user.roles.some((r) => rolesByService.GC.includes(r)))
            .map((user) => user._id);
         if (gcUserIds.length > 0) {
            const chargesResponses = await getMemberChargesByCompany(gcUserIds, user.group._id);
            const GcChargesResponse = chargesResponses;
            for (const user of usersData) {
               const index = GcChargesResponse.findIndex((c) => c.user === user._id);
               if (index >= 0) {
                  user.roles = user.roles.concat(GcChargesResponse[index].charges);
                  user.roles = user.roles.filter((r) => !rolesByService.GC.includes(r));
               }
            }
         }
         const usersDataNoAdmin = usersData.filter(
            (a) => a._id !== user.id && !a.roles.includes("Usuario de implementación")
         );
         setIsLoading(false);
         setCompanyUsers(usersDataNoAdmin);
         setFilteredUsers(usersDataNoAdmin);
      } catch (error) {
         console.error("Error fetching roles:", error);
         setIsLoading(false);
      }
   };

   function getRoles(roles: any[]) {
      const rolesArray = roles.flatMap((role) =>
         role.roles.map((r) => (r.name === "AdminDelSistema" ? "Administrador del Sistema" : r.name))
      );
      return Array.from(new Set(rolesArray));
   }

   const handleClickRowUser = async (_e, row) => {
      const userId = row.uid;
      const screen = "mi-lecosy/usuarios/0/" + userId;
      return navigate(`/${screen}`);
   };
   useEffect(() => {
      if (user.group) {
         let initialValuesSet = {};
         for (const iterator of companies) {
            initialValuesSet = {
               [iterator._id]: false,
               ...initialValuesSet,
            };
         }
         setInitialValues({
            ...initialValuesSet,
            ...initialValues,
         });
      }
      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      const users = async () => {
         await fetchUsers();
      };
      users();
      // eslint-disable-next-line
   }, []);

   useEffect(() => {
      const getStatus = async () => {
         setUserStatus(await getUserStatus(groupUsers));
      };
      if (groupUsers) getStatus();
      // eslint-disable-next-line
   }, [groupUsers]);

   useEffect(() => {
      const fetchCUsers = async () => {
         await fetchCompanyUsers();
      };
      if (userStatus.length > 0) fetchCUsers();
      // eslint-disable-next-line
   }, [userStatus, isLoadingGroupUsers, userStatus.length]);

   useEffect(() => {
      const rolesPerModule = async () => {
         setIsLoadingModules(true);
         const services = [];
         const rolesFiltered = {};
         for (const iterator of userInformation.companies) {
            if (
               Array.isArray(iterator.company_details.servicesDetails) &&
               iterator.company_details.servicesDetails.length > 0
            ) {
               for (const service of iterator.company_details.servicesDetails) {
                  let { data } = await getAccessByServiceId(service.serviceId);
                  let branches = [];
                  if (data.serviceName === "Canal de denuncias") {
                     const branchesResponse = await getBranchesByCompanyId(iterator._id);
                     branches = branchesResponse.data;
                  } else if (data.serviceName === "Gobierno corporativo") {
                     let role = data.roles.filter((r) => r.name === "N1");
                     role[0].name = "Usuario de implementación";
                     data = {
                        serviceName: data.serviceName,
                        roles: role,
                     };
                  }
                  const provisionalData = {
                     company: iterator._id,
                     service: { ...data, id: service.serviceId },
                     userLimit: service.userLimit,
                     branches: branches,
                  };
                  services.push(provisionalData);
               }
            } else {
               const provisionalData = {
                  company: iterator._id,
                  service: null,
               };
               services.push(provisionalData);
            }
         }
         if (rolesFiltered !== rolesSelected)
            setRolesSelected(Object.keys(rolesFiltered).length === 0 ? rolesSelected : rolesFiltered);
         const validation = [];
         for (const iterator of userInformation.companies) {
            const prov = modulesAndRoles.filter((serv) => serv.company === iterator._id);
            validation.push(...prov);
         }
         if (services.length > 0 || modulesAndRoles.length === 0) {
            setModulesAndRoles([...services]);
         } else if (validation.length > 0) {
            setModulesAndRoles([...validation]);
         } else {
            setModulesAndRoles([...modulesAndRoles]);
         }
         setIsLoadingModules(false);
      };

      if (userInformation && !firstSreen) {
         rolesPerModule();
      }
      // eslint-disable-next-line
   }, [userInformation, firstSreen]);

   const submitInformation = (values, action) => {
      const companiesSelected = [];
      if (user.group) {
         const validation = companies.some((company) => values[company._id] === true);
         if (!validation) {
            setValidationError(true);
            return;
         }
         const validation2 = companies.filter((company) => values[company._id] === true);
         for (const iterator of validation2) {
            companiesSelected.push(iterator);
         }
         setValidationError(false);
      }
      setInitialValues(values);
      setUserInformation({
         firstName: values.firstName,
         lastName: values.lastName,
         phone: values.phone,
         email: values.email,
         companies: companiesSelected,
      });
      setFirstSreen(false);
   };

   const handleOnCloseModal = () => {
      setFirstSreen(true);
      setOpenModal(false);
      setUserInformation(null);
      setValidationError(false);
      setModulesAndRoles([]);
      setCompanyNumber(0);
      setErrorRoles(false);
      setRolesSelected({});
      setInitialValues({ firstName: "", lastName: "", phone: "", email: "" });
   };

   const getUserCountByModule = (company) => {
      const processedData = [];
      if (!groupUsers) return;
      for (const users of groupUsers) {
         for (const role of users.role) {
            processedData.push(role);
         }
      }
      const filteredRoles = processedData.filter((rol) => rol?.company?._id === company?._id);
      const processedData2 = [];
      for (const users of filteredRoles) {
         for (const role of users.roles) {
            processedData2.push(role);
         }
      }
      const rolesByCD = processedData2.filter(
         (rol) => rol.name === "Auditor" || rol.name === "Director" || rol.name === "Oficial de cumplimiento"
      );
      return {
         cd: Array.isArray(rolesByCD) ? rolesByCD.length : 0,
      };
   };

   const handleContinue = (index) => {
      if (userInformation.companies.length > 0) {
         setErrorRoles(false);
         setErrorBranches(false);
         const modulesByCompany = modules.filter((module) => module.company === index);
         if (modulesByCompany.length === 0) {
            setErrorRoles(true);
            return;
         }

         //validation CD
         const modulesCD = modules.find(
            (module) => module.module === "64e784dd978b71bd4f011ee3" && module.company === index
         );
         if (modulesCD) {
            if (!modulesCD.branches || (modulesCD.branches && modulesCD.branches.length === 0)) {
               setErrorBranches(true);
               return;
            }
         }
         //validation GC
         const modulesGC = modules.find(
            (module) => module.module === "64e7851d978b71bd4f011ee4" && module.company === index
         );
         if (modulesGC) {
         }

         if (userInformation.companies.length === companyNumber + 1) {
            setErrorRoles(false);
            let formatedRoles = [];
            let branches = [];

            for (const company of userInformation.companies) {
               let roles = [];
               const modulesByC = modules.filter((module) => module.company === company._id);
               for (const module of modulesByC) {
                  if (module.module !== "64e7851d978b71bd4f011ee4" && module.company === company._id) {
                     if (module.branches && module.branches.length > 0) {
                        branches.push(...module.branches.map((branches) => branches._id));
                     }
                     roles.push(module.role);
                  }
               }
               formatedRoles.push({
                  company: company._id,
                  roles: roles,
               });
            }

            mutate(
               {
                  userData: {
                     firstName: userInformation.firstName,
                     lastName: userInformation.lastName,
                     email: userInformation.email,
                     phoneNumber: userInformation.phone,
                     role: formatedRoles,
                  },
                  additionalData: {
                     branches: branches,
                     admin: false,
                     group: user.group._id,
                  },
               },
               {
                  onError: (error: any) => {
                     error.response.data.message === "email already in use"
                        ? showSnackBar("Ya existe un usuario con ese correo", true)
                        : showSnackBar("Error al agregar usuario.", true);
                  },
                  onSuccess: async (data) => {
                     const moduleGC = modules.filter((module) => module.module === "64e7851d978b71bd4f011ee4");
                     if (moduleGC.length > 0) {
                        for (const iterator of moduleGC) {
                           const userData = {
                              user: data.user._id,
                              charge: iterator.role,
                              characters: [],
                              addedDate: new Date(),
                           };
                           await addUserGovernBody(iterator.governance, userData, iterator.company, resourceId);
                           const roleId = await getRoleByCharge(iterator.role);
                           const roleData = {
                              role: roleId._id,
                              company: iterator.company,
                              user: data.user._id,
                           };
                           await addGovernBodyRole(roleData, iterator.governance);
                        }
                     }
                     showSnackBar("El usuario fue agregado correctamente.", false);
                     await fetchUsers();
                     handleOnCloseModal();
                     // window.location.reload();
                  },
               }
            );
         } else {
            setFirstSreen(false);
            setCompanyNumber(companyNumber + 1);
            setErrorRoles(false);
            setErrorBranches(false);
         }
      } else {
         return;
         //save
      }
   };

   const fetchUsers = async () => {
      setIsLoadingGroupUsers(true);
      const users = await getGroupUsers(user.group._id);
      setGroupUsers(users);
      setIsLoadingGroupUsers(false);
   };
   if (isImplementationUser()) return <Navigate to={"../gobierno-corporativo"} />;

   return (
      <Box sx={{ width: "100%" }}>
         <Box mx={1}>
            <Typography fontWeight={600}>Lista de usuarios</Typography>
         </Box>
         <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <FilterComponent
               fullWidth
               originaltableOneRows={companyUsers}
               tableOneFilteredRows={filteredUsers}
               setTableOneFilteredRows={setFilteredUsers}
               filters={[]}
               searchBar={
                  <Box sx={{ display: "flex", flexDirection: "row", flex: 1 }}>
                     <Button
                        variant="contained"
                        onClick={() => {
                           setOpenModal(true);
                           fetchUsers();
                        }}
                        fullWidth
                     >
                        <AddCircle sx={{ mr: 1 }} />
                        Agregar usuario
                     </Button>
                  </Box>
               }
               disableRefresh
            />
         </Box>
         <TableComponent
            headerColor
            defaultColumnToOrder="status"
            defaultOrder="asc"
            defaultRowsPerPage={10}
            rowsPerPageOptions={[5, 10, 20, 50]}
            headers={headCells}
            rows={filteredUsers}
            onClick={(e, row) => {
               handleClickRowUser(e, row);
            }}
            loader={isLoading || isLoadingGroupUsers}
            emptyDataText="No existen usuarios registrados"
            cursor={"default"}
         />
         <CustomModalComponent
            open={openModal}
            setOpen={setOpenModal}
            onClose={handleOnCloseModal}
            title="Agregar nuevo usuario"
            timeStamp
         >
            <Box sx={{ p: 4, width: 900 }}>
               {!firstSreen ? (
                  <>
                     {userInformation.companies.map((company, index) => (
                        <>
                           {companyNumber === index ? (
                              <Stack spacing={2}>
                                 <Box
                                    sx={{
                                       display: "flex",
                                       alignItems: "center",
                                    }}
                                 >
                                    <Box flex={1}>
                                       <Button
                                          onClick={() =>
                                             companyNumber === 0
                                                ? setFirstSreen(true)
                                                : setCompanyNumber(companyNumber - 1)
                                          }
                                       >
                                          <ArrowBack />
                                          <Typography ml={1}>Regresar</Typography>
                                       </Button>
                                    </Box>

                                    <Box flex={4}>
                                       <Divider />
                                    </Box>
                                 </Box>
                                 <Stack sx={{ px: 1 }} spacing={2}>
                                    <Box>
                                       <Typography variant="h6" fontWeight={600}>
                                          {company.person_details.comercialName}
                                       </Typography>
                                       <Typography fontWeight={550}>Asignación de módulos</Typography>
                                    </Box>
                                    <Box sx={{ maxHeight: 350, overflowY: "auto" }}>
                                       {isLoadingModules ? (
                                          <Box sx={{ display: "flex", justifyContent: "center" }}>
                                             <CircularProgress />
                                          </Box>
                                       ) : (
                                          <>
                                             {Array.isArray(company.company_details.servicesDetails) &&
                                             company.company_details.servicesDetails.length > 0 ? (
                                                <Box>
                                                   {modulesAndRoles.map((module) => {
                                                      if (module.company === company._id && module.service) {
                                                         const moduleComponent = UseModules(module);
                                                         return moduleComponent;
                                                      } else {
                                                         return <></>;
                                                      }
                                                   })}
                                                </Box>
                                             ) : (
                                                <Typography>Esta empresa no tiene ningún módulo contratado</Typography>
                                             )}
                                          </>
                                       )}
                                    </Box>
                                    <FormHelperText error={errorRoles || errorBranches}>
                                       {errorRoles
                                          ? "Favor de seleccionar almenos 1 rol."
                                          : errorBranches
                                          ? "Favor de seleccionar almenos 1 sucursal."
                                          : null}
                                    </FormHelperText>
                                 </Stack>

                                 <Box
                                    sx={{
                                       display: "flex",
                                       justifyContent: "space-between",
                                       pt: 1,
                                    }}
                                 >
                                    <Button variant="contained" sx={{ opacity: 0.5 }} onClick={handleOnCloseModal}>
                                       Cancelar
                                    </Button>
                                    <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
                                       <Typography fontSize={14}>Completar información:</Typography>
                                       <Typography fontSize={14} fontWeight={600}>
                                          empresa {index + 1} de {userInformation.companies.length}
                                       </Typography>
                                    </Box>
                                    {/* {modulesAndRoles.some(
                                          (module) =>
                                             module.branches.length === 0 &&
                                             module.service.serviceName === "Canal de denuncias"
                                       ) ? ( */}
                                    {/* <Box
                                             sx={{
                                                height: 30,
                                                width: 90,
                                             }}
                                          >
                                             <Tooltip
                                                placement="top"
                                                title={
                                                   "Esta empresa no cuenta con sucursales, favor de agregar sucursales antes de agregar usuarios."
                                                }
                                             >
                                                <Warning sx={{ color: "orange" }} />
                                             </Tooltip>
                                          </Box>
                                       ) : ( */}
                                    <>
                                       {!isLoadingModules ? (
                                          <Button
                                             variant="contained"
                                             color="primary"
                                             disabled={
                                                Array.isArray(company.company_details.servicesDetails) &&
                                                company.company_details.servicesDetails.length <= 0
                                             }
                                             onClick={() => handleContinue(company._id)}
                                             fullWidth
                                             sx={{
                                                maxHeight: 50,
                                                maxWidth: 90,
                                             }}
                                          >
                                             {companyNumber + 1 === userInformation.companies.length ? (
                                                isLoadingCreate ? (
                                                   <CircularProgress
                                                      size={20}
                                                      sx={{
                                                         color: "white",
                                                      }}
                                                   />
                                                ) : (
                                                   "Guardar"
                                                )
                                             ) : (
                                                "Continuar"
                                             )}
                                          </Button>
                                       ) : (
                                          <></>
                                       )}
                                    </>
                                    {/* )} */}
                                 </Box>
                              </Stack>
                           ) : null}
                        </>
                     ))}
                  </>
               ) : (
                  <>
                     {openModal ? (
                        <>
                           <Typography fontWeight={600}>Información del usuario</Typography>
                           <Formik
                              initialValues={initialValues}
                              onSubmit={submitInformation}
                              validationSchema={userCreateSchemaNew}
                           >
                              <Form>
                                 <Box sx={{ display: "flex", columnGap: 2, pt: 2 }}>
                                    <InputTextField
                                       label="Nombre(s)"
                                       id="firstName"
                                       name="firstName"
                                       type="text"
                                       fullWidth={true}
                                       variant="outlined"
                                       size="small"
                                    />
                                    <InputTextField
                                       label="Apellido(s)"
                                       id="lastName"
                                       name="lastName"
                                       type="text"
                                       fullWidth={true}
                                       size="small"
                                       variant="outlined"
                                    />
                                 </Box>
                                 <Box sx={{ display: "flex", columnGap: 2, pt: 2 }}>
                                    <InputTextField
                                       label="Teléfono"
                                       id="phone"
                                       name="phone"
                                       type="text"
                                       fullWidth={true}
                                       size="small"
                                       variant="outlined"
                                    />
                                    <InputTextField
                                       label="Correo electrónico"
                                       id="email"
                                       name="email"
                                       type="text"
                                       fullWidth={true}
                                       size="small"
                                       variant="outlined"
                                    />
                                 </Box>
                                 <Stack spacing={1} sx={{ pt: 2 }}>
                                    <Typography fontWeight={600}>
                                       Empresas que forman parte del grupo empresarial
                                    </Typography>
                                    <Typography>Seleccione la empresa(s) que tendrá acceso.</Typography>
                                    <List>
                                       {companies.length > 0 &&
                                          companies.map((company, index) => {
                                             const userLimit = company.company_details.servicesDetails;
                                             const userPerModule = getUserCountByModule(company);
                                             let validation = false;
                                             for (const module of userLimit) {
                                                if (
                                                   module.serviceId.service === "Canal de denuncias" &&
                                                   module.userLimit === userPerModule.cd
                                                )
                                                   validation = true;
                                             }
                                             if (isLoadingGroupUsers && index === 0) return <CircularProgress />;
                                             else if (isLoadingGroupUsers) return null;
                                             else {
                                                return (
                                                   <>
                                                      <Box sx={{ display: "flex", alignItems: "center" }}>
                                                         <InputCheckBox
                                                            id={company._id}
                                                            label={company.person_details.comercialName}
                                                            name={company._id}
                                                            disabled={validation}
                                                            size="medium"
                                                         />
                                                         {!validation ? null : (
                                                            <Tooltip
                                                               placement="right-start"
                                                               title={
                                                                  "No cuentas con suficientes licencias en los módulos."
                                                               }
                                                            >
                                                               <Warning sx={{ color: "orange" }} />
                                                            </Tooltip>
                                                         )}
                                                      </Box>
                                                      <Divider />
                                                   </>
                                                );
                                             }
                                          })}
                                    </List>
                                    <FormHelperText error={validationError}>
                                       {validationError ? "Favor de seleccionar 1 empresa." : null}
                                    </FormHelperText>
                                 </Stack>
                                 <Box sx={{ display: "flex", justifyContent: "space-between", pt: 3 }}>
                                    <Button variant="contained" sx={{ opacity: 0.5 }} onClick={handleOnCloseModal}>
                                       Cancelar
                                    </Button>
                                    <Button variant="contained" color="primary" type="submit">
                                       Continuar
                                    </Button>
                                 </Box>
                              </Form>
                           </Formik>
                        </>
                     ) : null}
                  </>
               )}
            </Box>
         </CustomModalComponent>
      </Box>
   );
};
