import { Avatar, Badge, Box, IconButton, Modal, Typography } from "@mui/material";
import { Circle, Close, PictureAsPdf } from "@mui/icons-material";
import { useContext, useState } from "react";
import { ExternalSessionFiles } from "./ExternalSessionFiles";
import { GetSessionMembers, SessionMembersExternal } from "./SessionMembersExternal";
import { ExternalGovernanceSessionContext } from "../../../context/governanceContext/externalSessionContext";

export const MobileBillMenu = () => {
   const { session } = useContext(ExternalGovernanceSessionContext);
   const { attendedNumber } = GetSessionMembers();
   const [openSessionFiles, setOpenSessionFiles] = useState(false);
   const [openParticipants, setOpenParticipants] = useState(false);
   const [userStatusSelected, setUserStatusSelected] = useState(false);

   const handleClose = () => {
      setOpenSessionFiles(false);
      setOpenParticipants(false);
   };

   return (
      <Box sx={{ display: "flex", justifyContent: "space-between", p: 2, alignItems: "center" }}>
         <IconButton
            onClick={() => setOpenSessionFiles(true)}
            sx={{
               bgcolor: "#F8F8F9",
               border: 1,
               borderColor: "#E0E0E0",
               p: 1,
               borderRadius: 2,
               justifyContent: "center",
               alignItems: "center",
            }}
         >
            <PictureAsPdf sx={{ color: "#868FA0" }} />
         </IconButton>
         <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
            <Typography fontWeight={600}>Participantes</Typography>
            <Badge
               overlap="circular"
               anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
               badgeContent={<Circle sx={{ color: "#44b700", fontSize: 12 }} />}
               onClick={() => {
                  setUserStatusSelected(true);
                  setOpenParticipants(true);
               }}
            >
               <Avatar
                  sx={{
                     bgcolor: "#F5F5F5",
                     color: "#7D8592",
                     fontWeight: 600,
                     border: 2,
                     borderColor: "#D8D8D8",
                     "&:hover": { bgcolor: "#F5F5F5AA", cursor: "pointer" },
                  }}
               >
                  {attendedNumber}
               </Avatar>
            </Badge>
            <Badge
               overlap="circular"
               anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
               badgeContent={<Circle sx={{ color: "#E00000", fontSize: 12 }} />}
               onClick={() => {
                  setUserStatusSelected(false);
                  setOpenParticipants(true);
               }}
            >
               <Avatar
                  sx={{
                     bgcolor: "#F5F5F5",
                     color: "#7D8592",
                     fontWeight: 600,
                     border: 2,
                     borderColor: "#D8D8D8",
                     "&:hover": { bgcolor: "#F5F5F5AA", cursor: "pointer" },
                  }}
               >
                  {session.usersRegistry.length + session.externs.length - attendedNumber}
               </Avatar>
            </Badge>
         </Box>

         <Modal open={openSessionFiles} sx={{ display: "flex" }}>
            <>
               {/* <Box sx={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: 10, top: 0 }}>
                  <IconButton onClick={() => setOpenSessionFiles(false)}>
                     <Close sx={{ color: "white", fontSize: "37px" }} />
                  </IconButton>
               </Box> */}
               <Box sx={{ m: "auto", flex: 1, p: 2, maxWidth: 500 }}>
                  <ExternalSessionFiles handleCloseProp={handleClose} />
               </Box>
            </>
         </Modal>
         <Modal open={openParticipants} sx={{ display: "flex" }}>
            <>
               {/* <Box sx={{ display: "flex", justifyContent: "flex-end", position: "absolute", right: 10, top: 0 }}>
                  <IconButton onClick={() => setOpenParticipants(false)}>
                     <Close sx={{ color: "white", fontSize: "37px" }} />
                  </IconButton>
               </Box> */}
               <Box sx={{ m: "auto", flex: 1, p: 2, maxWidth: 500 }}>
                  <SessionMembersExternal online={userStatusSelected} handleCloseProp={handleClose} />
               </Box>
            </>
         </Modal>
      </Box>
   );
};
