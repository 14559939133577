import { Notifications } from "@mui/icons-material";
import { Badge, Stack, Typography } from "@mui/material";
import { UserContext } from "../../context/userContext";
import { useContext } from "react";

const modules = [
   { title: "Mostrar todas", filter: "all" },
   { title: "Gobierno corporativo", filter: "64e7851d978b71bd4f011ee4" },
   { title: "Programa de cumplimiento", filter: "64c99249d80c07068e94af59" },
   { title: "Canal de denuncias", filter: "64e784dd978b71bd4f011ee3" },
];

export const MenuOptions = ({ setFilter, filter }) => {
   const { notifications, user } = useContext(UserContext);

   return (
      <Stack sx={{ rowGap: 2 }}>
         <Typography fontWeight={600}>Menú de opciones</Typography>
         {modules.map((mod) => (
            <Stack
               flexDirection={"row"}
               sx={{ alignItems: "center", columnGap: 1, cursor: "pointer" }}
               onClick={() => setFilter(mod.filter)}
            >
               <Badge
                  badgeContent={
                     mod.filter !== "all"
                        ? notifications.filter(
                             (notification) =>
                                notification.service === mod.filter &&
                                !notification.users?.find((userF) => userF.userId === user.id)?.read
                          ).length
                        : notifications.filter(
                             (notification) => !notification.users?.find((userF) => userF.userId === user.id)?.read
                          ).length
                  }
                  color="error"
                  sx={{ color: "white" }}
               >
                  <Notifications sx={{ color: filter === mod.filter ? "#162c44" : "gray" }} />
               </Badge>
               <Typography fontWeight={600} sx={{ color: filter === mod.filter ? "#162c44" : "gray" }}>
                  {mod.title}
               </Typography>
            </Stack>
         ))}
      </Stack>
   );
};
