import { Typography, Button } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { useNavigate } from "react-router-dom";

interface VerifyEmailProps {
   success: boolean;
   master: boolean;
   redirect: Function;
}

export const VerifyEmail = (props: VerifyEmailProps) => {
   const navigate = useNavigate();

   return (
      <>
         {props.success ? (
            <>
               <Typography variant="h6" textAlign={"center"} sx={{ fontWeight: "bold", color: "#162c44", py: 2 }}>
                   Su correo electrónico fue verificado correctamente.
               </Typography>
               <CheckCircleIcon color="success" sx={{ fontSize: 80 }} />
               <Button
                  variant="contained"
                  sx={{ mt: 2 }}
                  onClick={() => (props.master ? props.redirect : navigate("/login"))}
               >
                  Volver a Lecosy
               </Button>
            </>
         ) : (
            <>
               <Typography variant="h6" textAlign={"center"} sx={{ fontWeight: "bold", color: "#162c44", pt: 2 }}>
                  El enlace proporcionado caducó, o hubo un problema al verificar el correo electrónico.
               </Typography>
               <ErrorIcon color="error" sx={{ fontSize: 80 }} />
               <Typography textAlign={"center"}>Favor de reintentar o contactar a soporte.</Typography>
               <Button
                  variant="contained"
                  sx={{ mt: 2 }}
                  onClick={() => (props.master ? props.redirect : navigate("/login"))}
               >
                  Volver a Lecosy
               </Button>
            </>
         )}
      </>
   );
};
