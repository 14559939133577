import { Stack, Typography } from "@mui/material";
import { InputTextField } from "../../../Inputs/InputTextField";
import { useContext } from "react";
import { getContrastYIQ } from "../../../../const/globalConst";
import { ExternalGovernanceSessionContext } from "../../../../context/governanceContext/externalSessionContext";

export const BillNotesExternal = () => {
   const { colors } = useContext(ExternalGovernanceSessionContext);
   return (
      <Stack>
         <Typography
            sx={{ bgcolor: colors.primary, textAlign: "center", fontSize: 12, color: getContrastYIQ(colors.primary) }}
         >
            NOTAS
         </Typography>
         <InputTextField
            id={"notes"}
            name={"notes"}
            type={"text"}
            multiline
            minRows={6}
            sx={{
               fontSize: 12,
               p: 2,
               bgcolor: "#EFEFEF",
               ".Mui-disabled": { "-webkit-text-fill-color": "black !important" },
            }}
            unstyled
            disabled={true}
         />
      </Stack>
   );
};
