import { Avatar, Stack, IconButton, Typography, Divider } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/material/styles";
import Badge from "@mui/material/Badge";
import { useContext } from "react";
import { ChatContext } from "../../../../context/governanceContext/ChatContext";

interface IHeaderChat {
   userReceived;
   user;
   openDrawer: Function;
   open: boolean;
   title: string;
   type: string;
   profilePic: string;
}

function stringAvatar(name: string) {
   return {
      sx: { color: "#162C44", bgcolor: "#F4F9FD", fontWeight: "bold", fontSize: 16 },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
   };
}
interface StyledProps {
   isOnline: boolean;
}

const StyledBadge = styled(Badge, {
   shouldForwardProp: (prop) => prop !== "isOnline",
})<StyledProps>(({ theme, isOnline }) => ({
   "& .MuiBadge-badge": {
      backgroundColor: isOnline ? "#44b700" : "gray",
      color: isOnline ? "#44b700" : "gray",
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      "&::after": {
         position: "absolute",
         top: 0,
         left: 0,
         width: "100%",
         height: "100%",
         borderRadius: "50%",
         border: "1px solid currentColor",
         content: '""',
      },
   },
   "@keyframes ripple": {
      "0%": {
         transform: "scale(.8)",
         opacity: 1,
      },
      "100%": {
         transform: "scale(2.4)",
         opacity: 0,
      },
   },
}));

export const HeaderChatComponent = (props: IHeaderChat) => {
   const { onlineUsers } = useContext(ChatContext);
   const isOnline = onlineUsers?.some((user) => user?.userId === props.user?._id);

   return (
      <Stack sx={{ display: "flex", flex: 1 }}>
         <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center" }}>
            <Stack direction={"row"} sx={{ alignItems: "center" }} spacing={2}>
               {props.type === "CONVERSATION" ? (
                  <StyledBadge
                     isOnline={isOnline}
                     overlap="circular"
                     anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                     variant="dot"
                  >
                     <Avatar src={props.profilePic} {...stringAvatar(props.title)} />
                  </StyledBadge>
               ) : (
                  <StyledBadge
                     isOnline={isOnline}
                     overlap="circular"
                     anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
                  >
                     <Avatar {...stringAvatar(props.title)} />
                  </StyledBadge>
               )}
               <Typography fontWeight={700}>{props.title}</Typography>
            </Stack>
            <IconButton
               onClick={() => {
                  props.openDrawer(!props.open);
               }}
               sx={{ borderRadius: 3, bgcolor: "#F4F9FD" }}
            >
               <InfoIcon sx={{ color: "#152C44" }} />
            </IconButton>
         </Stack>
         <Divider sx={{ p: 1, width: "100%" }} />
      </Stack>
   );
};
