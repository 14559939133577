import { Accordion, AccordionDetails, AccordionSummary, Stack, Typography } from "@mui/material";
import { useContext, useMemo } from "react";
import { getContrastYIQ } from "../../../../const/globalConst";
import { ExternalGovernanceSessionContext } from "../../../../context/governanceContext/externalSessionContext";
import { ArrowDownward } from "@mui/icons-material";
import { DeliberationVoteBox } from "../../Bill/SubComponentShareholder/ShareholderDeliberations";

export const ShareholderDeliberationsExternal = () => {
   const { membersWithCharge, affairsArray, valuesFromBill, colors, additionalVotes, session } = useContext(
      ExternalGovernanceSessionContext
   );
   const shareholdersArray =
      membersWithCharge?.filter(
         (member) => member.firstName && !member.memberCharge.includes("Usuario de implementacion")
      ) || [];

   const deliberationVotes = useMemo(
      () => affairsArray?.concat(additionalVotes as any).filter((d) => d) ?? [],
      [affairsArray, additionalVotes]
   );

   if (!valuesFromBill || deliberationVotes.length === 0) return;
   return (
      <Stack border={1} borderColor={"#EFEFEF"}>
         <Accordion disableGutters elevation={0}>
            <AccordionSummary
               sx={{
                  bgcolor: colors.secondary,
                  height: "22px",
                  minHeight: "22px !important",
               }}
               expandIcon={<ArrowDownward fontSize="small" sx={{ color: getContrastYIQ(colors.secondary) }} />}
            >
               <Typography
                  sx={{
                     width: "100%",
                     color: getContrastYIQ(colors.secondary),
                     textAlign: "center",
                     fontSize: 12,
                  }}
               >
                  DELIBERACIONES
               </Typography>
            </AccordionSummary>
            <AccordionDetails>
               <Stack sx={{ display: "flex" }} direction={"column"}>
                  {deliberationVotes
                     .map((order) => {
                        if ((order as any).affair || !order.totalVotesUsed) return;
                        return (
                           <Stack borderBottom={2} borderColor={colors.secondary} key={order._id}>
                              <Typography
                                 px={2}
                                 py={1}
                                 fontWeight={600}
                                 color={"#162c44"}
                                 width={"100%"}
                                 border={1}
                                 borderColor={"#EFEFEF"}
                                 fontSize={"13px"}
                              >
                                 {order?.description || order.title}
                              </Typography>
                              {order.vote && (
                                 <Typography
                                    px={2}
                                    py={0.5}
                                    width={"100%"}
                                    border={1}
                                    borderColor={"#EFEFEF"}
                                    fontSize={"12px"}
                                 >
                                    {deliberationVotes.find((votation) => votation?._id === order.vote)?.title}
                                 </Typography>
                              )}
                              <Stack direction={"row"} display={"flex"} overflow={"auto"} spacing={1} rowGap={2}>
                                 {(order as any).canceled ? (
                                    <Typography
                                       textAlign={"center"}
                                       py={0.5}
                                       width={"100%"}
                                       fontWeight={600}
                                       fontSize={"14px"}
                                    >
                                       Votación cancelada
                                    </Typography>
                                 ) : (order as any)?.votes !== undefined ? (
                                    (order as any).votes?.map((addVote) => {
                                       return (
                                          <DeliberationVoteBox userName={`${addVote.userName}`} vote={addVote.vote} />
                                       );
                                    })
                                 ) : order.vote ? (
                                    (deliberationVotes.find((v) => v?._id === order.vote) as any)?.votes?.map(
                                       (orderVote) => {
                                          return (
                                             <DeliberationVoteBox
                                                userName={`${orderVote.userName}`}
                                                vote={orderVote.vote}
                                             />
                                          );
                                       }
                                    )
                                 ) : (
                                    shareholdersArray.map((shareholder) => {
                                       let hasAnyVote = false;
                                       if (!session.assembly) hasAnyVote = true;
                                       else
                                          for (const affair of affairsArray) {
                                             const shareholderSeries = shareholder.series.map((serie) => serie.title);
                                             const affairSeries = affair.series.map((serie) => {
                                                return { [serie.title]: serie.faculties };
                                             });
                                             shareholderSeries.forEach((shareholderSerie) => {
                                                affairSeries.forEach((affairSerie) => {
                                                   if (affairSerie[shareholderSerie] > 1) hasAnyVote = true;
                                                });
                                             });
                                          }
                                       return (
                                          hasAnyVote && (
                                             <DeliberationVoteBox
                                                userName={shareholder.firstName + " " + shareholder.lastName}
                                                vote={
                                                   valuesFromBill[
                                                      `abstentionVote_user_${shareholder._id}_affair_${order.orderId}`
                                                   ]
                                                      ? "nullVote"
                                                      : valuesFromBill[
                                                           `vote_user_${shareholder._id}_affair_${order.orderId}`
                                                        ]
                                                }
                                             />
                                          )
                                       );
                                    })
                                 )}
                              </Stack>
                           </Stack>
                        );
                     })
                     .filter((v) => v)}
               </Stack>
            </AccordionDetails>
         </Accordion>
      </Stack>
   );
};
