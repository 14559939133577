import { CircularProgress, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { EvaluationContext } from "../../../../context/governanceContext/evaluationContext";
import { StepperProgressEvaluation } from "./StepperProgressEvaluation";
import { QuestionComponent } from "./QuestionComponent";

export const EvaluationQuestionary = ({ steps }) => {
   const { activeCouncil, activePersonEvaluation, councilQuestions, personQuestions, userData } =
      useContext(EvaluationContext);
   const [seed, setSeed] = useState("");

   useEffect(() => {
      setSeed(`${Math.random()}`);
   }, [activePersonEvaluation]);

   return (
      <Stack sx={{ flex: 5, pt: 2 }}>
         <Stack sx={{ px: 8, mr: 2, rowGap: 1 }}>
            <Typography fontWeight={600}>
               En evaluación:{" "}
               {activePersonEvaluation ? (
                  userData.find((user) => user._id === activePersonEvaluation.user)?.firstName +
                  " " +
                  userData.find((user) => user._id === activePersonEvaluation.user)?.lastName
               ) : !activeCouncil ? (
                  <CircularProgress />
               ) : (
                  activeCouncil.title
               )}
            </Typography>
            <StepperProgressEvaluation steps={steps} />
            <Typography sx={{ color: "#c2c8ce", pt: 2 }} fontSize={13} align="center">
               Elige la respuesta correcta en una escala del 1 al 6, siendo 6 la calificación mas alta:
            </Typography>
         </Stack>

         <Stack sx={{ maxHeight: 480, px: 6, overflowY: "auto" }} key={seed}>
            {activePersonEvaluation
               ? personQuestions.map((question, i) => (
                    <QuestionComponent question={question} index={i + 1} key={question._id} />
                 ))
               : councilQuestions.map((question, i) => (
                    <QuestionComponent question={question} index={i + 1} key={question._id} />
                 ))}
         </Stack>
      </Stack>
   );
};
