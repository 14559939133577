import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useState } from "react";
import { BasicInformation } from "./BasicInformation";
import { ProfesionalInformation } from "./ProfesionalInformation";
import { DocumentationComponent } from "./DocumentationComponent";

interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

function TabPanel(props: TabPanelProps) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`vertical-tabpanel-${index}`}
         aria-labelledby={`vertical-tab-${index}`}
         {...other}
         style={{ flex: 3 }}
      >
         {value === index && children}
      </div>
   );
}

function a11yProps(index: number) {
   return {
      id: `vertical-tab-${index}`,
      "aria-controls": `vertical-tabpanel-${index}`,
   };
}

export const LeftTabsComponent = () => {
   const [value, setValue] = useState(0);
   const [seedProfesionalInformation, setSeedProfesionalInformation] = useState(0);

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   return (
      <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex", py: 2 }}>
         <Box sx={{ flex: 1 }}>
            <Tabs
               orientation="vertical"
               value={value}
               onChange={handleChange}
               sx={{
                  "& button[aria-selected='true']": {
                     borderBottom: "2px solid black",
                     maxWidth: 180,
                  },
                  "& .MuiTabs-indicator": {
                     backgroundColor: "transparent",
                  },
                  alignItems: "start",
                  "&& .MuiTab-root": {
                     alignItems: "baseline",
                  },
               }}
            >
               <Tab label="Información básica" {...a11yProps(0)} />
               <Tab label="Información personal" {...a11yProps(1)} />
               <Tab label="Documentación" {...a11yProps(2)} />
            </Tabs>
         </Box>
         <TabPanel value={value} index={0}>
            <BasicInformation />
         </TabPanel>
         <TabPanel value={value} index={1}>
            <ProfesionalInformation
               key={seedProfesionalInformation}
               setSeedProfesionalInformation={setSeedProfesionalInformation}
            />
         </TabPanel>
         <TabPanel value={value} index={2}>
            <DocumentationComponent />
         </TabPanel>
      </Box>
   );
};
