import { Modal, Box, Typography, Divider, Button, Collapse, IconButton } from "@mui/material";
import { useContext, useState } from "react";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import PersonIcon from "@mui/icons-material/Person";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { Form, Formik } from "formik";
import { InputTextField } from "./Inputs/InputTextField";
import { aditionalInformationSchema } from "../lib/validations/inputSchemas";
import { addAditionalInfo } from "../lib/lecosyBackendClient";
import { ComplaintStepperContext } from "../context/complaintStepperContext";
import { SnackBarContext } from "../context/snackBarContext";

interface ComplainantAndWitnessDetailsModalProps {
   open: boolean;
   setOpen: Function;
   info: any[];
   isWitness: boolean;
   onConfirm: (event: React.MouseEvent<HTMLButtonElement>) => void;
   onCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const Accordion = styled((props: AccordionProps) => (
   <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
   backgroundColor: "white",
   border: `1px solid ${theme.palette.divider}`,
   "&:not(:last-child)": {
      borderBottom: 0,
   },
   "&:before": {
      display: "none",
   },
}));

export const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
   backgroundColor: "#f9f9f9",
   borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export const AccordionSummary = styled((props: AccordionSummaryProps) => (
   <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
   borderRadius: 2,
   borderBottom: `1px solid ${theme.palette.divider}`,
   flexDirection: "row-reverse",
   "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
   },
   "& .MuiAccordionSummary-content": {
      marginLeft: theme.spacing(1),
   },
}));

export const ComplainantAndWitnessDetailsModal = (props: ComplainantAndWitnessDetailsModalProps) => {
   const { getComplaint, complaint } = useContext(ComplaintStepperContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [expanded, setExpanded] = useState<string | false>(false);
   const [openAditionalInfo, setOpenAditionalInfo] = useState<boolean>(false);

   const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
   };

   const handleClose = () => {
      props.setOpen(false);
      setOpenAditionalInfo(false);
   };

   const handleAddAditionalInfo = async (values) => {
      await addAditionalInfo(values._id, { email: values.email, phoneNumber: values.phoneNumber });
      showSnackBar("Información adicional fué modificada correctamente.", false);
      getComplaint(complaint.reportNumber);
      setOpenAditionalInfo(false);
   };

   const items = (): any[] => {
      const content: any[] = [];

      props.info.forEach((e, i) => {
         content.push(
            <Accordion
               expanded={expanded === `panel${i}`}
               onChange={handleChange(`panel${i}`)}
               sx={{ borderRadius: 0 }}
            >
               <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                     <PersonIcon sx={{ mr: 1 }} />
                     <Typography fontWeight={600} fontSize={17}>
                        {e.name !== "" ? `${e.name}  ${e.lastName}` : `Testigo ${i + 1}`}
                     </Typography>
                  </Box>
               </AccordionSummary>
               <AccordionDetails>
                  <Box sx={{ px: 2, pt: 1 }}>
                     <Box sx={{ px: 2, mb: 2 }}>
                        <Typography fontSize={17} sx={{ color: "#162c62" }}>
                           Información general
                        </Typography>
                     </Box>
                     <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                        <Box flex={1}>
                           <Typography fontWeight={600}>Género</Typography>
                           <Typography gutterBottom>{e.gender}</Typography>
                        </Box>
                        <Box flex={1}>
                           <Typography fontWeight={600}>Puesto</Typography>
                           <Typography gutterBottom>{e.position}</Typography>
                        </Box>
                     </Box>
                     <Box>
                        <Typography fontWeight={600}>Observaciones</Typography>
                        <Typography gutterBottom>{e.observations}</Typography>
                     </Box>
                     <Divider sx={{ my: 1 }} />
                     <Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mx: 1 }}>
                           <Typography fontSize={17} sx={{ color: "#162c62" }}>
                              Información adicional
                           </Typography>
                           {openAditionalInfo ? (
                              <IconButton onClick={() => setOpenAditionalInfo(false)}>
                                 <CloseIcon />
                              </IconButton>
                           ) : (
                              <IconButton onClick={() => setOpenAditionalInfo(true)}>
                                 <EditIcon />
                              </IconButton>
                           )}
                        </Box>
                        <Collapse in={!openAditionalInfo}>
                           {e.aditionalInformation ? (
                              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                                 <Box flex={1} my={1}>
                                    <Typography fontWeight={600}>Correo electrónico</Typography>
                                    <Typography>{e.aditionalInformation.email}</Typography>
                                 </Box>
                                 <Box flex={1} my={1}>
                                    <Typography fontWeight={600}>Telefono</Typography>
                                    <Typography>{e.aditionalInformation.phoneNumber}</Typography>
                                 </Box>
                              </Box>
                           ) : (
                              <Typography>
                                 {openAditionalInfo ? "" : "Este testigo no tiene información adicional"}
                              </Typography>
                           )}
                        </Collapse>
                        <Collapse in={openAditionalInfo}>
                           <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center", mt: 2 }}></Box>
                           <Formik
                              initialValues={{
                                 _id: e._id,
                                 email: e.aditionalInformation ? e.aditionalInformation.email : "",
                                 phoneNumber: e.aditionalInformation ? e.aditionalInformation.phoneNumber : "",
                              }}
                              validationSchema={aditionalInformationSchema}
                              onSubmit={handleAddAditionalInfo}
                           >
                              <Form>
                                 <Box sx={{ display: "flex" }}>
                                    <InputTextField
                                       name="email"
                                       id="email"
                                       variant="standard"
                                       fullWidth
                                       sx={{ mr: 1 }}
                                       label="Correo electrónico"
                                       type="text"
                                    />
                                    <InputTextField
                                       name="phoneNumber"
                                       id="phoneNumber"
                                       variant="standard"
                                       fullWidth
                                       sx={{ ml: 1 }}
                                       label="Número de teléfono"
                                       type="text"
                                    />
                                 </Box>
                                 <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                                    <Button variant="outlined" type="submit" sx={{ mt: 2 }}>
                                       Guardar
                                    </Button>
                                 </Box>
                              </Form>
                           </Formik>
                        </Collapse>
                     </Box>
                  </Box>
               </AccordionDetails>
            </Accordion>
         );
      });
      return content.map((e) => e);
   };

   const rows = items();

   return (
      <Modal
         open={props.open}
         onClose={handleClose}
         sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
         <Box
            sx={{
               maxWidth: "90%",
               minWidth: 800,
               borderRadius: 1,
               bgcolor: "white",
               p: 2,
               minHeight: 200,
               height: "auto",
            }}
         >
            <Box sx={{ p: 2 }}>
               <Typography variant="h5">
                  {props.isWitness
                     ? `${props.info.length > 1 ? "Información de los testigos" : "Información del testigo"}`
                     : `${props.info.length > 1 ? "Información de los denunciados" : "Información del denunciado"}`}
               </Typography>
            </Box>
            <Divider />
            <Typography sx={{ m: 2 }}>
               Favor de desplegar algún {props.isWitness ? "testigo" : "denunciado"}, con la flecha hacia abajo, para
               ver la información.
            </Typography>
            <Divider sx={{ mx: 2 }} />
            <Box sx={{ maxHeight: "480px", overflowY: "scroll", m: 2 }}>
               {rows.map((e) => {
                  return e;
               })}
            </Box>
            <Box display="flex" justifyContent="flex-start" sx={{ mx: 2 }}>
               <Button variant="contained" onClick={handleClose}>
                  Cerrar
               </Button>
            </Box>
         </Box>
      </Modal>
   );
};
