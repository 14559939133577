import { Add, Close, DeleteForever, FiberManualRecord, Square } from "@mui/icons-material";
import { Box, Button, IconButton, List, Popover, Tooltip, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { TagsModal } from "../Modal/TagsModal";
import { SnackBarContext } from "../../../context/snackBarContext";
import { deleteTag } from "../../../lib/gobCorpBEClient";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { CustomModalComponent } from "../../CustomModalComponent";

export const TagsSelector = ({ tags, valuesTags, setFieldValue, deleteValue, disabled = false }) => {
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [openTagModal, setOpenTagModal] = useState(false);
   const { showSnackBar } = useContext(SnackBarContext);
   const { getUserDetailsData } = useContext(GovernanceContext);

   const handleDetele = async (id) => {
      try {
         await deleteTag(id);
         getUserDetailsData();
         showSnackBar("Exito al borrar la etiqueta", false);
      } catch (error) {
         console.log("id", error);
         showSnackBar("Error al borrar la etiqueta", true);
      }
   };
   const handleClick = () => {
      const element = document.getElementById("selector");
      setAnchorEl(anchorEl ? null : element);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };
   const handleModalClose = () => {
      setOpenTagModal(false);
   };

   const open = Boolean(anchorEl);
   const id = open ? "simple-popper" : undefined;

   return (
      <>
         <Box
            sx={{
               borderRadius: 2,
               display: "flex",
               flexWrap: "wrap",
               alignItems: "center",
               p: 0.5,
               gap: 1,
            }}
            id="selector"
         >
            {disabled ? null : (
               <Button
                  sx={{
                     bgcolor: "#E9EDF5",
                     borderRadius: 2,
                     display: "flex",
                     columnGap: 1,
                     alignItems: "center",
                     px: 1,
                     py: 0.5,
                  }}
                  onClick={handleClick}
                  aria-describedby={id}
               >
                  <FiberManualRecord sx={{ fontSize: 10, color: "black" }} />
                  <Typography fontSize={14}>Seleccionar</Typography>
               </Button>
            )}
            {valuesTags?.length > 0 ? (
               valuesTags?.map((tag, i) => (
                  <Box
                     key={i}
                     sx={{
                        bgcolor: "#E9EDF5",
                        borderRadius: 2,
                        display: "flex",

                        columnGap: 1,
                        alignItems: "center",
                        px: 1,
                        py: 0.5,
                     }}
                  >
                     <FiberManualRecord sx={{ fontSize: 10, color: tag.color }} />
                     <Typography fontSize={14} sx={{ color: tag.color }}>
                        {tag.title}
                     </Typography>
                     <IconButton disabled={disabled} sx={{ p: 0 }} onClick={() => deleteValue(tag._id)}>
                        <Close sx={{ fontSize: 14 }} />
                     </IconButton>
                  </Box>
               ))
            ) : (
               <Box
                  sx={{
                     bgcolor: "#E9EDF5",
                     borderRadius: 2,
                     display: "flex",
                     columnGap: 1,
                     alignItems: "center",
                     px: 1,
                     py: 0.5,
                  }}
               >
                  <Typography fontSize={14}>No hay etiquetas</Typography>
               </Box>
            )}
         </Box>
         <Box>
            <Popover
               id={id}
               open={open}
               anchorEl={anchorEl}
               onClose={handleClose}
               anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
               }}
               sx={{ zIndex: 3000 }}
            >
               <Box
                  sx={{ bgcolor: "white", borderRadius: 2, maxHeight: 150, overflowY: "scroll", maxWidth: 450, px: 1 }}
               >
                  <List>
                     <Box>
                        <Button
                           sx={{
                              display: "flex",
                              columnGap: 0.5,
                              alignItems: "center",
                              width: "100%",
                              justifyContent: "flex-start",
                           }}
                           onClick={() => {
                              setOpenTagModal(true);
                              handleClose();
                           }}
                        >
                           <Add />
                           <Typography fontSize={14}>Agregar nuevo</Typography>
                        </Button>
                     </Box>
                     {tags?.map((tag, i) => (
                        <Box key={i} sx={{ display: "flex" }}>
                           <Button
                              sx={{
                                 display: "flex",
                                 columnGap: 0.5,
                                 alignItems: "center",
                                 width: "100%",
                                 justifyContent: "flex-start",
                              }}
                              onClick={() => {
                                 setFieldValue(tag);
                              }}
                           >
                              <Square sx={{ color: tag.color, fontSize: 14 }} />
                              <Typography fontSize={14} sx={{ color: tag.color }}>
                                 {tag.title}
                              </Typography>
                           </Button>
                           <IconButton>
                              <Tooltip
                                 title={"Eliminar"}
                                 onClick={() => {
                                    handleDetele(tag._id);
                                 }}
                              >
                                 <DeleteForever />
                              </Tooltip>
                           </IconButton>
                        </Box>
                     ))}
                  </List>
               </Box>
            </Popover>
         </Box>
         <CustomModalComponent open={openTagModal} setOpen={setOpenTagModal} title="Agregar etiqueta" timeStamp>
            <TagsModal setOpen={handleModalClose} />
         </CustomModalComponent>
      </>
   );
};
