import { Autocomplete, Box, Chip, MenuItem, Popover, TextField } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { useField, useFormikContext } from "formik";
import { GovernanceSessionContext } from "../../../../context/governanceContext/governanceSessionContext";

interface AutoCompleteTextProps {
   values?: any;
   setFieldValue: Function;
   membersWithCharge: any[];
   id: string;
   name: string;
   index?: number;
   hasPermissions: boolean;
}

const GetBillCommitmentsData = (membersWithCharge, values, setValue, userForEdit, index) => {
   const { externalMemberUsers } = useContext(GovernanceSessionContext);

   const filteredMembersStart = useMemo(
      () =>
         membersWithCharge
            ?.filter(
               (member) =>
                  member.hasOwnProperty("firstName") && !member.memberCharge.includes("Usuario de implementacion")
            )
            .concat(
               externalMemberUsers.map((ex) => {
                  return { _id: ex.user, firstName: ex.name, email: ex.email };
               })
            ) || [],
      [membersWithCharge, externalMemberUsers]
   );

   const [showMenu, setShowMenu] = useState(false);
   const [availableMembers, setAvailableMembers] = useState(filteredMembersStart);
   const [textInputValue, setTextInputValue] = useState("");

   useEffect(() => {
      if (textInputValue) {
         const valuesToArray = textInputValue.split(",");
         setShowMenu(valuesToArray[valuesToArray.length - 1].startsWith("@"));

         if (textInputValue.replace("@", "") === "") setAvailableMembers(filteredMembersStart);
         else {
            const filteredMembers = availableMembers.filter((member) => {
               const firstName = member.firstName || "";
               const lastName = member.lastName || "";
               const searchValue = (textInputValue || "").replace("@", "");
               return firstName.includes(searchValue) || lastName.includes(searchValue);
            });
            if (filteredMembers.length > 0) setAvailableMembers(filteredMembers);
         }
      }
   }, [textInputValue]);

   useEffect(() => {
      if (!userForEdit) setValue(values["responsableName"]);
      else setValue(values["responsableNameEdit" + index]);
   }, [values]);

   return { showMenu, setTextInputValue, setShowMenu, availableMembers, textInputValue };
};

interface IMember {
   firstName: string;
   lastName: string;
   _id: string;
   email?: string;
}

export const BillAutoCompleteText = (props: AutoCompleteTextProps) => {
   const [value, setValue] = useState<IMember[]>(props.values ? props.values.users : []);
   const { values, setFieldValue } = useFormikContext();
   const { setReceivingChanges, receivingChanges } = useContext(GovernanceSessionContext);
   const { showMenu, setTextInputValue, setShowMenu, availableMembers, textInputValue } = GetBillCommitmentsData(
      props.membersWithCharge,
      values,
      setValue,
      props.values.users,
      props.index
   );

   const elementId = props.id;
   const anchorEl = document.getElementById(elementId);
   const [field] = useField(props);

   const handleClose = (member) => {
      const isSelectingAll = member === "Todos";
      const newMembers = isSelectingAll ? availableMembers : [...(values[elementId] || []), member];
      const uniqueNewMembers = newMembers.reduce((acc, current) => {
         if (!acc.some((member) => member._id === current._id)) acc.push(current);
         return acc;
      }, []);
      setValue(isSelectingAll ? availableMembers : (current) => [...current, member]);
      setFieldValue(elementId, uniqueNewMembers);
      if (receivingChanges) setReceivingChanges(false);
      setTextInputValue("");
      setShowMenu(false);
   };

   const handleInputChange = (event) => {
      if (event.target.value.startsWith("@")) setTextInputValue(event.target.value);
      else setTextInputValue("");
   };

   return (
      <Box>
         <Autocomplete
            {...field}
            value={value}
            onChange={(_event, newValue: IMember[]) => {
               setValue(newValue);
               if (receivingChanges) setReceivingChanges(false);
               setFieldValue(elementId, newValue);
            }}
            multiple
            size="small"
            id={props.id}
            options={[]}
            freeSolo
            disableClearable
            disabled={!props.hasPermissions}
            sx={{ maxWidth: 500 }}
            renderTags={(value: IMember[], getTagProps) =>
               value.map((member: IMember, index: number) => (
                  <Chip
                     label={`${member.firstName || member?.email} ${member.lastName || ""}`}
                     {...getTagProps({ index })}
                     size="small"
                  />
               ))
            }
            renderInput={(params) => {
               return (
                  <TextField
                     {...params}
                     id="textInputAutocomplete"
                     variant="standard"
                     placeholder={value?.length === 0 ? "[NOMBRE DEL RESPONSABLE]" : ""}
                     InputProps={{
                        ...params.InputProps,
                        disableUnderline: true,
                        style: { fontSize: 12 },
                     }}
                     inputProps={{ ...params.inputProps, value: textInputValue }}
                     sx={{ border: 0, bgcolor: "#EFEFEF", borderRadius: 0, px: 1, py: 0.25 }}
                     onChange={(e) => {
                        handleInputChange(e);
                     }}
                  />
               );
            }}
         />
         <Popover
            open={showMenu}
            anchorEl={anchorEl}
            onClose={() => {
               setShowMenu(false);
            }}
            anchorOrigin={{
               vertical: "bottom",
               horizontal: "left",
            }}
            disableAutoFocus={true}
            disableEnforceFocus={true}
         >
            <MenuItem
               onClick={() => {
                  handleClose("Todos");
               }}
            >
               {"Todos"}
            </MenuItem>
            {availableMembers.map((member) => (
               <MenuItem onClick={() => handleClose(member)}>{`${member.firstName || member?.email} ${
                  member.lastName || ""
               }`}</MenuItem>
            ))}
         </Popover>
      </Box>
   );
};
