import { Box, Button, MenuItem, Modal, Typography, Grid } from "@mui/material";
import { Formik, Form } from "formik";
import { witnessInfractorSchema } from "../../lib/validations/inputSchemas";
import { InputTextField } from "../Inputs/InputTextField";
import { InputSelectField } from "../Inputs/InputSelectField";
import { createWitness } from "../../lib/lecosyBackendClient";
import { useContext } from "react";
import { ComplaintStepperContext } from "../../context/complaintStepperContext";

interface AddWitnessesModalProps {
   open: boolean;
   setOpen: Function;
}

export const AddWitnessesModal = (props: AddWitnessesModalProps) => {
   const { selectedData, complaint, getFilteredReplica, getFilterWitnesses } = useContext(ComplaintStepperContext);
   const handleSubmit = async (values, actions) => {
      try {
         await createWitness(complaint.reportNumber, {
            name: values.name,
            lastName: values.lastName,
            gender: values.gender,
            position: values.position,
            observations: values.observations,
            createdBy: selectedData.e._id,
         });
         actions.resetForm();
         getFilteredReplica(complaint.reportNumber);
         getFilterWitnesses();
         props.setOpen(false);
      } catch (err) {
         console.log("values", err);
      }
   };

   return (
      <Modal
         open={props.open}
         onClose={() => props.setOpen(false)}
         sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
         <Box borderRadius={4} bgcolor="white" maxWidth={700}>
            <Box px={4} py={2}>
               <Typography variant="h5" fontWeight={600} mt={0.5} letterSpacing={1}>
                  Agregar testigo
               </Typography>
            </Box>
            <Formik
               initialValues={{
                  name: "",
                  lastName: "",
                  gender: "",
                  position: "",
                  observations: "",
               }}
               validationSchema={witnessInfractorSchema}
               onSubmit={handleSubmit}
            >
               <Form>
                  <Box p={2}>
                     <Grid container spacing={1}>
                        <Grid item xs={6}>
                           <InputTextField
                              fullWidth={true}
                              type={"text"}
                              variant="standard"
                              label="Nombre/s"
                              id="name"
                              name="name"
                           />
                        </Grid>
                        <Grid item xs={6}>
                           <InputTextField
                              fullWidth={true}
                              type={"text"}
                              variant="standard"
                              label="Apellido/s"
                              id="lastName"
                              name="lastName"
                           />
                        </Grid>
                        <Grid item xs={6}>
                           <InputSelectField
                              fullWidth={true}
                              variant="standard"
                              label="Género"
                              id="gender"
                              name="gender"
                              labelId="gender"
                           >
                              <MenuItem value={"masculino"}>Masculino</MenuItem>
                              <MenuItem value={"femenino"}>Femenino</MenuItem>
                              <MenuItem value={"indefinido"}>Indefinido</MenuItem>
                           </InputSelectField>
                        </Grid>
                        <Grid item xs={6}>
                           <InputTextField
                              fullWidth={true}
                              type={"text"}
                              variant="standard"
                              label="Puesto"
                              id="position"
                              name="position"
                           />
                        </Grid>
                        <Grid item xs={12}>
                           <Box display="flex">
                              <InputTextField
                                 fullWidth={true}
                                 type={"text"}
                                 variant="standard"
                                 label="Observaciones"
                                 id="observations"
                                 name="observations"
                                 multiline={true}
                              />
                           </Box>
                        </Grid>
                     </Grid>
                  </Box>
                  <Box
                     display="flex"
                     justifyContent="space-between"
                     bgcolor={"#F1F1F1"}
                     sx={{
                        borderBottomLeftRadius: 16,
                        borderBottomRightRadius: 16,
                     }}
                  >
                     <Button
                        variant="text"
                        onClick={() => props.setOpen(false)}
                        sx={{ color: "#d32f2f", flex: 1, py: 2, borderBottomLeftRadius: 16 }}
                     >
                        Cerrar
                     </Button>
                     <Button
                        data-cy="saveBtn"
                        variant="text"
                        sx={{ flex: 2, py: 2, borderBottomRightRadius: 16 }}
                        type="submit"
                     >
                        Guardar
                     </Button>
                  </Box>
               </Form>
            </Formik>
         </Box>
      </Modal>
   );
};
