import { Box, Grid, Typography } from "@mui/material";
import { InputTextField } from "../../../Inputs/InputTextField";
import { GovernanceSessionContext } from "../../../../context/governanceContext/governanceSessionContext";
import { useContext } from "react";
import { getContrastYIQ } from "../../../../const/globalConst";
import { InputCheckBox } from "../../../Inputs/InputCheckBox";
import { useFormikContext } from "formik";

export const ShareholderUrlRecording = () => {
   const { hasPermissions, colors, setReceivingChanges, receivingChanges } = useContext(GovernanceSessionContext);
   const { setFieldValue } = useFormikContext();

   return (
      <Grid container columnSpacing={0.1}>
         <Grid item xs={4}>
            <Typography
               sx={{
                  bgcolor: colors.primary,
                  color: getContrastYIQ(colors.primary),
                  textAlign: "center",
                  fontSize: 10,
                  py: 0.5,
               }}
            >
               LINK DE GRABACIÓN:
            </Typography>
         </Grid>
         <Grid item xs={8}>
            <Box sx={{ my: 0, fontSize: 10 }}>
               <Box
                  sx={{
                     py: 0.2,
                     px: 0.5,
                     fontSize: 10,
                     bgcolor: colors.primary,
                     color: getContrastYIQ(colors.primary),
                  }}
               >
                  <InputCheckBox
                     id={"meetingUrl"}
                     label={""}
                     name={"meetingUrl"}
                     sxformcontrol={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 0.3,
                        p: 0,
                     }}
                     sx={{
                        p: 0,
                        "& .MuiSvgIcon-root": {
                           fontSize: 15,
                        },
                        "&.Mui-checked": {
                           color: getContrastYIQ(colors.primary),
                        },
                        color: getContrastYIQ(colors.primary),
                     }}
                     disabled={!hasPermissions}
                     disablemargin
                     onChange={(e) => {
                        if (receivingChanges) {
                           setReceivingChanges(false);
                        }
                        setFieldValue("meetingUrl", e.target.checked);
                     }}
                  />
               </Box>
            </Box>
         </Grid>
      </Grid>
   );
};
