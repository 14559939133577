import React from "react";
import { Typography, Button, Box } from "@mui/material";
import { CustomModalComponent } from "../../CustomModalComponent";

interface EconomyModalComponentProps {
   state: boolean;
   setState: (open: boolean) => void;
   onConfirm: (open: boolean) => void;
}

export const EconomyModal = (props: EconomyModalComponentProps) => {
   const { state, setState, onConfirm } = props;
   return (
      <CustomModalComponent
         open={state}
         setOpen={setState}
         title="Registro realizado con éxito"
         onClose={() => setState(false)}
      >
         <Box
            sx={{
               display: "flex",
               flexDirection: "column",
               maxHeight: 500,
               width: 500,
               px: 4,
               py: 2,
               rowGap: 2,
               bgcolor: "white",
               overflow: "auto",
            }}
         >
            <Typography sx={{ marginTop: 1 }}>
               Al confirmar se abrirá el portal de <strong>Secretaría de Economía</strong> y <strong>SAT</strong> para
               notificar el registro.
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between", mt: 3 }}>
               <Button onClick={() => onConfirm(false)} sx={{ color: "#969696" }}>
                  Descartar
               </Button>
               <Button onClick={() => onConfirm(true)} variant="contained" color="primary">
                  Confirmar
               </Button>
            </Box>
         </Box>
      </CustomModalComponent>
   );
};

export default EconomyModal;
