import { Box } from "@mui/material";
import { MultiSeriePieChart } from "../../../../charts/multiSeriesPie";

export const ExperienceGraph = ({ answers }) => {
   const value1 = answers.filter((answer) => answer.rangeAnswer === 1 || answer.rangeAnswer === 2).length;
   const value2 = answers.filter((answer) => answer.rangeAnswer === 3 || answer.rangeAnswer === 4).length;
   const value3 = answers.filter((answer) => answer.rangeAnswer === 5 || answer.rangeAnswer === 6).length;
   const total = value1 + value2 + value3;
   return (
      <Box sx={{ position: "relative" }}>
         <Box sx={{ height: 220 }}>
            <MultiSeriePieChart
               scores={[value1, total]}
               labels={["Deficiente"]}
               colors={["#162c44", "#E5E5EF"]}
               chartRef={undefined}
               cutout={99}
            />
         </Box>
         <Box sx={{ position: "absolute", bottom: "12%", left: "0%", right: "0%", height: 170 }}>
            <MultiSeriePieChart
               scores={[value2, total]}
               labels={["Deficiente"]}
               colors={["#868FA0", "#E5E5EF"]}
               chartRef={undefined}
               cutout={75}
            />
         </Box>
         <Box sx={{ position: "absolute", bottom: "25%", left: "0%", right: "0%", height: 110 }}>
            <MultiSeriePieChart
               scores={[value1, total]}
               labels={["Deficiente"]}
               colors={["#A3A3A3", "#E5E5EF"]}
               chartRef={undefined}
               cutout={45}
            />
         </Box>
      </Box>
   );
};
