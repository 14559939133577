import { useContext } from "react";
import { Box, Button, Typography } from "@mui/material";
import { ComplainantAndWitnessCards } from "../ComplainantAndWitnessCards";
import { DocumentsTable } from "../DocumentsTable";
import { DocumentsTableNew } from "../DocumentsTableNew";
import { ComplaintStepperContext } from "../../../context/complaintStepperContext";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";

export const WitnessStep = () => {
   const {
      complaint,
      dataState,
      setDataState,
      witnesses,
      documentation,
      selectedData,
      setOpenCompleteDialog,
      checkDocuments,
   } = useContext(ComplaintStepperContext);

   return (
      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "50vh" }}>
         <Box sx={{ flex: 2 }}>
            {dataState === 0 && witnesses.length > 0 ? (
               <Box sx={{ p: 2 }}>
                  <Box sx={{ height: "280px", overflowY: "scroll", borderRadius: 1 }}>
                     <ComplainantAndWitnessCards data={witnesses} isOffender={false} />
                  </Box>
               </Box>
            ) : (
               <>
                  {witnesses.length === 0 ? (
                     <DocumentsTable
                        index={selectedData.index}
                        rows={[
                           {
                              document: "Constancia de inexistencia",
                              documentName: "constancia-inexistencia-testigo",
                              templateLink: "inexistencia/1",
                           },
                        ]}
                     />
                  ) : (
                     <>
                        <Box
                           sx={{
                              my: 1,
                              px: 2,
                              py: 1,
                              display: "flex",
                              justifyContent: "space-between",
                              bgcolor: "#f8f8f8",
                              borderRadius: 4,
                              alignItems: "center",
                           }}
                        >
                           <Typography fontWeight="400" variant="h5">
                              {selectedData.e.lastName
                                 ? selectedData.e.name + " " + selectedData.e.lastName
                                 : selectedData.e.name}
                           </Typography>
                           <Typography sx={{ display: "flex", alignItems: "center" }}>
                              {`Documentos obligatorios: ${
                                 documentation.find(
                                    (doc) =>
                                       doc.name ===
                                          `constancia-inexistencia-testigo-${complaint.reportNumber}-${selectedData.index}.pdf` ||
                                       doc.name ===
                                          `constancia-incomparecencia-testigo-${complaint.reportNumber}-${selectedData.index}.pdf` ||
                                       doc.name ===
                                          `entrevista-testigo-${complaint.reportNumber}-${selectedData.index}.pdf`
                                 )
                                    ? 1
                                    : 0
                              }/${1}`}
                              {documentation.find(
                                 (doc) =>
                                    doc.name ===
                                       `constancia-inexistencia-testigo-${complaint.reportNumber}-${selectedData.index}.pdf` ||
                                    doc.name ===
                                       `constancia-incomparecencia-testigo-${complaint.reportNumber}-${selectedData.index}.pdf` ||
                                    doc.name ===
                                       `entrevista-testigo-${complaint.reportNumber}-${selectedData.index}.pdf`
                              ) ? (
                                 <CheckCircleOutlineIcon sx={{ ml: 1, color: "green" }} />
                              ) : null}
                           </Typography>
                        </Box>
                        <DocumentsTableNew
                           index={selectedData.index}
                           rows={[
                              {
                                 document: "Notificación de entrevista",
                                 templateLink: `notificacion/testigo/${selectedData.index}`,
                                 documentName: ["entrevista-testigo", "constancia-incomparecencia-testigo"],
                                 fileHeader: "Añadir documento de notificación al testigo",
                              },
                              {
                                 document: "Entrevista",
                                 templateLink: `${selectedData.index}/entrevista/1`,
                                 documentName: ["entrevista-testigo", "constancia-incomparecencia-testigo"],
                                 fileHeader: "Añadir documento de entrevista al testigo",
                              },
                              {
                                 document: "Constancia de incomparecencia",
                                 templateLink: `incomparecencia/1`,
                                 documentName: ["constancia-incomparecencia-testigo", "entrevista-testigo"],
                                 fileHeader: "Añadir documento de constancia de incomparecencia",
                              },
                           ]}
                        />
                     </>
                  )}{" "}
               </>
            )}
         </Box>
         <Box
            sx={{
               display: "flex",
               flexDirection: "row",
               flex: 1,
               mt: 2,
               mx: 2,
               alignItems: "center",
            }}
         >
            {dataState === 0 ? null : (
               <Box sx={{ maxHeight: 30 }}>
                  <Button onClick={() => setDataState(0)} variant="outlined">
                     {`Volver`}
                  </Button>
               </Box>
            )}
            {dataState === 2 ? null : (
               <>
                  <Box sx={{ flex: "1 1 auto" }} />
                  <Box sx={{ maxHeight: 30 }}>
                     <Button
                        disabled={!checkDocuments("testigo")}
                        onClick={() => setOpenCompleteDialog(true)}
                        variant="outlined"
                     >
                        {`Completar testigos`}
                     </Button>
                  </Box>
               </>
            )}
         </Box>
      </Box>
   );
};
