import React, { useCallback, useContext, useMemo } from "react";
import { SnackBarContext } from "../../../context/snackBarContext";
import { useDropzone } from "react-dropzone";
import {
   Box,
   Button,
   Card,
   CardActions,
   CardContent,
   CircularProgress,
   Collapse,
   IconButton,
   Typography,
} from "@mui/material";
import { Delete } from "@mui/icons-material";
import { ListOfTexFields } from "../Inputs/ListOfTexFields";
import { Form, Formik, FormikProps } from "formik";
import { ConfirmationModal } from "../../ConfirmationModal";
import { CreateFile, complaintUploadPdfEvidence } from "../../../lib/usersBEClient";
import { UserContext } from "../../../context/userContext";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { useParams } from "react-router-dom";

export const UploadFile = ({
   folder,
   setReload,
   reload,
   setSelectedFile = null,
   setCloseModal = null,
   setOpenModalFile,
   chat = false,
}) => {
   const { showSnackBar } = useContext(SnackBarContext);
   const { user } = useContext(UserContext);
   const { id } = useParams();
   const { companySelected } = useContext(GovernanceContext);
   const [evidenceBlob, setEvidenceBlob] = React.useState<File[]>([]);
   const [evidences, setEvidences] = React.useState([]);
   const [isLoading, setIsLoading] = React.useState(false);
   const [openModal, setOpenModal] = React.useState(false);
   const [openModalCancel, setOpenModalCancel] = React.useState(false);

   const { mutate } = CreateFile();

   const openEvidence = useMemo(() => {
      return evidenceBlob.length > 0;
   }, [evidenceBlob.length]);

   const onDrop = useCallback(
      (acceptedFiles) => {
         const files = evidenceBlob;
         const fileNames = evidences;
         if (acceptedFiles[0].size === 0) {
            showSnackBar("Archivo invalido", true);
            return;
         }
         if (acceptedFiles[0].type.startsWith("video") && acceptedFiles[0].size > 50000000) {
            showSnackBar("Archivo mayor a 50 MB", true);
            return;
         }
         if (acceptedFiles[0].type.includes("pdf") && acceptedFiles[0].size > 300000000) {
            showSnackBar("Archivo mayor a 300 MB", true);
            return;
         }
         if (acceptedFiles[0].type.includes("mp3") && acceptedFiles[0].size > 5000000) {
            showSnackBar("Archivo mayor a 50 MB", true);
            return;
         }
         files.push(acceptedFiles[0]);
         fileNames.push(acceptedFiles[0].name);
         setEvidenceBlob(files);
         setEvidences(fileNames);
      },
      [evidenceBlob, evidences, showSnackBar]
   );

   const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: {
         "image/*": [".png", ".jpeg", ".jpg"],
         "video/*": [".mp4", ".ogg"],
         "application/pdf": [".pdf"],
         "audio/*": [".mp3"],
      },
   });

   const handleUploadFiles = async (values) => {
      if (!chat) {
         if (evidenceBlob.length === 0) return showSnackBar("Favor de agregar el archivo", true);
         if (values.order.length === 1 && values.order[0] === "") return setOpenModal(true);
         await uploadFile(values.order);
      } else {
         setSelectedFile(evidenceBlob[0]);
         if (setCloseModal) setCloseModal(false);
      }
   };

   const uploadFile = async (metadata) => {
      setOpenModal(false);
      setIsLoading(true);
      const fileExtension = evidenceBlob[0].name.split(".");
      const fileName = fileExtension[0];
      mutate(
         {
            name: `${fileName}.${fileExtension[1].toLowerCase()}`,
            owner: user.id,
            metadata: metadata,
            size: evidenceBlob[0].size,
            type: evidenceBlob[0].type,
            folder: folder,
            fileDirection: `gc/companies/${companySelected}/users/${user.id}/${folder}`,
            governingBody: id,
         },
         {
            onError: (error: any) => {
               showSnackBar("Error al agregar archivo.", true);
               setReload(!reload);
            },
            onSuccess: async (data) => {
               await complaintUploadPdfEvidence(data.urlToUpload, evidenceBlob[0]);
               showSnackBar("El archivo se subió correctamente.", false);
               setReload(!reload);
               setOpenModalFile(false);
            },
         }
      );
      setEvidences([]);
      setEvidenceBlob([]);
      setIsLoading(false);
   };

   const map = evidenceBlob.map((file, index) => (
      <Card
         key={file.name}
         sx={{
            display: "flex",
            bgcolor: "#F9F9F9",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 0.5,
            boxShadow: 0,
            border: 1,
            borderColor: "#E2E2E2",
         }}
      >
         <CardContent>
            <Typography sx={{ fontSize: 14, flexWrap: "nowrap" }}>{file.name}</Typography>
         </CardContent>
         <CardActions>
            <IconButton
               onClick={() => {
                  setEvidenceBlob((prev) => prev.filter((item, i) => i !== index));
                  setEvidences((prev) => prev.filter((item, i) => i !== index));
               }}
            >
               <Delete sx={{ color: "red" }} />
            </IconButton>
         </CardActions>
      </Card>
   ));

   const CloseModals = () => {
      setOpenModalCancel(false);
      if (setOpenModalFile) setOpenModalFile(false);
      if (setCloseModal) setCloseModal(false);
   };

   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
            flex: 1,
            p: 4,
         }}
      >
         <Box
            sx={{
               flex: 1,
               border: 1,
               borderStyle: "dashed",
               minHeight: 70,
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               p: 10,
               borderRadius: 2,
            }}
            {...getRootProps()}
         >
            <Typography variant="body2" color={"grey"}>
               Haga clic para navegar o arrastre y suelte su archivo
            </Typography>
            <input {...getInputProps()} />
         </Box>
         <Collapse in={openEvidence}>
            <Box sx={{ pb: 1, bx: 1, overflowY: "scroll", maxHeight: 130 }}>{map}</Box>
         </Collapse>
         <Formik
            initialValues={{
               order: [""],
            }}
            onSubmit={handleUploadFiles}
         >
            {(formProps: FormikProps<any>) => (
               <Form>
                  <Box>
                     <Typography variant="body2" fontWeight={600}>
                        Metadatos
                     </Typography>
                     <ListOfTexFields
                        listOfData={formProps.values.order}
                        setListOfData={formProps.setFieldValue}
                        inputProps={{
                           id: "order",
                           name: "order",
                        }}
                     />
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between", pt: 1 }}>
                     <Button onClick={() => setOpenModalCancel(true)} variant="outlined">
                        Atras
                     </Button>
                     <Button type="submit" variant="outlined">
                        {isLoading ? <CircularProgress /> : reload ? "Guardar" : "Subir"}
                     </Button>
                  </Box>
               </Form>
            )}
         </Formik>
         <ConfirmationModal
            open={openModal}
            setOpen={setOpenModal}
            onConfirm={() => uploadFile([])}
            body={
               <Box sx={{ pt: 2 }}>
                  <Typography align="center">¿Está seguro que desea guardar el archivo sin metadatos?</Typography>
               </Box>
            }
            title="Confirmar archivo sin metadatos"
            isLoading={isLoading}
         />
         <ConfirmationModal
            open={openModalCancel}
            setOpen={setOpenModalCancel}
            onConfirm={CloseModals}
            body={
               <Box sx={{ pt: 2 }}>
                  <Typography align="center">¿Está seguro que desea salir?</Typography>
               </Box>
            }
            title="Salir"
         />
      </Box>
   );
};
