import {
   Box,
   Button,
   CircularProgress,
   IconButton,
   List,
   ListItem,
   ListItemButton,
   ListItemText,
   Stack,
   Typography,
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import {
   MoveFileQuery,
   MoveFolderQuery,
   getFoldersAndFilesById,
   getFoldersById,
   useGetResourceSelected,
} from "../../../../lib/usersBEClient";
import { getIconByType } from "../../../../screens/Gobierno corporativo/FolderScreen";
import { getDateAndTimeFromISODate } from "../../../../const/globalConst";
import { ArrowBack, Folder } from "@mui/icons-material";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { UserContext } from "../../../../context/userContext";
import { ConfirmationModal } from "../../../ConfirmationModal";
import { useParams } from "react-router-dom";

const useGetFiles = () => {
   const { id: governanceId, companyId } = useParams();
   const { personalSpace, isLoading: isLoadingDetails } = useContext(GovernanceContext);
   const [isLoading, setIsLoading] = useState(true);
   const [id, setId] = useState<any>([]);
   const [files, setFiles] = useState([]);

   useEffect(() => {
      const fetchFolders = async () => {
         setIsLoading(true);
         let lastId = undefined;
         if (id.length > 0) {
            lastId = id[id.length - 1]._id;
         }
         const response = await getFoldersAndFilesById(lastId, companyId, governanceId);
         setFiles([...response.folder.children, ...response.files]);
         setIsLoading(false);
      };
      const fetchFoldersFirstTime = async () => {
         setIsLoading(true);
         const response = await getFoldersById(personalSpace.files);
         if (response) {
            const filteredFolders = response?.filter((folder) => folder.name !== "Mis chats");
            setFiles(filteredFolders);
         } else {
            setFiles([]);
         }
         setIsLoading(false);
      };
      if (!isLoadingDetails && id.length === 0 && !governanceId) {
         fetchFoldersFirstTime();
      } else {
         fetchFolders();
      }
   }, [id.length, isLoadingDetails, personalSpace]);

   const processedData = useMemo(() => {
      const data = [];
      for (const iterator of files) {
         if (iterator.size) {
            const name = iterator.name.split(".");
            name.pop();

            data.push({
               ...iterator,
               name: (
                  <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                     {getIconByType(iterator.type)}
                     <Typography variant="subtitle2">{iterator.name}</Typography>
                  </Box>
               ),
               originalName: name.join("."),
               updatedAt: getDateAndTimeFromISODate(iterator.updatedAt),
               owner: iterator.owner.firstName + " " + iterator.owner.lastName,
               size: (iterator.size / 1024).toFixed(2) + " Kb",
            });
         } else {
            data.push({
               ...iterator,
               type: "carpeta",
               name: (
                  <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                     <Folder sx={{ color: "#F1B44C", fontSize: 25 }} />
                     <Typography variant="subtitle2">{iterator.name}</Typography>
                  </Box>
               ),
               updatedAt: getDateAndTimeFromISODate(iterator.updatedAt),
               size: "---",
               originalName: iterator.name,
            });
         }
      }
      return data;
   }, [files]);

   return { isLoading, processedData, setId, id };
};

export const MoveFile = ({ selectedFile, setOpenModal, reload, setReload, handleClose }) => {
   const { id: governanceId, companyId } = useParams();
   const { processedData, isLoading, setId, id } = useGetFiles();
   const [openModal2, setOpenModal2] = useState(false);
   const resourceId = useGetResourceSelected();
   const lastSelected = id[id.length - 1];
   const { mutate, isLoading: isLoadingMove } = MoveFileQuery(selectedFile._id, resourceId, governanceId);
   const { mutate: mutateFolder, isLoading: isLoadingMoveFolder } = MoveFolderQuery(
      selectedFile._id,
      resourceId,
      companyId
   );
   const { showSnackBar } = useContext(SnackBarContext);
   const { user } = useContext(UserContext);

   const handleMoveFile = () => {
      if (selectedFile.type === "carpeta") {
         mutateFolder(lastSelected._id, {
            onError: (error: any) => {
               showSnackBar("Error al mover el carpeta.", true);
            },
            onSuccess: () => {
               showSnackBar("La carpeta fue movida correctamente.", false);
               setOpenModal(false);
               setReload(!reload);
               handleClose();
            },
         });
      } else {
         mutate(`gc/companies/${companyId}/users/${user.id}/${lastSelected._id}`, {
            onError: (error: any) => {
               showSnackBar("Error al mover el archivo.", true);
            },
            onSuccess: () => {
               showSnackBar("El archivo fue movidó correctamente.", false);
               setOpenModal(false);
               setReload(!reload);
               handleClose();
            },
         });
      }
   };

   return (
      <Stack p={2}>
         {id.length > 0 ? (
            <Box sx={{ py: 1, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
               <IconButton
                  onClick={() =>
                     setId((prev) => {
                        prev.pop();
                        const newValue = [...prev];
                        return newValue;
                     })
                  }
               >
                  <ArrowBack />
               </IconButton>
               <Typography>{lastSelected.originalName}</Typography>
            </Box>
         ) : null}
         <Box sx={{ width: 600, height: 400, overflow: "auto" }}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
               {isLoading ? (
                  <CircularProgress />
               ) : (
                  <List sx={{ flex: 1 }}>
                     {processedData.map((item) => (
                        <ListItemButton
                           onClick={() => {
                              if (item.type === "carpeta") {
                                 setId((prev) => {
                                    const newValue = [...prev, item];
                                    return newValue;
                                 });
                              }
                           }}
                           disabled={item.type !== "carpeta"}
                        >
                           <ListItem>
                              <ListItemText primary={item.name} />
                           </ListItem>
                        </ListItemButton>
                     ))}
                  </List>
               )}
            </Box>
         </Box>
         <Box sx={{ display: "flex", columnGap: 1 }}>
            <Button variant="outlined" fullWidth onClick={() => setOpenModal(false)}>
               Cancelar
            </Button>
            <Button
               variant="contained"
               fullWidth
               onClick={() => {
                  if (id.length > 0) {
                     setOpenModal2(true);
                  } else {
                     showSnackBar("No puede mover a esta carpeta.", true);
                  }
               }}
            >
               {isLoadingMove || isLoadingMoveFolder ? <CircularProgress size={24} sx={{ color: "white" }} /> : "Mover"}
            </Button>
         </Box>
         <ConfirmationModal
            open={openModal2}
            setOpen={setOpenModal2}
            onConfirm={handleMoveFile}
            isLoading={isLoadingMove || isLoadingMoveFolder}
            body={
               <Box sx={{ pt: 2 }}>
                  <Typography align="center">
                     ¿Esta seguro que desea mover el archivo {selectedFile.originalName} a la carpeta{" "}
                     {id.length > 0 ? lastSelected.originalName : null}?
                  </Typography>
               </Box>
            }
            title={"Confirmar cambio"}
         />
      </Stack>
   );
};
