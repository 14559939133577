import { Box, Stack, Tab, Tabs } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { getManyCompaniesById } from "../../../lib/usersBEClient";
import { UserContext } from "../../../context/userContext";
import { CorporateDataContext } from "../../../context/governanceContext/corporateDataContext";
import { ConsultiveGroupScreen } from "../../../screens/Mi Lecosy/Group/ConsultiveGroupScreen";
import { ConsultiveGroupInfo } from "../ConsultiveGroup/ConsultiveGroupInfo";
import { DenounceChannelMembersTable } from "./DenounceChannel/DenounceChannelMembersTable";
import { DenounceChannelCompanies } from "./DenounceChannel/DenounceChannelCompanies";

interface TabPanelProps {
   children?: React.ReactNode;
   index: number;
   value: number;
}

export type userBasicInfo = {
   firstName: string;
   lastName: string;
   phoneNumber: string;
   email: string;
};

const GroupServicesScreen = () => {
   const { user, isImplementationUser } = useContext(UserContext);
   const [value, setValue] = useState(0);
   // const [value, setValue] = useState(!isImplementationUser() ? 0 : 1);
   const { consultiveGroup } = useContext(CorporateDataContext);
   const [governanceExists, setGovernanceExists] = useState(false);

   //VERIFICAR SI ALGUNA EMPRESA DENTRO DEL GOBIERNO TIENE MODULO DE GC
   useEffect(() => {
      const fetchCompanies = async () => {
         const companiesResponse = await getManyCompaniesById(user.group.companies);
         const exists = companiesResponse?.some((c) =>
            c.company_details.servicesDetails.some((s) => s.serviceId === "64e7851d978b71bd4f011ee4" && !s.disabled)
         );
         setGovernanceExists(exists);
      };
      if (user.group) fetchCompanies();
   }, [user.group]);

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setValue(newValue);
   };

   const SubComponent = useMemo(() => {
      if (value === 0) return <DenounceChannelCompanies />;
      if (value === 1) return <ConsultiveGroupScreen />;
      return <Box></Box>;
   }, [value]);

   return (
      <Stack direction={"column"} spacing={2}>
         <Stack sx={{ borderBottomLeftRadius: 4, borderBottomRightRadius: 4, bgcolor: "white", boxShadow: 2 }}>
            <Tabs value={value} onChange={handleChange}>
               <Tab value={0} label={"Canal de denuncias"} />
               {((consultiveGroup && consultiveGroup._id === user.group._id) || governanceExists) && (
                  <Tab value={1} label={"Gobierno corporativo"} />
               )}
            </Tabs>
            <CustomTabPanel value={value} index={0}>
               <DenounceChannelMembersTable />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
               <ConsultiveGroupInfo />
            </CustomTabPanel>
         </Stack>
         {SubComponent}
      </Stack>
   );
};

function CustomTabPanel(props: Readonly<TabPanelProps>) {
   const { children, value, index, ...other } = props;

   return (
      <div
         role="tabpanel"
         hidden={value !== index}
         id={`simple-tabpanel-${index}`}
         aria-labelledby={`simple-tab-${index}`}
         {...other}
      >
         {value === index && <Box>{children}</Box>}
      </div>
   );
}

export default GroupServicesScreen;
