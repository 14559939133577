import { useCallback, useContext, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
   Box,
   Button,
   Grid,
   Typography,
   InputAdornment,
   MenuItem,
   Divider,
   Container,
   ListItemText,
   CircularProgress,
} from "@mui/material";
import { getDateAndTimeFromISODate, getDateFromISODate, toISOString } from "../../../const/globalConst";
import { NotificationDocumentsBar } from "../../../components/notificationsDocumentsBar";
import MapIcon from "@mui/icons-material/Map";
import { incompetenceDeclaration } from "../../../lib/documentsTemplates/acknowledgmentTemplates";
import { Form, Formik, useFormikContext, FormikProps } from "formik";
import { incompetenceSchema } from "../../../lib/validations/inputSchemas";
import { InputTextField } from "../../../components/Inputs/InputTextField";
import { InputSelectField } from "../../../components/Inputs/InputSelectField";
import { SnackBarContext } from "../../../context/snackBarContext";
import { ComplaintStepperContext } from "../../../context/complaintStepperContext";
import pdfMake from "../../../const/pdfMakeFonts";
import { reassignCompetence } from "../../../lib/lecosyBackendClient";
import { UserContext } from "../../../context/userContext";
import { GetSubUsersByComplaintBranch } from "../../../lib/usersBEClient";

export const IncompetenceScreen = () => {
   const { reportNumber, involved } = useParams();
   const { complaint } = useContext(ComplaintStepperContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { user } = useContext(UserContext);
   const navigate = useNavigate();
   const date = new Date();
   const { data: users, isLoading: loadingUsers } = GetSubUsersByComplaintBranch(
      complaint.company || complaint.companyBranch._id,
      !!complaint.company,
      user.group?._id || undefined
   );

   const handleGetOfCumUsers = (competent: boolean) => {
      const cumUsers = users.filter((user: any) => {
         const groupRole = user.role.filter((role) => role.group);
         const hasCdRole = groupRole.some((role) => {
            return role.roles.some((role) =>
               competent ? role.name === "Oficial de cumplimiento" : role.name === "Director" || role.name === "Auditor"
            );
         });
         if (hasCdRole) return true;
         for (const userRole of user.role) {
            if (userRole.company === complaint.company || complaint.companyBranch._id) {
               const isCumUser = userRole.roles.some((uniqueRole) => {
                  return competent
                     ? uniqueRole.name === "Oficial de cumplimiento"
                     : uniqueRole.name === "Director" || uniqueRole.name === "Auditor";
               });
               if (isCumUser) return true;
            }
         }
      });
      return cumUsers;
   };

   const handleGetDirAudUsers = useCallback(
      (competent: boolean) => {
         const cumUsersnt = users.filter((user: any) => {
            const groupRole = user.role.filter((role) => role.group);
            const hasCdRole = groupRole.some((role) => {
               return role.roles.some((role) => (competent ? role.name === "Director" : role.name === "Auditor"));
            });
            if (hasCdRole) return true;
            for (const userRole of user.role) {
               if (userRole.company === complaint.company || complaint.companyBranch._id) {
                  const hasDirAudRole = userRole.roles.some((uniqueRole) => {
                     return competent ? uniqueRole.name === "Director" : uniqueRole.name === "Auditor";
                  });
                  if (hasDirAudRole) return true;
               }
            }
         });
         return cumUsersnt;
      },
      [users]
   );

   const handleGetUserRole = (valuess, ofCum) => {
      const userFound: any = users.find((user: any) => {
         return ofCum ? user._id === valuess.competentName : user._id === valuess.involvedName;
      });
      if (!userFound) return undefined;

      const groupRole = userFound.role.filter((role) => role.group);
      if (groupRole.length > 0) {
         for (const userRole of groupRole) {
            for (const role of userRole.roles) {
               switch (role.name) {
                  case "Oficial de cumplimiento":
                     return "Oficial de cumplimiento";
                  case "Auditor":
                     return "Auditor";
                  case "Director":
                     return "Director";
                  default:
                     break;
               }
            }
         }
      }
      for (const userRoles of userFound.role) {
         if (userRoles.company === (complaint.company || complaint.companyBranch._id)) {
            for (const role of userRoles.roles) {
               switch (role.name) {
                  case "Oficial de cumplimiento":
                     return "Oficial de cumplimiento";
                  case "Auditor":
                     return "Auditor";
                  case "Director":
                     return "Director";
                  default:
                     break;
               }
            }
         }
      }
   };

   const getUserNameById = (userId: string) => {
      const foundUser = users.find((user) => user._id === userId);
      if (!foundUser) return "";
      return `${foundUser.firstName} ${foundUser.lastName}`;
   };

   const handleSendForm = async (values, actions) => {
      showSnackBar("Datos correctos", false);
      try {
         await reassignCompetence(reportNumber, involved, values.competentName, values.involvedName);
         if (involved) {
            let auditorIntern = values.additionalMember;
            let generalDirection = values.additionalMember;
            const competentName = getUserNameById(values.competentName);
            const involvedName = getUserNameById(values.involvedName);
            let involvedUser = involvedName;
            if (handleGetUserRole(values, true) === "Director") generalDirection = competentName;
            else if (handleGetUserRole(values, false) === "Director") generalDirection = involvedName;
            if (handleGetUserRole(values, true) === "Auditor") auditorIntern = competentName;
            else if (handleGetUserRole(values, false) === "Auditor") auditorIntern = involvedName;
            if (involved !== "1") involvedUser = `${user.firstName} ${user.lastName}`;
            const dd = incompetenceDeclaration(
               values.city,
               values.state,
               values.dateTime,
               involvedUser,
               auditorIntern,
               generalDirection,
               involvedName,
               getDateFromISODate(complaint.date),
               complaint.reportNumber,
               involved,
               values.competentWork,
               competentName
            );
            pdfMake.createPdf(dd).open();
         }
         actions.resetForm();
         setTimeout(() => navigate(-1), 1000);
      } catch (error) {
         console.log({ error });
         return showSnackBar("Surgió un problema, intentelo de nuevo más tarde", true);
      }
   };

   const FormObserver: React.FC = () => {
      const { values, setFieldValue } = useFormikContext();

      useEffect(() => {
         const valuess: any = values;
         if (valuess.competentName.length > 0 && involved === "1")
            setFieldValue("competentWork", handleGetUserRole(valuess, true));
         if (valuess.involvedName.length > 0 && involved === "2")
            setFieldValue("involvedJob", handleGetUserRole(valuess, false));
         // eslint-disable-next-line
      }, [values]);

      return null;
   };

   if (loadingUsers) return <CircularProgress size={26} />;
   return (
      <Box p={2}>
         <NotificationDocumentsBar head="Declaración de incompetencia" isScheduleAppointment={false} />
         <Container maxWidth="xl">
            {!loadingUsers ? (
               <Formik
                  initialValues={{
                     state: "",
                     city: "",
                     dateTime: toISOString(date),
                     competentWork: "",
                     competentName: involved === "1" ? "" : "a",
                     involvedName: "",
                     involvedJob: "",
                     additionalMember: "",
                  }}
                  validationSchema={incompetenceSchema(involved === "1")}
                  onSubmit={handleSendForm}
               >
                  {(formProps: FormikProps<any>) => (
                     <Form>
                        <FormObserver />
                        <Box
                           display={"flex"}
                           sx={{
                              px: 4,
                              minWidth: 950,
                              flexDirection: "column",
                              alignItems: "center",
                              m: 2,
                           }}
                        >
                           <Box
                              sx={{
                                 width: "100%",
                                 bgcolor: "#FFFFFF",
                                 borderRadius: 2,
                                 boxShadow: "2px 2px 5px #AFAFAF",
                              }}
                           >
                              <Grid container>
                                 <Grid item xs={6}>
                                    <Box display={"flex"} sx={dataFieldSX}>
                                       <Typography fontWeight={500} variant="body1">
                                          Reporte de la denuncia
                                       </Typography>
                                       <Typography variant="subtitle1">{reportNumber}</Typography>
                                    </Box>
                                 </Grid>
                                 <Grid item xs={6}>
                                    <Box display={"flex"} sx={dataFieldSX}>
                                       <Typography fontWeight={500} variant="body1">
                                          Fecha de interposición
                                       </Typography>
                                       <Typography variant="subtitle1">
                                          {getDateAndTimeFromISODate(complaint.createdAt)}
                                       </Typography>
                                    </Box>
                                 </Grid>
                              </Grid>
                              <Divider sx={{ mb: 3 }} variant="middle" />
                              <Grid container>
                                 <Grid
                                    item
                                    xs={4}
                                    sx={{
                                       display: "flex",
                                       flexDirection: "row",
                                       alignItems: "center",
                                       justifyContent: "space-between",
                                    }}
                                 >
                                    <InputTextField
                                       id="state"
                                       name="state"
                                       type="text"
                                       variant="standard"
                                       label="Estado"
                                       fullWidth={true}
                                       sx={{ ml: 2 }}
                                       InputProps={{
                                          startAdornment: (
                                             <InputAdornment position="start">
                                                <MapIcon />
                                             </InputAdornment>
                                          ),
                                       }}
                                    />
                                 </Grid>
                                 <Grid
                                    item
                                    xs={4}
                                    sx={{
                                       display: "flex",
                                       flexDirection: "row",
                                       alignItems: "center",
                                       justifyContent: "space-between",
                                       pr: 2,
                                    }}
                                 >
                                    <InputTextField
                                       id="city"
                                       name="city"
                                       type="text"
                                       variant="standard"
                                       label="Ciudad"
                                       fullWidth={true}
                                       sx={{ ml: 2 }}
                                       InputProps={{
                                          startAdornment: (
                                             <InputAdornment position="start">
                                                <MapIcon />
                                             </InputAdornment>
                                          ),
                                       }}
                                    />
                                 </Grid>
                                 <Grid
                                    item
                                    xs={4}
                                    sx={{
                                       display: "flex",
                                       flexDirection: "row",
                                       alignItems: "center",
                                       justifyContent: "space-between",
                                    }}
                                 >
                                    <InputTextField
                                       variant="standard"
                                       name="dateTime"
                                       fullWidth
                                       id="dateTime"
                                       label="Fecha y hora"
                                       type="datetime-local"
                                       InputLabelProps={{
                                          shrink: true,
                                       }}
                                       sx={{ mr: 2 }}
                                    />
                                 </Grid>
                              </Grid>
                              <Divider sx={{ mt: 3 }} variant="middle" />
                              <Grid container>
                                 <Grid item xs={12} sx={{ display: "flex", px: 2, pt: 2.5, pb: 1 }}>
                                    <Typography color={"#2d4357"} fontWeight={500} variant="h6">
                                       Datos del involucrado
                                    </Typography>
                                 </Grid>
                                 <Grid item xs={7}>
                                    <Box display={"flex"} sx={dataFieldSX}>
                                       <InputSelectField
                                          id="involvedName"
                                          name="involvedName"
                                          variant={"standard"}
                                          fullWidth
                                          label="Nombre"
                                          labelId="demo-select"
                                       >
                                          {handleGetOfCumUsers(involved === "1").map((e: any) => (
                                             <MenuItem key={e.firstName + e.lastName} value={e._id}>
                                                <ListItemText primary={`${e.firstName} ${e.lastName}`} />
                                             </MenuItem>
                                          ))}
                                       </InputSelectField>
                                    </Box>
                                 </Grid>
                                 <Grid item xs={5}>
                                    <Box display={"flex"} sx={dataFieldSX}>
                                       {involved === "1" ? (
                                          <>
                                             <Typography fontWeight={500} variant="body1" sx={{ textAlign: "center" }}>
                                                Puesto:&nbsp;
                                             </Typography>
                                             <Typography variant="subtitle1">Oficial de cumplimiento</Typography>
                                          </>
                                       ) : (
                                          <Box display={"flex"} sx={dataFieldSX}>
                                             <Typography>Puesto</Typography>
                                             <Typography
                                                sx={{
                                                   textTransform: "capitalize",
                                                }}
                                             >
                                                {formProps.values.involvedJob
                                                   ? formProps.values.involvedJob
                                                   : "No definido"}
                                             </Typography>
                                          </Box>
                                       )}
                                    </Box>
                                 </Grid>
                              </Grid>
                              {involved === "1" ? (
                                 <>
                                    <Divider sx={{ mt: 3 }} variant="middle" />
                                    <Grid container sx={{ display: "flex", justifyContent: "center" }}>
                                       <Grid item xs={12} sx={{ display: "flex", px: 2, py: 1 }}>
                                          <Typography color={"#2d4357"} fontWeight={500} variant="h6">
                                             Asignar oficial de cumplimiento
                                          </Typography>
                                       </Grid>
                                       <Grid item xs={7}>
                                          <Box display={"flex"} sx={dataFieldSX}>
                                             <InputSelectField
                                                id="competentName"
                                                name="competentName"
                                                label="Nombre"
                                                labelId="competentName"
                                                variant={"standard"}
                                                fullWidth
                                             >
                                                {handleGetOfCumUsers(false).map((e: any) => (
                                                   <MenuItem key={e.firstName + e.lastName} value={e._id}>
                                                      <ListItemText primary={`${e.firstName} ${e.lastName}`} />
                                                   </MenuItem>
                                                ))}
                                             </InputSelectField>
                                          </Box>
                                       </Grid>
                                       <Grid item xs={5}>
                                          <Box display={"flex"} sx={dataFieldSX}>
                                             <Typography>Puesto:</Typography>
                                             <Typography
                                                sx={{
                                                   textTransform: "capitalize",
                                                }}
                                             >
                                                {formProps.values.competentWork
                                                   ? formProps.values.competentWork
                                                   : "No definido"}
                                             </Typography>
                                          </Box>
                                       </Grid>
                                    </Grid>
                                 </>
                              ) : (
                                 <></>
                              )}
                              {(involved === "1" && formProps.values.competentName) ||
                              (involved === "2" && formProps.values.involvedName) ? (
                                 <Grid container>
                                    <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                       <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                          {involved === "1"
                                             ? formProps.values.competentWork !== "Director"
                                                ? "Director"
                                                : "Auditor"
                                             : formProps.values.involvedJob !== "Director"
                                             ? "Director"
                                             : "Auditor"}
                                       </Typography>
                                    </Grid>
                                    <Grid item xs={2} />
                                    <Grid item xs={8}>
                                       <Box display={"flex"} sx={dataFieldSX}>
                                          <InputSelectField
                                             id="additionalMember"
                                             name="additionalMember"
                                             label="Nombre"
                                             labelId="additionalMember"
                                             variant={"standard"}
                                             fullWidth
                                          >
                                             {handleGetDirAudUsers(
                                                involved === "1"
                                                   ? formProps.values.competentWork === "Director"
                                                      ? false
                                                      : true
                                                   : formProps.values.involvedJob === "Director"
                                                   ? false
                                                   : true
                                             ).map((e: any) => (
                                                <MenuItem
                                                   key={e.firstName + e.lastName}
                                                   value={`${e.firstName} ${e.lastName}`}
                                                >
                                                   <ListItemText primary={`${e.firstName} ${e.lastName}`} />
                                                </MenuItem>
                                             ))}
                                          </InputSelectField>
                                       </Box>
                                    </Grid>
                                 </Grid>
                              ) : undefined}
                           </Box>
                           <Box display={"flex"} sx={{ justifyContent: "flex-end", width: "100%" }}>
                              <Button variant="contained" sx={{ mt: 2 }} type="submit">
                                 Enviar
                              </Button>
                           </Box>
                        </Box>
                     </Form>
                  )}
               </Formik>
            ) : (
               <CircularProgress size={24} />
            )}
         </Container>
      </Box>
   );
};

const dataFieldSX = {
   alignItems: "center",
   justifyContent: "center",
   flexDirection: "column",
   py: 2,
   px: 4,
};
