import { Box, Typography } from "@mui/material";
import { SessionMember } from "./SubComponents/SessionMember";
import { GovernanceSessionContext } from "../../../context/governanceContext/governanceSessionContext";
import { useCallback, useContext, useEffect, useMemo, useState } from "react";

const GetSessionMembers = () => {
   const { membersWithCharge, session, usersOnline } = useContext(GovernanceSessionContext);

   let interUsers =
      useMemo(() => membersWithCharge?.filter((member) => member.firstName), [membersWithCharge, session]) || [];

   const usersWithStatus = useMemo(() => {
      let memberMap = {};

      session.usersRegistry.forEach((member) => {
         memberMap[member.user] = member.status;
      });
      const userWithStatusNotInOrder = membersWithCharge?.map((member) => {
         return { ...member, status: memberMap[member._id] ? memberMap[member._id] : member.status };
      });
      return userWithStatusNotInOrder?.sort((a, b) => {
         if (!a.status || !b.status) return 0;
         const statusA = a.status.toUpperCase();
         const statusB = b.status.toUpperCase();
         if (statusA < statusB) {
            return -1;
         }
         if (statusA > statusB) {
            return 1;
         }

         return 0;
      });
   }, [membersWithCharge]);

   const attendedNumber = useMemo(() => {
      const attendedList = session.usersRegistry.filter((user) =>
         interUsers.some((userIntern) => userIntern._id === user.user && usersOnline.includes(userIntern._id))
      );
      const attendedListExternals = session.externs.filter((extern) => usersOnline.includes(extern.user));
      return attendedList.length + attendedListExternals.length;
   }, [interUsers, session, usersOnline]);

   return { usersWithStatus, attendedNumber };
};

export const SessionMembers = () => {
   const { session, externalMemberUsers } = useContext(GovernanceSessionContext);
   const { usersWithStatus, attendedNumber } = GetSessionMembers();

   return (
      <Box
         sx={{
            height: "40%",
            minHeight: session.completed === false && "70vh",
            bgcolor: session.completed === false && "#F8F8F9",
            border: session.completed === false && 1,
            borderColor: session.completed === false && "#E0E0E0",
         }}
      >
         <Box
            sx={{
               bgcolor: "#F8F8F9",
               border: 1,
               borderColor: "#E0E0E0",
               p: 2,
            }}
         >
            <Box sx={{ display: "flex", justifyContent: "space-between", alignItem: "center" }}>
               <Typography sx={{ fontWeight: 600 }}>Invitados</Typography>
            </Box>
            <Box sx={{ display: "flex", gap: 2 }}>
               <Typography sx={{ fontSize: 12, color: "#64748B" }}>{attendedNumber} confirmados</Typography>
               <Typography sx={{ fontSize: 12, color: "#64748B" }}>
                  {session.usersRegistry.length + session.externs.length - attendedNumber} sin confirmar
               </Typography>
            </Box>
         </Box>
         <Box
            sx={{
               bgcolor: "white",
               border: 0.65,
               borderTop: 0,
               borderColor: "#CBD5E0",
               height: "78%",
               overflowY: "auto",
            }}
         >
            {usersWithStatus &&
               usersWithStatus
                  .concat(externalMemberUsers)
                  .map((member, index) => <SessionMember key={index} member={member} />)}
         </Box>
      </Box>
   );
};
