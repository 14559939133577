import { useState } from "react";
import { Box, Divider, Typography } from "@mui/material";
import { RecentEventComponent } from "./SubComponents/RecentEventComponent";

export const RightSideMenu = ({ recentEvents, schedulerRef }) => {
   const [showAllThisMonth, setShowAllThisMonth] = useState(false);
   const [showAllAfterMonth, setShowAllAfterMonth] = useState(false);
   const [showAllOther, setShowAllOther] = useState(false);

   const toggleShowAllThisMonth = () => setShowAllThisMonth(!showAllThisMonth);
   const toggleShowAllAfterMonth = () => setShowAllAfterMonth(!showAllAfterMonth);
   const toggleShowAllOther = () => setShowAllOther(!showAllOther);

   return (
      <Box
         sx={{
            bgcolor: "white",
            flex: 1,
            boxShadow: 2,
            borderRadius: 1,
            p: 1,
            maxHeight: "100vh",
            overflowY: "auto",
         }}
      >
         <Typography sx={{ fontWeight: 600, p: 1 }} variant="body1">
            Agenda próxima
         </Typography>
         <Divider />
         <Box
            sx={{
               mt: 1,
               display: "flex",
               flexDirection: "column",
               rowGap: 1,
               p: 1,
            }}
         >
            <Box>
               <Typography variant="caption" sx={{ p: 0.5, border: 1, borderColor: "gray", borderRadius: 3 }}>
                  Este mes
               </Typography>
            </Box>
            {recentEvents.thisMonth &&
               (recentEvents.thisMonth.length > 0 ? (
                  <>
                     {recentEvents.thisMonth
                        .slice(0, showAllThisMonth ? recentEvents.thisMonth.length : 4)
                        .map((event, i) => (
                           <RecentEventComponent
                              key={i}
                              color={event.color}
                              date={
                                 event.start.toLocaleTimeString("es-MX", {
                                    timeStyle: "short",
                                    hour12: true,
                                 }) +
                                 " - " +
                                 event.start.toLocaleDateString("es-MX", {
                                    month: "long",
                                    day: "numeric",
                                    year: "numeric",
                                 })
                              }
                              title={event.title}
                           />
                        ))}
                     {recentEvents.thisMonth.length > 4 && (
                        <Box sx={{ pl: 2, cursor: "pointer" }} onClick={toggleShowAllThisMonth}>
                           <Typography variant="caption" sx={{ textDecoration: "underline" }}>
                              {showAllThisMonth
                                 ? "Mostrar menos"
                                 : `${recentEvents.thisMonth.length - 4} ${
                                      recentEvents.thisMonth.length - 4 === 1 ? "evento más" : "eventos más"
                                   }`}
                           </Typography>
                        </Box>
                     )}
                  </>
               ) : (
                  <Typography variant="caption" color="gray">
                     Sin eventos en el mes
                  </Typography>
               ))}

            <Divider />
            <Box>
               <Typography variant="caption" sx={{ p: 0.5, border: 1, borderColor: "gray", borderRadius: 3 }}>
                  Próximo mes
               </Typography>
            </Box>
            {recentEvents.afterMonth &&
               (recentEvents.afterMonth.length > 0 ? (
                  <>
                     {recentEvents.afterMonth
                        .slice(0, showAllAfterMonth ? recentEvents.afterMonth.length : 3)
                        .map((event, i) => (
                           <RecentEventComponent
                              key={i}
                              color={event.color}
                              date={
                                 event.start.toLocaleTimeString("es-MX", {
                                    timeStyle: "short",
                                    hour12: true,
                                 }) +
                                 " - " +
                                 event.start.toLocaleDateString("es-MX", {
                                    month: "long",
                                    day: "numeric",
                                    year: "numeric",
                                 })
                              }
                              title={event.title}
                           />
                        ))}
                     {recentEvents.afterMonth.length > 3 && (
                        <Box sx={{ pl: 2, cursor: "pointer" }} onClick={toggleShowAllAfterMonth}>
                           <Typography variant="caption" sx={{ textDecoration: "underline" }}>
                              {showAllAfterMonth
                                 ? "Mostrar menos"
                                 : `${recentEvents.afterMonth.length - 3} ${
                                      recentEvents.afterMonth.length - 3 === 1 ? "evento más" : "eventos más"
                                   }`}
                           </Typography>
                        </Box>
                     )}
                  </>
               ) : (
                  <Typography variant="caption" color="gray">
                     Sin eventos en el mes
                  </Typography>
               ))}

            {recentEvents.other &&
               (recentEvents.other.length > 0 ? (
                  <>
                     <Divider />
                     <Box>
                        <Typography variant="caption" sx={{ p: 0.5, border: 1, borderColor: "gray", borderRadius: 3 }}>
                           Mes de{" "}
                           {recentEvents.other[0].start
                              .toLocaleDateString("es-MX", {
                                 month: "long",
                              })
                              .charAt(0)
                              .toUpperCase() +
                              recentEvents.other[0].start
                                 .toLocaleDateString("es-MX", {
                                    month: "long",
                                 })
                                 .slice(1)}
                        </Typography>
                     </Box>
                     {recentEvents.other.slice(0, showAllOther ? recentEvents.other.length : 1).map((event, i) => (
                        <RecentEventComponent
                           key={i}
                           color={event.color}
                           date={
                              event.start.toLocaleTimeString("es-MX", {
                                 timeStyle: "short",
                                 hour12: true,
                              }) +
                              " - " +
                              event.start.toLocaleDateString("es-MX", {
                                 month: "long",
                                 day: "numeric",
                                 year: "numeric",
                              })
                           }
                           title={event.title}
                        />
                     ))}
                     {recentEvents.other.length > 1 && (
                        <Box sx={{ pl: 2, cursor: "pointer" }} onClick={toggleShowAllOther}>
                           <Typography variant="caption" sx={{ textDecoration: "underline" }}>
                              {showAllOther
                                 ? "Mostrar menos"
                                 : `${recentEvents.other.length - 1} ${
                                      recentEvents.other.length - 1 === 1 ? "evento más" : "eventos más"
                                   }`}
                           </Typography>
                        </Box>
                     )}
                  </>
               ) : (
                  <></>
               ))}
         </Box>
      </Box>
   );
};
