import { useContext, useState, useEffect } from "react";
import { createFolder, getFoldersAndFilesById, getUrlS3GC, moveFolder } from "../../../lib/usersBEClient";
import { HeadCell, TableComponent } from "../../TableComponent";
import { MoreVert } from "@mui/icons-material";
import { Form, useNavigate, useParams } from "react-router-dom";
import { pdfjs } from "react-pdf";
import { FileVisualizer } from "./SubComponents/FileVisualizer";
import { Box, Button, Menu, MenuItem, Modal, Stack, Typography, Snackbar, Alert, AlertColor } from "@mui/material";
import CheckIcon from "@mui/icons-material/Check";
import CloseIcon from "@mui/icons-material/Close";
import UpdateIcon from "@mui/icons-material/Update";
import { UpdatePersonalFilesByUserId } from "../../../lib/gobCorpBEClient";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { Formik, FormikProps } from "formik";
import { InputTextField } from "../../Inputs/InputTextField";

interface IMyFiles {
   files: any[];
   isLoading: boolean;
   reload: boolean;
   setReload: Function;
   move?: boolean;
   setSelectedFolder?: Function;
   setSeed: Function;
}

interface Notification {
   open: boolean;
   message: string;
   severity: AlertColor;
}

export const MyFilesVerified = (props: IMyFiles) => {
   pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const [selected, setSelected] = useState(null);
   const [openPDF, setOpenPDF] = useState(false);
   const [openImage, setOpenImage] = useState(false);
   const [openVideo, setOpenVideo] = useState(false);
   const [openOther, setOpenOther] = useState(false);
   const [pdfUrl, setPdfUrl] = useState("");
   const [fileNames, setFileNames] = useState("");
   const [audio, setAudio] = useState(false);
   const { personalFilesId } = useParams();
   const { mutate } = UpdatePersonalFilesByUserId(personalFilesId);
   const { companySelected } = useContext(GovernanceContext);
   const [modalINE, setModalINE] = useState(false);
   const [selectedFileToOpen, setSelectedFileToOpen] = useState(null);
   const [notification, setNotification] = useState<Notification>({ open: false, message: "", severity: "success" });

   const getFileSignedUrl = async (folder: string, fileKey: string, fileType: string) => {
      const imagesUrlResponse = await getUrlS3GC("files-lecosy", { folder: folder }, fileKey);
      if (fileType.includes("pdf")) {
         setOpenPDF(true);
         setOpenImage(false);
      } else if (fileType.includes("png")) {
         setOpenImage(true);
         setOpenPDF(false);
         // } else if (fileType.startsWith("video")) {
         //    setOpenVideo(true);
         //    setAudio(false);
         // } else if (fileType.startsWith("audio")) {
         //    setOpenVideo(true);
         //    setAudio(true);
      } else {
         setOpenOther(true);
      }
      setFileNames(fileKey);
      setPdfUrl(imagesUrlResponse);
   };

   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleCloseNotification = () => {
      setNotification({ ...notification, open: false });
   };

   const handleSubmitINENumber = (values) => {
      const size = selected.size.split(" ")[0];
      const valuesData = {
         [selected.originalName]: {
            size: (size * 1024).toString(),
            name: `${selected.originalName}.${selected.type.toLowerCase()}`,
            verified: true,
            ineNumber: values.ineNumber,
         },
      };
      updateFile(valuesData);
   };

   const handleClick = (verified: boolean, isUpdateRequest: boolean = false) => {
      const size = selected.size.split(" ")[0];
      const values = {
         [selected.originalName]: {
            size: (size * 1024).toString(),
            name: `${selected.originalName}.${selected.type.toLowerCase()}`,
            verified: verified,
            rejected: !verified,
            updateFile: isUpdateRequest,
         },
      };
      updateFile(values, verified, isUpdateRequest);
   };

   const updateFile = (values, verified?: boolean, isUpdateRequest?: boolean) => {
      if (selected.size !== "---") {
         mutate(values, {
            onError: (error: any) => {
               console.log(error);
            },
            onSuccess: (data) => {
               setModalINE(false);
               props.setSeed(Math.random());
               if (verified && !isUpdateRequest) {
                  setNotification({ open: true, message: "Archivo verificado con éxito", severity: "success" });
               } else if (verified === true && isUpdateRequest === true) {
                  setNotification({
                     open: true,
                     message: "Solicitud enviada con éxito",
                     severity: "success",
                  });
               } else {
                  setNotification({ open: true, message: "Archivo rechazado con éxito", severity: "error" });
               }
            },
         });
      }
   };

   const handleCloseModal = () => {
      setModalINE(false);
   };

   const handleMenuClick = (event, row) => {
      if (row.updatedAt !== "Archivo pendiente de subir") {
         setAnchorEl(event.currentTarget);
         setSelected(row);
      }
   };

   const usersColumns: HeadCell[] = [
      { field: "name", headerName: "Nombre del archivo", type: "string", align: "left" },
      { field: "updatedAt", headerName: "Fecha de modificación", type: "string", align: "center" },
      { field: "type", headerName: "Tipo de archivo", type: "string", align: "center" },
      { field: "owner", headerName: "Propietario", type: "string", align: "center" },
      { field: "metadata", headerName: "Metadatos", type: "chips" },
      { field: "size", headerName: "Tamaño", type: "string", align: "center" },
      {
         field: "action",
         headerName: "Acción",
         type: "button",
         align: "center",
         icon: <MoreVert />,
         onClick: (e, row) => {
            handleMenuClick(e, row);
         },
      },
   ];

   const moveColumns: HeadCell[] = [
      { field: "name", headerName: "Nombre del archivo", type: "string", align: "left" },
      { field: "updatedAt", headerName: "Fecha de modificación", type: "string" },
      { field: "type", headerName: "Tipo de archivo", type: "string" },
      { field: "owner", headerName: "Propietario", type: "string" },
   ];

   const navigate = useNavigate();

   return (
      <>
         <TableComponent
            defaultColumnToOrder="name"
            defaultOrder="asc"
            headerColor
            defaultRowsPerPage={props.move ? 5 : 10}
            rowsPerPageOptions={props.move ? [5] : [5, 10, 20, 50]}
            headers={props.move ? moveColumns : usersColumns}
            rows={props.files}
            onClick={(_e, r) => {
               const type = r.type.toLowerCase();
               if (r.isEmpty === undefined) {
                  const url = `gc/users/${r.ownerId}/files`;
                  getFileSignedUrl(url, `${r.originalName}.${type}`, type);
                  if (props.move) {
                     setSelectedFileToOpen(r);
                     r.fileDirection
                        ? getFileSignedUrl(r.fileDirection, `${r.originalName}.${type}`, type)
                        : navigate(`/gobierno-corporativo/${companySelected}/mi-cuenta/archivos/folder/${r._id}`);
                  }
               }
            }}
            loader={props.isLoading}
            emptyDataText="No existen archivos verificados en esta carpeta"
            cursor={"default"}
         />
         <FileVisualizer
            audio={audio}
            fileNames={fileNames}
            pdfUrl={pdfUrl}
            setOpenImage={setOpenImage}
            setOpenOther={setOpenOther}
            setOpenPDF={setOpenPDF}
            setOpenVideo={setOpenVideo}
            openImage={openImage}
            openOther={openOther}
            openPDF={openPDF}
            openVideo={openVideo}
            selectedFile={selectedFileToOpen}
         />
         <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
               "aria-labelledby": "basic-button",
            }}
         >
            {selected && selected.isEmpty === undefined && selected.updatedAt !== "Archivo pendiente de subir" && (
               <>
                  {selected.verified === undefined ? (
                     <>
                        <MenuItem
                           onClick={() => {
                              handleClose();
                              handleClick(true);
                           }}
                           sx={{ display: "flex", gap: 1 }}
                        >
                           <CheckIcon sx={{ color: "green" }} />
                           Verificar
                        </MenuItem>
                        <MenuItem
                           onClick={() => {
                              handleClose();
                              handleClick(false);
                           }}
                           sx={{ display: "flex", gap: 1 }}
                        >
                           <CloseIcon sx={{ color: "red" }} /> Rechazar
                        </MenuItem>
                     </>
                  ) : selected.verified ? (
                     <MenuItem
                        onClick={() => {
                           handleClose();
                           handleClick(true, true);
                        }}
                        sx={{ display: "flex", gap: 1 }}
                     >
                        <UpdateIcon sx={{ color: "orange" }} />
                        Solicitar actualizar
                     </MenuItem>
                  ) : (
                     <MenuItem
                        onClick={() => {
                           handleClose();
                           handleClick(true);
                        }}
                        sx={{ display: "flex", gap: 1 }}
                     >
                        <CheckIcon sx={{ color: "green" }} />
                        Verificar
                     </MenuItem>
                  )}
               </>
            )}
         </Menu>

         <Modal
            open={modalINE}
            sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}
            onClose={handleCloseModal}
         >
            <Box
               sx={{
                  width: "50%",
                  height: "50%",
               }}
            >
               <Box
                  sx={{
                     bgcolor: "#162c44",
                     borderTopLeftRadius: 25,
                     borderTopRightRadius: 25,
                  }}
               >
                  <Typography sx={{ color: "white", p: 2, fontSize: 18, fontWeight: "600" }}>
                     Credencia de Elector
                  </Typography>
               </Box>
               <Box
                  sx={{
                     bgcolor: "white",
                     borderBottomLeftRadius: 25,
                     borderBottomRightRadius: 25,
                     py: 2,
                     px: 4,
                  }}
               >
                  <Typography sx={{ mb: 1 }}>Favor de agregar el numero de la credencial de elector </Typography>
                  <Formik initialValues={{ ineNumber: "" }} onSubmit={handleSubmitINENumber}>
                     {(formProps: FormikProps<any>) => (
                        <Form>
                           <Stack spacing={3}>
                              <InputTextField
                                 id={"ineNumber"}
                                 name={"ineNumber"}
                                 type={"text"}
                                 label="Numero de la INE"
                                 sx={{ width: "70%" }}
                              ></InputTextField>
                              <Button
                                 type="submit"
                                 variant="contained"
                                 sx={{
                                    bgcolor: "#162c44",
                                    py: 1,
                                    borderRadius: 1,
                                    color: "white",
                                    width: "30%",
                                    alignSelf: "center",
                                 }}
                                 onClick={formProps.submitForm}
                              >
                                 Guardar
                              </Button>
                           </Stack>
                        </Form>
                     )}
                  </Formik>
               </Box>
            </Box>
         </Modal>
         <Snackbar
            open={notification.open}
            autoHideDuration={6000}
            onClose={handleCloseNotification}
            anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
         >
            <Alert onClose={handleCloseNotification} severity={notification.severity}>
               {notification.message}
            </Alert>
         </Snackbar>
      </>
   );
};
