import { Box, Divider, Stack, Tab, Tabs, Typography } from "@mui/material";
import { TabPanel } from "../../../screens/Mi Lecosy/ClientInfoScreenNew";
import AssignedCommitmentList from "./SubComponents/AssignedCommitmentsList";
import SessionAssistenceChart from "./SubComponents/PerformanceGraphs/SessionAssistenceChart";
import { useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

interface AgreementCommitmentSectionProps {
   chartRef?: any;
}

const AgreementCommitmentSection = (props: AgreementCommitmentSectionProps) => {
   const [selectedTrimester, setSelectedTrimester] = useState();
   const { chartRef } = props;

   return (
      <Box bgcolor={"white"} borderColor={"#B5B5B5"} borderRadius={3}>
         <Tabs value={"1"} textColor="primary">
            <Tab label="Cumplimiento de Acuerdos y compromisos" value={"1"} />
         </Tabs>
         <Divider sx={{ borderBottomWidth: 2 }} />
         <TabPanel index={1} value={1}>
            <Stack direction={"row"} spacing={5} p={3}>
               <Box flex={2}>
                  <SessionAssistenceChart chartRef={chartRef} setSelectedTrimester={setSelectedTrimester} />
               </Box>
               <Box flex={4} display={"flex"} alignSelf={"center"} bgcolor={"white"} minHeight={"250px"}>
                  {selectedTrimester ? (
                     <AssignedCommitmentList selectedTrimester={selectedTrimester} />
                  ) : (
                     <Stack alignItems={"center"} width={"100%"} justifyContent={"center"}>
                        <InfoOutlinedIcon sx={{ color: "#4B5563", fontSize: "80px" }} />
                        <Typography textAlign={"center"} maxWidth={"300px"}>
                           Selecciona el periodo en la gráfica izquierda para visualizar la información
                        </Typography>
                     </Stack>
                  )}
               </Box>
            </Stack>
         </TabPanel>
      </Box>
   );
};

export default AgreementCommitmentSection;
