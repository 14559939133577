import { Autocomplete, Box, TextField } from "@mui/material";
import React, { useState } from "react";

interface UserSearchSelectorProps {
   users: any[];
   label?: string;
   placeholder?: string;
   isLoading: boolean;
   setSelectedUser: Function;
   setReset?: Function;
   disabled?: boolean;
}

export const UserSearchSelector = (props: UserSearchSelectorProps) => {
   return (
      <>
         <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <React.Fragment>
               <Autocomplete
                  size="small"
                  options={props.users}
                  loading={props.isLoading}
                  disabled={props.disabled}
                  onChange={(e, user) => {
                     props.setSelectedUser(user);
                  }}
                  getOptionLabel={(user) => `${user.firstName} ${user.lastName}`}
                  renderInput={(params) => (
                     <TextField {...params} label={props.label} placeholder={props.placeholder} />
                  )}
               />
            </React.Fragment>
         </Box>
      </>
   );
};
