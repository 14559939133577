import { Box, Checkbox, Chip, IconButton, Stack, Tooltip, Typography } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import LaunchRoundedIcon from "@mui/icons-material/LaunchRounded";
import { TableComponentProps } from "../TableComponent";
import { capitalizeFirstLetter, getDateFromISODate, tableStatusColors } from "../../const/globalConst";
import { Check, Circle, Close } from "@mui/icons-material";

interface TableComponentContentProps {
   isUpRow: boolean;
   isItemSelected: boolean;
   labelId: string;
   header: any;
   setIsOn?: Function;
   rowData?: any;
   onClick?: any;
}

const centeredDiv: React.CSSProperties = { display: "flex", alignItems: "center", justifyContent: "center" };

const getArray = (text: any[]) => {
   if (text === undefined) return "No tiene";
   const rolName: any[] = [];
   for (const role of text) {
      rolName.push(role.name);
   }
   const data = rolName.join(", ");
   return data;
};

export const DateTypeComponent = (row, mapProps: TableComponentContentProps) => {
   return getDateFromISODate(row[mapProps.header.field]);
};

export const StringNumberTypeComponent = (row, mapProps: TableComponentContentProps) => {
   return <>{row[mapProps.header.field]}</>;
};

export const StringWithNumberOfChildren = (row, mapProps: TableComponentContentProps) => {
   return (
      <Box sx={{ columnGap: 1, display: "flex", alignItems: "center" }}>
         <Typography>{row[mapProps.header.field]}</Typography>{" "}
         <Chip label={row.collapsible.length} size="small" sx={{ bgcolor: "#162c44", color: "white" }} />
      </Box>
   );
};

export const BooleanComponent = (row, mapProps: TableComponentContentProps) => {
   return (
      <>
         {row[mapProps.header.field] ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
               Inhabilitado&nbsp;
               <Close color="warning" fontSize="small" />
            </Box>
         ) : (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
               Habilitado&nbsp;
               <Check color="success" fontSize="small" />
            </Box>
         )}
      </>
   );
};

export const CollapsibleTypeComponent = (props: TableComponentProps, row) => {
   return (
      <IconButton
         aria-label="expand row"
         size="small"
         onClick={() => props.setOpened(row.id === props.opened ? "" : row.id)}
      >
         {props.opened === row.id ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
      </IconButton>
   );
};

export const CheckTypeComponent = (mapProps: TableComponentContentProps) => {
   return (
      <Checkbox
         color="primary"
         checked={mapProps.isUpRow ? true : mapProps.isItemSelected}
         inputProps={{
            "aria-labelledby": mapProps.labelId,
         }}
         sx={{ p: "0px !important", fontSize: 2, m: 0 }}
         onClick={(e) => mapProps.onClick(e, mapProps.rowData)}
      />
   );
};

export const ButtonTypeComponent = (props: TableComponentProps, mapProps: TableComponentContentProps, row) => {
   return (
      <IconButton
         onClick={(e) =>
            props.icon ? props.onClick(e, row) : mapProps.header.icon ? mapProps.header.onClick(e, row) : null
         }
         onMouseEnter={() => mapProps.setIsOn(true)}
         onMouseLeave={() => mapProps.setIsOn(false)}
         sx={{ p: 0 }}
         size="small"
      >
         {mapProps.header.icon ? mapProps.header.icon : props.icon}
      </IconButton>
   );
};

export const ImageTypeComponent = (row, mapProps: TableComponentContentProps, props: TableComponentProps) => {
   return (
      <>
         <img
            style={{ maxWidth: 100, maxHeight: 50 }}
            src={row[mapProps.header.field] as string}
            alt={`${row.name} logo`}
            onClick={(e) => props.onClick(e, row)}
         />
         {capitalizeFirstLetter(row.status)}
      </>
   );
};

export const GroupTypeComponent = (row, props: TableComponentProps, mapProps: TableComponentContentProps) => {
   return (
      <IconButton
         sx={{ ":hover": { background: "none" } }}
         onClick={(e) => {
            props.onClick(e, row);
         }}
      >
         <Typography sx={{ fontSize: 14, fontWeight: "bold" }}>{row[mapProps.header.field]}</Typography>
         <LaunchRoundedIcon sx={{ fontSize: 17, ml: 2 }} />
      </IconButton>
   );
};

export const StatusTypeComponent = (row) => {
   return (
      <div style={centeredDiv}>
         <Circle
            sx={{
               width: 10,
               color: tableStatusColors(row.status),
               mr: 1,
            }}
         />
      </div>
   );
};

export const ArrayTypeComponent = (row, mapProps: TableComponentContentProps) => {
   return <>{getArray(row[mapProps.header.field])}</>;
};

export const ChipsTypeComponent = (row, mapProps: TableComponentContentProps) => {
   return <>{getArrayChips(row[mapProps.header.field])}</>;
};

const getArrayChips = (text: any[]) => {
   if (text === undefined || text.length === 0) return "Sin datos";
   const rolName: any[] = [];

   const additionalMetadata = [];

   for (let i = 0; i < text.length; i++) {
      if (i < 2) {
         rolName.push(<Chip label={text[i]} />);
      } else {
         additionalMetadata.push(text[i]);
      }
   }
   if (additionalMetadata.length > 0) {
      rolName.push(
         <Tooltip
            title={
               <Stack>
                  {additionalMetadata.map((add) => (
                     <Typography>{add}</Typography>
                  ))}
               </Stack>
            }
         >
            <Chip label={"..."} />
         </Tooltip>
      );
   }
   return rolName;
};
