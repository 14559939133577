import { Box, Chip, FormControl, FormHelperText, Typography } from "@mui/material";
import { useField } from "formik";
import React, { useMemo } from "react";

interface IUsersSelector {
   inputProps: any;
   affairSelected: any[];
}

function findUnique(arr, predicate) {
   var found = {};
   arr.forEach((d) => {
      found[predicate(d)] = d;
   });
   return Object.keys(found).map((key) => found[key]);
}

export const SeriesSelect = ({ inputProps, affairSelected }: IUsersSelector) => {
   const [_field, meta] = useField(inputProps);

   const newArray = useMemo(() => {
      const newSeries = [];
      {
         affairSelected.length > 0 &&
            affairSelected.forEach((affair) => {
               newSeries.push(...affair.affair.series);
            });
      }
      return findUnique(newSeries, (d) => d.title);
   }, [affairSelected]);

   return (
      <React.Fragment>
         <Typography variant="body2" fontWeight={600}>
            Invitados
         </Typography>
         <Box sx={{ display: "flex", columnGap: 1 }}>
            <FormControl fullWidth>
               <Box
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     width: "100%",
                     border: 0.2,
                     borderColor: meta.error && meta.touched ? "red" : "#CBD5E0",
                     borderRadius: 1,
                  }}
               >
                  <Box
                     sx={{
                        flex: 2,
                        py: 1,
                        pl: 1,
                     }}
                     id="selector-users"
                  >
                     {newArray.length > 0 ? (
                        newArray.map((ser) => (
                           <Chip
                              label={ser.title}
                              sx={{
                                 mx: 0.5,
                              }}
                           />
                        ))
                     ) : (
                        <Typography>Sin series para mostrar</Typography>
                     )}
                  </Box>
               </Box>
               <FormHelperText error={meta.error && meta.touched ? true : false} sx={{ pl: 5 }}>
                  {meta.error && meta.touched ? meta.error : null}
               </FormHelperText>
            </FormControl>
         </Box>
      </React.Fragment>
   );
};
