import {
   Button,
   CircularProgress,
   Container,
   Divider,
   Fab,
   IconButton,
   Stack,
   Typography,
   Tooltip,
   Modal,
} from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { ConfirmationModal } from "../components/ConfirmationModal";
import { UserContext } from "../context/userContext";
import { SnackBarContext } from "../context/snackBarContext";
import { getUrlS3, updateUserPassword } from "../lib/usersBEClient";
import { userChangePasswordSchema } from "../lib/validations/inputSchemas";
import { InputTextField } from "../components/Inputs/InputTextField";
import { SystemThemeConfig } from "../config/systemTheme.config";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { SupportContext } from "../context/supportContext";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import InfoIcon from "@mui/icons-material/Info";

export const ValidatePasswordScreen = () => {
   const logo = "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/logo-lecosy.png";
   const navigate = useNavigate();
   const { logoutUser, setIsAuthenticated, setLoggedIn } = useContext(UserContext);
   const { setSupportOpen } = useContext(SupportContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [isLoading, setIsLoading] = useState(false);
   const [logoutIsLoading, setLogoutIsLoading] = useState(false);
   const [showPwd, setShowPwd] = useState(false);
   const [showNewPwd, setShowNewPwd] = useState(false);
   const [logoutModalOpen, setLogoutModalOpen] = useState(false);
   const [openConfirmationModal, setOpenConfirmationModal] = useState(false);
   const [openTerms, setOpenTerms] = useState<boolean>(true);
   const [termsUrl, setTermsUrl] = useState<string>("");

   useEffect(() => {
      const fetchTerms = async () => {
         const termsSignedUrl = await getUrlS3(
            "files-lecosy",
            { folder: "common" },
            "terminos-y-condiciones-de-uso.pdf"
         );
         setTermsUrl(termsSignedUrl);
      };
      fetchTerms();
   }, []);

   const [data, setData] = useState({
      passwordOriginal: "",
      passwordNew: "",
   });

   const handleOpenConfirmationModal = (values) => {
      setData({
         passwordNew: values.passwordNew,
         passwordOriginal: values.passwordOriginal,
      });
      setOpenConfirmationModal(true);
   };
   const handleLogOutModalOpen = () => setLogoutModalOpen(true);
   const onLogoutButtonPress = () => {
      setLogoutIsLoading(true);
      logoutUser();
      setLogoutIsLoading(false);
      setIsAuthenticated(false);
      navigate("/login");
   };
   const modifyUserPassword = async () => {
      try {
         setIsLoading(true);
         await updateUserPassword(data.passwordOriginal, data.passwordNew);
         showSnackBar("Contraseña actualizada", false);
         setIsLoading(false);
         setTimeout(() => {
            logoutUser().then(() => {
               setLoggedIn(false);
               navigate("/login");
            });
         }, 2000);
      } catch (error: any) {
         if (error.response.status === 400) {
            showSnackBar("Contraseña incorrecta", true);
            setIsLoading(false);
            setOpenConfirmationModal(false);
            return null;
         }
      }
   };
   return (
      <SystemThemeConfig>
         <Container
            maxWidth={"xl"}
            sx={{
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               height: "97vh",
               bgcolor: "whitesmoke",
               flexWrap: "wrap",
            }}
         >
            <Box flex={1} sx={{ display: "flex", justifyContent: "center" }}>
               <Box component={"img"} sx={{ maxHeight: 250, maxWidth: 350 }} alt="Logo Lecosy" src={logo} />
            </Box>
            <Box
               sx={{
                  maxWidth: "700px",
                  minWidth: "500px",
                  flex: 1,
                  boxShadow: 2,
                  borderRadius: 2,
                  bgcolor: "white",
                  px: 8,
                  py: 4,
               }}
            >
               <Typography variant="h5" textAlign={"center"} sx={{ fontWeight: "bold", color: "#162c44", py: 2 }}>
                  Crea una nueva contraseña
               </Typography>
               <Divider color="black" variant="middle" sx={{ my: 1 }} />
               <Formik
                  initialValues={{
                     passwordOriginal: "",
                     passwordNew: "",
                     passwordNewConfirmation: "",
                  }}
                  onSubmit={handleOpenConfirmationModal}
                  validationSchema={userChangePasswordSchema}
               >
                  <Form>
                     <Stack spacing={2}>
                        <InputTextField
                           fullWidth
                           type={showPwd ? "text" : "password"}
                           name="passwordOriginal"
                           id="passwordOriginal"
                           variant="standard"
                           label="Contraseña actual"
                           size="small"
                           endAdornment={
                              <IconButton size="small" onClick={() => setShowPwd(!showPwd)}>
                                 {showPwd ? <VisibilityOff /> : <Visibility />}
                              </IconButton>
                           }
                        />
                        <InputTextField
                           fullWidth
                           type={showNewPwd ? "text" : "password"}
                           name="passwordNew"
                           id="passwordNew"
                           variant="standard"
                           label="Nueva contraseña"
                           size="small"
                           endAdornment={
                              <>
                                 <IconButton size="small" onClick={() => setShowNewPwd(!showNewPwd)}>
                                    {showNewPwd ? <VisibilityOff /> : <Visibility />}
                                 </IconButton>
                                 <Tooltip
                                    title={
                                       "Se requiere al menos una mayúscula, una minúscula, un dígito, un carácter especial y no espacios en blanco."
                                    }
                                 >
                                    <InfoIcon fontSize="small" color="action" />
                                 </Tooltip>
                              </>
                           }
                        />
                        <InputTextField
                           fullWidth
                           type={showNewPwd ? "text" : "password"}
                           name="passwordNewConfirmation"
                           id="passwordNewConfirmation"
                           variant="standard"
                           label="Confirmar contraseña"
                           size="small"
                        />
                     </Stack>
                     <Modal open={openTerms} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <Stack bgcolor="white" borderRadius="8px 8px 8px 8px" minWidth="70%" minHeight="80%">
                           <Box component={"iframe"} flex={1} src={termsUrl} sx={{ width: "100%", height: "100%" }} />
                           <Stack direction="row" p={1} justifyContent="space-between" alignItems="center">
                              <Typography>Es necesario aceptar los términos y condiciones para seguir</Typography>
                              <Button variant="contained" onClick={() => setOpenTerms((prev) => !prev)}>
                                 Acepto los términos y condiciones
                              </Button>
                           </Stack>
                        </Stack>
                     </Modal>
                     <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", mt: 2 }}>
                           <Button variant="contained" onClick={handleLogOutModalOpen}>
                              Salir
                           </Button>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                           <Button variant="contained" type="submit" disabled={openTerms}>
                              {!isLoading ? "Guardar" : <CircularProgress size={24} color="success" />}
                           </Button>
                        </Box>
                     </Box>
                  </Form>
               </Formik>
            </Box>
            <ConfirmationModal
               open={openConfirmationModal}
               setOpen={setOpenConfirmationModal}
               title="Nueva contraseña"
               body={
                  <Box sx={{ textAlign: "center" }}>
                     <Typography>Estás a punto de cambiar tu contraseña.</Typography>
                     <Typography>Deberás iniciar sesión nuevamente con la nueva contraseña.</Typography>
                  </Box>
               }
               onConfirm={modifyUserPassword}
               isLoading={isLoading}
            />
            <ConfirmationModal
               open={logoutModalOpen}
               setOpen={setLogoutModalOpen}
               onConfirm={onLogoutButtonPress}
               title={"Cerrar sesión"}
               body={"¿Estás seguro que deseas salir?"}
               isLoading={logoutIsLoading}
            />
         </Container>
         <Box
            sx={{
               position: "absolute",
               right: 40,
               bottom: 40,
            }}
         >
            <Fab
               onClick={() => {
                  setSupportOpen(true);
               }}
               sx={{
                  bgcolor: "#162c44",
                  "&:hover": {
                     bgcolor: "#162c44",
                  },
               }}
            >
               <SupportAgentIcon sx={{ color: "white" }} />
            </Fab>
         </Box>
      </SystemThemeConfig>
   );
};
