import { Box, Button, Grid, Stack, Typography } from "@mui/material";
import { Formik, FormikProps } from "formik";
import { useState, useContext } from "react";
import { Form } from "react-router-dom";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { SnackBarContext } from "../../../context/snackBarContext";
import { UserContext } from "../../../context/userContext";
import { createNewAdvice, updateAdvice } from "../../../lib/gobCorpBEClient";
import { getAllResources, useGetResourceSelected } from "../../../lib/usersBEClient";
import { AdviceSchema } from "../../../lib/validations/GobCop/inputSchemas";
import { InputTextField } from "../../Inputs/InputTextField";
import TextEditor from "../../Inputs/TextEditor/TextEditorOnlyList";

interface ModalHeaderComponentProps {
   open: boolean;
   setOpen: Function;
   adviceData?: any;
}

export const ModalAddAdviceComponent = (props: ModalHeaderComponentProps) => {
   const { user } = useContext(UserContext);
   const { refetchTasks, setRefetchTasks, companySelected, selectedGovernance } = useContext(GovernanceContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [text, setText] = useState(props.adviceData ? props.adviceData.description : "");
   const [isLoading, setIsLoading] = useState(false);

   const handleSubmit = async (values) => {
      const resourceResponse = await getAllResources();
      const resourceId = resourceResponse.find((r) => r.name === "Tablero de avisos")._id;
      if (resourceId.trim().length === 0) return showSnackBar("Error al crear aviso", true);
      const newAdvice = {
         title: values.title,
         description: values.description,
         owner: user.id,
         goberningBody: selectedGovernance._id,
      };
      setIsLoading(true);
      try {
         await createNewAdvice(newAdvice, resourceId, companySelected);
         showSnackBar("Aviso creado correctamente", false);
         setTimeout(() => {
            setRefetchTasks(!refetchTasks);
            setIsLoading(false);
            props.setOpen(false);
         }, 1000);
      } catch (error) {
         console.log(error);
         showSnackBar("Error al crear aviso", true);
         setIsLoading(false);
      }
   };

   const handleSubmitModify = async (values) => {
      const resourceResponse = await getAllResources();
      const resourceId = resourceResponse.find((r) => r.name === "Tablero de avisos")._id;
      if (resourceId.trim().length === 0) return showSnackBar("Error al actualizar aviso", true);
      const newAdvice = {
         title: values.title,
         description: values.description,
         owner: user.id,
         goberningBody: selectedGovernance._id,
      };
      setIsLoading(true);
      try {
         await updateAdvice(props.adviceData._id, newAdvice, resourceId, companySelected);
         showSnackBar("Aviso actualizado correctamente", false);
         setTimeout(() => {
            setRefetchTasks(!refetchTasks);
            setIsLoading(false);
            props.setOpen(false);
         }, 1000);
      } catch (error) {
         console.log(error);
         showSnackBar("Error al actualizar aviso", true);
         setIsLoading(false);
      }
   };

   return (
      <>
         <Formik
            initialValues={{
               title: props.adviceData ? props.adviceData.title : "",
               description: props.adviceData ? props.adviceData.description : "",
            }}
            onSubmit={props.adviceData ? handleSubmitModify : handleSubmit}
            validationSchema={AdviceSchema}
         >
            {(formProps: FormikProps<any>) => (
               <Form>
                  <Box sx={{ py: 2, maxHeight: 450, width: 600, overflowY: "auto", bgcolor: "white" }}>
                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "center",
                           flexDirection: "column",
                           px: 3,
                           maxHeight: 600,
                           a: 600,
                        }}
                     >
                        <Grid container columnSpacing={4} rowSpacing={3}>
                           <Grid item xs={12}>
                              <Box
                                 sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    rowGap: 1,
                                 }}
                              >
                                 <Typography fontSize={14} fontWeight={600}>
                                    Titulo
                                 </Typography>
                                 <InputTextField id="title" name="title" type="text" fullWidth size="small" />
                              </Box>
                           </Grid>
                           <Grid item xs={12}>
                              <Box
                                 sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "flex-start",
                                    rowGap: 1,
                                 }}
                              >
                                 <Typography variant="body2" fontWeight={600}>
                                    Descripción
                                 </Typography>
                                 <TextEditor
                                    setText={(e) => {
                                       setText(e);
                                       formProps.setFieldValue("description", e);
                                    }}
                                    text={text}
                                 />
                              </Box>
                           </Grid>
                        </Grid>
                     </Box>
                  </Box>
                  <Stack
                     spacing={2}
                     direction={"row"}
                     sx={{
                        justifyContent: "flex-end",
                        bgcolor: "#F3F3F3",
                        px: 2,
                        borderBottomRightRadius: 5,
                        borderBottomLeftRadius: 5,
                        py: 1,
                     }}
                  >
                     <Button onClick={() => props.setOpen(false)} disabled={isLoading}>
                        <Typography fontSize={14}>Cancelar</Typography>
                     </Button>

                     <Button
                        type="submit"
                        disabled={isLoading}
                        onClick={(e) => {
                           e.preventDefault();
                           formProps.submitForm();
                        }}
                     >
                        <Typography fontSize={14}>Guardar cambios</Typography>
                     </Button>
                  </Stack>
               </Form>
            )}
         </Formik>
      </>
   );
};
