import { Typography, Box, Button, Divider } from "@mui/material";
import { useNavigate } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useContext } from "react";
import { ComplaintStepperContext } from "../context/complaintStepperContext";

interface NotificationDocumntsBarProps {
   head: React.ReactNode;
   isScheduleAppointment: boolean;
   main?: boolean;
}

export const NotificationDocumentsBar = (props: NotificationDocumntsBarProps) => {
   const { setDataState, setSubSteps, setReplicaOpen, setReplicaSubStep } = useContext(ComplaintStepperContext);
   const navigate = useNavigate();

   return (
      <>
         <Box
            sx={{
               display: "flex",
               px: 3,
               borderRadius: 4,
               justifyContent: "space-between",
               textTransform: "none",
            }}
         >
            <Box sx={{ display: "flex", alignItems: "center" }}>
               <Button
                  variant="contained"
                  sx={{ minWidth: 5, height: 30, backgroundColor: "#142c44" }}
                  onClick={() => {
                     if (props.main) {
                        setDataState(0);
                        setReplicaOpen(false);
                        setReplicaSubStep(false);
                        setSubSteps(0);
                     }
                     navigate(-1);
                  }}
               >
                  <ArrowBackIcon sx={{ width: 20 }} />
               </Button>
               <Typography variant="h6" sx={{ ml: 1 }}>
                  {props.head}
               </Typography>
            </Box>
         </Box>
         <Divider sx={{ my: 1 }} variant="middle" />
      </>
   );
};
