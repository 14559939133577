import ChartDataLabels from "chartjs-plugin-datalabels";
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend,
   Filler,
   ArcElement,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(
   ArcElement,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   ChartDataLabels,
   Title,
   Tooltip,
   Legend,
   Filler
);

export const DoughnutAssignmentChart = ({ scores, labels, colors, chartRef }) => {
   const textCenter = {
      id: "textCenter",
      afterDatasetsDraw(chart) {
         const { ctx } = chart;
         ctx.save();
         ctx.font = "bolder 20px open sans";
         ctx.fillStyle = colors[1];
         ctx.textAlign = "center";
         ctx.textBaseline = "middle";
         ctx.fillText(scores[1], chart.getDatasetMeta(0).data[0].x, chart.getDatasetMeta(0).data[0].y);
         ctx.restore();
      },
   };

   return (
      <Doughnut
         ref={chartRef}
         data={{
            datasets: [
               {
                  label: "Mis datos",
                  data: scores,
                  backgroundColor: colors,
                  borderColor: "#ffffff",
                  hoverOffset: 3,
                  normalized: true,
                  circular: true,
               },
            ],
            labels,
         }}
         plugins={[textCenter]}
         options={{
            plugins: {
               datalabels: {
                  display: false,
                  textStrokeWidth: 1,
                  color: "white",
               },
               tooltip: { enabled: false },
               legend: { display: false },
            },
            responsive: true,
            maintainAspectRatio: false,
            cutout: 24,
         }}
      />
   );
};
