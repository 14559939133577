import { Box, Container } from "@mui/material";
import React, { useState } from "react";
import { NotificationBar } from "../components/notificationsBar";
import { NotificationTable } from "../components/Notifications/NotificationTable";
import { MenuOptions } from "../components/Notifications/MenuOptions";

export const NotificationScreen = () => {
   const [filter, setFilter] = useState("all");
   return (
      <Box p={2}>
         <NotificationBar content={<></>} head="Notificaciones" />
         <Container maxWidth="xl">
            <Box sx={{ display: "flex", py: 4, px: 1 }}>
               <Box sx={{ flex: 1 }}>
                  <MenuOptions setFilter={setFilter} filter={filter} />
               </Box>
               <Box sx={{ flex: 4 }}>
                  <NotificationTable filter={filter} />
               </Box>
            </Box>
         </Container>
      </Box>
   );
};
