import { Box, CircularProgress, Stack } from "@mui/material";
import { LeftBarComponent } from "./LeftBarComponent";
import { Chat } from "./Chat";
import { useContext } from "react";
import { ChatContext } from "../../../context/governanceContext/ChatContext";

export const ChatComponent = () => {
   const { isUserChatsLoading } = useContext(ChatContext);

   if (isUserChatsLoading) return <CircularProgress />;
   return (
      <Box sx={{ display: "flex", columnGap: 2 }}>
         <LeftBarComponent />
         <Chat />
      </Box>
   );
};
