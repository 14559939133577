import { useState } from "react";
import { Box, ToggleButton } from "@mui/material";
import Stack from "@mui/material/Stack";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Typography from "@mui/material/Typography";
import { InvoiceTableComponent } from "./InvoiceTableComponent";
import { ReceiptTableComponent } from "./ReceiptTableComponent";

interface ClientStatusAccountProps {
   company?: any;
}

export default function ClientStatusAccount(props: ClientStatusAccountProps) {
   const [alignment, setAlignment] = useState("Facturas");

   const handleAlignment = (event: React.MouseEvent<HTMLElement>, newAlignment: string | null) => {
      if (newAlignment !== null) {
         setAlignment(newAlignment);
      }
   };

   return (
      <>
         <Stack direction="row" spacing={4} sx={{ mt: 2, display: "flex" }}>
            <ToggleButtonGroup
               value={alignment}
               exclusive
               onChange={handleAlignment}
               sx={{ display: "flex", justifyContent: "space-between", flex: 1, bgcolor: "white" }}
            >
               <ToggleButton value="Facturas" sx={{ flex: 1 }}>
                  <Typography>Facturas</Typography>
               </ToggleButton>
               <ToggleButton value="Comprobante" sx={{ flex: 1 }}>
                  <Typography>Complemento de pago</Typography>
               </ToggleButton>
            </ToggleButtonGroup>
         </Stack>

         {alignment === "Facturas" ? (
            <>
               <Box mt={2}>
                  <InvoiceTableComponent company={props.company ? props.company : null} />
               </Box>
            </>
         ) : (
            <>
               <Box mt={2}>
                  <ReceiptTableComponent company={props.company ? props.company : null} />
               </Box>
            </>
         )}
      </>
   );
}
