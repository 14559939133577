import { Chart as ChartJS, LinearScale, BarElement, Title, Tooltip, Legend, Filler } from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";

ChartJS.register(LinearScale, BarElement, ChartDataLabels, Title, Tooltip, Legend, Filler);

export const BarChart = (props) => {
   return (
      <Bar
         ref={props.chartRef}
         data={{
            datasets: [
               {
                  label: props.labelNames,
                  data: props.scores,
                  backgroundColor: props.colors,
                  normalized: true,
                  borderRadius: 8,
                  barThickness: 40,
               },
            ],
            labels: props.labels,
         }}
         options={{
            plugins: {
               legend: { display: false },
               datalabels: {
                  display: false,
                  font: { size: 15, weight: "bold" },
                  backgroundColor: "#ffffff",
                  borderRadius: 3,
                  borderWidth: 3,
                  align: "top",
                  color: "black",
                  // formatter: (value) => {
                  //    return value || null;
                  // },
               },
            },
            responsive: true,
            maintainAspectRatio: false,
            scales: {
               x: { min: 0, grid: { color: "transparent" } },
               y: { grid: { color: "transparent" } },
            },
         }}
      />
   );
};
