import { Box, Button, CircularProgress, Grid, MenuItem } from "@mui/material";
import { InputTextField } from "../../Inputs/InputTextField";
import { Form, Formik, FormikProps } from "formik";
import { InputSelectField } from "../../Inputs/InputSelectField";
import { BasicInformationSchema } from "../../../lib/validations/inputSchemas";
import { UserContext } from "../../../context/userContext";
import { useContext, useEffect, useState } from "react";
import {
   getUserDetailsByUserId,
   UpdateUserAllUserDetails,
   updateUserBasicInformation,
} from "../../../lib/gobCorpBEClient";
import { SnackBarContext } from "../../../context/snackBarContext";
import { ConfirmationModal } from "../../ConfirmationModal";
import { updateUser } from "../../../lib/usersBEClient";

const gridRow = {
   display: "flex",
   alignItems: "center",
};

const genere = ["hombre", "mujer", "indefinido"];

const useGetUserInfo = (userId, seed) => {
   const [userDetails, setUserDetails] = useState(null);
   const { showSnackBar } = useContext(SnackBarContext);

   useEffect(() => {
      const fetch = async () => {
         try {
            const details = await getUserDetailsByUserId(userId);
            setUserDetails(details);
         } catch (error) {
            console.log(error);
            showSnackBar("Error al obtener los datos", true);
         }
      };
      if (userId) if (userId !== "") fetch();
   }, [userId, seed]);
   return { userDetails };
};

export const BasicInformation = () => {
   const { user } = useContext(UserContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { mutate } = UpdateUserAllUserDetails();
   const [isLoadingInfo, setIsLoadingInfo] = useState<boolean>(false);
   const [seed, setSeed] = useState<boolean>(false);
   const { userDetails } = useGetUserInfo(user.id, seed);

   const initialValues = userDetails
      ? {
           firstName: user.firstName || "",
           lastName: user.lastName || "",
           email: user.email || "",
           gender: userDetails.gender ? userDetails.gender : "",
           phoneNumber: user.phoneNumber || "",
           dob: userDetails.dob ? userDetails.dob.toString().split("T")[0] : "",
           birthLocation: userDetails.birthLocation ? userDetails.birthLocation : "",
           address: userDetails.address ? userDetails.address : "",
        }
      : {};

   const modifyUserInfo = async (values) => {
      const properties = Object.keys(values);
      let newModifiedData = {};
      for (const iterator of properties) {
         if (values[iterator] !== initialValues[iterator]) newModifiedData[iterator] = values[iterator];
      }
      try {
         if (
            values.firstName !== user.firstName ||
            values.lastName !== user.lastName ||
            values.phoneNumber !== user.phoneNumber
         ) {
            updateUser(user.uid, values.firstName, values.lastName, values.phoneNumber).then(() => {
               mutate({
                  userId: user.id,
                  userDetailsData: { firstName: values.firstName, lastName: values.lastName },
               });
            });
         }
         if (Object.keys(newModifiedData).length > 0) await updateUserBasicInformation(user.id, newModifiedData);
         showSnackBar("Datos modificados con éxito", false);
      } catch (error) {
         showSnackBar("Error al modificar los datos", true);
      }
      setSeed(!seed);
      setIsLoadingInfo(false);
   };

   return (
      <Box sx={{ pr: 2 }}>
         {userDetails ? (
            <Formik initialValues={initialValues} onSubmit={() => {}} validationSchema={BasicInformationSchema}>
               {(formProps: FormikProps<any>) => (
                  <Form>
                     <Grid container rowSpacing={2}>
                        <Grid item xs={4} sx={gridRow}>
                           Nombre
                        </Grid>
                        <Grid item xs={8}>
                           <InputTextField
                              id="firstName"
                              label="Nombre"
                              name="firstName"
                              type="string"
                              size={"small"}
                              fullWidth
                              sx={{ maxWidth: 500 }}
                              disabled
                           />
                        </Grid>
                        <Grid item xs={4} sx={gridRow}>
                           Apellido
                        </Grid>
                        <Grid item xs={8}>
                           <InputTextField
                              id="lastName"
                              label="Apellido"
                              name="lastName"
                              type="string"
                              size={"small"}
                              fullWidth
                              sx={{ maxWidth: 500 }}
                              disabled
                           />
                        </Grid>
                        <Grid item xs={4} sx={gridRow}>
                           Correo electrónico
                        </Grid>
                        <Grid item xs={8}>
                           <InputTextField
                              id="email"
                              label="Correo electrónico"
                              name="email"
                              type="string"
                              size={"small"}
                              fullWidth
                              sx={{ maxWidth: 500 }}
                              disabled
                           />
                        </Grid>
                        <Grid item xs={4} sx={gridRow}>
                           Género
                        </Grid>
                        <Grid item xs={8}>
                           <InputSelectField
                              label="Género"
                              labelId="gender"
                              id="gender"
                              name="gender"
                              variant="outlined"
                              size={"small"}
                              fullWidth
                              sx={{ maxWidth: 500 }}
                           >
                              {genere.map((e, index) => (
                                 <MenuItem key={index} value={e}>
                                    {e.charAt(0).toUpperCase() + e.slice(1)}
                                 </MenuItem>
                              ))}
                           </InputSelectField>
                        </Grid>
                        <Grid item xs={4} sx={gridRow}>
                           Número de celular
                        </Grid>
                        <Grid item xs={8}>
                           <InputTextField
                              id="phoneNumber"
                              label="Número de celular"
                              name="phoneNumber"
                              type="string"
                              size={"small"}
                              fullWidth
                              sx={{ maxWidth: 500 }}
                           />
                        </Grid>
                        <Grid item xs={4} sx={gridRow}>
                           Fecha de nacimiento
                        </Grid>
                        <Grid item xs={8}>
                           <InputTextField
                              id="dob"
                              name="dob"
                              type="date"
                              size={"small"}
                              fullWidth
                              sx={{ maxWidth: 500 }}
                           />
                        </Grid>
                        <Grid item xs={4} sx={gridRow}>
                           Lugar de nacimiento
                        </Grid>
                        <Grid item xs={8}>
                           <InputTextField
                              id="birthLocation"
                              label="Lugar de nacimiento"
                              name="birthLocation"
                              type="string"
                              size={"small"}
                              fullWidth
                              sx={{ maxWidth: 500 }}
                           />
                        </Grid>
                        <Grid item xs={4} sx={gridRow}>
                           Domicilio
                        </Grid>
                        <Grid item xs={8}>
                           <InputTextField
                              id="address"
                              label="Domicilio"
                              name="address"
                              type="string"
                              size={"small"}
                              fullWidth
                              sx={{ maxWidth: 500 }}
                           />
                        </Grid>
                        <Box sx={{ justifyContent: "flex-end", display: "flex", flex: 1, mt: 4 }}>
                           <Button variant="contained" onClick={() => setIsLoadingInfo(true)}>
                              {isLoadingInfo ? <CircularProgress size={24} /> : "Guardar cambios"}
                           </Button>
                        </Box>
                     </Grid>
                     <ConfirmationModal
                        open={isLoadingInfo}
                        setOpen={setIsLoadingInfo}
                        generic
                        onConfirm={() => modifyUserInfo(formProps.values)}
                        cancelButton={false}
                     />
                  </Form>
               )}
            </Formik>
         ) : (
            <Box sx={{ display: "flex", justifyContent: "center", flex: 1 }}>
               <CircularProgress sx={{ size: 24 }} />
            </Box>
         )}
      </Box>
   );
};
