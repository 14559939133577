import { Box, Grid, IconButton, Menu, MenuItem, Select, Stack, Typography } from "@mui/material";
import { InputTextField } from "../../../Inputs/InputTextField";
import { BillAutoCompleteText } from "./BillAutoCompleteText";
import CheckIcon from "@mui/icons-material/Check";
import { useFormikContext } from "formik";
import {
   CreateCommitment,
   CreateMultipleCommitments,
   UpdateCommitment,
   useGetResourceSelected,
} from "../../../../lib/gobCorpBEClient";
import { useContext, useState } from "react";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";
import ClearIcon from "@mui/icons-material/Clear";
import { GovernanceSessionContext } from "../../../../context/governanceContext/governanceSessionContext";

export const BillCommitmentForm = ({
   isEdit = false,
   commitmentsValues = null,
   index = null,
   setModifyCommitment = null,
   modifyCommitment = null,
}) => {
   const { setFieldValue } = useFormikContext();
   const { companySelected } = useContext(GovernanceContext);
   const resourceId = useGetResourceSelected();
   const [anchorEl, setAnchorEl] = useState(null);
   const [openCreateCommitment, setOpenCreateCommitment] = useState(false);
   const { mutate } = CreateCommitment(companySelected, resourceId);
   const { mutate: mutateMany } = CreateMultipleCommitments(companySelected, resourceId);
   const { mutate: updateCommitment } = UpdateCommitment(companySelected, resourceId, commitmentsValues?._id);
   const {
      membersWithCharge,
      session,
      hasPermissions,
      setReceivingChanges,
      receivingChanges,
      groupCompaniesInSession,
      valuesFromBill,
   } = useContext(GovernanceSessionContext);

   const handleCreateCommitment = (multiple: boolean) => {
      if (
         valuesFromBill["responsableName"].length === 0 ||
         valuesFromBill["limitDate"] === "" ||
         valuesFromBill["activity"] === "" ||
         (session.group && valuesFromBill["commitmentCompanies"].length === 0)
      )
         return;
      if (multiple) {
         const commitmentObjectArray = valuesFromBill["responsableName"].map((user) => {
            return {
               users: user._id,
               title: valuesFromBill["activity"],
               dueDate: new Date(valuesFromBill["limitDate"]),
               companies:
                  valuesFromBill["commitmentCompanies"]?.length > 0
                     ? valuesFromBill["commitmentCompanies"]?.map((c) => c?._id)
                     : [],
               session: session._id,
            };
         });
         mutateMany(commitmentObjectArray, {
            onError: (error: any) => {
               console.log(error);
            },
            onSuccess: (data) => {
               const newCommitments = data.map((commitment) => {
                  const userFound = valuesFromBill["responsableName"].find((u) => u._id === commitment.users[0]);
                  return {
                     _id: commitment._id,
                     activity: valuesFromBill["activity"],
                     limitDate: valuesFromBill["limitDate"],
                     companies:
                        valuesFromBill["commitmentCompanies"]?.length > 0
                           ? valuesFromBill["commitmentCompanies"]?.map((company) => {
                                return { _id: company._id, person_details: company.person_details };
                             })
                           : [],
                     session: session._id,
                     users: [
                        {
                           _id: userFound._id,
                           firstName: userFound.firstName,
                           lastName: userFound.lastName,
                           email: userFound.email,
                        },
                     ],
                  };
               });
               setFieldValue("commitmentsArray", [...valuesFromBill["commitmentsArray"], ...newCommitments]);

               setFieldValue("activity", "");
               setFieldValue("limitDate", "");
               setFieldValue("responsableName", []);
               setFieldValue("commitmentCompanies", []);

               setOpenCreateCommitment(false);
            },
         });
      } else {
         const commitmentObject = {
            users: valuesFromBill["responsableName"].map((user) => user._id),
            title: valuesFromBill["activity"],
            dueDate: new Date(valuesFromBill["limitDate"]),
            companies:
               valuesFromBill["commitmentCompanies"]?.length > 0
                  ? valuesFromBill["commitmentCompanies"]?.map((c) => c?._id)
                  : [],
            session: session._id,
         };
         mutate(commitmentObject, {
            onError: (error: any) => {
               console.log(error);
            },
            onSuccess: (data) => {
               const users = valuesFromBill["responsableName"];
               const newCommitment = {
                  _id: data._id,
                  activity: valuesFromBill["activity"],
                  limitDate: valuesFromBill["limitDate"],
                  companies:
                     valuesFromBill["commitmentCompanies"]?.length > 0
                        ? valuesFromBill["commitmentCompanies"]?.map((company) => {
                             return { _id: company._id, person_details: company.person_details };
                          })
                        : [],
                  users: users.map((user) => {
                     return {
                        _id: user._id,
                        firstName: user.firstName,
                        lastName: user.lastName,
                        email: user.email,
                     };
                  }),
               };
               setFieldValue("commitmentsArray", [...valuesFromBill["commitmentsArray"], newCommitment]);
               setFieldValue("activity", "");
               setFieldValue("limitDate", "");
               setFieldValue("responsableName", []);
               setFieldValue("commitmentCompanies", []);
               setOpenCreateCommitment(false);
            },
         });
      }
   };

   const handleUpdateCommitment = () => {
      if (
         valuesFromBill["responsableNameEdit" + index].length === 0 ||
         valuesFromBill["limitDateEdit" + index] === "" ||
         valuesFromBill["activityEdit" + index] === "" ||
         (session.group && valuesFromBill["commitmentCompaniesEdit" + index].length === 0)
      )
         return;
      const commitmentObject = {
         users: valuesFromBill["responsableNameEdit" + index].map((user) => user._id),
         title: valuesFromBill["activityEdit" + index],
         dueDate: new Date(valuesFromBill["limitDateEdit" + index]),
         companies:
            valuesFromBill["commitmentCompaniesEdit" + index]?.length > 0
               ? valuesFromBill["commitmentCompaniesEdit" + index]?.map((c) => c?._id)
               : [],
         session: session._id,
      };

      updateCommitment(commitmentObject, {
         onError: (error: any) => {
            console.log(error);
         },
         onSuccess: (data) => {
            const oldIndex = valuesFromBill["commitmentsArray"].findIndex((commitment) => commitment._id === data._id);
            const newCommitment = {
               _id: data._id,
               activity: valuesFromBill["activityEdit" + index],
               limitDate: valuesFromBill["limitDateEdit" + index],
               companies:
                  valuesFromBill["commitmentCompaniesEdit" + index]?.length > 0
                     ? valuesFromBill["commitmentCompaniesEdit" + index]?.map((company) => {
                          return { _id: company._id, person_details: company.person_details };
                       })
                     : [],
               users: valuesFromBill["responsableNameEdit" + index].map((user) => {
                  return { _id: user._id, firstName: user.firstName, lastName: user.lastName };
               }),
            };

            const tempArray = valuesFromBill["commitmentsArray"];
            tempArray[oldIndex] = newCommitment;
            setFieldValue("commitmentsArray", tempArray);
            commitmentsValues = newCommitment;

            setFieldValue("responsableNameEdit" + index, []);
            setFieldValue("activityEdit" + index, "");
            setFieldValue("commitmentCompaniesEdit" + index, []);
            setFieldValue("limitDateEdit" + index, "");

            setModifyCommitment(!modifyCommitment);
         },
      });
   };

   return (
      <Box sx={{ display: "flex", pt: isEdit ? 0.5 : 0, width: "100%" }}>
         <Grid item xs={hasPermissions ? (session.group ? 11.3 / 4 : 11.3 / 3) : 4} sx={{ bgcolor: "#EFEFEF" }}>
            <BillAutoCompleteText
               id={isEdit ? "responsableNameEdit" + index : "responsableName"}
               name={isEdit ? "responsableNameEdit" + index : "responsableName"}
               values={isEdit && commitmentsValues}
               setFieldValue={(e) => {
                  if (receivingChanges) setReceivingChanges(false);
                  if (isEdit) setFieldValue("responsableNameEdit" + index, e);
                  else setFieldValue("responsableName", e);
               }}
               membersWithCharge={membersWithCharge}
               index={index}
               hasPermissions={hasPermissions}
            />
         </Grid>
         <Grid
            item
            xs={hasPermissions ? (session.group ? 11.3 / 4 : 11.3 / 3) : 4}
            sx={{ bgcolor: "#EFEFEF", borderLeft: 1, borderColor: "white" }}
         >
            <InputTextField
               id={isEdit ? "activityEdit" + index : "activity"}
               name={isEdit ? "activityEdit" + index : "activity"}
               type={"text"}
               unstyled
               multiline
               placeholder="[NOMBRE DE LA ACTIVIDAD]"
               fullWidth
               sx={{ m: 0, fontSize: 12, bgcolor: "#EFEFEF", px: 1 }}
               noError
               disabled={!hasPermissions}
               onChange={(e) => {
                  if (receivingChanges) setReceivingChanges(false);
                  if (isEdit) setFieldValue("activityEdit" + index, e.target.value);
                  else setFieldValue("activity", e.target.value);
               }}
            />
         </Grid>
         {session.group && (
            <Grid
               item
               xs={hasPermissions ? 11.3 / 4 : 4}
               sx={{ bgcolor: "#EFEFEF", borderLeft: 1, borderColor: "white" }}
            >
               <Select
                  fullWidth
                  multiple
                  size="small"
                  id={isEdit ? "commitmentCompaniesEdit" + index : "commitmentCompanies"}
                  name={isEdit ? "commitmentCompaniesEdit" + index : "commitmentCompanies"}
                  value={
                     isEdit ? valuesFromBill["commitmentCompaniesEdit" + index] : valuesFromBill["commitmentCompanies"]
                  }
                  disabled={!hasPermissions}
                  sx={{
                     ".MuiOutlinedInput-notchedOutline": {
                        border: "0px solid #F0F0F0",
                     },
                     "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "0px solid #F0F0F0",
                     },
                     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "0px solid #F0F0F0",
                     },
                     borderRadius: 0,
                  }}
                  onChange={(e) => {
                     const allCompanies = (e.target.value as any[]).flatMap((c) => c);
                     const idCounts = allCompanies.reduce((acc, company) => {
                        acc[company._id] = (acc[company._id] || 0) + 1;
                        return acc;
                     }, {} as Record<string, number>);
                     const duplicates = Object.keys(idCounts).filter((id) => idCounts[id] > 1);
                     const uniqueCompanies = allCompanies.filter((company) => !duplicates?.includes(company._id));
                     if (isEdit) setFieldValue("commitmentCompaniesEdit" + index, uniqueCompanies);
                     else setFieldValue("commitmentCompanies", uniqueCompanies);
                  }}
                  renderValue={(company) => {
                     if (!company) return;
                     return company?.map((c, i) => (
                        <Typography
                           key={`selectedC-${i}`}
                           sx={{
                              textAlign: "center",
                              fontSize: 12,
                              px: 1,
                           }}
                        >
                           {c?.person_details?.comercialName?.length > 15
                              ? `${c.person_details.comercialName.slice(0, 15)}...`
                              : c?.person_details?.comercialName}
                        </Typography>
                     ));
                  }}
               >
                  {(groupCompaniesInSession as any)?.map((c) => (
                     <MenuItem value={c} key={`menuItem-${c._id}`}>
                        <Typography sx={{ fontSize: 12 }}>{c?.person_details?.comercialName}</Typography>
                     </MenuItem>
                  ))}
               </Select>
            </Grid>
         )}

         <Grid
            item
            xs={hasPermissions ? (session.group ? 11.3 / 4 : 11.3 / 3) : 4}
            sx={{ bgcolor: "#EFEFEF", borderLeft: 1, borderColor: "white" }}
         >
            <InputTextField
               id={isEdit ? "limitDateEdit" + index : "limitDate"}
               name={isEdit ? "limitDateEdit" + index : "limitDate"}
               type={"date"}
               unstyled
               placeholder="[FECHA LÍMITE]"
               fullWidth
               sx={{ m: 0, fontSize: 12, bgcolor: "#EFEFEF", color: "#989898", px: 1 }}
               noError
               disabled={!hasPermissions}
               onChange={(e) => {
                  if (receivingChanges) setReceivingChanges(false);
                  if (isEdit) setFieldValue("limitDateEdit" + index, e.target.value);
                  else setFieldValue("limitDate", e.target.value);
               }}
            />
         </Grid>
         {hasPermissions &&
            (!isEdit ? (
               <Grid item xs={2 / 3}>
                  <IconButton
                     sx={{ p: 0, m: 0, ml: 0.2 }}
                     onClick={(e) => {
                        if (valuesFromBill["responsableName"].length > 1) {
                           setOpenCreateCommitment(true);
                           setAnchorEl(e.currentTarget);
                        } else handleCreateCommitment(false);
                     }}
                  >
                     <CheckIcon />
                  </IconButton>
               </Grid>
            ) : (
               <Grid item xs={2 / 3} sx={{ maxHeight: 18, display: "flex", alignItems: "center" }}>
                  <Stack sx={{ mt: 1.5 }}>
                     <IconButton onClick={() => handleUpdateCommitment()} sx={{ p: 0, m: 0, ml: 0.2 }}>
                        <CheckIcon sx={{ fontSize: 18 }} />
                     </IconButton>
                     <IconButton
                        onClick={() => {
                           setFieldValue("responsableNameEdit" + index, []);
                           setFieldValue("activityEdit" + index, "");
                           setFieldValue("commitmentCompaniesEdit" + index, []);
                           setFieldValue("limitDateEdit" + index, "");
                           setModifyCommitment(!modifyCommitment);
                        }}
                        sx={{ p: 0, m: 0, ml: 0.2 }}
                     >
                        <ClearIcon sx={{ fontSize: 18 }} />
                     </IconButton>
                  </Stack>
               </Grid>
            ))}

         <Menu
            id="demo-positioned-menu"
            aria-labelledby="demo-positioned-button"
            anchorEl={anchorEl}
            open={openCreateCommitment}
            onClose={() => setOpenCreateCommitment(false)}
            anchorOrigin={{
               vertical: "top",
               horizontal: "left",
            }}
            transformOrigin={{
               vertical: "top",
               horizontal: "left",
            }}
         >
            <MenuItem sx={{ fontSize: 14 }} onClick={() => handleCreateCommitment(false)}>
               Tarea grupal
            </MenuItem>
            <MenuItem sx={{ fontSize: 14 }} onClick={() => handleCreateCommitment(true)}>
               Tarea individual
            </MenuItem>
         </Menu>
      </Box>
   );
};
