import { useState, useContext } from "react";
import { Box, Divider, Stack, Button, IconButton, Typography, CircularProgress, Tooltip } from "@mui/material";
import { Form, Formik } from "formik";
import { InputTextField } from "../Inputs/InputTextField";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { SnackBarContext } from "../../context/snackBarContext";
import { useNavigate } from "react-router-dom";
import { confirmPasswordReset } from "../../lib/usersBEClient";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import { userResetPasswordSchema } from "../../lib/validations/inputSchemas";
import InfoIcon from "@mui/icons-material/Info";

interface ResetPasswordProps {
   code: string;
   master: boolean;
   redirect: Function;
}

export const ResetPassword = (props: ResetPasswordProps) => {
   const { showSnackBar } = useContext(SnackBarContext);
   const [isLoading, setIsLoading] = useState(false);
   const [showNewPwd, setNewShowPwd] = useState(false);
   const [completed, setCompleted] = useState(false);
   const [error, setError] = useState(false);
   const navigate = useNavigate();

   const modifyUserPassword = async (values) => {
      try {
         setIsLoading(true);
         await confirmPasswordReset(values.passwordNew, props.code);
         setCompleted(true);
         showSnackBar("Contraseña actualizada", false);
         setIsLoading(false);
      } catch (error: any) {
         if (error.response.data.message === "something went wrong") {
            setError(true);
         }
      }
   };

   return (
      <>
         {completed ? (
            <>
               <Typography variant="h6" textAlign={"center"} sx={{ fontWeight: "bold", color: "#162c44", py: 2 }}>
                   Su contraseña fue modificada correctamente.
               </Typography>
               <CheckCircleIcon color="success" sx={{ fontSize: 80 }} />
               <Button
                  variant="contained"
                  sx={{ mt: 2 }}
                  onClick={() => (props.master ? props.redirect() : navigate("/login"))}
               >
                  Volver a Lecosy
               </Button>
            </>
         ) : error ? (
            <>
               <Typography variant="h6" textAlign={"center"} sx={{ fontWeight: "bold", color: "#162c44", pt: 2 }}>
                  Ocurrió un error inesperado.
               </Typography>
               <ErrorIcon color="error" sx={{ fontSize: 80 }} />
               <Typography textAlign={"center"} sx={{ fontWeight: "bold", color: "#162c44", pt: 2 }}>
                  Favor de volver a intentar o contactar a soporte.
               </Typography>
               <Button
                  variant="contained"
                  sx={{ mt: 2 }}
                  onClick={() => (props.master ? props.redirect() : navigate("/login"))}
               >
                  Volver a Lecosy
               </Button>
            </>
         ) : (
            <>
               <Typography variant="h5" textAlign={"center"} sx={{ fontWeight: "bold", color: "#162c44", py: 2 }}>
                  Crea una nueva contraseña
               </Typography>
               <Divider color="black" variant="middle" sx={{ my: 1 }} />
               <Typography textAlign={"center"} sx={{ fontWeight: "bold", fontSize: 14, color: "#162c44", py: 1 }}>
                  Escriba a continuación la nueva contraseña.
               </Typography>
               <Formik
                  initialValues={{
                     passwordNew: "",
                     passwordNewConfirmation: "",
                  }}
                  onSubmit={modifyUserPassword}
                  validationSchema={userResetPasswordSchema}
               >
                  <Form style={{ width: "100%" }}>
                     <Stack spacing={2}>
                        <InputTextField
                           fullWidth
                           variant="standard"
                           size="small"
                           name="passwordNew"
                           id="passwordNew"
                           type={showNewPwd ? "text" : "password"}
                           label="Nueva contraseña"
                           endAdornment={
                              <>
                                 <IconButton onClick={() => setNewShowPwd(!showNewPwd)}>
                                    {showNewPwd ? <VisibilityOff /> : <Visibility />}
                                 </IconButton>
                                 <Tooltip
                                    title={
                                       "Se requiere al menos una mayúscula, una minúscula, un dígito, un carácter especial y no espacios en blanco."
                                    }
                                 >
                                    <InfoIcon fontSize="small" color="action" />
                                 </Tooltip>
                              </>
                           }
                        />
                        <InputTextField
                           fullWidth
                           variant="standard"
                           size="small"
                           name="passwordNewConfirmation"
                           id="passwordNewConfirmation"
                           type={showNewPwd ? "text" : "password"}
                           label="Confirmar nueva contraseña"
                        />
                     </Stack>
                     <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                        <Box sx={{ display: "flex", mt: 2 }}>
                           <Button
                              variant="outlined"
                              onClick={() => (props.master ? props.redirect : navigate("/login"))}
                           >
                              Volver a Lecosy
                           </Button>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                           <Button variant="contained" type="submit">
                              {!isLoading ? "Confirmar" : <CircularProgress size={24} color="success" />}
                           </Button>
                        </Box>
                     </Box>
                  </Form>
               </Formik>
            </>
         )}
      </>
   );
};
