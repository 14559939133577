import React, { useContext } from "react";
import { FilePresentOutlined } from "@mui/icons-material";
import { Button } from "@mui/material";
import { BeneficiaryControllerContext } from "../../../context/beneficiaryContext/beneficiaryContollerContext";

const BeneficiaryManualButton = () => {
   const { identificationManual } = useContext(BeneficiaryControllerContext);

   const handleClick = () => {
      if (identificationManual) window.open(identificationManual, "_blank");
      else console.error("URL no disponible");
   };

   return (
      <>
         <Button
            variant="text"
            disabled={!identificationManual}
            onClick={handleClick}
            sx={{ borderRadius: 1, gap: 1, ml: "auto" }}
         >
            <FilePresentOutlined />
            Manual de identificación
         </Button>
      </>
   );
};

export default BeneficiaryManualButton;
