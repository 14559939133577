import { Box, Grid, MenuItem, Select, Typography } from "@mui/material";
import { ExternalGovernanceSessionContext } from "../../../../../context/governanceContext/externalSessionContext";
import { useContext } from "react";
import { InputTextField } from "../../../../Inputs/InputTextField";

export const BillCommitmentFormExternal = () => {
   const { session, valuesFromBill, groupCompaniesInSession } = useContext(ExternalGovernanceSessionContext);
   const gridWidth = session.group ? 3 : 4;

   return (
      <Box sx={{ display: "flex", width: "100%" }}>
         <Grid item xs={gridWidth} sx={{ bgcolor: "#EFEFEF" }}>
            <InputTextField
               id={"responsableName"}
               name={"responsableName"}
               type={"text"}
               unstyled
               multiline
               value={valuesFromBill["responsableName"]
                  .map((u) => `${u.firstName || u?.email} ${u.lastName || ""}`)
                  .join(", ")}
               placeholder="[NOMBRE DEL RESPONSABLE]"
               fullWidth
               sx={{ m: 0, fontSize: 12, bgcolor: "#EFEFEF", px: 1 }}
               noError
               disabled={true}
            />
         </Grid>
         <Grid item xs={gridWidth} sx={{ bgcolor: "#EFEFEF", borderLeft: 1, borderColor: "white" }}>
            <InputTextField
               id={"activity"}
               name={"activity"}
               type={"text"}
               unstyled
               multiline
               placeholder="[NOMBRE DE LA ACTIVIDAD]"
               fullWidth
               sx={{ m: 0, fontSize: 12, bgcolor: "#EFEFEF", px: 1 }}
               noError
               disabled={true}
            />
         </Grid>
         {session.group && (
            <Grid item xs={3} sx={{ bgcolor: "#EFEFEF", borderLeft: 1, borderColor: "white" }}>
               <Select
                  fullWidth
                  multiple
                  size="small"
                  id={"commitmentCompanies"}
                  name={"commitmentCompanies"}
                  value={valuesFromBill["commitmentCompanies"]}
                  disabled={true}
                  sx={{
                     ".MuiOutlinedInput-notchedOutline": {
                        border: "0px solid #F0F0F0",
                     },
                     "&:hover .MuiOutlinedInput-notchedOutline": {
                        border: "0px solid #F0F0F0",
                     },
                     "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: "0px solid #F0F0F0",
                     },
                     borderRadius: 0,
                  }}
                  renderValue={(company) => {
                     if (!company) return;
                     return company?.map((c, i) => (
                        <Typography
                           key={`selectedC-${i}`}
                           sx={{
                              textAlign: "center",
                              fontSize: 12,
                              px: 1,
                           }}
                        >
                           {c?.person_details?.comercialName?.length > 15
                              ? `${c.person_details.comercialName.slice(0, 15)}...`
                              : c?.person_details?.comercialName}
                        </Typography>
                     ));
                  }}
               >
                  {(groupCompaniesInSession as any)?.map((c) => (
                     <MenuItem value={c} key={`menuItem-${c._id}`}>
                        <Typography sx={{ fontSize: 12 }}>{c?.person_details?.comercialName}</Typography>
                     </MenuItem>
                  ))}
               </Select>
            </Grid>
         )}

         <Grid item xs={gridWidth} sx={{ bgcolor: "#EFEFEF", borderLeft: 1, borderColor: "white" }}>
            <InputTextField
               id={"limitDate"}
               name={"limitDate"}
               type={"date"}
               unstyled
               placeholder="[FECHA LÍMITE]"
               fullWidth
               sx={{ m: 0, fontSize: 12, bgcolor: "#EFEFEF", color: "#989898", px: 1 }}
               noError
               disabled={true}
            />
         </Grid>
      </Box>
   );
};
