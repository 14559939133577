import { Box, Chip, Grid, Typography } from "@mui/material";
import { formatTextUpperLower } from "../../../../const/globalConst";

interface PersonalInfoProps {
   governData: any;
   memberData: any;
}

export const PersonalInfo = (props: PersonalInfoProps) => {
   return (
      <Box
         sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            overflow: "auto",
            height: "60vh",
            p: 3,
         }}
      >
         <Box sx={{ pb: 2 }}>
            <Typography variant="h6" fontWeight={"bold"} color={"#64748B"}>
               INFORMACIÓN DE PERFIL
            </Typography>
         </Box>
         <Grid container>
            <Grid item xs={6} sx={{ my: 1 }}>
               <Typography variant="subtitle1" fontWeight={"bold"} color={"#152C44"}>
                  Nombre completo:
               </Typography>
            </Grid>
            <Grid item xs={6} sx={{ my: 1 }}>
               <Typography variant="subtitle1">
                  {props.memberData.firstName} {props.memberData.lastName}
               </Typography>
            </Grid>
            <Grid item xs={6} sx={{ my: 1 }}>
               <Typography variant="subtitle1" fontWeight={"bold"} color={"#152C44"}>
                  Cargo:
               </Typography>
            </Grid>
            <Grid item xs={6} sx={{ my: 1 }}>
               <Chip label={props.memberData.chargeName} sx={{ borderRadius: 1, fontSize: 15 }} />
            </Grid>
            <Grid item xs={6} sx={{ my: 1 }}>
               <Typography variant="subtitle1" fontWeight={"bold"} color={"#152C44"}>
                  Correo electrónico:
               </Typography>
            </Grid>
            <Grid item xs={6} sx={{ my: 1 }}>
               <Typography variant="subtitle1">{props.memberData.email}</Typography>
            </Grid>
            <Grid item xs={6} sx={{ my: 1 }}>
               <Typography variant="subtitle1" fontWeight={"bold"} color={"#152C44"}>
                  Órganos de gobierno:
               </Typography>
            </Grid>
            <Grid item xs={6} sx={{ my: 1 }}>
               {props.governData.map((e, i) => (
                  <Chip
                     key={i}
                     label={formatTextUpperLower(e.title)}
                     sx={{ borderRadius: 1, fontSize: 15, mr: 1, mt: 1 }}
                  />
               ))}
            </Grid>
            <Grid item xs={6} sx={{ my: 1 }}>
               <Typography variant="subtitle1" fontWeight={"bold"} color={"#152C44"}>
                  Caracter:
               </Typography>
            </Grid>
            <Grid item xs={6} sx={{ my: 1 }}>
               {props.memberData.characters.length <= 0
                  ? "Sin datos"
                  : props.memberData.characters.map((e, i) => (
                       <Chip key={i} label={e} sx={{ borderRadius: 1, fontSize: 15, mr: 1, mt: 1 }} />
                    ))}
            </Grid>
         </Grid>
      </Box>
   );
};
