import { useContext, useEffect, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";
import { UserContext } from "../../../context/userContext";
import { SnackBarContext } from "../../../context/snackBarContext";
import { HeadCell, TableComponent } from "../../TableComponent";
import { getManyCompaniesById, getManyCorporateData, getUrlS3 } from "../../../lib/usersBEClient";
import { CorporateDataContext } from "../../../context/governanceContext/corporateDataContext";
import { useNavigate } from "react-router-dom";

let columns: HeadCell[] = [
   { field: "logo", headerName: "Logo", type: "string" },
   { field: "name", headerName: "Empresa", type: "string" },
   { field: "councilType", headerName: "Tipo de consejo", type: "string" },
   { field: "status", headerName: "Estatus", type: "boolean" },
];

export const ConsultiveCompaniesTable = () => {
   const { companies, isImplementationUser, user } = useContext(UserContext);
   const { consultiveGroup } = useContext(CorporateDataContext);
   const [companiesRow, setCompaniesRow] = useState([]);
   const { showSnackBar } = useContext(SnackBarContext);
   const [isLoading, setIsLoading] = useState(true);
   const navigate = useNavigate();

   const fetchCompaniesInfo = async () => {
      const companyData: any[] = [];
      setIsLoading(true);
      try {
         const companyCorporateDatas = await getManyCorporateData(user.group.companies);
         const groupCompanies = await getManyCompaniesById(user.group.companies);
         for (const company of groupCompanies) {
            companyData.push({
               _id: company._id,
               logo: (
                  <img
                     defaultValue={company.logo}
                     src={company.logo}
                     alt="logo"
                     style={{ maxWidth: "140px", maxHeight: "50px" }}
                  />
               ),
               name: company.person_details.comercialName,
               status: company.disabled,
               councilType: companyCorporateDatas?.find((c) => c.companyId === company._id)?.councilType ?? "---",
            });
         }
         setCompaniesRow(companyData);
      } catch (error) {
         showSnackBar("Error al conseguir empresas", true);
      }
      setIsLoading(false);
   };

   useEffect(() => {
      if (consultiveGroup) fetchCompaniesInfo();
      // eslint-disable-next-line
   }, [consultiveGroup, user.group, companies]);

   return (
      <Box
         sx={{
            bgcolor: "white",
            boxShadow: 2,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            borderRadius: 1,
         }}
      >
         <Stack direction={"row"} sx={{ px: 4, pt: 3, justifyContent: "space-between", alignItems: "center" }}>
            <Typography sx={{ fontWeight: 700 }}>Empresas</Typography>
         </Stack>
         <TableComponent
            defaultColumnToOrder="empresa"
            defaultOrder="asc"
            defaultRowsPerPage={5}
            emptyDataText={
               companiesRow.length > 0 ? "No se encontró una empresa con ese nombre" : "No existen empresas registradas"
            }
            disableBorders
            headers={columns}
            loader={isLoading}
            rows={companiesRow}
            rowsPerPageOptions={[5, 10, 20]}
            onClick={(e, row) => {
               isImplementationUser()
                  ? navigate(`/mi-lecosy/${row._id}/servicios`)
                  : navigate(`/mi-lecosy/${row._id}/inicio`);
            }}
         />
      </Box>
   );
};
