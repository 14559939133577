import { HeadCell, TableComponent } from "../../components/TableComponent";

interface GetTableComponentProps {
   defaultOrder: string;
   defaultText: string;
   tableLoader: boolean;
   tableHeaders: HeadCell[];
   tableRow: any;
   selectedRow: (e: any, row: any) => void;
   tableFilters?: any[];
   tableInitialState?: any;
   rowsPerPage?: number[];
}

const GetTableComponent = (props: GetTableComponentProps) => {
   return (
      <TableComponent
         defaultColumnToOrder={props.defaultOrder}
         defaultOrder="asc"
         defaultRowsPerPage={props.rowsPerPage ? props.rowsPerPage[0] : 10}
         rowsPerPageOptions={props.rowsPerPage ? props.rowsPerPage : [5, 10, 25]}
         emptyDataText={props.defaultText}
         loader={props.tableLoader}
         onClick={props.selectedRow}
         headers={props.tableHeaders}
         rows={props.tableRow || []}
         filters={props.tableFilters}
         filtersInitialState={props.tableInitialState}
         headerColor={true}
      />
   );
};

export default GetTableComponent;
