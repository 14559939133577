import { Avatar, Box, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { ChatContext } from "../../../../context/governanceContext/ChatContext";
import { UserContext } from "../../../../context/userContext";
import { getUrlS3 } from "../../../../lib/usersBEClient";

export function stringAvatar(name: string) {
   return {
      sx: {
         bgcolor: "#162c44",
         fontWeight: "bold",
         fontSize: 16,
      },
      children: `${name.toUpperCase().split(" ")[0][0]}${name.toUpperCase().split(" ")[1][0]}`,
   };
}

interface ILastMessage {
   content: string;
   createdAt: string;
   receivedUser;
   unseenMessages: number;
   type: string;
   receivedUserId?: string;
}

export const LastMessage = (props: ILastMessage) => {
   const { currentChat } = useContext(ChatContext);
   const compareDate = useMemo(() => {
      if (!props.createdAt) return "";

      const lastCreatedMsg = new Date(props.createdAt);
      const hours = lastCreatedMsg.getHours();
      const minutes = lastCreatedMsg.getMinutes();

      const now = new Date();
      const isSameDay =
         lastCreatedMsg.getFullYear() === now.getFullYear() &&
         lastCreatedMsg.getMonth() === now.getMonth() &&
         lastCreatedMsg.getDate() === now.getDate();

      if (isSameDay) {
         return (hours % 12 || 12) + ":" + (minutes < 10 ? "0" : "") + minutes + " " + (hours >= 12 ? "PM" : "AM");
      } else {
         return lastCreatedMsg.toLocaleDateString("es-MX", {
            day: "numeric",
            month: "numeric",
            year: "numeric",
         });
      }
   }, [props.createdAt]);
   const [profilePic, setProfilePic] = useState("");

   useEffect(() => {
      const getPicture = async () => {
         const url = await getUrlS3("files-lecosy", { folder: `users/${props.receivedUserId}` }, "profile.png");
         setProfilePic(url);
      };
      getPicture();
   }, [props.receivedUserId]);

   return (
      <Stack
         direction={"row"}
         sx={{
            alignItems: "center",
            justifyContent: "space-between",
            display: "flex",
            bgcolor: currentChat?.title === props.receivedUser ? "#F4F9FD" : "white",
            ":hover": { bgcolor: "#F4F9FD" },
            px: 2,
            mx: 1,
            py: 1,
            borderRadius: 2.5,
         }}
         spacing={2}
      >
         <Avatar
            src={props.type === "CONVERSATION" ? profilePic : ""}
            {...stringAvatar(
               props.receivedUser.split(" ")[0] +
                  " " +
                  props.receivedUser.split(" ")[props.receivedUser.split(" ").length - 1]
            )}
         />
         <Stack sx={{ display: "flex", flex: 1 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
               {props.type === "CONVERSATION" ? (
                  <Typography fontSize={14} fontWeight={700}>
                     {props.receivedUser}
                  </Typography>
               ) : (
                  <Typography fontSize={14} fontWeight={700}>
                     {props.receivedUser}
                  </Typography>
               )}
               <Typography fontSize={12} fontWeight={600} color={"#7D8592"} noWrap minWidth={60} textAlign={"end"}>
                  {compareDate}
               </Typography>
            </Box>
            <Stack
               sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
               }}
            >
               <Typography fontSize={13} color={"#91929E"}>
                  {props.content.length > 22 ? props.content.substring(0, 20).concat("...") : props.content}
               </Typography>
               {props.unseenMessages > 0 && (
                  <Typography
                     fontSize={11}
                     fontWeight={700}
                     sx={{
                        p: 0.5,
                        borderRadius: "50%",
                        bgcolor: "#e9e9e9",
                        justifyContent: "center",
                        alignItems: "center",
                        color: "#9E9E9E",
                     }}
                  >
                     {props.unseenMessages}
                  </Typography>
               )}
            </Stack>
         </Stack>
      </Stack>
   );
};
