import { Autocomplete, TextField, Typography } from "@mui/material";

interface CountrySelectComponentProps {
   id: string;
   setCountries: Function;
   label: string;
   name: string;
   countries: [];
}

const countries = ["México", "Estados Unidos"];

export const CountrySelectComponent = (props: CountrySelectComponentProps) => {
   return (
      <>
         <Typography>{props.label}</Typography>
         <Autocomplete
            size="small"
            options={countries}
            multiple
            value={props.countries}
            onChange={(e, role) => {
               props.setCountries(role);
            }}
            getOptionLabel={(option) => `${option}`}
            renderInput={(params) => <TextField {...params} label={""} />}
         />
      </>
   );
};
