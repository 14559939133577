import { Box, CircularProgress, Divider, IconButton, Stack, Typography } from "@mui/material";
import React, { useContext, useMemo } from "react";
import {
   ArrowBack,
   Folder,
   FolderShared,
   FolderSpecial,
   HomeRepairService,
   RuleFolder,
   TipsAndUpdates,
} from "@mui/icons-material";
import { FilesContext } from "../../../context/governanceContext/filesContext";
import { UserContext } from "../../../context/userContext";
import { GovernanceTheSequelContext } from "../../../context/governanceContext/governanceTheSequelContext";
import { useParams } from "react-router-dom";
import _ from "lodash";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";

const FoldersSidebar = () => {
   const { isCompanyAdmin, user } = useContext(UserContext);
   const { consultiveGroupCompanies, consultiveGroup, gobernanceBody, selectedGovernance } =
      useContext(GovernanceContext);
   const { myGovBody, loadingFormat } = useContext(GovernanceTheSequelContext);
   const { selectedFolder, setSelectedFolder, selectedCompanyFolder, setSelectedCompanyFolder } =
      useContext(FilesContext);
   const { companyId } = useParams();

   const governanceBodies = useMemo(() => {
      const uniqueBodies = _.uniqBy(
         myGovBody.map((body) => body.bodiesData),
         "title"
      );
      return uniqueBodies;
   }, [selectedGovernance, myGovBody, gobernanceBody, companyId]);

   const availableCompanies = useMemo(() => {
      let companies = [];
      if (!consultiveGroup || consultiveGroup?._id !== companyId) return [];
      const groupGovernance = myGovBody.some(
         (gb) => gb.bodiesData.company === consultiveGroup._id && gb.bodiesData.title === selectedFolder.title
      );
      if (groupGovernance) companies.push({ title: user.group.name, _id: consultiveGroup._id });
      for (const company of consultiveGroupCompanies) {
         const governanceFound = myGovBody.some(
            (gb) => gb.bodiesData.company === company._id && gb.bodiesData.title === selectedFolder.title
         );
         if (governanceFound) companies.push({ title: company.person_details.comercialName, _id: company._id });
      }

      if (selectedFolder.title === "Consejo de administración" && consultiveGroup._id === companyId) {
         companies = companies.filter((c) => !consultiveGroup.companies.includes(c._id));
      }

      return companies;
   }, [consultiveGroup, consultiveGroupCompanies, selectedFolder, selectedCompanyFolder]);

   const commonFolders = {
      enterprise: [
         { icon: <HomeRepairService />, title: "Formularios y herramientas" },
         { icon: <TipsAndUpdates />, title: "Consejos prácticos" },
      ],
      governance: [
         { icon: <FolderSpecial />, title: "Mis archivos" },
         { icon: <FolderShared />, title: "Compartidos conmigo" },
      ],
   };

   const isAbleToVerify = myGovBody.find(
      (gov) =>
         gov.bodiesData.title === "Consejo de administración" &&
         (gov.userData?.charge === "659f11b1fb579847f88877cd" || gov.userData?.charge === "659f11befb579847f88877ce")
   );

   const adminCoord =
      user.role.includes("Usuario de implementación") || user.role.includes("Coordinador de gobierno corporativo");

   if (isAbleToVerify || adminCoord)
      commonFolders.governance.push({ icon: <RuleFolder />, title: "Verificación de miembros" });

   return (
      <Stack spacing={2} sx={{ flex: 1 }}>
         <Stack spacing={1.5} sx={{ bgcolor: "white", borderRadius: 1, px: 3, py: 3, boxShadow: 1 }}>
            {selectedCompanyFolder ? (
               <>
                  <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", columnGap: 1, pb: 1 }}>
                     <IconButton
                        sx={{ p: 0 }}
                        onClick={() => {
                           const governanceFound = governanceBodies.find((gb) => gb.title === selectedFolder.title);
                           setSelectedFolder({ _id: governanceFound._id, title: selectedFolder.title });
                           setSelectedCompanyFolder(null);
                        }}
                     >
                        <ArrowBack />
                     </IconButton>
                     <Typography
                        sx={{
                           fontWeight: 700,
                           color: "#162c44",
                           textAlign: "left",
                        }}
                     >
                        {selectedFolder.title.toUpperCase()}
                     </Typography>
                  </Box>

                  <Stack
                     spacing={1.5}
                     sx={{
                        maxHeight: 200,
                        overflowY: "auto",
                     }}
                  >
                     {availableCompanies.map((company, i) => (
                        <Typography
                           key={company._id}
                           sx={{
                              ...commonSx,
                              color: selectedCompanyFolder === company._id ? "#5A5A5A" : "#9B9B9B",
                           }}
                           onClick={() => {
                              const governanceFound = myGovBody.find(
                                 (gb) =>
                                    gb.bodiesData.company === company._id &&
                                    gb.bodiesData.title === selectedFolder.title
                              );
                              setSelectedFolder({
                                 _id: governanceFound.bodiesData._id,
                                 title: selectedFolder.title,
                              });
                              setSelectedCompanyFolder(company._id);
                           }}
                        >
                           <Folder /> {company.title}
                        </Typography>
                     ))}
                  </Stack>
               </>
            ) : (
               <>
                  <Typography sx={{ fontWeight: 700, color: "#162c44", pb: 1, textAlign: "center" }}>
                     GOBIERNO CORPORATIVO
                  </Typography>
                  {commonFolders.governance.map((folder) => (
                     <Typography
                        key={folder.title}
                        sx={{ ...commonSx, color: selectedFolder.title === folder.title ? "#5A5A5A" : "#9B9B9B" }}
                        onClick={() => setSelectedFolder(folder)}
                     >
                        {folder.icon} {folder.title}
                     </Typography>
                  ))}
                  <Divider sx={{ my: 1 }} />
                  {loadingFormat ? (
                     <Box sx={{ display: "flex", my: 1, justifyContent: "center" }}>
                        <CircularProgress />
                     </Box>
                  ) : (
                     <Stack spacing={1.5}>
                        {governanceBodies.map((gov, i) => (
                           <Typography
                              key={gov._id}
                              sx={{ ...commonSx, color: selectedFolder._id === gov._id ? "#5A5A5A" : "#9B9B9B" }}
                              onClick={() => setSelectedFolder(gov)}
                           >
                              <Folder /> {gov.title}
                           </Typography>
                        ))}
                     </Stack>
                  )}
               </>
            )}
         </Stack>
      </Stack>
   );
};

const commonSx = {
   gap: 1,
   display: "flex",
   cursor: "pointer",
   alignItems: "center",
   ":hover": { color: "#5A5A5A" },
};

export default FoldersSidebar;
