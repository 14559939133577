import { Box, Button } from "@mui/material";
import { useContext } from "react";
import { ComplaintStepperContext } from "../../../context/complaintStepperContext";
import { DocumentsTable } from "../DocumentsTable";

export const ResolutionStep = () => {
   const { complaint, setOpenCompleteDialog, checkDocuments } = useContext(ComplaintStepperContext);
   return (
      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "50vh" }}>
         <Box sx={{ flex: 2 }}>
            <DocumentsTable
               rows={[
                  {
                     document:
                        complaint?.type && complaint?.type !== "denuncia"
                           ? `Resolución de la ${complaint.type}`
                           : "Resolución de la denuncia",
                     templateLink:
                        complaint?.type && complaint?.type !== "denuncia" ? `resolucion-queja` : `acuse-resolucion`,
                     documentName:
                        complaint?.type && complaint?.type !== "denuncia"
                           ? `resolucion-${complaint.type}`
                           : "resolucion-denuncia",
                     fileHeader:
                        complaint?.type && complaint?.type !== "denuncia"
                           ? `Añadir documento de resolución de la ${complaint.type}`
                           : "Añadir documento de resolución de la denuncia",
                  },
               ]}
            />
         </Box>
         <Box sx={{ display: "flex", flexDirection: "row", mt: 2, mr: 2 }}>
            <Box sx={{ flex: "1 1 auto" }} />
            <Button
               disabled={!checkDocuments("resolution")}
               onClick={() => setOpenCompleteDialog(true)}
               variant="outlined"
            >
               {`Completar resolución`}
            </Button>
         </Box>
      </Box>
   );
};
