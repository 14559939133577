import { Box, Divider, IconButton, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import StringToHTMLOutput from "../../Inputs/TextEditor/StringToHTMLOutput";
import { getDateFromISODate } from "../../../const/globalConst";
import { UserContext } from "../../../context/userContext";
import { useContext, useState } from "react";
import { Delete, Edit, MoreHoriz } from "@mui/icons-material";
import { ConfirmationModal } from "../../ConfirmationModal";
import { deleteAdviceById } from "../../../lib/gobCorpBEClient";
import { SnackBarContext } from "../../../context/snackBarContext";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { getAllResources } from "../../../lib/usersBEClient";
import { CustomModalComponent } from "../../CustomModalComponent";
import { ModalAddAdviceComponent } from "./AddAdviceModal";

interface AdviceCardComponentProps {
   advice: any;
   author: any;
}

export const AdviceCardComponent = (props: AdviceCardComponentProps) => {
   const { user } = useContext(UserContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { refetchTasks, setRefetchTasks, companySelected } = useContext(GovernanceContext);
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const [openDeleteModal, setOpenDeleteModal] = useState(false);
   const [openModifyModal, setOpenModifyModal] = useState(false);
   const open = Boolean(anchorEl);

   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      event.stopPropagation();
      setAnchorEl(event.currentTarget);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   const handleDelete = async () => {
      const resourceResponse = await getAllResources();
      const resourceId = resourceResponse.find((r) => r.name === "Tablero de avisos")._id;
      if (resourceId.trim().length === 0) return showSnackBar("Error al eliminar aviso", true);
      if (props.author.user !== user.id) return;
      try {
         await deleteAdviceById(props.advice._id, resourceId, companySelected);
      } catch (error) {
         console.log(error);
         showSnackBar("Error al eliminar el aviso", true);
      } finally {
         setOpenDeleteModal(false);
         showSnackBar("Aviso eliminado con exito", false);
         setRefetchTasks(!refetchTasks);
      }
   };
   return (
      <Box
         sx={{
            boxShadow: 2,
            borderRadius: "10px",
            bgcolor: "white",
            display: "flex",
            flexDirection: "column",
            height: 300,
            width: 300,
         }}
      >
         <Box
            sx={{
               width: "100%",
               height: 30,
               borderTopRightRadius: 10,
               borderTopLeftRadius: 10,
               bgcolor: "#162C44",
            }}
         />
         <Box sx={{ display: "flex", flexDirection: "column", overflowY: "auto", px: 2, py: 3 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
               <Typography fontWeight={"bold"} mb={2} px={1}>
                  {props.advice.title}
               </Typography>
               {
                  <Box>
                     {props.author?.user === user.id ? (
                        <>
                           <IconButton
                              aria-label="more"
                              id="more-button"
                              aria-controls={open ? "more-menu" : undefined}
                              aria-expanded={open ? "true" : undefined}
                              aria-haspopup="true"
                              onClick={handleClick}
                              sx={{ p: 0 }}
                           >
                              <MoreHoriz />
                           </IconButton>
                           <Menu
                              id="more-menu"
                              MenuListProps={{
                                 "aria-labelledby": "more-button",
                              }}
                              anchorEl={anchorEl}
                              open={open}
                              onClose={handleClose}
                           >
                              <MenuItem
                                 onClick={(e) => {
                                    setAnchorEl(null);
                                    setOpenDeleteModal(true);
                                 }}
                              >
                                 <ListItemIcon>
                                    <Delete />
                                 </ListItemIcon>
                                 <Typography>Eliminar</Typography>
                              </MenuItem>
                              <MenuItem
                                 onClick={(e) => {
                                    setAnchorEl(null);
                                    setOpenModifyModal(true);
                                 }}
                              >
                                 <ListItemIcon>
                                    <Edit />
                                 </ListItemIcon>
                                 <Typography>Editar</Typography>
                              </MenuItem>
                           </Menu>
                           <Box>
                              <ConfirmationModal
                                 open={openDeleteModal}
                                 setOpen={setOpenDeleteModal}
                                 onConfirm={handleDelete}
                                 title={"Eliminar aviso"}
                                 body={
                                    <>
                                       <Box sx={{ textAlign: "center" }}>
                                          <Typography>¿Está seguro que desea eliminar este aviso?</Typography>
                                          <Typography
                                             sx={{ display: "flex", justifyContent: "left" }}
                                             fontStyle={"italic"}
                                             color={"red"}
                                             fontSize={13}
                                          >
                                             Una vez confirmado, la información no se podrá recuperar
                                          </Typography>
                                       </Box>
                                    </>
                                 }
                              />
                           </Box>
                        </>
                     ) : null}
                  </Box>
               }
            </Box>
            <Box sx={{ width: "100%", height: 200, overflowY: "auto" }}>
               <StringToHTMLOutput
                  text={props.advice.description}
                  sx={{ px: 1, py: 0, fontSize: 14, textAlign: "justify" }}
               />
            </Box>
            <Divider sx={{ my: 2 }} />
            <Box sx={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center" }}>
               <Typography fontSize={12}>
                  {props.author?.firstName} {props.author?.lastName}
               </Typography>
               <Typography fontSize={12}>{getDateFromISODate(props.advice.createdAt)} </Typography>
            </Box>
         </Box>
         <CustomModalComponent open={openModifyModal} setOpen={setOpenModifyModal} title="Modificar aviso" timeStamp>
            <ModalAddAdviceComponent open={openModifyModal} setOpen={setOpenModifyModal} adviceData={props.advice} />
         </CustomModalComponent>
      </Box>
   );
};
