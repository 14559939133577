import { School } from "@mui/icons-material";
import { Box, CircularProgress, Container, Divider, Grid, Stack, Typography } from "@mui/material";
import { getDateFromISODate } from "../../../../const/globalConst";

export const StudiesData = ({ data }) => {
   const hasStudyRecords = data?.profData?.studyRecord && data.profData.studyRecord.length > 0;

   return (
      <Container
         sx={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            overflow: "auto",
            height: "60vh",
            p: 3,
         }}
      >
         <Box sx={{ pb: 2 }}>
            <Typography variant="h6" fontWeight={"bold"} color={"#64748B"}>
               ESTUDIOS
            </Typography>
         </Box>
         {data ? (
            hasStudyRecords ? (
               <Box sx={{ maxHeight: 280, overflow: "auto" }}>
                  {data.profData.studyRecord.map((e, i) => (
                     <Grid container key={i} sx={{ px: 4 }}>
                        <Grid xs={12} sx={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                           <School sx={{ mr: 2, color: "#162c44" }} />
                           <Stack sx={{ display: "flex", flexDirection: "column" }}>
                              <Typography variant="body1" color={"#152C44"}>
                                 {e.title}
                              </Typography>
                              <Typography variant="caption">
                                 {e.institution} {getDateFromISODate(e.startTime)} - {getDateFromISODate(e.endTime)}
                              </Typography>
                           </Stack>
                        </Grid>
                        {data.profData.studyRecord.length === i + 1 ? null : (
                           <>
                              <Grid xs sx={{ display: "flex", justifyContent: "center" }}>
                                 <Divider
                                    orientation="vertical"
                                    flexItem
                                    sx={{ borderWidth: 1.5, borderColor: "#162c44", height: 25, mb: 1 }}
                                 />
                              </Grid>
                              <Grid xs={11.5}></Grid>
                           </>
                        )}
                     </Grid>
                  ))}
               </Box>
            ) : (
               <Typography>Sin datos para mostrar</Typography>
            )
         ) : data === undefined ? (
            <CircularProgress />
         ) : (
            <Typography>Sin datos para mostrar</Typography>
         )}
      </Container>
   );
};
