import { Box, Button, Divider, IconButton, Modal, Stack, Typography } from "@mui/material";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { useContext, useEffect, useState } from "react";
import { ExternalGovernanceSessionContext } from "../../../context/governanceContext/externalSessionContext";
import { SessionFile } from "../SessionComponent/SubComponents/SessionFile";
import { UploadFileToSessionExternal } from "./UploadFileToSessionExternal";
import { Close } from "@mui/icons-material";

const GetSessionFiles = () => {
   const { socket, setFileArray } = useContext(ExternalGovernanceSessionContext);

   const socketHandler = (valuesFromSocket) => {
      setFileArray((current) => [...current, valuesFromSocket]);
   };

   useEffect(() => {
      if (socket === null) return;
      socket.on("fetch-file", socketHandler);

      return () => {
         socket.off("fetch-file", socketHandler);
      };
   }, [socket]);
};

export const ExternalSessionFiles = (props: { handleCloseProp?: Function }) => {
   const { fileArray, session, externalSessionToken } = useContext(ExternalGovernanceSessionContext);
   const [openSessionFileModal, setOpenSessionFileModal] = useState(false);
   GetSessionFiles();

   const handleClose = () => {
      setOpenSessionFileModal(false);
   };

   return (
      <>
         {/* //MOBILE */}
         <Box sx={{ display: { xs: "block", lg: "none" }, bgcolor: "white", borderRadius: 5 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
               <Box sx={{ p: 2 }}>
                  <Typography sx={{ fontWeight: 600 }}>Archivos</Typography>
                  <Typography sx={{ fontSize: 12, color: "#64748B" }}>
                     Documentación generada para analizar en la sesión
                  </Typography>
               </Box>
               <Box sx={{ alignSelf: "center", pr: 2 }}>
                  <IconButton onClick={() => props.handleCloseProp()}>
                     <Close sx={{ color: "#162c4470" }} />
                  </IconButton>
               </Box>
            </Box>
            <Divider sx={{ color: "#EBEBEB", border: 1 }} />
            <Stack
               sx={{
                  bgcolor: "#FFFFFF",
                  borderLeft: 1,
                  borderRight: 1,
                  borderBottom: 1,
                  borderColor: "#E0E0E0",
                  py: 2,
                  alignItems: "center",
               }}
            >
               <Button
                  onClick={() => {
                     setOpenSessionFileModal(true);
                  }}
                  sx={{ display: "flex", gap: 1, alignContent: "center", ml: -1, width: "55%" }}
               >
                  <AddCircleIcon sx={{ color: "#64748B", fontSize: 16 }} />
                  <Typography sx={{ color: "#64748B", fontSize: { xs: 12, lg: 10 }, fontWeight: 600 }}>
                     Agregar archivo
                  </Typography>
               </Button>
               <Typography sx={{ fontSize: { xs: 12, lg: 10 }, color: "#D9D9D9", textAlign: "center" }}>
                  Los archivos serán compartidos en la sesión actual.
               </Typography>
            </Stack>
            <Box
               sx={{
                  bgcolor: "white",
                  borderBottom: 0.65,
                  borderLeft: 0.65,
                  borderRight: 0.65,
                  borderColor: "#E0E0E0",
                  height: "50vh",
                  overflowY: "auto",
                  borderBottomLeftRadius: "20px",
                  borderBottomRightRadius: "20px",
                  maxWidth: 500,
               }}
            >
               {fileArray.map((file, i) => (
                  <SessionFile key={`file-${i}`} file={file} session={session} token={externalSessionToken} />
               ))}
            </Box>
         </Box>

         {/* //COMPUTER */}

         <Box sx={{ height: "100%", borderRadius: 5, display: { xs: "none", lg: "block" } }}>
            <Box sx={{ bgcolor: "#F8F8F9", border: 1, borderColor: "#E0E0E0", p: 1 }}>
               <Typography sx={{ fontWeight: 600 }}>Archivos</Typography>
               <Typography sx={{ fontSize: { xs: 11, lg: 9 }, color: "#64748B" }}>
                  Documentación generada para analizar en la sesión
               </Typography>
            </Box>

            <Stack
               sx={{
                  bgcolor: "#FFFFFF",
                  borderLeft: 1,
                  borderRight: 1,
                  borderBottom: 1,
                  borderColor: "#E0E0E0",
                  py: 2,
                  alignItems: "center",
               }}
            >
               <Button
                  onClick={() => {
                     setOpenSessionFileModal(true);
                  }}
                  sx={{ display: "flex", gap: 1, alignContent: "center", ml: -1, width: "55%" }}
               >
                  <AddCircleIcon sx={{ color: "#64748B", fontSize: 16 }} />
                  <Typography sx={{ color: "#64748B", fontSize: { xs: 12, lg: 10 }, fontWeight: 600 }}>
                     Agregar archivo
                  </Typography>
               </Button>
               <Typography sx={{ fontSize: { xs: 12, lg: 10 }, color: "#D9D9D9", textAlign: "center" }}>
                  Los archivos serán compartidos en la sesión actual.
               </Typography>
            </Stack>
            <Box
               sx={{
                  bgcolor: "white",
                  borderBottom: 0.65,
                  borderLeft: 0.65,
                  borderRight: 0.65,
                  borderColor: "#E0E0E0",
                  height: 750,
                  overflowY: "auto",
               }}
            >
               {fileArray.map((file, i) => (
                  <SessionFile key={`file-${i}`} file={file} session={session} token={externalSessionToken} />
               ))}
            </Box>
         </Box>
         <Modal
            open={openSessionFileModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ display: "flex", alignItems: "center", justifyContent: "center", px: 2 }}
         >
            <UploadFileToSessionExternal setOpenSessionFileModal={setOpenSessionFileModal} />
         </Modal>
      </>
   );
};
