import { useMemo } from "react";

const GetTablesFilters = (data, filterName) => {
   const filter = useMemo(() => {
      if (!data) return [];
      const names: string[] = [];
      for (const object of data) {
         //Complaints
         if (filterName === "branches") names.push(object.branch?.name ?? object.company?.person_details.comercialName);
         if (filterName === "categories") names.push(object.complaint.category);
         //MasterRoles
         if (filterName === "roles") {
            for (const role of object.role) {
               names.push(role.name);
            }
         }
      }
      return Array.from(new Set(names));
   }, [data, filterName]);

   return filter;
};

export default GetTablesFilters;
