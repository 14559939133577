import { useContext, useEffect, useMemo, useState } from "react";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { getCommitmentsByGovernanceBody } from "../../../lib/gobCorpBEClient";

interface UseGetCommitmentsInfoProps {
   setIsLoading?: Function;
}
export const useGetCommitmentsInfo = (props: UseGetCommitmentsInfoProps) => {
   const { setIsLoading = () => {} } = props;
   const { gobernanceBody, selectedGovernance, setSpecificCalendar, getSpecificCalendar, selectedSection } =
      useContext(GovernanceContext);
   const [totalCommitments, setTotalCommitments] = useState<any[]>([]);
   const [commitmentsByGovernance, setCommitmentsByGovernance] = useState<any[]>([]);

   useEffect(() => {
      setIsLoading(true);
      const fetchData = async () => {
         if (selectedGovernance?._id !== "0") {
            getSpecificCalendar(selectedGovernance?.calendar, false, true);
         } else {
            setSpecificCalendar(null);
         }
         const fetchCommitments = async () => {
            if (selectedGovernance?._id !== "0") {
               const governingBodyCommitment = await getCommitmentsByGovernanceBody(selectedGovernance._id);
               setCommitmentsByGovernance(governingBodyCommitment);
            }
         };
         await fetchCommitments();
         setIsLoading(false);
      };
      if (gobernanceBody.length > 0) fetchData();
      setIsLoading(false);
   }, [gobernanceBody, selectedGovernance?._id, selectedSection]);

   const commitments = useMemo(() => {
      if (commitmentsByGovernance) {
         const commitmentsObject = {
            pending: commitmentsByGovernance.filter((commitment) => commitment.status === "PENDING"),
            process: commitmentsByGovernance.filter((commitment) => commitment.status === "PROCESS"),
            review: commitmentsByGovernance.filter((commitment) => commitment.status === "REVIEW"),
            complete: commitmentsByGovernance.filter((commitment) => commitment.status === "COMPLETE"),
            expired: commitmentsByGovernance.filter((commitment) => commitment.status === "EXPIRED"),
         };
         setTotalCommitments(commitmentsByGovernance);
         return commitmentsObject;
      }
   }, [commitmentsByGovernance, selectedGovernance, selectedSection]);
   return { commitments, totalCommitments, commitmentsByGovernance };
};
