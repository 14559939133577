import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material";
import { InputTextField } from "../../../Inputs/InputTextField";
import { useContext } from "react";
import { getContrastYIQ } from "../../../../const/globalConst";
import { ExternalGovernanceSessionContext } from "../../../../context/governanceContext/externalSessionContext";
import { ArrowDownward } from "@mui/icons-material";

export const BillCommentExternal = () => {
   const { colors, valuesFromBill } = useContext(ExternalGovernanceSessionContext);

   return (
      <>
         <Stack>
            <Typography
               sx={{
                  bgcolor: colors.primary,
                  textAlign: "center",
                  fontSize: 12,
                  color: getContrastYIQ(colors.primary),
               }}
            >
               COMENTARIOS CLAVE DEL PRESIDENTE / VICEPRESIDENTE
            </Typography>
            <Box display={"flex"} width={"100%"}>
               <InputTextField
                  id={"commentTemp"}
                  name={"commentTemp"}
                  type={"text"}
                  multiline
                  sx={{
                     ".Mui-disabled": { "-webkit-text-fill-color": "black !important" },
                     bgcolor: "#EFEFEF",
                     width: "100%",
                     fontSize: 12,
                     p: 2,
                  }}
                  unstyled
                  placeholder="Descripción..."
                  disabled={true}
               />
            </Box>
         </Stack>
         <Stack spacing={0.5}>
            <Accordion disableGutters elevation={0}>
               <AccordionSummary
                  sx={{
                     bgcolor: colors.primary,
                     height: "22px",
                     minHeight: "22px !important",
                  }}
                  expandIcon={<ArrowDownward fontSize="small" sx={{ color: getContrastYIQ(colors.secondary) }} />}
               >
                  <Typography
                     sx={{
                        width: "100%",
                        color: getContrastYIQ(colors.primary),
                        textAlign: "center",
                        fontSize: 12,
                     }}
                  >
                     COMENTARIOS
                  </Typography>
               </AccordionSummary>
               <AccordionDetails sx={{ p: 0 }}>
                  <Stack spacing={0.5}>
                     <Stack
                        spacing={0.5}
                        sx={{
                           flex: 1,
                           justifyContent: "center",
                           borderLeft: `5px solid ${colors.primary}`,
                           bgcolor: "#EFEFEF",
                           p: 2,
                        }}
                     >
                        {valuesFromBill[`comment`]?.map((comment, i) => (
                           <Box
                              key={`general-comment-${i}`}
                              sx={{
                                 display: "flex",
                                 width: "100%",
                                 justifyContent: "space-between",
                                 "&:hover .iconButton": { display: "inherit" },
                              }}
                           >
                              <Typography sx={{ fontSize: 12 }}>{`${i + 1}. ${comment}`}</Typography>
                           </Box>
                        ))}
                     </Stack>
                  </Stack>
               </AccordionDetails>
            </Accordion>
         </Stack>
      </>
   );
};
