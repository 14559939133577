import React from "react";
import { Box, Modal, Typography } from "@mui/material";
import { getDateAndTimeFromISODate } from "../const/globalConst";

interface ConfirmationModalProps {
   open: boolean;
   setOpen: Function;
   title: React.ReactNode;
   subtitle?: string;
   body: React.ReactNode;
   selectedOption?: string;
   onConfirm: (event: React.MouseEvent<HTMLButtonElement>) => void;
   onCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void;
   isOnGroup?: boolean;
   blue?: boolean;
   onClose?: () => {};
}

export const ClientTypeModal = (props: ConfirmationModalProps) => {
   const handleClose = () => {
      props.setOpen(false);
   };

   return (
      <div>
         <Modal
            open={props.open}
            onClose={props.onClose ? props.onClose : handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ display: "flex" }}
         >
            <Box sx={{ m: "auto", borderRadius: 2 }}>
               <Box
                  sx={{
                     display: "flex",
                     justifyContent: "center",
                     flexDirection: "column",
                     bgcolor: props.blue ? "#162c44" : "#E0E0E0",
                     p: 2,
                     borderTopLeftRadius: 5,
                     borderTopRightRadius: 5,
                  }}
               >
                  <Typography variant="h5" color={props.blue ? "white" : "black"} fontWeight={600}>
                     {props.title}
                  </Typography>
                  {props.subtitle ? (
                     <Typography variant="subtitle1" fontWeight={600} sx={{ color: props.blue ? "white" : "black" }}>
                        {getDateAndTimeFromISODate(props.subtitle)}
                     </Typography>
                  ) : null}
               </Box>
               <Box
                  sx={{
                     justifyContent: "center",
                     alignItems: "center",
                     // border: "solid #EBEBEB",
                     borderWidth: "thin 0px",
                     bgcolor: "white",
                     borderBottomLeftRadius: 5,
                     borderBottomRightRadius: 5,
                  }}
               >
                  {props.body}
               </Box>
            </Box>
         </Modal>
      </div>
   );
};
