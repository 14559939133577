import React from "react";
import { Box, DialogTitle, IconButton, Modal, Stack, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

interface CustomModalProps {
   open: boolean;
   setOpen: Function;
   title: string;
   timeStamp?: boolean;
   children: React.ReactNode;
   onClose?: Function;
}

export const CustomModalComponent = (props: CustomModalProps) => {
   const { open, setOpen, onClose, title, timeStamp, children } = props;

   const handleClose = () => {
      setOpen(false);
      if (onClose) onClose();
   };
   const currentDate = new Date();
   const hour = currentDate.getHours();
   const minute = currentDate.getMinutes();
   const amPM = hour >= 12 ? "p. m." : "a. m.";
   const formattedHour = hour % 12 || 12;

   return (
      <Modal open={open} sx={{ display: "flex" }} tabIndex={1}>
         <Box sx={{ m: "auto", maxWidth: "60%" }}>
            <Box bgcolor={"#162c44"} px={1} sx={{ borderTopLeftRadius: 8, borderTopRightRadius: 8 }}>
               <DialogTitle sx={{ alignItems: "center", display: "flex", justifyContent: "space-between" }}>
                  <Stack>
                     <Typography fontSize={20} color={"white"} fontWeight={600}>
                        {title}
                     </Typography>
                     {timeStamp && (
                        <Typography color={"white"}>
                           {new Date().toLocaleDateString("es-MX", {
                              day: "numeric",
                              month: "long",
                              year: "numeric",
                           }) + `, ${formattedHour}:${minute < 10 ? "0" + minute : minute} ${amPM}`}
                        </Typography>
                     )}
                  </Stack>
                  <IconButton onClick={() => handleClose()}>
                     <CloseIcon sx={{ color: "white" }} />
                  </IconButton>
               </DialogTitle>
            </Box>

            <Box
               sx={{
                  justifyContent: "center",
                  alignItems: "center",
                  borderWidth: "thin 0px",
                  bgcolor: "white",
                  borderBottomLeftRadius: 8,
                  borderBottomRightRadius: 8,
                  overflow: "auto",
               }}
            >
               {children}
            </Box>
         </Box>
      </Modal>
   );
};
