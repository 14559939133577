import { useContext, useEffect, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../../context/userContext";
import { SnackBarContext } from "../../../context/snackBarContext";
import { getManyCompaniesById, getManyCorporateData, getUrlS3 } from "../../../lib/usersBEClient";
import { FilterComponent } from "../../../components/FilterComponent";
import { HeadCell, TableComponent } from "../../../components/TableComponent";

let columns: HeadCell[] = [
   { field: "logo", headerName: "Logo", type: "string" },
   { field: "name", headerName: "Nombre", type: "string" },
   { field: "type", headerName: "Tipo", type: "string" },
   { field: "councilType", headerName: "Tipo de consejo", type: "string" },
   { field: "status", headerName: "Estatus", type: "boolean" },
];

export const CompaniesScreen = () => {
   const { user, adminCompanies, isloadingCompanies, isImplementationUser, companies } = useContext(UserContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [companiesRow, setCompaniesRow] = useState([]);
   const [filteredCompanies, setFilteredCompanies] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const navigate = useNavigate();

   const fetchCompaniesInfo = async () => {
      const companyData: any[] = [];
      setIsLoading(true);
      try {
         if (user.group) {
            const logo = await getDataS3(`group/${user.group._id}`);
            companyData.push({
               _id: user.group._id,
               logo: logo,
               name: user.group.name,
               status: user.group.disabled,
               councilType: "---",
               type: "Grupo empresarial",
               group: true,
            });
         }
         const companyCorporateDatas = await getManyCorporateData(companies.map((company) => company._id));
         const allCompanies = await getManyCompaniesById(companies.map((company) => company._id));
         for (const company of allCompanies) {
            companyData.push({
               _id: company._id,
               logo: (
                  <img
                     defaultValue={company.logo}
                     src={company.logo}
                     alt="logo"
                     style={{ maxWidth: "140px", maxHeight: "50px" }}
                  />
               ),
               name: company.person_details.comercialName,
               status: company.disabled,
               type: "Empresa",
               councilType: companyCorporateDatas?.find((c) => c.companyId === company._id)?.councilType ?? "---",
            });
         }
         setCompaniesRow(companyData);
         setFilteredCompanies(companyData);
      } catch (error) {
         showSnackBar("Error al conseguir empresas", true);
      }
      setIsLoading(false);
   };

   useEffect(() => {
      fetchCompaniesInfo();
      // eslint-disable-next-line
   }, [isloadingCompanies, adminCompanies]);

   const getDataS3 = async (companyId: string) => {
      const logoUrl: string = await getUrlS3("images-lecosy", { folder: companyId }, "logo.png");
      return <img defaultValue={logoUrl} src={logoUrl} alt="logo" style={{ maxWidth: "140px", maxHeight: "50px" }} />;
   };

   return (
      <Box sx={{ width: "100%" }}>
         {!isLoading && !isloadingCompanies ? (
            <>
               <FilterComponent
                  originaltableOneRows={companiesRow}
                  tableOneFilteredRows={filteredCompanies}
                  setTableOneFilteredRows={setFilteredCompanies}
                  filters={[]}
                  fullWidth
                  searchBar={<></>}
                  disableRefresh
               />

               <TableComponent
                  headerColor
                  defaultColumnToOrder="empresa"
                  defaultOrder="asc"
                  defaultRowsPerPage={5}
                  emptyDataText={
                     companiesRow.length > 0
                        ? "No se encontró una empresa con ese nombre"
                        : "No existen empresas registradas"
                  }
                  headers={columns}
                  loader={isLoading}
                  rows={filteredCompanies}
                  rowsPerPageOptions={[5, 10, 20]}
                  onClick={(_e, row) => {
                     if (row.group)
                        isImplementationUser()
                           ? navigate(`/mi-lecosy/grupo/servicios`)
                           : navigate(`/mi-lecosy/grupo/inicio`);
                     else
                        isImplementationUser()
                           ? navigate(`/mi-lecosy/${row._id}/servicios`)
                           : navigate(`/mi-lecosy/${row._id}/inicio`);
                  }}
               />
            </>
         ) : (
            <>
               <Box width={"100%"} display="flex" justifyContent={"center"} p={1}>
                  <CircularProgress sx={{ alignSelf: "center" }} />
               </Box>
            </>
         )}
      </Box>
   );
};
