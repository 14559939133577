import { Box, Chip, Typography } from "@mui/material";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";

export const AssignationComponent = (props: { users: string[] }) => {
   const { users } = props;
   if (!users || users.length === 0) return <></>;
   const remainingUsers = users.slice(2);
   return (
      <Box sx={{ columnGap: 1, display: "flex", justifyContent: "center" }}>
         {users.slice(0, 2).map((user, index) => (
            <Typography
               key={index}
               fontSize={14}
               fontWeight={600}
               sx={{
                  color: "#787486",
                  alignItems: "center",
                  backgroundColor: "#EBEBEB",
                  px: 2,
                  borderRadius: "7px",
                  display: "flex",
               }}
            >
               {user.split(" ")[0] + " " + user.split(" ")[1].charAt(0) + "."}
            </Typography>
         ))}
         {remainingUsers.length > 0 && (
            <Typography
               fontSize={14}
               fontWeight={600}
               sx={{
                  color: "#787486",
                  alignItems: "center",
                  backgroundColor: "#EBEBEB",
                  borderRadius: "7px",
                  justifyContent: "center",
                  px: 1,
               }}
            >{`+${remainingUsers.length}`}</Typography>
         )}
      </Box>
   );
};

export const PriorityComponent = ({ priority }) => {
   switch (priority) {
      case "HIGH":
         return (
            <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
               <ArrowUpwardIcon fontSize="small" sx={{ color: "#ff0000" }} />
               <Typography fontSize={15} color={"#ff0000"}>
                  Alta
               </Typography>
            </Box>
         );
      case "LOW":
         return (
            <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
               <ArrowDownwardIcon fontSize="small" sx={{ color: "#009929" }} />
               <Typography fontSize={15} color={"#009929"}>
                  Baja
               </Typography>
            </Box>
         );
      case "MEDIUM":
         return (
            <Box sx={{ display: "flex", justifyContent: "center", alignContent: "center" }}>
               <ArrowUpwardIcon fontSize="small" sx={{ color: "#ffa500" }} />
               <Typography fontSize={15} color={"#ffa500"}>
                  Media
               </Typography>
            </Box>
         );
      default:
         return <Typography fontSize={15}>sin datos</Typography>;
   }
};

export const StatusComponent = ({ status }) => {
   switch (status) {
      case "PENDING":
         return (
            <Chip
               label={
                  <Typography fontSize={14} color={"#64748B"}>
                     Pendiente
                  </Typography>
               }
               sx={{ bgcolor: "#7D8DA61A" }}
            />
         );
      case "REVIEW":
         return (
            <Chip
               label={
                  <Typography fontSize={14} color={"#F1B44C"}>
                     En revisión
                  </Typography>
               }
               sx={{ backgroundColor: "#FFF5E8" }}
            />
         );
      case "COMPLETE":
         return (
            <Chip
               label={
                  <Typography fontSize={14} color={"#009929"}>
                     Completo
                  </Typography>
               }
               sx={{ backgroundColor: "#e6f8eb" }}
            />
         );
      case "EXPIRED":
         return (
            <Chip
               label={
                  <Typography fontSize={14} color={"#E00000"}>
                     Vencido
                  </Typography>
               }
               sx={{ backgroundColor: "#FF000026" }}
            />
         );
      case "PROCESS":
         return (
            <Chip
               label={
                  <Typography fontSize={14} color={"#3498DB"}>
                     En proceso
                  </Typography>
               }
               sx={{ backgroundColor: "#0085FF1A" }}
            />
         );
      default:
         break;
   }
};
