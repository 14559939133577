import { ConsultiveGroupUsers } from "../../../components/MiLecosy/ConsultiveGroup/ConsultiveGroupUsers";
import { ConsultiveCompaniesTable } from "../../../components/MiLecosy/ConsultiveGroup/ConsultiveCompaniesTable";
import { ConsultiveGroupGoverningBodiesComponent } from "../../../components/MiLecosy/ConsultiveGroup/ConsultiveGroupGoverningBodiesComponent";

export const ConsultiveGroupScreen = () => {
   return (
      <>
         <ConsultiveGroupGoverningBodiesComponent />
         <ConsultiveGroupUsers />
         <ConsultiveCompaniesTable />
      </>
   );
};
