import React, { useContext } from "react";
import { Box, Container, CssBaseline, Stack, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { SystemThemeConfig } from "../config/systemTheme.config";
import { SnackBarContext } from "../context/snackBarContext";
import { SnackBarComponent } from "../components/SnackBarComponent";
import { Form, Formik } from "formik";
import * as yup from "yup";
import { InputTextField } from "../components/Inputs/InputTextField";
import { restoreMasterUserPassword } from "../lib/usersBEClient";

export const ForgotPasswordScreen = () => {
   const { showSnackBar } = useContext(SnackBarContext);

   const schema = yup.object({
      email: yup.string().email("Correo electrónico invalido").required("Campo obligatorio"),
   });

   type schemaType = yup.InferType<typeof schema>;

   const handleSubmit = async (values: schemaType) => {
      try {
         await restoreMasterUserPassword(values.email);
         showSnackBar("Instrucciones enviadas con éxito.", false);
      } catch (error) {
         showSnackBar("Error, intente más tarde.", true);
      }
   };

   return (
      <SystemThemeConfig>
         <SnackBarComponent />
         <Box bgcolor="#12181B">
            <Container maxWidth="lg">
               <CssBaseline />
               <Box minHeight="100vh" display="flex" alignItems="center" justifyContent="center">
                  <Box
                     bgcolor="whitesmoke"
                     display="flex"
                     alignItems="center"
                     flexDirection="column"
                     borderRadius={2}
                     py={5}
                     sx={{ width: "90%" }}
                  >
                     <img
                        src="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/logo-lecosy.png"
                        alt="Logo Lecosy"
                        style={{ maxWidth: 250, marginBottom: 20 }}
                     />
                     <Formik
                        initialValues={{ email: "" }}
                        onSubmit={handleSubmit}
                        validationSchema={yup.object().shape({
                           email: yup.string().email("Correo electrónico invalido").required("Campo obligatorio"),
                        })}
                     >
                        <Form>
                           <Stack spacing={2} minWidth="50%">
                              <Typography color="black" variant="h4" align="center" sx={{ my: 2 }}>
                                 Restaurar Contraseña
                              </Typography>
                              <Typography color="black" sx={{ mr: 2 }}>
                                 Ingrese el correo electrónico con el que se registró.
                              </Typography>
                              <InputTextField id="email" name="email" label="Correo electrónico" type="email" />
                              <Button variant="contained" color="primary" type="submit">
                                 Recibir instrucciones
                              </Button>
                              <Link
                                 to={"/login"}
                                 style={{ textDecoration: "none", color: "#12181B", textAlign: "center" }}
                              >
                                 Regresar a inicio de sesión
                              </Link>
                           </Stack>
                        </Form>
                     </Formik>
                  </Box>
               </Box>
            </Container>
         </Box>
      </SystemThemeConfig>
   );
};
