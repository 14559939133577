import { createContext, useCallback, useContext, useEffect, useMemo, useReducer, useState } from "react";
import {
   getChargeByGovernUsers,
   getClassificationById,
   getGoberningBodiesByCompany,
   updateClassificationData,
   updateSeriesByGovernBody,
   getAffairsByCompany,
   getConsultiveGroupById,
} from "../../lib/gobCorpBEClient";
import { getCorporateData, getUserDataGB, updateCorporateData } from "../../lib/usersBEClient";
import { useParams } from "react-router-dom";
import { UserContext } from "../userContext";
import { SnackBarContext } from "../snackBarContext";

export interface documentType {
   name: string;
   size: number;
}

export interface CorporateData {
   _id: string;
   societyKind: string;
   legalConcept: string;
   legalConceptOther: string;
   physicalAddress: string;
   incorporationYear: Date | string;
   taxRegime: string;
   operatingCountries: [];
   watchman: [];
   socialObject: [];
   administration: string;
   legalRepresentative: string;
   councilType: string;
   administratorFaculties: string;
   filePact: documentType;
   beneficiaryManual: documentType;
   updatedAt: Date;
   businessFolio: string;
   notaryName: string;
   notaryNumber: number;
   recordType: string;
   actNumber: number;
   grantDate: Date;
}

export interface AffairSeries {
   title: string;
   faculties: number;
}
export interface AffairsInterface {
   _id: string;
   title: string;
   series: AffairSeries[];
   classification: string;
   isNew?: boolean;
   companyId: string;
}

export interface SeriesInterface {
   title: string;
   nominalValue: number;
}

export interface ClassificationInterface {
   title: string;
   quorumA: number[];
   quorumV: number[];
}

export interface ConsultiveGroup {
   _id: string;
   name: string;
   companies: string[];
   allCompanies: string[];
   updatedAt: string;
   assemblies: any[];
}

interface CorporateDataContextType {
   affairs: AffairsInterface[];
   currentAffair: AffairsInterface;
   series: SeriesInterface[];
   classifications: ClassificationInterface[];
   corporateData: CorporateData;
   isLoading: boolean;
   bodies: any[];
   groupBodies: any[];
   governanceBody: any;
   members: any[];
   consultiveGroup: ConsultiveGroup;
   seed: number;
   setCurrentAffair: Function;
   handleChangedSeries: Function;
   setAffairs: Function;
   setSeries: Function;
   handleUpdateAffairInfo: Function;
   createSerie: Function;
   updateAffairSerie: Function;
   deleteAffairSerie: Function;
   updateSeries: Function;
   deleteSerie: Function;
   updateClassification: Function;
   updateMembers: Function;
   updateClassSeriesData: Function;
   handleUpdateLegalRepresentative: Function;
   deleteUnsaveAffair: Function;
   setSeed: Function;
}

interface CorporateDataState {
   affairs: AffairsInterface[];
   currentAffair: AffairsInterface;
   series: SeriesInterface[];
   classifications: ClassificationInterface[];
   corporateData: CorporateData;
   isLoading: boolean;
   bodies: any[];
   groupBodies: any[];
   governanceBody: any;
   members: any[];
   consultiveGroup: ConsultiveGroup;
}

const initialState: CorporateDataState = {
   affairs: null,
   currentAffair: null,
   series: [],
   classifications: [],
   corporateData: null,
   isLoading: false,
   bodies: [],
   groupBodies: [],
   governanceBody: null,
   members: [],
   consultiveGroup: null,
};

function corporateDataReducer(state: any, action) {
   switch (action.type) {
      case "SET_CORPORATE_DATA":
         return { ...state, corporateData: action.payload };
      case "SET_CONSULTIVE_GROUP":
         return { ...state, consultiveGroup: action.payload };
      case "SET_MEMBERS":
         return { ...state, members: action.payload };
      case "SET_GOVENANCE_BODY":
         return { ...state, governanceBody: action.payload };
      case "SET_BODIES":
         return { ...state, bodies: action.payload };
      case "SET_GROUP_BODIES":
         return { ...state, groupBodies: action.payload };
      case "SET_AFFAIRS":
         return { ...state, affairs: action.payload };
      case "SET_CURRENT_AFFAIR":
         return { ...state, currentAffair: action.payload };
      case "SET_SERIES":
         return { ...state, series: action.payload };
      case "SET_CLASSIFICATIONS":
         return { ...state, classifications: action.payload };
      case "SET_IS_LOADING":
         return { ...state, isLoading: action.payload };
      default:
         return state;
   }
}

// Crear contexto
export const CorporateDataContext = createContext<CorporateDataContextType | undefined>(undefined);

export const CorporateDataProvider = ({ children }) => {
   const { companyId } = useParams();
   const { showSnackBar } = useContext(SnackBarContext);
   const { companySelected, user, handleUpdateEditingSection } = useContext(UserContext);
   const [seed, setSeed] = useState<number>(0);
   const [state, dispatch] = useReducer(corporateDataReducer, initialState);

   //#region Dispatch

   const setCorporateData = useCallback((corporateData: CorporateData) => {
      dispatch({ type: "SET_CORPORATE_DATA", payload: corporateData });
   }, []);

   const setIsLoading = useCallback((isLoading: boolean) => {
      dispatch({ type: "SET_IS_LOADING", payload: isLoading });
   }, []);

   const setConsultiveGroup = useCallback((consultiveGroup: ConsultiveGroup) => {
      dispatch({ type: "SET_CONSULTIVE_GROUP", payload: consultiveGroup });
   }, []);

   const setMembers = useCallback((members: any[]) => {
      dispatch({ type: "SET_MEMBERS", payload: members });
   }, []);

   const setGoverningBodies = useCallback((bodies: any) => {
      dispatch({ type: "SET_BODIES", payload: bodies });
   }, []);

   const setGroupGoverningBodies = useCallback((bodies: any) => {
      dispatch({ type: "SET_GROUP_BODIES", payload: bodies });
   }, []);

   const setGovernanceBody = useCallback((governanceBody: any) => {
      dispatch({ type: "SET_GOVENANCE_BODY", payload: governanceBody });
   }, []);

   const setAffairs = useCallback((affairs: AffairsInterface[]) => {
      dispatch({ type: "SET_AFFAIRS", payload: affairs });
   }, []);

   const setCurrentAffair = useCallback((currentAffair: AffairsInterface) => {
      dispatch({ type: "SET_CURRENT_AFFAIR", payload: currentAffair });
   }, []);

   const setSeries = useCallback((series: SeriesInterface[]) => {
      dispatch({ type: "SET_SERIES", payload: series });
   }, []);

   const setClassifications = useCallback((classifications: ClassificationInterface[]) => {
      dispatch({ type: "SET_CLASSIFICATIONS", payload: classifications });
   }, []);

   //#end region

   //#region Functions

   const getBodies = useCallback(async () => {
      if (state.bodies.length > 0) return;
      if (companySelected?._id) {
         const bodies = await getGoberningBodiesByCompany(companySelected?._id);

         const users = [];
         for (const body of bodies) users.push(...body.users);
         const ids = users.map((e) => e.user); //array con formato de govern bodies
         const chargesIds = users.map((e) => e.charge);
         const cleanArray = ids.filter((a, b) => ids.indexOf(a) === b); //string array sin duplicados para mandar al backend
         const chargeData = await getChargeByGovernUsers(chargesIds);
         const userData = await getUserDataGB(cleanArray);

         let completeProfile = [];
         for (const element of users) {
            completeProfile.push({
               ...chargeData.find((e) => e._id === element.charge),
               ...userData.find((e) => e._id === element.user),
               ...element,
            });
         }
         // ASIGNACION DE DATA A ORGANOS DE GOBIERNO
         let mergedFinalBodies = [];
         for (let i = 0; i < bodies.length; i++) {
            const element = bodies[i];
            let bodiesData = (({ _id, title, regulationFile, structure, functions }) => ({
               _id,
               title,
               regulationFile,
               structure,
               functions,
            }))(element);
            let usersData = [];
            for (let j = 0; j < element.users.length; j++) {
               const elementUser = element.users[j]._id;
               if (element.title === "Asamblea de accionistas") {
                  let userObject = {
                     ...completeProfile.find((e) => e._id === elementUser && e.chargeName === "Accionista"),
                  };
                  if (Object.entries(userObject).length !== 0) {
                     usersData.push({ ...userObject });
                  }
               } else {
                  usersData.push({
                     ...completeProfile.find((e) => e._id === elementUser),
                  });
               }
            }
            mergedFinalBodies.push({ bodiesData, usersData });
         }
         setGoverningBodies(mergedFinalBodies);
      }
   }, [companySelected, companyId, getGoberningBodiesByCompany, seed]);

   useEffect(() => {
      getBodies();
   }, [getBodies]);

   const getGroupBodies = useCallback(async () => {
      if (state.consultiveGroup) {
         const bodies = await getGoberningBodiesByCompany(state.consultiveGroup._id);

         const users = [];
         for (const body of bodies) users.push(...body.users);
         const ids = users.map((e) => e.user); //array con formato de govern bodies
         const chargesIds = users.map((e) => e.charge);
         const cleanArray = ids.filter((a, b) => ids.indexOf(a) === b); //string array sin duplicados para mandar al backend
         const chargeData = await getChargeByGovernUsers(chargesIds);
         const userData = await getUserDataGB(cleanArray);

         let completeProfile = [];
         for (const element of users) {
            completeProfile.push({
               ...chargeData.find((e) => e._id === element.charge),
               ...userData.find((e) => e._id === element.user),
               ...element,
            });
         }
         // ASIGNACION DE DATA A ORGANOS DE GOBIERNO
         let mergedFinalBodies = [];
         for (let i = 0; i < bodies.length; i++) {
            const element = bodies[i];
            let bodiesData = (({ _id, title, regulationFile, structure, functions }) => ({
               _id,
               title,
               regulationFile,
               structure,
               functions,
            }))(element);
            let usersData = [];
            for (let j = 0; j < element.users.length; j++) {
               const elementUser = element.users[j]._id;
               if (element.title === "Asamblea de accionistas") {
                  let userObject = {
                     ...completeProfile.find((e) => e._id === elementUser && e.chargeName === "Accionista"),
                  };
                  if (Object.entries(userObject).length !== 0) {
                     usersData.push({ ...userObject });
                  }
               } else {
                  usersData.push({
                     ...completeProfile.find((e) => e._id === elementUser),
                  });
               }
            }
            mergedFinalBodies.push({ bodiesData, usersData });
         }
         setGroupGoverningBodies(mergedFinalBodies);
      }
   }, [getGoberningBodiesByCompany, seed, state.consultiveGroup]);

   useEffect(() => {
      getGroupBodies();
   }, [getBodies, seed, state.consultiveGroup]);

   const getCorporateInfo = useCallback(async () => {
      if (companySelected?._id && !state.corporateData) {
         const data = await getCorporateData(companySelected?._id);
         setCorporateData(data);
      }
   }, [companySelected?._id, setCorporateData, state.corporateData, seed]);

   useEffect(() => {
      getCorporateInfo();
   }, [getCorporateInfo]);

   const getConsultiveData = useCallback(async () => {
      if (user.group && !state.consultiveGroup) {
         const data = await getConsultiveGroupById(user.group?._id);
         if (data) setConsultiveGroup(data);
      }
   }, [setConsultiveGroup, state.consultiveGroup, user.group, state.corporateData]);

   useEffect(() => {
      getConsultiveData();
   }, [getConsultiveData, companySelected?._id, seed, state.isCouncil, state.corporateData]);

   const fetchClassData = useCallback(async () => {
      if (companySelected) {
         const classData = await getClassificationById(companySelected._id);
         if (classData) setClassifications(classData.categories);
      }
   }, [companySelected?._id]);

   useEffect(() => {
      fetchClassData();
   }, [fetchClassData, seed, state.isCouncil]);

   const updateClassSeriesData = async () => {
      try {
         setIsLoading(true);
         const classUpdatedData = {
            categories: state.classifications,
         };
         await updateClassificationData(companySelected._id, classUpdatedData);
         await updateSeriesByGovernBody(state.governanceBody._id, state.series);
         setSeed(Math.random());
         setIsLoading(false);
         showSnackBar("Series y clasificaciones modificadas correctamente", false);
         handleUpdateEditingSection("series", true);
      } catch (error) {
         console.log(error);
         showSnackBar("Error al modificar series y classificaciones", true);
      }
   };

   const handleUpdateLegalRepresentative = async (representative, selectedCompanyId) => {
      try {
         if (state.corporateData?.legalRepresentative !== representative && representative) {
            const data = {
               companyId: selectedCompanyId,
               legalRepresentative: representative,
            };
            await updateCorporateData(state.corporateData._id, data);
            showSnackBar("Apoderado legal actualizado correctamente", false);
            setSeed((s) => s + 1);
         }
      } catch (error) {
         console.log(error);
         showSnackBar("Error al actualizar al apoderado legal", true);
      }
   };

   const updateClassification = (quorumList, index) => {
      const tempClasifications = state.classifications;
      tempClasifications[index].quorumA = quorumList.quorumA;
      tempClasifications[index].quorumV = quorumList.quorumV;
      setClassifications(tempClasifications);
   };

   const getGovernance = useCallback(async () => {
      if (companySelected?._id) {
         const response = await getGoberningBodiesByCompany(companySelected._id);
         if (response === undefined) return;
         const body = response.find((body) => body.title === "Asamblea de accionistas");
         if (body) {
            const users = body.users;
            const ids = users.map((e) => e.user);
            const chargesIds = users.map((e) => e.charge);
            const cleanArray = ids.filter((a, b) => ids.indexOf(a) === b);
            const chargeData = await getChargeByGovernUsers(chargesIds);
            const userData = await getUserDataGB(cleanArray);

            let completePorfile = [];
            for (const user of users) {
               completePorfile.push({
                  ...chargeData.find((e) => e._id === user.charge),
                  ...userData.find((e) => e._id === user.user),
                  ...user,
               });
            }

            let usersData = [];
            for (const element of body.users) {
               const elementUser = element._id;
               usersData.push({
                  ...completePorfile.find((e) => e._id === elementUser),
               });
            }

            setMembers(usersData.filter((user) => user.chargeName === "Accionista"));
            setGovernanceBody(body);
            setSeries(
               body.series.sort((a, b) => {
                  let titleA = a.title.toLowerCase();
                  let titleB = b.title.toLowerCase();
                  if (titleA < titleB) {
                     return -1;
                  }
                  if (titleA > titleB) {
                     return 1;
                  }
                  return 0;
               })
            );
         }
      }
   }, [companySelected?._id, seed, state.isCouncil]);

   useEffect(() => {
      getGovernance();
   }, [getGovernance]);

   const updateMembers = () => {
      setSeed(seed + 1);
   };

   const updateSeries = (serie, index) => {
      const tempArray = state.series;
      tempArray[index] = serie;
      setSeries(tempArray);
      handleUpdateEditingSection("series");
   };

   const createSerie = () => {
      const newSerie: SeriesInterface = {
         title: `Serie`,
         nominalValue: 0,
      };
      setSeries(state.series.concat(newSerie));
      handleUpdateEditingSection("series");
   };

   const deleteSerie = (index, setSeed) => {
      const tempArray = state.series;
      tempArray.splice(index, 1);
      setSeed((s) => s + 1);
      setSeries(tempArray);
      handleUpdateEditingSection("series");
   };

   const handleChangedSeries = (seriesList) => {
      setCurrentAffair({ ...state.currentAffair, series: seriesList });
      const tempCurrentAffair = { ...state.currentAffair, series: seriesList };
      const tempAffairList = state.affairs;
      const tempAffairListIds = tempAffairList.map((affair) => affair._id);
      const affairIndex = tempAffairListIds.indexOf(tempCurrentAffair._id);
      tempAffairList[affairIndex] = tempCurrentAffair;
      setAffairs(tempAffairList);
   };

   const updateAffairSerie = (serie, index) => {
      const tempSeries = state.currentAffair.series;
      tempSeries[index] = serie;
      handleChangedSeries(tempSeries);
   };

   const deleteAffairSerie = (index) => {
      const tempSeriesList = state.series;
      const slicedSeries = [
         ...tempSeriesList.slice(0, index),
         ...tempSeriesList.slice(index + 1, tempSeriesList.length),
      ];
      setSeries(slicedSeries);
   };

   const fetchAffairsData = useCallback(async () => {
      if (state.affairs !== null || (state.affairs && state.affairs.length > 0)) return;
      const response = await getAffairsByCompany(companySelected?._id);
      if (response) setAffairs(response.data);
   }, [companySelected, seed]);

   useEffect(() => {
      fetchAffairsData();
   }, [fetchAffairsData, companySelected, seed]);

   const deleteUnsaveAffair = (affairId: string) => {
      const tempAffairs = state.affairs;
      const newAffairsList = tempAffairs.filter((a) => a._id !== affairId);
      setAffairs(newAffairsList);
      setCurrentAffair(null);
      setSeed(Math.random());
   };

   //#endregion

   return (
      <CorporateDataContext.Provider
         value={{
            ...state,
            setCurrentAffair,
            handleChangedSeries,
            setAffairs,
            setSeries,
            createSerie,
            updateAffairSerie,
            deleteAffairSerie,
            updateSeries,
            deleteSerie,
            updateClassification,
            updateMembers,
            updateClassSeriesData,
            handleUpdateLegalRepresentative,
            deleteUnsaveAffair,
            seed,
            setSeed,
         }}
      >
         {children}
      </CorporateDataContext.Provider>
   );
};
