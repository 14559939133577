import { Box, Checkbox, Divider, Stack, TablePagination, Typography } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { UserContext } from "../../context/userContext";
import { Check, PriorityHigh } from "@mui/icons-material";
import { getComparator, stableSort } from "../../const/globalConst";
import { useNavigate } from "react-router-dom";

interface INotification {
   _id: string;
   read: boolean;
}
interface MarkAsReadNotes {
   page: number;
   notList: INotification[];
}
export const NotificationTable = ({ filter }) => {
   const { notifications, user, socket } = useContext(UserContext);
   const date = new Date();
   const [notificationFiltered, setNotificationFiltered] = useState([]);
   const [readMarkMode, setReadMarkMode] = useState(false);
   const [markAsReadNots, setMarkAsReadNots] = useState<MarkAsReadNotes[]>([]);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(5);

   const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      setPage(newPage);
      setReadMarkMode(checkIfNotPageExists(newPage));
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
   };

   const filteredNotifications = useMemo(() => {
      if (filter !== "all") {
         return notifications.filter((notification) => notification.service === filter);
      } else {
         return notifications;
      }
   }, [filter, notifications]);

   useEffect(() => {
      const notificationN = [];
      let validation = false;
      let validation2 = false;

      for (const not of filteredNotifications) {
         const createdDate = new Date(not.createdAt);
         const dif = date.getTime() - createdDate.getTime();
         let diasDeDiferencia = Math.floor(dif / (1000 * 60 * 60 * 24));
         let horasDeDiferencia = Math.floor((dif % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
         let minutosDeDiferencia = Math.floor((dif % (1000 * 60 * 60)) / (1000 * 60));
         const displayDate =
            diasDeDiferencia === 1
               ? `Hace ${diasDeDiferencia} día`
               : diasDeDiferencia > 1
               ? `Hace ${diasDeDiferencia} días`
               : horasDeDiferencia
               ? `Hace ${horasDeDiferencia} horas`
               : `Hace ${minutosDeDiferencia} minutos`;
         const read = not.users?.find((userF) => userF.userId === user.id)?.["read"];
         if (!validation && diasDeDiferencia > 0 && !validation2) {
            validation2 = true;
            notificationN.push({
               ...not,
               time: displayDate,
               read: read,
               validation: true,
            });
         } else {
            notificationN.push({
               ...not,
               time: displayDate,
               read: read,
               validation: false,
            });
         }
      }

      setNotificationFiltered(notificationN);
   }, [filteredNotifications]);

   const visibleRows = useMemo(
      () =>
         stableSort(notificationFiltered, getComparator("desc", "")).slice(
            page * rowsPerPage,
            page * rowsPerPage + rowsPerPage
         ),
      [page, rowsPerPage, notificationFiltered]
   );

   const handleReadNote = (not, isMarked) => {
      const notesArray = markAsReadNots;
      const pagesArray = notesArray.map((array) => array.page);
      const notPageIndex = pagesArray.indexOf(page);
      if (notPageIndex === -1) {
         notesArray.push({ page: page, notList: [not] });
         return setMarkAsReadNots(notesArray);
      }
      if (isMarked) {
         const notesIdArray = notesArray[notPageIndex].notList.map((not) => not._id);
         if (notesIdArray.includes(not._id)) return;
         notesArray[notPageIndex].notList.push(not);
         return setMarkAsReadNots(notesArray);
      }
      const notIndex = notesArray[notPageIndex].notList.findIndex((item) => item._id === not._id);
      if (notIndex !== -1) {
         notesArray[notPageIndex].notList.splice(notIndex, 1);
         setMarkAsReadNots(notesArray);
      }
   };

   function sendNotifications() {
      const notReadNotifications = [];
      for (const notList of markAsReadNots) {
         for (const not of notList.notList) if (!not.read) notReadNotifications.push(not._id);
      }
      socket.emit("mark_as_read", {
         userId: user.id,
         notificationsIds: notReadNotifications,
      });
   }

   function checkIfNotPageExists(page) {
      const notList = markAsReadNots.find((item) => item.page === page);
      return notList ? notList.notList.length === rowsPerPage : false;
   }

   const handleSelectAllChange = (checked) => {
      if (checked) {
         const updatedNotifications = visibleRows.map((not) => ({
            _id: not._id,
            read: not.read,
         }));
         setMarkAsReadNots([{ page: page, notList: updatedNotifications }]);
      } else {
         setMarkAsReadNots(markAsReadNots.filter((notes) => notes.page !== page));
      }
      setReadMarkMode(checked);
   };

   return (
      <Stack sx={{ bgcolor: "white", p: 4 }}>
         <Box width={"100%"} display={"flex"} justifyContent={"flex-end"} px={7}>
            <Typography
               fontWeight={600}
               variant="button"
               sx={{ ":hover": { cursor: "pointer" } }}
               onClick={() => sendNotifications()}
            >
               Marcar como leídas
            </Typography>
         </Box>
         <Stack sx={{ px: 4, py: 2 }}>
            <Stack sx={{ pb: 2 }}>
               {visibleRows.length === 0 ? (
                  <Stack flexDirection={"row"} sx={{ justifyContent: "center", alignItems: "center", py: 2 }}>
                     <Typography>Sin notificaciones</Typography>
                  </Stack>
               ) : (
                  visibleRows.map((not, i) => {
                     if (i === 0 && not.time.includes("día")) {
                        return (
                           <Stack key={not._id}>
                              <Stack direction={"row"} display={"flex"} spacing={1} alignContent={"center"} mx={1}>
                                 <Checkbox
                                    checked={readMarkMode}
                                    onChange={(e) => handleSelectAllChange(e.target.checked)}
                                 />
                                 <Typography alignSelf={"center"}>Seleccionar todo</Typography>
                              </Stack>
                              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mx: 8 }}>
                                 <Box sx={{ flex: 3 }}>
                                    <Divider sx={{ borderWidth: 2 }} />
                                 </Box>
                                 <Box sx={{ flex: 1 }}>
                                    <Typography align="center">Anteriores</Typography>
                                 </Box>
                                 <Box sx={{ flex: 3 }}>
                                    <Divider sx={{ borderWidth: 2 }} />
                                 </Box>
                              </Box>
                              <MapNotification
                                 key={not._id}
                                 not={not}
                                 defaultChecked={readMarkMode}
                                 handleReadNote={handleReadNote}
                                 page={page}
                              />
                           </Stack>
                        );
                     }
                     if (i === 0) {
                        return (
                           <Stack key={not._id}>
                              <Stack direction={"row"} display={"flex"} spacing={1} alignContent={"center"} mx={1}>
                                 <Checkbox
                                    checked={readMarkMode}
                                    onChange={(e) => handleSelectAllChange(e.target.checked)}
                                 />
                                 <Typography alignSelf={"center"}>Seleccionar todo</Typography>
                              </Stack>
                              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mx: 8 }}>
                                 <Box sx={{ flex: 3 }}>
                                    <Divider sx={{ borderWidth: 2 }} />
                                 </Box>
                                 <Box sx={{ flex: 1 }}>
                                    <Typography align="center">Hoy</Typography>
                                 </Box>
                                 <Box sx={{ flex: 3 }}>
                                    <Divider sx={{ borderWidth: 2 }} />
                                 </Box>
                              </Box>
                              <MapNotification
                                 key={not._id}
                                 not={not}
                                 defaultChecked={readMarkMode}
                                 handleReadNote={handleReadNote}
                                 page={page}
                              />
                           </Stack>
                        );
                     }
                     if (not.validation) {
                        return (
                           <Stack key={not._id}>
                              <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", mx: 8, py: 2 }}>
                                 <Box sx={{ flex: 3 }}>
                                    <Divider sx={{ borderWidth: 2 }} />
                                 </Box>
                                 <Box sx={{ flex: 1 }}>
                                    <Typography align="center">Anteriores</Typography>
                                 </Box>
                                 <Box sx={{ flex: 3 }}>
                                    <Divider sx={{ borderWidth: 2 }} />
                                 </Box>
                              </Box>
                              <MapNotification
                                 key={not._id}
                                 not={not}
                                 defaultChecked={readMarkMode}
                                 handleReadNote={handleReadNote}
                                 page={page}
                              />
                           </Stack>
                        );
                     }
                     return (
                        <MapNotification
                           key={not._id}
                           not={not}
                           defaultChecked={readMarkMode}
                           handleReadNote={handleReadNote}
                           page={page}
                        />
                     );
                  })
               )}
            </Stack>
         </Stack>
         <Stack flexDirection={"row"} sx={{ justifyContent: "center", alignItems: "center" }}>
            <TablePagination
               component="div"
               count={notifications.length}
               page={page}
               onPageChange={handleChangePage}
               rowsPerPage={rowsPerPage}
               onRowsPerPageChange={handleChangeRowsPerPage}
               rowsPerPageOptions={[5, 10, 15]}
            />
         </Stack>
      </Stack>
   );
};

const MapNotification = ({ not, handleReadNote, defaultChecked, notMarkedList = [], page }) => {
   const { user, socket } = useContext(UserContext);
   const [enabledMarked, setEnabledMarked] = useState(false);
   const navigate = useNavigate();

   useEffect(() => {
      if (defaultChecked) changeMark(true);
      else {
         const notList = notMarkedList.map((not) => not._id);
         setEnabledMarked(notList.includes(not._id));
      }
   }, [defaultChecked, page]);

   function changeMark(checked) {
      setEnabledMarked(checked);
      handleReadNote({ _id: not._id, read: not.read }, checked);
   }

   return (
      <Stack direction={"row"} spacing={1} alignItems={"center"} sx={{ p: 1 }}>
         <Checkbox checked={enabledMarked} onChange={() => changeMark(!enabledMarked)} />
         <Box
            flex={1}
            sx={{
               bgcolor: not.read ? "#F0F1F2" : "#FBFBFB",
               border: 1,
               display: "flex",
               my: 1,
               borderRadius: 1,
               borderLeftWidth: 4,
               borderColor: "#D8D8D8",
               borderLeftColor: not.read ? "#162c44" : "#D0D0D0",
               alignItems: "center",
               minHeight: 80,
            }}
         >
            <Stack sx={{ flex: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
               {not.read ? (
                  <Check fontSize="large" sx={{ color: "#162c44" }} />
               ) : (
                  <PriorityHigh fontSize="large" sx={{ color: "#D0D0D0" }} />
               )}
            </Stack>
            <Stack sx={{ flex: 4 }}>
               <Typography variant="body2">{not.title}</Typography>
               <Typography variant="caption">{not.description}</Typography>
            </Stack>
            <Box sx={{ flex: 4 }}>
               <Typography variant="body2" align="center">
                  {not.time}
               </Typography>
            </Box>
            <Box sx={{ flex: 4, mr: 4 }}>
               <Typography
                  variant="body2"
                  align="right"
                  sx={{ cursor: "pointer" }}
                  onClick={() => {
                     if (!not.read) {
                        socket.emit("read_notification", {
                           userId: user.id,
                           notificationId: not._id,
                        });
                     }
                     navigate(not.redirectTo);
                  }}
               >
                  Ver mas información
               </Typography>
            </Box>
         </Box>
      </Stack>
   );
};
