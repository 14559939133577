import { useContext } from "react";
import { capitalizeFirstLetter, getDateFromISODate } from "../const/globalConst";
import { changeIncomingComplaint } from "../lib/lecosyBackendClient";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../context/userContext";
import { SnackBarContext } from "../context/snackBarContext";
import { HeadCell, TableComponentFilter } from "../components/TableComponent";
import GetTableComponent from "../hooks/tables/getTableComponent";
import GetTablesFilters from "../hooks/tables/getTablesFilters";
import GetFilteredComplaints from "../hooks/complaints/getFilteredComplaints";
import { ComplaintContext } from "../context/complaintContext";

const status = ["En proceso", "En valoración"];

const columns: HeadCell[] = [
   { field: "reportNumber", headerName: "No. reporte", type: "string" },
   { field: "company", headerName: "Empresa/Sucursal", type: "string" },
   { field: "complaintType", headerName: "Tipo", type: "string" },
   { field: "category", headerName: "Categoría", type: "string", align: "left" },
   { field: "sentDate", headerName: "Fecha de Envío", type: "string" },
   { field: "status", headerName: "Estatus", type: "string" },
];

export const ProcessComplaintsTable = () => {
   const navigate = useNavigate();
   const { loadingComplaints, complaints } = useContext(ComplaintContext);
   const { user } = useContext(UserContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const branchesFilter = GetTablesFilters((complaints as any).get("tableProceso"), "branches");
   const filteredComplaints = GetFilteredComplaints(complaints, "tableProceso");

   const seenComplaint = async (reportNumber: string) => {
      await changeIncomingComplaint(reportNumber);
   };

   const rows =
      !loadingComplaints &&
      filteredComplaints?.map((complaint) => ({
         reportNumber: complaint.complaint.reportNumber,
         complaintType: capitalizeFirstLetter(complaint.complaint.type || "denuncia"),
         complainerType: complaint.complaint.complainerType,
         category: complaint.complaint.category || "N/A",
         updatedAt: getDateFromISODate(complaint.complaint.updatedAt),
         sentDate: getDateFromISODate(complaint.complaint.sentDate || complaint.complaint.createdAt),
         status: capitalizeFirstLetter(complaint.complaint.status),
         company: complaint.branch?.name || complaint.company.person_details.comercialName,
         competence: complaint.complaint.competence,
      }));

   const filters: TableComponentFilter[] = [
      {
         allOption: "Todas las sucursales",
         data: branchesFilter,
         id: "company",
         label: "Empresas/Sucursales",
         type: "select",
      },
      {
         allOption: "",
         data: [],
         id: "reportNumber",
         label: "No. de reporte",
         type: "text",
      },
      {
         allOption: "Todos los estatus",
         data: status,
         id: "status",
         label: "Estatus",
         type: "select",
      },
   ];

   const filtersInitialState = {
      company: "Todas las sucursales",
      reportNumber: "",
      status: "Todos los estatus",
   };

   const tableComponentOnClick = (e, row) => {
      if (row.competence && user.id === row.competence.incompetent) {
         return showSnackBar(`Se te acusa de incompetente para la denuncia ${row.reportNumber}`, true);
      }
      seenComplaint(row.reportNumber);
      return navigate(row.reportNumber);
   };

   return GetTableComponent({
      defaultOrder: "asc",
      defaultText: "No existen denuncias en proceso",
      selectedRow: (e, row) => tableComponentOnClick(e, row),
      tableFilters: filters,
      tableInitialState: filtersInitialState,
      tableHeaders: columns,
      tableLoader: loadingComplaints,
      tableRow: rows,
   });
};
