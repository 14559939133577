import {
   Box,
   CircularProgress,
   FormControl,
   FormHelperText,
   SvgIconTypeMap,
   TextField,
   TextFieldProps,
   Typography,
} from "@mui/material";
import { Users } from "../../../types/BaseTypes";
import React from "react";
import { InputTextField } from "../../Inputs/InputTextField";
import { DatePicker, LocalizationProvider, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useField } from "formik";
import { OverridableComponent } from "@mui/material/OverridableComponent";

interface ICustomInputStyleProps {
   icon?: OverridableComponent<SvgIconTypeMap<{}, "svg">> & { muiName: string };
   iconDesign?: JSX.Element;
   inputProps: TextFieldProps | any;
   label: string;
   options?: Users[];
   isLoadingOptions?: boolean;
   setOpen?: Function;
   type?: string;
   fullWidth?: boolean;
   disabled?: boolean;
   unstyled?: boolean;
}

export const CustomInputStyle = (props: ICustomInputStyleProps) => {
   const [field, meta] = useField(props.inputProps);
   return (
      <React.Fragment>
         <Box sx={{ display: "flex", flexDirection: "column", rowGap: 1, maxWidth: "100%", flex: 1 }}>
            <Typography variant="body2" fontWeight={600}>
               {props.label}
            </Typography>
            <Box
               sx={
                  props.iconDesign
                     ? {
                          display: "flex",
                          borderRadius: 1,
                          border: 0.2,
                          borderColor: "#CBD5E0",
                       }
                     : { display: "flex" }
               }
            >
               {props.iconDesign ? (
                  <Box>
                     <Box
                        sx={{
                           display: "flex",
                           alignItems: "center",
                           justifyContent: "center",
                           borderRight: 1,
                           borderColor: "#CBD5E0",
                           borderRadius: 1,
                           p: 1,
                           px: 2,
                           mr: 1,
                           bgcolor: "#F6F7FB",
                        }}
                     >
                        {props.iconDesign}
                     </Box>
                  </Box>
               ) : null}
               <Box sx={{ flex: 1 }}>
                  {props.type === "date" ? (
                     <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                        <FormControl fullWidth={props.fullWidth}>
                           <DatePicker
                              {...props.inputProps}
                              {...props}
                              label={""}
                              value={meta.value}
                              slots={{ openPickerIcon: props.icon }}
                              error={meta.error && meta.touched ? true : false}
                              slotProps={{
                                 textField: {
                                    size: "small",
                                    error: meta.error && meta.touched ? true : false,
                                 },
                              }}
                           />
                           <FormHelperText error={meta.error && meta.touched ? true : false}>
                              {meta.error && meta.touched ? meta.error : null}
                           </FormHelperText>
                        </FormControl>
                     </LocalizationProvider>
                  ) : props.type === "time" ? (
                     <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                        <FormControl fullWidth={props.fullWidth}>
                           <TimePicker
                              value={meta.value}
                              {...props.inputProps}
                              error={meta.error && meta.touched ? true : false}
                              slots={{ openPickerIcon: props.icon }}
                              slotProps={{
                                 textField: { size: "small", error: meta.error && meta.touched ? true : false },
                              }}
                           />
                           <FormHelperText error={meta.error && meta.touched ? true : false}>
                              {meta.error && meta.touched ? meta.error : null}
                           </FormHelperText>
                        </FormControl>
                     </LocalizationProvider>
                  ) : (
                     <>
                        <InputTextField {...props.inputProps} unstyled={props.unstyled} noError={props.unstyled} />
                     </>
                  )}
               </Box>
            </Box>
            {props.type !== "date" && props.type !== "time" && (
               <FormHelperText error={meta.error && meta.touched ? true : false}>
                  {meta.error && meta.touched ? meta.error : null}
               </FormHelperText>
            )}
         </Box>
      </React.Fragment>
   );
};
