import { useContext, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Box, Button, Grid, Typography, CircularProgress, Divider, Container, IconButton } from "@mui/material";
import { NotificationDocumentsBar } from "../../../../components/notificationsDocumentsBar";
import { createDocumentPdf } from "../../../../lib/uploadDocuments";
import { Form, Formik, FormikProps } from "formik";
import { witnessInterviewSchema } from "../../../../lib/validations/inputSchemas";
import { InputTextField } from "../../../../components/Inputs/InputTextField";
import { InputRadioButton } from "../../../../components/Inputs/InputRadioButton";
import ClearIcon from "@mui/icons-material/Clear";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { ComplaintStepperContext } from "../../../../context/complaintStepperContext";
import pdfMake from "../../../../const/pdfMakeFonts";
import { witnessInterview } from "../../../../lib/documentsTemplates/NewInterviewTemplates";

export const WitnessInterview = () => {
   const { witness } = useParams();
   const { selectedData, selectedWitness } = useContext(ComplaintStepperContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { complaint } = useContext(ComplaintStepperContext);
   const [uploadedLogoBlob, setUploadedLogoBlob] = useState<File | null>();
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const navigate = useNavigate();

   const handleSendForm = async (values, actions) => {
      setIsLoading(true);
      let base64data: any = "";
      const document = selectedWitness
         ? `entrevista-testigo-${complaint.reportNumber}-${selectedData.index}-${selectedWitness.index}`
         : `entrevista-testigo-${complaint.reportNumber}-${witness}`;
      if (values.online === "Si" && !uploadedLogoBlob) {
         return showSnackBar("Favor de subir la imagen de evidencia.", true);
      } else if (values.online === "Si" && uploadedLogoBlob) {
         const reader = new FileReader();
         reader.readAsDataURL(uploadedLogoBlob);
         reader.onloadend = function () {
            base64data = reader.result;
            const dd = witnessInterview(
               false,
               values.witnessName,
               values.worksInPlace,
               values.witnessActivity,
               values.witnessTime,
               complaint.body,
               values.witnessKnowledge,
               values.presentInComplaint,
               values.trueComplaint,
               values.knowsPeople,
               values.witnessPeople,
               values.perceptionVariation,
               values.witnessNarration,
               values.witnessDateActivities,
               values.witnessOfWitness,
               values.witnessAddWitnessNames,
               values.witnessEvidence,
               values.witnessHasEvidence,
               values.witnessAddInfo,
               values.modifyActions,
               values.witnessTruth,
               base64data
            );
            const pdfDocGenerator = pdfMake.createPdf(dd);
            pdfDocGenerator.getBlob(async (blob) => {
               if (complaint.documentation.some((e) => e.name === `${document}.pdf`)) {
                  showSnackBar("Este documento ya fue enviado.", true);
                  setIsLoading(false);
                  setTimeout(() => navigate(-1), 1000);
                  return null;
               }
               await createDocumentPdf(complaint.reportNumber, document, blob);
               showSnackBar("Documento creado y subido correctamente.", false);
               setTimeout(() => navigate(-1), 1000);
               actions.resetForm();
               setIsLoading(false);
            });
            // .open();
         };
      } else {
         const dd = witnessInterview(
            false,
            values.witnessName,
            values.worksInPlace,
            values.witnessActivity,
            values.witnessTime,
            complaint.body,
            values.witnessKnowledge,
            values.presentInComplaint,
            values.trueComplaint,
            values.knowsPeople,
            values.witnessPeople,
            values.perceptionVariation,
            values.witnessNarration,
            values.witnessDateActivities,
            values.witnessOfWitness,
            values.witnessAddWitnessNames,
            values.witnessEvidence,
            values.witnessHasEvidence,
            values.witnessAddInfo,
            values.modifyActions,
            values.witnessTruth,
            ""
         );
         pdfMake.createPdf(dd).open();
      }
      setIsLoading(false);
      setTimeout(() => navigate(-1), 1000);
   };
   const handleOpenEmptyInterview = () => {
      const dd = witnessInterview(
         true,
         "",
         "",
         "",
         "",
         complaint.body,
         "",
         "",
         "",
         "",
         "",
         "",
         "",
         "",
         "",
         "",
         "",
         "",
         "",
         "",
         "",
         ""
      );
      pdfMake.createPdf(dd).open();
      setTimeout(() => navigate(-1), 1000);
   };

   return (
      <Box p={2}>
         <NotificationDocumentsBar head="Entrevista a testigo" isScheduleAppointment={true} />
         <Container maxWidth="xl">
            <Formik
               initialValues={{
                  online: "",
                  witnessName: "",
                  worksInPlace: "",
                  witnessActivity: "",
                  witnessTime: "",
                  witnessKnowledge: "",
                  presentInComplaint: "",
                  trueComplaint: "",
                  knowsPeople: "",
                  witnessPeople: "",
                  witnessNarration: "",
                  perceptionVariation: "",
                  witnessDateActivities: "",
                  witnessAddWitnessNames: "",
                  witnessOfWitness: "",
                  witnessAddInfo: "",
                  modifyActions: "",
                  witnessTruth: "",
                  witnessEvidence: "",
                  witnessHasEvidence: "",
               }}
               validationSchema={witnessInterviewSchema}
               validateOnChange={false}
               onSubmit={handleSendForm}
            >
               {(props: FormikProps<any>) => (
                  <Form>
                     <Box
                        display={"flex"}
                        sx={{ p: 4, minWidth: 950, flexDirection: "column", alignItems: "center", mx: 4 }}
                     >
                        <Box display={"flex"} sx={{ justifyContent: "flex-end", width: "100%" }}>
                           <Button variant="contained" sx={{ mb: 2 }} onClick={() => handleOpenEmptyInterview()}>
                              Descargar formato
                           </Button>
                        </Box>
                        <Box
                           sx={{
                              width: "100%",
                              borderRadius: 2,
                              bgcolor: "#FFFFFF",
                              border: 1,
                              borderColor: "#8A95A0",
                              mb: 2,
                           }}
                        >
                           <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", p: 2 }}>
                              <Typography color={"#2d4357"} fontWeight={700} variant="h5" sx={{ mb: 1 }}>
                                 Evidencia
                              </Typography>
                           </Grid>
                           <Divider sx={{ mx: 2, mb: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    ¿La entrevista es por vía electrónica?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton name="online" id="online" row={true} setfile={setUploadedLogoBlob} />
                              </Grid>
                           </Grid>
                           {props.values.online === "Si" ? (
                              <Box py={2} px={6}>
                                 <Box display="flex" justifyContent="space-evenly" alignItems="center">
                                    <Box>
                                       <Typography>Evidencia</Typography>{" "}
                                       <Typography fontSize={11}>Solo archivos .png y .jpeg</Typography>
                                    </Box>

                                    <Button variant="outlined" component="span" sx={{ p: 1, ml: 1 }}>
                                       <input
                                          accept="image/png, image/jpeg, image/jpg"
                                          id="logo-button-file"
                                          // multiple
                                          // value={uploadedLogoBlob ? uploadedLogoBlob.name : ""}
                                          type="file"
                                          onChange={(e) => {
                                             if (e.target.files) setUploadedLogoBlob(e.target.files[0]);
                                          }}
                                       />
                                    </Button>
                                 </Box>
                                 {uploadedLogoBlob ? (
                                    <Box
                                       alignItems="center"
                                       justifyContent="space-between"
                                       display="flex"
                                       bgcolor="whitesmoke"
                                       m={1}
                                       px={2}
                                       borderRadius={1}
                                    >
                                       <Box
                                          component="img"
                                          src={URL.createObjectURL(uploadedLogoBlob)}
                                          maxHeight={90}
                                          maxWidth={180}
                                          sx={{ alignSelf: "center", borderRadius: 1, m: 1, p: 1 }}
                                       />
                                       <IconButton
                                          onClick={() => {
                                             setUploadedLogoBlob(null);
                                          }}
                                       >
                                          <ClearIcon sx={{ color: "#C60000" }} />
                                       </IconButton>
                                    </Box>
                                 ) : (
                                    <></>
                                 )}
                              </Box>
                           ) : null}
                        </Box>
                        <Box
                           sx={{
                              width: "100%",
                              borderRadius: 2,
                              bgcolor: "#FFFFFF",
                              border: 1,
                              borderColor: "#8A95A0",
                           }}
                        >
                           <Grid item xs={12} sx={{ display: "flex", flexDirection: "column", p: 2 }}>
                              <Typography color={"#2d4357"} fontWeight={700} variant="h5" sx={{ mb: 1 }}>
                                 Formato de entrevista a testigo
                              </Typography>
                              <Typography color={"#2d4357"} fontWeight={500} variant="body1">
                                 La razón de esta entrevista es colaborar con la investigación de los hechos, conforme
                                 un acopio de información de cualquier denuncia existente en el canal de denuncias.
                              </Typography>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    1. ¿Cuál es su nombre completo?
                                 </Typography>
                              </Grid>
                              <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                 <InputTextField
                                    variant="standard"
                                    multiline={true}
                                    size="medium"
                                    name="witnessName"
                                    id="witnessName"
                                    type="text"
                                    fullWidth={true}
                                 />
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1" textAlign={"justify"}>
                                    2. ¿Usted labora en la organización?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton name="worksInPlace" id="worksInPlace" row={true} />
                              </Grid>
                           </Grid>
                           {props.values.worksInPlace === "Si" ? (
                              <>
                                 <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                    <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 8 }}>
                                       <Typography fontWeight={500} variant="body1">
                                          a. ¿Cuál es la actividad que realiza en la organización?
                                       </Typography>
                                    </Grid>
                                    <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                       <InputTextField
                                          variant="standard"
                                          multiline={true}
                                          size="medium"
                                          name="witnessActivity"
                                          id="witnessActivity"
                                          type="text"
                                          fullWidth={true}
                                       />
                                    </Grid>
                                 </Grid>
                                 <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                    <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 8 }}>
                                       <Typography fontWeight={500} variant="body1" width={"500px"}>
                                          b. ¿Cuánto tiempo tiene trabajando en esta organización?
                                       </Typography>
                                    </Grid>
                                    <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                       <InputTextField
                                          variant="standard"
                                          multiline={true}
                                          size="medium"
                                          name="witnessTime"
                                          id="witnessTime"
                                          type="text"
                                          fullWidth={true}
                                       />
                                    </Grid>
                                 </Grid>
                              </>
                           ) : (
                              <></>
                           )}
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                 <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                    Se da lectura a la descripción de la denuncia:
                                 </Typography>
                              </Grid>
                              <Typography fontWeight={500} variant="body1" width={"750px"} textAlign={"justify"}>
                                 {complaint.body}
                              </Typography>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    3. ¿Usted tiene conocimiento de los hechos anteriormente leídos?
                                 </Typography>
                              </Grid>
                              <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                 <InputTextField
                                    variant="standard"
                                    multiline={true}
                                    size="medium"
                                    name="witnessKnowledge"
                                    id="witnessKnowledge"
                                    type="text"
                                    fullWidth={true}
                                 />
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    4. ¿Usted presenció la denuncia que se menciona?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton name="presentInComplaint" id="presentInComplaint" row={true} />
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    5. ¿Reconoce la denuncia narrada como cierta?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton name="trueComplaint" id="trueComplaint" row={true} />
                              </Grid>
                           </Grid>
                           {props.values.trueComplaint === "Si" ? (
                              <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                 <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 8 }}>
                                    <Typography fontWeight={500} variant="body1">
                                       a. ¿Tiene conocimiento del nombre de las personas implicadas en los hechos?
                                    </Typography>
                                 </Grid>
                                 <Grid
                                    item
                                    xs={4}
                                    display={"flex"}
                                    sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                                 >
                                    <InputRadioButton
                                       name="knowsPeople"
                                       id="knowsPeople"
                                       value={props.values.knowsPeople}
                                       onChange={props.handleChange}
                                       row={true}
                                    />
                                 </Grid>
                              </Grid>
                           ) : (
                              <></>
                           )}
                           {props.values.knowsPeople === "Si" && props.values.trueComplaint === "Si" ? (
                              <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                 <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 11 }}>
                                    <Typography fontWeight={500} variant="body1" width={"210px"}>
                                       i. ¿Puede mencionarlos?
                                    </Typography>
                                 </Grid>
                                 <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       size="medium"
                                       name="witnessPeople"
                                       id="witnessPeople"
                                       type="text"
                                       fullWidth={true}
                                    />
                                 </Grid>
                              </Grid>
                           ) : (
                              <></>
                           )}
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    6. ¿Los hechos narrados en la denuncia tienen alguna variación conforme a su
                                    percepción?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton name="perceptionVariation" id="perceptionVariation" row={true} />
                              </Grid>
                           </Grid>
                           {props.values.perceptionVariation === "Si" ? (
                              <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                 <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 8 }}>
                                    <Typography fontWeight={500} variant="body1" width={"210px"}>
                                       a. ¿Puede narrar los hechos?
                                    </Typography>
                                 </Grid>
                                 <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       size="medium"
                                       name="witnessNarration"
                                       id="witnessNarration"
                                       type="text"
                                       fullWidth={true}
                                    />
                                 </Grid>
                              </Grid>
                           ) : (
                              <></>
                           )}
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    7. ¿Qué actividades realizó usted en la fecha que se estima esta denuncia?
                                 </Typography>
                              </Grid>
                              <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                 <InputTextField
                                    variant="standard"
                                    multiline={true}
                                    size="medium"
                                    name="witnessDateActivities"
                                    id="witnessDateActivities"
                                    type="text"
                                    fullWidth={true}
                                 />
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    8. ¿Tiene testigos de las actividades que realizaba ese día?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton name="witnessOfWitness" id="witnessOfWitness" row={true} />
                              </Grid>
                           </Grid>
                           {props.values.witnessOfWitness === "Si" ? (
                              <>
                                 <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                    <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 8 }}>
                                       <Typography fontWeight={500} variant="body1">
                                          a. ¿Puede mencionarlos?
                                       </Typography>
                                    </Grid>
                                    <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                       <InputTextField
                                          variant="standard"
                                          multiline={true}
                                          size="medium"
                                          name="witnessAddWitnessNames"
                                          id="witnessAddWitnessNames"
                                          type="text"
                                          fullWidth={true}
                                       />
                                    </Grid>
                                 </Grid>
                              </>
                           ) : (
                              <></>
                           )}
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    9. ¿Cuenta con alguna evidencia que pruebe lo que menciona en esta entrevista?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton name="witnessEvidence" id="witnessEvidence" row={true} />
                              </Grid>
                           </Grid>
                           {props.values.witnessEvidence === "Si" ? (
                              <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                                 <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 8 }}>
                                    <Typography fontWeight={500} variant="body1">
                                       a. ¿Puede proporcionar tales evidencias?
                                    </Typography>
                                 </Grid>
                                 <Grid
                                    item
                                    xs={4}
                                    display={"flex"}
                                    sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                                 >
                                    <InputRadioButton name="witnessHasEvidence" id="witnessHasEvidence" row={true} />
                                 </Grid>
                              </Grid>
                           ) : (
                              <></>
                           )}
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    10. ¿Tiene alguna información que crea pertinente adicionar a esta entrevista para
                                    la correcta investigación de los hechos?
                                 </Typography>
                              </Grid>
                              <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                 <InputTextField
                                    variant="standard"
                                    multiline={true}
                                    size="medium"
                                    name="witnessAddInfo"
                                    id="witnessAddInfo"
                                    type="text"
                                    fullWidth={true}
                                 />
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={6} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    11. ¿Qué acciones cree que pudieran modificarse para evitar la existencia de este
                                    tipo de denuncias?
                                 </Typography>
                              </Grid>
                              <Grid item xs={6} display={"flex"} sx={dataFieldSX}>
                                 <InputTextField
                                    variant="standard"
                                    multiline={true}
                                    size="medium"
                                    name="modifyActions"
                                    id="modifyActions"
                                    type="text"
                                    fullWidth={true}
                                 />
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container item xs={12} sx={{ display: "flex", justifyContent: "center", p: 2 }}>
                              <Grid item xs={8} display={"flex"} sx={{ alignItems: "center", pl: 5 }}>
                                 <Typography fontWeight={500} variant="body1">
                                    12. ¿Ha respondido todas las preguntas con la verdad?
                                 </Typography>
                              </Grid>
                              <Grid
                                 item
                                 xs={4}
                                 display={"flex"}
                                 sx={{ alignItems: "center", justifyContent: "center", p: 2 }}
                              >
                                 <InputRadioButton name="witnessTruth" id="witnessTruth" row={true} />
                              </Grid>
                           </Grid>
                        </Box>
                        <Box display={"flex"} sx={{ justifyContent: "flex-end", width: "100%" }}>
                           <Button variant="contained" sx={{ mt: 2, width: "50px" }} type="submit">
                              {isLoading ? <CircularProgress size={25} sx={{ color: "white" }} /> : "Enviar"}
                           </Button>
                        </Box>
                     </Box>
                  </Form>
               )}
            </Formik>
         </Container>
      </Box>
   );
};

const dataFieldSX = {
   py: 2,
   pr: 16,
};
