import {
   Box,
   Divider,
   Modal,
   Tab,
   Tabs,
   Typography,
   Button,
   Collapse,
   Card,
   CardContent,
   CardActions,
   IconButton,
   CircularProgress,
} from "@mui/material";
import React, { useContext, useCallback, useMemo } from "react";
import { ComplaintStepperContext } from "../../context/complaintStepperContext";
import EvidenceTable from "./EvidenceTable";
import PersonIcon from "@mui/icons-material/Person";
import DeleteIcon from "@mui/icons-material/Delete";
import { Accordion, AccordionDetails, AccordionSummary } from "../ComplainantAndWitnessDetailsModal";
import { SnackBarContext } from "../../context/snackBarContext";
import { useDropzone } from "react-dropzone";
import { complaintUploadPdfEvidence, getUrlForEvidenceUpload } from "../../lib/usersBEClient";
import UseGetDocumentationList from "../../hooks/complaints/getDocumentList";

interface EvidencesModalProps {
   open: boolean;
   setOpen: Function;
   onConfirm: (event: React.MouseEvent<HTMLButtonElement>) => void;
   onCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const EvidencesModal = (props: EvidencesModalProps) => {
   const { showSnackBar } = useContext(SnackBarContext);
   const { complaint, getComplaint } = useContext(ComplaintStepperContext);
   const [value, setValue] = React.useState(0);
   const [tabExpanded, setTabExpanded] = React.useState({
      tab1: "",
      tab2: "",
      tab3: "",
   });
   const [uploadFile, setUploadFile] = React.useState(false);
   const [selected, setSelected] = React.useState<any>();
   const [evidenceBlob, setEvidenceBlob] = React.useState([]);
   const [evidences, setEvidences] = React.useState([]);
   const [subject, setSubject] = React.useState<string>("");
   const [isLoading, setIsLoading] = React.useState<boolean>(false);
   const [openAcc, setOpenAcc] = React.useState<boolean>(false);
   const documentList = UseGetDocumentationList({
      selected,
      subject,
      isLoading,
      reportNumber: complaint.reportNumber,
      open: openAcc,
   });

   const openEvidence = useMemo(() => {
      return evidenceBlob.length > 0;
   }, [evidenceBlob.length]);

   const handleChangeAccordion =
      (panel: string, type: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
         switch (type) {
            case "Denunciado":
               setTabExpanded({ ...tabExpanded, tab2: isExpanded ? panel : "", tab1: "", tab3: "" });
               break;
            case "Testigo":
               setTabExpanded({ ...tabExpanded, tab3: isExpanded ? panel : "", tab1: "", tab2: "" });
               break;
            default:
               setTabExpanded({ ...tabExpanded, tab1: isExpanded ? panel : "", tab2: "", tab3: "" });
               break;
         }
      };

   const handleChange = (event: React.SyntheticEvent, newValue: number) => {
      setUploadFile(false);
      setValue(newValue);
   };
   const handleUploadFiles = async () => {
      if (evidenceBlob.length === 0) return showSnackBar("Favor de agregar los archivos", true);
      const complaitData = await getUrlForEvidenceUpload(evidences, selected._id, subject, complaint.reportNumber);
      for (let i = 0; i < evidenceBlob.length; i++) {
         await complaintUploadPdfEvidence(complaitData[i], evidenceBlob[i]);
      }
      getComplaint(complaint.reportNumber);
      showSnackBar("La evidencia fue actualizada correctamente", false);
      setUploadFile(false);
      setEvidences([]);
      setEvidenceBlob([]);
      setIsLoading(false);
   };

   const onDrop = useCallback(
      (acceptedFiles) => {
         const files = evidenceBlob;
         const fileNames = evidences;

         acceptedFiles.forEach(async (file: File) => {
            if (file.type.startsWith("video") && file.size > 50000000) {
               showSnackBar("Archivo mayor a 50 MB", true);
               return;
            }
            if (file.type.includes("pdf") && file.size > 5000000) {
               showSnackBar("Archivo mayor a 5 MB", true);
               return;
            }
            if (file.type.includes("mp3") && file.size > 5000000) {
               showSnackBar("Archivo mayor a 50 MB", true);
               return;
            }
            files.push(file);
            fileNames.push(file.name);
         });
         setEvidenceBlob(files);
         setEvidences(fileNames);
      },
      [evidenceBlob, evidences, showSnackBar]
   );

   const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: {
         "image/*": [".png", ".jpeg", ".jpg"],
         "video/*": [".mp4", ".ogg"],
         "application/pdf": [".pdf"],
         "audio/*": [".mp3"],
      },
   });

   const withDeleteHeaders = [
      { field: "file", headerName: "Evidencia" },
      { field: "delete", headerName: "Borrar" },
      { field: "icon", headerName: "Ver" },
   ];

   const noDeleteHeaders = [
      { field: "file", headerName: "Evidencia" },
      { field: "icon", headerName: "Ver" },
   ];

   const handleClose = () => {
      props.setOpen(false);
   };

   function a11yProps(index: number) {
      return {
         id: `vertical-tab-${index}`,
         "aria-controls": `vertical-tabpanel-${index}`,
      };
   }

   const count = {
      Denunciado: complaint.offenderDetails.length,
      Testigo: complaint.witnesses.length,
   };

   const accordionData = (data: any, i: number, type: string, tab: string) => {
      return (
         <Accordion
            key={`${data.name} Involved ${i}`}
            expanded={tabExpanded[tab] === `panel${i}`}
            onChange={handleChangeAccordion(`panel${i}`, type)}
            onClick={() => {
               setSubject(type);
               setSelected(data);
               setOpenAcc(!openAcc);
            }}
            sx={{ borderRadius: 0 }}
         >
            <AccordionSummary aria-controls="panel1bh-content" id="panel1bh-header">
               <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Box sx={{ display: "flex", flex: 1 }}>
                     <PersonIcon sx={{ mr: 1 }} />
                     <Typography fontWeight={600} fontSize={17}>
                        {data.name !== ""
                           ? `${data.name}  ${data.lastName}`
                           : complaint.complaintType === "anonima"
                           ? "Denunciante anónimo"
                           : ` ${type + (i + 1)}`}
                     </Typography>
                  </Box>
               </Box>
            </AccordionSummary>
            <AccordionDetails>
               <Box
                  sx={{
                     minHeight: type === "Denunciante" || type === "anonima" || count[type] < 2 ? 260 : 210,
                     display: "flex",
                     flexDirection: "column",
                  }}
               >
                  {!uploadFile ? (
                     <Box sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                           <Button
                              variant="outlined"
                              onClick={() => {
                                 setUploadFile(true);
                                 setIsLoading(true);
                                 setSelected(data);
                                 setSubject(type);
                                 setOpenAcc(!openAcc);
                              }}
                           >
                              Agregar evidencias +
                           </Button>
                        </Box>
                        <Box
                           sx={{
                              flex: 1,
                              justifyContent: "center",
                              display: "flex",
                              alignItems: "center",
                           }}
                        >
                           {documentList.length > 0 && selected ? (
                              <EvidenceTable
                                 rows={documentList}
                                 headers={
                                    subject === "anonima" || subject === "Denunciante"
                                       ? noDeleteHeaders
                                       : withDeleteHeaders
                                 }
                                 reportNumber={complaint.reportNumber}
                                 subject={subject}
                                 selectedUser={selected._id}
                              />
                           ) : (
                              <Typography>Esta persona no tiene evidencias</Typography>
                           )}
                        </Box>
                     </Box>
                  ) : (
                     uploadFileComp()
                  )}
               </Box>
            </AccordionDetails>
         </Accordion>
      );
   };

   const map = evidenceBlob.map((file, index) => (
      <Card
         key={file.name}
         sx={{
            display: "flex",
            bgcolor: "#F9F9F9",
            flexDirection: "row",
            justifyContent: "space-between",
            mt: 0.5,
            boxShadow: 0,
            border: 1,
            borderColor: "#E2E2E2",
         }}
      >
         <CardContent>
            <Typography sx={{ fontSize: 14, flexWrap: "nowrap" }}>{file.name}</Typography>
         </CardContent>
         <CardActions>
            <IconButton
               onClick={() => {
                  setEvidenceBlob((prev) => prev.filter((item, i) => i !== index));
                  setEvidences((prev) => prev.filter((item, i) => i !== index));
               }}
            >
               <DeleteIcon sx={{ color: "red" }} />
            </IconButton>
         </CardActions>
      </Card>
   ));

   const uploadFileComp = () => {
      return (
         <Box
            sx={{
               display: "flex",
               flexDirection: "column",
               gap: 1,
               flex: 1,
            }}
         >
            <Box>
               <Typography variant="body1" fontWeight={600}>
                  Agregar evidencias al expediente
               </Typography>
            </Box>
            <Box>
               <Typography variant="body2" align="justify">
                  Favor de seleccionar el archivo que desea agregar a las evidencias, y arrastrarlo dentro del recuadro
                  siguiente:
               </Typography>
            </Box>
            <Box
               sx={{
                  flex: 1,
                  border: 1,
                  borderStyle: "dashed",
                  minHeight: 70,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
               }}
               {...getRootProps()}
            >
               <Typography variant="body2" color={"grey"}>
                  Arrastra y suelta dentro del recuadro los archivos
               </Typography>
               <input {...getInputProps()} />
            </Box>
            <Collapse in={openEvidence}>
               <Box sx={{ pb: 1, bx: 1, overflowY: "scroll", maxHeight: 130 }}>{map}</Box>
            </Collapse>
            <Box sx={{ display: "flex", justifyContent: "space-between", pt: 1 }}>
               <Button
                  onClick={() => {
                     setUploadFile(false);
                  }}
                  variant="outlined"
               >
                  Atras
               </Button>
               <Button
                  onClick={() => {
                     handleUploadFiles();
                  }}
                  variant="outlined"
               >
                  {!isLoading ? <CircularProgress /> : "Guardar"}
               </Button>
            </Box>
         </Box>
      );
   };

   return (
      <Modal
         open={props.open}
         onClose={handleClose}
         sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
         <Box sx={{ bgcolor: "white" }}>
            <Box
               sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  p: 2,
               }}
            >
               <Box sx={{ fontWeight: 600, fontSize: 20 }}>Evidencias</Box>
            </Box>
            <Divider />
            <Box sx={{ flexGrow: 1, bgcolor: "background.paper", display: "flex" }}>
               <Tabs
                  orientation="vertical"
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="Vertical tabs example"
                  sx={{ borderRight: 1, borderColor: "divider" }}
               >
                  <Tab label="Denunciante" {...a11yProps(0)} />
                  <Tab label="Denunciados" {...a11yProps(1)} />
                  <Tab label="Testigos" {...a11yProps(2)} />
               </Tabs>
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                     p: 1,
                     gap: 1,
                     minWidth: { mx: 200, md: 700 },
                     minHeight: 360,
                     maxWidth: 700,
                  }}
               >
                  {value === 0 ? (
                     <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
                        {complaint.complaintType !== "no anonima"
                           ? accordionData(
                                { name: "", evidence: complaint.anonimousEvidence },
                                0,
                                "Denunciante",
                                "tab1"
                             )
                           : accordionData(complaint.complainerDetails, 0, "Denunciante", "tab1")}
                     </Box>
                  ) : value === 1 ? (
                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: "column",
                           flex: 1,
                           alignItems: complaint.offenderDetails.length === 0 ? "center" : "none",
                           justifyContent: complaint.offenderDetails.length === 0 ? "center" : "none",
                        }}
                     >
                        {complaint.offenderDetails.length > 0 ? (
                           complaint.offenderDetails.map((offender, i) =>
                              accordionData(offender, i, "Denunciado", "tab2")
                           )
                        ) : (
                           <>
                              <Box
                                 component={"img"}
                                 src="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/ICONOS+SISTEMA_Canal+de+denuncia.svg"
                                 sx={{
                                    maxWidth: 100,
                                    mb: 2,
                                 }}
                              />
                              <Typography variant="h6">No existen denunciados</Typography>
                           </>
                        )}
                     </Box>
                  ) : value === 2 ? (
                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: "column",
                           flex: 1,
                           alignItems: complaint.witnesses.length === 0 ? "center" : "none",
                           justifyContent: complaint.witnesses.length === 0 ? "center" : "none",
                           overflowY: complaint.witnesses.length > 3 ? "scroll" : "hidden",
                           maxHeight: 800,
                        }}
                     >
                        {complaint.witnesses.length > 0 ? (
                           complaint.witnesses.map((witness, i) => accordionData(witness, i, "Testigo", "tab3"))
                        ) : (
                           <>
                              <Box
                                 component={"img"}
                                 src="https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/ICONOS+SISTEMA_Canal+de+denuncia.svg"
                                 sx={{
                                    maxWidth: 100,
                                    mb: 2,
                                 }}
                              />
                              <Typography variant="h6">No existen denunciados</Typography>
                           </>
                        )}
                     </Box>
                  ) : null}
               </Box>
            </Box>
         </Box>
      </Modal>
   );
};
