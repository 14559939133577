import React, { useContext } from "react";
import { CustomModalComponent } from "../CustomModalComponent";
import { Box, Step, StepLabel, Stepper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { DocumentationUpload } from "../Gobierno corporativo/CreateProfile/SubComponent/DocumentationUpload";
import { CreateProfileContext } from "../../context/beneficiaryContext/createProfileContext";
import { NewUserBasicInformationForm } from "../Gobierno corporativo/CreateProfile/SubComponent/NewUserBasicInformationForm";
import { NewUserProfesionalInformation } from "../Gobierno corporativo/CreateProfile/SubComponent/NewUserProfesionalInformation";
import BeneficiaryForm from "../Gobierno corporativo/CreateProfile/SubComponent/BeneficiaryForm";

const CreateProfileBeneficiary = (props: { open: boolean; setOpen: Function }) => {
   const { activeStep, isStepSkipped, steps } = useContext(CreateProfileContext);
   const { open, setOpen } = props;
   const navigate = useNavigate();

   const stepsComponents = [
      <DocumentationUpload />,
      <NewUserBasicInformationForm />,
      <NewUserProfesionalInformation />,
      <BeneficiaryForm isNew />,
   ];

   return (
      <CustomModalComponent
         title="Creación de mi perfil"
         setOpen={setOpen}
         open={open}
         onClose={() => navigate("/inicio")}
      >
         <Box sx={{ width: 700 }}>
            <Stepper activeStep={activeStep} alternativeLabel sx={{ p: 2 }}>
               {steps.map((label, index) => {
                  const stepProps: { completed?: boolean } = isStepSkipped(index) ? { completed: false } : {};
                  return (
                     <Step sx={{ zIndex: 5, bgcolor: "white" }} key={label} {...stepProps}>
                        <StepLabel>{label}</StepLabel>
                     </Step>
                  );
               })}
            </Stepper>
            {stepsComponents[activeStep]}
         </Box>
      </CustomModalComponent>
   );
};

export default CreateProfileBeneficiary;
