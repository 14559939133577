import { Form, Formik } from "formik";
import { InputTextField } from "../Inputs/InputTextField";
import { Box, Button, Grid, IconButton, Stack, SxProps, Theme, Toolbar, Typography, Collapse } from "@mui/material";
import { LoadingButton } from "../LoadingButton";
import { aditionalContactModifySchema } from "../../lib/validations/inputSchemas";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DeleteIcon from "@mui/icons-material/Delete";
import { useContext, useState } from "react";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { Close } from "@mui/icons-material";
import { ConfirmationModal } from "../ConfirmationModal";
import { deleteAdditionalContact } from "../../lib/usersBEClient";
import { SnackBarContext } from "../../context/snackBarContext";

interface PropsAddAditionalsContacts {
   setDrawerOpen: Function;
   open: boolean;
   onConfirm: (values) => void;
   isLoading: boolean;
   isModify?: boolean;
   contactInfo?: any;
}

export const AddAditionalsContacts = (props: PropsAddAditionalsContacts) => {
   const [openUserDeleteCollapse, setOpenUserDeleteCollapse] = useState(false);
   const [openSubUserDeleteConfirmModal, setOpenSubUserDeleteConfirmModal] = useState(false);
   const [isLoadingDelete, setIsLoadingDelete] = useState<boolean>(false);
   const { showSnackBar } = useContext(SnackBarContext);

   function handleOpen() {
      props.setDrawerOpen(!props.open);
   }

   const handleDeleteContact = async () => {
      setIsLoadingDelete(true);
      try {
         await deleteAdditionalContact(props.contactInfo._id);
         showSnackBar("Contacto eliminado con éxito.", false);
         props.setDrawerOpen(!props.open);
         setIsLoadingDelete(false);
      } catch (error) {
         showSnackBar("Error al eliminar contacto.", false);
         setIsLoadingDelete(false);
      }
   };

   return (
      <>
         <Toolbar />

         <Box sx={{ width: 450, display: "flex", maxHeight: "100vh", height: "100%" }} role="presentation">
            <Box
               display="flex"
               alignItems="center"
               onClick={handleOpen}
               sx={{
                  "&:hover": {
                     bgcolor: "#F7F7F7",
                  },
               }}
            >
               <ChevronRightIcon fontSize="small" />
            </Box>
            <Box mt={2} mr={1}>
               <Stack spacing={1}>
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        mt: 1,
                     }}
                  >
                     <Typography variant="h5" sx={{ pl: 1 }}>
                        {props.isModify ? "Modificar contacto adicional" : "Agregar contactos adicionales"}
                     </Typography>
                     {props.isModify ? (
                        <IconButton onClick={() => setOpenUserDeleteCollapse(!openUserDeleteCollapse)}>
                           {!openUserDeleteCollapse ? <MoreVertIcon /> : <Close />}
                        </IconButton>
                     ) : (
                        <></>
                     )}
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                     <Collapse in={openUserDeleteCollapse}>
                        <Button
                           color="error"
                           onClick={() => {
                              setOpenSubUserDeleteConfirmModal(true);
                           }}
                           variant="outlined"
                        >
                           <Typography>Eliminar contacto</Typography>
                           <DeleteIcon />
                        </Button>
                     </Collapse>
                  </Box>
               </Stack>
               <Formik
                  initialValues={{
                     firstName: props.isModify ? props.contactInfo.firstName : "",
                     lastName: props.isModify ? props.contactInfo.lastName : "",
                     email: props.isModify ? props.contactInfo.email : "",
                     phoneNumber: props.isModify ? props.contactInfo.phoneNumber : "",
                  }}
                  onSubmit={props.onConfirm}
                  validationSchema={aditionalContactModifySchema}
               >
                  <Form>
                     <Grid container sx={{ p: 1 }} spacing={1}>
                        <Grid item xs={12} sx={middleRow}>
                           <InputTextField
                              label="Nombre(s)"
                              id="firstName"
                              name="firstName"
                              type="text"
                              fullWidth={true}
                              variant="standard"
                              size={"small"}
                           />
                        </Grid>
                        <Grid item xs={12} sx={middleRow}>
                           <InputTextField
                              label="Apellidos(s)"
                              id="lastName"
                              name="lastName"
                              type="text"
                              fullWidth={true}
                              variant="standard"
                              size={"small"}
                           />
                        </Grid>
                        <Grid item xs={12} sx={middleRow}>
                           <InputTextField
                              label="Correo electrónico"
                              id="email"
                              name="email"
                              type="text"
                              fullWidth={true}
                              variant="standard"
                              size={"small"}
                           />
                        </Grid>
                        <Grid item xs={12} sx={middleRow}>
                           <InputTextField
                              label="Teléfono"
                              id="phoneNumber"
                              name="phoneNumber"
                              type="text"
                              fullWidth={true}
                              variant="standard"
                              size={"small"}
                           />
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 4 }}>
                           <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                              <Button
                                 onClick={() => props.setDrawerOpen(false)}
                                 sx={{
                                    borderColor: "whitesmoke",
                                    border: 1,
                                    width: "100px",
                                    height: "35px",
                                 }}
                              >
                                 Cancelar
                              </Button>
                              <LoadingButton isLoading={props.isLoading} label="Confirmar" />
                           </Box>
                        </Grid>
                     </Grid>
                  </Form>
               </Formik>
            </Box>
         </Box>
         <ConfirmationModal
            open={openSubUserDeleteConfirmModal}
            setOpen={setOpenSubUserDeleteConfirmModal}
            title="Eliminar contactos de la compañía"
            body={
               <>
                  <Typography>¿Estás seguro de eliminar el contacto?</Typography>
                  <Typography variant="body2">En cualquier momento podrá agregar agregar nuevos contactos.</Typography>
               </>
            }
            onConfirm={handleDeleteContact}
            isLoading={isLoadingDelete}
         />
      </>
   );
};
const middleRow: SxProps<Theme> = {
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
};
