import { Box, Card, CircularProgress, Divider, Modal, Typography } from "@mui/material";
import { Scheduler } from "@aldabil/react-scheduler";
import { SchedulerRef } from "@aldabil/react-scheduler/types";
import { useContext, useEffect, useMemo, useRef, useState } from "react";
import { es } from "date-fns/locale";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { EventViewer } from "../SchedulerScreen/SubComponents/EventViewer";
import { EventRenderer } from "./SubComponent/EventRenderer";
import { DoughnutAssignmentChart } from "../../charts/DoughnutAssignmentChart";
import {
   getCommitmentsByManyGovernancedAndUserId,
   getCommitmentsByUserIdAndCompanyId,
   getTasksByUserId,
} from "../../../lib/gobCorpBEClient";
import { UserContext } from "../../../context/userContext";
import { InsightsRender } from "./SubComponent/InsightsRender";
import { IEventsS } from "../SchedulerScreen/SchedulerComponent";
import { getFilteredUsers } from "../../../lib/usersBEClient";
import { useParams } from "react-router-dom";
import { GuidesRender } from "./SubComponent/GuidesRender";
import { getContrastYIQ } from "../../../const/globalConst";
import { GovernanceTheSequelContext } from "../../../context/governanceContext/governanceTheSequelContext";

const handleEvents = (data: any, companySelected) => {
   let events: IEventsS[] = [];
   for (const event of data.event) {
      const date = event.date.split("T")[0];
      events.push({
         start: new Date(date + "T" + event.startTime.split("T")[1]),
         end: new Date(date + "T" + event.finishTime.split("T")[1]),
         date: new Date(event.date),
         description: event.description,
         title: event.title,
         location: event.location,
         files: event.files,
         users: event.users,
         color: event.tags && event.tags.length > 0 ? event.tags[0].color : "#028A0F",
         textColor: getContrastYIQ(event.tags && event.tags.length > 0 ? event.tags[0].color : "#028A0F"),
         type: "event",
         event_id: event.id,
         tags: event.tags,
      });
   }
   for (const task of data.task) {
      const date = task.date.split("T")[0];
      events.push({
         start: new Date(date + "T" + task.startTime.split("T")[1]),
         end: new Date(date + "T" + task.finishTime.split("T")[1]),
         description: task.description,
         title: task.title,
         users: task.users,
         color: "red",
         textColor: getContrastYIQ("ff0000"),
         type: "task",
         event_id: task.id,
         tags: [],
         files: [],
      });
   }
   for (const session of data.session) {
      const date = session.date.split("T")[0];
      events.push({
         start: new Date(date + "T" + session.startTime.split("T")[1]),
         end: new Date(date + "T" + session.finishTime.split("T")[1]),
         date: new Date(session.date),
         order: session.order,
         billCode: session.billCode,
         title: session.title,
         color: session.group ? "#162c44" : companySelected?.company_details?.primaryColor || "#162c44",
         textColor: session.group
            ? "#FFF"
            : getContrastYIQ(companySelected?.company_details?.primaryColor || "#162c44"),
         type: session.type,
         event_id: session._id,
         tags: session.tags,
         files: session.files,
         location: session.location,
         meetingURL: session.meetingURL,
         comments: session.comments,
         usersRegistry: session.usersRegistry,
         externs: session.externs,
         governance: session.governance?._id,
         governanceUsers: session.governance?.users,
         governanceTitle: session.governance?.title,
         provider: session.provider,
         assembly: session.assembly,
         group: session.group,
         completed: session?.group,
      });
   }
   return events;
};

const useGetDashboardData = (page, setLoading, setUsers) => {
   const { allCalendars } = useContext(GovernanceContext);
   const { myGovBody } = useContext(GovernanceTheSequelContext);
   const { user, companySelected } = useContext(UserContext);
   const [filteredCommitments, setFilteredCommitments] = useState([]);
   const [pendingCommitments, setPendingCommitments] = useState<number>(0);
   const [proccesCommitments, setProccesCommitments] = useState<number>(0);
   const [reviewCommitments, setReviewCommitments] = useState<number>(0);
   const [completeCommitments, setCompleteCommitments] = useState<number>(0);
   const [expiredCommitments, setExpiredCommitments] = useState<number>(0);
   const { companyId } = useParams();

   function onlyUnique(value, index, array) {
      return array.indexOf(value) === index;
   }
   useEffect(() => {
      const fetchCommitments = async () => {
         if (companyId === "") return;
         setLoading(true);
         const tasksData = await getTasksByUserId(user.id, companyId);
         //const commitmentData = await getCommitmentsByUserIdAndCompanyId(companyId, user.id);
         const commitmentData = await getCommitmentsByManyGovernancedAndUserId(
            myGovBody.map((g) => g.bodiesData._id),
            user.id
         );
         setFilteredCommitments(tasksData?.concat(commitmentData));
      };
      return () => {
         if (user.id.length > 0 && myGovBody) fetchCommitments();
      };
   }, [user, myGovBody]);

   const filteredEvents = useMemo(() => {
      let allEvents = { event: [], task: [], session: [] };
      allCalendars.forEach((calendar) => {
         allEvents.event = allEvents.event.concat(calendar.event);
         allEvents.task = allEvents.task.concat(calendar.task);
         allEvents.session = allEvents.session.concat(calendar.session);
      });
      return handleEvents(allEvents, companySelected);
   }, [allCalendars, companySelected]);

   useEffect(() => {
      if (filteredCommitments) {
         setPendingCommitments(filteredCommitments.filter((commitment) => commitment.status === "PENDING").length);
         setProccesCommitments(filteredCommitments.filter((commitment) => commitment.status === "PROCESS").length);
         setReviewCommitments(filteredCommitments.filter((commitment) => commitment.status === "REVIEW").length);
         setCompleteCommitments(filteredCommitments.filter((commitment) => commitment.status === "COMPLETE").length);
         setExpiredCommitments(filteredCommitments.filter((commitment) => commitment.status === "EXPIRED").length);
         setLoading(false);
      }
   }, [filteredCommitments]);

   useEffect(() => {
      const fetchInformation = () => {
         setLoading(true);
         const users = [];
         for (const event of filteredEvents) {
            if (event.usersRegistry) users.push(...event.usersRegistry.map((user) => user.user));
            else users.push(...event.users);
         }
         const unique = users.filter(onlyUnique);
         const fetchUsers = async () => {
            const usersF = await getFilteredUsers(unique);
            setUsers(usersF);
         };
         fetchUsers();
         setLoading(false);
      };

      if (filteredEvents.length > 0) fetchInformation();
   }, [filteredEvents]);

   return {
      filteredEvents,
      filteredCommitments,
      pendingCommitments,
      proccesCommitments,
      reviewCommitments,
      completeCommitments,
      expiredCommitments,
   };
};

export const DashboardComponent = ({ page }) => {
   const [loading, setLoading] = useState(true);
   const [users, setUsers] = useState([]);
   const {
      filteredCommitments,
      filteredEvents,
      pendingCommitments,
      proccesCommitments,
      reviewCommitments,
      completeCommitments,
      expiredCommitments,
   } = useGetDashboardData(page, setLoading, setUsers);
   const calendarRef = useRef<SchedulerRef>(null);
   const [open, setOpen] = useState<boolean>(false);
   const [selectedEvent, setSelectedEvent] = useState<IEventsS | null>(null);

   const handleOpen = (event, type) => {
      setOpen(true);
      setSelectedEvent(event);
   };

   const handleClose = () => {
      setOpen(false);
      setSelectedEvent(null);
   };

   const cardStyles = {
      width: 220,
      height: 100,
      display: "flex",
      px: 2.5,
      alignItems: "center",
      justifyContent: "space-between",
      borderRadius: 3,
      border: 1,
      borderColor: "#EEEEEF",
      transform: "box-shadow(0px 0px 30px 0px rgba(233,233,233,1)",
   };

   const DashboardChart = (props: DashboardChartProps) => {
      const { title, dependantValue, colors } = props;

      const chartComponent = useMemo(() => {
         return (
            <Card sx={cardStyles} key={dependantValue}>
               <Box>
                  <Typography fontWeight={800}>{title}</Typography>
                  <Typography color={"#91929E"} fontSize={14} sx={{ textAlign: "center" }}>
                     {filteredCommitments && `${dependantValue}/${filteredCommitments?.length}`}
                  </Typography>
               </Box>
               <Box width={65} height={65} sx={{ pointerEvents: "none" }}>
                  <DoughnutAssignmentChart
                     scores={[
                        filteredCommitments?.length === 0 ? 1 : filteredCommitments?.length - dependantValue,
                        dependantValue,
                     ]}
                     labels={["Total", "Pendientes"]}
                     colors={colors}
                     chartRef={undefined}
                  />
               </Box>
            </Card>
         );
      }, [filteredCommitments?.length, dependantValue]);

      return chartComponent;
   };

   return (
      <>
         <Box
            sx={{
               display: "grid",
               gridTemplateColumns: "repeat(3, 1fr)",
               gridTemplateAreas: `"calendar calendar insights"
               "calendar calendar insights2"
   "footer footer footer"`,
               columnGap: 3,
               rowGap: 2,
            }}
         >
            <Card
               sx={{
                  gridArea: "calendar",
                  px: 5,
                  py: 2,
                  borderRadius: 2,
               }}
            >
               <Typography fontSize={22} fontWeight={700}>
                  Calendario
               </Typography>
               <Divider sx={{ my: 2 }} />
               <Box
                  sx={{
                     overflowY: "auto",
                     maxHeight: 500,
                     "::-webkit-scrollbar": {
                        display: "none",
                     },
                  }}
               >
                  <Scheduler
                     ref={calendarRef}
                     view="week"
                     deletable={false}
                     locale={es}
                     agenda={false}
                     week={{
                        weekDays: [0, 1, 2, 3, 4, 5],
                        startHour: 6,
                        endHour: 24,
                        weekStartOn: 1,
                        step: 60,
                     }}
                     translations={{
                        navigation: {
                           agenda: "Agenda",
                           today: "Hoy",
                           month: "Mes",
                           week: "Semana",
                           day: "Día",
                        },
                        form: {
                           addTitle: "Agregar evento",
                           editTitle: "Editar evento",
                           delete: "Borrar evento",
                           cancel: "Cancelar",
                           confirm: "Confirmar",
                        },
                        event: {
                           title: "Título",
                           start: "Inicio",
                           end: "Fin",
                           allDay: "Todo el día",
                        },
                        moreEvents: "Más...",
                        noDataToDisplay: "Sin datos",
                        loading: "Cargando...",
                     }}
                     fields={[
                        {
                           name: "users",
                           type: "select",
                        },
                     ]}
                     events={filteredEvents}
                     eventRenderer={({ event, ...props }) => {
                        return <EventRenderer event={event} props={props} />;
                     }}
                     customViewer={(event: IEventsS, close) => {
                        return <EventViewer event={{ ...event }} handleOpenModal={handleOpen} users={users} />;
                     }}
                     editable={false}
                  />
               </Box>
            </Card>
            <InsightsRender isUserPanel={true} />
            <GuidesRender isUserPanel={true} />
            <Card
               sx={{
                  gridArea: "footer",
                  borderRadius: 2,
                  px: 5,
                  py: 1,
               }}
            >
               <Typography fontSize={20} fontWeight={800} pt={1}>
                  Acuerdos y compromisos
               </Typography>
               {loading ? (
                  <CircularProgress />
               ) : (
                  <>
                     <Typography color={"#9E9E9E"} fontSize={12} fontWeight={400}>
                        {filteredCommitments && filteredCommitments.length} Encontrados
                     </Typography>
                     <Divider sx={{ mt: 1 }} />
                     <Box display={"flex"} justifyContent={"space-between"} sx={{ py: 2 }}>
                        <DashboardChart
                           title={"Pendientes"}
                           dependantValue={pendingCommitments}
                           colors={["#EEEEEF", "#7D8592"]}
                        />
                        <DashboardChart
                           title={"En proceso"}
                           dependantValue={proccesCommitments}
                           colors={["#EEEEEF", "#3498DB"]}
                        />
                        <DashboardChart
                           title={"En revisión"}
                           dependantValue={reviewCommitments}
                           colors={["#EEEEEF", "#F1B44C"]}
                        />
                        <DashboardChart
                           title={"Finalizadas"}
                           dependantValue={completeCommitments}
                           colors={["#EEEEEF", "#009929"]}
                        />
                        <DashboardChart
                           title={"Vencidas"}
                           dependantValue={expiredCommitments}
                           colors={["#EEEEEF", "#E92C2C"]}
                        />
                     </Box>
                  </>
               )}
            </Card>
         </Box>
         {open && selectedEvent && (
            <Modal
               open={open}
               // setOpen={setOpen}
               onClose={handleClose}
               sx={{ display: "flex" }}
               tabIndex={1}
               // title={selectedEvent.title}
            >
               <Box sx={{ m: "auto", maxWidth: 700 }}>
                  <EventViewer
                     event={{ ...selectedEvent }}
                     users={users}
                     handleOpenModal={handleOpen}
                     handleClose={handleClose}
                     modal
                  />
               </Box>
            </Modal>
         )}
      </>
   );
};

interface DashboardChartProps {
   title: string;
   dependantValue: number;
   colors: string[];
}
