export const FunctionsList = {
   adminCouncilList: [
      "Definir el rumbo estratégico de la Sociedad.",
      "Promover la consideración de los terceros interesados en la toma de sus decisiones.",
      "Elaborar el informe anual de gobierno corporativo y/o cualquier otro para los accionistas.",
      "Supervisar el efectivo funcionamiento y actuación de los Comités que hubiera constituido.",
      "Realizar una evaluación anual de su funcionamiento y del de sus Comités.",
      "Realizar plan de acción para corregir deficiencias detectadas en Comités.",
      "Asegurar que la Sociedad emita su Código de Ética, así como promover la revelación de hechos indebidos y la protección a los informantes.",
      "Aprobar y actualizar periódicamente un plan de acción de aplicación de criterios ASG.",
      "Asegurar la generación de valor económico y social para los accionistas y la permanencia en el tiempo de la sociedad.",
      "Pagar los dividendos que haya decretado la asamblea, en la forma, términos y plazos designados por la misma.",
      "Convocar a asambleas de accionistas.",
      "Presidir las asambleas de accionistas y elaborar las actas de asamblea de accionistas de la Sociedad.",
      "Ejecutar los acuerdos aprobados por la asamblea de accionistas y ejercer las funciones que ésta le haya encomendado.",
      "Dirigir el suministro de información de la Sociedad a los accionistas y los demás interesados, conforme a los criterios de igualdad, transparencia y veracidad.",
      "Informar a la asamblea de accionistas las operaciones de adquisición o enajenación de activos operativos esenciales y todos aquellos asuntos que, conforme a la legislación vigente y el Manual de Gobierno Corporativo de la Sociedad, sean de su competencia.",
      "Llevar el libro de registro de acciones de acuerdo con lo previsto en los Artículos 128 y 129 de la Ley General de Sociedades Mercantiles.",
      "Nombrar al Director General y a los funcionarios de alto nivel de la sociedad, así como evaluar y aprobar su desempeño.",
      "Supervisar la emisión y administración de acciones, incluyendo acciones no suscritas.",
      "Establecer términos y condiciones para la suscripción y pago de acciones no suscritas.",
      "Administrar acciones en tesorería de la sociedad.",
      "Autorizar la suscripción y adquisición de ciertas series de acciones.",
      "Determinar el monto mínimo requerido para la suscripción y adquisición de acciones.",
      "Decidir sobre la colocación de acciones de la empresa.",
      "Autorizar cualquier transferencia o gravamen de acciones por parte de los accionistas.",
      "Evaluar y decidir sobre el ejercicio del derecho de compra en caso de cambio de control respecto de un accionista.",
      "Coordinar el ejercicio del derecho de oferta preferente de los accionistas.",
      "Registrar todas las emisiones y transmisiones de acciones.",
      "Emitir manuales para la celebración de asambleas por medios electrónicos.",
   ],
   auditCouncilList: [
      "Recomendar al Consejo de Administración los candidatos para auditores externos de la sociedad, las condiciones de contratación y el alcance de los trabajos profesionales y supervisar el cumplimiento de los mismos.",
      "Aprobar aquellos servicios adicionales a los de auditoría que vayan a prestar los auditores externos.",
      "Ser el canal de comunicación entre el Consejo de Administración y los auditores externos, así como asegurar su independencia, capacidad profesional y no tener conflictos de interés.",
      "Revisar el programa de trabajo, las cartas de observaciones y los reportes de auditoría interna y externa e informar al Consejo de Administración sobre los resultados.",
      "Dar seguimiento a la remediación de los hallazgos de auditoría interna y externa por las áreas responsables.",
      "Reunirse periódicamente con los auditores internos y externos, sin la presencia de funcionarios de la sociedad, para conocer sus comentarios y observaciones en el avance de su trabajo.",
      "Dar su opinión al Consejo de Administración sobre las políticas y criterios utilizados en la preparación de la información financiera, así como del proceso para su emisión, asegurando su confiabilidad, calidad y transparencia.",
      "Contribuir en la definición de los lineamientos generales del control interno, de la auditoría interna y evaluar su efectividad.",
      "Verificar que se observen los mecanismos establecidos para el control de los riesgos estratégicos a que está sujeta la sociedad.",
      "Coordinar las labores del Auditor Externo, Auditor Interno y el Comisario.",
      "Contribuir en el establecimiento de las políticas para las operaciones con partes relacionadas.",
      "Analizar y evaluar las operaciones con partes relacionadas para recomendar su aprobación al Consejo de Administración.",
      "Decidir la contratación de terceros expertos que emitan su opinión sobre las operaciones con partes relacionadas o algún otro asunto, que le permita el adecuado cumplimiento de sus funciones.",
      "Conocer las denuncias por faltas al Código de Ética, las medidas tomadas y la protección dada a los informantes.",
      "Auxiliar al Consejo de Administración en el análisis de los planes de continuidad del negocio y recuperación de la información en caso de desastres.",
      "Verificar que se cuente con los mecanismos necesarios que permitan asegurar que la sociedad cumple con las diferentes disposiciones legales que le son aplicables.",
   ],
   evaluationCompensationCouncilList: [
      "Sugerir al Consejo de Administración criterios para designar o remover al Director General y a los funcionarios de alto nivel.",
      "Proponer al Consejo de Administración los criterios para la evaluación y compensación del Director General y de los funcionarios de alto nivel.",
      "Recomendar al Consejo de Administración los criterios para determinar los pagos por separación de la sociedad, del Director General y los funcionarios de alto nivel.",
      "Asegurar que el talento y la estructura de la organización estén alineados al plan estratégico aprobado por el Consejo de Administración.",
      "Recomendar los criterios para definir el perfil, así como la contratación, evaluación del desempeño y compensación de los consejeros.",
      "Analizar la propuesta realizada por el Director General acerca de la estructura y criterios para la compensación del personal.",
      "Analizar y presentar al Consejo de Administración para su aprobación, el Código de Ética, así como el sistema de información de hechos indebidos y la protección a los informantes.",
      "Asegurar el adecuado funcionamiento del sistema de información de hechos indebidos y de la protección de los informantes.",
      "Analizar y proponer al Consejo de Administración la aprobación del Sistema Formal de Sucesión del Director General y los funcionarios de alto nivel, así como verificar su cumplimiento.",
      "Asegurar que en la familia de los accionistas exista un acuerdo que defina la forma como serán representados sus intereses en el Consejo de Administración de la sociedad.",
   ],
   planningFinanceCouncilList: [
      "Estudiar y proponer al Consejo de Administración el rumbo estratégico de la sociedad para asegurar su estabilidad y permanencia en el tiempo.",
      "Analizar los lineamientos generales que presente la Dirección General para la determinación del plan estratégico de la sociedad y darle seguimiento a su implementación.",
      "Asegurar que el plan estratégico contemple la generación de valor económico y social para los accionistas, así como las fuentes de empleo y la existencia de los terceros interesados.",
      "Asegurar que el plan estratégico esté alineado con el rumbo a largo plazo establecida por el Consejo de Administración.",
      "Evaluar las políticas de inversión y de financiamiento de la sociedad propuestas por la Dirección General y dar su opinión al Consejo de Administración.",
      "Opinar sobre las premisas del presupuesto anual que presente el Director General y darle seguimiento a su aplicación, así como a su sistema de control.",
   ],
   riskComplianceCouncilList: [
      "Evaluar los mecanismos que presente la Dirección General para la identificación, análisis, administración y control de los riesgos a que esté sujeta la sociedad y dar su opinión al Consejo de Administración.",
      "Analizar los riesgos identificados por la Dirección General.",
      "Definir los riesgos estratégicos a los que dará seguimiento el Consejo de Administración.",
      "Definir los riesgos financieros y de la operación a los que dará seguimiento la Dirección General.",
      "Evaluar los criterios que presente el Director General para la revelación de los riesgos a que está sujeta la sociedad y dar su opinión al Consejo de Administración.",
      "Conocer las disposiciones legales a que está sujeta la sociedad y dar seguimiento estricto a su cumplimiento.",
      "Conocer los asuntos legales pendientes y dar su opinión al Consejo de Administración.",
   ],
};
