import { Box, Grid, Typography } from "@mui/material";
import { BillCommitment } from "./BillCommitment";
import { useContext } from "react";
import { BillCommitmentForm } from "./BillCommitmentForm";
import { GovernanceSessionContext } from "../../../../context/governanceContext/governanceSessionContext";
import { getContrastYIQ } from "../../../../const/globalConst";

export const BillCommitments = () => {
   const { valuesFromBill, hasPermissions, colors, session } = useContext(GovernanceSessionContext);

   const gridWidthConst = hasPermissions ? (session.group ? 11.3 / 4 : 11.3 / 3) : session.group ? 3 : 4;

   return (
      <Box>
         <Grid container spacing={0.2}>
            <Grid item xs={hasPermissions ? 11.3 : 12}>
               <Typography sx={{ textAlign: "center", fontSize: 12, fontWeight: 600, pb: 0.2 }}>
                  ACUERDOS Y/O COMPROMISOS
               </Typography>
            </Grid>
            <Grid item xs={gridWidthConst} sx={{ bgcolor: colors.primary }}>
               <Typography
                  sx={{
                     color: getContrastYIQ(colors.primary),
                     bgcolor: colors.primary,
                     fontSize: 12,
                     textAlign: "center",
                  }}
               >
                  RESPONSABLE
               </Typography>
            </Grid>
            <Grid item xs={gridWidthConst} sx={{ borderLeft: 1, borderColor: "white", bgcolor: colors.primary }}>
               <Typography
                  sx={{
                     color: getContrastYIQ(colors.primary),
                     bgcolor: colors.primary,
                     fontSize: 12,
                     textAlign: "center",
                  }}
               >
                  ACTIVIDAD
               </Typography>
            </Grid>
            {session.group && (
               <Grid
                  item
                  xs={hasPermissions ? 11.3 / 4 : 3}
                  sx={{ bgcolor: colors.primary, borderLeft: 1, borderColor: "white", py: 0.5 }}
               >
                  <Typography
                     sx={{
                        color: getContrastYIQ(colors.primary),
                        bgcolor: colors.primary,
                        fontSize: 12,
                        textAlign: "center",
                     }}
                  >
                     EMPRESA(S)
                  </Typography>
               </Grid>
            )}
            <Grid
               item
               xs={gridWidthConst}
               sx={{ bgcolor: colors.primary, borderLeft: 1, borderColor: "white", py: 0.5 }}
            >
               <Typography
                  sx={{
                     color: getContrastYIQ(colors.primary),
                     bgcolor: colors.primary,
                     fontSize: 12,
                     textAlign: "center",
                  }}
               >
                  FECHA COMPROMISO
               </Typography>
            </Grid>
         </Grid>
         <Grid container spacing={0.2} rowSpacing={0.4}>
            <BillCommitmentForm />
            {valuesFromBill["commitmentsArray"].map((commitment, index) => (
               <BillCommitment commitment={commitment} key={"commitments" + index} index={index} />
            ))}
         </Grid>
      </Box>
   );
};
