import { Box, CircularProgress, Stack, Typography } from "@mui/material";
import { DoughnutChart } from "../../../charts/doughnutChart";
import { useMemo, useRef } from "react";
import { textCenter } from "../../../../const/globalConst";
import { TableGrid } from "./TableGrid";

export const SessionsGraph = ({ sessions, user, isLoading, chartRef }) => {
   const values = useMemo(() => {
      if (sessions.length > 0) {
         let newValues = { value1: 0, percentage1: 0, value2: 0, percentage2: 0 };
         for (const iterator of sessions) {
            if (iterator.usersRegistry) {
               for (const users of iterator.usersRegistry) {
                  if (users.user === user && users.attended) {
                     newValues["value2"] = newValues.value2 + 1;
                  }
                  if (users.user === user && !users.attended) {
                     newValues["value1"] = newValues.value1 + 1;
                  }
               }
            }
         }
         newValues["percentage1"] = Number(
            ((100 * newValues["value1"]) / (newValues["value1"] + newValues["value2"])).toFixed(2)
         );
         newValues["percentage2"] = Number(
            ((100 * newValues["value2"]) / (newValues["value1"] + newValues["value2"])).toFixed(2)
         );
         return newValues;
      } else {
         return {
            value1: 0,
            percentage1: 0,
            value2: 0,
            percentage2: 0,
         };
      }
   }, [sessions]);

   if (isLoading) {
      return (
         <Stack
            sx={{
               flex: 1,
               display: "flex",
               alignItems: "center",
               flexDirection: "column",
               boxShadow: 1,
               borderRadius: 2,
            }}
         >
            <CircularProgress />
         </Stack>
      );
   }

   // if (sessions.length === 0) {
   //    return (
   //       <Stack
   //          sx={{
   //             flex: 1,
   //             display: "flex",
   //             alignItems: "center",
   //             flexDirection: "column",
   //             boxShadow: 2,
   //             borderRadius: 2,
   //          }}
   //       >
   //          <Typography fontWeight={600} sx={{ py: 1 }}>
   //             Asistencia a sesiones
   //          </Typography>
   //          <Box>
   //             <Typography fontWeight={600} sx={{ py: 1 }}>
   //                No existen sesiones registradas.
   //             </Typography>
   //          </Box>
   //       </Stack>
   //    );
   // }

   return (
      <Stack
         sx={{
            flex: 1,
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            boxShadow: 1,
            borderRadius: 2,
         }}
      >
         <Typography fontWeight={600} sx={{ py: 1 }}>
            Asistencia a sesiones
         </Typography>
         <Box sx={{ maxWidth: 200 }}>
            <DoughnutChart
               scores={[values.value1, values.value2]}
               labels={["Ausencias", "Asistencia"]}
               colors={["#8a95a0", "#5C79E3"]}
               chartRef={chartRef}
               cutout={55}
               borderRadius
               textCenterProp={textCenter(
                  {
                     size: "15",
                     text: "Convocatorias",
                  },
                  {
                     size: "22",
                     text: values.value1 + values.value2,
                  }
               )}
               // borderRadius
            />
         </Box>
         <Box sx={{ width: "100%", px: 2, py: 2 }}>
            <TableGrid
               data={[
                  {
                     text: "Ausencia",
                     type: "Dot",
                     color: "#8a95a0",
                  },
                  {
                     text: values.value1,
                  },
                  {
                     text: values.percentage1,
                     type: "%",
                  },
                  {
                     text: "Asistencia",
                     type: "Dot",
                     color: "#5E77F9",
                  },
                  {
                     text: values.value2,
                  },
                  {
                     text: values.percentage2,
                     type: "%",
                  },
               ]}
            />
         </Box>
      </Stack>
   );
};
