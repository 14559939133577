import { useState } from "react";
import { IGovernanceBody } from "../../types/governance.types";
import { ISubMenuOptions } from "./SubMenu";
import { Menu, MenuItem } from "@mui/material";

interface IMenuComponentProps {
   menuOptions: ISubMenuOptions[] | IGovernanceBody[];
   onClick: (section?: string, myaccount?: boolean, id?: string) => void;
   anchorEl: HTMLElement | null;
   handleClose: () => void;
   open: boolean;
   index?: number;
   dual?: boolean;
   secondOptions?: ISubMenuOptions[];
}

const formatName = (name: string): string => {
   return "C." + name.split("comité")[1];
};

const useGetSubComitesOptions = (subComites: any) => {
   let subComitesOptions = [];
   if (subComites) {
      for (const comite of subComites) {
         subComitesOptions.push({
            id: comite.id,
            label: formatName(comite.title.toLowerCase()),
         });
      }
   }
   return { subComitesOptions };
};

export const MenuComponent = (props: IMenuComponentProps) => {
   const { subComitesOptions } = useGetSubComitesOptions(props.dual ? props.menuOptions : null);
   const [selectedComite, setSelectedComite] = useState("");
   const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const handleClick = (event: React.MouseEvent<HTMLDivElement | HTMLLIElement>, id?: string) => {
      setAnchorEl(event.currentTarget);
      setSelectedComite(id);
   };
   const handleClose = () => {
      setAnchorEl(null);
   };

   return (
      <>
         <Menu
            elevation={1}
            anchorEl={props.anchorEl}
            open={props.open}
            onClose={props.handleClose}
            MenuListProps={{
               "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
               vertical: "bottom",
               horizontal: "left",
            }}
            transformOrigin={{
               vertical: "top",
               horizontal: "left",
            }}
         >
            {props.dual
               ? subComitesOptions.map((option) => (
                    <MenuItem key={option.label} onClick={(event) => handleClick(event, option.id)}>
                       {option.label}
                    </MenuItem>
                 ))
               : props.menuOptions.map((option) => (
                    <MenuItem
                       key={option.label}
                       onClick={() => {
                          props.onClick(option.path, props.index === 0);
                          option.action();
                          props.handleClose();
                       }}
                    >
                       {option.label}
                    </MenuItem>
                 ))}
         </Menu>
         <Menu
            id={"menu2"}
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
               "aria-labelledby": "basic-button",
            }}
            anchorOrigin={{
               vertical: "top",
               horizontal: "right",
            }}
            transformOrigin={{
               vertical: "top",
               horizontal: "left",
            }}
         >
            {props.secondOptions.map((option) => (
               <MenuItem
                  key={option.label}
                  onClick={() => {
                     props.onClick(option.path, props.index === 0, selectedComite);
                     option.action();
                  }}
               >
                  {option.label}
               </MenuItem>
            ))}
         </Menu>
      </>
   );
};
