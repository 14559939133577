import { Typography, Box } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import HelpIcon from "@mui/icons-material/Help";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import React, { useCallback, useContext } from "react";
import { ComplaintStepperContext } from "../../context/complaintStepperContext";

interface ComplainantAndWitnessCardsProps {
   data: any;
   replica?: boolean;
   isOffender: boolean;
}

export const ComplainantAndWitnessCards = (props: ComplainantAndWitnessCardsProps) => {
   const {
      complaint,
      setDataState,
      setSelectedData,
      documentation,
      setReplicaSubStep,
      setSelectedWitness,
      selectedData,
   } = useContext(ComplaintStepperContext);

   const absenceInvolved = useCallback(
      (i) =>
         documentation.find((doc) =>
            !props.replica
               ? props.isOffender
                  ? doc.name === `constancia-inexistencia-denunciado-${complaint.reportNumber}-${i + 1}.pdf` ||
                    doc.name === `entrevista-denunciado-${complaint.reportNumber}-${i + 1}.pdf` ||
                    doc.name === `constancia-incomparecencia-denunciado-${complaint.reportNumber}-${i + 1}.pdf`
                  : doc.name === `constancia-incomparecencia-testigo-${complaint.reportNumber}-${i + 1}.pdf` ||
                    doc.name === `entrevista-testigo-${complaint.reportNumber}-${i + 1}.pdf` ||
                    doc.name === `constancia-inexistencia-testigo-${complaint.reportNumber}-${i + 1}.pdf`
               : doc.name === `entrevista-testigo-${complaint.reportNumber}-${selectedData.index}-${i + 1}.pdf` ||
                 doc.name ===
                    `constancia-incomparecencia-testigo-${complaint.reportNumber}-${selectedData.index}-${i + 1}.pdf`
         ),
      [complaint, documentation, selectedData, props.isOffender, props.replica]
   );

   const items = (): any[] => {
      const content: any[] = [];

      props.data.forEach((e, i) => {
         content.push(
            <Box
               sx={{
                  p: 2,
                  m: 1.3,
                  borderRadius: 4,
                  height: "80px",
                  bgcolor: "#2d4357",
                  color: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  "&:hover": {
                     background: "#162c44",
                     cursor: "cell",
                  },
               }}
               onClick={() => {
                  !props.replica ? setDataState(2) : setReplicaSubStep(true);
                  !props.replica
                     ? setSelectedData(
                          e.name
                             ? { e: e, index: i + 1 }
                             : { e: { name: `Testigo ${i + 1}`, _id: e._id }, index: i + 1 }
                       )
                     : setSelectedWitness(
                          e.name
                             ? { e: e, index: i + 1 }
                             : { e: { name: `Testigo ${i + 1}`, _id: e._id }, index: i + 1 }
                       );
               }}
            >
               <Typography fontWeight={400}>
                  {e.name !== ""
                     ? `${e.name}  ${e.lastName}`
                     : props.isOffender
                     ? `Denunciado ${i + 1}`
                     : `Testigo ${i + 1}`}
               </Typography>
               <br />
               <Box sx={{ display: "flex", alignItems: "center" }}>
                  <Typography fontWeight={400}>Documentos obligatorios</Typography>
                  <Tooltip
                     title={
                        <React.Fragment>
                           Se requiere por lo menos uno de los siguientes documentos... <br />-
                           constancia-inexistencia-denunciado <br />- constancia-incomparecencia-denunciado <br />-
                           entrevista-denunciado
                        </React.Fragment>
                     }
                     placement="right-start"
                  >
                     <IconButton aria-label="delete" size="small">
                        <HelpIcon fontSize="small" sx={{ color: "white" }} />
                     </IconButton>
                  </Tooltip>
                  <Typography fontWeight={400} sx={{ ml: 2 }}>
                     {` ${absenceInvolved(i) ? 1 : 0}/${1}`}
                  </Typography>
                  <Box sx={{ p: 2, display: "flex", alignItems: "center" }}>
                     {absenceInvolved(i) ? <CheckCircleOutlineIcon sx={{ ml: 1, color: "#51FF2A" }} /> : null}
                  </Box>
               </Box>
            </Box>
         );
      });
      return content.map((e) => e);
   };

   const rows = items();

   return (
      <>
         {rows.map((e) => {
            return e;
         })}
      </>
   );
};
