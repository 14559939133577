import { sendResolutionNotificationEmail } from "./lecosyBackendClient";
import { complaintUploadPdfEvidence, getUrlForDocumentationUpload } from "./usersBEClient";

export const createDocumentPdf = async (reportNumber: string, documentName: string, blob: Blob) => {
   const updatedComplaint = await getUrlForDocumentationUpload(
      "files-lecosy",
      { folder: `docs/${reportNumber}` },
      documentName,
      reportNumber
   );
   await complaintUploadPdfEvidence(updatedComplaint, blob);
};

export const createDocumentPdfResolution = async (
   reportNumber: string,
   documentName: string,
   blob: Blob,
   complainerEmail: string,
   offenderEmail: string[]
) => {
   const response = await getUrlForDocumentationUpload(
      "files-lecosy",
      { folder: `docs/${reportNumber}` },
      documentName,
      reportNumber
   );
   const res = await complaintUploadPdfEvidence(response, blob);
   if (res.status === 200) {
      await sendResolutionNotificationEmail(reportNumber, complainerEmail, offenderEmail);
   }
};
