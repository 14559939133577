import { useContext, useEffect, useMemo, useState } from "react";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";
import { getUrlS3 } from "../../../../lib/usersBEClient";
import { Box, Stack, Typography } from "@mui/material";
import { GovernanceSessionContext } from "../../../../context/governanceContext/governanceSessionContext";
import { getContrastYIQ } from "../../../../const/globalConst";

const GetBillHeaderData = (finishTime) => {
   const { companyLogo, setCompanyLogo, session } = useContext(GovernanceSessionContext);

   const formattedHourFinish = useMemo(() => {
      if (finishTime === null) return;
      return (
         new Date(finishTime).getHours() +
         ":" +
         (new Date(finishTime).getMinutes() < 10 ? "0" : "") +
         new Date(finishTime).getMinutes()
      );
   }, [finishTime]);

   const fetchLogo = async () => {
      const logoUrl = await getUrlS3(
         "images-lecosy",
         { folder: session.group ? `group/${session.company}` : session.company },
         "logo.png"
      );

      setCompanyLogo(logoUrl);
   };

   useEffect(() => {
      fetchLogo();
   }, []);

   return { companyLogo, formattedHourFinish };
};

export const BillHeader = () => {
   const { session, finishHour, colors, governingBody } = useContext(GovernanceSessionContext);
   const { companyLogo, formattedHourFinish } = GetBillHeaderData(finishHour);
   const formattedDate = new Date(session.startTime).toLocaleDateString();
   return (
      <>
         <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 1 }}>
            <Box sx={{ width: "30%", display: "flex" }}>
               <img
                  defaultValue={companyLogo}
                  src={companyLogo}
                  style={{
                     maxHeight: "60px",
                     alignSelf: "center",
                  }}
               />
            </Box>
            <Typography sx={{ fontSize: 18, fontWeight: "800", textAlign: "center", lineHeight: 1.15 }}>
               {session.assembly
                  ? session.type === "Ordinaria"
                     ? "ASAMBLEA ORDINARIA"
                     : session.type === "Extraordinaria"
                     ? "ASAMBLEA EXTRAORDINARIA"
                     : "SESIÓN DE ACUERDOS"
                  : governingBody?.title.toUpperCase()}
            </Typography>
            <Stack sx={{ display: "flex", width: "25%", gap: 0.5, alignItems: "flex-end" }}>
               <Typography
                  sx={{
                     bgcolor: colors.secondary,
                     borderTopLeftRadius: 5,
                     borderBottomRightRadius: 5,
                     px: 0.5,
                     color: getContrastYIQ(colors.secondary),
                     fontSize: 11,
                     textAlign: "right",
                  }}
               >
                  {session.billCode}
               </Typography>
            </Stack>
         </Box>

         <Stack sx={{ border: 0.5 }}>
            <Box sx={{ display: "flex" }}>
               <Box sx={{ display: "flex", width: "50%", alignItems: "center", gap: 1 }}>
                  <Typography
                     sx={{ textAlign: "right", fontWeight: "800", color: "#434343", fontSize: 12, width: "50%" }}
                  >
                     Fecha:
                  </Typography>
                  <Typography
                     sx={{
                        bgcolor: colors.primary,
                        width: "50%",
                        height: "100%",
                        p: 0.5,
                        color: getContrastYIQ(colors.primary),
                        fontSize: 10,
                        display: "flex",
                        alignItems: "center",
                     }}
                  >
                     {session.startTime.split("T")[0]}
                  </Typography>
               </Box>
               <Box sx={{ display: "flex", width: "50%", alignItems: "center", gap: 1 }}>
                  <Typography
                     sx={{ textAlign: "right", fontWeight: "800", color: "#434343", fontSize: 12, width: "50%" }}
                  >
                     Domicilio social:
                  </Typography>
                  <Box
                     sx={{
                        bgcolor: colors.primary,
                        width: "50%",
                        height: "100%",
                        p: 0.5,
                        color: getContrastYIQ(colors.primary),
                        fontSize: 10,
                     }}
                  >
                     {session.location !== "" ? session.location : "Sin datos"}
                  </Box>
               </Box>
            </Box>
            {
               /*SHAREHOLDER SECOND ROW*/
               session.assembly && (
                  <Box sx={{ display: "flex", borderTop: 0.5, borderColor: "white" }}>
                     <Box sx={{ display: "flex", width: "50%", alignItems: "center", gap: 1 }}>
                        <Typography
                           sx={{ textAlign: "right", fontWeight: "800", color: "#434343", fontSize: 12, width: "50%" }}
                        >
                           Enlace de acceso:
                        </Typography>
                        <Typography
                           sx={{
                              bgcolor: colors.primary,
                              width: "50%",
                              height: "100%",
                              p: 0.5,
                              color: getContrastYIQ(colors.primary),
                              fontSize: 10,
                              wordWrap: "break-word",
                           }}
                        >
                           {session.provider !== "" ? session.provider : "Sin datos"}
                        </Typography>
                     </Box>
                     <Box sx={{ display: "flex", width: "50%", alignItems: "center", gap: 1 }}>
                        <Typography
                           sx={{ textAlign: "right", fontWeight: "800", color: "#434343", fontSize: 12, width: "50%" }}
                        >
                           ID de reunión / Código:
                        </Typography>
                        <Typography
                           sx={{
                              bgcolor: colors.primary,
                              width: "50%",
                              height: "100%",
                              p: 0.5,
                              color: getContrastYIQ(colors.primary),
                              fontSize: 10,
                           }}
                        >
                           {session.meetingURL !== "" ? session.meetingURL : "Sin datos"}
                        </Typography>
                     </Box>
                  </Box>
               )
            }
            {/* SECOND ROW */}
            <Box sx={{ display: "flex", borderTop: 0.5, borderColor: "white" }}>
               <Box sx={{ display: "flex", width: "50%", alignItems: "center", gap: 1 }}>
                  <Typography
                     sx={{ textAlign: "right", fontWeight: "800", color: "#434343", fontSize: 12, width: "50%" }}
                  >
                     Hora Inicial:
                  </Typography>
                  <Typography
                     sx={{
                        bgcolor: colors.primary,
                        width: "50%",
                        height: "100%",
                        p: 0.5,
                        color: getContrastYIQ(colors.primary),
                        fontSize: 10,
                     }}
                  >
                     {session.startTime.split("T")[1]}
                  </Typography>
               </Box>
               <Box sx={{ display: "flex", width: "50%", alignItems: "center", gap: 1 }}>
                  <Typography
                     sx={{ textAlign: "right", fontWeight: "800", color: "#434343", fontSize: 12, width: "50%" }}
                  >
                     Hora Final:
                  </Typography>
                  <Typography
                     sx={{
                        bgcolor: colors.primary,
                        width: "50%",
                        height: "100%",
                        p: 0.5,
                        color: getContrastYIQ(colors.primary),
                        fontSize: 10,
                     }}
                  >
                     {formattedHourFinish ? formattedHourFinish : "[Hora de fin]"}
                  </Typography>
               </Box>
            </Box>
            {
               /*SHAREHOLDER LAST ROW*/
               session.assembly && (
                  <Box sx={{ display: "flex", borderTop: 0.5, borderColor: "white", gap: 0.5 }}>
                     <Typography
                        sx={{ textAlign: "right", fontWeight: "800", color: "#434343", fontSize: 12, width: "25.25%" }}
                     >
                        # de convocatoria
                     </Typography>
                     <Typography
                        sx={{
                           bgcolor: colors.primary,
                           height: "100%",
                           p: 0.5,
                           color: getContrastYIQ(colors.primary),
                           fontSize: 10,
                           width: "74.75%",
                        }}
                     >
                        {session.proclamation}
                     </Typography>
                  </Box>
               )
            }
         </Stack>
      </>
   );
};
