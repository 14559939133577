import { Divider, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { KanbanTaskComponent } from "./KanbanTaskComponent";
import { StrictModeDroppable } from "../../../utils/StrictModeDroppable";

export const ColumnTaskStatus = ({ title, color, tasks, allUsers }) => {
   return (
      <StrictModeDroppable droppableId={title}>
         {(provided) => (
            <Grid {...provided.droppableProps} ref={provided.innerRef} item xs={2.4}>
               <Stack sx={{ alignItems: "center", justifyContent: "center", minWidth: 240 }}>
                  <Typography fontWeight={700}>{title}</Typography>
                  <Divider sx={{ color: color, borderRadius: 2, border: 2, width: "100%" }} />
               </Stack>
               <Stack my={2} spacing={2}>
                  {tasks.map((task, index) => (
                     <KanbanTaskComponent
                        key={`task-${index}`}
                        governingBody={task?.session?.governance?.title || undefined}
                        index={index}
                        allUsers={allUsers}
                        task={task}
                     />
                  ))}
               </Stack>
               {provided.placeholder}
            </Grid>
         )}
      </StrictModeDroppable>
   );
};
