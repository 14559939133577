import { useContext, useMemo } from "react";
import { getDateFromISODate } from "../../const/globalConst";
import { ComplaintContext } from "../../context/complaintContext";
import dayjs from "dayjs";

interface GetComplaintsByBranchProps {
   totalComplaints: any;
   selectedCompany: any;
   startDate: dayjs.Dayjs;
   finishDate: dayjs.Dayjs;
}

export const getCompanyByBranch = (companies, companyId) => {
   const filteredCompany = companies.find((company) => company._id === companyId);
   return filteredCompany;
};

const GetComplaintsByBranch = (props: GetComplaintsByBranchProps) => {
   const { branches } = useContext(ComplaintContext);
   const filteredComplaints = useMemo(() => {
      if (!props.selectedCompany) return props.totalComplaints;
      return props.totalComplaints
         ? props.totalComplaints.filter(
              (e) =>
                 props.selectedCompany?._id === (e.branch?.company._id || e.company._id) &&
                 ((dayjs(new Date(e.complaint.createdAt)) >= props.startDate &&
                    dayjs(new Date(e.complaint.createdAt)) <= props.finishDate) ||
                    getDateFromISODate(props.startDate.toDate()) === getDateFromISODate(props.finishDate.toDate()))
           )
         : [];
   }, [props.totalComplaints, props.startDate, props.finishDate, props.selectedCompany]);

   const complaintsByBranch: any[] = [];
   const filteredBranches =
      branches?.filter((branch) =>
         Array.from(
            new Set(
               filteredComplaints
                  ?.map((complaint) => {
                     if (complaint.branch?._id === undefined) return undefined;
                     return complaint.branch._id;
                  })
                  .filter((v) => v)
            )
         ).includes(branch._id)
      ) || [];
   if (filteredBranches.length > 0) {
      for (const branch of filteredBranches) {
         let count = 0;
         for (const complaint of filteredComplaints) if (branch._id === complaint.branch?._id) count++;
         complaintsByBranch.push({ name: branch, complaints: count });
      }
   }
   return complaintsByBranch;
};

export default GetComplaintsByBranch;
