import * as yup from "yup";
import { expression, longTextValidation, mediumTextValidation, textValidation } from "../inputSchemas";

export const datesSchema = yup.object().shape({
   date: yup.date().typeError("Fecha invalida").required("Fecha invalida"),
   start: yup
      .date()
      .typeError("Hora invalida")
      .required("Hora invalida")
      .test("start", "Hora invalida", (item, testContext) => {
         const now = new Date();
         const date = testContext.parent.date;
         if (now.getDate() + now.getMonth() === date.getDate() + date.getMonth()) {
            return item.getTime() > now.getTime();
         }
         return true;
      }),

   end: yup
      .date()
      .typeError("Hora invalida")
      .required("Hora invalida")
      .test("start", "Hora inicial mayor a final", (item, testContext) => {
         return testContext.parent.start < item;
      }),
});

export const sessionCreateSchema = yup.object().shape({
   title: textValidation,
   date: yup.date().typeError("Fecha invalida").required("Fecha invalida"),
   start: yup
      .date()
      .typeError("Hora invalida")
      .required("Hora invalida")
      .test("start", "Hora invalida", (item, testContext) => {
         const now = new Date();
         const date = testContext.parent.date;
         if (now.getDate() + now.getMonth() === date.getDate() + date.getMonth()) {
            return item.getTime() > now.getTime();
         }
         return true;
      }),
   end: yup
      .date()
      .typeError("Hora invalida")
      .required("Hora invalida")
      .test("start", "Hora inicial mayor a final", (item, testContext) => {
         return testContext.parent.start < item;
      }),
   meetingURL: yup
      .string()
      .min(2, "Se requiere un mínimo de 2 caracteres.")
      .max(150, "Se requiere un máximo de 150 caracteres.")
      .test("provider", "Favor de llenar el lugar o el enlace de acceso", (item, testContext) => {
         const provider = testContext.parent.provider;
         if (provider !== "") {
            return true;
         }
         return false;
      }),
   provider: yup
      .string()
      .min(2, "Se requiere un mínimo de 2 caracteres.")
      .max(150, "Se requiere un máximo de 150 caracteres.")
      .test("meetingURL", "Favor de llenar el lugar o el enlace de acceso", (item, testContext) => {
         const meetingURL = testContext.parent.meetingURL;
         const location = testContext.parent.location;
         if (meetingURL !== "" || location !== "") {
            return true;
         }
         return false;
      }),
   location: yup
      .string()
      .min(2, "Se requiere un mínimo de 2 caracteres.")
      .max(200, "Se requiere un máximo de 200 caracteres.")
      .test("provider", "Favor de llenar el lugar o el enlace de acceso", (item, testContext) => {
         const provider = testContext.parent.provider;
         if (provider !== "") {
            return true;
         }
         return false;
      }),
   assembly: yup.bool(),

   users: yup.array().when("assembly", {
      is: false, // alternatively: (val) => val == true
      then: (schema) => schema.min(1, "Seleccionar minimo 1 usuario"),
      otherwise: (schema) => schema.min(0),
   }),
   // users: yup.object().test("users", "Seleccionar minimo 1 usuario", function (object) {

   //    if (Object.keys(object).length > 0) {
   //       return true;
   //    }
   //    return false;
   // }),
   externs: yup.array().of(
      yup.object({
         email: yup.string().email(),
         phone: yup
            .string()
            .matches(expression.onlyNumbers, "Favor de introducir únicamente números.")
            .min(10, "Se requiere exactamente 10 números.")
            .max(10, "Se requiere exactamente 10 números."),
      })
   ),
   type: yup.string(),
   affairAdded: yup
      .array()
      .test("description", "Favor de rellenar todas las descripciones", (item) => {
         let error = false;
         for (const iterator of item) {
            if (iterator.description && iterator.description !== "") {
               error = true;
            }
            if (!iterator.description) {
               error = false;
            }
         }
         return error;
      })
      .test("companies", "Favor de seleccionar al menos una empresa por asunto", (item) => {
         let error = false;
         for (const iterator of item) {
            if (iterator.companies && iterator.companies.length > 0) {
               error = true;
            }
            if (iterator.companies === null) error = true;
            else if (iterator.companies === undefined || iterator.companies.length === 0) error = false;
         }
         return error;
      })
      .min(1, "Favor de completar el campo")
      .required("Favor de llenar el campo"),
   description: yup.string(),
});

export const eventCreateSchema = yup.object().shape({
   title: textValidation,
   // tags: yup
   //    .array()
   //    .of(
   //       yup.object().shape({
   //          id: yup.string(),
   //          title: yup.string().min(3).max(15),
   //          color: yup.string().min(3).max(15),
   //       })
   //    )
   //    .optional(),
   date: yup.date().typeError("Fecha invalida").required("Fecha invalida"),
   start: yup
      .date()
      .typeError("Hora invalida")
      .required("Hora invalida")
      .test("start", "Hora invalida", (item, testContext) => {
         const now = new Date();
         const date = testContext.parent.date;
         if (now.getDate() + now.getMonth() === date.getDate() + date.getMonth()) {
            return item.getTime() > now.getTime();
         }
         return true;
      }),

   end: yup
      .date()
      .typeError("Hora invalida")
      .required("Hora invalida")
      .test("start", "Hora inicial mayor a final", (item, testContext) => {
         return testContext.parent.start < item;
      }),
   suggest: yup.boolean(),
   // confirm: yup.boolean(),
   // users: yup.array().min(1, "Seleccionar minimo 1 usuario"),
});

export const TaskSchema = yup.object().shape({
   title: textValidation,
   users: yup.array().optional(),
   limitDate: yup.date().typeError("Hora invalida").required("Hora invalida"),
   priority: yup.string().required("Seleccione una prioridad"),
   allowedComments: yup.boolean().optional(),
   description: yup.string().optional(),
   tags: yup.array().optional(),
   files: yup.array().optional(),
});

export const AdviceSchema = yup.object().shape({
   title: textValidation,
   description: yup.string(),
});

export const beneficiaryCreateSchema2 = (filesToValidate, newV) => {
   let baseDoc = {};
   // if (newV) {
   //    baseDoc = {
   //       alias_value: yup
   //          .string()
   //          .min(2, "Se requiere un mínimo de 2 caracteres.")
   //          .max(50, "Se requiere un máximo de 50 caracteres.")
   //          .optional(),
   //       participation: textValidation,
   //       place: textValidation,
   //       relation: textValidation,
   //    };
   // }

   for (const key of filesToValidate) {
      baseDoc[key] = yup.mixed().required("Favor de llenar documento");
   }

   return yup.object().shape(baseDoc);
};
