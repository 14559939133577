import React, { useContext, useEffect, useState } from "react";
import { Box, Typography, IconButton, TextField, Button, Divider } from "@mui/material";
// import { ClientConfigContext } from "../../../context/clientConfigContext";
interface SuggestionProps {
   suggestion: string;
}

const Suggestion = (props: SuggestionProps) => {
   const { suggestion } = props;
   return (
      <Box
         display={"flex"}
         justifyContent={"space-between"}
         maxWidth={"100%"}
         sx={{ borderBottom: 1, borderColor: "lightGray", py: 1.5 }}
      >
         <Typography
            variant="h6"
            sx={{
               pl: 3,
               pr: 1,
               fontWeight: "500",
               display: "flex",
               alignItems: "center",
               fontSize: "16px",
               wordBreak: "break-word",
               whiteSpace: "pre-wrap",
               textAlign: "left",
            }}
            align="center"
            maxWidth={"550px"}
         >
            {/* {suggestion.length > 52 ? suggestion.slice(0, 45) + "..." : suggestion} */}
            {suggestion}
         </Typography>
      </Box>
   );
};

interface SuggestionsModalProps {
   suggestions: string[];
   setSuggestions: Function;
   onClose: Function;
   isOpen: boolean;
}

export const SuggestionsModal: React.FC<SuggestionsModalProps> = ({ suggestions }) => {
   // const { clientInfo } = useContext(ClientConfigContext);

   return (
      <Box
         display={"flex"}
         flexDirection={"column"}
         justifyContent={"space-between"}
         height={"100%"}
         minWidth={"600px"}
      >
         <Box display={"flex"} flexDirection={"column"} justifyContent={"space-between"}>
            <Box
               display={"flex"}
               justifyContent={"space-between"}
               marginTop={4}
               sx={{ borderBottom: 1, borderColor: "lightGray", pb: 1 }}
            >
               <Typography
                  variant="h6"
                  sx={{
                     pl: 3,
                     pr: 1,
                     fontWeight: "600",
                     display: "flex",
                     alignItems: "center",
                     fontSize: "1.1rem",
                  }}
                  align="center"
               >
                  Categorias
               </Typography>
            </Box>
            <Box key={suggestions.length} maxHeight={"410px"} sx={{ overflowY: "auto", overflowX: "hidden" }}>
               {suggestions.map((suggestion, index) => (
                  <Suggestion key={index} suggestion={suggestion} />
               ))}
            </Box>
         </Box>
      </Box>
   );
};
