import React, { useContext, useMemo, useState } from "react";
import { UpdateModal } from "../../components/MiLecosy/ModalsGovernanceModule/updateModal";
import ControllingBeneficiaryModal from "../../components/MiLecosy/ModalsGovernanceModule/ControllingBeneficiaryModal";
import { Box, Button, Collapse, IconButton, Stack, Typography } from "@mui/material";
import { HeadCell, TableComponent } from "../../components/TableComponent";
import { DownloadRounded, Folder } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { CorporateDataContext } from "../../context/governanceContext/corporateDataContext";
import _ from "lodash";
import { FilterComponent } from "../../components/FilterComponent";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { CircularProgressWithLabel } from "../../components/CircularProgressWithLabel";
import { BeneficiaryControllerContext } from "../../context/beneficiaryContext/beneficiaryContollerContext";
import { IBeneficiary } from "../../types/beneficiaryTypes";

const headers: HeadCell[] = [
   { field: "check", headerName: "check", type: "check", width: 50, align: "center", icon: "check" },
   { field: "name", headerName: "Nombre", type: "string", width: 170, align: "left" },
   { field: "rfc", headerName: "RFC", type: "string", width: 170, align: "left" },
   { field: "identifier", headerName: "Identificador", type: "string", width: 170, align: "left" },
   { field: "createdAt", headerName: "Fecha de registro", type: "date", width: 145 },
   { field: "updatedAt", headerName: "Última modificación", type: "date", width: 165 },
   { field: "active", headerName: "Estatus", type: "boolean", width: 70 },
   { field: "file", headerName: "Expediente", type: "string", width: 70 },
];

export const EffectiveControlScreen = () => {
   const { efectiveControllers, refetch, handleDownload } = useContext(BeneficiaryControllerContext);
   const { governanceBody, members, bodies } = useContext(CorporateDataContext);
   const [filteredRows, setFilteredRows] = useState([]);
   const [isControllingBeneficiaryOpen, setControllingBeneficiaryOpen] = useState(false);
   const [isUpdateModal, setUpdateModal] = useState(false);
   const [openDownloadModal, setOpenDownloadModal] = useState(false);
   const [downloadProgress, setDownloadProgress] = useState(0);
   const [selectedRows, setSelectedRows] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const navigate = useNavigate();
   const location = useLocation();

   const handleFolderClick = (user: IBeneficiary) =>
      navigate(`${location.pathname.replace(/[^/]+$/, `expediente/${user?.folderId}`)}`);

   const rows = useMemo(() => {
      if (!efectiveControllers) return [];
      function addCollapsible(item, formattedRows) {
         const searchParent = formattedRows.filter((beneficiary) => beneficiary.moralAssociation === item._id);
         if (searchParent.length === 0) return item;
         searchParent.forEach((element) => {
            if (element.moralAssociation) {
               const value = addCollapsible(element, formattedRows);
               if (value._id === element._id) {
                  item.collapsible = item.collapsible ? [...item.collapsible, value] : [value];
               }
            }
         });
         return item;
      }
      const formattedRows = efectiveControllers.reduce((acc, beneficiary) => {
         const formattedRow = {
            ...beneficiary,
            active: !beneficiary.active,
            name:
               beneficiary.businessName ||
               (beneficiary.user ? `${beneficiary.user.firstName} ${beneficiary.user.lastName}` : beneficiary.name),
            rfc: beneficiary?.rfc || beneficiary?.businessRFC,
            identifier: `${beneficiary.identificationSub ? `${beneficiary.identificationSub}-` : ""}${
               (beneficiary.identification as any).text
            }`,
            nameOg: beneficiary.user ? `${beneficiary.user.firstName} ${beneficiary.user.lastName}` : beneficiary.name,
            file: (
               <IconButton onClick={() => handleFolderClick(beneficiary)}>
                  <Folder style={{ cursor: "pointer" }} sx={{ color: "#F1B44C", fontSize: 27 }} />
               </IconButton>
            ),
         };
         acc.push(formattedRow);
         return acc;
      }, []);

      const newValues = formattedRows
         .filter((element) => !element.moralAssociation)
         .map((element) => addCollapsible(element, formattedRows));
      setFilteredRows(newValues);
      setIsLoading(false);

      return newValues;
   }, [efectiveControllers]);

   return (
      <>
         <Box
            sx={{
               justifyContent: "center",
               display: "flex",
               flexDirection: "column",
               paddingTop: 1,
               paddingBottom: 2,
            }}
         >
            <Stack
               direction="row"
               justifyContent="space-between"
               alignItems="center"
               sx={{
                  columnGap: 2,
               }}
            >
               <Collapse in={selectedRows.length > 0} unmountOnExit>
                  <Button variant="contained" onClick={() => setOpenDownloadModal(true)}>
                     <DownloadRounded />
                  </Button>
               </Collapse>
               <FilterComponent
                  fullWidth
                  originaltableOneRows={rows}
                  tableOneFilteredRows={filteredRows}
                  setTableOneFilteredRows={setFilteredRows}
                  filters={[]}
                  searchBar={
                     <Stack sx={{ flexDirection: "row", gap: 2 }}>
                        <Button
                           variant="contained"
                           sx={{ borderRadius: 1, px: 3, bgcolor: "#8A95A0" }}
                           onClick={() => setUpdateModal(true)}
                        >
                           Solicitar actualización
                        </Button>
                        <Button
                           variant="contained"
                           sx={{ borderRadius: 1, px: 3 }}
                           onClick={() => setControllingBeneficiaryOpen(true)}
                        >
                           Agregar nuevo
                        </Button>
                     </Stack>
                  }
                  disableRefresh
               />
            </Stack>
         </Box>
         <TableComponent
            headerColor
            headers={headers}
            rows={filteredRows}
            defaultColumnToOrder="name"
            defaultOrder="desc"
            defaultRowsPerPage={10}
            emptyDataText={rows.length > 0 ? "No se encontraron beneficiarios" : "No existen beneficiarios registrados"}
            loader={isLoading}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onClick={() => {}}
            selectedRows={selectedRows}
            setSelected={setSelectedRows}
         />

         {isControllingBeneficiaryOpen && !isLoading && (
            <ControllingBeneficiaryModal
               state={isControllingBeneficiaryOpen}
               setState={setControllingBeneficiaryOpen}
               onConfirm={() => {
                  refetch();
                  setControllingBeneficiaryOpen(false);
               }}
               timeStamp={true}
               companyId={governanceBody.company}
               members={_.uniqBy(bodies.map((body) => body.usersData).flat(), (i) => i.user)}
               beneficiaries={rows}
            />
         )}

         {isUpdateModal && !isLoading && (
            <UpdateModal
               state={isUpdateModal}
               setState={setUpdateModal}
               onConfirm={() => {
                  refetch();
                  setUpdateModal(false);
               }}
               companyId={governanceBody?.company}
               members={members}
               beneficiaries={rows}
            />
         )}

         {openDownloadModal && !isLoading && (
            <ConfirmationModal
               open={openDownloadModal}
               onConfirm={() => handleDownload(setOpenDownloadModal, rows, selectedRows, setDownloadProgress)}
               setOpen={setOpenDownloadModal}
               title={"Descargar expediente(s)"}
               isLoading={downloadProgress > 0}
               body={
                  <Stack sx={{ justifyContent: "center", alignItems: "center", rowGap: 1 }}>
                     <Typography>¿Esta seguro que desea descargar el expediente(s)?</Typography>
                     <Typography variant="caption" sx={{ color: "red" }}>
                        Esta acción puede tardar algunos minutos en completarse.
                     </Typography>
                     {downloadProgress > 0 && (
                        <>
                           <Typography>Progreso estimado:</Typography>
                           <CircularProgressWithLabel value={downloadProgress} size={60} />
                        </>
                     )}
                  </Stack>
               }
            />
         )}
      </>
   );
};
