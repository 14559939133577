import { Box, Button, Grid, IconButton, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useMemo } from "react";
import { GovernanceSessionContext } from "../../../../context/governanceContext/governanceSessionContext";
import ListComponent from "../../../ListComponent";
import { IAffairs } from "../../../../types/governance.types";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Form, Formik, FormikProps } from "formik";
import { affairVotesSchema } from "../../../../lib/validations/inputSchemas";
import { InputTextField } from "../../../Inputs/InputTextField";
import { CreateVotes, unlinkAssociatedVoteFromOrder, UpdateVotes } from "../../../../lib/gobCorpBEClient";
import { SnackBarContext } from "../../../../context/snackBarContext";

const AffairsVoteModal = ({ vote, setVote, setVotationOnCourse, setOpenModal }) => {
   const {
      affairsArray,
      socket,
      session,
      membersWithCharge,
      additionalVotes,
      valuesFromBill,
      sessionSeed,
      additionalVotesSeed,
      setSession,
   } = useContext(GovernanceSessionContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { mutate } = CreateVotes();
   const { mutate: CancelVote } = UpdateVotes();

   const createVoteFunction = async (values) => {
      mutate(values, {
         onError: (error: any) => {
            console.error(error);
         },
         onSuccess: (data) => {
            setSession(data.updatedSession);
            socket.emit("send-affair-votation", { session: data.updatedSession, vote: data.createdVote });
            setVotationOnCourse(true);
         },
      });
   };

   useEffect(() => {
      if (!vote) return;
      if (!vote.orderId) {
         const foundVoteIndex = additionalVotes.findIndex((v) => v._id === vote._id);
         setVote(additionalVotes[foundVoteIndex]);
      }
   }, [sessionSeed, additionalVotesSeed]);

   const uniqueArray = {};

   for (const affair of affairsArray) {
      if (!uniqueArray[affair._id ?? affair.title]) uniqueArray[affair._id ?? affair.title] = affair;
   }

   const orderVotationCourse = useMemo(() => {
      if (!vote?.orderId) return null;
      const votationValues = Object.keys(valuesFromBill)
         .filter((key) => key.endsWith(vote.orderId))
         .reduce((obj, key) => {
            obj[key] = valuesFromBill[key];
            return obj;
         }, {});

      const abstentionVotes = Object.keys(votationValues)
         .filter((key) => key.includes("abstentionVote"))
         .reduce((acc, vote) => {
            const sumatory = votationValues[vote] ? 1 : 0;
            return acc + sumatory;
         }, 0);

      const agreedVotation = Object.keys(votationValues)
         .filter((key) => key.includes("vote_user"))
         .reduce((acc: number, vote) => {
            const sumatory = votationValues[vote] ? 1 : 0;
            return acc + sumatory;
         }, 0);

      const disagreedVotation = Object.keys(votationValues)
         .filter((key) => key.includes("vote_user"))
         .reduce((acc: number, vote) => {
            const sumatory = votationValues[vote] === false ? 1 : 0;
            return acc + sumatory;
         }, 0);

      const pendingVotation =
         Object.keys(votationValues).filter((key) => key.includes("vote_user")).length -
         abstentionVotes -
         agreedVotation -
         disagreedVotation;

      return { agreedVotation, disagreedVotation, abstentionVotes, pendingVotation };
   }, [valuesFromBill, vote, sessionSeed]);

   const handleSubmit = (values) => {
      if (!vote) {
         const membersWithVotes = membersWithCharge.filter((member) => {
            return !member.memberCharge.some((charge) => charge.toLowerCase().includes("coordinador"));
         });
         if (values.question !== "") {
            createVoteFunction({
               affair: values.selectedAffair,
               description: values.selectedOrder.description,
               title: values.question,
               session: session._id,
               agreed: 0,
               disagreed: 0,
               nullVote: 0,
               votes: session.usersRegistry
                  .map((user) => {
                     const foundOnlineUser = membersWithVotes.find((voteUser) => voteUser._id === user.user);
                     if (foundOnlineUser)
                        return {
                           userId: foundOnlineUser._id,
                           userName: `${foundOnlineUser.firstName} ${foundOnlineUser.lastName}`,
                           vote: "pending",
                        };
                  })
                  .filter((v) => v),
            });
         } else {
            socket.emit("send-affair-votation", { session: session, order: values.selectedOrder });
            setVotationOnCourse(true);
         }
      } else {
         setOpenModal(false);
         setVote(null);
         setVotationOnCourse(false);
         socket.emit("end-vote", session._id);
      }
   };
   const handleCancelVotation = () => {
      if (!vote) {
         return setOpenModal(false);
      }
      CancelVote(
         { votationId: vote._id, voteData: { canceled: true } },
         {
            onError: (error) => {
               console.error(error);
            },
            onSuccess: async () => {
               const updatedData = await unlinkAssociatedVoteFromOrder(session._id, vote._id);
               const { updatedSession, updatedVote } = updatedData;
               const tempVotes = additionalVotes;
               const voteIndex = additionalVotes.findIndex((v) => v._id === updatedVote._id);
               if (voteIndex !== -1) {
                  tempVotes[voteIndex].canceled = true;
                  delete tempVotes[voteIndex].affair;
               }
               return socket.emit("cancel-affair-vote", updatedSession, tempVotes);
            },
         }
      );
   };

   return (
      <Formik
         initialValues={{ question: "", selectedAffair: null, selectedOrder: null }}
         validationSchema={affairVotesSchema}
         onSubmit={handleSubmit}
      >
         {(formProps: FormikProps<any>) => (
            <Form>
               {vote ? (
                  <Stack spacing={1.5}>
                     <Stack spacing={1}>
                        <Typography>Título de la pregunta</Typography>
                        <Typography>{vote.orderId ? vote.description : vote.title}</Typography>
                     </Stack>
                     <Stack spacing={1}>
                        <Typography>Respuestas:</Typography>
                        <Stack spacing={0.5}>
                           <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                              <Typography>De acuerdo: </Typography>
                              <Typography>
                                 {vote?.orderId ? orderVotationCourse.agreedVotation : vote?.agreed || 0}
                              </Typography>
                           </Box>
                           <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                              <Typography>En desacuerdo: </Typography>
                              <Typography>
                                 {vote?.orderId ? orderVotationCourse.disagreedVotation : vote?.disagreed || 0}
                              </Typography>
                           </Box>
                           <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                              <Typography>Se abstuvo de votar: </Typography>
                              <Typography>
                                 {vote?.orderId ? orderVotationCourse.abstentionVotes : vote?.nullVote || 0}
                              </Typography>
                           </Box>
                           <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                              <Typography sx={{ fontWeight: 600 }}>Votos faltantes: </Typography>
                              <Typography sx={{ fontWeight: 600 }}>
                                 {vote
                                    ? vote.orderId
                                       ? orderVotationCourse.pendingVotation
                                       : vote.votes?.length - (vote.agreed + vote.disagreed + vote.nullVote)
                                    : 0}
                              </Typography>
                           </Box>
                        </Stack>
                     </Stack>
                     <Box sx={{ display: "flex", justifyContent: "space-around" }}>
                        <Button
                           type="submit"
                           variant="contained"
                           sx={{ bgcolor: "#2D4357", color: "white", width: "33%" }}
                        >
                           Terminar
                        </Button>
                        <Button
                           type="button"
                           onClick={handleCancelVotation}
                           sx={{
                              bgcolor: "#E1E1E1",
                              border: 0.65,
                              borderColor: "#EFEFEF",
                              width: "33%",
                              color: "black",
                           }}
                        >
                           Cancelar
                        </Button>
                     </Box>
                  </Stack>
               ) : (
                  <Stack direction={"column"} spacing={2}>
                     <ListComponent
                        headers={[
                           {
                              headerTitle: "Selecciona un asunto",
                              type: "text",
                              bodyPosition: "left",
                              headerPosition: "left",
                           },
                        ]}
                        rows={Object.keys(uniqueArray).map((affair) => {
                           return {
                              title: uniqueArray[affair].title,
                           };
                        })}
                        rowEndAndornment={(row) => {
                           const foundAffair: any = Object.values(uniqueArray).find(
                              (affair: IAffairs) => affair.title === row.title
                           );
                           const descriptionCount = affairsArray.filter((localAffair) => {
                              if (session.assembly) return localAffair._id === foundAffair._id;
                              return localAffair.title === foundAffair.title;
                           }).length;
                           return (
                              <Stack direction={"row"} spacing={1}>
                                 {descriptionCount > 1 && (
                                    <Typography sx={{ bgcolor: "#162c44", color: "white", borderRadius: 5, px: 1 }}>
                                       {
                                          affairsArray.filter((localAffair) => {
                                             if (session.assembly) return localAffair._id === foundAffair._id;
                                             return localAffair.title === foundAffair.title;
                                          }).length
                                       }
                                    </Typography>
                                 )}
                                 {formProps.values.selectedAffair &&
                                    formProps.values.selectedAffair ===
                                       (session.assembly ? foundAffair._id : foundAffair.title) && (
                                       <IconButton
                                          onClick={(e) => {
                                             e.stopPropagation();
                                             formProps.values.selectedAffair = null;
                                             formProps.values.selectedOrder = null;
                                          }}
                                          sx={{ p: 0 }}
                                       >
                                          <DeleteForeverIcon color="error" />
                                       </IconButton>
                                    )}
                              </Stack>
                           );
                        }}
                        headerProps={{
                           pb: 1,
                           px: 2,
                           borderRadius: 1,
                        }}
                        headerTextProps={{
                           fontSize: "16px",
                           fontWeight: 600,
                        }}
                        rowProps={function (row) {
                           const foundAffair: any = Object.values(uniqueArray).find(
                              (affair: IAffairs) => affair.title === row.title
                           );
                           return {
                              display:
                                 formProps.values.selectedAffair &&
                                 (session.assembly
                                    ? formProps.values.selectedAffair !== foundAffair._id
                                    : formProps.values.selectedAffair !== foundAffair.title)
                                    ? "none"
                                    : "auto",
                              bgcolor: (
                                 session.assembly
                                    ? formProps.values.selectedAffair === foundAffair._id
                                    : formProps.values.selectedAffair === foundAffair.title
                              )
                                 ? "#D9D9D9"
                                 : "#EFEFEF",
                              py: 1,
                              px: 2,
                              mb: 1,
                           };
                        }}
                        onClick={(_e, row) => {
                           formProps.values.selectedOrder = null;
                           const clickedAffair: any = Object.values(uniqueArray).find(
                              (affair: IAffairs) => affair.title === row.title
                           );
                           const ordersArray = affairsArray.filter((affair) =>
                              session.assembly ? affair._id === clickedAffair._id : affair.title === clickedAffair.title
                           );
                           const foundOrder = affairsArray.find((affair) =>
                              session.assembly ? affair._id === clickedAffair._id : affair.title === clickedAffair.title
                           );
                           if (ordersArray.length < 2) {
                              if ((foundOrder as any).vote)
                                 return showSnackBar("La orden seleccionada ya cuenta con una pregunta asociada", true);
                              formProps.values.selectedOrder = affairsArray.find((affair) =>
                                 session.assembly
                                    ? affair._id === clickedAffair._id
                                    : affair.title === clickedAffair.title
                              );
                           }
                           formProps.values.selectedAffair = session.assembly ? clickedAffair._id : clickedAffair.title;
                        }}
                     />
                     {!formProps.values.selectedOrder ? (
                        affairsArray.filter((affair) =>
                           session.assembly
                              ? affair._id === formProps.values.selectedAffair
                              : affair.title === formProps.values.selectedAffair
                        ).length > 0 && (
                           <ListComponent
                              headers={[
                                 {
                                    headerTitle: "Selecciona una orden del día",
                                    type: "text",
                                    bodyPosition: "left",
                                    headerPosition: "left",
                                 },
                              ]}
                              rows={affairsArray
                                 .filter((affair) =>
                                    session.assembly
                                       ? affair._id === formProps.values.selectedAffair
                                       : affair.title === formProps.values.selectedAffair
                                 )
                                 .map((affair) => {
                                    return { description: affair.description };
                                 })}
                              headerProps={{
                                 pb: 1,
                                 px: 2,
                                 borderRadius: 1,
                              }}
                              headerTextProps={{
                                 fontSize: "16px",
                                 fontWeight: 600,
                              }}
                              rowProps={function () {
                                 return {
                                    bgcolor: "#F5F5F5",
                                    py: 1,
                                    px: 2,
                                    mb: 1,
                                 };
                              }}
                              onClick={(_e, row) => {
                                 const foundOrder = affairsArray.find(
                                    (array) =>
                                       (session.assembly ? array._id : array.title) ===
                                          formProps.values.selectedAffair && row.description === array.description
                                 );
                                 if ((foundOrder as any).vote)
                                    return showSnackBar(
                                       "La orden seleccionada ya cuenta con una pregunta asociada",
                                       true
                                    );
                                 formProps.values.selectedOrder = foundOrder;
                              }}
                           />
                        )
                     ) : (
                        <Box>
                           <Grid container sx={{ alignItems: "center" }}>
                              <Grid item xs={11} bgcolor={"#EFEFEF"} sx={{ px: 2, py: 1, alignContent: "center" }}>
                                 <Typography fontSize={"14px"}>{formProps.values.selectedOrder.description}</Typography>
                              </Grid>
                              <Grid item xs={1}>
                                 <IconButton onClick={() => (formProps.values.selectedOrder = null)}>
                                    <DeleteForeverIcon color="error" />
                                 </IconButton>
                              </Grid>
                           </Grid>
                        </Box>
                     )}
                     {formProps.values.selectedOrder && (
                        <InputTextField
                           id="question"
                           name="question"
                           type="text"
                           size={"small"}
                           placeholder="Agregar pregunta"
                           fullWidth
                           multiline
                           maxRows={3}
                        />
                     )}
                     <Box display={"flex"} justifyContent={"center"}>
                        <Button
                           sx={formProps.values.selectedOrder ? shareButton : undefined}
                           disabled={!formProps.values.selectedOrder}
                           type="submit"
                        >
                           <Typography>Compartir</Typography>
                        </Button>
                     </Box>
                  </Stack>
               )}
            </Form>
         )}
      </Formik>
   );
};

const shareButton = {
   bgcolor: "#162c44",
   ":hover": { bgcolor: "#162c44" },
   color: "white",
   px: 5,
};

export default AffairsVoteModal;
