import { Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { getDateFromISODate } from "../../../const/globalConst";

export const BeneficiarySheetTemplate = ({ structure, data1, data2, logo, society }) => {
   const handleValues = (fields, valuesFromBd, valuesFormBd2?) => {
      const newList = [];
      fields.forEach((element) => {
         let fields = [];
         element.fields.forEach((field) => {
            if (field.name.includes(".")) {
               const split = field.name.split(".");
               if (field.depends) {
                  const splitDepend = field.depends.split(".");
                  if (field.if && field.if.includes(valuesFromBd?.[splitDepend[0]]?.[splitDepend[1]])) {
                     fields.push({ ...field, value: valuesFromBd?.[split[0]]?.[split[1]] });
                  }
               } else {
                  fields.push({ ...field, value: valuesFromBd?.[split[0]]?.[split[1]] });
               }
            } else {
               if (field.depends) {
                  const splitDepend = field.depends.split(".");
                  if (field.if && field.if.includes(valuesFromBd?.[splitDepend[0]]?.[splitDepend[1]])) {
                     fields.push({
                        ...field,
                        value: valuesFromBd[field.name] ? valuesFromBd[field.name] : valuesFormBd2[field.name],
                     });
                  }
               } else {
                  fields.push({
                     ...field,
                     value: valuesFormBd2[field.name],
                  });
               }
            }
         });
         newList.push({
            ...element,
            fields: fields,
         });
         const newFields = [];
         if (element.subSection) {
            element.subSection.subFields.forEach((field) => {
               if (field.name.includes(".")) {
                  const split = field.name.split(".");
                  newFields.push({ ...field, value: valuesFromBd?.[split[0]]?.[split[1]] });
               } else {
                  newFields.push({
                     ...field,
                     value: valuesFormBd2[field.name],
                  });
               }
            });
            if (element.subSection.if) {
               const split = element.subSection.subSectionDepends.split(".");
               if (element.subSection.if.includes(valuesFromBd?.[split[0]]?.[split[1]])) {
                  newList.push({
                     section: element.subSection.title.toUpperCase(),
                     fields: newFields,
                  });
               } else if (element.subSection.title === "Domicilio fiscal") {
                  newList.push({
                     section: element.subSection.title.toUpperCase(),
                     fields: fields,
                  });
               }
            }
         }
      });
      return newList;
   };
   const values = handleValues(structure, data1, data2);
   return (
      <Document>
         <Page style={styles.body}>
            <Image style={styles.logo} fixed src={logo} />
            <Text style={styles.title}>FICHA DE BENEFICIARIO CONTROLADOR</Text>
            <Text style={{ fontSize: 11, textAlign: "center" }}>{society.toUpperCase()}</Text>
            {values.map((section) => {
               return (
                  <View>
                     <Text style={styles.subtitle}>{section.section}</Text>
                     {section.fields
                        .filter((field) => field.type !== "file")
                        .map((field) => {
                           let value2 = "";
                           if (field.depends && field.depends.includes(".")) {
                              const split = field.depends.split(".");
                              value2 = data1[split[0]][split[1]];
                           }
                           return (
                              <View>
                                 <View style={{ flexDirection: "row" }}>
                                    <View
                                       style={{
                                          flex: 2,
                                          justifyContent: "center",
                                       }}
                                    >
                                       <Text style={styles.textLine}>
                                          {value2 && !field.if
                                             ? field.titles
                                                ? field.titles[value2]
                                                : field.title
                                             : field.title}
                                          :
                                       </Text>
                                    </View>
                                    <View
                                       style={{
                                          flex: 4,
                                          justifyContent: "center",
                                          borderLeft: "1px solid #000000",
                                       }}
                                    >
                                       {field.type === "special" ? (
                                          Actions(field.value)
                                       ) : (
                                          <Text style={{ fontSize: 11, marginHorizontal: 5 }}>
                                             {field.type === "date" ? getDateFromISODate(field.value) : field.value}
                                          </Text>
                                       )}
                                    </View>
                                 </View>
                                 <Text
                                    style={{
                                       fontSize: "10",
                                       textAlign: "center",
                                       marginTop: "2",
                                       borderBottom: "0.5px",
                                       marginBottom: 5,
                                    }}
                                 ></Text>
                              </View>
                           );
                        })}
                  </View>
               );
            })}
         </Page>
      </Document>
   );
};

const Actions = (actions) => {
   return actions.map((action) => (
      <View style={{ flexDirection: "row" }}>
         <View style={styles.actions}>
            <Text style={{ fontSize: 11 }}>{action.title}</Text>
         </View>
         <View style={{ flex: 4, flexDirection: "row" }}>
            <View style={styles.actions}>
               <Text style={{ fontSize: 11 }}>Votos</Text>
            </View>
            <View style={styles.actions}>
               <Text style={{ fontSize: 11 }}>{action?.nominalValue || ""}</Text>
            </View>
            <View style={styles.actions}>
               <Text style={{ fontSize: 11 }}>Total de acciones</Text>
            </View>
            <View style={styles.actions}>
               <Text style={{ fontSize: 11 }}>{action?.totalActions || ""}</Text>
            </View>
         </View>
      </View>
   ));
};

Font.register({
   family: "open-sans",
   src: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-Regular.ttf",
});

Font.register({
   family: "open-sans-bold",
   src: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-Bold.ttf",
});

const styles = StyleSheet.create({
   body: {
      paddingTop: 80,
      paddingBottom: 80,
      paddingHorizontal: 65,
      fontFamily: "open-sans",
   },
   actions: {
      flex: 1,
      borderRight: "1px solid #000000",
      alignItems: "center",
      justifyContent: "center",
      padding: 4,
   },
   logo: {
      height: 40,
      width: "auto",
      position: "absolute",
      top: 25,
      left: 55,
   },
   textLine: {
      fontSize: 11,
      marginHorizontal: 5,
      fontFamily: "open-sans-bold",
      fontWeight: "bold",
      padding: 5,
   },
   title: {
      fontSize: 12,
      fontFamily: "open-sans-bold",
      fontWeight: "bold",
      textAlign: "center",
   },
   subtitle: {
      fontSize: 11,
      fontFamily: "open-sans-bold",
      fontWeight: "bold",
      textAlign: "center",
      color: "#73808c",
      paddingVertical: 15,
   },
});
