import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { useState, useContext } from "react";
import { formatName } from "../../../screens/Gobierno corporativo/SchedulerScreen";
import { Circle } from "@mui/icons-material";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";

interface CheckBoxComponentProps {
   data: string;
   tags?: string;
   id: string;
   notes?: boolean;
   checked?: boolean;
}

export const CheckBoxComponent = (props: CheckBoxComponentProps) => {
   const { getSpecificCalendar } = useContext(GovernanceContext);

   const { tagsFilterNotes, setTagsFilterNotes } = useContext(GovernanceContext);

   function getSpecificNote(id: string) {
      if (tagsFilterNotes.includes(id)) {
         setTagsFilterNotes((prevTags) => prevTags.filter((tag) => tag !== id));
      } else {
         const newTags = [...tagsFilterNotes, id];
         setTagsFilterNotes(newTags);
      }
      return;
   }

   // const { getSpecificNote } = useFetchData();
   const [checked, setChecked] = useState(props.checked || false);

   const handleFilter1 = () => {
      setChecked(!checked);
      getSpecificCalendar(props.id);
   };

   const handleFilter2 = () => {
      setChecked(!checked);
      if (!props.notes) {
         getSpecificCalendar(props.id, true);
      } else {
         getSpecificNote(props.id);
      }
   };

   return (
      <>
         <FormControlLabel
            control={
               <Checkbox
                  checked={checked}
                  size="small"
                  onChange={() => (props.tags ? handleFilter2() : handleFilter1())}
               />
            }
            label={<Typography variant="subtitle2">{formatName(props.data.toLowerCase())}</Typography>}
         />
         {props.tags ? <Circle sx={{ color: props.tags }} fontSize={"small"} /> : <></>}
      </>
   );
};
