import { useContext } from "react";
import { ServiceInfoScreen } from "../ServiceInfoScreen";
import { UserContext } from "../../../context/userContext";

export const ServicesScreen = () => {
   const { companySelected } = useContext(UserContext);

   return (
      companySelected?.company_details && (
         <ServiceInfoScreen
            services={companySelected?.company_details?.servicesDetails}
            company={companySelected ? companySelected : null}
         />
      )
   );
};
