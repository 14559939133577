import { Box, Button, CircularProgress, Divider, Grid, Stack, Tab, Tabs, Typography } from "@mui/material";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { TabPanel } from "../../../screens/Mi Lecosy/ClientInfoScreenNew";
import { BarChart } from "../../charts/barChartSingleColor";
import UseCreateCharts from "../../../hooks/useCreateCharts";
import { CompromiseGraph } from "./SubComponents/PerformanceGraphs/CompromiseGraph";
import { ExperienceGraph } from "./SubComponents/PerformanceGraphs/ExperienceGraph";
import { GraphLabels } from "./SubComponents/GraphLabels";
import { ConstantActualization } from "./SubComponents/PerformanceGraphs/ConstantActualization";
import {
   getEvaluationConfig,
   getLastEvaluation,
   getMultipleResponsesByIds,
   getNumberOfEvaluations,
} from "../../../lib/gobCorpBEClient";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { ResultsGraph } from "./SubComponents/PerformanceGraphs/ResultsGraph";
import { getDateFromISODate } from "../../../const/globalConst";

export const EvaluationResults = () => {
   const [data, setData] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const { selectedGovernance, companySelected } = useContext(GovernanceContext);
   const [lastEvaluation, setLastEvaluation] = useState(null);
   const [number, setNumber] = useState(null);
   const [nextEvaluation, setNextEvaluation] = useState("Sin datos");

   const fetchLastEvaluationCompleted = useCallback(async () => {
      if (!companySelected || lastEvaluation) return;
      const response = await getLastEvaluation(companySelected);
      if (response[0]) {
         setLastEvaluation(response[0]);
      }
   }, [companySelected]);

   useEffect(() => {
      fetchLastEvaluationCompleted();
   }, [fetchLastEvaluationCompleted]);

   const fetchData = useCallback(async () => {
      if (data.length > 0 || !companySelected || !selectedGovernance || !lastEvaluation) return;
      setIsLoading(true);
      const configFound = await getEvaluationConfig({ company: companySelected });
      if (configFound.period && configFound.period.length > 0) {
         const index = configFound.period.findIndex((per) => per.startDate === lastEvaluation.startDate);
         if (index) {
            setNextEvaluation(configFound.period[index + 1]["startDate"]);
         }
      }
      const response = await getMultipleResponsesByIds(selectedGovernance._id, lastEvaluation._id);
      const responseNumber = await getNumberOfEvaluations();
      setNumber(responseNumber);
      setData(response);
      setIsLoading(false);
   }, [selectedGovernance, companySelected, lastEvaluation]);

   useEffect(() => {
      fetchData();
   }, [fetchData]);

   if (!lastEvaluation) {
      return null;
   }

   if (isLoading) {
      return <CircularProgress />;
   }

   return (
      <Box bgcolor={"white"} borderColor={"#B5B5B5"} borderRadius={3}>
         <Tabs value={"1"} textColor="primary">
            <Tab label="Resultados de la evaluación" value={"1"} />
         </Tabs>
         <Divider sx={{ borderBottomWidth: 2 }} />
         <TabPanel index={1} value={1}>
            <Stack sx={{ p: 2 }}>
               <Box sx={{ display: "flex", minHeight: 300, p: 2, columnGap: 2 }}>
                  <Stack sx={{ boxShadow: 1, borderRadius: 2, flex: 2, p: 2 }}>
                     <Typography textAlign={"center"} fontWeight={600} sx={{ minHeight: 30 }}>
                        Resultados obtenidos
                     </Typography>
                     <ResultsGraph
                        answers1={
                           data.find((question) => question.title === "Cumplimiento de responsabilidades")["responses"]
                        }
                        answers2={data.find((question) => question.title === "Desempeño de los miembros")["responses"]}
                        answers3={
                           data.find((question) => question.title === "Calidad de la comunicación de información")[
                              "responses"
                           ]
                        }
                        answers4={
                           data.find((question) => question.title === "Calificación de transparencia")["responses"]
                        }
                     />
                  </Stack>
                  <Stack sx={{ boxShadow: 1, borderRadius: 2, flex: 1, rowGap: 2, p: 1 }}>
                     <Typography textAlign={"center"} fontWeight={600} sx={{ minHeight: 60 }}>
                        Diversidad y experiencia para toma de decisiones
                     </Typography>
                     {data.find((question) => question.title === "Diversidad y experiencia para toma de decisiones")[
                        "responses"
                     ].length > 0 ? (
                        <CompromiseGraph
                           answers={
                              data.find(
                                 (question) => question.title === "Diversidad y experiencia para toma de decisiones"
                              )["responses"]
                           }
                        />
                     ) : (
                        <Typography textAlign="center" variant="subtitle1">
                           {" "}
                           Sin datos
                        </Typography>
                     )}
                  </Stack>
                  <Stack sx={{ boxShadow: 1, borderRadius: 2, flex: 1, rowGap: 2, p: 1 }}>
                     <Typography textAlign={"center"} fontWeight={600} sx={{ minHeight: 60 }}>
                        Identificación de riesgos
                     </Typography>
                     {data.find((question) => question.title === "Identificación de riesgos")["responses"].length >
                     0 ? (
                        <CompromiseGraph
                           answers={
                              data.find((question) => question.title === "Identificación de riesgos")["responses"]
                           }
                        />
                     ) : (
                        <Typography textAlign="center" variant="subtitle1">
                           {" "}
                           Sin datos
                        </Typography>
                     )}
                  </Stack>
               </Box>
               <Box sx={{ display: "flex", minHeight: 300, p: 2, columnGap: 2 }}>
                  <Stack sx={{ boxShadow: 1, borderRadius: 2, flex: 1, py: 1 }}>
                     <Typography textAlign={"center"} fontWeight={600}>
                        Evaluación periódica
                     </Typography>
                     <Box sx={{ minHeight: 250, py: 2 }}>
                        <ExperienceGraph
                           answers={data.find((question) => question.title === "Evaluación periódica")["responses"]}
                        />
                        <Box sx={{ pt: 1 }}>
                           <GraphLabels />
                        </Box>
                     </Box>
                  </Stack>
                  <Stack sx={{ boxShadow: 1, borderRadius: 2, flex: 1, py: 1 }}>
                     <Typography textAlign={"center"} fontWeight={600}>
                        Comunicación de áreas de oportunidad
                     </Typography>
                     <Box sx={{ minHeight: 250, py: 2 }}>
                        <ExperienceGraph
                           answers={
                              data.find((question) => question.title === "Comunicación de áreas de oportunidad")[
                                 "responses"
                              ]
                           }
                        />
                        <Box sx={{ pt: 1 }}>
                           <GraphLabels />
                        </Box>
                     </Box>
                  </Stack>
                  <Stack sx={{ boxShadow: 1, borderRadius: 2, flex: 2, p: 1 }}>
                     <Typography textAlign={"center"} fontWeight={600}>
                        Cultura ética e integra
                     </Typography>

                     <ConstantActualization
                        answers={data.find((question) => question.title === "Cultura ética e integra")["responses"]}
                     />
                  </Stack>
               </Box>
               <Stack sx={{ mx: 2, boxShadow: 1, borderRadius: 2 }}>
                  <Stack direction={"row"} sx={{ justifyContent: "space-between" }}>
                     <Box sx={{ height: 320, minWidth: "80%", maxWidth: "90%", flex: 2, pr: 2 }}>
                        {UseCreateCharts({
                           type: "stack",
                           scores: [
                              data
                                 .slice(9, 14)
                                 .map(
                                    (item) =>
                                       item.responses.filter(
                                          (answer) => answer.rangeAnswer === 1 || answer.rangeAnswer === 2
                                       ).length
                                 ),
                              data
                                 .slice(9, 14)
                                 .map(
                                    (item) =>
                                       item.responses.filter(
                                          (answer) => answer.rangeAnswer === 3 || answer.rangeAnswer === 4
                                       ).length
                                 ),
                              data
                                 .slice(9, 14)
                                 .map(
                                    (item) =>
                                       item.responses.filter(
                                          (answer) => answer.rangeAnswer === 5 || answer.rangeAnswer === 6
                                       ).length
                                 ),
                           ],
                           labels: data.slice(9, 14).map((question) => question.title),
                           labelNames: ["Deficiente", "Regular", "Sobresaliente"],
                           colors: ["#A3A3A3", "#868FA0", "#152C44"],
                           chartRef: undefined,
                           borderRadius: true,
                           barThickness: 30,
                           indexAxis: "y",
                           removeXLables: true,
                           removeMaxValue: true,
                        })}
                     </Box>
                     <Stack sx={{ rowGap: 4.5, pt: 3.5, flex: 0.1 }}>
                        {data.slice(9, 14).map((question) => {
                           const sum = question.responses.reduce((total, item) => total + item.rangeAnswer, 0);

                           const total = question.responses.length * 6;
                           const percentage = sum / total;
                           return <Typography>{isNaN(percentage) ? 0 + "%" : percentage.toFixed(2) + "%"}</Typography>;
                        })}
                     </Stack>
                  </Stack>
                  <GraphLabels />
               </Stack>
               <Divider sx={{ my: 2 }} />
               <Stack direction={"row"} sx={{ justifyContent: "center", px: 4, py: 2 }}>
                  <Grid container>
                     <Grid item container xs={8} spacing={2}>
                        <Grid item xs={6}>
                           <Stack direction={"row"} columnGap={2}>
                              <Typography sx={{ fontWeight: 600 }} variant="body2">
                                 Fecha de inicio:
                              </Typography>
                              <Typography variant="body2">{getDateFromISODate(lastEvaluation.createdAt)}</Typography>
                           </Stack>
                        </Grid>
                        <Grid item xs={6}>
                           <Stack direction={"row"} columnGap={2}>
                              <Typography sx={{ fontWeight: 600 }} variant="body2">
                                 Número de evaluaciones realizadas:
                              </Typography>
                              <Typography
                                 sx={{ bgcolor: "#162c44", color: "white", borderRadius: 2, px: 1.5 }}
                                 variant="body2"
                              >
                                 {number ? number.progress : 0}
                              </Typography>
                           </Stack>
                        </Grid>
                        <Grid item xs={6}>
                           <Stack direction={"row"} columnGap={2}>
                              <Typography sx={{ fontWeight: 600 }} variant="body2">
                                 Fecha de última evaluación realizada:
                              </Typography>
                              <Typography variant="body2">
                                 {lastEvaluation ? getDateFromISODate(lastEvaluation.updatedAt) : "Sin datos"}
                              </Typography>
                           </Stack>
                        </Grid>
                        <Grid item xs={6}>
                           <Stack direction={"row"} columnGap={2}>
                              <Typography sx={{ fontWeight: 600 }} variant="body2">
                                 Fecha de próxima evaluación:
                              </Typography>
                              <Typography
                                 sx={{ bgcolor: "#162c44", color: "white", borderRadius: 2, px: 1.5 }}
                                 variant="body2"
                              >
                                 {nextEvaluation !== "Sin datos" ? getDateFromISODate(nextEvaluation) : nextEvaluation}
                              </Typography>
                           </Stack>
                        </Grid>
                     </Grid>
                     <Grid item xs={4} justifyContent={"center"} display={"flex"} alignItems={"center"}>
                        <Stack sx={{ rowGap: 2 }}>
                           <Button variant="contained" fullWidth sx={{ maxWidth: 250 }} disabled={true}>
                              Evaluación finalizada
                           </Button>
                        </Stack>
                     </Grid>
                  </Grid>
               </Stack>
            </Stack>
         </TabPanel>
      </Box>
   );
};
