import { Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";
import { getContrastYIQ } from "../../../const/globalConst";

Font.register({
   family: "open-sans",
   src: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-Regular.ttf",
});

Font.register({
   family: "open-sans-bold",
   src: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-Bold.ttf",
});

export const shareHolderUpdateRequestTemplate = (
   companyLogo,
   primaryColor,
   userName,
   presidentName,
   municipality,
   state,
   documents
) => {
   const styles = StyleSheet.create({
      body: {
         paddingTop: 100,
         paddingBottom: 65,
         paddingHorizontal: 75,
         fontFamily: "open-sans",
      },
      logo: {
         height: 40,
         width: "auto",
         position: "absolute",
         top: 50,
         left: 65,
      },
      title: {
         fontSize: 12,
         fontFamily: "open-sans-bold",
         fontWeight: "bold",
         textAlign: "center",
         paddingBottom: 20,
      },
      text: {
         fontSize: 11,
         textAlign: "justify",
         lineHeight: 1.8,
      },
      headerTextRight: {
         textAlign: "right",
         fontSize: 11,
         paddingBottom: 20,
      },
      headerTextLeft: {
         textAlign: "left",
         fontSize: 11,
         paddingBottom: 20,
      },
      indentText: {
         paddingBottom: 10,
         textIndent: 30,
      },
      tableHeader: {
         display: "flex",
         flexDirection: "row",
         backgroundColor: primaryColor,
      },
      tableHeaderText: {
         fontSize: 12,
         flex: 1,
         textAlign: "center",
         color: getContrastYIQ(primaryColor),
         fontWeight: "bold",
         paddingVertical: 5,
         lineHeight: 1.8,
      },
      tableHeaderTextWithBorder: {
         fontSize: 12,
         flex: 1,
         textAlign: "center",
         color: "white",
         fontWeight: "bold",
         paddingVertical: 5,
         borderRight: "1px solid #ffffff",
         lineHeight: 1.8,
      },
      tableRow: {
         display: "flex",
         flexDirection: "row",
         backgroundColor: "#EFEFEF",
      },
      tableCell: {
         paddingVertical: 5,
         paddingHorizontal: 10,
         fontSize: 11,
         flex: 1,
         textAlign: "justify",
         lineHeight: 1.8,
      },
      tableCellWithBorder: {
         paddingVertical: 5,
         paddingHorizontal: 10,
         fontSize: 11,
         flex: 1,
         textAlign: "justify",
         borderRight: "1px solid #d8d8d8",
         lineHeight: 1.8,
      },
      tableCellTopBorder: {
         borderTop: "1px solid #d8d8d8",
      },
   });

   const fecha = new Date();
   const day = fecha.getDate();
   const month = fecha.toLocaleString("es-ES", { month: "long" });
   const year = fecha.getFullYear();
   const hours24 = fecha.getHours();
   const hours12 = hours24 % 12 || 12;
   const minutes = fecha.getMinutes().toString().padStart(2, "0");
   const amPm = hours24 >= 12 ? "PM" : "AM";

   return (
      <Document>
         <Page style={styles.body}>
            <Image style={styles.logo} fixed src={companyLogo} />
            <Text style={styles.title}>SOLICITUD DE ACTUALIZACIÓN DE INFORMACIÓN ACCIONISTAS</Text>
            <Text style={[styles.text, styles.headerTextRight]}>
               {`${municipality}, ${state} a ${day} de ${month} del ${year}, ${hours12}:${minutes} ${amPm}`}
            </Text>
            <Text style={[styles.text, styles.headerTextLeft]}>Estimado, {userName}</Text>
            <Text style={[styles.text, styles.indentText]}>
               Por medio de la presente, y en cumplimiento de los controles establecidos para mantener la información
               actualizada en todo momento del expediente de accionista, le solicitamos actualizar la siguiente
               información y documentación:
            </Text>
            <View style={styles.tableHeader}>
               <Text style={styles.tableHeaderText}>Documentación</Text>
            </View>
            {documents.map((doc) => (
               <View style={styles.tableRow}>
                  <Text style={[styles.tableCell, styles.tableCellTopBorder]}>&#x2022; {doc}</Text>
               </View>
            ))}
            <Text style={[styles.text, { paddingVertical: 20 }]}>
               De antemano agradecemos su colaboración y pronta respuesta a la información.
            </Text>
            <Text style={styles.text}>
               Atentamente
               {"\n"}
               {presidentName}
            </Text>
         </Page>
      </Document>
   );
};
