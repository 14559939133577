import { useEffect, useState } from "react";
import XMLParser from "react-xml-parser";

const useGetInsights = () => {
   const [xml, setXml] = useState(null);
   const [insights, setInsights] = useState([]);
   const [guides, setGuides] = useState([]);

   function sortByPubDate(array) {
      let len = array.length;
      for (let i = 0; i < len - 1; i++) {
         for (let j = 0; j < len - 1 - i; j++) {
            const date1 = new Date(array[j].children.find((child) => child.name === "pubDate").value);
            const date2 = new Date(array[j + 1].children.find((child) => child.name === "pubDate").value);
            if (date1 < date2) {
               let temp = array[j];
               array[j] = array[j + 1];
               array[j + 1] = temp;
            }
         }
      }
      return array;
   }

   const getImageUrl = (description) => {
      const imageSrc = description.indexOf("src=");
      let imageExt = description.indexOf("jpg");
      if (imageExt === -1) imageExt = description.indexOf("png");
      return description.substring(imageSrc + 5, imageExt + 3);
   };

   useEffect(() => {
      const getWordpressBlog = async () => {
         const urls = ["https://blog.lecosy.com.mx/index.php/feed/"];
         const fetchAndParse = async (url) => {
            const response = await fetch(url);
            const data = await response.text();
            const xml = new XMLParser().parseFromString(data);
            const itemsFound = xml.children[0].children.filter((i) =>
               i.children.some(
                  (c) =>
                     c.name === "category" &&
                     (c.value.includes("Buenas prácticas empresariales") ||
                        c.value.includes("Gobierno Corporativo") ||
                        c.value.includes("Formularios y herramientas") ||
                        c.value.includes("Consejos prácticos"))
               )
            );
            return itemsFound;
         };
         const itemsPromises = urls.map(fetchAndParse);
         Promise.all(itemsPromises)
            .then((itemsArrays) => {
               const itemsFound = itemsArrays.flat();
               const orderByPubDate = sortByPubDate(itemsFound);
               setXml(orderByPubDate);
            })
            .catch((error) => {
               console.error("Error al obtener los datos:", error);
            });
      };
      getWordpressBlog();
   }, []);

   useEffect(() => {
      if (xml) {
         const xmlObject = xml.map((element) => {
            const title = element.children.find((prop) => prop.name === "title").value;
            const image = getImageUrl(element.children.find((prop) => prop.name === "description").value);
            const dateRaw = new Date(element.children.find((prop) => prop.name === "pubDate").value);
            const date =
               (dateRaw.getDate().toString().length === 1 ? "0" + dateRaw.getDate().toString() : dateRaw.getDate()) +
               " " +
               capitalizeFirstLetter(getMonthName(dateRaw.getMonth().toString())) +
               " " +
               dateRaw.getFullYear();
            const link = element.children.find((prop) => prop.name === "link").value;
            const category = element.children.find((prop) => prop.name === "category").value.replace(">", "");
            const insights = {
               title: title,
               image: image,
               date: date,
               link: link,
               category: category,
            };
            return insights;
         });

         setInsights(
            xmlObject.filter((c) => c.category.includes("Buenas prácticas empresariales" || "Gobierno Corporativo"))
         );
         setGuides(xmlObject.filter((c) => c.category.includes("Formularios y herramientas" || "Consejos prácticos")));
      }
   }, [xml]);

   const capitalizeFirstLetter = (string) => {
      return string.charAt(0).toUpperCase() + string.slice(1);
   };

   const getMonthName = (monthNumber) => {
      const date = new Date();
      date.setMonth(monthNumber);

      return date.toLocaleString("es-ES", { month: "long" });
   };

   return { insights, guides };
};

export default useGetInsights;
