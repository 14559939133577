import { CouncilGraphs } from "../../components/Gobierno corporativo/ReportsScreen/CouncilGraphs";
import { PerformanceEvaluationBar } from "../../components/Gobierno corporativo/ReportsScreen/PerformanceEvaluationBar";
import { Stack } from "@mui/material";
import { EvaluationProvider } from "../../context/governanceContext/evaluationContext";

export const ReportsScreen = () => {
   return (
      <Stack rowGap={2}>
         <EvaluationProvider>
            <PerformanceEvaluationBar />
            <CouncilGraphs />
         </EvaluationProvider>
      </Stack>
   );
};
