import { Box, Stack, Typography } from "@mui/material";

export const SessionNote = ({ noteData }) => {
   const formattedDate = new Date(noteData?.createdAt).toLocaleDateString();
   const formattedHour = new Date(noteData?.createdAt).getHours() + ":" + new Date(noteData?.createdAt).getMinutes();
   return (
      <Stack sx={{ borderBottom: 1, borderColor: "#E8E8E8", p: 2, gap: 1 }}>
         <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
            <Box sx={{ display: "flex", gap: 1 }}>
               <Typography sx={{ fontWeight: 600, fontSize: 10, color: "#707070" }}>{formattedDate}</Typography>
               <Typography sx={{ fontWeight: 600, fontSize: 10, color: "#707070" }}>•</Typography>
               <Typography sx={{ fontWeight: 600, fontSize: 10, color: "#707070" }}>{formattedHour}</Typography>
            </Box>
            <Box sx={{ bgcolor: "#F7F9FB", borderRadius: 2.5, px: 1.5 }}>
               <Typography noWrap sx={{ fontSize: 8.5, color: "#3F5063" }}>
                  {noteData?.ownerName}
               </Typography>
            </Box>
         </Box>
         <Typography noWrap sx={{ color: "#373737", fontSize: 14, maxWidth: "90%" }}>
            {noteData?.title}
         </Typography>
         <Typography
            noWrap
            dangerouslySetInnerHTML={{ __html: noteData?.description }}
            sx={{
               color: "#9E9E9E",
               fontSize: 10,
               "& p": { margin: 0, padding: 0 },
               overflow: "hidden",
               whiteSpace: "nowrap",
               textOverflow: "ellipsis",
            }}
         />
      </Stack>
   );
};
