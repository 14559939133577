import { useContext, useEffect, useMemo, useState } from "react";
import { getUserDataGB } from "../../../lib/usersBEClient";
import { getChargeByGovernUsers, getUserProfData } from "../../../lib/gobCorpBEClient";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import _ from "lodash";

const useFilterGovernBodies = () => {
   const { gobernanceBody } = useContext(GovernanceContext);
   const [loadingBodies, setLoadingBodies] = useState(true);

   const bodies: any[] = useMemo(() => {
      if (gobernanceBody.length > 0) {
         let array = gobernanceBody.filter((e) => e.title !== "PANEL DE USUARIO");
         setLoadingBodies(false);
         return array;
      }
      return [];
   }, [gobernanceBody]);
   return { bodies, loadingBodies };
};

const useGetGovernmentBodyUsersInfo = (onlyShareholdersCheck = false) => {
   const { bodies, loadingBodies } = useFilterGovernBodies();
   const [members, setMembers] = useState([]);
   const [loadingFormat, setLoadingFormat] = useState(false);

   useEffect(() => {
      const processData = async () => {
         setLoadingFormat(true);
         const users = [];
         for (const body of bodies) {
            users.push(...body.users);
         }
         const ids = users.map((e) => e.user);
         //array con formato de govern bodies
         const chargesIds = users.map((e) => e.charge);
         const cleanArray = ids.filter((a, b) => ids.indexOf(a) === b); //string array sin duplicados para mandar al backend
         const chargeData = await getChargeByGovernUsers(chargesIds);
         const userData = await getUserDataGB(cleanArray);
         const userProfData = await getUserProfData(cleanArray);
         //FORMATEO DE USER DATA
         const formatUserIds = userProfData.map((e) => e.ids);
         const formatUserProf = userProfData.map((e) => e.data);

         const theUsers = [];
         for (const user of users) {
            theUsers.push({
               profesionalData: formatUserIds.find((e) => e.user === user.user)?.profesionalData,
               ...user,
            });
         }

         const completePorfile = [];
         for (const theUser of theUsers) {
            completePorfile.push({
               ...chargeData.find((e) => e._id === theUser.charge),
               ...formatUserProf.find((e) => e._id === theUser.profesionalData),
               ...userData.find((e) => e._id === theUser.user),
               ...theUser,
            });
         }

         // ASIGNACION DE DATA A ORGANOS DE GOBIERNO
         const mergedFinalBodies = [];
         for (const body of bodies) {
            let bodiesData = (({ _id, title }) => ({ _id, title }))(body);
            let usersData = [];
            for (const user of body.users) {
               if (onlyShareholdersCheck && body.title === "Asamblea de accionistas") {
                  let userObject = {
                     ...completePorfile.find((e) => e._id === user._id && e.chargeName === "Accionista"),
                  };
                  if (Object.entries(userObject).length !== 0) {
                     usersData.push({ ...userObject });
                  }
               } else {
                  usersData.push({ ...completePorfile.find((e) => e._id === user._id) });
               }
            }
            mergedFinalBodies.push({ bodiesData, usersData });
         }
         setMembers(mergedFinalBodies);
         setLoadingFormat(false);
      };

      // return () => {
      if (!loadingBodies && members.length === 0 && bodies.length > 0) {
         processData();
      }
      // };
   }, [bodies, loadingBodies]);

   return { loadingFormat, members };
};

export default useGetGovernmentBodyUsersInfo;
