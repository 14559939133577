import { Box } from "@mui/material";
import { LeftTabsComponent } from "../../components/Gobierno corporativo/ProfileScreen/LeftTabsComponent";

export const ProfileScreen = () => {
   return (
      <Box
         sx={{
            display: "flex",
            columnGap: 2,
            flexDirection: { sm: "column", md: "row" },
            flex: 1,
            bgcolor: "white",
            boxShadow: 2,
            borderRadius: 1,
            p: 2,
         }}
      >
         <LeftTabsComponent />
      </Box>
   );
};
