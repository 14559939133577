import { Box, Typography, CircularProgress, Grid, IconButton, Popover } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import CheckIcon from "@mui/icons-material/Check";
import InfoIcon from "@mui/icons-material/Info";
import { UserContext } from "../../../../../context/userContext";
import { GovernanceContext } from "../../../../../context/governanceContext/governanceContext";
import { DeleteAFilesByUserId, getFilesByUserId } from "../../../../../lib/gobCorpBEClient";
import { FilesInformationComponent } from "../../../ProfileScreen/SubComponents/FilesInformationComponent";
import { deletePdfEvidence } from "../../../../../lib/usersBEClient";

const gridRow = {
   display: "flex",
   alignItems: "center",
};

const useGetFiles = (seed: number, isShareholder: boolean, memberId) => {
   const [files, setFiles] = useState<any>(null);
   const [isLoading, setIsLoading] = useState<boolean>(true);
   const { user } = useContext(UserContext);
   const { fileToDelete, companySelected } = useContext(GovernanceContext);
   const { mutate } = DeleteAFilesByUserId(files ? files._id : "");
   const [refreshFiles, setRefreshFiles] = useState(false);

   useEffect(() => {
      setIsLoading(true);
      const fetch = async () => {
         const data = await getFilesByUserId(memberId, companySelected, isShareholder);
         setFiles(data);
         setIsLoading(false);
      };
      if (user.id.length > 0) fetch();
   }, [seed, user, refreshFiles]);

   useEffect(() => {
      if (files && fileToDelete) {
         const key = () => {
            for (const key in files) {
               if (Object.prototype.hasOwnProperty.call(files, key)) {
                  const document = files[key];
                  if (document) {
                     if (document.name === fileToDelete.name) {
                        return key;
                     }
                  }
               }
            }
         };
         const newFiles = { ...files, [key()]: null };
         const updateFiles = () => {
            mutate(newFiles, {
               onError: (error) => {
                  console.error(error);
               },
               onSuccess: async (data) => {
                  setRefreshFiles(!refreshFiles);
                  await deletePdfEvidence(
                     "files-lecosy",
                     { folder: `gc/users/${memberId}/files/${files._id}` },
                     fileToDelete.name
                  );
               },
            });
         };

         updateFiles();
      }
   }, [fileToDelete]);

   return { files, isLoading };
};

export const DocumentationUploadMembers = ({ isShareholder, memberId }) => {
   const [seed, setSeed] = useState(0);
   const { files, isLoading } = useGetFiles(seed, isShareholder, memberId);

   const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

   const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };

   const handlePopoverClose = () => {
      setAnchorEl(null);
   };

   const open = Boolean(anchorEl);

   const fileArray = [
      { label: "Curriculum vitae o semblanza personal", name: "cv" },
      { label: "Copia de título profesional", name: "tituloProfesional" },
      { label: "Cédula profesional", name: "cedulaProfesional" },
      { label: "Historial en buró de crédito", name: "historialCrediticio" },
      { label: "Carta de recomendación 1", name: "cartaRecomendacion1" },
      { label: "Carta de recomendación 2", name: "cartaRecomendacion2" },
      { label: "Carta de recomendación 3", name: "cartaRecomendacion3" },
      { label: "Acta de nacimiento", name: "actaNacimiento" },
      { label: "Credencial de elector", name: "credencialElector" },
      { label: "Firma", name: "firmaEscaneada" },
      { label: "Comprobante de domicilio", name: "comprobanteDomicilio" },
      { label: "Buró Legal", name: "buroLegal" },
   ];

   if (isLoading) return <CircularProgress />;
   if (files.isShareholder) {
      fileArray.push({ label: "Titulo accionario", name: "tituloAccionario" });
   }
   return (
      <Grid
         container
         rowSpacing={3}
         key={seed}
         sx={{ overflowY: "auto", mt: 1, height: { md: "40vh", lg: "40vh", xl: "50vh" } }}
      >
         {fileArray.map((fileNames, index) => (
            <Grid container item xs={12} key={index}>
               <Grid item xs={5} sx={gridRow}>
                  <Box sx={{ display: "flex", justifyContent: "space-between", gap: 1, alignItems: "center" }}>
                     {files && files[`${fileNames.name}`] && <CheckIcon />}
                     <Typography sx={{ fontWeight: 600, fontSize: 13, color: "#585757" }}>{fileNames.label}</Typography>
                     {fileNames.name === "firmaEscaneada" && (
                        <>
                           <Popover
                              id="mouse-over-popover"
                              sx={{
                                 pointerEvents: "none",
                              }}
                              open={open}
                              anchorEl={anchorEl}
                              anchorOrigin={{
                                 vertical: "bottom",
                                 horizontal: "left",
                              }}
                              transformOrigin={{
                                 vertical: "top",
                                 horizontal: "left",
                              }}
                              onClose={handlePopoverClose}
                              disableRestoreFocus
                           >
                              <Typography sx={{ p: 1, fontSize: 14, width: 400 }}>
                                 La imagen de la firma deberá ser legible, de color azul y en formato JPG, PNG. El uso
                                 de la firma será para las sesiones que se convoquen dentro del sistema.
                              </Typography>
                           </Popover>
                           <Box onMouseEnter={handlePopoverOpen} onMouseLeave={handlePopoverClose}>
                              <InfoIcon sx={{ color: "#7D8592", fontSize: 16 }} />
                           </Box>
                        </>
                     )}
                  </Box>
               </Grid>
               <Grid item xs={7}>
                  <FilesInformationComponent
                     file={files ? files[`${fileNames.name}`] : null}
                     fileName={fileNames.name}
                     id={files ? files._id : null}
                     setSeed={setSeed}
                     notDisplaySize
                     isVerfied={
                        files &&
                        files[`${fileNames.name}`] &&
                        (files[`${fileNames.name}`].verified !== undefined
                           ? files[`${fileNames.name}`].verified
                           : false)
                     }
                     userId={files.user}
                  />
               </Grid>
            </Grid>
         ))}
      </Grid>
   );
};
