import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { style } from "../../../const/gobCopConst";
import dayjs from "dayjs";
import { Close } from "@mui/icons-material";

interface DateModalProps {
   setOpen: Function;
   startDate;
   finishDate;
   setFinishDate;
   setStartDate;
   setConfirmModal;
}

export const DateModal = ({
   startDate,
   finishDate,
   setFinishDate,
   setStartDate,
   setConfirmModal,
   setOpen,
}: DateModalProps) => {
   return (
      <>
         <Box sx={{ ...style, width: 650 }}>
            <Box
               sx={{
                  bgcolor: "#152C44",
                  minHeight: 50,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  borderTopRightRadius: 6,
                  borderTopLeftRadius: 6,
                  px: 2,
               }}
            >
               <Typography fontWeight={600} variant="h6" sx={{ color: "white" }}>
                  Selección de fechas
               </Typography>
               <IconButton sx={{ color: "#FAFAFA" }} onClick={() => setOpen()}>
                  <Close />
               </IconButton>
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-evenly", py: 1 }}>
               <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="es-mx">
                  <DatePicker
                     slotProps={{
                        textField: { variant: "standard", sx: { mx: 2, pb: 2 } },
                        field: {
                           clearable: true,
                           onClear: () => {
                              setStartDate(null);
                           },
                        },
                     }}
                     maxDate={finishDate}
                     label={"Fecha inicio"}
                     format="DD/MM/YYYY"
                     value={startDate}
                     onChange={(event: any) => {
                        if (event) {
                           setStartDate(dayjs(event.$d));
                        } else {
                           setStartDate(null);
                        }
                     }}
                  />
                  <DatePicker
                     slotProps={{
                        textField: { variant: "standard", sx: { mx: 2, pb: 2 } },
                        field: { clearable: true, onClear: () => setFinishDate(null) },
                     }}
                     minDate={startDate}
                     label={"Fecha final"}
                     format="DD/MM/YYYY"
                     value={finishDate}
                     onChange={(event: any) => {
                        if (event) {
                           setFinishDate(dayjs(event.$d));
                        } else {
                           setFinishDate(null);
                        }
                     }}
                  />
               </LocalizationProvider>
            </Box>
            <Box
               sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  bgcolor: "#F3F3F3",
                  p: 2,
                  borderBottomRightRadius: 5,
                  borderBottomLeftRadius: 5,
               }}
            >
               <Button
                  sx={{ color: "grey", height: 30 }}
                  onClick={() => {
                     setStartDate(null);
                     setFinishDate(null);
                     setOpen();
                  }}
               >
                  Cancelar
               </Button>
               <Button
                  sx={{ height: 30 }}
                  onClick={() => {
                     setConfirmModal(true);
                     setOpen();
                  }}
               >
                  Confirmar
               </Button>
            </Box>
         </Box>
      </>
   );
};
