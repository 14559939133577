import dayjs from "dayjs";
import { useCallback, useContext, useMemo } from "react";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { getTrimesterDates } from "../../../const/globalConst";
import { IAffairs } from "../../../types/governance.types";

type sessionInvolved = {
   _id: string;
   fullName: string;
   chargeName: string;
   attended: boolean;
};
export type sessionAttendance = {
   _id: string;
   involved: sessionInvolved[];
   affairs: IAffairs[];
   votes: any[];
};

type trimesterSessionInfo = {
   title: string;
   sessionDate: Date;
   involved: sessionAttendance[];
};

type trimesterSession = {
   trimester1: trimesterSessionInfo[];
   trimester2: trimesterSessionInfo[];
   trimester3: trimesterSessionInfo[];
   trimester4: trimesterSessionInfo[];
};

export const useGetSessionsAttendanceInfo = (props) => {
   const { selectedBody } = props;
   const trimesterDates = getTrimesterDates();
   const { allCalendars, selectedGovernance } = useContext(GovernanceContext);
   const getFormattedInfo = useCallback(
      (session) => {
         return {
            _id: session._id,
            title: session.title,
            sessionDate: session.finishTime,
            affairs: session.affairs,
            involved: [
               session.usersRegistry.map((user) => {
                  const userInfo = selectedBody.usersData.find((bodyUser) => bodyUser.user === user.user);
                  if (!userInfo) return;
                  return {
                     _id: user.user,
                     attended: user.attended,
                     fullName: `${userInfo.firstName} ${userInfo.lastName}`,
                     chargeName: userInfo.chargeName,
                  };
               }),
            ].flat(),
            votes: session.votes,
         };
      },
      [selectedBody.usersData]
   );

   const getSessionsInfo = () => {
      const sessionsInfo =
         allCalendars.find((callendar) => callendar._id === selectedGovernance.calendar)?.session || undefined;
      const sessionsList: trimesterSession = {
         trimester1: [],
         trimester2: [],
         trimester3: [],
         trimester4: [],
      };

      if (!sessionsInfo) return sessionsList;
      for (let i = 0; i < trimesterDates.length; i++) {
         let selectedTrimesterKey = Object.keys(sessionsList)[i];
         for (const element of sessionsInfo) {
            if (
               dayjs(element.date) >= dayjs(trimesterDates[i]) &&
               dayjs(element.date) < dayjs(trimesterDates[i + 1]) &&
               element.completed
            )
               sessionsList[selectedTrimesterKey].push(getFormattedInfo(element));
         }
      }
      return sessionsList;
   };

   const trimesterSessions = useMemo(getSessionsInfo, [
      allCalendars,
      getFormattedInfo,
      selectedGovernance.calendar,
      trimesterDates,
   ]);

   return trimesterSessions;
};

export const useGetSessionsInfo = () => {
   const trimesterDates = getTrimesterDates();
   const { allCalendars, selectedGovernance } = useContext(GovernanceContext);

   const getSessionsInfo = () => {
      const sessionsInfo =
         allCalendars.find((callendar) => callendar._id === selectedGovernance.calendar)?.session || undefined;
      const sessionsList: trimesterSession = {
         trimester1: [],
         trimester2: [],
         trimester3: [],
         trimester4: [],
      };
      if (!sessionsInfo) return sessionsList;
      for (let i = 0; i < trimesterDates.length; i++) {
         let selectedTrimesterKey = Object.keys(sessionsList)[i];
         for (const element of sessionsInfo) {
            if (
               dayjs(element.finishTime) >= dayjs(trimesterDates[i]) &&
               dayjs(element.finishTime) < dayjs(trimesterDates[i + 1])
            )
               sessionsList[selectedTrimesterKey].push(element);
         }
      }
      return sessionsList;
   };

   const trimesterSessions = useMemo(getSessionsInfo, [allCalendars, selectedGovernance.calendar, trimesterDates]);
   return trimesterSessions;
};
