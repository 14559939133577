import { Person } from "@mui/icons-material";
import {
   Modal,
   Box,
   Grid,
   Typography,
   Divider,
   Button,
   Accordion,
   AccordionSummary,
   AccordionDetails,
} from "@mui/material";
import { useContext } from "react";
import { ComplaintStepperContext } from "../context/complaintStepperContext";

interface ComplainerDetailsModalProps {
   open: boolean;
   setOpen: Function;
   onCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

export const ComplainerDetailsModal = (props: ComplainerDetailsModalProps) => {
   const { complaint } = useContext(ComplaintStepperContext);

   const handleClose = () => {
      props.setOpen(false);
   };

   return (
      <Modal
         open={props.open}
         onClose={handleClose}
         sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
         <Box sx={{ width: "50%", minWidth: 600, maxWidth: 1000, borderRadius: 1, bgcolor: "white", px: 3, py: 1 }}>
            <Typography variant="h5" mt={1}>
               Información del denunciante
            </Typography>
            <Divider sx={{ my: 1 }} />
            <Accordion expanded sx={{ borderRadius: 1, border: "1px solid #00000010" }}>
               <AccordionSummary
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  sx={{ "&:hover": { cursor: "move" } }}
               >
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                     <Person sx={{ mr: 1 }} />
                     <Typography fontWeight={600} fontSize={17}>
                        {complaint.complainerDetails ? (
                           `${complaint.complainerDetails.name} ${complaint.complainerDetails.lastName}`
                        ) : (
                           <></>
                        )}
                     </Typography>
                  </Box>
               </AccordionSummary>
               <AccordionDetails sx={{ bgcolor: "#F1F1F1" }}>
                  <Box sx={{ px: 2, pt: 1 }}>
                     <Grid container>
                        <Grid item xs={6}>
                           <Typography fontWeight={600}>Género</Typography>
                           <Typography gutterBottom>
                              {complaint.complainerDetails ? complaint.complainerDetails.gender : <></>}
                           </Typography>
                        </Grid>
                        <Grid item xs={6}>
                           <Typography fontWeight={600}>Celular</Typography>
                           <Typography>
                              {complaint.complainerDetails ? complaint.complainerDetails.phoneNumber : <></>}
                           </Typography>
                        </Grid>
                        <Grid item xs={6}>
                           <Typography fontWeight={600}>Correo electónico</Typography>
                           <Typography>
                              {complaint.complainerDetails ? complaint.complainerDetails.email : <></>}
                           </Typography>
                        </Grid>
                        <Grid item xs={6}>
                           <Typography fontWeight={600}>Dirección</Typography>
                           <Typography>
                              {complaint.complainerDetails ? complaint.complainerDetails.address : <></>}
                           </Typography>
                        </Grid>
                        <Grid item xs={6}>
                           <Typography fontWeight={600}>Estado</Typography>
                           <Typography>
                              {complaint.complainerDetails ? complaint.complainerDetails.state : <></>}
                           </Typography>
                        </Grid>
                        <Grid item xs={6}>
                           <Typography fontWeight={600}>Ciudad</Typography>
                           <Typography>
                              {complaint.complainerDetails ? complaint.complainerDetails.city : <></>}
                           </Typography>
                        </Grid>
                        <Grid item xs={6}>
                           <Typography fontWeight={600}>C.P.</Typography>
                           <Typography>
                              {complaint.complainerDetails ? complaint.complainerDetails.zipCode : <></>}
                           </Typography>
                        </Grid>
                     </Grid>
                  </Box>
               </AccordionDetails>
            </Accordion>
            <Box display="flex" justifyContent="flex-end">
               <Button variant="contained" onClick={() => props.setOpen(false)}>
                  Cerrar
               </Button>
            </Box>
         </Box>
      </Modal>
   );
};
