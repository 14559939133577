import { createContext, useMemo, useState } from "react";

interface AddUserContextInferface {
   modules: any[];
   addModuleOrRole: Function;
   removeModule: Function;
   removeRole: Function;
   addModuleOrRoleGC: Function;
   removeBranch: Function;
}

export const AddUserContext = createContext<AddUserContextInferface>({
   modules: [],
   addModuleOrRole: (module, role, branch?) => {},
   removeModule: (module) => {},
   removeRole: (role) => {},
   addModuleOrRoleGC: (module, role) => {},
   removeBranch: (module, branch) => {},
});

export const AddUserProvider = ({ children }) => {
   const [modules, setModules] = useState([]);

   const addModuleOrRole = (moduleData, event, branch) => {
      const dataEvent = event.target.value;
      if (branch) {
         const modulesFilteredD = modules.find((data) => data.module === moduleData.service.id);
         if (modulesFilteredD) {
            setModules(() => {
               const modulesFiltered = modules.filter((data) => data.module !== moduleData.service.id);
               const newState = [
                  ...modulesFiltered,
                  {
                     ...modulesFilteredD,
                     branches: [dataEvent],
                  },
               ];
               return newState;
            });
         } else {
            setModules((prevState) => {
               const newState = [
                  ...prevState,
                  {
                     module: moduleData.service.id,
                     // role: dataEvent._id,
                     company: moduleData.company,
                     // name: dataEvent.name,
                     branches: [dataEvent],
                  },
               ];
               return newState;
            });
         }
      } else {
         const modulesFiltered = modules.filter((data) => data.module === moduleData.service.id);
         if (modulesFiltered.length > 0) {
            if (moduleData.service.serviceName === "Canal de denuncias") {
               const modulesFilteredD = modules.find((data) => data.module === moduleData.service.id);
               setModules(() => {
                  const modulesFiltered = modules.filter((data) => data.module !== moduleData.service.id);
                  const newState = [
                     ...modulesFiltered,
                     {
                        module: moduleData.service.id,
                        role: dataEvent._id,
                        company: moduleData.company,
                        name: dataEvent.name,
                        branches: modulesFilteredD.branches ? modulesFilteredD.branches : [],
                     },
                  ];
                  return newState;
               });
               return;
            }
            setModules((prevState) => {
               const modulesFiltered = modules.filter((data) => data.module !== moduleData.service.id);
               const newState = [
                  ...modulesFiltered,
                  {
                     module: moduleData.service.id,
                     role: dataEvent._id,
                     company: moduleData.company,
                     name: dataEvent.name,
                  },
               ];
               return newState;
            });
            return;
         }
         setModules((prevState) => {
            const newState = [
               ...prevState,
               {
                  module: moduleData.service.id,
                  role: dataEvent._id,
                  company: moduleData.company,
                  name: dataEvent.name,
               },
            ];
            return newState;
         });
      }
   };

   const addModuleOrRoleGC = (moduleData, event, governance) => {
      const modulesFiltered = modules.filter(
         (data) => data.module === moduleData.service.id && data.company === moduleData.company
      );
      if (modulesFiltered.length > 0) {
         setModules((prevState) => {
            const modulesFiltered = modules.filter(
               (data) => data.module !== moduleData.service.id && data.company !== moduleData.company
            );
            const newState = [
               ...modulesFiltered,
               {
                  module: moduleData.service.id,
                  role: event._id,
                  company: moduleData.company,
                  name: event.chargeName,
                  governance: governance,
               },
            ];
            return newState;
         });
         return;
      }
      setModules((prevState) => {
         const newState = [
            ...prevState,
            {
               module: moduleData.service.id,
               role: event._id,
               company: moduleData.company,
               name: event.chargeName,
               governance: governance,
            },
         ];
         return newState;
      });
   };

   const removeModule = (module) => {
      setModules((prevState) => {
         const newState = [...prevState.filter((data) => data.module !== module)];
         return newState;
      });
   };

   const removeRole = (module, role) => {
      if (module.service.serviceName === "Canal de denuncias") {
         const modulesFilteredD = modules.find((data) => data.module === module.service.id);
         setModules((prevState) => {
            const newState = [
               ...prevState.filter((data) => data.role !== role),
               {
                  module: module.service.id,
                  company: module.company,
                  branches: modulesFilteredD.branches,
               },
            ];
            return newState;
         });
         return;
      }
      setModules((prevState) => {
         const newState = [...prevState.filter((data) => data.role !== role)];
         return newState;
      });
   };

   const removeBranch = (module, branch) => {
      const modulesFilteredD = modules.find((data) => data.module === module.service.id);
      setModules((prevState) => {
         const newState = [
            ...prevState.filter(
               (data) => data.role !== modulesFilteredD.module && data.branches.some((b) => b._id !== branch)
            ),
            {
               module: module.service.id,
               company: module.company,
               role: modulesFilteredD.role,
               name: modulesFilteredD.name,
               branches: modulesFilteredD.branches.filter((branchF) => branchF._id !== branch),
            },
         ];
         return newState;
      });
   };

   const value = useMemo(
      () => ({ modules, addModuleOrRole, removeModule, removeRole, addModuleOrRoleGC, removeBranch }),
      [modules, addModuleOrRole, removeModule, removeRole, addModuleOrRoleGC, removeBranch]
   );

   return <AddUserContext.Provider value={value}>{children}</AddUserContext.Provider>;
};
