import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getBeneficiaryByFolderId, getFoldersAndFilesById } from "../../lib/usersBEClient";
import { Box, Typography, Stack } from "@mui/material";
import { MyFiles } from "../../components/Gobierno corporativo/MyFilesScreen/MyFiles";
import { Filters } from "../../components/Gobierno corporativo/MyFilesScreen/SubComponents/Filters";
import { Folder, PictureAsPdf } from "@mui/icons-material";
import { formatFileSize, getDateAndTimeFromISODate } from "../../const/globalConst";
import { FilesContext } from "../../context/governanceContext/filesContext";
import dayjs from "dayjs";
import { UserContext } from "../../context/userContext";
import { getIconByType } from "../Gobierno corporativo/FilesVerificationScreen";

const useGetFiles = () => {
   const { folderId } = useParams();
   const [isLoading, setIsLoading] = useState(true);
   const [files, setFiles] = useState([]);
   const [reload, setReload] = useState(null);
   const { startDate, endDate, confirmModal, setDocumentSelected, setBeneficiary, beneficiary, documentSelected } =
      useContext(FilesContext);
   const { user, companySelected } = useContext(UserContext);

   useEffect(() => {
      const fetchFolders = async () => {
         if (!folderId || !companySelected) return;
         setIsLoading(true);
         try {
            const companyInGroup = user?.group?.companies.some((c) => c === companySelected._id);
            const response = await getFoldersAndFilesById(
               [folderId],
               companyInGroup ? user?.group?._id : companySelected._id
            );
            const beneficiary = await getBeneficiaryByFolderId(folderId);
            setBeneficiary(beneficiary);
            setDocumentSelected(response.folder);
            setFiles([...response.folder.children, ...response.files]);
         } catch (error) {
            console.error("Error fetching folders and files:", error);
         }
         setIsLoading(false);
      };
      if (folderId) fetchFolders();
   }, [folderId, reload, companySelected, setDocumentSelected]);

   const filteredData = useMemo(() => {
      return files
         .filter((file) => (file.company && file.company === companySelected._id) || file.name !== "Control")
         .map((iterator) => {
            if (iterator.size) {
               const name = iterator.name.split(".");
               name.pop();
               let nameNew = null;
               if (iterator.beneficiary) {
                  nameNew = (
                     <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                        {getIconByType(iterator.beneficiary?.verified, iterator.beneficiary?.needUpdate, !iterator)}
                        <Typography variant="subtitle2">{iterator.name}</Typography>
                     </Box>
                  );
               } else {
                  nameNew = (
                     <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                        <PictureAsPdf sx={{ color: "red" }} />
                        <Typography variant="subtitle2">{iterator.name}</Typography>
                     </Box>
                  );
               }

               return {
                  ...iterator,
                  name: nameNew,
                  originalName: name.join("."),
                  updatedAt: getDateAndTimeFromISODate(iterator.updatedAt),
                  owner: iterator.owner ? `${iterator.owner.firstName} ${iterator.owner.lastName}` : "---",
                  ownerId: iterator.owner ? iterator.owner._id : "",
                  size: formatFileSize(iterator.size),
                  originalType: iterator.name.split(".").pop(),
               };
            } else {
               return {
                  ...iterator,
                  type: "carpeta",
                  name:
                     iterator.name === "Control" ? (
                        <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                           <Folder sx={{ color: "#162C44", fontSize: 25 }} />
                           <Typography variant="subtitle2">{iterator.name}</Typography>
                        </Box>
                     ) : (
                        <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                           <Folder sx={{ color: "#F1B44C", fontSize: 25 }} />
                           <Typography variant="subtitle2">{iterator.name}</Typography>
                        </Box>
                     ),
                  updatedAt: getDateAndTimeFromISODate(iterator.updatedAt),
                  owner: iterator.owner ? `${iterator.owner.firstName} ${iterator.owner.lastName}` : "---",
                  ownerId: iterator.owner ? iterator.owner._id : "",
                  size: "---",
                  originalName: iterator.name,
               };
            }
         });
   }, [files]);

   const processedData = useMemo(() => {
      if (confirmModal && startDate !== null && endDate !== null) {
         return filteredData.filter(
            (file) => dayjs(new Date(file.createdAt)) >= startDate && dayjs(new Date(file.createdAt)) <= endDate
         );
      }
      return filteredData;
   }, [filteredData, confirmModal, startDate, endDate]);

   return { isLoading, processedData, setReload, reload };
};

export const ExpedientScreen = () => {
   const { isLoading, processedData, setReload, reload } = useGetFiles();

   return (
      <>
         <Stack direction="row" spacing={2} alignItems="center" sx={{ mt: 3 }}>
            <Box sx={{ flexGrow: 1 }}>
               <Filters />
            </Box>
         </Stack>
         <Box sx={{ py: 1 }}>
            <MyFiles files={processedData} isLoading={isLoading} setReload={setReload} reload={reload} beneficiary />
         </Box>
      </>
   );
};
