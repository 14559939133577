import { Box, Divider, Popover, Stack, Tab, Tabs, Typography } from "@mui/material";
import { TabPanel } from "../../../screens/Mi Lecosy/ClientInfoScreenNew";
import { useState } from "react";
import SessionInfoChart from "./SubComponents/PerformanceGraphs/SessionInfoChart";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import CircleIcon from "@mui/icons-material/Circle";

const SessionInfoSection = () => {
   const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
   const [popoverText, setPopoverText] = useState("");
   const open = Boolean(anchorEl);

   const handlePopoverClose = () => setAnchorEl(null);

   const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      switch (event.currentTarget.id) {
         case "popover-1":
            setPopoverText(
               'El indicador "Número de sesiones realizadas" representa la cantidad total de reuniones que se llevaron a cabo independientemente de las convocadas.'
            );
            break;
         case "popover-2":
            setPopoverText(
               'El indicador "Número de sesiones convocadas" representa la cantidad total de reuniones que se planificaron, sin considerar si se llevaron a cabo o no.'
            );
            break;
         default:
            break;
      }

      setAnchorEl(event.currentTarget);
   };

   return (
      <Box bgcolor={"white"} borderColor={"#B5B5B5"} borderRadius={3}>
         <Tabs value={"1"} textColor="primary">
            <Tab label="Información de sesiones" value={"1"} />
         </Tabs>
         <Divider sx={{ borderBottomWidth: 2 }} />
         <TabPanel index={1} value={1}>
            <Stack direction={"row"} height={"350px"} p={2} display={"flex"} alignItems={"center"} spacing={2}>
               <Box flex={2} height={"100%"}>
                  <SessionInfoChart />
               </Box>
               <Stack flex={1} height={"100%"} py={5}>
                  <Box rowGap={3} display={"flex"} flexDirection={"column"} maxWidth={"200px"}>
                     <Typography fontWeight={600} fontSize={18}>
                        Indicadores:
                     </Typography>

                     <Box sx={{ display: "flex", columnGap: 1 }}>
                        <CircleIcon sx={{ color: "#152C44", alignSelf: "center" }} />
                        <Typography fontSize={14}>
                           Número de sesiones realizadas{" "}
                           <Box
                              id={"popover-1"}
                              onMouseEnter={handlePopoverOpen}
                              onMouseLeave={handlePopoverClose}
                              display={"inline"}
                           >
                              <InfoOutlinedIcon fontSize="small" />
                           </Box>
                        </Typography>
                     </Box>
                     <Box sx={{ display: "flex", columnGap: 1 }}>
                        <CircleIcon sx={{ color: "#5E77F9", alignSelf: "center" }} />
                        <Typography fontSize={14}>
                           Número de sesiones convocadas{" "}
                           <Box
                              id={"popover-2"}
                              onMouseEnter={handlePopoverOpen}
                              onMouseLeave={handlePopoverClose}
                              display={"inline"}
                           >
                              <InfoOutlinedIcon fontSize="small" />
                           </Box>
                        </Typography>
                     </Box>
                     <Popover
                        sx={{
                           pointerEvents: "none",
                           ".MuiPopover-paper": {
                              borderRadius: 2.5,
                              border: "1px solid #D9D9D9",
                              boxShadow: 0,
                           },
                        }}
                        open={open}
                        anchorEl={anchorEl}
                        onClose={handlePopoverClose}
                        disableRestoreFocus
                        anchorOrigin={{
                           vertical: "bottom",
                           horizontal: "center",
                        }}
                        transformOrigin={{
                           vertical: "top",
                           horizontal: "center",
                        }}
                     >
                        <Box p={2} maxWidth={500}>
                           <Typography sx={{ color: "#9CA1A7" }} fontSize={14}>
                              Información
                           </Typography>
                           <Typography align="justify" fontSize={14}>
                              {popoverText}
                           </Typography>
                        </Box>
                     </Popover>
                  </Box>
               </Stack>
            </Stack>
         </TabPanel>
      </Box>
   );
};

export default SessionInfoSection;
