import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../context/userContext";
import { getLogsByUserAndResource } from "../../../../lib/usersBEClient";
import { Box, CircularProgress, Divider, Grid, Typography } from "@mui/material";
import { FiberManualRecord } from "@mui/icons-material";
import { getDateAndTimeFromISODate } from "../../../../const/globalConst";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";

const useGetLogs = () => {
   const { user, selectedResources, isLoadingUser } = useContext(UserContext);
   const { refetch, companySelected } = useContext(GovernanceContext);
   const [logs, setLogs] = useState([]);
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      const fetchLogs = async () => {
         setIsLoading(true);
         const response = await getLogsByUserAndResource(user.id, selectedResources._id, companySelected);
         setLogs(response);
         setIsLoading(false);
      };
      if (user?.id && selectedResources && companySelected) fetchLogs();
   }, [refetch, selectedResources, companySelected, isLoadingUser, user]);

   return { logs, isLoading };
};

export const RecentMovements = () => {
   const { user } = useContext(UserContext);
   const { logs, isLoading } = useGetLogs();

   return (
      <Box sx={{ overflowY: "auto", p: 2, "::-webkit-scrollbar": { display: "none" } }}>
         {isLoading ? (
            <Box sx={{ display: "flex", justifyContent: "center" }}>
               <CircularProgress />
            </Box>
         ) : (
            (logs.length > 0 &&
               logs.map((log, i) => (
                  <Box key={i}>
                     <Grid container spacing={2}>
                        <Grid item container direction={"column"} xs={1}>
                           <Grid item xs={1} sx={{ display: "flex", justifyContent: "center" }}>
                              <FiberManualRecord sx={{ color: "#B8C5D0", fontSize: 20 }} />
                           </Grid>
                           <Grid item xs sx={{ display: "flex", justifyContent: "center" }}>
                              <Divider
                                 orientation="vertical"
                                 flexItem
                                 sx={{ borderRightWidth: 3, borderColor: "#B8C5D0" }}
                              />
                           </Grid>
                        </Grid>
                        <Grid item xs={11}>
                           <Box
                              sx={{
                                 bgcolor: "#F6F6F6",
                                 borderRadius: 2,
                                 p: 1,
                                 display: "flex",
                                 flexDirection: "column",
                                 rowGap: 1,
                              }}
                           >
                              <Typography variant="body2">
                                 {user.id === log.details.user._id
                                    ? getWord(log.title.split(" ")[0]) + " " + log.title.split(" ")[2]
                                    : log.title}
                              </Typography>
                              <Typography variant="caption" sx={{ color: "#898989" }}>
                                 {log.details.object} -{" "}
                                 {log.governingBody ? log.governingBody.name : "Panel de Usuario"}
                              </Typography>
                              {user.id === log.details.user._id ? null : (
                                 <Typography variant="caption" sx={{ color: "#898989" }}>
                                    Realizado por {log.details.user.firstName} {log.details.user.lastName}
                                 </Typography>
                              )}
                              <Typography variant="caption" sx={{ color: "#898989" }}>
                                 {getDateAndTimeFromISODate(log.createdAt)}
                              </Typography>
                           </Box>
                        </Grid>
                     </Grid>
                     <Divider sx={{ my: 1 }} />
                  </Box>
               ))) || <Typography>Sin registros</Typography>
         )}
      </Box>
   );
};

const getWord = (name: string) => {
   switch (name) {
      case "Creación":
         return "Creaste";
      case "Modificación":
         return "Modificaste";
      case "Eliminación":
         return "Eliminaste";
      default:
         break;
   }
};
