const UseCreateComplaintsMap = (complaints) => {
   const complaintsMap = new Map();
   if (!complaints) return complaintsMap;
   complaintsMap.set("todos", complaints);
   complaintsMap.set(
      "tableProceso",
      complaints.filter((e) => e.complaint.status === "en proceso" || e.complaint.status === "en valoración")
   );
   complaintsMap.set(
      "en proceso",
      complaints.filter((e) => e.complaint.status === "en proceso")
   );
   complaintsMap.set(
      "en valoración",
      complaints.filter((e) => e.complaint.status === "en valoración")
   );
   complaintsMap.set(
      "resuelto",
      complaints.filter((e) => e.complaint.status === "resuelto")
   );
   complaintsMap.set(
      "improcedente",
      complaints.filter((e) => e.complaint.status === "improcedente")
   );
   complaintsMap.set(
      "cerrada",
      complaints.filter((e) => e.complaint.status === "improcedente" || e.complaint.status === "cerrada")
   );
   return complaintsMap;
};

export default UseCreateComplaintsMap;
