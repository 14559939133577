import GetTableComponent from "../hooks/tables/getTableComponent";
import { HeadCell } from "../components/TableComponent";

const columns: HeadCell[] = [
   { field: "name", headerName: "Sucursal/establecimiento", type: "string" },
   { field: "complaints", headerName: "Denuncias Totales", type: "string" },
];

interface PropsCompaniesStatisticsTable {
   complaintsByBranch: any;
}

export const CompaniesStatisticsTable = (props: PropsCompaniesStatisticsTable) => {
   const rows =
      props.complaintsByBranch &&
      props.complaintsByBranch.map((branch) => ({
         name: branch.name.name,
         complaints: branch.complaints,
      }));

   return GetTableComponent({
      defaultOrder: "name",
      defaultText: "Esta empresa no cunta con sucursales",
      selectedRow: () => {},
      tableLoader: false,
      tableHeaders: columns,
      tableRow: rows,
      rowsPerPage: [2],
   });
};
