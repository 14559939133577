import { getMonthWord, imageLogo } from "../../const/globalConst";

export const constancyOfNonAppearance = (
   reportNumber: string,
   status: string,
   city: string,
   state: string,
   time: string,
   interviewDate: string,
   company: string,
   complainerName: string,
   ofCumOfficial: string
) => {
   const date = time.split("-");
   const dateInterview = interviewDate.split("-");
   const timeDate = date[2].split("T");
   const timeDateInterview = dateInterview[2].split("T");
   const dd = {
      pageSize: "A4",
      pageMargins: [40, 60, 40, 60],
      content: [
         {
            canvas: [
               {
                  type: "rect",
                  x: 105,
                  y: -30,
                  w: 300,
                  h: 60,
                  color: "#e7e9ec",
               },
               {
                  type: "rect",
                  x: 168,
                  y: 29,
                  w: 180,
                  h: 3,
                  color: "#152c44",
               },
            ],
         },
         {
            image: imageLogo,
            width: 100,
            absolutePosition: { x: 250, y: 45 },
         },

         {
            text: `CONSTANCIA DE INCOMPARECENCIA`,

            bold: true,
            fontSize: 14,
            alignment: "center",
            color: "#152c44",
            margin: [0, 100, 0, 10],
         },
         {
            canvas: [
               {
                  type: "rect",
                  x: 60,
                  y: -5,
                  w: 400,
                  h: 3.5,
                  color: "#152c44",
               },
            ],
         },
         {
            text: [
               {
                  text: "En la ciudad de ",
               },
               { text: `${city}, ${state} `, bold: true },
               {
                  text: "siendo las ",
               },
               { text: `${timeDate[1]} `, bold: true },
               {
                  text: `horas del día `,
               },
               { text: `${timeDate[0]} `, bold: true },
               {
                  text: `del mes `,
               },
               { text: `${getMonthWord(date[1])} `, bold: true },
               {
                  text: `del año `,
               },
               { text: `${date[0]} `, bold: true },
               {
                  text: "la organización ",
               },
               { text: `${company} `, bold: true },
               {
                  text: "hace constar la incomparecencia del C.",
               },
               { text: `${complainerName} `, bold: true },
               {
                  text: "a la cita de la entrevista de la entrevista para el esclarecimiento de los hechos de la denuncia con reporte ",
               },
               { text: `${reportNumber} `, bold: true },
               {
                  text: "en su calidad de ",
               },
               { text: `${status}, `, bold: true },
               {
                  text: "acto que le fue previamente notificado el día ",
               },
               {
                  text: `${timeDateInterview[0]} `,

                  bold: true,
               },
               {
                  text: "del mes ",
               },
               { text: `${getMonthWord(dateInterview[1])} `, bold: true },
               {
                  text: "del año ",
               },
               { text: `${dateInterview[0]} `, bold: true },
               {
                  text: ", tal y como consta en el expediente de la denuncia.",
               },
            ],
            fontSize: 11,
            alignment: "justify",

            margin: [45, 60, 45, 0],
            lineHeight: 1.1,
         },
         {
            text: "Por lo que se da por concluido el presente acto para los efectos a los que haya lugar.",

            fontSize: 11,
            alignment: "justify",
            margin: [45, 20, 45, 100],
            lineHeight: 1.1,
         },
         {
            canvas: [
               {
                  type: "rect",
                  x: 182,
                  y: 40,
                  w: 150,
                  h: 0.8,
                  color: "#152c44",
               },
            ],
         },
         {
            text: "Oficial de Cumplimiento",

            fontSize: 9,
            color: "#152c44",
            alignment: "center",
         },
         {
            text: `C. ${ofCumOfficial}`,

            fontSize: 10,
            color: "#152c44",
            alignment: "center",
            bold: true,
         },
      ],
   };

   return dd;
};

export const proofOfNonExistence = (reportNumber: string, type: string, date: string) => {
   const now = date.split("-");
   const timeDate = now[2].split("T");
   const dd = {
      pageSize: "A4",
      pageMargins: [40, 60, 40, 60],
      content: [
         {
            canvas: [
               {
                  type: "rect",
                  x: 105,
                  y: -30,
                  w: 300,
                  h: 60,
                  color: "#e7e9ec",
               },
               {
                  type: "rect",
                  x: 168,
                  y: 29,
                  w: 180,
                  h: 3,
                  color: "#152c44",
               },
            ],
         },
         {
            image: imageLogo,
            width: 100,
            absolutePosition: { x: 250, y: 45 },
         },

         {
            text: `CONSTANCIA DE INEXISTENCIA DE ${type.toLocaleUpperCase()}`,

            bold: true,
            fontSize: 14,
            alignment: "center",
            color: "#152c44",
            margin: [0, 100, 0, 10],
         },
         {
            canvas: [
               {
                  type: "rect",
                  x: 60,
                  y: -5,
                  w: 400,
                  h: 3.5,
                  color: "#152c44",
               },
            ],
         },
         {
            text: [
               {
                  text: "Por medio del presente documento se hace constar que dentro de la denuncia con el número de reporte ",
               },
               { text: `${reportNumber} `, bold: true },
               {
                  text: "interpuesta a las ",
               },
               { text: `${timeDate[1].substring(0, 5)} `, bold: true },
               {
                  text: `horas del día `,
               },
               { text: `${timeDate[0]} `, bold: true },
               {
                  text: `del mes `,
               },
               { text: `${getMonthWord(now[1])} `, bold: true },
               {
                  text: `del año `,
               },
               { text: `${now[0]}, `, bold: true },
               {
                  text: "no se desprende la existencia de una persona que funja como ",
               },
               { text: `${type}.`, bold: true },
            ],
            fontSize: 11,
            alignment: "justify",

            margin: [45, 60, 45, 0],
            lineHeight: 1.1,
         },
         {
            text: "En tal orden de ideas, la presente instancia del proceso de gestión de la denuncia se tendrá por agotada.",

            fontSize: 11,
            alignment: "justify",
            margin: [45, 20, 45, 100],
            lineHeight: 1.1,
         },
      ],
   };

   return dd;
};

export const anonimousComplainant = (reportNumber: string, date: string) => {
   const now = date.split("-");
   const timeDate = now[2].split("T");
   const dd = {
      pageSize: "A4",
      pageMargins: [40, 60, 40, 60],
      content: [
         {
            canvas: [
               {
                  type: "rect",
                  x: 105,
                  y: 60,
                  w: 300,
                  h: 20,
                  color: "#e7e9ec",
               },
               {
                  type: "rect",
                  x: 75,
                  y: 68,
                  w: 360,
                  h: 5,
                  color: "#152c44",
               },
            ],
         },
         {
            image: imageLogo,
            width: 160,
            absolutePosition: { x: 217.5, y: 40 },
         },

         {
            text: `DENUNCIANTE ANÓNIMO`,

            bold: true,
            fontSize: 14,
            alignment: "center",
            color: "#152c44",
            margin: [0, 60, 0, 10],
         },
         {
            canvas: [
               {
                  type: "rect",
                  x: 180,
                  y: -5,
                  w: 160,
                  h: 1,
                  color: "#152c44",
               },
            ],
         },
         {
            text: [
               {
                  text: "Por medio del presente documento se hace constar que dentro de la denuncia con el número de reporte ",
               },
               { text: `${reportNumber} `, bold: true },
               {
                  text: "interpuesta a las ",
               },
               { text: `${timeDate[1].substring(0, 5)} `, bold: true },
               {
                  text: `horas del día `,
               },
               { text: `${timeDate[0]} `, bold: true },
               {
                  text: `del mes `,
               },
               { text: `${getMonthWord(now[1])} `, bold: true },
               {
                  text: `del año `,
               },
               { text: `${now[0]}, `, bold: true },
               {
                  text: "se desprende que el denunciante es anónimo y, por lo tanto, no existen medios para contactarlo.",
               },
            ],
            fontSize: 11,
            alignment: "justify",

            margin: [45, 60, 45, 0],
            lineHeight: 1.1,
         },
         {
            text: "En tal orden de ideas, la presente instancia del proceso de gestión de la denuncia se tendrá por agotada, y se tomarán en cuenta los elementos proporcionados en la interposición de denuncia.",

            fontSize: 11,
            alignment: "justify",
            margin: [45, 20, 45, 100],
            lineHeight: 1.1,
         },
      ],
   };

   return dd;
};
