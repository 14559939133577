import { DeleteForever } from "@mui/icons-material";
import {
   Box,
   Chip,
   Collapse,
   FormControl,
   IconButton,
   InputLabel,
   MenuItem,
   Select,
   Stack,
   Typography,
} from "@mui/material";
import { useContext, useState } from "react";
import { AddUserContext } from "../../../context/addUserContext";

interface InputMapProps {
   name: string;
   roles: any[];
   service: string;
   module: any;
   branch?: boolean;
}

export const InputMap = ({ name, roles, service, module, branch }: InputMapProps) => {
   const { modules, addModuleOrRole, removeRole, removeBranch } = useContext(AddUserContext);
   const [selectedValue, setSelectedValue] = useState(null);

   return (
      <>
         <FormControl sx={{ my: 1, minWidth: 120 }} size="small" fullWidth>
            <InputLabel id="demo-select-small-label">Seleccionar {name}</InputLabel>
            <Select
               labelId="demo-select-small-label"
               id="demo-select-small"
               label={`Seleccionar ${name}`}
               name={name}
               value={selectedValue}
               // value={modules.filter((moduleD) => moduleD.module === module.service.id)}
               onChange={(event: any) => {
                  addModuleOrRole(module, event, branch);
                  setSelectedValue(event.target.value);
               }}
            >
               {roles.map((rol: any) => (
                  <MenuItem value={rol}>{rol.name}</MenuItem>
               ))}
            </Select>
         </FormControl>
         <Collapse
            in={
               (modules.filter((moduleD) => moduleD.module === service).length > 0 && !branch) ||
               (branch && modules.find((moduleD) => moduleD.module === service))
            }
            unmountOnExit
         >
            <Stack spacing={1} flexWrap={"wrap"} direction="row" rowGap={2}>
               {branch
                  ? modules.find((moduleD) => moduleD.module === service) &&
                    modules.find((moduleD) => moduleD.module === service)["branches"] &&
                    modules
                       .find((moduleD) => moduleD.module === service)
                       ["branches"].map((rol) => (
                          <Chip
                             sx={{
                                bgcolor: "#D2D2D2",
                                color: "#162c44",
                             }}
                             label={
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                   <Typography fontSize={13} fontWeight={500}>
                                      {modules.find((moduleD) => moduleD.module === service)["branches"].length > 1
                                         ? rol.name?.substring(0, 15) + "..."
                                         : rol.name}
                                   </Typography>
                                   <IconButton
                                      onClick={() => {
                                         removeBranch(module, rol._id);
                                         setSelectedValue(null);
                                      }}
                                   >
                                      <DeleteForever sx={{ color: "#162c44" }} />
                                   </IconButton>
                                </Box>
                             }
                          />
                       ))
                  : modules
                       .filter((moduleD) => moduleD.module === service && moduleD.role)
                       .map((rol) => (
                          <Chip
                             sx={{
                                bgcolor: "#D2D2D2",
                                color: "#162c44",
                             }}
                             label={
                                <Box sx={{ display: "flex", alignItems: "center" }}>
                                   <Typography fontSize={13} fontWeight={500}>
                                      {modules.filter((moduleD) => moduleD.module === service).length > 1
                                         ? rol.name?.substring(0, 15) + "..."
                                         : rol.name}
                                   </Typography>
                                   <IconButton
                                      onClick={() => {
                                         removeRole(module, rol.role);
                                         setSelectedValue(null);
                                      }}
                                   >
                                      <DeleteForever sx={{ color: "#162c44" }} />
                                   </IconButton>
                                </Box>
                             }
                          />
                       ))}
            </Stack>
         </Collapse>
      </>
   );
};
