import { Box, Button, Chip, Drawer, ListItemText, MenuItem, Stack, Toolbar, Typography } from "@mui/material";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { InputTextField } from "./Inputs/InputTextField";
import { InputSelectField } from "./Inputs/InputSelectField";
import DeleteIcon from "@mui/icons-material/Delete";

interface IDrawerComponent {
   anchor: "left" | "right";
   hideBackdrop?: boolean;
   children?: React.ReactNode; // any child independent to form
   open: boolean;
   setOpen: Function;
   variant?: "permanent" | "temporary";
   title?: string;
   formObject: any; //creates Formik initialValues based on object and infers types based on values from object
   labels?: string[]; // optional label for field, index must be equal to object property
   disabled?: boolean[]; // disables field, index must be equal to object property
   onClose?: Function; // callback when drawer is closed
   onSubmit: Function; // callback when submiting data
   selectorOptions?: any[]; //optional props for selector, best if changed
}

const DrawerComponent = (props: IDrawerComponent) => {
   const {
      open,
      setOpen,
      anchor,
      children,
      variant,
      title,
      formObject,
      labels,
      onClose,
      onSubmit,
      selectorOptions,
      hideBackdrop,
      disabled,
   } = props;
   const initialValues = formObject;

   const handleCloseDrawer = () => {
      onClose?.();
      setOpen(false);
   };

   const handleSubmit = (values) => onSubmit(values);

   return (
      <Drawer
         hideBackdrop={hideBackdrop || false}
         open={open}
         anchor={anchor}
         sx={{ maxWidth: 450 }}
         variant={variant || "temporary"}
      >
         <>
            <Toolbar />
            <Box sx={{ width: "380px", height: "100%", display: "flex", flexDirection: "row" }}>
               <Box
                  display="flex"
                  alignItems="center"
                  onClick={handleCloseDrawer}
                  sx={{
                     "&:hover": {
                        bgcolor: "#F7F7F7",
                     },
                  }}
               >
                  <ChevronRightIcon fontSize="small" />
               </Box>
               <Stack direction={"column"} flex={1} px={3} py={3}>
                  {title && (
                     <Typography fontWeight={600} fontSize={"18px"} pb={2}>
                        {title}
                     </Typography>
                  )}
                  <Formik onSubmit={handleSubmit} initialValues={initialValues}>
                     {(formProps: FormikProps<any>) => (
                        <Form>
                           {Object.keys(initialValues).map((key, i) => {
                              if (key.includes("id")) return;
                              return (
                                 <Box py={1.5} key={key}>
                                    <HandleInputType
                                       value={initialValues[key]}
                                       labelKey={key}
                                       label={labels?.[i] || key}
                                       disabled={disabled?.[i]}
                                       options={selectorOptions}
                                    />
                                 </Box>
                              );
                           })}
                           <Box width={"100%"} display={"flex"} justifyContent={"flex-end"} py={1}>
                              <Button variant="contained" type="submit">
                                 <Typography>Guardar</Typography>
                              </Button>
                           </Box>
                        </Form>
                     )}
                  </Formik>
                  {children}
               </Stack>
            </Box>
         </>
      </Drawer>
   );
};

interface IHandleInputType {
   value: any;
   labelKey: string;
   label: string;
   options?: any[];
   disabled?: boolean;
}
const HandleInputType = (props: IHandleInputType) => {
   const { value, label, labelKey, disabled, options } = props;
   const { setFieldValue, values } = useFormikContext();
   switch (typeof value) {
      case "string":
         return (
            <InputTextField
               id={labelKey}
               name={labelKey}
               label={label}
               type="standard"
               size={"small"}
               fullWidth
               disabled={disabled}
            />
         );
      case "object":
         return (
            <Box rowGap={0.5}>
               <InputSelectField
                  id={labelKey}
                  name={labelKey}
                  size={"small"}
                  label={label}
                  labelId={label}
                  fullWidth
                  defaultValue={""}
                  disabled={disabled}
                  onChange={(e) => setFieldValue(labelKey, e.target.value)}
                  renderValue={() => "Seleccionar rol"}
               >
                  {options.map((item) => {
                     return (
                        <MenuItem value={item} key={item}>
                           <ListItemText>{item}</ListItemText>
                        </MenuItem>
                     );
                  })}
               </InputSelectField>
               {values[labelKey] !== "" && (
                  <Chip
                     key={values[labelKey]}
                     label={values[labelKey]}
                     onDelete={() => setFieldValue(labelKey, "")}
                     deleteIcon={<DeleteIcon />}
                     sx={{ m: 0.5 }}
                  />
               )}
            </Box>
         );

      default:
         return <></>;
   }
};

export default DrawerComponent;
