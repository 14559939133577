import { Users } from "./BaseTypes";

export interface IComment {
   user: Users;
   content: string;
   createdAt: string;
}

export interface INotes {
   _id: string;
   title: string;
   description: string;
   tags: string[];
   users: Users;
   session?: ISession;
   comments: IComment[];
}

export enum TaskStatus {
   PENDING = "PENDING",
   PROCESS = "PROCESS",
   REVIEW = "REVIEW",
   COMPLETE = "COMPLETE",
   EXPIRED = "EXPIRED",
}

export enum TaskPriority {
   HIGH = "HIGH",
   MEDIUM = "MEDIUM",
   LOW = "LOW",
}

export interface ICommunData {
   title: string;
   description?: string;
   date: string;
   startTime: string;
   finishTime: string;
   users?: Users[];
}

export interface ICommitmens {
   _id: string;
   title: string;
   description?: string;
   status: TaskStatus;
   tags: string[];
   users: Users[] | { name: string; _id: string }[];
   session: ISession | string;
   comments: IComment[];
   dueDate: Date;
   files?: string[];
}

export interface ICommitmensData {
   _id: string;
   title?: string;
   description?: string;
   status: TaskStatus;
   tags?: string[];
   users?: string[];
   dueDate?: string;
   session?: string;
   comments: IComment[];
}

export interface ISession extends ICommunData {
   id?: string;
   _id?: string;
   location: string;
   tags?: ITags[];
   files: string[];
   meetingURL: string;
   comments: IComment[];
   order: { affair: string; description: string }[];
   owner?: string;
   externs?: IUserRegistry[];
   governance: string;
   companyName?: string;
   usersEmails?: any[];
   usersRegistry: IUserRegistry[];
   provider?: string;
   type?: string;
   assembly?: boolean;
   proclamation?: string;
   billCode?: string;
   completed?: boolean;
   company?: string;
   affairs?: IAffairs[];
   canceled?: boolean;
   userNotified?: string[];
   group?: boolean;
}

export interface IUserRegistry {
   user: string;
   name?: string;
   email?: string;
   status?: UserStatus;
   suggest?: ISuggest;
   specialty?: string;
   attended?: boolean;
   picture?: string;
}

export interface ISuggest {
   date?: string;
   startTime?: string;
   endTime?: string;
}

export enum UserStatus {
   PENDING = "PENDING",
   CANCELLED = "CANCELLED",
   APPROVED = "APPROVED",
   SUGGEST = "SUGGEST",
}

export interface IEvent extends ICommunData {
   id?: string;
   location: string;
   files: string[];
   owner?: string;
   tags?: ITags[];
   company: string;
   externs: string[];
   usersNotFormated: any[];
   ownerNotFormated: any;
   meetingURL: string;
}

export interface ITask {
   _id: string;
   title: string;
   status: TaskStatus;
   priority: string;
   limitDate: Date;
   dueDate?: Date;
   users: string[];
   description: string;
   owner: string;
   company: string;
   allowedComments: boolean;
   tags: ITags[];
   comments?: IComment[];
}

export interface ICalendar {
   session: ISession[];
   notes: INotes[];
   commitmens: ICommitmens[];
   event: IEvent[];
   task: ITask[];
}

export interface IGovernanceBody {
   _id: string;
   title: string;
   calendar?: string;
   subComites?: IGovernanceBody[];
   tags?: ITags[];
   company?: string;
   users?: any[];
   spaces?: string;
   structure?: any;
   functions?: string[];
}

export interface ITags {
   _id: string;
   color: string;
   title: string;
}

export interface IUserDetails {
   _id: string;
   gender: string;
   address: string;
   birthLocation: string;
   dob: string;
   calender?: ICalendar;
   disable?: boolean;
   profesionalData?: IProfesionalData;
   personalFiles?: IPersonalFiles;
   spaces?: string;
   tags?: ITags[];
   calendar?: string;
}

export interface IPersonalFiles {
   id: string;
   actaNacimiento: IFile;
   comprobanteDomicilio: IFile;
   cv: IFile;
   tituloProfesional: IFile;
   cedulaProfesional: IFile;
   cartaRecomendacion: IFile;
   historialCrediticio: IFile;
}

export interface IProfesionalData {
   company: string;
   positions: string[];
   studyRecord: IStudyRecord[];
   experienceRecord: IExperienceRecord[];
}

export interface IStudyRecord {
   title: string;
   intitution: string;
   startTime: Date;
   endTime: Date;
   degree?: string;
}

export interface IExperienceRecord {
   title: string;
   intitution: string;
   startTime: Date;
   endTime: Date;
}

export interface IFile {
   name: string;
   size: string;
}

export interface IMessage {
   authorId: string;
   content: string;
   conversation: string;
   createdAt: string;
   updatedAt: string;
   usersRead: string[];
   file?: string;
   _id: string;
}

export interface IConversation {
   createdAt: string;
   lastMessage: IMessage;
   participants: Users[];
   title: any;
   type: string;
   updatedAt: string;
   _id: string;
}

export interface ILaboralExperience {
   title: string;
   institution: string;
   startTime: string;
   endTime: string;
}

export interface IProfesionalStudies {
   title: string;
   degree: string;
   institution: string;
   startTime: string;
   endTime: string;
}
export interface INotification {
   description: string;
   date: string;
}

export interface ISign {
   sign: string;
   userId: string;
   userName: string;
}

export interface IMembersToVerify {
   file: string;
   request: string;
   user: Users;
   external: boolean;
}

export interface IMembersWithCharges {
   name?: string;
   firstName?: string;
   lastName?: string;
   memberCharge?: string[];
   _id?: string;
   attended?: boolean;
   email?: string;
   status?: string;
   suggest?: any;
   user?: string;
   series?: { title: string; votes: number; sharesAmount: number }[];
   totalCapital?: number;
   profilePic?: string;
}

export interface IAffairs {
   _id: string;
   title: string;
   companyId: string;
   series: { title: string; faculties: number; createdAt: Date; updatedAt: Date }[];
   classification: string;
   description: string;
   totalVotes?: number;
   vote?: string;
   votes?: number;
   totalVotesUsed?: number;
   status?: string;
   orderId: string;
   linkedVote?: any;
   companies?: string[];
}

export interface IAdditionalVotes {
   _id: string;
   title: string;
   votes: { userId: string; userName: string; vote: string }[];
   affair?: string;
   agreed: number;
   disagreed: number;
   nullVote: number;
   canceled: boolean;
   totalVotes?: number;
   totalVotesUsed?: number;
   positiveVotes?: number;
   status?: string;
}
