import { Box, CircularProgress, Grid, List, ListItem, ListItemText, Modal, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { getPlatformInfoByCompanyId, getS3LastModifiedDate, getUrlS3 } from "../../lib/usersBEClient";
import { getDateFromISODate } from "../../const/globalConst";
import { Form, Formik } from "formik";
import { InputImage } from "../Inputs/InputImage";
import { UseGetPresignedURLUpload, uploadFileToS3 } from "../../lib/s3Client";
import { InputFile } from "../Inputs/InputFile";
import { FileWithDateInterface } from "../../types/BaseTypes";
import { Info } from "@mui/icons-material";
import { CustomModalComponent } from "../CustomModalComponent";
import { SuggestionsModal } from "./SuggestionsModal";
import { SnackBarContext } from "../../context/snackBarContext";

interface ImageFlyerInterface {
   file: any;
   name: string;
   fileType: string;
}

interface PlatformInfoComponentProps {
   company?: any;
}
interface IPlatformInfo {
   _id: string;
   subName: string;
   color: string;
   flyers: [];
   inter: boolean;
   updatedAt: Date;
   createdAt: Date;
   message: string;
   privacy_policies: string;
   suggestions: string[];
}

export const PlatformInfoComponent = (props: PlatformInfoComponentProps) => {
   const { user, companySelected } = useContext(UserContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [isLoading, setIsLoading] = useState<boolean>(true);
   const [userLimit, setUserLimit] = useState<number>();
   const [licensesAvailable, setLicensesAvailable] = useState<number>();
   const [platformInfoData, setPlatformInfoData] = useState<IPlatformInfo>();
   const [flyerPoliciesImageBlob, setFlyerPoliciesImageBlob] = useState<FileWithDateInterface>(null);
   const [flyerMediaImageBlob, setFlyerMediaImageBlob] = useState<FileWithDateInterface>(null);
   const [flyerProcessImageBlob, setFlyerProcessImageBlob] = useState<FileWithDateInterface>(null);
   const [uploadedPoliciesBlob, setUploadedPoliciesBlob] = useState<FileWithDateInterface>(null);
   const [openMedia, setOpenMedia] = useState(false);
   const [openProcess, setOpenProcess] = useState(false);
   const [openPolicies, setOpenPolicies] = useState(false);
   const [editingFile, setEditingFile] = useState<ImageFlyerInterface>(null);
   const [suggestions, setSuggestions] = useState<string[]>([]);
   // const { clientInfo, refreshClientInfo } = useContext(ClientConfigContext);

   const getInfoS3 = async (clientId: string) => {
      const policies: string = await getUrlS3("images-lecosy", { folder: clientId }, "policies.pdf");
      const policiesFileDate: Date = await getS3LastModifiedDate("images-lecosy", { folder: clientId }, "policies.pdf");

      const policiesFlyer: string = await getUrlS3(
         "files-lecosy",
         { folder: `diffusionCamps/${clientId}` },
         "policiesFlyer.png"
      );
      const policiesDate: Date = await getS3LastModifiedDate(
         "files-lecosy",
         { folder: `diffusionCamps/${clientId}` },
         "policiesFlyer.png"
      );

      const mediaFlyer: string = await getUrlS3(
         "files-lecosy",
         { folder: `diffusionCamps/${clientId}` },
         "mediaFlyer.png"
      );

      const mediaDate: Date = await getS3LastModifiedDate(
         "files-lecosy",
         { folder: `diffusionCamps/${clientId}` },
         "mediaFlyer.png"
      );

      const processFlyer: string = await getUrlS3(
         "files-lecosy",
         { folder: `diffusionCamps/${clientId}` },
         "processFlyer.png"
      );

      const processDate: Date = await getS3LastModifiedDate(
         "files-lecosy",
         { folder: `diffusionCamps/${clientId}` },
         "processFlyer.png"
      );

      try {
         setFlyerPoliciesImageBlob({ file: policiesFlyer, lastDateModified: policiesDate });
         setFlyerMediaImageBlob({ file: mediaFlyer, lastDateModified: mediaDate });
         setFlyerProcessImageBlob({ file: processFlyer, lastDateModified: processDate });
         await fetch(policies).then(async (data) => {
            const newBlob = await data.blob();
            const file = new File([newBlob], "politicas.pdf", { type: "application/pdf" });
            setUploadedPoliciesBlob({ file: file, lastDateModified: policiesFileDate });
         });
      } catch (error) {}
   };

   const fetchData = async () => {
      let userLicenses = 0;
      const platformInfoRes: IPlatformInfo = await getPlatformInfoByCompanyId(companySelected._id);
      if (!platformInfoRes) return showSnackBar("Error al obtener información de canal de denuncias", true);
      const foundPlatformService = companySelected.company_details.servicesDetails.find(
         (service) => service.details?.platformId
      );
      if (!foundPlatformService) return showSnackBar("Error al obtener información de canal de denuncias", true);
      userLicenses = foundPlatformService.userLimit;
      getInfoS3(companySelected._id);
      setUserLimit(userLicenses);
      setPlatformInfoData(platformInfoRes);
      setSuggestions(platformInfoRes.suggestions);
      setIsLoading(false);
   };

   const getLicenses = (company) => {
      const roles: any[] = [];
      const usersIds: string[] = [];
      const usersArray = company.company_details.users;
      for (const users of usersArray) {
         for (const access of users.role) {
            if (usersIds.includes(users._id)) break;
            for (const rol of access.roles) {
               roles.push(rol);
               usersIds.push(users._id);
            }
         }
      }
      const rolesByCD = roles.filter(
         (rol) => rol.name === "Auditor" || rol.name === "Director" || rol.name === "Oficial de cumplimiento"
      );
      setLicensesAvailable(rolesByCD ? rolesByCD.length : 0);
   };

   useEffect(() => {
      if (props.company) {
         if (!licensesAvailable) getLicenses(props.company);
         if (!platformInfoData) fetchData();
      }
      // eslint-disable-next-line
   }, [user, props.company]);

   useEffect(() => {
      const handleSubmitFiles = async () => {
         const fileUrl = await UseGetPresignedURLUpload({
            bucket: editingFile.fileType === "pdf" ? "images-lecosy" : "files-lecosy",
            folder: editingFile.fileType === "pdf" ? `${props.company._id}` : `diffusionCamps/${props.company._id}`,
            name: `${editingFile.name}.${editingFile.fileType}`,
         });
         await uploadFileToS3(fileUrl.data, editingFile.file);
         setEditingFile(null);
      };
      if (editingFile) handleSubmitFiles();
   }, [editingFile]);

   const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
   const open = Boolean(anchorEl);
   const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => setAnchorEl(event.currentTarget);
   const handlePopoverClose = () => setAnchorEl(null);
   // const suggestionsArray = clientInfo.company_details.servicesDetails.find((service) => service.details.platformId).details?.platformId?.suggestions;
   const suggestionsArray = [
      "Lorem ipsum dolor sit amet consectetur, adipisicing elit. Perferendis excepturi repellat nulla sequi velit minima? Commodi, enim corrupti a vel, possimus iure ducimus eaque cumque quia labore qui, odit delectus.",
      "Hola2",
      "Hola3",
      "Hola4",
      "Hola5",
      "Hola6",
      "Hola7",
      "Hola8",
      "Hola9",
   ];
   const [openModal, setOpenModal] = useState(false);

   return (
      <Box
         display="flex"
         flexDirection={"column"}
         p={4}
         sx={{
            borderBottom: 1,
            borderColor: "divider",
            borderBottomRightRadius: 5,
            borderBottomLeftRadius: 5,
         }}
         boxShadow={2}
         bgcolor="white"
      >
         <>
            {!isLoading ? (
               <Formik initialValues={{}} onSubmit={() => {}}>
                  {!platformInfoData ? (
                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: "column",
                           justifyContent: "center",
                           alignItems: "center",
                           height: 100,
                           width: "100%",
                        }}
                     >
                        <Info sx={{ fontSize: 60, color: "#E0E0E0" }} />
                        <Typography sx={{ color: "#E0E0E0", py: 1 }}>Sin información para mostrar</Typography>
                     </Box>
                  ) : (
                     <Form>
                        <Grid container spacing={2} direction={"row"} sx={{ mb: 2 }}>
                           <Grid item>
                              <Grid item xs={4} container direction={"column"}>
                                 <Typography fontWeight={600} fontSize={16} width={"180px"}>
                                    Acceso a plataforma:
                                 </Typography>
                                 <Typography mb={2}>
                                    {platformInfoData.subName ? platformInfoData.subName : ""}
                                 </Typography>
                              </Grid>
                              <Grid item xs={4} container direction={"column"}>
                                 <Typography fontWeight={600} fontSize={16} mb={1} width={"180px"}>
                                    Políticas de privacidad:
                                 </Typography>
                                 <Box maxWidth={"100%"}>
                                    {!isLoading ? (
                                       <>
                                          <InputFile
                                             file={uploadedPoliciesBlob?.file}
                                             setFile={setUploadedPoliciesBlob}
                                             onChange={setEditingFile}
                                             keyName="policies"
                                          />
                                          <Typography fontSize={12} mt={1}>
                                             {`Últ. modificación: ${
                                                uploadedPoliciesBlob?.lastDateModified
                                                   ? getDateFromISODate(uploadedPoliciesBlob?.lastDateModified)
                                                   : "N/A"
                                             }`}
                                          </Typography>
                                       </>
                                    ) : (
                                       <CircularProgress size={24} />
                                    )}
                                 </Box>
                              </Grid>
                           </Grid>
                           <Grid item lg={4}>
                              <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
                                 <Typography pb={1}>Listado de sugerencias:</Typography>
                                 <List
                                    component="nav"
                                    onClick={() => {
                                       setOpenModal(true);
                                    }}
                                    sx={{
                                       backgroundColor: "white",
                                       width: 200,
                                       height: 130,
                                       border: 1,
                                       borderColor: "#B8B8B8",
                                       borderRadius: 1,
                                       ":hover": {
                                          cursor: "pointer",
                                       },
                                    }}
                                 >
                                    {suggestions.length === 0 ? (
                                       <ListItem>
                                          <ListItemText
                                             sx={{ textAlign: "center" }}
                                             primary="No hay sugerencias"
                                             primaryTypographyProps={{ fontSize: "15px" }}
                                          />
                                       </ListItem>
                                    ) : (
                                       suggestions.slice(0, 4).map((suggestion, index) => (
                                          <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }} key={index}>
                                             <ListItemText
                                                sx={{ textAlign: "center", fontSize: "10px" }}
                                                primary={`${index + 1}. ${
                                                   suggestion.length > 17 ? suggestion.slice(0, 16) + "..." : suggestion
                                                }`}
                                                primaryTypographyProps={{ fontSize: "14px", lineHeight: 1 }}
                                             />
                                          </ListItem>
                                       ))
                                    )}
                                    {suggestions.length > 4 && (
                                       <ListItem sx={{ paddingTop: 0, paddingBottom: 0 }}>
                                          <ListItemText
                                             sx={{ textAlign: "center" }}
                                             primary="Mostrar todo"
                                             primaryTypographyProps={{ fontSize: "14px", lineHeight: 1 }}
                                          />
                                       </ListItem>
                                    )}
                                 </List>
                              </Box>
                           </Grid>
                           <Grid item xs={4} container direction={"column"}>
                              <Typography fontWeight={600} fontSize={16} mb={1}>
                                 Mensaje de bienvenida:
                              </Typography>
                              <Box
                                 sx={{
                                    border: 1,
                                    borderColor: "#868FA0",
                                    borderRadius: 2,
                                    p: 1,
                                    overflow: "auto",
                                 }}
                              >
                                 {platformInfoData.message.length > 90 ? (
                                    <>
                                       <Typography
                                          fontSize={"14px"}
                                          height={112}
                                          aria-owns={open ? "mouse-over-popover" : undefined}
                                          aria-haspopup="true"
                                          onMouseEnter={handlePopoverOpen}
                                          onMouseLeave={handlePopoverClose}
                                       >
                                          {platformInfoData.message ? platformInfoData.message : "No contiene datos"}
                                       </Typography>
                                    </>
                                 ) : (
                                    platformInfoData.message
                                 )}
                              </Box>
                           </Grid>
                           <Grid item xs={12}>
                              <Typography fontWeight={600} fontSize={16}>
                                 Flyers de campañas de difusión
                              </Typography>
                           </Grid>
                           <Grid item xs={4} container direction={"column"} display={"flex"} alignItems={"center"}>
                              <Typography fontSize={14} mb={1}>
                                 Medios para interponer una denuncia
                              </Typography>
                              {!isLoading ? (
                                 <>
                                    <InputImage
                                       keyName="mediaFlyer"
                                       image={flyerMediaImageBlob?.file}
                                       setImage={setFlyerMediaImageBlob}
                                       onClick={async () => setOpenMedia(true)}
                                       onChange={setEditingFile}
                                    />
                                    <Typography fontSize={"12px"} mt={1}>{`Últ. modificación: ${
                                       flyerMediaImageBlob?.lastDateModified
                                          ? getDateFromISODate(flyerMediaImageBlob?.lastDateModified)
                                          : "N/A"
                                    }`}</Typography>
                                    <Modal
                                       autoFocus={false}
                                       open={openMedia}
                                       onClose={() => {
                                          setOpenMedia(false);
                                       }}
                                       sx={{ display: "flex" }}
                                    >
                                       <Box sx={{ m: "auto", maxWidth: "60%" }}>
                                          <Box
                                             component={"img"}
                                             width={"100%"}
                                             maxHeight={600}
                                             src={
                                                flyerMediaImageBlob?.file &&
                                                (typeof flyerMediaImageBlob?.file === "string"
                                                   ? flyerMediaImageBlob?.file
                                                   : URL.createObjectURL(flyerMediaImageBlob?.file))
                                             }
                                          />
                                       </Box>
                                    </Modal>
                                 </>
                              ) : (
                                 <Box width={"100%"} display="flex" justifyContent={"center"} p={1}>
                                    <CircularProgress sx={{ alignSelf: "center" }} />
                                 </Box>
                              )}
                           </Grid>
                           <Grid item xs={4} container direction={"column"} display={"flex"} alignItems={"center"}>
                              <Typography fontSize={14} mb={1}>
                                 Proceso de gestión de denuncia
                              </Typography>
                              {!isLoading ? (
                                 <>
                                    <InputImage
                                       keyName="processFlyer"
                                       image={flyerProcessImageBlob?.file}
                                       setImage={setFlyerProcessImageBlob}
                                       onClick={async () => setOpenProcess(true)}
                                       onChange={setEditingFile}
                                    />
                                    <Typography fontSize={"12px"} mt={1}>{`Últ. modificación: ${
                                       flyerProcessImageBlob?.lastDateModified
                                          ? getDateFromISODate(flyerProcessImageBlob?.lastDateModified)
                                          : "N/A"
                                    }`}</Typography>
                                    <Modal
                                       autoFocus={false}
                                       open={openProcess}
                                       onClose={() => {
                                          setOpenProcess(false);
                                       }}
                                       sx={{ display: "flex" }}
                                    >
                                       <Box sx={{ m: "auto", maxWidth: "60%" }}>
                                          <Box
                                             component={"img"}
                                             width={"100%"}
                                             maxHeight={600}
                                             src={
                                                flyerProcessImageBlob?.file &&
                                                (typeof flyerProcessImageBlob?.file === "string"
                                                   ? flyerProcessImageBlob?.file
                                                   : URL.createObjectURL(flyerProcessImageBlob?.file))
                                             }
                                          />
                                       </Box>
                                    </Modal>
                                 </>
                              ) : (
                                 <Box width={"100%"} display="flex" justifyContent={"center"} p={1}>
                                    <CircularProgress sx={{ alignSelf: "center" }} />
                                 </Box>
                              )}
                           </Grid>
                           <Grid item xs={4} container direction={"column"} display={"flex"} alignItems={"center"}>
                              <Typography fontSize={14} mb={1}>
                                 Políticas del canal de denuncias
                              </Typography>
                              {!isLoading ? (
                                 <>
                                    <InputImage
                                       keyName="policiesFlyer"
                                       image={flyerPoliciesImageBlob?.file}
                                       setImage={setFlyerPoliciesImageBlob}
                                       onClick={async () => setOpenPolicies(true)}
                                       onChange={setEditingFile}
                                    />
                                    <Typography fontSize={"12px"} mt={1}>{`Últ. modificación: ${
                                       flyerPoliciesImageBlob?.lastDateModified
                                          ? getDateFromISODate(flyerPoliciesImageBlob?.lastDateModified)
                                          : "N/A"
                                    }`}</Typography>
                                    <Modal
                                       autoFocus={false}
                                       open={openPolicies}
                                       onClose={() => {
                                          setOpenPolicies(false);
                                       }}
                                       sx={{ display: "flex" }}
                                    >
                                       <Box sx={{ m: "auto", maxWidth: "60%" }}>
                                          <Box
                                             component={"img"}
                                             width={"100%"}
                                             maxHeight={600}
                                             src={
                                                flyerPoliciesImageBlob?.file &&
                                                (typeof flyerPoliciesImageBlob?.file === "string"
                                                   ? flyerPoliciesImageBlob?.file
                                                   : URL.createObjectURL(flyerPoliciesImageBlob?.file))
                                             }
                                          />
                                       </Box>
                                    </Modal>
                                 </>
                              ) : (
                                 <Box width={"100%"} display="flex" justifyContent={"center"} p={1}>
                                    <CircularProgress sx={{ alignSelf: "center" }} />
                                 </Box>
                              )}
                           </Grid>
                           <Grid item xs={12}>
                              <Typography fontWeight={600} fontSize={16}>
                                 Licencias
                              </Typography>
                           </Grid>
                           <Grid item xs={4} container direction={"column"} display={"flex"} alignItems={"center"}>
                              <Typography fontSize={14} fontWeight={600}>
                                 Licencias totales:
                              </Typography>
                              <Typography fontSize={14}>{userLimit}</Typography>
                           </Grid>
                           <Grid item xs={4} container direction={"column"} display={"flex"} alignItems={"center"}>
                              <Typography fontSize={14} fontWeight={600}>
                                 Licencias disponibles:
                              </Typography>
                              <Typography fontSize={14}>
                                 {userLimit - licensesAvailable <= 0 ? 0 : userLimit - licensesAvailable}
                              </Typography>
                           </Grid>
                           <Grid item xs={4} container direction={"column"} display={"flex"} alignItems={"center"}>
                              <Typography fontSize={14} fontWeight={600}>
                                 Fecha de registro:
                              </Typography>
                              <Typography fontSize={14}>{getDateFromISODate(platformInfoData.createdAt)}</Typography>
                           </Grid>
                        </Grid>
                     </Form>
                  )}
               </Formik>
            ) : (
               <CircularProgress sx={{ alignSelf: "center", width: "34px" }} />
            )}
         </>
         <CustomModalComponent open={openModal} setOpen={setOpenModal} title={`Lista de sugerencias`} timeStamp={true}>
            <Box
               sx={{
                  width: "600px",
                  height: "500px",
                  overflowY: "auto",
                  minWidth: "600px",
               }}
               minWidth={"600px"}
            >
               <SuggestionsModal
                  suggestions={suggestions}
                  setSuggestions={setSuggestions}
                  onClose={() => {
                     setOpenModal(false);
                  }}
                  isOpen={openModal}
               />
            </Box>
         </CustomModalComponent>
      </Box>
   );
};
