import { Box, Button, Chip, CircularProgress, Grid, Typography } from "@mui/material";
import { InputTextField } from "../Inputs/InputTextField";
import { Form, Formik, FormikProps } from "formik";
import { UserSearchSelector } from "../Inputs/UserSearchSelector";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { CargoSelectorComponent } from "./CargoSelectorComponent";
import { SnackBarContext } from "../../context/snackBarContext";
import { userGoverningBody } from "../../lib/validations/inputSchemas";
import { CharacterSelectComponent } from "../Inputs/CharacterSelectComponent";
import { addGovernBodyUser, addUserGovernBody } from "../../lib/gobCorpBEClient";
import {
   addGovernBodyRole,
   getAllResources,
   getGroupUsers,
   getRoleByCharge,
   getUsersDetails,
} from "../../lib/usersBEClient";
import { BodyUserFormat } from "./GoverningBodiesComponent";

interface NewUserGovernBodyProps {
   company: string;
   group: string;
   bodyData: any;
   bodyUsers: BodyUserFormat[];
   state: boolean;
   setState: Function;
   setSeed: Function;
}

const UsefetchCompanyUsers = (bodyUsers, group) => {
   const { companySelected } = useContext(UserContext);
   const [isLoading, setIsLoading] = useState(false);
   const [users, setUsers] = useState([]);
   const [showUsers, setShowUsers] = useState([]);

   useEffect(() => {
      const fetchCompanyUsers = async () => {
         setIsLoading(true);
         const forShowUsers = bodyUsers.filter((u) => u.chargeName !== "Usuario de implementacion");
         setShowUsers(forShowUsers);

         let usersData = [];
         if (group) {
            const usersResponse = await getGroupUsers(group);
            usersData = usersResponse;
         } else {
            usersData = [...companySelected.company_details.users];
            if (companySelected.company_details.admin) usersData.push(companySelected.company_details.admin as any);
         }
         const users = (usersData as any[]).map((user) => ({
            _id: user._id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phoneNumber: user.phoneNumber,
         }));

         const usersIds = users.map((u) => u._id);
         const usersDetails = await getUsersDetails(usersIds);

         let usersFormat = [];
         for (let t = 0; t < users.length; t++) {
            usersFormat.push({
               ...usersDetails.find((e) => e.user === users[t]._id),
               ...users[t],
            });
         }
         const bodyUsersIds = bodyUsers.map((e) => e.user);
         const filterUsers: BodyUserFormat[] = usersFormat.filter(
            (user) => user.disabled === false && !bodyUsersIds.includes(user._id)
         );
         setUsers(filterUsers);
         setIsLoading(false);
      };
      fetchCompanyUsers();
   }, [bodyUsers]);

   return { users, isLoading, showUsers };
};

export const NewUserGovernBody = (props: NewUserGovernBodyProps) => {
   const { users, isLoading, showUsers } = UsefetchCompanyUsers(props.bodyUsers, props.group);
   const [submitLoading, setSubmitLoading] = useState(false);
   const { showSnackBar } = useContext(SnackBarContext);

   const handleSubmit = async (values) => {
      try {
         setSubmitLoading(true);
         const entityId = props.company ? props.company : props.group;
         if (values.charge === "659f11befb579847f88877ce") {
            await addGovernBodyUser(entityId, values.userId, "659f11befb579847f88877ce");
         }
         if (values.charge === "66300f211fc117a6154a3f7a") {
            await addGovernBodyUser(entityId, values.userId, "66300f211fc117a6154a3f7a");
         }
         const resourceResponse = await getAllResources();
         const resourceId = resourceResponse.find((r) => r.name === "Mi Lecosy")._id;
         if (!resourceId) return showSnackBar("Error al eliminar usuario", true);
         const userData = {
            user: values.userId,
            charge: values.charge,
            characters: values.character,
            specialPermission: values.specialP,
            addedDate: new Date(),
         };
         await addUserGovernBody(props.bodyData.bodyId, userData, entityId, resourceId);

         const roleId = await getRoleByCharge(values.charge);
         const roleData = {
            role: roleId._id,
            company: props.company,
            group: props.group,
            user: values.userId,
         };
         await addGovernBodyRole(roleData, props.bodyData.bodyId);
         setSubmitLoading(false);
         props.setState(false);
         showSnackBar("Usuario agregado correctamente", false);
         props.setSeed(Math.random());
      } catch (error) {
         console.log(error);
         showSnackBar("Error al agregar usuario", true);
      }
   };

   return (
      <Box sx={{ display: "flex", flexDirection: "column", width: 700, justifySelf: "center", overflow: "auto" }}>
         <Formik
            initialValues={{
               userId: "",
               firstName: "",
               lastName: "",
               phoneNumber: "",
               email: "",
               charge: "",
               character: [],
               specialP: false,
            }}
            onSubmit={handleSubmit}
            validationSchema={userGoverningBody}
         >
            {(formProps: FormikProps<any>) => (
               <Form>
                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: "column",
                        width: 700,
                        maxHeight: 450,
                        overflowY: "auto",
                        justifySelf: "center",
                     }}
                  >
                     <Box
                        sx={{
                           display: "flex",
                           flexDirection: "column",
                           width: "100%",
                           maxHeight: 550,
                           px: 6,
                           py: 2,
                           rowGap: 2,
                           bgcolor: "white",
                        }}
                     >
                        <Grid container>
                           <Grid item xs={12} sx={{ pl: 1 }}>
                              <Typography variant="body2" fontWeight={600}>
                                 Nombre del órgano de gobierno
                              </Typography>
                           </Grid>
                           <Grid item xs={12} sx={{ p: 1 }}>
                              <InputTextField
                                 fullWidth
                                 placeholder={props.bodyData.title}
                                 id="bodyTitle"
                                 name="bodyTitle"
                                 type="text"
                                 size="small"
                                 disabled
                              />
                           </Grid>
                           {showUsers.length === 0 ? null : (
                              <Grid item xs={12} sx={{ p: 1 }}>
                                 <Typography variant="body2" fontWeight={600} sx={{ mb: 1 }}>
                                    Miembros registrados
                                 </Typography>
                                 <Box
                                    sx={{
                                       display: "flex",
                                       border: 1,
                                       borderRadius: 1.5,
                                       p: 1,
                                       borderColor: "#BFC1C5",
                                       overflow: "auto",
                                       height: 65,
                                       maxWidth: "100%",
                                    }}
                                 >
                                    {showUsers.map((u, i) => (
                                       <Chip key={i} label={u.firstName + " " + u.lastName} sx={{ m: 0.5 }} />
                                    ))}
                                 </Box>
                              </Grid>
                           )}
                           <Grid item xs={12} sx={{ p: 1, my: 1 }}>
                              <UserSearchSelector
                                 users={users}
                                 isLoading={isLoading}
                                 label="Añadir usuario existente"
                                 setSelectedUser={(user) => {
                                    if (!user) {
                                       formProps.setFieldValue("userId", "");
                                       formProps.setFieldValue("firstName", "");
                                       formProps.setFieldValue("lastName", "");
                                       formProps.setFieldValue("phoneNumber", "");
                                       formProps.setFieldValue("email", "");
                                       formProps.setFieldValue("specialP", false);
                                    } else {
                                       formProps.setFieldValue("userId", user._id);
                                       formProps.setFieldValue("firstName", user.firstName);
                                       formProps.setFieldValue("lastName", user.lastName);
                                       formProps.setFieldValue("phoneNumber", user.phoneNumber);
                                       formProps.setFieldValue("email", user.email);
                                       formProps.setFieldValue("specialP", user.specialPermission || false);
                                    }
                                 }}
                              />
                           </Grid>
                           <Grid item xs={12} sx={{ pl: 1 }}>
                              <Typography variant="body2" fontWeight={600}>
                                 Información del usuario
                              </Typography>
                           </Grid>
                           <Grid item xs={6} sx={{ p: 1, my: 1 }}>
                              <InputTextField
                                 fullWidth
                                 placeholder="Nombre"
                                 id="firstName"
                                 name="firstName"
                                 type="text"
                                 size="small"
                                 disabled
                              />
                           </Grid>
                           <Grid item xs={6} sx={{ p: 1, my: 1 }}>
                              <InputTextField
                                 fullWidth
                                 placeholder="Apellido"
                                 id="lastName"
                                 name="lastName"
                                 type="text"
                                 size="small"
                                 disabled
                              />
                           </Grid>
                           <Grid item xs={6} sx={{ p: 1, my: 1 }}>
                              <InputTextField
                                 fullWidth
                                 placeholder="Telefono"
                                 id="phoneNumber"
                                 name="phoneNumber"
                                 type="text"
                                 size="small"
                                 disabled
                              />
                           </Grid>
                           <Grid item xs={6} sx={{ p: 1, my: 1 }}>
                              <InputTextField
                                 fullWidth
                                 placeholder="Correo electronico"
                                 id="email"
                                 name="email"
                                 type="email"
                                 size="small"
                                 disabled
                              />
                           </Grid>
                           <Grid item xs={12} sx={{ p: 1, my: 1 }}>
                              <CargoSelectorComponent
                                 governData={props.bodyData}
                                 governUsers={props.bodyUsers}
                                 companyUsers={users}
                                 isLoading={isLoading}
                                 label="Cargo a desempeñar"
                                 setSelectedRole={(charge) => {
                                    if (!charge) {
                                       formProps.setFieldValue("charge", "");
                                    } else {
                                       formProps.setFieldValue("charge", charge._id);
                                    }
                                 }}
                              />
                           </Grid>
                           <Grid item xs={12}>
                              <CharacterSelectComponent
                                 label="Caracter"
                                 id="character"
                                 name="character"
                                 setCharacters={(c) => {
                                    formProps.setFieldValue("character", typeof c === "string" ? c.split(",") : c);
                                 }}
                              />
                           </Grid>
                        </Grid>
                     </Box>
                  </Box>
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        bgcolor: "#F3F3F3",
                        width: "100%",
                        p: 1,
                        height: 50,
                        borderBottomRightRadius: 5,
                        borderBottomLeftRadius: 5,
                     }}
                  >
                     <Button
                        onClick={() => {
                           props.setState(false);
                        }}
                        sx={{ color: "black", height: 35, ":hover": { bgcolor: "#E5E6EB" }, mr: 2 }}
                     >
                        Cancelar
                     </Button>
                     <Button type="submit" sx={{ height: 35, ":hover": { bgcolor: "success" } }}>
                        {!submitLoading ? "Guardar" : <CircularProgress size={24} color="success" />}
                     </Button>
                  </Box>
               </Form>
            )}
         </Formik>
      </Box>
   );
};
