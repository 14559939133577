import { AddCircle } from "@mui/icons-material";
import { Box, IconButton, Typography } from "@mui/material";
import { CheckBoxComponent } from "../../Inputs/CheckBoxComponent";
import { useContext, useState } from "react";
import { TagsModal } from "../../Modal/TagsModal";
import { CustomModalComponent } from "../../../CustomModalComponent";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";

interface TagsComponentProps {
   notes: boolean;
}

export const TagsComponent = (props: TagsComponentProps) => {
   const [openTagModal, setOpenTagModal] = useState(false);
   const { notesTags, eventTags } = useContext(GovernanceContext);
   const tags = props.notes ? notesTags : eventTags;

   const handleModalClose = () => {
      setOpenTagModal(false);
   };
   return (
      <>
         <Box
            sx={{
               bgcolor: "#B8C5D0",
               px: 2,
               py: 1,
               display: "flex",
               justifyContent: "space-between",
               alignItems: "center",
               borderTopLeftRadius: 4,
               borderTopRightRadius: 4,
            }}
         >
            <Typography fontWeight={600} fontSize={14} sx={{ color: "#46566A" }}>
               Etiquetas
            </Typography>
            <IconButton
               sx={{ p: 0 }}
               onClick={() => {
                  setOpenTagModal(true);
               }}
            >
               <AddCircle fontSize="small" />
            </IconButton>
         </Box>
         <Box sx={{ px: 2, maxHeight: 200, overflowY: "auto" }}>
            {tags.length > 0 ? (
               tags.map((tag, i) => {
                  return (
                     <Box key={i} sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                        <CheckBoxComponent data={tag.title} tags={tag.color} id={tag._id} notes={props.notes} />
                     </Box>
                  );
               })
            ) : (
               <Typography sx={{ display: "flex", justifyContent: "center", my: 2, fontSize: 14 }}>
                  No se han creado etiquetas
               </Typography>
            )}
         </Box>
         <CustomModalComponent open={openTagModal} setOpen={setOpenTagModal} title="Agregar etiqueta" timeStamp>
            <TagsModal setOpen={handleModalClose} />
         </CustomModalComponent>
      </>
   );
};
