import { Box, Button, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { getAdviceByGoberningBodyId } from "../../../lib/gobCorpBEClient";
import { UserContext } from "../../../context/userContext";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { TextFilterComponent } from "../AgreementsAndCommitmentsScreen/SubComponents/TextFilterComponent";
import { AdviceCardComponent } from "./AdviceCardComponent";
import { ModalAddAdviceComponent } from "./AddAdviceModal";
import { CustomModalComponent } from "../../CustomModalComponent";
import { Info } from "@mui/icons-material";
import { GovernanceTheSequelContext } from "../../../context/governanceContext/governanceTheSequelContext";
import { getNameUserData } from "../../../lib/usersBEClient";

const useGetAdvices = (goberningBodyId, members) => {
   const { refetchTasks } = useContext(GovernanceContext);
   const { isLoadingUser } = useContext(UserContext);
   const [tasks, setTasks] = useState([]);
   const [isLoading, setIsLoading] = useState(false);

   useEffect(() => {
      const fetch = async () => {
         setIsLoading(true);
         try {
            const advices = await getAdviceByGoberningBodyId(goberningBodyId);
            const advicesOwners = advices.map((a) => a.owner);
            const uniqueUsers = advicesOwners.filter((value, index, self) => self.indexOf(value) === index);
            const userData = await getNameUserData(uniqueUsers);
            for (const advice of advices) advice.owner = userData.find((user) => user._id === advice.owner);
            setTasks(advices);
         } catch (error) {
            console.log(error);
         }
         setIsLoading(false);
      };
      if (!isLoadingUser && members?.length > 0) fetch();
   }, [refetchTasks, isLoadingUser, members, goberningBodyId]);

   return { tasks, isLoading };
};

const useGetFilteredTasks = (textFiltered, tasks) => {
   const filteredTasks = useMemo(() => {
      if (textFiltered === "") return tasks;
      const tasksFiltered = tasks.filter((task) => {
         if (task?.title) {
            return (
               task?.title?.toLowerCase()?.includes(textFiltered.toLowerCase()) ||
               task?.description?.toLowerCase()?.includes(textFiltered.toLowerCase()) ||
               task?.owner?.firstName?.toLowerCase()?.includes(textFiltered.toLowerCase())
            );
         }
      });
      return tasksFiltered;
   }, [textFiltered, tasks]);
   return { filteredTasks };
};

export const AdvicesScreenComponent = () => {
   const { user } = useContext(UserContext);
   const { members } = useContext(GovernanceTheSequelContext);
   const { selectedGovernance, resourceAccessGob } = useContext(GovernanceContext);
   const { tasks, isLoading: loadingUserTask } = useGetAdvices(selectedGovernance._id, members);
   const [filterText, setFilterText] = useState("");
   const { filteredTasks } = useGetFilteredTasks(filterText, tasks);
   const [permission, setPermission] = useState(0);
   const [open, setOpen] = useState(false);

   useEffect(() => {
      const access = resourceAccessGob.find((r) => r.name === "Tablero de avisos");
      setPermission(access?.permission);
   }, [resourceAccessGob]);

   if (loadingUserTask) {
      return (
         <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
         </Box>
      );
   }

   return (
      <>
         <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
            <TextFilterComponent setFilterText={setFilterText} />
            {selectedGovernance.title !== "PANEL DE USUARIO" && (
               <>
                  {(permission >= 2 || user.role.find((role) => role.includes("Coordinador"))) && (
                     <Button
                        variant="contained"
                        fullWidth
                        onClick={() => {
                           setOpen(true);
                        }}
                        sx={{ width: 200 }}
                     >
                        Agregar aviso
                     </Button>
                  )}
               </>
            )}
         </Box>
         {filteredTasks.length > 0 ? (
            <Box
               sx={{
                  display: "flex",
                  my: 2,
                  columnGap: 2,
               }}
            >
               <Box sx={{ flex: 1 }}>
                  <Grid container spacing={5} display={"flex"} justifyContent={"flex-start"}>
                     {filteredTasks.map((advice, i) => (
                        <Grid key={advice.title + i} item xs={3} display={"flex"} justifyContent={"center"}>
                           <AdviceCardComponent advice={advice} author={advice.owner} />
                        </Grid>
                     ))}
                  </Grid>
               </Box>
            </Box>
         ) : (
            <Stack direction={"column"} width={"100%"} display={"flex"} justifySelf={"center"} my={4}>
               <Box
                  display={"flex"}
                  flexDirection={"column"}
                  justifyContent={"center"}
                  alignItems={"center"}
                  sx={{ opacity: 0.2 }}
                  rowGap={1}
               >
                  <Info sx={{ fontSize: "50px" }} />
                  <Typography fontSize={"18px"} textAlign={"center"} maxWidth={"270px"}>
                     No se encontraron avisos
                  </Typography>
               </Box>
            </Stack>
         )}

         <CustomModalComponent open={open} setOpen={setOpen} title="Crear aviso" timeStamp>
            <ModalAddAdviceComponent open={open} setOpen={setOpen} />
         </CustomModalComponent>
      </>
   );
};
