import { Select, FormControl, InputLabel, FormHelperText } from "@mui/material";
import { useField } from "formik";

interface InputSelectFieldInterfaceProps {
   label?: string;
   labelId: string;
   fullWidth?: boolean;
   multiple?: boolean;
   id: string;
   name: string;
   renderValue?: any;
   children: any;
   variant?: any;
   size?: any;
   sx?: any;
   disabled?: any;
   onChange?: any;
   placeholder?: string;
   check?: boolean;
   error?: boolean;
   defaultValue?: any;
}

export const InputSelectField = (props: InputSelectFieldInterfaceProps) => {
   const [field, meta] = useField(props);
   return (
      <FormControl fullWidth={props.fullWidth} size={props.size} error={props.error}>
         <InputLabel id={props.labelId}>{props.label}</InputLabel>
         <Select
            sx={props.sx}
            error={props.error ? props.error : meta.error && meta.touched}
            {...props}
            {...field}
            onChange={props.onChange ? props.onChange : field.onChange}
            MenuProps={{ PaperProps: { sx: { maxHeight: 250 } } }}
         >
            {props.children}
         </Select>
         <FormHelperText error={meta.error && meta.touched}>
            {meta.error && meta.touched ? meta.error : null}
         </FormHelperText>
      </FormControl>
   );
};
