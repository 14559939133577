import { useContext, useMemo, useState } from "react";
import { CorporateDataContext } from "../../../context/governanceContext/corporateDataContext";
import { HeadersProps } from "../../../components/ListComponent";
import { UserContext } from "../../../context/userContext";

export const useGetMembersInfo = (hideFullInfo: boolean) => {
   const { series, governanceBody, members } = useContext(CorporateDataContext);
   const { companySelected } = useContext(UserContext);
   const [isLoading, setIsLoading] = useState(true);

   const formatedMembers = useMemo(() => {
      if (governanceBody) {
         let totalCompanyCapital = 0;
         members.map((member) => {
            //sum of all member´s capitals
            return (totalCompanyCapital =
               totalCompanyCapital +
               series.reduce((sum, serie) => {
                  const action = member.actions.find((action) => action.title === serie.title);
                  if (action) {
                     return sum + serie.nominalValue * action.sharesAmount;
                  }
                  return sum;
               }, 0));
         });
         if (members.length === 0) {
            setIsLoading(false);
            return [];
         }
         return members.map((member) => {
            const actions = series.map((serie) => {
               const action = member.actions.find((action) => action.title === serie.title);
               return {
                  [serie.title]: action ? action.sharesAmount : 0,
               };
            });
            const mergedActions = !hideFullInfo && Object.assign({}, ...actions);
            const totalCapital = series.reduce((sum, serie) => {
               const action = member.actions.find((action) => action.title === serie.title);
               if (action) {
                  return sum + serie.nominalValue * action.sharesAmount;
               }
               return sum;
            }, 0);
            setIsLoading(false);
            return {
               name: member.businessName ? member.businessName : `${member.firstName} ${member.lastName}`,
               founder: member.founder,
               rfc: member.rfc,
               propertyPercentage: !totalCapital ? 0 : ((totalCapital * 100) / totalCompanyCapital).toFixed(1) ?? 0,
               ...mergedActions,
               totalActions: actions.reduce((sum, action) => sum + Object.values(action)[0], 0),
               socialCapital: totalCapital,
               votes: member.actions.reduce((sum, action) => sum + action.votes, 0) || 0,
               userId: member.user,
            };
         });
      }
      setIsLoading(false);
   }, [governanceBody, members, series, hideFullInfo, companySelected]);

   const seriesheaders: HeadersProps[] = useMemo(() => {
      const seriesHeaders: HeadersProps[] =
         (!hideFullInfo &&
            series.map((serie) => {
               return {
                  headerTitle: `Acciones ${serie.title}`,
                  headerPosition: "center",
                  bodyPosition: "center",
                  type: "number",
               };
            })) ||
         [];

      return [
         {
            headerTitle: "Socio / accionista",
            headerPosition: "center",
            bodyPosition: "left",
            type: "text",
         },
         {
            headerTitle: "Fundadores",
            headerPosition: "center",
            bodyPosition: "center",
            type: "checkIcon",
         },
         {
            headerTitle: "RFC",
            headerPosition: "center",
            bodyPosition: "center",
            type: "text",
         },
         {
            headerTitle: "Porcentaje de propiedad",
            headerPosition: "center",
            bodyPosition: "center",
            type: "percentage",
         },
         ...seriesHeaders,
         {
            headerTitle: "Total acciones",
            headerPosition: "center",
            bodyPosition: "center",
            type: "number",
         },
         {
            headerTitle: "Capital social",
            headerPosition: "center",
            bodyPosition: "center",
            type: "currency",
         },
         {
            headerTitle: "Número de votos",
            headerPosition: "center",
            bodyPosition: "center",
            type: "number",
         },
      ];
   }, [series, hideFullInfo, companySelected]);

   return { formatedMembers, members, seriesheaders, isLoading };
};
