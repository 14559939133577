import { Box, Button, CircularProgress, Collapse, IconButton, Stack, Typography } from "@mui/material";
import React, { useContext, useEffect, useMemo, useState } from "react";
import { useGetMembersInfo } from "../../hooks/gob-corp/corporateData/useGetMembersInfo";
import { SnackBarContext } from "../../context/snackBarContext";
import { CorporateDataContext } from "../../context/governanceContext/corporateDataContext";
import { AddShareholderModal } from "../../components/MiLecosy/ModalsGovernanceModule/AddShareholderModal";
import { EditShareholderModal } from "../../components/MiLecosy/ModalsGovernanceModule/EditShareholderModal";
import { FilterComponent } from "../../components/FilterComponent";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { formatCurrency } from "../../const/globalConst";
import { UpdateModal } from "../../components/MiLecosy/ModalsGovernanceModule/updateModal";
import { BeneficiaryControllerContext } from "../../context/beneficiaryContext/beneficiaryContollerContext";
import { HeadCell, TableComponent } from "../../components/TableComponent";
import { Check, DownloadRounded, Folder } from "@mui/icons-material";
import { IBeneficiary } from "../../types/beneficiaryTypes";
import { ConfirmationModal } from "../../components/ConfirmationModal";
import { CircularProgressWithLabel } from "../../components/CircularProgressWithLabel";

const headers: HeadCell[] = [
   { field: "check", headerName: "check", type: "check", width: 50, align: "center", icon: "check" },
   { field: "name", headerName: "Nombre", type: "string", width: 170, align: "left" },
   { field: "founder", headerName: "Fundador", type: "string", align: "center" },
   { field: "rfc", headerName: "RFC", type: "string", align: "left" },
   { field: "propertyPercentage", headerName: "Porcentaje de propiedad", type: "string", align: "center" },
   { field: "totalActions", headerName: "Total acciones", type: "string", align: "center" },
   { field: "socialCapital", headerName: "Capital social", type: "string", align: "center" },
   { field: "createdAt", headerName: "Fecha de registro", type: "date", align: "center" },
   { field: "updatedAt", headerName: "Última modificación", type: "date", align: "center" },
   { field: "active", headerName: "Estatus", type: "boolean", width: 70 },
   { field: "file", headerName: "Expediente", type: "string", width: 100 },
];

export const ShareHolderScreen = () => {
   const { refetch, shareHolders, handleDownload } = useContext(BeneficiaryControllerContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const { series } = useContext(CorporateDataContext);
   const { formatedMembers, members } = useGetMembersInfo(false);
   const [filteredMembers, setFilteredMembers] = useState([]);
   const [openDownloadModal, setOpenDownloadModal] = useState(false);
   const [downloadProgress, setDownloadProgress] = useState(0);
   const [selectedUser, setSelectedUser] = useState(null);
   const [openModal, setOpenModal] = useState(false);
   const [openModal2, setOpenModal2] = useState(false);
   const [isUpdateModal, setUpdateModal] = useState(false);
   const [selectedRows, setSelectedRows] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   const { companyId } = useParams();
   const navigate = useNavigate();
   const location = useLocation();

   const handleUpdateConfig = () => navigate(`/beneficiario-controlador/${companyId}/configuracion`);
   const handleUpdateClick = () => setUpdateModal(true);
   const handleFolderClick = (user: IBeneficiary) =>
      navigate(`${location.pathname.replace(/[^/]+$/, `expediente/${user?.folderId}`)}`);

   const rows = useMemo(() => {
      if (!formatedMembers || !shareHolders) return [];
      const formattedRows = formatedMembers
         .map((member) => {
            const beneficiaryInfoFound = shareHolders.find((b) => b.user?._id === member.userId);
            return {
               ...member,
               beneficiary: beneficiaryInfoFound,
               founder: member.founder && <Check />,
               propertyPercentage: member.propertyPercentage + "%",
               socialCapital: "$" + formatCurrency(member.socialCapital),
               createdAt: beneficiaryInfoFound?.createdAt ?? "",
               updatedAt: beneficiaryInfoFound?.updatedAt ?? "",
               file: (
                  <IconButton onClick={() => handleFolderClick(beneficiaryInfoFound)}>
                     <Folder style={{ cursor: "pointer" }} sx={{ color: "#F1B44C", fontSize: 27 }} />
                  </IconButton>
               ),
               _id: beneficiaryInfoFound?._id,
               folderId: beneficiaryInfoFound?.folderId,
            };
         })
         .filter((m) => m.beneficiary);
      setFilteredMembers(formattedRows);
      setIsLoading(false);
      return formattedRows;
   }, [formatedMembers, shareHolders]);

   return (
      <Stack>
         <Box
            sx={{
               justifyContent: "center",
               display: "flex",
               flexDirection: "column",
               paddingTop: 1,
               paddingBottom: 2,
            }}
         >
            <Stack
               direction="row"
               justifyContent="space-between"
               alignItems="center"
               sx={{
                  columnGap: 2,
               }}
            >
               <Collapse in={selectedRows.length > 0} unmountOnExit>
                  <Button variant="contained" onClick={() => setOpenDownloadModal(true)}>
                     <DownloadRounded />
                  </Button>
               </Collapse>
               <FilterComponent
                  fullWidth
                  originaltableOneRows={rows}
                  tableOneFilteredRows={filteredMembers}
                  setTableOneFilteredRows={setFilteredMembers}
                  filters={[]}
                  disableRefresh
                  searchBar={
                     <Stack sx={{ flexDirection: "row", gap: 2 }}>
                        <Button
                           variant="contained"
                           sx={{ borderRadius: 1, px: 3, bgcolor: "#8A95A0" }}
                           onClick={handleUpdateConfig}
                        >
                           Configurar actualización
                        </Button>
                        <Button
                           variant="contained"
                           sx={{ borderRadius: 1, px: 3, bgcolor: "#8A95A0" }}
                           onClick={handleUpdateClick}
                        >
                           Solicitar actualización
                        </Button>
                        <Button
                           variant="contained"
                           sx={{ borderRadius: 1, px: 3 }}
                           onClick={() => {
                              if (series?.length > 0) setOpenModal(!openModal);
                              else showSnackBar("Favor de crear series antes de agregar accionistas", true);
                           }}
                        >
                           Agregar nuevo
                        </Button>
                     </Stack>
                  }
               />
            </Stack>
         </Box>

         <TableComponent
            headerColor
            headers={headers}
            rows={filteredMembers || []}
            defaultColumnToOrder="name"
            defaultOrder="desc"
            defaultRowsPerPage={10}
            emptyDataText={rows?.length > 0 ? "No se encontraron accionistas" : "No existen accionistas registrados"}
            loader={isLoading}
            rowsPerPageOptions={[5, 10, 20, 50]}
            onClick={() => {}}
            selectedRows={selectedRows}
            setSelected={setSelectedRows}
            multiselect
         />

         {series.length > 0 && !isLoading && (
            <EditShareholderModal
               state={openModal2}
               setState={setOpenModal2}
               user={selectedUser}
               setUser={setSelectedUser}
            />
         )}
         {isUpdateModal && !isLoading && (
            <UpdateModal
               state={isUpdateModal}
               setState={setUpdateModal}
               onConfirm={() => {
                  refetch();
                  setUpdateModal(false);
               }}
               companyId={companyId}
               members={members}
               beneficiaries={[]}
            />
         )}
         {series.length > 0 && !isLoading && <AddShareholderModal state={openModal} setState={setOpenModal} />}

         {openDownloadModal && !isLoading && (
            <ConfirmationModal
               open={openDownloadModal}
               onConfirm={() => handleDownload(setOpenDownloadModal, rows, selectedRows, setDownloadProgress)}
               setOpen={setOpenDownloadModal}
               title={"Descargar expediente(s)"}
               isLoading={downloadProgress > 0}
               body={
                  <Stack sx={{ justifyContent: "center", alignItems: "center", rowGap: 1 }}>
                     <Typography>¿Esta seguro que desea descargar el expediente(s)?</Typography>
                     <Typography variant="caption" sx={{ color: "red" }}>
                        Esta acción puede tardar algunos minutos en completarse.
                     </Typography>
                     {downloadProgress > 0 && (
                        <>
                           <Typography>Progreso estimado:</Typography>
                           <CircularProgressWithLabel value={downloadProgress} size={60} />
                        </>
                     )}
                  </Stack>
               }
            />
         )}
      </Stack>
   );
};
