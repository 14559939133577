import { Box, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useMemo } from "react";
import { ExternalGovernanceSessionContext } from "../../../context/governanceContext/externalSessionContext";
import { SessionAvatar } from "../SessionComponent/SubComponents/SessionAvatar";

const GetSessionMember = (member) => {
   const { socket, session, usersOnline, setUsersOnline } = useContext(ExternalGovernanceSessionContext);
   const isOnline = useMemo(() => {
      return usersOnline.some((user) => user === (member._id ? member._id : member.user));
   }, [usersOnline]);

   useEffect(() => {
      if (session) socket.emit("user-already-load", session._id);
   }, [session]);

   useEffect(() => {
      if (socket === null) return;
      const handleJoinUser = ({ users }) => {
         const usersIdData = Object.values(users);
         setUsersOnline(usersIdData);
      };
      const handleLeftUser = (usersOnline) => {
         const usersIdData = Object.values(usersOnline);
         setUsersOnline(usersIdData);
      };
      socket.on("user-connected", handleJoinUser);
      socket.on("users-offline", handleLeftUser);
      return () => {
         socket.off("user-connected", handleJoinUser);
         socket.off("users-offline", handleLeftUser);
      };
   }, [socket]);

   return { isOnline };
};

export const SessionMemberExternal = ({ member }) => {
   const { isOnline } = GetSessionMember(member);
   const { session } = useContext(ExternalGovernanceSessionContext);

   return (
      <Box
         sx={{
            display: "flex",
            gap: 2,
            alignItems: "center",
            p: 2,
            borderBottom: 1,
            borderColor: "#E9EDF5",
         }}
      >
         <SessionAvatar
            imgSrc={member.profilePic}
            isOnline={isOnline}
            color={
               session.completed === false
                  ? member.status === "CANCELLED"
                     ? "red"
                     : member.status === "APPROVED"
                     ? "#44b700"
                     : "gray"
                  : null
            }
            name={
               member.memberCharge
                  ? `${member.firstName} ${member.lastName}`
                  : member.name || `${member.email?.charAt(0).toUpperCase()} ${member.email?.charAt(1).toUpperCase()}`
            }
         />
         <Stack>
            <Typography sx={{ fontSize: { xs: 16, lg: 14 } }}>
               {member.memberCharge ? `${member.firstName} ${member.lastName}` : member.name || member.email}
            </Typography>
            <Typography sx={{ fontSize: { xs: 14, lg: 12 }, color: "#9E9E9E" }}>
               {member.memberCharge ? member.memberCharge.join(", ") : `Usuario Externo - ${member.specialty}`}
            </Typography>
         </Stack>
      </Box>
   );
};
