import { Pause, PlayArrow, RestartAlt, VolumeUp } from "@mui/icons-material";
import { Box, IconButton, Popper, Slider, Typography } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";

export const VideoPlayer = ({ video, name, audio }) => {
   const videoRef = useRef(null);
   const [isPlaying, setIsPlaying] = useState(false);
   const [currentTime, setCurrentTime] = useState([0, 0]); // current time of the video in array. The first value represents the minute and the second represents seconds.
   const [currentTimeSec, setCurrentTimeSec] = useState(); //current time of the video in seconds
   const [duration, setDuration] = useState([0, 0]); // // total duration of the video in the array. The first value represents the minute and the second represents seconds.
   const [durationSec, setDurationSec] = useState(); // // current duration of the video in seconds
   const [volume, setVolume] = useState(0);
   const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
   const open = Boolean(anchorEl);
   const id = open ? "simple-popper" : undefined;

   const handleClick = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(anchorEl ? null : event.currentTarget);
   };
   const handleChange = (event: Event, newValue: number) => {
      videoRef.current.volume = newValue / 100;
      setVolume(newValue as number);
   };

   useEffect(() => {
      const { min, sec } = sec2Min(videoRef.current.duration);
      setDurationSec(videoRef.current.duration);
      setDuration([min, sec]);
      setVolume(videoRef.current.volume * 100);

      const interval = setInterval(() => {
         const { min, sec } = sec2Min(videoRef.current.currentTime);
         setCurrentTimeSec(videoRef.current.currentTime);
         setCurrentTime([min, sec]);
      }, 1000);
      return () => clearInterval(interval);
   }, [isPlaying]);

   const sec2Min = (sec) => {
      const min = Math.floor(sec / 60);
      const secRemain = Math.floor(sec % 60);
      return {
         min: min,
         sec: secRemain,
      };
   };

   const handlePlay = () => {
      if (isPlaying) {
         videoRef.current.pause();
         setIsPlaying(false);
      } else {
         videoRef.current.play();
         setIsPlaying(true);
      }
   };

   return (
      <Box
         className="container"
         sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
         }}
      >
         <Box sx={{ position: audio ? "absolute" : "" }}>
            <video
               className="videoPlayer"
               ref={videoRef}
               src={video}
               onClick={handlePlay}
               style={{ cursor: "pointer", maxHeight: 600 }}
            />
         </Box>
         <Box
            className="controlsContainer"
            sx={{
               display: "flex",
               alignItems: "center",
               columnGap: 2,
               justifyContent: "center",
               px: 2,
               bgcolor: "#162c44",
               opacity: 0.8,
               boxShadow: 2,
            }}
         >
            <Typography sx={{ color: "white" }}>{name}</Typography>

            <Box className="controls" sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
               {isPlaying ? (
                  Math.round(durationSec) === Math.round(currentTimeSec) ? (
                     <IconButton className="controlButton" onClick={() => videoRef.current.play()}>
                        <RestartAlt sx={{ color: "white" }} />
                     </IconButton>
                  ) : (
                     <IconButton className="controlButton" onClick={handlePlay}>
                        <Pause sx={{ color: "white" }} />
                     </IconButton>
                  )
               ) : (
                  <IconButton className="controlButton" onClick={handlePlay}>
                     <PlayArrow sx={{ color: "white" }} />
                  </IconButton>
               )}
               <Box className="duration">
                  <Typography sx={{ color: "white" }}>
                     {currentTime[0]}:{currentTime[1]} / {duration[0] ? duration[0] : currentTime[0]}:
                     {duration[1] ? duration[1] : currentTime[1]}
                  </Typography>
               </Box>
            </Box>
            <input
               type="range"
               min={0}
               max={Math.round(durationSec)}
               //  default={}
               value={Math.round(currentTimeSec)}
               className="timeline"
               onChange={(e) => {
                  videoRef.current.currentTime = e.target.value;
               }}
               style={{ color: "#162c44" }}
            />
            <IconButton onClick={handleClick}>
               <VolumeUp sx={{ color: "white" }} />
            </IconButton>
         </Box>
         <Popper id={id} open={open} anchorEl={anchorEl} placement="top" sx={{ zIndex: 2000 }}>
            <Box
               sx={{
                  bgcolor: "white",
                  boxShadow: 2,
                  borderRadius: 2,
                  minHeight: 150,
                  py: 2,
               }}
            >
               <Box>
                  <Slider
                     orientation="vertical"
                     value={volume}
                     min={0}
                     max={100}
                     sx={{ height: 120 }}
                     onChange={handleChange}
                  />
               </Box>
            </Box>
         </Popper>
      </Box>
   );
};
