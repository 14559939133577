import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, Divider, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from "@mui/material";
import { useState, useContext } from "react";
import { useLocation } from "react-router-dom";
import { UserContext } from "../context/userContext";

interface ListItemComponentProps {
   item: any;
   index: number;
   open: boolean;
   setOpen: Function;
   openModal?: boolean;
   setOpenModal?: Function;
}

export const ListItemComponent = (props: ListItemComponentProps) => {
   const { setPath, user, companies } = useContext(UserContext);
   const [openMenu, setOpenMenu] = useState(false);
   const location = useLocation();

   const handleClick = () => {
      setOpenMenu(false);
      props.setOpen(false);
      if ((companies.length > 1 || user.group) && !!props.setOpenModal) props.setOpenModal(true);
      if (!location.pathname.startsWith(props.item.path)) setPath(props.item.path);
   };

   const handleOpenMenu = () => {
      props.setOpen(true);
      setOpenMenu(props.open ? !openMenu : true);
   };

   return (
      <>
         <ListItem
            key={props.index}
            disablePadding
            sx={{ display: "block" }}
            onClick={() => {
               props.item.submenu ? handleOpenMenu() : handleClick();
            }}
         >
            <ListItemButton
               sx={{
                  height: 35,
                  justifyContent: props.open ? "initial" : "center",
                  px: 2.5,
               }}
            >
               <ListItemIcon
                  sx={{
                     minWidth: 0,
                     mr: props.open ? 3 : "auto",
                     justifyContent: "center",
                  }}
               >
                  {props.item.icon}
               </ListItemIcon>
               <ListItemText
                  primary={<Typography variant="subtitle1">{props.item.lable}</Typography>}
                  sx={{ opacity: props.open ? 1 : 0 }}
               />
               {props.item.submenu && props.open ? <>{openMenu ? <ExpandLess /> : <ExpandMore />}</> : null}
            </ListItemButton>
         </ListItem>
         {props.item.submenu && props.open ? (
            <Collapse in={openMenu && props.open} style={{ overflow: "hidden" }}>
               <Divider sx={{ mx: 2 }} />
               {props.item.submenu.map((menu) => (
                  <ListItem
                     key={menu.lable}
                     disablePadding
                     sx={{ display: "block", pl: 6 }}
                     onClick={() => props.setOpen(false)}
                  >
                     <ListItemButton
                        sx={{
                           height: 38,
                           justifyContent: props.open ? "initial" : "center",
                           px: 2.5,
                        }}
                        onClick={() => setPath(menu.path)}
                     >
                        <ListItemText
                           primary={<Typography variant="subtitle1">{menu.lable}</Typography>}
                           sx={{ opacity: props.open ? 1 : 0, fontSize: 12 }}
                        />
                     </ListItemButton>
                  </ListItem>
               ))}
               <Divider sx={{ mx: 2 }} />
            </Collapse>
         ) : null}
      </>
   );
};
