import { PeopleAltOutlined } from "@mui/icons-material";
import { Autocomplete, Box, TextField, Typography } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { getUsersDataByGoverningBody } from "../../../lib/usersBEClient";
import { IGovernanceBody } from "../../../types/governance.types";

interface UserSearchComponentProps {
   label: string;
   governBodies: IGovernanceBody[];
   setSelectedUser: Function;
   selectedUsers: string[];
}

const useFetchUsers = (governBodies) => {
   const { user } = useContext(UserContext);
   const [users, setUsers] = useState([]);
   const [isLoading, setisLoading] = useState(false);
   useEffect(() => {
      const usersGet = async () => {
         setisLoading(true);
         const array = governBodies.map((e) => e.users);
         let s: any[] = [];
         for (let i = 0; i < array.length; i++) {
            s.push(...array[i]);
         }
         const usersArray = s.filter((e) => e.user !== user.id);
         if (usersArray.length > 0) {
            const ids = usersArray.map((user) => user.user);
            const userData = await getUsersDataByGoverningBody(ids);
            setUsers(userData);
            setisLoading(false);
         } else {
            setisLoading(false);
            setUsers([]);
         }
      };
      if (governBodies.length > 0) usersGet();
   }, [governBodies]);

   return { isLoading, users };
};

export const UserSearchComponent = (props: UserSearchComponentProps) => {
   const { users, isLoading } = useFetchUsers(props.governBodies);
   const [loadingSelected, setLoadingSelected] = useState(false);
   const [userSelected, setUserSelected] = useState([]);
   useEffect(() => {
      const selectedIds = async () => {
         setLoadingSelected(true);
         if (props.selectedUsers.length > 0) {
            const formUsers = users.filter((u) => {
               return props.selectedUsers.includes(u._id);
            });
            setUserSelected(formUsers);
            setLoadingSelected(false);
         } else {
            setUserSelected([]);
            setLoadingSelected(false);
         }
      };
      selectedIds();
   }, [users, props.selectedUsers]);

   return (
      <>
         <Box sx={{ display: "flex", flexDirection: "column", width: "100%", rowGap: 1 }}>
            <Typography variant="body2" fontWeight={600}>
               {props.label}
            </Typography>
            <React.Fragment>
               <Box sx={{ display: "flex", width: "100%", alignContent: "center" }}>
                  <Box
                     sx={{
                        bgcolor: "#F6F7FB",
                        p: 1,
                        display: "flex",
                        alignItems: "center",
                        borderRadius: 1,
                        border: 0.2,
                        borderColor: "#CBD5E0",
                        justifyContent: "center",
                     }}
                  >
                     <PeopleAltOutlined />
                  </Box>
                  <Box id="selector-users" sx={{ width: "100%" }}>
                     <Autocomplete
                        value={userSelected}
                        multiple
                        size="small"
                        options={users}
                        getOptionLabel={(user) => `${user.firstName} ${user.lastName}`}
                        onChange={(event, user) => {
                           props.setSelectedUser(user);
                        }}
                        loading={isLoading && loadingSelected}
                        renderInput={(params) => <TextField {...params} placeholder="Usuarios" />}
                     />
                  </Box>
               </Box>
            </React.Fragment>
         </Box>
      </>
   );
};
