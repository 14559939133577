import { Stack, Typography } from "@mui/material";
import { InputTextField } from "../../../Inputs/InputTextField";
import { useContext } from "react";
import { GovernanceSessionContext } from "../../../../context/governanceContext/governanceSessionContext";
import { getContrastYIQ } from "../../../../const/globalConst";
import { useFormikContext } from "formik";

export const BillNotes = () => {
   const { hasPermissions, colors, setReceivingChanges, receivingChanges } = useContext(GovernanceSessionContext);
   const { setFieldValue } = useFormikContext();
   return (
      <Stack>
         <Typography
            sx={{ bgcolor: colors.primary, textAlign: "center", fontSize: 12, color: getContrastYIQ(colors.primary) }}
         >
            NOTAS
         </Typography>
         <InputTextField
            id={"notes"}
            name={"notes"}
            type={"text"}
            multiline
            minRows={6}
            sx={{
               fontSize: 12,
               p: 2,
               bgcolor: "#EFEFEF",
               ".Mui-disabled": { "-webkit-text-fill-color": "black !important" },
            }}
            unstyled
            disabled={!hasPermissions}
            onChange={(e) => {
               if (receivingChanges) setReceivingChanges(false);
               setFieldValue("notes", e.target.value);
            }}
         />
      </Stack>
   );
};
