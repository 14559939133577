import { Grid, MenuItem, Typography } from "@mui/material";
import { InputSelectField } from "../../Inputs/InputSelectField";
import { InputTextField } from "../../Inputs/InputTextField";

interface SocietyKindsComponentProps {
   currentSociety: string;
   currentLegalConcept: string;
   legalConceptsId: string;
   legalConceptsName: string;
   societyKindsId: string;
   societyKindsName: string;
   legalConceptOtherId: string;
   legalConceptOtherName: string;
}

const legalConceptsCivil = ["Sociedad civil", "Asociación civil"];

const legalConceptsMerc = [
   "Sociedad de responsabilidad limitada",
   "Sociedad anónima",
   "Sociedad anónima promotora de inversión",
   "Sociedad en nombre colectivo",
   "Sociedad en comandita simple",
   "Sociedad en comandita por acciones",
   "Sociedad cooperativa",
   "Sociedad por acciones simplificada",
   "Sociedades de solidaridad social",
   "Otro",
];

const societyKinds = ["Sociedad mercantil", "Sociedad civil"];

export const SocietyKindsComponent = (props: SocietyKindsComponentProps) => {
   const {
      currentSociety,
      legalConceptsId,
      legalConceptsName,
      societyKindsId,
      societyKindsName,
      currentLegalConcept,
      legalConceptOtherId,
      legalConceptOtherName,
   } = props;

   return (
      <Grid container gap={1}>
         <Grid item xs={12}>
            <Typography>Tipo de sociedad</Typography>
            <InputSelectField
               id={societyKindsId}
               name={societyKindsName}
               label=""
               labelId=""
               variant="outlined"
               size={"small"}
               fullWidth
            >
               {societyKinds.map((e, index) => (
                  <MenuItem key={index} value={e}>
                     {e}
                  </MenuItem>
               ))}
            </InputSelectField>
         </Grid>
         <Grid item xs={currentLegalConcept === "Otro" ? 3.5 : 12}>
            <Typography>Figura jurídica</Typography>
            <InputSelectField
               id={legalConceptsId}
               name={legalConceptsName}
               label=""
               labelId=""
               variant="outlined"
               size={"small"}
               fullWidth
               disabled={currentSociety === ""}
            >
               {currentSociety === "Sociedad mercantil"
                  ? legalConceptsMerc.map((e, index) => (
                       <MenuItem key={index} value={e}>
                          {e}
                       </MenuItem>
                    ))
                  : legalConceptsCivil.map((e, index) => (
                       <MenuItem key={index} value={e}>
                          {e}
                       </MenuItem>
                    ))}
            </InputSelectField>
         </Grid>
         {currentLegalConcept === "Otro" ? (
            <Grid item xs={8} sx={{ mt: 3, ml: 2 }}>
               <InputTextField
                  id={legalConceptOtherId}
                  name={legalConceptOtherName}
                  type="string"
                  fullWidth
                  size={"small"}
               />
            </Grid>
         ) : null}
      </Grid>
   );
};
