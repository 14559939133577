import { Box, Stack, Typography } from "@mui/material";
import ListComponent, { HeadersProps } from "../../../../ListComponent";
import { useState } from "react";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const AssemblyList = ({ selectedSession }) => {
   const affairsList = {};
   for (const affair of selectedSession.affairs) {
      // const affairVotes = selectedSession.votes.filter((vote) => vote.affair === affair._id);
      const involvedVotes = [];
      for (const involved of selectedSession.involved) {
         if (!involved) continue;
         // affairVotes.find((vote) => {
         //    return vote.user === involved._id;
         // });
         involvedVotes.push({
            name: involved.fullName,
            // vote: affairVotes.find((vote) => vote.user === involved._id)?.vote || null,
         });
      }
      affairsList[affair.title] = {
         resolution: affair.status,
         // percentage: (affair.totalVotes * 100) / affair.votes,
         involved: involvedVotes,
      };
   }

   // const [openedShareHolders, setOpenedShareHolders] = useState(false);
   // const handleShareHolderList = () => setOpenedShareHolders((s) => !s);
   return (
      <Stack direction={"column"} spacing={2}>
         <ListComponent
            headers={[
               { headerTitle: "Atendió", type: "check", bodyPosition: "center" },
               { headerTitle: "Puesto", type: "text", bodyPosition: "center" },
            ]}
            rows={
               (selectedSession.involved.length > 0 &&
                  selectedSession.involved
                     .map((user) => {
                        if (user === undefined) return;
                        return {
                           attended: { attended: user.attended, name: user.fullName },
                           charge: user.chargeName,
                        };
                     })
                     .filter((user) => user)) ||
               []
            }
            rowProps={(row, i) => {
               const attended = row.attended.attended;
               return {
                  bgcolor: "#F5F5F5",
                  borderLeft: 7,
                  borderBottomLeftRadius: 4,
                  borderLeftColor: attended ? "#5E77F9" : "#CECECE",
                  py: 1,
                  my: 0.5,
               };
            }}
            rowsColumnProps={{
               px: 2,
            }}
         />
         {/* <Stack direction={"column"} display={"flex"}>
            <Stack bgcolor={"#D9D9D9"}>
               <Typography variant="caption" fontWeight={600} px={8} py={0.45}>
                  Votaciones y deliberaciones
               </Typography>
            </Stack>
            <Box width={"100%"} display={"flex"} justifyContent={"center"}>
               <Stack
                  display={"flex"}
                  borderRadius={1}
                  onClick={handleShareHolderList}
                  direction={"row"}
                  bgcolor={"#2d4357"}
                  color={"white"}
                  sx={{ ":hover": { cursor: "pointer" } }}
                  px={1}
                  mr={openedShareHolders ? 0 : "170px"}
                  width={openedShareHolders ? `${selectedSession.involved.length * 100}px` : "45px"}
                  maxWidth={"400px"}
                  marginY={-0.65}
                  zIndex={120}
               >
                  {openedShareHolders && (
                     <Typography fontSize={"14px"} width={"100%"} textAlign={"center"} my={0}>
                        Deliberación
                     </Typography>
                  )}
                  <Box
                     py={0.5}
                     sx={{
                        display: "flex",
                        alignSelf: "center",
                        justifyContent: openedShareHolders ? "flex-end" : "center",
                        width: !openedShareHolders ? "100%" : "auto",
                     }}
                  >
                     <ArrowForwardIosIcon
                        sx={{
                           transform: `rotate(${openedShareHolders ? "180deg" : "0deg"})`,
                           fontSize: "14px",
                        }}
                     />
                  </Box>
               </Stack>
            </Box>
            <Box maxWidth={"705px"} sx={{ overflowx: "auto" }}>
               <ListComponent
                  maxColumns={6}
                  headers={
                     openedShareHolders
                        ? [
                             { headerTitle: "Asunto", type: "text", bodyPosition: "center" },
                             ...Object.values(affairsList).flatMap((affair: any) => {
                                const involvedArray: HeadersProps[] = [];
                                for (const involved of affair.involved) {
                                   involvedArray.push({
                                      headerTitle: involved.name,
                                      type: "check",
                                      bodyPosition: "center",
                                   });
                                }
                                return involvedArray;
                             }),
                             { headerTitle: "Porcentaje", type: "percentage", bodyPosition: "center" },
                          ]
                        : [
                             { headerTitle: "Asunto", type: "text", bodyPosition: "center" },
                             { headerTitle: "Porcentaje", type: "percentage", bodyPosition: "center" },
                             { headerTitle: "Resolución", type: "positive", bodyPosition: "center" },
                          ]
                  }
                  rows={Object.keys(affairsList).map((affair) => {
                     if (!openedShareHolders)
                        return {
                           affairTitle: affair,
                           percentage: affairsList[affair].percentage,
                           resolution: affairsList[affair].resolution,
                        };
                     const involvedList = {};
                     for (const involved of affairsList[affair].involved) {
                        involvedList[involved._id] = { voted: involved.vote };
                     }
                     return {
                        affairTitle: affair,
                        ...involvedList,
                        percentage: affairsList[affair].percentage,
                     };
                  })}
               />
            </Box>
         </Stack> */}
      </Stack>
   );
};

export default AssemblyList;
