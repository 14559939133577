import { Box, Button, CircularProgress, Collapse, IconButton, MenuItem, Stack, Typography } from "@mui/material";
import { Form, Formik, FormikProps, useFormikContext } from "formik";
import { InputTextField } from "../../../Inputs/InputTextField";
import { userCreateSchemaNew } from "../../../../lib/validations/inputSchemas";
import { InputSelectField } from "../../../Inputs/InputSelectField";
import rolesByService from "../../../../const/rolesByService.json";
import { useContext, useEffect, useState, FC } from "react";
import { userBasicInfo } from "../GroupServicesScreen";
import { UserContext } from "../../../../context/userContext";
import { addUserToGroupModule, checkUserExistence, getGroupLeveledUsers } from "../../../../lib/usersBEClient";
import { SnackBarContext } from "../../../../context/snackBarContext";
import CloseIcon from "@mui/icons-material/Close";

type cdSpecificRole = {
   groupUser: string | userBasicInfo;
   role: string;
};
const initialValues: userBasicInfo & cdSpecificRole = {
   firstName: "",
   lastName: "",
   phoneNumber: "",
   email: "",
   role: "",
   groupUser: "",
};

interface IAddUserToDenounceGroup {
   setOpen: Function;
   existingUsersIds: string[];
   setSeed: Function;
}
const AddUserToDenounceGroup = (props: IAddUserToDenounceGroup) => {
   const { user } = useContext(UserContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const [basicInfo, setBasicInfo] = useState<userBasicInfo>(null);
   const [groupUsers, setGroupUsers] = useState([]);
   const [isLoadingUsers, setIsLoadingUsers] = useState(true);
   const [loadingSubmit, setIsLoadingSubmit] = useState(false);
   const { setOpen, existingUsersIds, setSeed } = props;
   const handleOnCloseModal = () => setOpen(false);

   const handleSubmit = async (values) => {
      if (!basicInfo) {
         const emailEInUse = await checkUserExistence(values.email);
         if (!emailEInUse) return setBasicInfo(values);
         return showSnackBar("Correo electrónico en uso", true);
      }
      setIsLoadingSubmit(true);
      const response = await addUserToGroupModule(user.group._id, values, "cd");
      setIsLoadingSubmit(false);
      setSeed((s) => s + 1);
      if (!response) return showSnackBar("Error al agregar usuario al grupo, intente de nuevo", true);
      showSnackBar("Usuario agregado al grupo exitosamente", false);
      setOpen(false);
   };

   useEffect(() => {
      const fetchUsersInGroup = async () => {
         const usersInGroup = await getGroupLeveledUsers(user.group._id, "2");
         const filteredAlreadyInModuleUsers = usersInGroup.filter(
            (userInGroup) => !existingUsersIds.includes(userInGroup._id)
         );
         setGroupUsers(filteredAlreadyInModuleUsers);
         setIsLoadingUsers(false);
      };
      fetchUsersInGroup();
   }, []);

   const FormObserver: FC = () => {
      const { values, setFieldValue } = useFormikContext();
      const valuess: cdSpecificRole = values as cdSpecificRole;
      const userBasicInfo: userBasicInfo = values as userBasicInfo;

      useEffect(() => {
         if (valuess.groupUser) {
            setFieldValue("firstName", (valuess.groupUser as userBasicInfo).firstName);
            setFieldValue("lastName", (valuess.groupUser as userBasicInfo).lastName);
            setFieldValue("phoneNumber", (valuess.groupUser as userBasicInfo).phoneNumber);
            setFieldValue("email", (valuess.groupUser as userBasicInfo).email);
            setBasicInfo({
               firstName: (valuess.groupUser as userBasicInfo).firstName,
               lastName: (valuess.groupUser as userBasicInfo).lastName,
               email: (valuess.groupUser as userBasicInfo).email,
               phoneNumber: (valuess.groupUser as userBasicInfo).phoneNumber,
            });
         }
      }, [valuess.groupUser]);

      useEffect(() => {
         if (basicInfo?.email !== userBasicInfo.email && typeof valuess.groupUser === "string") {
            setBasicInfo(null);
         }
      }, [userBasicInfo.email]);
      return null;
   };

   return (
      <Stack minWidth={"700px"} py={2} px={5}>
         <Formik
            initialValues={initialValues}
            onSubmit={handleSubmit}
            validationSchema={() => userCreateSchemaNew(basicInfo)}
         >
            {(formProps: FormikProps<any>) => (
               <Form>
                  <FormObserver />
                  <Typography>Usuario del grupo</Typography>
                  <Stack direction={"row"} spacing={1} width={"100%"} display={"flex"} alignItems={"center"}>
                     <Box width={"75%"} py={1}>
                        <InputSelectField
                           id="groupUser"
                           name="groupUser"
                           labelId="user-in-group"
                           label="Nombre del usuario"
                           fullWidth
                           size={"small"}
                           defaultValue={{}}
                        >
                           {!isLoadingUsers ? (
                              groupUsers.map((user) => {
                                 return (
                                    <MenuItem
                                       value={user}
                                       key={user._id}
                                    >{`${user.firstName} ${user.lastName}`}</MenuItem>
                                 );
                              })
                           ) : (
                              <Box width={"100%"} justifyContent={"center"} display={"flex"}>
                                 <CircularProgress size={20} />
                              </Box>
                           )}
                        </InputSelectField>
                     </Box>
                     {typeof formProps.values.groupUser !== "string" && (
                        <IconButton onClick={() => formProps.resetForm()} sx={{ p: 0 }}>
                           <CloseIcon color="error" />
                        </IconButton>
                     )}
                  </Stack>
                  <Typography fontWeight={600} color={"#162C44"}>
                     Información del usuario
                  </Typography>
                  <Box sx={{ display: "flex", columnGap: 3, pt: 2 }}>
                     <InputTextField
                        disabled={!!formProps.values.groupUser}
                        label="Nombre(s)"
                        id="firstName"
                        name="firstName"
                        type="text"
                        fullWidth={true}
                        variant="outlined"
                        size="small"
                     />
                     <InputTextField
                        disabled={!!formProps.values.groupUser}
                        label="Apellido(s)"
                        id="lastName"
                        name="lastName"
                        type="text"
                        fullWidth={true}
                        size="small"
                        variant="outlined"
                     />
                  </Box>
                  <Box sx={{ display: "flex", columnGap: 3, pt: 3 }}>
                     <InputTextField
                        disabled={!!formProps.values.groupUser}
                        label="Teléfono"
                        id="phoneNumber"
                        name="phoneNumber"
                        type="text"
                        fullWidth={true}
                        size="small"
                        variant="outlined"
                     />
                     <InputTextField
                        disabled={!!formProps.values.groupUser}
                        label="Correo electrónico"
                        id="email"
                        name="email"
                        type="text"
                        fullWidth={true}
                        size="small"
                        variant="outlined"
                     />
                  </Box>
                  <Collapse in={!!basicInfo}>
                     <Stack direction={"column"} py={3} spacing={2}>
                        <Typography fontWeight={600} color={"#162c44"}>
                           Asignar rol(es)
                        </Typography>
                        <InputSelectField
                           id="role"
                           name="role"
                           label="Seleccionar"
                           labelId="role"
                           fullWidth
                           size={"small"}
                           onChange={(e) => formProps.setFieldValue("role", e.target.value)}
                        >
                           {rolesByService.CD.map((role) => {
                              return (
                                 <MenuItem key={role} value={role}>
                                    {role}
                                 </MenuItem>
                              );
                           })}
                        </InputSelectField>
                     </Stack>
                  </Collapse>
                  <Box sx={{ display: "flex", justifyContent: "space-between", pt: 3 }}>
                     <Button variant="contained" sx={{ opacity: 0.5 }} onClick={handleOnCloseModal}>
                        Cancelar
                     </Button>
                     <Button variant="contained" color="primary" type="submit">
                        {loadingSubmit ? (
                           <CircularProgress sx={{ color: "white" }} />
                        ) : (
                           <Typography fontWeight={600}>{!!basicInfo ? "Guardar" : "Siguiente"}</Typography>
                        )}
                     </Button>
                  </Box>
               </Form>
            )}
         </Formik>
      </Stack>
   );
};
export default AddUserToDenounceGroup;
