import { Text, Font, Image, View } from "@react-pdf/renderer";
import { Companies } from "../../../types/BaseTypes";
import { ReportTemplate } from "../ReportTemplate";
import { breakLine } from "../ReportCover";

function bgContrast(color) {
   if (!color) return false;
   const hex = color.replace("#", "");
   const rgb = hex.match(/.{1,2}/g).map((x) => parseInt(x, 16));
   const brightness = (rgb[0] * 299 + rgb[1] * 587 + rgb[2] * 114) / 1000;
   return brightness > 155;
}

interface UserPanelReportProps {
   company: Companies;
   governanceData: any;
   chartRefs: any[];
}

export const UserPanelReport = (props: UserPanelReportProps) => {
   const fontColorOnPrimary = bgContrast(props.company.company_details.primaryColor) ? "#162c44" : "white";
   const fontColorOnSecondary = bgContrast(props.company.company_details.secondaryColor) ? "#162c44" : "white";

   const getImage = (ref) => {
      if (!ref) return "";
      return ref.current.canvas.toDataURL("image:png");
   };

   const YearlyPerformanceComponent = (chart) => (
      <View
         style={{
            flex: 1.8,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "flex-start",
         }}
      >
         <Text
            style={{
               fontSize: 9,
               fontFamily: "Open Sans",
               marginBottom: 10,
            }}
         >
            Resumen de desempeño del periodo
         </Text>
         <Image style={{ width: 200, height: "auto" }} src={getImage(chart)} />
      </View>
   );

   const SessionsComponent = (chart, data) => (
      <View
         style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
         }}
      >
         <Text
            style={{
               fontSize: 9,
               fontFamily: "Open Sans",
               textAlign: "center",
               marginBottom: 5,
            }}
         >
            Asistencia a sesiones
         </Text>
         <Image style={{ width: 70, height: "auto", marginTop: 5 }} src={getImage(chart)} />
         <View
            style={{
               display: "flex",
               flexDirection: "row",
               borderBottom: 0.5,
               paddingVertical: 3,
               width: 110,
            }}
         >
            <View
               style={{ marginLeft: 5, padding: 3, borderRadius: 100, backgroundColor: "#fff", alignSelf: "center" }}
            />
            <Text
               style={{
                  fontSize: 5,
                  marginLeft: 5,

                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  flex: 1,
                  textAlign: "left",
               }}
            >
               Estado
            </Text>
            <Text
               style={{
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  flex: 1,
                  textAlign: "center",
               }}
            >
               Totales
            </Text>
            <Text
               style={{
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  flex: 1,
                  textAlign: "center",
               }}
            >
               %
            </Text>
         </View>
         <View
            style={{
               display: "flex",
               flexDirection: "row",
               paddingTop: 3,
               width: 110,
            }}
         >
            <View
               style={{
                  marginLeft: 5,
                  padding: 3,
                  borderRadius: 100,
                  backgroundColor: "#8a95a0",
                  alignSelf: "center",
               }}
            />
            <Text
               style={{
                  fontSize: 5,
                  marginLeft: 5,
                  fontFamily: "Open Sans",
                  flex: 1,
                  textAlign: "left",
               }}
            >
               Ausencia
            </Text>
            <Text
               style={{
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  flex: 1,
                  textAlign: "center",
               }}
            >
               {data[0]}
            </Text>
            <Text
               style={{
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  flex: 1,
                  textAlign: "center",
               }}
            >
               {`${data[0] > 0 ? (data[0] / (data[0] + data[1])) * 100 : 0}%`}
            </Text>
         </View>
         <View
            style={{
               display: "flex",
               flexDirection: "row",
               paddingTop: 3,
               width: 110,
            }}
         >
            <View
               style={{ marginLeft: 5, padding: 3, borderRadius: 100, backgroundColor: "#5e77f9", alignSelf: "center" }}
            />
            <Text
               style={{
                  marginLeft: 5,
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  flex: 1,
                  textAlign: "left",
               }}
            >
               Asistencia
            </Text>
            <Text
               style={{
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  flex: 1,
                  textAlign: "center",
               }}
            >
               {data[1]}
            </Text>
            <Text
               style={{
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  flex: 1,
                  textAlign: "center",
               }}
            >
               {`${data[1] > 0 ? (data[1] / (data[0] + data[1])) * 100 : 0}%`}
            </Text>
         </View>
      </View>
   );

   const CommitmentsComponent = (chart, data) => (
      <View
         style={{
            flex: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
         }}
      >
         <Text
            style={{
               fontSize: 9,
               fontFamily: "Open Sans",
               textAlign: "center",
               marginBottom: 10,
            }}
         >
            Estatus de acuerdos
         </Text>
         <Image style={{ width: 50, height: "auto" }} src={getImage(chart)} />

         <View
            style={{
               display: "flex",
               flexDirection: "row",
               borderBottom: 0.5,
               paddingVertical: 3,
               width: 110,
            }}
         >
            <View
               style={{ marginLeft: 5, padding: 3, borderRadius: 100, backgroundColor: "#fff", alignSelf: "center" }}
            />
            <Text
               style={{
                  fontSize: 5,
                  marginLeft: 5,

                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  flex: 1,
                  textAlign: "left",
               }}
            >
               Estado
            </Text>
            <Text
               style={{
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  flex: 1,
                  textAlign: "center",
               }}
            >
               Totales
            </Text>
            <Text
               style={{
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  flex: 1,
                  textAlign: "center",
               }}
            >
               %
            </Text>
         </View>
         <View
            style={{
               display: "flex",
               flexDirection: "row",
               paddingTop: 3,
               width: 110,
            }}
         >
            <View
               style={{
                  marginLeft: 5,
                  padding: 3,
                  borderRadius: 100,
                  backgroundColor: "#8a95a0",
                  alignSelf: "center",
               }}
            />
            <Text
               style={{
                  fontSize: 5,
                  marginLeft: 5,
                  fontFamily: "Open Sans",
                  flex: 1,
                  textAlign: "left",
               }}
            >
               Por hacer
            </Text>
            <Text
               style={{
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  flex: 1,
                  textAlign: "center",
               }}
            >
               {data[0]}
            </Text>
            <Text
               style={{
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  flex: 1,
                  textAlign: "center",
               }}
            >
               {`${data[0] > 0 ? Math.floor((data[0] / (data[0] + data[1] + data[2] + data[3] + data[4])) * 100) : 0}%`}
            </Text>
         </View>
         <View
            style={{
               display: "flex",
               flexDirection: "row",
               paddingTop: 3,
               width: 110,
            }}
         >
            <View
               style={{ marginLeft: 5, padding: 3, borderRadius: 100, backgroundColor: "#5e77f9", alignSelf: "center" }}
            />
            <Text
               style={{
                  marginLeft: 5,
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  flex: 1,
                  textAlign: "left",
               }}
            >
               En proceso
            </Text>
            <Text
               style={{
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  flex: 1,
                  textAlign: "center",
               }}
            >
               {data[1]}
            </Text>
            <Text
               style={{
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  flex: 1,
                  textAlign: "center",
               }}
            >
               {`${data[1] > 0 ? Math.floor((data[1] / (data[0] + data[1] + data[2] + data[3] + data[4])) * 100) : 0}%`}
            </Text>
         </View>
         <View
            style={{
               display: "flex",
               flexDirection: "row",
               width: 110,
               paddingTop: 3,
            }}
         >
            <View
               style={{ marginLeft: 5, padding: 3, borderRadius: 100, backgroundColor: "#f39c12", alignSelf: "center" }}
            />
            <Text
               style={{
                  marginLeft: 5,
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  flex: 1,
                  textAlign: "left",
               }}
            >
               En revisión
            </Text>
            <Text
               style={{
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  flex: 1,
                  textAlign: "center",
               }}
            >
               {data[2]}
            </Text>
            <Text
               style={{
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  flex: 1,
                  textAlign: "center",
               }}
            >
               {`${data[2] > 0 ? Math.floor((data[2] / (data[0] + data[1] + data[2] + data[3] + data[4])) * 100) : 0}%`}
            </Text>
         </View>
         <View
            style={{
               display: "flex",
               flexDirection: "row",
               width: 110,
               paddingTop: 3,
            }}
         >
            <View
               style={{ marginLeft: 5, padding: 3, borderRadius: 100, backgroundColor: "#28b463", alignSelf: "center" }}
            />
            <Text
               style={{
                  marginLeft: 5,
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  flex: 1,
                  textAlign: "left",
               }}
            >
               Completadas
            </Text>
            <Text
               style={{
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  flex: 1,
                  textAlign: "center",
               }}
            >
               {data[3]}
            </Text>
            <Text
               style={{
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  flex: 1,
                  textAlign: "center",
               }}
            >
               {`${data[3] > 0 ? Math.floor((data[3] / (data[0] + data[1] + data[2] + data[3] + data[4])) * 100) : 0}%`}
            </Text>
         </View>
         <View
            style={{
               display: "flex",
               flexDirection: "row",
               width: 110,
               paddingTop: 3,
            }}
         >
            <View
               style={{ marginLeft: 5, padding: 3, borderRadius: 100, backgroundColor: "#d20000", alignSelf: "center" }}
            />
            <Text
               style={{
                  marginLeft: 5,
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  flex: 1,
                  textAlign: "left",
               }}
            >
               Vencidos
            </Text>
            <Text
               style={{
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  flex: 1,
                  textAlign: "center",
               }}
            >
               {data[4]}
            </Text>
            <Text
               style={{
                  fontSize: 5,
                  fontFamily: "Open Sans",
                  fontWeight: 600,
                  flex: 1,
                  textAlign: "center",
               }}
            >
               {`${data[4] > 0 ? Math.floor((data[4] / (data[0] + data[1] + data[2] + data[3] + data[4])) * 100) : 0}%`}
            </Text>
         </View>
      </View>
   );

   return (
      <ReportTemplate
         company={props.company}
         governanceTitle={"Panel de usuario"}
         fontColorOnPrimary={fontColorOnPrimary}
         fontColorOnSecondary={fontColorOnSecondary}
      >
         <Text
            style={{
               fontSize: 14,
               fontWeight: 600,
               fontFamily: "Open Sans",
               color: "#162c44",
               marginVertical: 5,
               paddingVertical: 5,
               borderBottom: 1,
               borderColor: props.company.company_details.secondaryColor,
            }}
         >
            EVALUACIÓN DE DESEMPEÑO
         </Text>
         <View
            style={{
               display: "flex",
               flexDirection: "row",
               alignItems: "center",
               justifyContent: "space-between",
               marginBottom: 15,
            }}
         >
            <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
               <View
                  style={{
                     backgroundColor: props.company.company_details.primaryColor,
                     borderRadius: 5,
                  }}
               >
                  <Text
                     style={{
                        fontSize: 9,
                        fontWeight: 600,
                        fontFamily: "Open Sans",
                        color: fontColorOnPrimary,
                        padding: 5,
                        textAlign: "center",
                     }}
                  >
                     03 MAR 2024
                  </Text>
               </View>
               <Text
                  style={{
                     fontSize: 9,
                     fontFamily: "Open Sans",
                     marginLeft: 5,
                  }}
               >
                  Próxima evaluación
               </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
               <View
                  style={{
                     backgroundColor: props.company.company_details.primaryColor,
                     borderRadius: 5,
                  }}
               >
                  <Text
                     style={{
                        fontSize: 9,
                        fontWeight: 600,
                        fontFamily: "Open Sans",
                        color: fontColorOnPrimary,
                        padding: 5,
                        textAlign: "center",
                     }}
                  >
                     03 MAR 2024
                  </Text>
               </View>
               <Text
                  style={{
                     fontSize: 9,
                     fontFamily: "Open Sans",
                     marginLeft: 5,
                  }}
               >
                  Última evaluación
               </Text>
            </View>
            <View style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
               <View
                  style={{
                     backgroundColor: props.company.company_details.primaryColor,
                     borderRadius: 5,
                  }}
               >
                  <Text
                     style={{
                        fontSize: 9,
                        fontWeight: 600,
                        fontFamily: "Open Sans",
                        color: fontColorOnPrimary,
                        padding: 5,
                        textAlign: "center",
                     }}
                  >
                     12
                  </Text>
               </View>
               <Text
                  style={{
                     fontSize: 9,
                     fontFamily: "Open Sans",
                     marginLeft: 5,
                  }}
               >
                  Evaluaciónes realizadas
               </Text>
            </View>
         </View>
         {props.governanceData
            .filter((g) => g.title !== "PANEL DE USUARIO")
            .map((body, index) => (
               <View key={index} style={{ marginBottom: 15 }}>
                  <Text
                     style={{
                        fontSize: 14,
                        fontWeight: 600,
                        fontFamily: "Open Sans",
                        color: "#162c44",
                        marginBottom: 5,
                        paddingVertical: 5,
                        borderBottom: 1,
                        borderColor: props.company.company_details.secondaryColor,
                     }}
                  >
                     {body.title.toUpperCase()}
                  </Text>
                  <View
                     style={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: 5,
                     }}
                  >
                     {YearlyPerformanceComponent(
                        props.chartRefs.find((c) => c.governance === body._id).yearlyPerformanceRef
                     )}
                     {SessionsComponent(
                        props.chartRefs.find((c) => c.governance === body._id).sessionsGraphRef,
                        props.chartRefs.find((c) => c.governance === body._id).sessionData
                     )}
                     {CommitmentsComponent(
                        props.chartRefs.find((c) => c.governance === body._id).commitmentsGraphRef,
                        props.chartRefs.find((c) => c.governance === body._id).commitmentsData
                     )}
                     <View
                        style={{
                           flex: 0.7,
                           display: "flex",
                           flexDirection: "column",
                           justifyContent: "space-between",
                           alignItems: "center",
                        }}
                     >
                        <View
                           style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                           }}
                        >
                           <Text
                              style={{
                                 fontSize: 7,
                                 fontFamily: "Open Sans",
                                 textAlign: "center",
                              }}
                           >
                              Acuerdos asignados
                           </Text>
                           <View
                              style={{
                                 backgroundColor: props.company.company_details.primaryColor,
                                 borderRadius: 5,
                                 marginTop: 2,
                              }}
                           >
                              <Text
                                 style={{
                                    fontSize: 8,
                                    fontWeight: 600,
                                    fontFamily: "Open Sans",
                                    color: fontColorOnPrimary,
                                    padding: 5,
                                    textAlign: "center",
                                    width: 60,
                                 }}
                              >
                                 {props.chartRefs.find((c) => c.governance === body._id).assignedCommitments}
                              </Text>
                           </View>
                        </View>
                        <View
                           style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                           }}
                        >
                           <Text
                              style={{
                                 fontSize: 7,
                                 fontFamily: "Open Sans",
                                 textAlign: "center",
                              }}
                           >
                              {breakLine("Porcentaje de cumplimiento")}
                           </Text>
                           <View
                              style={{
                                 backgroundColor: props.company.company_details.primaryColor,
                                 borderRadius: 5,
                                 marginTop: 2,
                              }}
                           >
                              <Text
                                 style={{
                                    fontSize: 8,
                                    fontWeight: 600,
                                    fontFamily: "Open Sans",
                                    color: fontColorOnPrimary,
                                    padding: 5,
                                    textAlign: "center",
                                    width: 60,
                                 }}
                              >
                                 {props.chartRefs.find((c) => c.governance === body._id).compliancePercentage}
                              </Text>
                           </View>
                        </View>
                        <View
                           style={{
                              display: "flex",
                              flexDirection: "column",
                              justifyContent: "center",
                              alignItems: "center",
                              width: "100%",
                           }}
                        >
                           <Text
                              style={{
                                 fontSize: 7,
                                 fontFamily: "Open Sans",
                                 textAlign: "center",
                              }}
                           >
                              Última actualización
                           </Text>
                           <View
                              style={{
                                 backgroundColor: props.company.company_details.primaryColor,
                                 borderRadius: 5,
                                 marginTop: 2,
                              }}
                           >
                              <Text
                                 style={{
                                    fontSize: 8,
                                    fontWeight: 600,
                                    fontFamily: "Open Sans",
                                    color: fontColorOnPrimary,
                                    padding: 5,
                                    textAlign: "center",
                                    width: 60,
                                 }}
                              >
                                 {props.chartRefs.find((c) => c.governance === body._id).updatedAt}
                              </Text>
                           </View>
                        </View>
                     </View>
                  </View>
               </View>
            ))}
      </ReportTemplate>
   );
};

Font.register({
   family: "Open Sans",
   fonts: [
      { src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf" },
      { src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf", fontWeight: 600 },
      { src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-800.ttf", fontWeight: 800 },
   ],
});
