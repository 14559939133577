import { Typography, Stack, Box, Button, IconButton, CircularProgress } from "@mui/material";
import React, { useCallback, useContext, useState } from "react";
import { InputCheckBox } from "../../Inputs/InputCheckBox";
import { Formik, FormikProps } from "formik";
import { Form } from "react-router-dom";
import ImageIcon from "@mui/icons-material/Image";
import { useDropzone } from "react-dropzone";
import { uploadFileToS3, UseGetPresignedURLUploadExternal } from "../../../lib/s3Client";
import { getUrlS3Token } from "../../../lib/usersBEClient";
import ClearIcon from "@mui/icons-material/Clear";
import { ExternalGovernanceSessionContext } from "../../../context/governanceContext/externalSessionContext";
import { SnackBarContext } from "../../../context/snackBarContext";

export const SignModalExternal = ({ setOpenModalToSign, session }) => {
   const [file, setFile] = useState(null);
   const [fileTemp, setFileTemp] = useState(null);
   const [alertFrom, setAlertFrom] = useState(false);
   const [isLoadingSubmit, setIsLoadingSubmit] = useState(false);
   const { socket, valuesFromBill, externalSessionToken, userId } = useContext(ExternalGovernanceSessionContext);
   const { showSnackBar } = useContext(SnackBarContext);

   const userCommitments = valuesFromBill["commitmentsArray"].filter((commitments) =>
      commitments.users.some((userInCommitment) => userInCommitment._id === userId)
   );

   const commitments = userCommitments.map((commitment) => commitment.activity);

   const handleSubmit = async (values) => {
      try {
         let allCommitmentsAccepted = true;
         commitments.forEach((commitment, index) => {
            if (values["commitmentSign" + index] === false) {
               allCommitmentsAccepted = false;
            }
         });
         if (!file || !allCommitmentsAccepted) {
            setAlertFrom(true);
            return;
         } else {
            setIsLoadingSubmit(true);
            const urlReceipt1 = await UseGetPresignedURLUploadExternal({
               bucket: "files-lecosy",
               folder: `gc/companies/${session.company}/governing-body/${session._id}`,
               name: userId + file.name,
               token: externalSessionToken,
            });

            await uploadFileToS3(urlReceipt1.data, file);

            const documentUrl: string = await getUrlS3Token(
               "files-lecosy",
               { folder: `gc/companies/${session.company}/governing-body/${session._id}`, token: externalSessionToken },
               userId + file.name
            );

            const userFound = session.externs.find((extern) => extern.user === userId);
            socket.emit("send-sing-external", {
               ...userFound,
               request: "sign",
               sessionId: session._id,
               file: documentUrl,
               external: true,
            });
            setOpenModalToSign(false);
            setIsLoadingSubmit(false);
         }
      } catch (error) {
         setIsLoadingSubmit(false);
      }
   };

   let initialValues = {};

   commitments.forEach((commitment, index) => {
      initialValues = { ...initialValues, ["commitmentSign" + index]: false };
   });

   const onDrop = useCallback(
      (acceptedFiles) => {
         if (acceptedFiles.length < 1) return showSnackBar("Favor de seleccionar un archivo valido.", true);
         if (
            (acceptedFiles[0].type.includes("png") ||
               acceptedFiles[0].type.includes("jpeg") ||
               acceptedFiles[0].type.includes("jpg")) &&
            acceptedFiles.size > 5000000
         )
            return showSnackBar("Archivo mayor a 5 MB", true);
         setFileTemp(URL.createObjectURL(acceptedFiles[0]));
         setFile(acceptedFiles[0]);
      },
      [showSnackBar, setFile]
   );

   const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: {
         "application/image": [".png", ".jpeg", ".jpg"],
      },
      maxFiles: 1,
   });
   return (
      <Formik initialValues={initialValues} onSubmit={handleSubmit}>
         {(formProps: FormikProps<any>) => (
            <Form>
               <Box
                  sx={{
                     py: 1,
                     bgcolor: "#152c44",
                     borderTopLeftRadius: 15,
                     borderTopRightRadius: 15,
                     display: "flex",
                     justifyContent: "space-between",
                     alignItems: "center",
                     minWidth: 400,
                  }}
               >
                  <Typography sx={{ color: "white", ml: 4, fontWeight: 600 }}>Firmar minuta</Typography>
               </Box>
               <Stack
                  spacing={3}
                  sx={{
                     bgcolor: "background.paper",
                     py: 3,
                     px: 6,
                     borderBottomLeftRadius: 15,
                     borderBottomRightRadius: 15,
                  }}
               >
                  <Stack spacing={1}>
                     {commitments?.length > 0 && (
                        <>
                           <Stack>
                              <Box sx={{ bgcolor: "#EBEBEB", py: 1 }}>
                                 <Typography sx={{ fontWeight: 600, textAlign: "center" }}>
                                    Acuerdos y compromisos establecidos
                                 </Typography>
                              </Box>
                              <Stack spacing={1} sx={{ border: 1, borderColor: "#EBEBEB", py: 1 }}>
                                 {commitments.map((commitment, index) => (
                                    <Box sx={{ display: "flex", alignItems: "center" }}>
                                       <InputCheckBox
                                          id={"commitmentSign" + index}
                                          name={"commitmentSign" + index}
                                          label={""}
                                          sx={{ p: 0, ml: 3 }}
                                          size="medium"
                                       />
                                       <Typography>{commitment}</Typography>
                                    </Box>
                                 ))}
                              </Stack>
                           </Stack>
                           {alertFrom && (
                              <Typography sx={{ color: "red", fontSize: 12 }}>
                                 Favor de aceptar todos los compromisos y adjuntar la firma
                              </Typography>
                           )}
                        </>
                     )}
                     <Stack
                        spacing={2}
                        sx={{
                           flexDirection: { xs: "column", lg: "row" },
                           justifyContent: "space-between",
                           alignItems: "center",
                        }}
                     >
                        <Box>
                           <Typography sx={{ color: "#D8D8D8", fontSize: { xs: 14, lg: 12 } }}>Vista previa</Typography>
                           {file ? (
                              <Stack
                                 sx={{
                                    border: 1,
                                    borderColor: "#EBEBEB",
                                    width: { xs: 200, lg: 159 },
                                    height: { xs: 200, lg: 112 },
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    position: "relative",
                                 }}
                              >
                                 <IconButton
                                    sx={{ position: "absolute", left: "75%", bottom: "65%", color: "black" }}
                                    onClick={() => {
                                       setFile(null);
                                       setFileTemp(null);
                                    }}
                                 >
                                    <ClearIcon />
                                 </IconButton>
                                 <img
                                    src={fileTemp}
                                    alt="Firma"
                                    style={{ maxWidth: "100%", maxHeight: "100%", objectFit: "cover" }}
                                 />
                              </Stack>
                           ) : (
                              <Stack
                                 sx={{
                                    border: 1,
                                    borderColor: "#EBEBEB",
                                    width: { xs: 200, lg: 159 },
                                    height: { xs: 200, lg: 112 },
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                 }}
                                 {...getRootProps()}
                              >
                                 <input {...getInputProps()} />
                                 <ImageIcon sx={{ color: "#EBEBEB", fontSize: { xs: 150, lg: 80 } }} />
                                 <Typography
                                    sx={{ color: "#D8D8D8", fontSize: { xs: 14, lg: 12 }, textAlign: "center" }}
                                 >
                                    Subir imagen
                                 </Typography>
                              </Stack>
                           )}
                        </Box>
                        <Button
                           type="submit"
                           variant="contained"
                           sx={{ bgcolor: "#2D4357", color: "white", alignSelf: { xs: "center", lg: "flex-end" } }}
                           onClick={() => formProps.submitForm()}
                           disabled={isLoadingSubmit}
                        >
                           {!isLoadingSubmit ? `Firma minuta` : <CircularProgress sx={{ color: "white" }} size={24} />}
                        </Button>
                     </Stack>
                  </Stack>
               </Stack>
            </Form>
         )}
      </Formik>
   );
};
