import { useContext, useEffect, useMemo, useState } from "react";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";
import { getNumberSessionById } from "../../../../lib/gobCorpBEClient";
import { CircularProgress, Typography } from "@mui/material";
import { UserContext } from "../../../../context/userContext";
import { useParams } from "react-router-dom";

const useGetTitle = (assembly, setCode) => {
   const { companyId } = useParams();
   const { selectedGovernance, consultiveGroup } = useContext(GovernanceContext);
   const { companySelected, user } = useContext(UserContext);
   const [numberOfSessions, setNumberOfSessions] = useState("0");
   const date = new Date();

   const isConsultiveGroup = useMemo(
      () =>
         consultiveGroup &&
         consultiveGroup?._id === companyId &&
         selectedGovernance?.title !== "Asamblea de accionistas" &&
         selectedGovernance.company === consultiveGroup?._id,
      [consultiveGroup, selectedGovernance]
   );

   useEffect(() => {
      const fetchCompany = async () => {
         const responseNumber = await getNumberSessionById(selectedGovernance._id);
         setNumberOfSessions(responseNumber);
      };
      fetchCompany();
   }, [companySelected, selectedGovernance._id]);

   const title = useMemo(() => {
      const company = isConsultiveGroup
         ? user?.group?.name?.substring(0, 2)?.toUpperCase()
         : companySelected?.person_details?.businessName?.substring(0, 2)?.toUpperCase();

      const month = date
         .toLocaleDateString("es-MX", {
            month: "long",
         })
         .substring(0, 3)
         .toUpperCase();
      const year = date
         .toLocaleDateString("es-MX", {
            year: "2-digit",
         })
         .substring(0, 3);
      const type = assembly ? `A` : `S`;
      const number = parseInt(numberOfSessions) + 1;
      const code = company + `_` + type + month + `_` + year + `_` + number;
      setCode(code);
      return code;
   }, [companySelected, numberOfSessions]);

   return { title };
};

export const SessionTitle = ({ assembly, setCode }: { assembly: boolean; setCode: Function }) => {
   const { title } = useGetTitle(assembly, setCode);
   return title ? <Typography>{title}</Typography> : <CircularProgress size={20} />;
};
