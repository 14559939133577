import { Box, Typography } from "@mui/material";
import { SessionCommitment } from "./SubComponents/SessionCommitment";
import { useContext, useMemo } from "react";
import { GovernanceSessionContext } from "../../../context/governanceContext/governanceSessionContext";
import { UserContext } from "../../../context/userContext";

export const SessionCommitments = () => {
   const { valuesFromBill, isLoadingSession } = useContext(GovernanceSessionContext);
   const { user } = useContext(UserContext);

   const filteredCommitments = useMemo(() => {
      return (
         valuesFromBill["commitmentsArray"]?.length > 0 &&
         valuesFromBill["commitmentsArray"].filter((commitment) =>
            commitment?.users?.some((userCommitment) => userCommitment._id === user.id)
         )
      );
   }, [valuesFromBill["commitmentsArray"], user.id]);

   return (
      <Box sx={{ height: "41%" }}>
         <Box sx={{ bgcolor: "#F8F8F9", border: 1, borderColor: "#E0E0E0", p: 1 }}>
            <Typography sx={{ fontWeight: 600 }}>Mis acuerdos y compromisos</Typography>
            <Typography sx={{ fontSize: 9, color: "#64748B" }}>
               Acuerdos y compromisos asignados durante la sesión actual.
            </Typography>
         </Box>
         <Box
            sx={{
               bgcolor: "white",
               border: 0.65,
               borderTop: 0,
               borderColor: "#CBD5E0",
               height: "84%",
               overflowY: "auto",
            }}
         >
            {!isLoadingSession &&
               filteredCommitments?.length > 0 &&
               filteredCommitments.map((commitment) => <SessionCommitment commitment={commitment} />)}
         </Box>
      </Box>
   );
};
