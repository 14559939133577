import {
   Box,
   Divider,
   FormControl,
   Grid,
   IconButton,
   InputBase,
   MenuItem,
   Select,
   Stack,
   Typography,
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";
import { getAffairsByClassification, getAffairsByCompany } from "../../../../lib/gobCorpBEClient";
import { AffairSelector } from "./AffairSelector";
import Close from "@mui/icons-material/Close";

const useGetAffairs = (classification) => {
   const [affairs, setAffairs] = useState([]);
   const [isLoading, setisLoading] = useState(true);
   const { selectedGovernance } = useContext(GovernanceContext);

   useEffect(() => {
      const fetchAffairsByClassification = async () => {
         setisLoading(true);

         if (classification === "Mixta" || classification === "Especiales") {
            const response = await getAffairsByCompany(selectedGovernance.company);
            let affairs = response.data;
            setAffairs(affairs);
         } else {
            const response = await getAffairsByClassification(selectedGovernance.company, classification);
            setAffairs(response);
         }
         setisLoading(false);
      };
      if (classification) {
         fetchAffairsByClassification();
      } else {
         setisLoading(false);
      }
   }, [classification, selectedGovernance]);

   return { affairs, isLoading };
};

export const AffairsComponent = ({ affairAdded, setAffairAdded, inputProps, classification, functions }) => {
   const { affairs, isLoading } = useGetAffairs(classification);
   const { consultiveGroup, companySelected, selectedGovernance, consultiveGroupCompanies } =
      useContext(GovernanceContext);

   const consultiveGroupAffairs = useMemo(
      () =>
         consultiveGroup?._id === companySelected &&
         selectedGovernance.title !== "Asamblea de accionistas" &&
         selectedGovernance.company === consultiveGroup?._id &&
         consultiveGroupCompanies?.length > 0,
      [consultiveGroup, consultiveGroupCompanies]
   );

   if (isLoading) return <></>;

   const handleClick = (value) => {
      const same = affairAdded.filter((valueF) => value._id === valueF.affair._id);
      const newValues = [
         ...affairAdded,
         {
            affair: classification ? value : value.toString(),
            description: "",
            companies: consultiveGroupAffairs ? undefined : null,
            index: same.length,
         },
      ];
      setAffairAdded(newValues);
   };

   const removeAffair = (value) => {
      if (classification) {
         const same = affairAdded.filter((valueF) => value._id !== valueF.affair._id && value.index !== valueF.index);
         setAffairAdded(same);
      } else {
         const same = affairAdded.filter((valueF) => value !== valueF.affair && value.index !== valueF.index);
         setAffairAdded(same);
      }
   };

   return (
      <>
         <Typography variant="body2" fontWeight={600}>
            Asuntos a tratar
         </Typography>
         <FormControl fullWidth>
            <AffairSelector
               inputProps={inputProps}
               options={
                  functions.length > 0 && !classification
                     ? functions.map((option) => (
                          <div key={option.title}>
                             <Divider />

                             <MenuItem
                                key={option}
                                value={option}
                                sx={{ maxWidth: 600 }}
                                onClick={() => handleClick(option)}
                             >
                                <Typography
                                   variant="body1"
                                   sx={{
                                      maxWidth: 500,
                                      textWrap: "wrap",
                                      textAlign: "justify",
                                      py: 1,
                                   }}
                                >
                                   {classification ? option.title : option}
                                </Typography>
                             </MenuItem>
                          </div>
                       ))
                     : affairs.map((option) => (
                          <div key={option.title}>
                             <Divider />
                             <MenuItem
                                key={option}
                                value={option}
                                sx={{ maxWidth: 600 }}
                                onClick={() => handleClick(option)}
                             >
                                <Typography
                                   variant="body1"
                                   sx={{
                                      maxWidth: 500,
                                      textWrap: "wrap",
                                      textAlign: "justify",
                                      py: 1,
                                   }}
                                >
                                   {classification ? option.title : option}
                                </Typography>
                             </MenuItem>
                          </div>
                       ))
               }
            />
         </FormControl>

         <Typography variant="body2" fontWeight={600}>
            Orden del día
         </Typography>
         <Grid container spacing={0.2}>
            <Grid item xs={affairAdded.length > 0 ? 4 : 5}>
               <Box
                  sx={{
                     bgcolor: "#F0F0F0",
                     display: "flex",
                     justifyContent: "center",
                     p: 0.5,
                     borderRadius: 1,
                     fontSize: 14,
                  }}
               >
                  Asunto
               </Box>
            </Grid>
            <Grid item xs={consultiveGroupAffairs ? 4 : 7}>
               <Box
                  sx={{
                     bgcolor: "#F0F0F0",
                     display: "flex",
                     justifyContent: "center",
                     p: 0.5,
                     borderRadius: 1,
                     fontSize: 14,
                  }}
               >
                  Descripción
               </Box>
            </Grid>
            {consultiveGroupAffairs && (
               <Grid item xs={3}>
                  <Box
                     sx={{
                        bgcolor: "#F0F0F0",
                        display: "flex",
                        justifyContent: "center",
                        p: 0.5,
                        borderRadius: 1,
                        fontSize: 14,
                     }}
                  >
                     Empresa(s)
                  </Box>
               </Grid>
            )}

            {affairAdded.length > 0 ? (
               affairAdded.map((affair, i) => (
                  <>
                     <Grid item xs={affairAdded.length > 0 ? 4 : 5}>
                        <Box sx={{ bgcolor: "#FAFAFA", p: 1, height: "100%", fontSize: 14 }}>
                           {classification ? affair.affair.title : affair.affair}
                        </Box>
                     </Grid>
                     <Grid item xs={consultiveGroupAffairs ? 4 : 7}>
                        <InputBase
                           name={affair.affair}
                           value={affair.description}
                           sx={{ height: "100%", border: 1, borderColor: "#F0F0F0", px: 1, fontSize: 14 }}
                           fullWidth
                           multiline
                           onChange={(e) => {
                              affairAdded[i]["description"] = e.target.value;
                              const newValue = affairAdded;
                              setAffairAdded(newValue);
                           }}
                        />
                     </Grid>
                     {consultiveGroupAffairs && (
                        <Grid item xs={3}>
                           <Select
                              fullWidth
                              multiple
                              size="small"
                              defaultValue={[]}
                              value={affair.companies}
                              sx={{
                                 height: "100%",
                                 ".MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #F0F0F0",
                                 },
                                 "&:hover .MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #F0F0F0",
                                 },
                                 "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                    border: "1px solid #F0F0F0",
                                 },
                                 borderRadius: 0,
                              }}
                              onChange={(e) => {
                                 affairAdded[i]["companies"] = e.target.value.map((c) => c._id);
                                 const newValue = affairAdded;
                                 setAffairAdded(newValue);
                              }}
                              renderValue={(company) => (
                                 <Stack spacing={1}>
                                    {company.map((c) => (
                                       <Typography
                                          key={`selectedC-${c?._id}`}
                                          sx={{
                                             bgcolor: "#E9EDF5",
                                             borderRadius: 2,
                                             textAlign: "center",
                                             fontSize: 14,
                                             px: 1,
                                          }}
                                       >
                                          {c?.person_details?.comercialName?.length > 10
                                             ? `${c.person_details.comercialName.slice(0, 10)}...`
                                             : c?.person_details?.comercialName || ""}
                                       </Typography>
                                    ))}
                                 </Stack>
                              )}
                           >
                              {consultiveGroupCompanies
                                 .filter((com) => {
                                    if (selectedGovernance.title === "Consejo de administración")
                                       return consultiveGroup.companies.includes(com._id);
                                    else return com;
                                 })
                                 .map((c) => (
                                    <MenuItem value={c} key={`menuItem-${c._id}`}>
                                       {c?.person_details?.comercialName}
                                    </MenuItem>
                                 ))}
                           </Select>
                        </Grid>
                     )}
                     <Grid item xs={1} sx={{ alignSelf: "center" }}>
                        <IconButton onClick={() => removeAffair(affair)}>
                           <Close color="error" />
                        </IconButton>
                     </Grid>
                  </>
               ))
            ) : (
               <Grid item xs={12} container justifyContent="center">
                  Favor de seleccionar un asunto
               </Grid>
            )}
         </Grid>
      </>
   );
};
