import { Box, Checkbox, Collapse, Divider, ListItem, Stack, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { AddUserContext } from "../../../context/addUserContext";
import { InputMap } from "./InputMap";

const ComplaintChannelModule = ({ module }) => {
   const { removeModule } = useContext(AddUserContext);
   const [open, setOpen] = useState(false);

   return (
      <>
         <Box sx={{ display: "flex", alignItems: "center" }}>
            <Checkbox
               checked={open}
               onChange={() => {
                  if (open) {
                     removeModule(module.service.id);
                  }
                  setOpen(!open);
               }}
            />
            <ListItem>{module.service.serviceName}</ListItem>
         </Box>
         <Divider />
         <Collapse in={open}>
            <Stack spacing={2} sx={{ p: 2, border: 1, borderColor: "#F2F2F2", display: "flex" }} direction={"column"}>
               {open && (
                  <>
                     <InputMap module={module} name={"rol"} roles={module.service.roles} service={module.service.id} />
                     {module.branches && module.branches.length > 0 ? (
                        <InputMap
                           module={module}
                           name={"sucursal"}
                           roles={module.branches}
                           service={module.service.id}
                           branch={true}
                        />
                     ) : (
                        <Typography>La empresa no tiene sucursales</Typography>
                     )}
                  </>
               )}
            </Stack>
         </Collapse>
      </>
   );
};

export default ComplaintChannelModule;
