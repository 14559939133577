import { Box, Card, CircularProgress, Divider, Stack, Typography } from "@mui/material";
import { SearchBarMembers } from "./SearchBarMembers";
import { useContext, useState } from "react";
import { MemberModal } from "./MemberModal";
import { MembersPagination } from "./MembersPagination";
import { CustomModalComponent } from "../../CustomModalComponent";
import { GovernanceTheSequelContext } from "../../../context/governanceContext/governanceTheSequelContext";
import _ from "lodash";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { useParams } from "react-router-dom";

interface MemberData {
   firstName: string;
   lastName: string;
   email: string;
   chargeName: string;
   characters: string[];
}

export const MembersContainer = () => {
   const { companyId } = useParams();
   const { loadingFormat, textValue, setTextValue, finalMembers } = useContext(GovernanceTheSequelContext);
   const { consultiveGroup, consultiveGroupCompanies } = useContext(GovernanceContext);
   const [userId, setUserId] = useState("");
   const [memberData, setMemberData] = useState<MemberData>({
      email: "",
      firstName: "",
      lastName: "",
      chargeName: "",
      characters: [],
   });
   const [openModal, setOpenModal] = useState(false);

   const handleClose = () => {
      setOpenModal(false);
      setUserId("");
   };

   return (
      <Card sx={{ px: 3, py: 2, bgcolor: "white", borderRadius: 2 }}>
         <SearchBarMembers setValue={setTextValue} value={textValue} />
         <Stack direction={"row"} sx={{ overflowX: "auto", pb: 1 }} spacing={4} mt={2}>
            {loadingFormat || !finalMembers ? (
               <Box
                  sx={{
                     width: "100%",
                     height: "50vh",
                     display: "flex",
                     justifyContent: "center",
                     alignItems: "center",
                  }}
               >
                  <CircularProgress />
               </Box>
            ) : (
               finalMembers.map((body, index) => {
                  let governingBodyTitle = body.bodiesData.title;
                  if (consultiveGroup?._id === companyId && consultiveGroupCompanies.length > 0) {
                     const companyName = consultiveGroupCompanies.find(
                        (company) => company._id === body.bodiesData.company
                     )?.person_details?.comercialName;
                     governingBodyTitle = companyName
                        ? `${companyName} - ${body.bodiesData.title}`
                        : body.bodiesData.title;
                  }
                  const uniqUsers = body.usersData.filter((user, index, ary) => !_.isEqual(user, ary[index - 1]));
                  return (
                     <Box
                        key={`${index}-${body.bodiesData._id}`}
                        sx={{
                           display: "flex",
                           flexDirection: "column",
                           minWidth: "300px",
                           height: 615,
                           borderRadius: 2.5,
                           border: 1,
                           borderColor: "#D4D4D4",
                        }}
                     >
                        <Stack
                           sx={{
                              display: "flex",
                              alignItems: "center",
                              pt: 3,
                              width: "100%",
                           }}
                        >
                           <Typography
                              fontWeight={600}
                              fontSize={"17px"}
                              color={"#3F5063"}
                              textAlign={"center"}
                              sx={{
                                 maxWidth: 280,
                                 overflow: "hidden",
                                 textOverflow: "ellipsis",
                                 whiteSpace: "nowrap",
                              }}
                           >
                              {governingBodyTitle}
                           </Typography>
                        </Stack>
                        <Divider variant="middle" sx={{ borderBottomWidth: 2 }} color="#3F5063" />
                        <MembersPagination
                           users={uniqUsers}
                           setUserId={setUserId}
                           setMemberData={setMemberData}
                           setState={setOpenModal}
                        />
                     </Box>
                  );
               })
            )}
         </Stack>
         <CustomModalComponent title="Ficha de usuario" open={openModal} setOpen={handleClose}>
            <MemberModal state={openModal} setState={handleClose} dataId={userId} memberData={memberData} />
         </CustomModalComponent>
      </Card>
   );
};
