import { Box, Button, Card, CardActions, CardContent, Collapse, IconButton, Typography } from "@mui/material";
import { Delete } from "@mui/icons-material";
import { useCallback, useContext } from "react";
import { SnackBarContext } from "../../context/snackBarContext";
import { useDropzone } from "react-dropzone";
import { CustomModalComponent } from "../CustomModalComponent";
import { formatFileSize } from "../../const/globalConst";

interface AddFileGovernBodyModalProps {
   state: boolean;
   setState: Function;
   file?: any;
   setFile?: Function;
   multiple?: boolean;
}

export const AddFileModal = (props: AddFileGovernBodyModalProps) => {
   const { showSnackBar } = useContext(SnackBarContext);

   const onDrop = useCallback(
      (acceptedFiles) => {
         if (acceptedFiles.length === 0) return showSnackBar("Favor de adjuntar un documento válido", true);
         for (const file of acceptedFiles) {
            if (file.type !== "application/pdf") return showSnackBar("Archivo inválido", true);
            if (file.size > 20000000) return showSnackBar("Archivo mayor a 20 MB", true);
         }
         if (props.multiple) return props.setFile(acceptedFiles);
         else return props.setFile(acceptedFiles[0]);
      },
      [showSnackBar, props.setFile, props.multiple]
   );

   const { getRootProps, getInputProps } = useDropzone({
      onDrop,
      accept: {
         "application/pdf": [".pdf"],
      },
      maxFiles: props.multiple ? 50 : 1,
   });

   return (
      <>
         <CustomModalComponent title="Agregar archivo" setOpen={props.setState} open={props.state} timeStamp>
            <Box sx={{ display: "flex", justifyContent: "center", flexDirection: "column", p: 2, minWidth: 450 }}>
               <Box
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "center",
                     flexDirection: "column",
                     flex: 2,
                  }}
               >
                  <Box
                     sx={{
                        flex: 1,
                        minWidth: "80%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mb: 2,
                     }}
                  >
                     {(!props.file || (props.multiple && props.file.lenght === 0)) && (
                        <Box
                           sx={{
                              flex: 1,
                              border: 1,
                              borderStyle: "dashed",
                              borderRadius: 2,
                              minHeight: 150,
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                           }}
                           {...getRootProps()}
                        >
                           <Typography variant="body2" color={"grey"}>
                              Arrastra y suelta dentro del recuadro los archivos
                           </Typography>
                           <input {...getInputProps()} />
                        </Box>
                     )}
                  </Box>
                  {!props.file && (
                     <Box
                        sx={{
                           flex: 1,
                           display: "flex",
                           justifyContent: "center",
                           flexDirection: "column",
                           alignItems: "center",
                           gap: 1,
                           mb: 2,
                        }}
                     >
                        <Box sx={{ bgcolor: "whitesmoke", borderRadius: 2, p: 1 }}>
                           <Typography fontSize={12}>Solo archivos PDF, no mayores a 20 MB.</Typography>
                        </Box>
                     </Box>
                  )}
                  <Collapse in={props.file ? true : false}>
                     <Typography align="center">Archivo adjunto:</Typography>
                     <Box sx={{ my: 2, px: 1, maxHeight: 300, overflowY: "auto" }}>
                        {props.file && !props.multiple ? (
                           <Card
                              key={props.file.name}
                              sx={{
                                 display: "flex",
                                 bgcolor: "#F9F9F9",
                                 flexDirection: "row",
                                 justifyContent: "space-between",
                                 mt: 0.5,
                                 boxShadow: 0,
                                 border: 1,
                                 borderColor: "#E2E2E2",
                              }}
                           >
                              <CardContent>
                                 <Typography sx={{ fontSize: 14, flexWrap: "nowrap" }}>{props.file.name}</Typography>
                              </CardContent>
                              <CardActions>
                                 <Typography sx={{ fontSize: 12 }}>{formatFileSize(props.file.size)}</Typography>
                                 <IconButton
                                    onClick={() => {
                                       props.setFile(null);
                                    }}
                                 >
                                    <Delete sx={{ color: "red" }} />
                                 </IconButton>
                              </CardActions>
                           </Card>
                        ) : (
                           props.multiple &&
                           props.file &&
                           props.file.map((file) => (
                              <Card
                                 key={file.name}
                                 sx={{
                                    display: "flex",
                                    bgcolor: "#F9F9F9",
                                    flexDirection: "row",
                                    justifyContent: "space-between",
                                    mt: 0.5,
                                    boxShadow: 0,
                                    border: 1,
                                    borderColor: "#E2E2E2",
                                 }}
                              >
                                 <CardContent>
                                    <Typography sx={{ fontSize: 14, flexWrap: "nowrap" }}>{file.name}</Typography>
                                 </CardContent>
                                 <CardActions>
                                    <Typography sx={{ fontSize: 12 }}>{formatFileSize(file.size)}</Typography>
                                    <IconButton
                                       onClick={() => {
                                          const newFiles = props.file.filter((f) => f.name !== file.name);
                                          if (newFiles.length > 0) props.setFile(newFiles);
                                          else props.setFile(null);
                                       }}
                                    >
                                       <Delete sx={{ color: "red" }} />
                                    </IconButton>
                                 </CardActions>
                              </Card>
                           ))
                        )}
                     </Box>
                  </Collapse>
               </Box>
               <Box
                  sx={{
                     display: "flex",
                     justifyContent: "center",
                     flex: 1,
                     py: 2,
                     px: 4,
                  }}
               >
                  <Button
                     onClick={() => {
                        props.setState(false);
                     }}
                     variant="contained"
                     sx={{ maxHeight: 40 }}
                  >
                     Confirmar
                  </Button>
               </Box>
            </Box>
         </CustomModalComponent>
      </>
   );
};
