import { Avatar, Badge, styled } from "@mui/material";

export const SessionAvatar = ({ isOnline, name, noDecoration = false, color = null, imgSrc }) => {
   function stringAvatar(name: string) {
      return {
         sx: {
            color: "#162C44",
            fontWeight: "bold",
            fontSize: 16,
            bgcolor: imgSrc ? "white" : "#BDBDBD",
         },
         children: name.includes(" ")
            ? `${name.split(" ")[0][0]?.toUpperCase()}${name.split(" ")[1][0]?.toUpperCase() || ""}`
            : name.split("")[0],
      };
   }

   const StyledBadge = styled(Badge, {
      shouldForwardProp: (prop) => prop !== "isOnline",
   })<StyledProps>(({ theme, isOnline }) => ({
      "& .MuiBadge-badge": {
         backgroundColor: color ? color : isOnline ? "#44b700" : "gray",
         color: color ? color : isOnline ? "#44b700" : "gray",
         boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
         "&::after": {
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            borderRadius: "50%",
            border: "1px solid currentColor",
            content: '""',
         },
      },
      "@keyframes ripple": {
         "0%": {
            transform: "scale(.8)",
            opacity: 1,
         },
         "100%": {
            transform: "scale(2.4)",
            opacity: 0,
         },
      },
   }));

   interface StyledProps {
      isOnline: boolean;
   }

   return (
      <>
         {noDecoration ? (
            <Avatar {...stringAvatar(name)} src={imgSrc} />
         ) : (
            <StyledBadge
               isOnline={isOnline}
               overlap="circular"
               anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
               variant="dot"
            >
               <Avatar {...stringAvatar(name)} src={imgSrc} />
            </StyledBadge>
         )}
      </>
   );
};
