import { createContext, useCallback, useContext, useEffect, useReducer, useState } from "react";
import { IBeneficiary, IBeneficiaryConfiguration, IIdentifier } from "../../types/beneficiaryTypes";
import {
   CLIENT_ENDPOINT,
   createMultipleFileAsync,
   deleteFilesBC,
   deleteSheetsBCByFoldersId,
   getBeneficiaries,
   getBeneficiariesControl,
   getBeneficiariesShareholder,
   getBeneficiaryApplicationConfig,
   getBeneficiaryDashboardInfo,
   getBeneficiaryDetailsById,
   getCorporateData,
   getIdentifications,
   getSavedInsights,
   getUrlS3,
   host,
} from "../../lib/usersBEClient";
import { UserContext } from "../userContext";
import useGetInsights from "../../hooks/gob-corp/useGetInsights";
import axios from "axios";
import { BASE_ENDPOINT } from "../../lib/lecosyBackendClient";
import { handleInvalidRequest } from "../../lib/gobCorpBEClient";
import { BeneficiarySheetTemplate } from "../../lib/documentsTemplates/beneficiary/beneficiarySheetTemplate";
import { fieldsFormat } from "../../components/MiLecosy/ModalsGovernanceModule/beneficiaryControlSheetModal2";
import { pdf } from "@react-pdf/renderer";
import JSZip from "jszip";
import { CorporateDataContext } from "../governanceContext/corporateDataContext";
import { uploadFileToS3 } from "../../lib/s3Client";

interface BeneficiaryContextType {
   dashboardData: { beneficiariesCount; fileStatus };
   insightsData: { savedInsights; insights };
   beneficiaries: IBeneficiary[];
   efectiveControllers: IBeneficiary[];
   shareHolders: IBeneficiary[];
   identificationManual: string;
   configurationData: IBeneficiaryConfiguration;
   refetch: Function;
   handleDownload: Function;
}

interface BeneficiaryState {
   dashboardData: { beneficiariesCount; fileStatus };
   insightsData: { savedInsights; insights };
   beneficiaries: IBeneficiary[];
   efectiveControllers: IBeneficiary[];
   shareHolders: IBeneficiary[];
   identificationManual: string;
   configurationData: IBeneficiaryConfiguration;
}

const initialState: BeneficiaryState = {
   dashboardData: null,
   insightsData: null,
   beneficiaries: null,
   efectiveControllers: null,
   shareHolders: null,
   identificationManual: null,
   configurationData: null,
};

function userReducer(state: BeneficiaryState, action) {
   switch (action.type) {
      case "SET_DASHBOARD_DATA":
         return { ...state, dashboardData: action.payload };
      case "SET_INSIGHTS_DATA":
         return { ...state, insightsData: action.payload };
      case "SET_BENEFICIARIES":
         return { ...state, beneficiaries: action.payload };
      case "SET_EFECTIVE_CONTROLERS":
         return { ...state, efectiveControllers: action.payload };
      case "SET_SHAREHOLDERS":
         return { ...state, shareHolders: action.payload };
      case "SET_MANUAL":
         return { ...state, identificationManual: action.payload };
      case "SET_CONFIGURATION":
         return { ...state, configurationData: action.payload };
      default:
         return state;
   }
}

export const BeneficiaryControllerContext = createContext<BeneficiaryContextType | undefined>(undefined);

export const BeneficiaryControllerProvider = ({ children }) => {
   const [state, dispatch] = useReducer(userReducer, initialState);
   //#region Dispatch
   const setDashboardData = useCallback((state: BeneficiaryState) => {
      dispatch({ type: "SET_DASHBOARD_DATA", payload: state });
   }, []);

   const setInsightsData = useCallback((state: BeneficiaryState) => {
      dispatch({ type: "SET_INSIGHTS_DATA", payload: state });
   }, []);

   const setBeneficiaries = useCallback((state: BeneficiaryState) => {
      dispatch({ type: "SET_BENEFICIARIES", payload: state });
   }, []);

   const setEfectiveControllers = useCallback((state: BeneficiaryState) => {
      dispatch({ type: "SET_EFECTIVE_CONTROLERS", payload: state });
   }, []);

   const setShareholders = useCallback((state: BeneficiaryState) => {
      dispatch({ type: "SET_SHAREHOLDERS", payload: state });
   }, []);

   const setManual = useCallback((state: BeneficiaryState) => {
      dispatch({ type: "SET_MANUAL", payload: state });
   }, []);

   const setConfigurationData = useCallback((state: BeneficiaryState) => {
      dispatch({ type: "SET_CONFIGURATION", payload: state });
   }, []);
   //#end region

   const { user, companySelected } = useContext(UserContext);
   const { governanceBody } = useContext(CorporateDataContext);
   const [fetchKey, setFetchKey] = useState(0);
   const { insights } = useGetInsights();

   const refetch = () => setFetchKey((prevKey) => prevKey + 1);

   const fetchBeneficiaryData = useCallback(async () => {
      if (!companySelected?._id || !user) return;
      try {
         const [beneficiaries, efectiveControllers, shareholders, dashboardData, configData] = await Promise.all([
            getBeneficiaries(companySelected._id),
            getBeneficiariesControl(companySelected._id),
            getBeneficiariesShareholder(companySelected._id),
            getBeneficiaryDashboardInfo(companySelected._id),
            getBeneficiaryApplicationConfig(companySelected._id),
         ]);
         setBeneficiaries(beneficiaries);
         setEfectiveControllers(efectiveControllers);
         setShareholders(shareholders);
         setDashboardData(dashboardData);
         setConfigurationData(configData);
      } catch (error) {
         console.error("Error fetching beneficiary data:", error);
      }
   }, [companySelected, fetchKey]);

   useEffect(() => {
      fetchBeneficiaryData();
   }, [fetchBeneficiaryData]);

   const getInsightsData = useCallback(async () => {
      if (!insights || !user?.id) return;
      try {
         const insightsResponse = await getSavedInsights(user.id);
         setInsightsData({ savedInsights: insightsResponse, insights } as any);
      } catch (error) {
         console.error("Error insights data:", error);
      }
   }, [user, insights]);

   useEffect(() => {
      getInsightsData();
   }, [getInsightsData]);

   const fetchIdentificationManual = useCallback(async () => {
      if (!companySelected?._id || !user) return;
      try {
         const fileUrl = await getUrlS3(
            "files-lecosy",
            { folder: `gc/companies/${companySelected._id}/informacion-societaria` },
            (
               await getCorporateData(companySelected._id)
            )?.beneficiaryManual?.name
         );
         setManual(fileUrl);
      } catch (error) {
         console.error("Error fetching manual:", error);
      }
   }, [companySelected]);

   useEffect(() => {
      fetchIdentificationManual();
   }, [fetchIdentificationManual]);

   const handleCreateSheets = async (infoByBeneficiary) => {
      let processedInfo = [];
      for (const element of infoByBeneficiary) {
         let newData = {};
         if (element.moralAssociation) {
            newData["society"] = element.moralAssociation.businessName;
         } else {
            newData["society"] = companySelected.person_details?.businessName;
         }
         if (element.user) {
            newData["name"] = element.user.firstName + " " + element.user.lastName;
            newData["email"] = element.user.email;
            newData["phoneNumber"] = element.user.phoneNumber;
         }
         if (element.legalPerson === "Accionista") {
            const selectedUser = governanceBody.users.find((user) => user.user === element.user?._id);
            const actions =
               selectedUser?.actions?.map((action) => ({
                  title: action.title || "",
                  nominalValue: action.votes || "",
                  totalActions: action.sharesAmount || "",
               })) || [];
            processedInfo.push({ ...element, ...newData, actions });
         } else {
            processedInfo.push({ ...element, ...newData, actions: [] });
         }
      }
      const beneficiaryDetailsPromise = await Promise.all(
         processedInfo.map((row) => getBeneficiaryDetailsById(row._id, companySelected._id))
      );
      const [logo, beneficiaryDetails] = await Promise.all([
         await getUrlS3("images-lecosy", { folder: `${companySelected._id}` }, "logo.png"),
         beneficiaryDetailsPromise,
      ]);
      const filesPromise = await Promise.all(
         processedInfo.map((row, index) =>
            pdf(
               <BeneficiarySheetTemplate
                  structure={fieldsFormat(!!row.user, row.shareHolderType === "Persona moral")}
                  data1={beneficiaryDetails[index]}
                  data2={row}
                  logo={logo}
                  society={row.society}
               />
            ).toBlob()
         )
      );

      return { processedInfo, filesPromise };
   };

   const handleDownload = async (
      setModalState,
      rows,
      selectedRows,
      setDownloadProgress,
      type,
      setAlreadyDownloading
   ) => {
      let folderIds = [];
      let estimate = 0;

      async function download() {
         for (const key of selectedRows) {
            const findBeneficiaryById = rows.find((row) => row._id === key);
            estimate = estimate + 8 * 2 * 10;
            folderIds.push(findBeneficiaryById.folderId);
         }
         try {
            axios.defaults.withCredentials = true;
            const response = await axios.post(
               `${host}${BASE_ENDPOINT}${CLIENT_ENDPOINT}/folder/get/download/expedients/bc`,
               { foldersIds: folderIds, companyId: companySelected._id },
               {
                  responseType: "blob",
                  onDownloadProgress: (progressEvent) => {
                     updateDownloadProgress(progressEvent.loaded, estimate, setDownloadProgress);
                  },
               }
            );
            downloadBlob(response.data, "expediente.zip");
            setDownloadProgress(0);
            setAlreadyDownloading(false);
            setModalState(false);
         } catch (error) {
            console.error("Error downloading expedients:", error);
            handleInvalidRequest(error);
         }
      }

      function getInnerBeneficiaries(item, formattedRows, infoByBeneficiary) {
         const searchParent = formattedRows.filter((beneficiary) => beneficiary.moralAssociation?._id === item._id);
         if (searchParent.length === 0) return item;
         searchParent.forEach((element) => {
            if (element.moralAssociation) {
               const value = getInnerBeneficiaries(element, formattedRows, infoByBeneficiary);
               infoByBeneficiary.push(value);
            }
         });
         return item;
      }

      switch (type) {
         case "expedient":
            setAlreadyDownloading(true);
            await download();
            break;
         case "sheet":
            setAlreadyDownloading(true);
            const infoByBeneficiary = [];

            for (const element of selectedRows) {
               infoByBeneficiary.push(state.beneficiaries.find((beneficiary) => element === beneficiary._id));
            }

            infoByBeneficiary
               .filter((element) => !element.moralAssociation)
               .map((element) => getInnerBeneficiaries(element, state.beneficiaries, infoByBeneficiary));

            const { filesPromise, processedInfo } = await handleCreateSheets(infoByBeneficiary);
            const zip = new JSZip();
            filesPromise.forEach((file, index) =>
               zip.file(
                  processedInfo[index]["businessName"]
                     ? processedInfo[index]["businessName"] + ".pdf"
                     : processedInfo[index]["name"] + ".pdf",
                  file
               )
            );
            const zipBlob = await zip.generateAsync({ type: "blob" });
            downloadBlob(zipBlob, `Fichas.zip`);
            setAlreadyDownloading(false);
            break;
         case "expedientAndSheet":
            setAlreadyDownloading(true);
            const infoByBeneficiaryExp = [];

            for (const element of selectedRows) {
               infoByBeneficiaryExp.push(state.beneficiaries.find((beneficiary) => element === beneficiary._id));
            }

            infoByBeneficiaryExp
               .filter((element) => !element.moralAssociation)
               .map((element) => getInnerBeneficiaries(element, state.beneficiaries, infoByBeneficiaryExp));

            await deleteSheetsBCByFoldersId(infoByBeneficiaryExp.map((info) => info.folderId));

            const { filesPromise: filesProm, processedInfo: info } = await handleCreateSheets(infoByBeneficiaryExp);
            const files = await createMultipleFileAsync(
               info.map((beneficiary, index) => {
                  return {
                     name: beneficiary["businessName"]
                        ? beneficiary["businessName"] + ".pdf"
                        : beneficiary["name"] + ".pdf",
                     owner: null,
                     size: filesProm[index].size,
                     type: filesProm[index].type,
                     folder: beneficiary.folderId,
                     beneficiary: {
                        needUpdate: false,
                        verified: undefined,
                     },
                     fileDirection: `beneficiaries/${companySelected._id}/${beneficiary.folderId}`,
                  };
               })
            );
            const filesUploaded = await Promise.all(
               filesProm.map((element, index) => uploadFileToS3(files[index].urlToUpload, element))
            );
            await download();
            const deleteFiles = await Promise.all(files.map((element) => deleteFilesBC(element.file._id)));
            break;
         default:
            break;
      }
   };

   const updateDownloadProgress = (loaded, estimate, setDownloadProgress) => {
      const percentage = Math.floor((loaded / (estimate * 3000)) * 100);
      if (percentage < 100) setDownloadProgress(percentage);
   };

   const downloadBlob = (blobData, filename) => {
      const url = window.URL.createObjectURL(new Blob([blobData]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", filename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
   };

   return (
      <BeneficiaryControllerContext.Provider
         value={{
            ...state,
            refetch,
            handleDownload,
         }}
      >
         {children}
      </BeneficiaryControllerContext.Provider>
   );
};
