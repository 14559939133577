import { Box, Button, CircularProgress, Grid, MenuItem, Stack, Typography } from "@mui/material";
import { InputTextField } from "../../../../Inputs/InputTextField";
import { Form, Formik, FormikProps } from "formik";
import { InputSelectField } from "../../../../Inputs/InputSelectField";
import { TagsSelector } from "../../../Inputs/TagsSelector";
import { CalendarMonth, Delete, Flag, PeopleAltOutlined, Sell } from "@mui/icons-material";
import { useContext, useEffect, useState } from "react";
import { CustomInputStyle } from "../../../Inputs/InputStyled";
import dayjs from "dayjs";
import TextEditor from "../../../../Inputs/TextEditor/TextEditor";
import { UserContext } from "../../../../../context/userContext";
import {
   createComment,
   createNewTask,
   deleteTaskById,
   updateTask,
   useGetResourceSelected,
} from "../../../../../lib/gobCorpBEClient";
import { InputCheckBox } from "../../../../Inputs/InputCheckBox";
import { SnackBarContext } from "../../../../../context/snackBarContext";
import { GovernanceContext } from "../../../../../context/governanceContext/governanceContext";
import { TaskSchema } from "../../../../../lib/validations/GobCop/inputSchemas";
import { IGovernanceBody, ITask, TaskPriority, TaskStatus } from "../../../../../types/governance.types";
import { UserSearchComponent } from "../../../NotesScreen/UserSearchComponent";
import { CustomModalComponent } from "../../../../CustomModalComponent";
import StringToHTMLOutput from "../../../../Inputs/TextEditor/StringToHTMLOutput";
import { CommentsComponent } from "./Components/CommentsComponent";
import { getDateFromISODate } from "../../../../../const/globalConst";
import { StatusComponent } from "../ComponentsForTaskList";
import { ConfirmationModal } from "../../../../ConfirmationModal";

interface ModalHeaderComponentProps {
   open: boolean;
   setOpen: Function;
   task?: ITask;
   users?: any[];
}

export const processPriority = (priory) => {
   switch (priory) {
      case "HIGH":
         return "Alta";
      case "MEDIUM":
         return "Media";
      case "LOW":
         return "Baja";
      default:
         break;
   }
};

const processStatus = (status: string) => {
   switch (status) {
      case "PENDING":
         return "Pendiente";
      case "PROCESS":
         return "En proceso";
      case "REVIEW":
         return "En revisión";
      case "EXPIRED":
         return "Vencidas";
      case "COMPLETE":
         return "Finalizado";
      default:
         break;
   }
};

const useFilterGovernBodies = (gobernanceBody) => {
   const [bodies, setBodies] = useState<IGovernanceBody[]>();
   const [loader, setLoader] = useState(true);
   const { user, isImplementationUser } = useContext(UserContext);

   useEffect(() => {
      const filterBodies = async () => {
         const array = gobernanceBody.filter((e) => e.title !== "PANEL DE USUARIO");
         if (isImplementationUser()) setBodies(array);
         else {
            const userBodies = array.filter((body) => body.users.some((u) => u.user === user.id));
            setBodies(userBodies);
         }
         setLoader(false);
      };
      filterBodies();
   }, [gobernanceBody]);

   return { bodies, loader };
};

export const TaskModalComponent = (props: ModalHeaderComponentProps) => {
   const { user } = useContext(UserContext);
   const { userDetails, refetchTasks, setRefetchTasks, gobernanceBody, companySelected } =
      useContext(GovernanceContext);
   const [comments, setComments] = useState(props.task ? props.task?.comments : []);
   const [openDeleteModal, setOpenDeleteModal] = useState(false);
   const { bodies, loader } = useFilterGovernBodies(gobernanceBody);
   const { showSnackBar } = useContext(SnackBarContext);
   const resourceId = useGetResourceSelected();
   const [text, setText] = useState(props.task?.description || "");
   const [isLoading, setIsLoading] = useState(false);
   const [edit, setEdit] = useState(false);
   useEffect(() => {
      setComments(props.task?.comments);
   }, [props.task]);

   const handleClose = () => {
      setText("");
      setEdit(false);
   };

   const handleSubmit = async (values) => {
      setIsLoading(true);
      const data = {
         title: values.title,
         description: values.description,
         users: values.users,
         limitDate: values.limitDate,
         priority: values.priority,
         owner: user.id,
         company: companySelected,
         allowedComments: values.allowedComments,
         tags: values.tags,
      };
      try {
         await createNewTask(data, resourceId);
         showSnackBar("Tarea creada con éxito", false);
         setTimeout(() => {
            setRefetchTasks(!refetchTasks);
            setIsLoading(false);
            props.setOpen(false);
         }, 1000);
      } catch (error) {
         console.log(error);
         showSnackBar("Error al crear la tarea", true);
      }
      setIsLoading(false);
   };

   const handleSubmitEdit = async (values) => {
      setIsLoading(true);
      const data = {
         title: values.title,
         description: values.description,
         users: values.users,
         priority: values.priority,
         status: values.status,
         allowedComments: values.allowedComments,
         tags: values.tags,
      };
      try {
         await updateTask(props.task?._id, data, resourceId, companySelected);
         showSnackBar("Tarea actualizada con exito", false);
         setTimeout(() => {
            setRefetchTasks(!refetchTasks);
            props.setOpen(false);
            handleClose();
         }, 1000);
      } catch (error) {
         showSnackBar("Error al actualizar la tarea", true);
         console.log(error);
      }
      setIsLoading(false);
   };

   const handleDelete = async () => {
      if (user.id !== props.task.owner) return;
      try {
         await deleteTaskById(props.task?._id, resourceId);
      } catch (error) {
         console.log(error);
         showSnackBar("Error al eliminar la tarea", true);
      } finally {
         setOpenDeleteModal(false);
         showSnackBar("Tarea eliminada con éxito", false);
         setRefetchTasks(!refetchTasks);
      }
   };

   const handleSubmitComment = async (comment) => {
      let Obj: any = {};
      if (comment === "") return;
      try {
         Obj = {
            commentBody: { user: user.id, userName: `${user.firstName} ${user.lastName}`, content: comment },
            taskId: props.task._id,
         };
         await createComment(Obj);
         setRefetchTasks(!refetchTasks);
         setComments([...comments, Obj.commentBody]);
         comment = "";
      } catch (error) {
         console.log(error);
      }
   };

   return (
      <CustomModalComponent
         open={props.open}
         setOpen={props.setOpen}
         title={props.task ? props.task?.title : "Registro de tarea nueva"}
         timeStamp={props.task && !edit ? false : true}
         onClose={handleClose}
      >
         {!edit && props.task ? (
            <>
               <Box
                  sx={{
                     display: "flex",
                     flexDirection: "column",
                     width: 650,
                     p: 4,
                     maxHeight: 500,
                     overflowY: "auto",
                     rowGap: 2,
                  }}
               >
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                     <Typography variant="body2">{props.task.title}</Typography>
                     <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                        <Typography variant="body2" fontWeight={600}>
                           Estatus:
                        </Typography>
                        <StatusComponent status={props.task.status} />
                     </Box>
                  </Box>
                  <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                     <Typography variant="body2">
                        <b>Fecha límite:</b>{" "}
                        {props.task.limitDate
                           ? getDateFromISODate(props.task.limitDate)
                           : getDateFromISODate(props.task.dueDate)}
                     </Typography>
                     <Typography variant="body2">
                        <Box
                           sx={{
                              display: "flex",
                              flexDirection: "row",
                              justifyContent: "space-between",
                              alignItems: "center",
                              flex: 1,
                              columnGap: 1,
                           }}
                        >
                           <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
                              <Typography variant="body2" fontWeight={600}>
                                 Prioridad:
                              </Typography>
                              <Typography variant="body2">
                                 {processPriority(TaskPriority[props.task.priority])}
                              </Typography>
                              <Flag
                                 sx={{
                                    width: 20,
                                    color:
                                       processPriority(TaskPriority[props.task.priority]) === "Alta"
                                          ? "#ff0000"
                                          : processPriority(TaskPriority[props.task.priority]) === "Media"
                                          ? "#ffa500"
                                          : processPriority(TaskPriority[props.task.priority]) === "Baja"
                                          ? "#009929"
                                          : "",
                                 }}
                              />
                           </Box>
                        </Box>
                     </Typography>
                  </Box>
                  <Typography variant="body2" fontWeight={600}>
                     Descripción
                  </Typography>
                  <Box>
                     <StringToHTMLOutput
                        text={props.task.description}
                        sx={{
                           p: 0,
                           fontSize: 14,
                           textAlign: "justify",
                        }}
                     />
                  </Box>
                  <Box sx={{ display: "flex" }}>
                     <Sell sx={{ fontSize: 15, mr: 1, mt: 1.5 }} />
                     {props.task.tags.length > 0 ? (
                        props.task.tags.map((e, i) => (
                           <Typography key={i} m={1} fontSize={"14px"} color={e.color}>
                              {e.title}
                           </Typography>
                        ))
                     ) : (
                        <Typography m={1} fontSize={"14px"}>
                           No tiene etiquetas
                        </Typography>
                     )}
                  </Box>

                  {props.task?.owner === user.id ? (
                     <>
                        {props.users.length > 0 ? (
                           <>
                              <Typography variant="body2" fontWeight={600}>
                                 Asignada a
                              </Typography>
                              <Box
                                 sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    columnGap: 2,
                                    flexWrap: "wrap",
                                    rowGap: 1,
                                 }}
                              >
                                 <PeopleAltOutlined sx={{ fontSize: 15 }} />
                                 {props.users
                                    .filter((u) => props.task?.users.includes(u.id) || props.task?.owner === u.id)
                                    .map((e, i) => (
                                       <Typography key={i} fontSize={"14px"}>
                                          {e.user}
                                       </Typography>
                                    ))}
                              </Box>
                           </>
                        ) : (
                           <Typography variant="body2" fontWeight={600}>
                              Sin asignar
                           </Typography>
                        )}
                     </>
                  ) : (
                     <>
                        <Typography variant="body2" fontWeight={600}>
                           Asignada por
                        </Typography>
                        <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                           <PeopleAltOutlined sx={{ fontSize: 15 }} />
                           <Typography fontSize={"14px"}>
                              {props.users.find((u) => props.task?.owner === u.id)?.user}
                           </Typography>
                        </Box>
                     </>
                  )}

                  <Formik
                     initialValues={{
                        comment: "",
                     }}
                     onSubmit={handleSubmitComment}
                  >
                     <Form>
                        {(props.task.allowedComments || props.task.dueDate) && (
                           <CommentsComponent
                              users={props.users}
                              comment={comments}
                              submitComment={handleSubmitComment}
                           />
                        )}
                     </Form>
                  </Formik>
               </Box>
               {props.task && props.task.owner === user.id && (
                  <Box
                     sx={{
                        display: "flex",
                        justifyContent: "flex-start",
                        bgcolor: "#F3F3F3",
                        p: 2,
                        borderBottomRightRadius: 5,
                        borderBottomLeftRadius: 5,
                     }}
                  >
                     <Button
                        onClick={() => {
                           setEdit(true);
                           setText(props.task.description);
                        }}
                        sx={{ maxWidth: "200", height: 30 }}
                     >
                        Modificar información
                     </Button>
                  </Box>
               )}
            </>
         ) : (
            <Formik
               initialValues={{
                  title: props.task?.title || "",
                  users: props.task?.users || [],
                  priority: props.task?.priority || "LOW",
                  status: props.task?.status || "PENDING",
                  tags: props.task?.tags || [],
                  limitDate: props.task ? dayjs(props.task?.limitDate) : null,
                  allowedComments: props.task?.allowedComments || false,
                  company: props.task?.company || companySelected,
               }}
               onSubmit={props.task ? handleSubmitEdit : handleSubmit}
               validationSchema={TaskSchema}
            >
               {(formProps: FormikProps<any>) => (
                  <Form>
                     <Box sx={{ p: 4, maxHeight: 450, overflowY: "auto", bgcolor: "white" }}>
                        <Box
                           sx={{
                              display: "flex",
                              justifyContent: "center",
                              flexDirection: "column",
                              maxWidth: 600,
                           }}
                        >
                           <Grid container columnSpacing={4} rowSpacing={3}>
                              <Grid item xs={8}>
                                 <Box
                                    sx={{
                                       display: "flex",
                                       flexDirection: "column",
                                       justifyContent: "flex-start",
                                       rowGap: 1,
                                    }}
                                 >
                                    <Typography fontSize={14} fontWeight={600}>
                                       Nombre
                                    </Typography>
                                    <InputTextField id="title" name="title" type="text" fullWidth size="small" />
                                 </Box>
                              </Grid>
                              <Grid item xs={4}>
                                 <Box
                                    sx={{
                                       display: "flex",
                                       flexDirection: "column",
                                       justifyContent: "flex-start",
                                       rowGap: 1,
                                    }}
                                 >
                                    <Typography fontSize={14} fontWeight={600}>
                                       Estatus
                                    </Typography>
                                    <InputSelectField
                                       id="status"
                                       labelId="status"
                                       name="status"
                                       fullWidth
                                       label=""
                                       size="small"
                                       disabled={!props.task}
                                    >
                                       {Object.keys(TaskStatus).map((e) => (
                                          <MenuItem key={e} value={e}>
                                             <Typography>{processStatus(TaskStatus[e])}</Typography>
                                          </MenuItem>
                                       ))}
                                    </InputSelectField>
                                 </Box>
                              </Grid>
                              <Grid item xs={8}>
                                 <Box
                                    sx={{
                                       display: "flex",
                                       flexDirection: "column",
                                       justifyContent: "flex-start",
                                       rowGap: 1,
                                    }}
                                 >
                                    <CustomInputStyle
                                       icon={CalendarMonth}
                                       inputProps={{
                                          id: "limitDate",
                                          name: "limitDate",
                                          fullWidth: true,
                                          unstyled: true,
                                          format: "DD/MM/YYYY",
                                          minDate: dayjs(new Date()),
                                          disablePast: true,
                                          onChange: (e) => {
                                             formProps.setFieldValue("limitDate", e.$d);
                                          },
                                       }}
                                       type="date"
                                       label="Fecha límite"
                                       fullWidth
                                       disabled={edit ? user.id !== props.task?.owner : false}
                                    />
                                 </Box>
                              </Grid>
                              <Grid item xs={4}>
                                 <Box
                                    sx={{
                                       display: "flex",
                                       flexDirection: "column",
                                       justifyContent: "flex-start",
                                       rowGap: 1,
                                    }}
                                 >
                                    <Typography fontSize={14} fontWeight={600}>
                                       Prioridad
                                    </Typography>
                                    <InputSelectField
                                       size={"small"}
                                       id="priority"
                                       labelId="priority"
                                       name="priority"
                                       fullWidth
                                       label=""
                                    >
                                       {Object.keys(TaskPriority).map((e) => (
                                          <MenuItem key={e} value={e}>
                                             <Box
                                                sx={{
                                                   display: "flex",
                                                   flexDirection: "row",
                                                   justifyContent: "space-between",
                                                   alignItems: "center",
                                                   flex: 1,
                                                }}
                                             >
                                                <Box>{processPriority(TaskPriority[e])}</Box>
                                                <Flag
                                                   sx={{
                                                      width: 20,
                                                      color:
                                                         processPriority(TaskPriority[e]) === "Alta"
                                                            ? "#ff0000"
                                                            : processPriority(TaskPriority[e]) === "Media"
                                                            ? "#ffa500"
                                                            : processPriority(TaskPriority[e]) === "Baja"
                                                            ? "#009929"
                                                            : "",
                                                   }}
                                                />
                                             </Box>
                                          </MenuItem>
                                       ))}
                                    </InputSelectField>
                                 </Box>
                              </Grid>
                              <Grid item xs={12}>
                                 <Box
                                    sx={{
                                       display: "flex",
                                       flexDirection: "column",
                                       justifyContent: "flex-start",
                                       rowGap: 1,
                                    }}
                                 >
                                    <Typography fontSize={14} fontWeight={600}>
                                       Asignar etiqueta
                                    </Typography>
                                    <TagsSelector
                                       tags={userDetails.tags}
                                       valuesTags={formProps.values.tags}
                                       setFieldValue={(e) => {
                                          let newValues = formProps.values.tags;
                                          if (newValues.filter((tag) => tag._id === e._id).length > 0) return;
                                          newValues.push(e);
                                          formProps.setFieldValue("tags", newValues);
                                       }}
                                       deleteValue={(e) => {
                                          const newValues = formProps.values.tags.filter((tag) => tag._id !== e);
                                          formProps.setFieldValue("tags", newValues);
                                       }}
                                    />
                                 </Box>
                              </Grid>
                              <Grid item xs={12}>
                                 {!loader && bodies.length > 0 ? (
                                    <UserSearchComponent
                                       governBodies={bodies}
                                       label={"Invitados"}
                                       selectedUsers={formProps.values.users}
                                       setSelectedUser={(users) => {
                                          const filterT = users.map((user) => user._id);
                                          formProps.setFieldValue("users", filterT);
                                       }}
                                    />
                                 ) : loader ? (
                                    <CircularProgress />
                                 ) : (
                                    <Typography>No se encontraron usuarios</Typography>
                                 )}
                              </Grid>
                              <Grid item xs={12}>
                                 <Box
                                    sx={{
                                       display: "flex",
                                       flexDirection: "column",
                                       justifyContent: "flex-start",
                                       rowGap: 1,
                                    }}
                                 >
                                    <Typography variant="body2" fontWeight={600}>
                                       Descripción
                                    </Typography>
                                    <TextEditor
                                       setText={(e) => {
                                          setText(e);
                                          formProps.setFieldValue("description", e);
                                       }}
                                       text={text}
                                    />
                                 </Box>
                              </Grid>
                              <Grid item xs={6}>
                                 <InputCheckBox
                                    id="allowedComments"
                                    label="Permitir comentarios"
                                    name="allowedComments"
                                 />
                              </Grid>
                           </Grid>
                        </Box>
                     </Box>
                     <Stack
                        direction={"row"}
                        sx={{
                           justifyContent: props.task ? "space-between" : "flex-end",
                           bgcolor: "#F3F3F3",
                           px: 2,
                           borderBottomRightRadius: 5,
                           borderBottomLeftRadius: 5,
                           py: 1,
                        }}
                     >
                        {props.task && (
                           <Button
                              sx={{ height: 30, display: "flex", columnGap: 1 }}
                              onClick={() => {
                                 setOpenDeleteModal(true);
                              }}
                           >
                              <Delete sx={{ fontSize: 20 }} />
                              Eliminar
                           </Button>
                        )}
                        <Box sx={{ display: "flex", justifyContent: "flex-end", columnGap: 1 }}>
                           <Button
                              onClick={() => {
                                 props.setOpen(false);
                                 handleClose();
                              }}
                              disabled={isLoading}
                           >
                              <Typography fontSize={14}>Cancelar</Typography>
                           </Button>
                           <Button type="submit" disabled={isLoading}>
                              <Typography fontSize={14}>Guardar</Typography>
                           </Button>
                        </Box>
                     </Stack>
                     <ConfirmationModal
                        open={openDeleteModal}
                        setOpen={setOpenDeleteModal}
                        onConfirm={handleDelete}
                        title={"Eliminar tarea"}
                        body={"¿Está seguro que desea eliminar esta tarea?"}
                     />
                  </Form>
               )}
            </Formik>
         )}
      </CustomModalComponent>
   );
};
