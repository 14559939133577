import { Box, Grid, Typography } from "@mui/material";
import { useContext } from "react";
import { getContrastYIQ } from "../../../../const/globalConst";
import { ExternalGovernanceSessionContext } from "../../../../context/governanceContext/externalSessionContext";
import { InputCheckBox } from "../../../Inputs/InputCheckBox";

export const ShareholderUrlRecordingExternal = () => {
   const { colors } = useContext(ExternalGovernanceSessionContext);

   return (
      <Grid container columnSpacing={0.1}>
         <Grid item xs={4}>
            <Typography
               sx={{
                  bgcolor: colors.primary,
                  color: getContrastYIQ(colors.primary),
                  textAlign: "center",
                  fontSize: 10,
                  py: 0.5,
               }}
            >
               LINK DE GRABACIÓN:
            </Typography>
         </Grid>
         <Grid item xs={8}>
            <Box sx={{ my: 0, fontSize: 10 }}>
               <Box
                  sx={{
                     py: 0.2,
                     px: 0.5,
                     fontSize: 10,
                     bgcolor: colors.primary,
                     color: getContrastYIQ(colors.primary),
                  }}
               >
                  <InputCheckBox
                     id={"meetingUrl"}
                     label={""}
                     name={"meetingUrl"}
                     sxformcontrol={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        mt: 0.3,
                        p: 0,
                     }}
                     sx={{
                        p: 0,
                        "& .MuiSvgIcon-root": {
                           fontSize: 15,
                        },
                        "&.Mui-checked": {
                           color: getContrastYIQ(colors.primary),
                        },
                        color: getContrastYIQ(colors.primary),
                     }}
                     disabled={true}
                     disablemargin
                  />
               </Box>
            </Box>
         </Grid>
      </Grid>
   );
};
