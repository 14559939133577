import UseCreateCharts from "../../../../../hooks/useCreateCharts";
import { useGetCommitmentTrimesterInfo } from "../../../../../hooks/gob-corp/governmentBody/useGetCommitmentTrimesterInfo";

const SessionAssistenceChart = ({ chartRef, setSelectedTrimester }) => {
   const commiteeInfo = useGetCommitmentTrimesterInfo();

   return UseCreateCharts({
      chartRef: chartRef,
      barThickness: 20,
      type: "bar",
      borderRadius: true,
      colors: ["#162c44", "#FBBC05"],
      labels: Object.keys(commiteeInfo).map((key) => key.replace("trimester", "T")),
      scores: [
         Object.keys(commiteeInfo).map((key) => commiteeInfo[key].filter((com) => com.status).length),
         Object.keys(commiteeInfo).map((key) => commiteeInfo[key].filter((com) => !com.status).length),
      ],
      labelNames: ["En tiempo y forma", "Fuera de tiempo y forma"],
      onClick: (e, i) => {
         if (i.length === 0) return;
         const key = Object.keys(commiteeInfo)[i[0].index];
         setSelectedTrimester(commiteeInfo[key]);
      },
   });
};

export default SessionAssistenceChart;
