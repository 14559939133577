import { imageLogo, getDateFromISODate } from "../../const/globalConst";
import categories from "../../const/denounceCategories.json";
import { getComplaintCompany } from "../../hooks/companies-branches/getCompaniesNames";

function complaintPercentage(barChartValues, actualValue) {
   const total = barChartValues.overall.yAxis.reduce((a, b) => a + b, 0);
   return ((actualValue * 100) / total).toFixed(2);
}
function complaintSum(barChartValues) {
   let total = 0;
   barChartValues.forEach((barChart) => {
      total += barChart.value;
   });
   return total;
}
type valueTypes = {
   value: number;
   name: string;
};
function separateChartValues(barChartValues, chartClasification) {
   const values: valueTypes[] = [
      {
         value: 0,
         name: "",
      },
   ];
   switch (chartClasification) {
      case "type":
         values.push({
            value: barChartValues.type.yAxis[0].reduce((a, b) => a + b, 0),
            name: "denuncias",
         });
         values.push({
            value: barChartValues.type.yAxis[1].reduce((a, b) => a + b, 0),
            name: "quejas",
         });
         break;
      case "calification":
         values.push({
            value: barChartValues.calification.yAxis[0].reduce((a, b) => a + b, 0),
            name: "procedentes",
         });
         values.push({
            value: barChartValues.calification.yAxis[1].reduce((a, b) => a + b, 0),
            name: "improcedentes",
         });
         break;
      case "anonymous":
         values.push({
            value: barChartValues.anonymous.yAxis[0].reduce((a, b) => a + b, 0),
            name: "anónimas",
         });
         values.push({
            value: barChartValues.anonymous.yAxis[1].reduce((a, b) => a + b, 0),
            name: "no anónimas",
         });
         break;
      case "clasification1":
         categories.a.forEach((name, index) => {
            values.push({
               value: barChartValues.clasification1.yAxis[index].reduce((a, b) => a + b, 0),
               name: name,
            });
         });
         break;
      case "clasification2":
         categories.b.forEach((name, index) => {
            values.push({
               value: barChartValues.clasification2.yAxis[index].reduce((a, b) => a + b, 0),
               name: name,
            });
         });
         break;
      case "clasification3":
         categories.c.forEach((name, index) => {
            values.push({
               value: barChartValues.clasification3.yAxis[index].reduce((a, b) => a + b, 0),
               name: name,
            });
         });
         break;
      default:
         values.push({
            value: barChartValues.clasification.yAxis[0].reduce((a, b) => a + b, 0),
            name: "posible comisión de un hecho delictivo",
         });
         values.push({
            value: barChartValues.clasification.yAxis[1].reduce((a, b) => a + b, 0),
            name: "posible violación de los derechos humanos",
         });
         values.push({
            value: barChartValues.clasification.yAxis[2].reduce((a, b) => a + b, 0),
            name: "posible violación a las políticas y directrices de la empresa",
         });
         break;
   }
   return values;
}

function individualChartValues(barChartValues, chartClasification, index) {
   const values: valueTypes[] = [
      {
         value: 0,
         name: "",
      },
   ];
   switch (chartClasification) {
      case "type":
         values.push({
            value: barChartValues.type.yAxis[0][index],
            name: "denuncias",
         });
         values.push({
            value: barChartValues.type.yAxis[1][index],
            name: "quejas",
         });
         break;
      case "calification":
         values.push({
            value: barChartValues.calification.yAxis[0][index],
            name: "procedentes",
         });
         values.push({
            value: barChartValues.calification.yAxis[1][index],
            name: "improcedentes",
         });
         break;
      case "anonymous":
         values.push({
            value: barChartValues.anonymous.yAxis[0][index],
            name: "anónimas",
         });
         values.push({
            value: barChartValues.anonymous.yAxis[1][index],
            name: "no anónimas",
         });
         break;
      case "clasification1":
         categories.a.forEach((name, i) => {
            values.push({
               value: barChartValues.clasification1.yAxis[i][index],
               name: name,
            });
         });
         break;
      case "clasification2":
         categories.b.forEach((name, i) => {
            values.push({
               value: barChartValues.clasification2.yAxis[i][index],
               name: name,
            });
         });
         break;
      case "clasification3":
         categories.c.forEach((name, i) => {
            values.push({
               value: barChartValues.clasification3.yAxis[i][index],
               name: name,
            });
         });
         break;
      default:
         values.push({
            value: barChartValues.clasification.yAxis[0][index],
            name: "posible comisión de un hecho delictivo",
         });
         values.push({
            value: barChartValues.clasification.yAxis[1][index],
            name: "posible violación de los derechos humanos",
         });
         values.push({
            value: barChartValues.clasification.yAxis[2][index],
            name: "posible violación a las políticas y directrices de la empresa",
         });
         break;
   }
   return values;
}

const createChart = (chart) => {
   return {
      image: chart,
      fit: [200, 180],
      margin: [0, 30, 0, 30],
   };
};

const createChartMargin = (chart) => {
   return {
      image: chart,
      fit: [200, 180],
      margin: [0, 45, 0, 30],
   };
};

const getComplaintValue = (e) => {
   if (e.value !== 0) {
      return {
         text: [
            {
               text: `, ${e.value}`,
               bold: true,
            },
            ` se clasificaron como ${e.name}`,
         ],
      };
   }
   return undefined;
};

interface StatisticsReportProps {
   clientName: string;
   startDate;
   finishDate;
   selectedBranch?: any;
   overallBarChart: string;
   typeBarChart: string;
   anonymousBarChart: string;
   calificationBarChart: string;
   clasificationBarChart?: string;
   clasification1BarChart?: string;
   clasification2BarChart?: string;
   clasification3BarChart?: string;
   companiesFound?: any;
   branchesFound?: any;
   totalComplaints: any;
   barChartValues: any;
}
export const specificStatisticReportTemplate2 = (props: StatisticsReportProps) => {
   const companies: any[] = [];
   let clasificationValues;
   let clasification1Values;
   let clasification2Values;
   let clasification3Values;
   const typeValues = separateChartValues(props.barChartValues, "type");
   const calificationValues = separateChartValues(props.barChartValues, "calification");
   const anonymousValues = separateChartValues(props.barChartValues, "anonymous");
   if (!props.selectedBranch) {
      clasificationValues = separateChartValues(props.barChartValues, "clasification");
   }
   if (props.selectedBranch) {
      clasification1Values = separateChartValues(props.barChartValues, "clasification1");
      clasification2Values = separateChartValues(props.barChartValues, "clasification2");
      clasification3Values = separateChartValues(props.barChartValues, "clasification3");
   }

   const typeValuesCount = typeValues
      .map((typeValue) => {
         return getComplaintValue(typeValue);
      })
      .filter(function (e) {
         return e !== undefined;
      });
   const calificationValuesCount = calificationValues
      .map((typeValue) => {
         return getComplaintValue(typeValue);
      })
      .filter(function (e) {
         return e !== undefined;
      });
   const anonymousValuesCount = anonymousValues
      .map((typeValue) => {
         return getComplaintValue(typeValue);
      })
      .filter(function (e) {
         return e !== undefined;
      });
   const clasificationCount = clasificationValues
      ? clasificationValues
           .map((typeValue) => {
              return getComplaintValue(typeValue);
           })
           .filter(function (e) {
              return e !== undefined;
           })
      : undefined;
   const clasification1ValuesCount = clasification1Values
      ? clasification1Values
           .map((typeValue) => {
              return getComplaintValue(typeValue);
           })
           .filter(function (e) {
              return e !== undefined;
           })
      : undefined;
   const clasification2ValuesCount = clasification2Values
      ? clasification2Values
           .map((typeValue) => {
              return getComplaintValue(typeValue);
           })
           .filter(function (e) {
              return e !== undefined;
           })
      : undefined;
   const clasification3ValuesCount = clasification3Values
      ? clasification3Values
           .map((typeValue) => {
              return getComplaintValue(typeValue);
           })
           .filter(function (e) {
              return e !== undefined;
           })
      : undefined;
   props.branchesFound
      ? props.branchesFound.forEach((element) => {
           return companies.push({
              text: `${element.name}`,
           });
        })
      : props.companiesFound.forEach((element) => {
           return companies.push({
              text: `${element}`,
           });
        });

   function getFinishedComplaint() {
      const rows: any[] = [];
      rows.push([
         {
            text: "No. REPORTE",
            fontSize: 11,
            bold: true,
            alignment: "left",
            margin: [15, 5, 0, 5],
            color: "#ffffff",
            fillColor: "#162c44",
         },
         {
            text: "EMPRESA",
            fontSize: 11,
            bold: true,
            alignment: "left",
            margin: [0, 5],
            color: "#ffffff",
            fillColor: "#162c44",
         },
         {
            text: "CATEGORÍA",
            fontSize: 11,
            bold: true,
            alignment: "left",
            margin: [0, 5],
            color: "#ffffff",
            fillColor: "#162c44",
         },
         {
            text: "FECHA DE ENVÍO",
            fontSize: 11,
            bold: true,
            alignment: "left",
            margin: [0, 5],
            color: "#ffffff",
            fillColor: "#162c44",
         },
      ]);
      props.totalComplaints.forEach((e) => {
         if (e.complaint.status === "resuelto")
            rows.push([
               [
                  {
                     text: e.complaint.reportNumber,
                     alignment: "left",
                     fontSize: 11,
                     margin: [15, 0, 0, 0],
                     color: "#162c44",
                     bold: true,
                     border: [false, true, false, true],
                  },
               ],
               [
                  {
                     text: props.branchesFound ? getComplaintCompany(e, props.companiesFound) : props.companiesFound,
                     alignment: "left",
                     fontSize: 11,
                     color: "#162c44",
                     border: [false, true, false, true],
                  },
               ],
               [
                  {
                     text: e.complaint.category,
                     alignment: "left",
                     fontSize: 11,
                     color: "#162c44",
                     border: [false, true, false, true],
                  },
               ],
               [
                  {
                     text: getDateFromISODate(e.complaint.date),
                     fontSize: 11,
                     color: "#162c44",
                     border: [false, true, false, true],
                  },
               ],
            ]);
      });
      return rows;
   }
   function getProcessComplaint() {
      const rows: any[] = [];
      rows.push([
         {
            text: "No. REPORTE",
            fontSize: 11,
            bold: true,
            alignment: "left",
            margin: [15, 5, 0, 5],
            color: "#ffffff",
            fillColor: "#162c44",
         },
         {
            text: "EMPRESA",
            fontSize: 11,
            bold: true,
            alignment: "left",
            margin: [0, 5],
            color: "#ffffff",
            fillColor: "#162c44",
         },
         {
            text: "CATEGORÍA",
            fontSize: 11,
            bold: true,
            alignment: "left",
            margin: [0, 5],
            color: "#ffffff",
            fillColor: "#162c44",
         },
         {
            text: "FECHA DE ENVÍO",
            fontSize: 11,
            bold: true,
            alignment: "left",
            margin: [0, 5],
            color: "#ffffff",
            fillColor: "#162c44",
         },
      ]);
      props.totalComplaints.forEach((e) => {
         if (e.complaint.status === "en proceso")
            rows.push([
               [
                  {
                     text: e.complaint.reportNumber,
                     alignment: "left",
                     fontSize: 11,
                     margin: [15, 0, 0, 0],
                     color: "#162c44",
                     bold: true,
                  },
               ],
               [
                  {
                     text: props.branchesFound ? getComplaintCompany(e, props.companiesFound) : props.companiesFound,
                     alignment: "left",
                     fontSize: 11,
                     color: "#162c44",
                  },
               ],
               [
                  {
                     text: e.complaint.category,
                     alignment: "left",
                     fontSize: 11,
                     color: "#162c44",
                  },
               ],
               [
                  {
                     text: getDateFromISODate(e.complaint.date),
                     fontSize: 11,
                     color: "#162c44",
                  },
               ],
            ]);
      });
      return rows;
   }

   const dd = {
      info: {
         title: props.branchesFound ? `reporte específico de denuncias` : `reporte general de denuncias`,
      },
      pageSize: "A4",
      pageMargins: [70, 90, 60, 45],
      header: function (currentPage) {
         return [
            currentPage !== 1
               ? {
                    columns: [
                       {
                          image: imageLogo,
                          width: 120,
                          absolutePosition: { x: 50, y: 25 },
                       },
                       {
                          canvas: [
                             {
                                type: "rect",
                                x: 90,
                                y: 55,
                                w: 540,
                                h: 4,
                                color: "#152c44",
                             },
                          ],
                       },
                       {
                          text: props.branchesFound
                             ? "REPORTE DEL CANAL DE DENUNCIAS"
                             : "REPORTE GENERAL DEL CANAL DE DENUNCIAS",
                          bold: true,
                          fontSize: 15,
                          color: "#152c44",
                          absolutePosition: { x: 218, y: 35 },
                       },
                    ],
                 }
               : {},
         ];
      },
      content: [
         {
            image: imageLogo,
            width: 250,
            alignment: "center",
            absolutePosition: { x: 60, y: 250 },
         },
         {
            text: "REPORTE",
            bold: true,
            alignment: "center",
            absolutePosition: { x: 60, y: 350 },
            fontSize: 18,
         },
         {
            text: props.branchesFound ? "Estadísticas por Razón Social" : "Estadísticas generales",
            alignment: "center",
            absolutePosition: { x: 60, y: 380 },
            fontSize: 14,
         },
         {
            text: `${props.clientName}`,
            bold: true,
            alignment: "center",
            absolutePosition: { x: 60, y: 420 },
            fontSize: 12,
         },
         props.branchesFound
            ? {
                 text: `${props.selectedBranch.person_details.comercialName}`,
                 bold: true,
                 alignment: "center",
                 absolutePosition: { x: 60, y: 450 },
                 fontSize: 12,
              }
            : undefined,
         {
            text: `${new Date().toLocaleDateString("es-ES", {
               weekday: "long",
               year: "numeric",
               month: "long",
               day: "numeric",
            })}`,
            bold: true,
            alignment: "center",
            absolutePosition: props.branchesFound ? { x: 60, y: 500 } : { x: 60, y: 450 },
            fontSize: 12,
         },
         {
            text:
               props.startDate.toLocaleDateString("es-ES", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
               }) ===
               props.finishDate.toLocaleDateString("es-ES", {
                  weekday: "long",
                  year: "numeric",
                  month: "long",
                  day: "numeric",
               })
                  ? "En el presente documento, se anexan las estadísticas y/o datos de todas las denuncias y quejas que se presentaron."
                  : [
                       "En el presente documento, se anexan las estadísticas y/o datos de denuncias y quejas que se presentaron en el periodo comprendido del ",
                       {
                          text: `${props.startDate.toLocaleDateString("es-ES", {
                             weekday: "long",
                             year: "numeric",
                             month: "long",
                             day: "numeric",
                          })}`,
                          bold: true,
                       },
                       " al ",
                       {
                          text: `${props.finishDate.toLocaleDateString("es-ES", {
                             weekday: "long",
                             year: "numeric",
                             month: "long",
                             day: "numeric",
                          })}.`,
                          bold: true,
                       },
                    ],
            margin: [0, 10, 0, 10],
            alignment: "justify",
            pageBreak: "before",
         },
         {
            text: [
               "A través de este reporte, hacemos de su conocimiento los datos generales de las denuncias y/o quejas que se atendieron a ",
               { text: `${props.clientName}`, bold: true },
               " de acuerdo al contrato establecido.",
            ],
            margin: [0, 10, 0, 10],
            alignment: "justify",
         },
         {
            text: "Razón Social(es)",
            margin: [0, 40, 0, 1],
            color: "#162c44",
            bold: true,
         },
         {
            table: {
               widths: ["*"],
               body: [
                  [
                     companies.map((element) => {
                        return {
                           ul: [
                              {
                                 text: element,
                                 margin: [25, 3],
                              },
                           ],
                        };
                     }),
                  ],
               ],
            },
            margin: [0, 10, 0, 10],
         },
         {
            columns: [
               {
                  text: "Denuncias resueltas",
                  color: "#162c44",
                  bold: true,
                  margin: [0, 0, 10, 0],
               },
               {
                  text: "Denuncias en proceso",
                  color: "#162c44",
                  bold: true,
                  margin: [10, 0, 0, 0],
               },
            ],
            margin: [0, 40, 0, 2],
         },
         {
            columns: [
               {
                  table: {
                     heights: [50],
                     widths: ["*"],
                     body: [
                        [
                           {
                              text: `${getFinishedComplaint().length - 1}`,
                              alignment: "center",
                              fillColor: "#eeeeee",
                              margin: [0, 20],
                              color: "#162c44",
                              bold: true,
                           },
                        ],
                     ],
                  },
                  layout: "noBorders",
                  margin: [0, 0, 10, 0],
               },
               {
                  table: {
                     heights: [50],
                     widths: ["*"],
                     body: [
                        [
                           {
                              text: `${getProcessComplaint().length - 1}`,
                              alignment: "center",
                              fillColor: "#eeeeee",
                              margin: [0, 20],
                              color: "#162c44",
                              bold: true,
                           },
                        ],
                     ],
                  },
                  layout: "noBorders",
                  margin: [10, 0, 0, 0],
               },
            ],
            margin: [0, 0, 0, 10],
            pageBreak: getFinishedComplaint().length > 1 ? "after" : "none",
         },
         getFinishedComplaint().length > 1
            ? [
                 {
                    text: "Listado de denuncias resueltas",
                    margin: [0, 10, 0, 2],
                    bold: true,
                    color: "#162c44",
                 },
                 {
                    table: {
                       headerRows: 1,
                       widths: ["*", "*", "*", "*"],
                       body: getFinishedComplaint(),
                    },
                    layout: "lightHorizontalLines",
                    margin: [0, 0, 0, 10],
                 },
              ]
            : undefined,
         getProcessComplaint().length > 1
            ? [
                 {
                    text: "Listado de denuncias en proceso",
                    margin: [0, 10, 0, 2],
                    bold: true,
                    color: "#162c44",
                 },
                 {
                    table: {
                       headerRows: 1,
                       widths: ["*", "*", "*", "*"],
                       body: getProcessComplaint(),
                    },
                    layout: "lightHorizontalLines",
                    margin: [0, 0, 0, 10],
                 },
              ]
            : undefined,
         {
            text: "Denuncias totales",
            fontSize: 12,
            bold: true,
            color: "#162c44",
            margin: [0, 0, 0, 10],
            pageBreak: "before",
         },
         {
            columns: [
               createChart(props.overallBarChart),
               [
                  {
                     text: "Interpretación",
                     bold: true,
                     color: "#162c44",
                     margin: [0, 40, 0, 10],
                  },
                  {
                     text: [
                        "Como se observa en la gráfica, existen un total de ",
                        { text: `${props.totalComplaints.length}`, bold: true },
                        " denuncias y/o quejas interpuestas en las diferentes empresas.",
                     ],
                     alignment: "left",
                     lineHeight: 1.2,
                     fontSize: 11,
                  },
               ],
            ],
         },
         {
            text: props.branchesFound ? "Desglose por sucursales/establecimiento" : "Desglose por razón social",
            margin: [0, 10, 0, 10],
         },
         props.barChartValues.overall.xAxis.map((e, index) => {
            return {
               text: [
                  "La razón social ",
                  { text: `${e}`, bold: true },
                  " abarca ",
                  { text: `${props.barChartValues.overall.yAxis[index]}`, bold: true },
                  " denuncias y/o quejas, lo que representa el ",
                  {
                     text: `${complaintPercentage(props.barChartValues, props.barChartValues.overall.yAxis[index])}`,
                     bold: true,
                  },
                  "%.",
               ],
               alignment: "left",
               fontSize: 11,
               lineHeight: 1.2,
            };
         }),
         {
            text: "Tipo de denuncia",
            fontSize: 12,
            bold: true,
            color: "#162c44",
            margin: [0, 55, 0, 10],
         },
         {
            columns: [
               createChart(props.typeBarChart),
               [
                  {
                     text: "Interpretación",
                     bold: true,
                     color: "#162c44",
                     margin: [0, 40, 0, 10],
                  },
                  {
                     text:
                        typeValues.length !== 0
                           ? [
                                "La gráfica nos muestra que de un total de ",
                                { text: `${props.totalComplaints.length}`, bold: true },
                                " interposiciones",
                                {
                                   text: typeValuesCount,
                                },
                                ".",
                             ]
                           : "No existen denuncias que pertenezcan a ninguna categoría",
                     alignment: "left",
                     lineHeight: 1.2,
                     fontSize: 11,
                  },
               ],
            ],
            margin: [0, 10, 0, 0],
         },
         {
            text: props.branchesFound ? "Desglose por sucursales/establecimiento" : "Desglose por razón social",
            margin: [0, 5, 0, 10],
         },
         props.barChartValues.type.xAxis.map((e, index) => {
            return {
               text:
                  individualChartValues(props.barChartValues, "type", index)
                     .map((value) => {
                        return getComplaintValue(value);
                     })
                     .filter(function (e) {
                        return e !== undefined;
                     }).length !== 0
                     ? [
                          { text: `${e}.`, bold: true },
                          " De un total de ",
                          { text: `${complaintSum(typeValues)}`, bold: true },
                          " interposiciones",
                          {
                             text: individualChartValues(props.barChartValues, "type", index).map((value) => {
                                return getComplaintValue(value);
                             }),
                          },
                          ".",
                       ]
                     : [
                          { text: `${e}.`, bold: true },
                          {
                             text: " No cuenta con denuncias para esta clasificación.",
                          },
                       ],
               alignment: "left",
               fontSize: 11,
               lineHeight: 1.2,
            };
         }),
         {
            text: "Denuncias anónimas",
            fontSize: 12,
            bold: true,
            color: "#162c44",
            margin: [0, 0, 0, 10],
            pageBreak: "before",
         },
         {
            columns: [
               createChart(props.anonymousBarChart),
               [
                  {
                     text: "Interpretación",
                     bold: true,
                     color: "#162c44",
                     margin: [0, 40, 0, 10],
                  },
                  {
                     text:
                        anonymousValuesCount.length !== 0
                           ? [
                                "De las ",
                                { text: `${props.totalComplaints.length}`, bold: true },
                                " denuncias y/o quejas que se presentaron",
                                {
                                   text: anonymousValuesCount,
                                },
                                ".",
                             ]
                           : "No existen denuncias que pertenezcan a ninguna de las categorías",
                     alignment: "left",
                     lineHeight: 1.2,
                     fontSize: 11,
                  },
               ],
            ],
            margin: [0, 10, 0, 0],
         },
         {
            text: props.branchesFound ? "Desglose por sucursales/establecimiento" : "Desglose por razón social",
            margin: [0, 10, 0, 10],
         },
         props.barChartValues.anonymous.xAxis.map((e, index) => {
            return {
               text:
                  individualChartValues(props.barChartValues, "anonymous", index)
                     .map((value) => {
                        return getComplaintValue(value);
                     })
                     .filter(function (e) {
                        return e !== undefined;
                     }).length !== 0
                     ? [
                          { text: `${e}.`, bold: true },
                          " De un total de ",
                          {
                             text: `${complaintSum(anonymousValues)}`,
                             bold: true,
                          },
                          " interposiciones",
                          {
                             text: individualChartValues(props.barChartValues, "anonymous", index).map((value) => {
                                return getComplaintValue(value);
                             }),
                          },
                          ".",
                       ]
                     : [
                          { text: `${e}.`, bold: true },
                          {
                             text: " No cuenta con denuncias para esta clasificación.",
                          },
                       ],
               alignment: "left",
               lineHeight: 1.2,
               fontSize: 11,
            };
         }),
         {
            text: "Denuncias por calificación",
            bold: true,
            color: "#162c44",
            margin: [0, 55, 0, 10],
         },
         {
            columns: [
               createChart(props.calificationBarChart),
               [
                  {
                     text: "Interpretación",
                     bold: true,
                     color: "#162c44",
                     margin: [0, 40, 0, 10],
                  },
                  {
                     text:
                        calificationValuesCount.length !== 0
                           ? [
                                "De las ",
                                { text: `${complaintSum(calificationValues)}`, bold: true },
                                " denuncias y/o quejas que se presentaron",
                                {
                                   text: calificationValuesCount,
                                },
                                ".",
                             ]
                           : "No existen denuncias que pertenzcan a ninguna de las categorías",
                     alignment: "left",
                     lineHeight: 1.2,
                     fontSize: 11,
                  },
               ],
            ],
         },
         {
            text: props.branchesFound ? "Desglose por sucursales/establecimiento" : "Desglose por razón social",
            margin: [0, 10, 0, 10],
         },
         props.barChartValues.calification.xAxis.map((e, index) => {
            return {
               text:
                  individualChartValues(props.barChartValues, "type", index)
                     .map((value) => {
                        return getComplaintValue(value);
                     })
                     .filter(function (e) {
                        return e !== undefined;
                     }).length !== 0
                     ? [
                          { text: `${e}.`, bold: true },
                          " De un total de ",
                          {
                             text: `${complaintSum(calificationValues)}`,
                             bold: true,
                          },
                          " interposiciones",
                          {
                             text: individualChartValues(props.barChartValues, "calification", index).map((value) => {
                                return getComplaintValue(value);
                             }),
                          },
                          ".",
                       ]
                     : [
                          { text: `${e}.`, bold: true },
                          {
                             text: " No cuenta con denuncias para esta clasificación.",
                          },
                       ],
               alignment: "left",
               lineHeight: 1.2,
               fontSize: 11,
            };
         }),
         props.branchesFound
            ? [
                 {
                    text: "Clasificación - Posible Comisión de los Derechos Humanos",
                    bold: true,
                    color: "#162c44",
                    pageBreak: "before",
                 },
                 {
                    columns: [
                       createChart(props.clasification1BarChart),
                       [
                          {
                             text: "Interpretación",
                             bold: true,
                             color: "#162c44",
                             margin: [0, 10, 0, 10],
                          },
                          {
                             text:
                                clasification1ValuesCount.length !== 0
                                   ? [
                                        "De manera general",
                                        {
                                           text: clasification1ValuesCount,
                                        },
                                        ".",
                                     ]
                                   : "No existen denuncias que pertenezcan a ninguna de las categorías",
                             alignment: "left",
                             lineHeight: 1.2,
                             fontSize: 11,
                          },
                       ],
                    ],
                    margin: [0, 20, 0, 0],
                 },
                 {
                    text: "Desglose por sucursales/establecimiento",
                    margin: [0, 5, 0, 10],
                 },
                 props.barChartValues.clasification1.xAxis.map((e, index) => {
                    return {
                       text:
                          individualChartValues(props.barChartValues, "clasification1", index)
                             .map((value) => {
                                return getComplaintValue(value);
                             })
                             .filter(function (e) {
                                return e !== undefined;
                             }).length !== 0
                             ? [
                                  { text: `${e}.`, bold: true },
                                  " De un total de ",
                                  {
                                     text: `${complaintSum(clasification1Values)}`,
                                     bold: true,
                                  },
                                  " interposiciones",
                                  {
                                     text: individualChartValues(props.barChartValues, "clasification1", index).map(
                                        (value) => {
                                           return getComplaintValue(value);
                                        }
                                     ),
                                  },
                                  ".",
                               ]
                             : [
                                  { text: `${e}.`, bold: true },
                                  {
                                     text: " No cuenta con denuncias para esta clasificación.",
                                  },
                               ],
                       alignment: "left",
                       fontSize: 11,
                       lineHeight: 1.2,
                    };
                 }),
                 {
                    text: "Clasificación - Posible Violación de los Derechos Humanos",
                    bold: true,
                    color: "#162c44",
                    margin: [0, 55, 0, 10],
                 },
                 {
                    columns: [
                       createChart(props.clasification2BarChart),
                       [
                          {
                             text: "Interpretación",
                             bold: true,
                             color: "#162c44",
                             margin: [0, 10, 0, 10],
                          },
                          {
                             text:
                                clasification2ValuesCount.length !== 0
                                   ? [
                                        "De manera general",
                                        {
                                           text: clasification2ValuesCount,
                                        },
                                        ".",
                                     ]
                                   : "No existen denuncias que pertenezcan a ninguna de las categorías.",
                             alignment: "left",
                             fontSize: 11,
                             lineHeight: 1.2,
                          },
                       ],
                    ],
                    margin: [0, 10, 0, 0],
                 },
                 {
                    text: "Desglose por sucursales/establecimiento",
                    margin: [0, 10, 0, 10],
                 },
                 props.barChartValues.clasification2.xAxis.map((e, index) => {
                    return {
                       text:
                          individualChartValues(props.barChartValues, "clasification2", index)
                             .map((value) => {
                                return getComplaintValue(value);
                             })
                             .filter(function (e) {
                                return e !== undefined;
                             }).length !== 0
                             ? [
                                  { text: `${e}.`, bold: true },
                                  `${individualChartValues(props.barChartValues, "clasification2", index).filter(
                                     function (e) {
                                        return e !== undefined;
                                     }
                                  )}`,
                                  {
                                     text: individualChartValues(props.barChartValues, "clasification2", index).map(
                                        (value) => {
                                           return getComplaintValue(value);
                                        }
                                     ),
                                  },
                               ]
                             : [
                                  { text: `${e}.`, bold: true },
                                  {
                                     text: " No cuenta con denuncias para esta clasificación.",
                                  },
                               ],
                       alignment: "left",
                       lineHeight: 1.2,
                       fontSize: 11,
                    };
                 }),
                 {
                    text: "Clasificación - Posible Violación a las Políticas y Directrices de la Empresa",
                    bold: true,
                    color: "#162c44",
                    margin: [0, 10, 0, 10],
                    pageBreak: "before",
                 },
                 {
                    columns: [
                       createChartMargin(props.clasification3BarChart),
                       [
                          {
                             text: "Interpretación",
                             bold: true,
                             color: "#162c44",
                             margin: [0, 10, 0, 10],
                          },
                          {
                             text:
                                clasification3ValuesCount.length !== 0
                                   ? [
                                        "De manera general",
                                        {
                                           text: clasification3ValuesCount,
                                        },
                                        ".",
                                     ]
                                   : "No existen denuncias que pertenezcan a ninguna de las categorías",
                             alignment: "left",
                             lineHeight: 1.2,
                             fontSize: 11,
                          },
                       ],
                    ],
                    margin: [0, 10, 0, 0],
                 },
                 {
                    text: "Desglose por sucursales/establecimiento",
                    margin: [0, 10, 0, 10],
                 },
                 props.barChartValues.clasification3.xAxis.map((e, index) => {
                    return {
                       text:
                          individualChartValues(props.barChartValues, "clasification3", index)
                             .map((value) => {
                                return getComplaintValue(value);
                             })
                             .filter(function (e) {
                                return e !== undefined;
                             }).length !== 0
                             ? [
                                  { text: `${e}.`, bold: true },
                                  " De un total de ",
                                  {
                                     text: `${complaintSum(clasification3Values)}`,
                                     bold: true,
                                  },
                                  " interposiciones",
                                  {
                                     text: individualChartValues(props.barChartValues, "clasification3", index).map(
                                        (value) => {
                                           return getComplaintValue(value);
                                        }
                                     ),
                                  },
                                  ".",
                               ]
                             : [
                                  { text: `${e}.`, bold: true },
                                  {
                                     text: "No cuenta con denuncias para esta clasificación.",
                                  },
                               ],
                       alignment: "left",
                       margin: [0, 10, 0, 0],
                       lineHeight: 1.2,
                       fontSize: 11,
                    };
                 }),
              ]
            : [
                 {
                    text: "Denuncias por clasificación",
                    bold: true,
                    color: "#162c44",
                    margin: [0, 10, 0, 10],
                    pageBreak: "before",
                 },
                 {
                    columns: [
                       createChart(props.clasificationBarChart),
                       [
                          {
                             text: "Interpretación",
                             bold: true,
                             color: "#162c44",
                             margin: [0, 10, 0, 10],
                          },
                          {
                             text:
                                clasificationCount.length !== 0
                                   ? [
                                        "De manera general",
                                        {
                                           text: clasificationCount,
                                        },
                                        ".",
                                     ]
                                   : "No existen denuncias que pertenezcan a ninguna de las clasificaciones",
                             alignment: "left",
                          },
                       ],
                    ],
                    margin: [0, 10, 0, 0],
                 },
                 {
                    text: "Desglose por razón social",
                    margin: [0, 10, 0, 10],
                 },
                 props.barChartValues.clasification.xAxis.map((e, index) => {
                    return {
                       text: [
                          { text: `${e}.`, bold: true },
                          " De un total de ",
                          {
                             text: `${complaintSum(clasificationValues)}`,
                             bold: true,
                          },
                          " interposiciones",
                          {
                             text: individualChartValues(props.barChartValues, "clasification", index).map((value) => {
                                return getComplaintValue(value);
                             }),
                          },
                          ".",
                       ],
                       alignment: "left",
                    };
                 }),
              ],
      ],
   };
   return dd;
};
