import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { HeadCell, TableComponent } from "../../TableComponent";
import { getMemberChargesByCompany } from "../../../lib/gobCorpBEClient";
import rolesByService from "../../../const/rolesByService.json";
import { Box, Button, Drawer, Stack, Typography } from "@mui/material";
import {
   getAccessByServiceId,
   getBranchesByCompanyId,
   getGroupUsers,
   getUserByUserUid,
   getUserStatus,
   getUsersBasicInfoByIds,
} from "../../../lib/usersBEClient";
import { AddUserModal } from "../AddUserModal";
import { ModifyUserInfoDrawer } from "../../Drawers/ModifyUserInfoDrawer";
import { ModifyUserRoles } from "../../Drawers/ModifyUserRoles";
import { AddCircle } from "@mui/icons-material";
import { CorporateDataContext } from "../../../context/governanceContext/corporateDataContext";
import { AnyObject } from "chart.js/types/basic";
import { useNavigate } from "react-router-dom";

export const ConsultiveGroupUsers = () => {
   const [userArray, setUserArray] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false);
   const { user, companySelected, companies } = useContext(UserContext);
   const { consultiveGroup, seed } = useContext(CorporateDataContext);
   const [openModifySubUserDrawer, setOpenModifySubUserDrawer] = useState(false);
   const [userInformation, setUserInformation] = useState(null);
   const [modulesAndRoles, setModulesAndRoles] = useState([]);
   const [isLoadingModules, setisLoadingModules] = useState(true);
   const [userSelected, setUserSelected] = useState(null);
   const [openDrawer, setOpenDrawer] = useState(false);
   const navigate = useNavigate();
   // useEffect(() => {
   //    const fetchUserSelected = async () => {
   //       await fetchRolesInfo();
   //    };
   //    if (userSelected) {
   //       fetchUserSelected();
   //    }
   //    // eslint-disable-next-line
   // }, [userSelected]);

   // const fetchRolesInfo = async () => {
   //    setisLoadingModules(true);
   //    setUserInformation({
   //       name: props.company.person_details.comercialName,
   //       module: props.serviceName.serviceId.service,
   //    });
   //    const data = await getUserByUserUid(userSelected.uid);
   //    let modules = {};
   //    for (const iterator of props.company.company_details.servicesDetails) {
   //       if (props.serviceName.serviceId.service === iterator.serviceId.service) {
   //          let branches = [];

   //          const { data } = await getAccessByServiceId(iterator.serviceId._id);
   //          const module = {
   //             module: iterator.serviceId.service,
   //             company: props.company,
   //             roles: [],
   //          };
   //          if (data.serviceName === "Canal de denuncias") {
   //             const branchesResponse = await getBranchesByCompanyId(props.company._id);
   //             branches = branchesResponse.data;
   //          }
   //          for (const rol of userSelected.role) {
   //             if (data.roles.some((role) => role._id === rol._id)) {
   //                module.roles.push(rol);
   //             }
   //          }
   //          const provisionalData = {
   //             company: props.company._id,
   //             service: data,
   //             userLimit: iterator.userLimit,
   //             branches: branches,
   //          };
   //          modules = module;
   //          setModulesAndRoles([provisionalData]);
   //       }
   //    }
   //    setUserInformation({
   //       ...userInformation,
   //       ...modules,
   //       uid: data.user.uid,
   //       _id: data.user._id,
   //       name: props.company.person_details.comercialName,
   //       branches: data.userDetails.branches,
   //       generalRoles: data.user.role,
   //       module: props.serviceName.serviceId.service,
   //    });
   //    setisLoadingModules(false);
   // };

   const usersColumns: HeadCell[] = [
      { field: "username", headerName: "Nombre", type: "string" },
      { field: "email", headerName: "Correo electrónico", type: "string" },
      { field: "phone", headerName: "Teléfono ", type: "string" },
      { field: "roles", headerName: "Rol", type: "popover" },
   ];

   const getGroupRoles = (roles: any[]) => {
      const rolesArray = roles.flatMap((role) =>
         role.roles.filter((r) => rolesByService.GC.includes(r.name)).map((r) => r.name)
      );
      return Array.from(new Set(rolesArray));
   };

   useEffect(() => {
      const fetchUserData = async () => {
         setIsLoading(true);
         const groupId = user.group._id;
         const groupUsers = await getGroupUsers(groupId);
         const filteredGroupUsers = groupUsers.filter((userG) => userG.role.some((role) => role.group === groupId));
         const usersData = filteredGroupUsers.map((user) => ({
            _id: user._id,
            uid: user.uid,
            username: user.firstName + " " + user.lastName,
            firstName: user.firstName,
            lastName: user.lastName,
            phone: user.phoneNumber,
            email: user.email,
            roles: getGroupRoles(user.role),
         }));
         const gcUserIds = usersData
            .filter((user) => user.roles.some((r) => rolesByService.GC.includes(r)))
            .map((user) => user._id);

         if (gcUserIds.length > 0) {
            const chargesResponses = await getMemberChargesByCompany(gcUserIds, groupId);
            const GcChargesResponse = chargesResponses;
            for (const user of usersData) {
               const index = GcChargesResponse.findIndex((c) => c.user === user._id);
               if (index >= 0) {
                  user.roles = user.roles.concat(GcChargesResponse[index].charges);
                  user.roles = user.roles.filter((r) => !rolesByService.GC.includes(r));
               }
            }
         }
         const finalUserFilter = usersData.filter(
            (a) => a._id !== user.id && !a.roles.includes("Usuario de implementación") && a.roles.length > 0
         );
         setUserArray(finalUserFilter);
         setIsLoading(false);
      };
      if (user.group && userArray.length === 0) fetchUserData();
      // eslint-disable-next-line
   }, [user.group, seed]);

   return (
      <Box
         sx={{
            bgcolor: "white",
            boxShadow: 2,
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
            borderRadius: 1,
         }}
      >
         <Stack direction={"row"} justifyContent={"space-between"} alignContent={"center"} sx={{ px: 4, pt: 3 }}>
            <Typography sx={{ fontWeight: 700 }}>Usuarios del módulo</Typography>
            {/* <Button
               variant="contained"
               sx={{ borderRadius: 1, px: 3 }}
               onClick={() => {
                  setOpenModal(true);
               }}
            >
               <AddCircle sx={{ mr: 1 }} />
               Agregar usuario
            </Button> */}
         </Stack>
         <TableComponent
            defaultColumnToOrder="firstName"
            defaultOrder="asc"
            defaultRowsPerPage={5}
            rowsPerPageOptions={[5, 10, 20, 50]}
            headers={usersColumns}
            rows={userArray}
            onClick={(_e, row) => {
               const userId = row.uid;
               const screen = "mi-lecosy/usuarios/0/" + userId;
               return navigate(`/${screen}`);
            }}
            loader={isLoading}
            emptyDataText="No existen usuarios registrados en este módulo"
            disableBorders
         />
         {/* <AddUserModal
            company={props.company}
            open={openModal}
            setOpen={setOpenModal}
            module={true}
            usersByModule={userArray}
            service={props.serviceName}
         />
         <Drawer
            anchor={"right"}
            open={openModifySubUserDrawer}
            hideBackdrop={true}
            onClose={() => setOpenModifySubUserDrawer(false)}
            sx={{ maxWidth: 450 }}
         >
            {userInformation && (
               <ModifyUserRoles
                  addModule={false}
                  userInfo={userInformation}
                  modulesAndRoles={modulesAndRoles}
                  open={openModifySubUserDrawer}
                  setOpen={setOpenModifySubUserDrawer}
                  isLoadingModules={isLoadingModules}
                  setisLoadingModules={setisLoadingModules}
                  fetchData={() => {}}
               />
            )}
         </Drawer> */}
      </Box>
   );
};
