import { useCallback, useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { addResolution } from "../../../../lib/lecosyBackendClient";
import {
   Box,
   Button,
   Grid,
   MenuItem,
   Typography,
   InputAdornment,
   Divider,
   Container,
   CircularProgress,
   ListItemText,
} from "@mui/material";
import RateReviewIcon from "@mui/icons-material/RateReview";
import StickyNote2Icon from "@mui/icons-material/StickyNote2";
import StoreIcon from "@mui/icons-material/Store";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Form, Formik } from "formik";
import { complaintResolutionScreenSchema } from "../../../../lib/validations/inputSchemas";
import { InputTextField } from "../../../../components/Inputs/InputTextField";
import { InputSelectField } from "../../../../components/Inputs/InputSelectField";
import { InputCheckBox } from "../../../../components/Inputs/InputCheckBox";
import { NotificationDocumentsBar } from "../../../../components/notificationsDocumentsBar";
import { SnackBarContext } from "../../../../context/snackBarContext";
import pdfMake from "../../../../const/pdfMakeFonts";
import { toISOString } from "../../../../const/globalConst";
import { ComplaintStepperContext } from "../../../../context/complaintStepperContext";
import { complaintResolution } from "../../../../lib/documentsTemplates/acknowledgmentTemplates";
import { GetSubUsersByComplaintBranch } from "../../../../lib/usersBEClient";
import { UserContext } from "../../../../context/userContext";

const clasificationsOptions = [
   "Posible comisión de un hecho delictivo",
   "Violación de derechos humanos",
   "Violación a las políticas internas y códigos de ética de la organización",
   "No es violatoria a ningún hecho contrario a la ley o a las políticas de la organización",
];

export const ComplaintResolutionScreen = () => {
   const { showSnackBar } = useContext(SnackBarContext);
   const { complaint } = useContext(ComplaintStepperContext);
   const { user } = useContext(UserContext);
   const [isLoading, setIsLoading] = useState<boolean>(false);
   const { data: users, isLoading: loadingUsers } = GetSubUsersByComplaintBranch(
      complaint.company || complaint.companyBranch._id,
      !!complaint.company,
      user.group?._id || undefined
   );
   const date = new Date();
   const navigate = useNavigate();

   const handleSendForm = async (values, actions) => {
      const dd = complaintResolution(
         complaint.reportNumber,
         complaint.complainerDetails
            ? complaint.complainerDetails.name + " " + complaint.complainerDetails.lastName
            : (complaint.type || "denuncia") !== "denuncia"
            ? "Interpositor anónimo"
            : "Denunciante anónimo",
         complaint.clasification,
         values.offenderFacts,
         values.offenderEvidence,
         values.clasificationComplaint,
         values.fundamentals,
         values.measures,
         values.dateTime,
         values.organization,
         complaint.complaintType !== "Quejas",
         `${user.firstName} ${user.lastName}`,
         values.auditor,
         values.director,
         [],
         [],
         complaint.type || "denuncia",
         values.address
      );
      try {
         await addResolution(complaint.reportNumber, values.measures, values.clasificationComplaint);
         showSnackBar("Datos correctos", false);
         actions.resetForm();
      } catch (error) {
         showSnackBar("Hubo un error, favor de volver a intentarlo", true);
      }
      setIsLoading(false);
      const pdfDocGenerator = pdfMake.createPdf(dd);
      pdfDocGenerator.open();
      setTimeout(() => navigate(-1), 1000);
   };

   const handleGetDirAudUsers = useCallback(
      (competent: boolean) => {
         const cumUsersnt = users.filter((user: any) => {
            const groupRole = user.role.filter((role) => role.group);
            const hasCdRole = groupRole.some((role) => {
               return role.roles.some((role) => (competent ? role.name === "Director" : role.name === "Auditor"));
            });
            if (hasCdRole) return true;
            for (const userRole of user.role) {
               if (userRole.company === complaint.company || complaint.companyBranch._id) {
                  const hasDirAudRole = userRole.roles.some((uniqueRole) => {
                     return competent ? uniqueRole.name === "Director" : uniqueRole.name === "Auditor";
                  });
                  if (hasDirAudRole) return true;
               }
            }
         });
         return cumUsersnt;
      },
      [users]
   );

   return (
      <Box p={2}>
         <NotificationDocumentsBar
            head={`Resolución de la ${complaint.type || "denuncia"}`}
            isScheduleAppointment={false}
         />
         <Container maxWidth="xl">
            {!loadingUsers ? (
               <Formik
                  initialValues={{
                     offenderFacts: "",
                     organization: "",
                     address: "",
                     offenderEvidence: "",
                     clasificationComplaint: "",
                     fundamentals: "",
                     measures: "",
                     queryBank: false,
                     dateTime: toISOString(date),
                     auditor: "",
                     director: "",
                  }}
                  validateOnChange={false}
                  validationSchema={() =>
                     complaintResolutionScreenSchema(!complaint.type || complaint.type === "denuncia")
                  }
                  onSubmit={(values, action) => {
                     setIsLoading(true);
                     handleSendForm(values, action);
                  }}
               >
                  <Form>
                     <Box
                        display={"flex"}
                        sx={{ px: 4, py: 1, minWidth: 950, flexDirection: "column", alignItems: "center", m: 2 }}
                     >
                        <Box
                           sx={{
                              width: "100%",
                              backgroundColor: "#FFFFFF",
                              border: 1,
                              borderRadius: 2,
                              borderColor: "#8A95A0",
                           }}
                        >
                           <Grid container>
                              <Grid item xs={4}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <Typography fontWeight={500} variant="body1">
                                       {`Reporte de la ${complaint.type || "denuncia"}`}
                                    </Typography>
                                    <Typography variant="subtitle1">{complaint.reportNumber}</Typography>
                                 </Box>
                              </Grid>
                              <Grid item xs={4}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <Typography fontWeight={500} variant="body1" sx={{ textAlign: "center" }}>
                                       {`Nombre del ${
                                          complaint.type && complaint.type !== "denuncia"
                                             ? "interpositor"
                                             : "denunciante"
                                       }`}
                                    </Typography>
                                    <Typography variant="subtitle1">
                                       {complaint.complaintType === "anonima"
                                          ? "Anónimo"
                                          : `${complaint.complainerDetails?.name} ${complaint.complainerDetails?.lastName}`}
                                    </Typography>
                                 </Box>
                              </Grid>
                              <Grid item xs={4}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       fullWidth
                                       name="dateTime"
                                       id="dateTime"
                                       label="Fecha y hora"
                                       type="datetime-local"
                                       InputLabelProps={{
                                          shrink: true,
                                       }}
                                    />
                                 </Box>
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container>
                              <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                 <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                    DATOS DE LA EMPRESA
                                 </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       name="organization"
                                       label="Empresa"
                                       id="organization"
                                       type="text"
                                       fullWidth={true}
                                       InputProps={{
                                          startAdornment: (
                                             <InputAdornment position="start">
                                                <StoreIcon />
                                             </InputAdornment>
                                          ),
                                       }}
                                    />
                                 </Box>
                              </Grid>
                              <Grid item xs={6}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       name="address"
                                       label="Dirección"
                                       id="address"
                                       type="text"
                                       fullWidth={true}
                                       InputProps={{
                                          startAdornment: (
                                             <InputAdornment position="start">
                                                <LocationOnIcon />
                                             </InputAdornment>
                                          ),
                                       }}
                                    />
                                 </Box>
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container>
                              <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                 <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                    RELACIÓN DE LOS HECHOS
                                 </Typography>
                              </Grid>
                              <Grid item xs={6}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       name="offenderFacts"
                                       label="Hechos declarados"
                                       id="offenderFacts"
                                       type="text"
                                       fullWidth={true}
                                       InputProps={{
                                          startAdornment: (
                                             <InputAdornment position="start">
                                                <RateReviewIcon />
                                             </InputAdornment>
                                          ),
                                       }}
                                    />
                                 </Box>
                              </Grid>
                              <Grid item xs={6}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       name="offenderEvidence"
                                       label="Pruebas"
                                       id="offenderEvidence"
                                       type="text"
                                       fullWidth={true}
                                       InputProps={{
                                          startAdornment: (
                                             <InputAdornment position="start">
                                                <StickyNote2Icon />
                                             </InputAdornment>
                                          ),
                                       }}
                                    />
                                 </Box>
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container>
                              <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                 <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                    Resolución
                                 </Typography>
                              </Grid>
                              {(!complaint.type || complaint.type === "denuncia") && (
                                 <Grid item xs={4}>
                                    <Box display={"flex"} sx={{ p: 2 }}>
                                       <InputSelectField
                                          labelId="state-select-label"
                                          id="clasificationComplaint"
                                          name="clasificationComplaint"
                                          label="La denuncia se considera como"
                                          variant="standard"
                                          fullWidth={true}
                                       >
                                          {clasificationsOptions.map((a) => (
                                             <MenuItem value={a} key={a}>
                                                {a}
                                             </MenuItem>
                                          ))}
                                       </InputSelectField>
                                    </Box>
                                 </Grid>
                              )}
                              <Grid item xs={8} sx={{ margin: complaint.type !== "denuncia" ? "0 auto" : "initial" }}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputTextField
                                       variant="standard"
                                       multiline={true}
                                       name="fundamentals"
                                       label="Fundamentos"
                                       id="fundamentals"
                                       type="text"
                                       fullWidth={true}
                                    />
                                 </Box>
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container>
                              <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                 <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                    MEDIDAS
                                 </Typography>
                              </Grid>
                              <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                 <Grid item xs={9}>
                                    <Box display={"flex"} sx={dataFieldSX}>
                                       <InputTextField
                                          variant="standard"
                                          multiline={true}
                                          name="measures"
                                          label="Se ordena la imposición de las siguientes medidas"
                                          id="measures"
                                          type="text"
                                          fullWidth={true}
                                       />
                                    </Box>
                                 </Grid>
                              </Grid>

                              <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                 <Box sx={{ display: "flex" }}>
                                    <InputCheckBox
                                       label="Enviar expediente de la presente denuncia a banco de consultas jurídicas."
                                       name="queryBank"
                                       id="queryBank"
                                       size="medium"
                                    />
                                 </Box>
                              </Grid>
                           </Grid>
                           <Divider sx={{ mx: 2 }} />
                           <Grid container>
                              <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                 <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                    Auditor
                                 </Typography>
                              </Grid>
                              <Grid item xs={2} />
                              <Grid item xs={8}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputSelectField
                                       id="auditor"
                                       name="auditor"
                                       label="Nombre"
                                       labelId="auditor"
                                       variant={"standard"}
                                       fullWidth
                                    >
                                       {handleGetDirAudUsers(false).map((e: any) => (
                                          <MenuItem key={e.firstName} value={`${e.firstName} ${e.lastName}`}>
                                             <ListItemText primary={`${e.firstName} ${e.lastName}`} />
                                          </MenuItem>
                                       ))}
                                    </InputSelectField>
                                 </Box>
                              </Grid>
                           </Grid>
                           <Grid container>
                              <Grid item xs={12} sx={{ display: "flex", p: 2 }}>
                                 <Typography color={"#2d4357"} fontWeight={700} variant="h6">
                                    Director
                                 </Typography>
                              </Grid>
                              <Grid item xs={2} />
                              <Grid item xs={8}>
                                 <Box display={"flex"} sx={dataFieldSX}>
                                    <InputSelectField
                                       id="director"
                                       name="director"
                                       label="Nombre"
                                       labelId="director"
                                       variant={"standard"}
                                       fullWidth
                                    >
                                       {handleGetDirAudUsers(true).map((e: any) => (
                                          <MenuItem key={e.firstName} value={`${e.firstName} ${e.lastName}`}>
                                             <ListItemText primary={`${e.firstName} ${e.lastName}`} />
                                          </MenuItem>
                                       ))}
                                    </InputSelectField>
                                 </Box>
                              </Grid>
                           </Grid>
                        </Box>
                        <Box display={"flex"} sx={{ width: "100%", justifyContent: "flex-end" }}>
                           <Button variant="contained" sx={{ mt: 2 }} type="submit">
                              {!isLoading ? "Enviar" : <CircularProgress size={24} sx={{ color: "white" }} />}
                           </Button>
                        </Box>
                     </Box>
                  </Form>
               </Formik>
            ) : (
               <Box display={"flex"} width={"100%"} justifyContent={"center"}>
                  <CircularProgress size={24} />
               </Box>
            )}
         </Container>
      </Box>
   );
};

const dataFieldSX = {
   alignItems: "center",
   justifyContent: "center",
   flexDirection: "column",
   p: 2,
};
