import { Box, Button, Stack, Typography } from "@mui/material";
import React, { useContext } from "react";
import { UpdateCompletedSession } from "../../../lib/gobCorpBEClient";
import { GovernanceSessionContext } from "../../../context/governanceContext/governanceSessionContext";

export const CancelSessionModal = ({ setOpenConfirmCancelSession, createPDF }) => {
   const { mutate } = UpdateCompletedSession();
   const { session, socket } = useContext(GovernanceSessionContext);
   const handleCancelSession = () => {
      mutate(
         { completed: false, sessionId: session._id, canceled: true },
         {
            onError: (error) => {
               console.log(error);
               setOpenConfirmCancelSession(false);
               showSnackBar("Error al cancelar session.", true);
            },
            onSuccess: () => {
               socket.emit("cancel-session", session._id);
               createPDF(null, true);
               setOpenConfirmCancelSession(false);
            },
         }
      );
   };
   return (
      <Stack>
         <Box
            sx={{
               py: 1,
               bgcolor: "#152c44",
               borderTopLeftRadius: 15,
               borderTopRightRadius: 15,
               display: "flex",
               justifyContent: "space-between",
               alignItems: "center",
            }}
         >
            <Typography sx={{ color: "white", ml: 4, fontWeight: 600 }}>Cancelar sesión</Typography>
         </Box>
         <Stack
            sx={{
               bgcolor: "background.paper",
               py: 3,
               px: 6,
               borderBottomLeftRadius: 15,
               borderBottomRightRadius: 15,
               alignItems: "center",
               rowGap: 2,
            }}
         >
            <Typography>¿Estas seguro que quieres cancelar la sesión?</Typography>
            <Box sx={{ display: "flex", columnGap: 4 }}>
               <Button
                  variant="contained"
                  sx={{ bgcolor: "#2D4357", color: "white", alignSelf: "flex-end", width: 90 }}
                  onClick={() => {
                     setOpenConfirmCancelSession(false);
                  }}
               >
                  Cancelar
               </Button>
               <Button
                  variant="contained"
                  sx={{ bgcolor: "#2D4357", color: "white", alignSelf: "flex-end", width: 90 }}
                  onClick={handleCancelSession}
               >
                  Aceptar
               </Button>
            </Box>
         </Stack>
      </Stack>
   );
};
function showSnackBar(arg0: string, arg1: boolean) {
   throw new Error("Function not implemented.");
}
