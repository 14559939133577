export const QuillToolbarList = () => (
   <div id="toolbar">
      {/* <span className="ql-formats">
          <select className="ql-header" defaultValue="3">
             <option value="1">Título</option>
             <option value="2">Subtítulo</option>
             <option value="3">Normal</option>
          </select>
       </span> */}
      {/* <span className="ql-formats">
          <button className="ql-bold" />
          <button className="ql-italic" />
          <button className="ql-underline" />
          <button className="ql-strike" />
       </span> */}
      <span className="ql-formats">
         <button className="ql-list" value="ordered" />
         <button className="ql-list" value="bullet" />
         <button className="ql-indent" value="-1" />
         <button className="ql-indent" value="+1" />
      </span>
      {/* <span className="ql-formats">
          <select className="ql-align" />
          <select className="ql-color" />
          <select className="ql-background" />
       </span> */}
      {/* <span className="ql-formats">
          <button className="ql-link" />
       </span>
       <span className="ql-formats">
          <button className="ql-undo">
             <CustomUndo />
          </button>
          <button className="ql-redo">
             <CustomRedo />
          </button>
       </span> */}
   </div>
);

export default QuillToolbarList;
