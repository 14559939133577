import { Avatar, Box, Stack, Typography } from "@mui/material";
import { getDateAndTimeFromISODate } from "../../../../const/globalConst";

export const Comments = (props: { comment: any; user?: any }) => {
   const { comment, user } = props;
   const formatAvatarText = (text: string) => {
      if (!text) return "";
      const avatarText = text?.split(" ");
      return avatarText[0].charAt(0) + avatarText[1].charAt(0);
   };

   return (
      <>
         <Stack
            sx={{
               border: "1px solid",
               borderColor: "#CBD5E0",
               p: 2,
            }}
            spacing={0.5}
         >
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
               <Avatar src={user?.profilePicture} sx={{ width: 25, height: 25, fontSize: 10 }}>
                  {formatAvatarText(user?.user || "Usuario no disponible")}
               </Avatar>
               <Typography variant="caption">{user?.user || "Usuario no disponible"}</Typography>
            </Box>
            <Typography variant="caption" sx={{ color: "#3F5063" }}>
               {comment.content}
            </Typography>
            <Box id={"comment"} sx={{ display: "flex", flexDirection: "column" }}>
               <Typography variant="caption" sx={{ fontSize: 10 }}>
                  {getDateAndTimeFromISODate(comment.createdAt || new Date())}
               </Typography>
            </Box>
         </Stack>
      </>
   );
};
