import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { SnackBarContext } from "../context/snackBarContext";
import { UserContext } from "../context/userContext";
import { HeadCell, TableComponentFilter } from "../components/TableComponent";
import GetFilteredComplaints from "../hooks/complaints/getFilteredComplaints";
import GetTablesFilters from "../hooks/tables/getTablesFilters";
import { ComplaintContext } from "../context/complaintContext";
import GetTableComponent from "../hooks/tables/getTableComponent";
import { capitalizeFirstLetter, getDateFromISODate } from "../const/globalConst";

const columns: HeadCell[] = [
   { field: "reportNumber", headerName: "No. reporte", type: "string" },
   { field: "company", headerName: "Empresa/Sucursal", type: "string" },
   { field: "complainerType", headerName: "Denuncia", type: "string" },
   { field: "complaintType", headerName: "Tipo", type: "string" },
   { field: "category", headerName: "Clasificación", type: "string" },
   { field: "updatedAt", headerName: "Fecha de finalización", type: "string" },
   { field: "status", headerName: "Estatus", type: "string" },
];

export const CompletedComplaintsTable = () => {
   const { showSnackBar } = useContext(SnackBarContext);
   const { user } = useContext(UserContext);
   const { loadingComplaints, complaints } = useContext(ComplaintContext);
   const navigate = useNavigate();
   const filteredComplaints = GetFilteredComplaints(complaints, "resuelto");
   const branchesFilter = GetTablesFilters((complaints as any).get("resuelto"), "branches");

   const rows =
      !loadingComplaints &&
      filteredComplaints?.map((element) => {
         const complaint = {
            reportNumber: element.complaint.reportNumber,
            complaintType: capitalizeFirstLetter(element.complaint.type || "denuncia"),
            complainerType: element.complaint.complainerType,
            category: element.complaint.category || "N/A",
            updatedAt: getDateFromISODate(element.complaint.updatedAt),
            sentDate: getDateFromISODate(element.complaint.sentDate || element.complaint.createdAt),
            status: capitalizeFirstLetter(element.complaint.status),
            company: element.branch?.name || element.company.person_details.comercialName,
            competence: element.complaint.competence
               ? {
                    competent: element.complaint.competence.competent,
                    incompetent: element.complaint.competence.incompetent,
                 }
               : undefined,
         };
         return complaint;
      });

   const filters: TableComponentFilter[] = [
      {
         id: "company",
         label: "Empresas/Sucursales",
         type: "select",
         data: branchesFilter,
         allOption: "Todas las sucursales",
      },
      { id: "reportNumber", label: "No. de Reporte", type: "text", allOption: "", data: [] },
   ];

   const filtersInitialState = {
      company: "Todas las sucursales",
      reportNumber: "",
   };

   const tableComponentOnClick = (e, row) => {
      if (row.competence && user.id === row.competence.incompetent) {
         return showSnackBar(`Se te acusa de incompetente para la denuncia ${row.reportNumber}`, true);
      }
      navigate(row.reportNumber);
   };
   return GetTableComponent({
      defaultOrder: "branch",
      defaultText: "No existen denuncias completadas en este momento",
      tableHeaders: columns,
      tableLoader: loadingComplaints,
      tableRow: rows,
      selectedRow: (e, row) => tableComponentOnClick(e, row),
      tableFilters: filters,
      tableInitialState: filtersInitialState,
   });
};
