import { useState, useContext, useEffect } from "react";
import { Box, Typography, CircularProgress, Divider } from "@mui/material";
import { FilterComponent } from "../FilterComponent";
import { HeadCell, TableComponent } from "../TableComponent";
import { UserContext } from "../../context/userContext";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { GetReceiptByCompanyId, GetReceiptByGroupId, getUrlS3 } from "../../lib/usersBEClient";
import { FilesModalDownload } from "./filesModalDownload";

interface ReceiptTableComponentProps {
   company?: any;
}

export const ReceiptTableComponent = (props: ReceiptTableComponentProps) => {
   const { user } = useContext(UserContext);
   const group = user.group ? user.group.invoiceCompany : null;
   const [isLoading, setIsLoading] = useState(true);
   const [filteredRecipt, setFilteredRecipt] = useState([]);
   const [originalData, setOriginalData] = useState([]);
   const [open, setOpen] = useState<boolean>(false);
   const [file1, setFile1] = useState(null);
   const [file2, setFile2] = useState(null);
   const [fileName1, setFileName1] = useState(null);
   const [fileName2, setFileName2] = useState(null);
   const [idReceipt, setIdReceipt] = useState(null);
   const [nameReceipt, setNameReceipt] = useState(null);

   const getFile1 = async (clientId: string, year: string, month: string, file1: string, id: string, name: string) => {
      const fileData1: string = await getUrlS3(
         "files-lecosy",
         { folder: `receipts/${clientId}/${year}/${month}` },
         file1
      );
      setNameReceipt(name);
      setIdReceipt(id);
      setFile1(fileData1);
      setFileName1(file1);
   };

   const getFile2 = async (clientId: string, year: string, month: string, file2: string) => {
      const fileData2: string = await getUrlS3(
         "files-lecosy",
         { folder: `receipts/${clientId}/${year}/${month}` },
         file2
      );
      setFile2(fileData2);
      setFileName2(file2);
   };

   useEffect(() => {
      const refetchReceipt = async (_id) => {
         if (!user.group) {
            const recipt = await GetReceiptByCompanyId(_id);

            const reciptData = recipt.map((e) => ({
               companyId: _id ? _id : "",
               companyName: e.companyName ? e.companyName : "",
               _id: e._id,
               name: e.name,
               paymentDate: e.paymentDate,
               unformatedDate: new Date(e.paymentDate.split("T")[0]).getTime(),
               createdAt: e.createdAt,
               unformatedDate2: new Date(e.createdAt.split("T")[0]).getTime(),
               file1: e.file1,
               file2: e.file2,
            }));
            setOriginalData(reciptData);
            setFilteredRecipt(reciptData);
            setIsLoading(false);
            return;
         }
         if (!group) {
            const recipt = await GetReceiptByGroupId(_id);
            const reciptData = recipt.map((e) => ({
               companyId: e.companyId ? e.companyId : "",
               companyName: e.companyName ? e.companyName : "",
               _id: e._id,
               name: e.name,
               paymentDate: e.paymentDate,
               unformatedDate: new Date(e.paymentDate.split("T")[0]).getTime(),
               createdAt: e.createdAt,
               unformatedDate2: new Date(e.createdAt.split("T")[0]).getTime(),
               file1: e.file1,
               file2: e.file2,
            }));
            setOriginalData(reciptData);
            setFilteredRecipt(reciptData);
            setIsLoading(false);
         } else {
            const recipt = await GetReceiptByCompanyId(_id);

            const reciptData = recipt.map((e) => ({
               companyId: _id ? _id : "",
               companyName: e.companyName ? e.companyName : "",
               _id: e._id,
               name: e.name,
               paymentDate: e.paymentDate,
               unformatedDate: new Date(e.paymentDate.split("T")[0]).getTime(),
               createdAt: e.createdAt,
               unformatedDate2: new Date(e.createdAt.split("T")[0]).getTime(),
               file1: e.file1,
               file2: e.file2,
            }));
            setOriginalData(reciptData);
            setFilteredRecipt(reciptData);
            setIsLoading(false);
         }
      };
      if (user.group) {
         setIsLoading(true);
         if (group) {
            refetchReceipt(group._id);
         } else {
            refetchReceipt(user.group._id);
         }
      } else {
         refetchReceipt(props.company._id);
      }
      // eslint-disable-next-line
   }, []);

   const branchesColumns = (): HeadCell[] => {
      const headers: HeadCell[] = [{ field: "name", headerName: "Nombre", type: "string", width: 50 }];
      const headers2: HeadCell[] = [
         { field: "paymentDate", headerName: "Fecha de pago", type: "date", width: 50 },
         { field: "createdAt", headerName: "Fecha de envió", type: "date", width: 50 },
         {
            field: "icon",
            headerName: "Ver",
            type: "button",
            width: 50,
            icon: <VisibilityIcon fontSize="small" />,
            onClick: async (e, row) => {
               setOpen(true);
               await getFile1(
                  row.companyId,
                  row.paymentDate.split("-")[0],
                  row.paymentDate.split("-")[1],
                  row.file1,
                  row._id,
                  row.name
               );
               await getFile2(row.companyId, row.paymentDate.split("-")[0], row.paymentDate.split("-")[1], row.file2);
            },
         },
      ];
      if (user.group) {
         headers.push({ field: "companyName", headerName: "Empresa", type: "string", width: 50 });
      }
      return [...headers, ...headers2];
   };

   const reciptsColumns: HeadCell[] = [
      { field: "name", headerName: "Nombre", type: "string", width: 50 },
      { field: "paymentDate", headerName: "Fecha de pago", type: "date", width: 50 },
      { field: "createdAt", headerName: "Fecha de envió", type: "date", width: 50 },
      {
         field: "icon",
         headerName: "Ver",
         type: "button",
         width: 50,
         icon: <VisibilityIcon fontSize="small" />,
         onClick: async (e, row) => {
            setOpen(true);
            await getFile1(
               group._id,
               row.paymentDate.split("-")[0],
               row.paymentDate.split("-")[1],
               row.file1,
               row._id,
               row.name
            );
            await getFile2(group._id, row.paymentDate.split("-")[0], row.paymentDate.split("-")[1], row.file2);
         },
      },
   ];
   return (
      <>
         <Box>
            <Box sx={{ bgcolor: "white", py: 1, boxShadow: 2, borderRadius: 0 }}>
               {!isLoading ? (
                  <>
                     <Box display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography fontSize={20} fontWeight={500} mx={5}>
                           Complementos de pago
                        </Typography>
                        <FilterComponent
                           originaltableOneRows={originalData}
                           tableOneFilteredRows={filteredRecipt}
                           period={true}
                           setTableOneFilteredRows={setFilteredRecipt}
                           filters={["daterange"]}
                           searchBar={
                              <Box width={"100%"} sx={{ display: "flex", flexDirection: "row", flex: 1 }}></Box>
                           }
                        />
                     </Box>
                     <Divider />
                     <TableComponent
                        defaultColumnToOrder="name"
                        defaultOrder="asc"
                        defaultRowsPerPage={5}
                        emptyDataText="No hay complementos registrados por el momento"
                        rowsPerPageOptions={[5, 10, 15, 20]}
                        loader={isLoading}
                        headers={!group ? branchesColumns() : reciptsColumns}
                        onClick={() => {}}
                        rows={filteredRecipt}
                        disableBorders
                     />
                  </>
               ) : (
                  <>
                     <Box display={"flex"}>
                        <CircularProgress sx={{ display: "flex", flex: 1, justifyContent: "center" }} />
                     </Box>
                  </>
               )}
            </Box>
         </Box>

         <FilesModalDownload
            setOpen={setOpen}
            open={open}
            file1URL={file1}
            file2URL={file2}
            file1Name={fileName1}
            file2Name={fileName2}
            id={idReceipt}
            name={nameReceipt}
            receipt={true}
         />
      </>
   );
};
