export const complianceIcon =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/ICONOS+SISTEMA_Programa+de+cumplimiento.svg";
export const riskIcon =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/ICONOS+SISTEMA_Gesti%C3%B3n+de+riesgos.svg";
export const correlationIcon =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/ICONOS+SISTEMA_Correlaci%C3%B3n+de+leyes.svg";
export const auditIcon =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/ICONOS+SISTEMA_Auditor%C3%ADa+interna+.svg";
export const practicesIcon =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/ICONOS+SISTEMA_Buenas+pr%C3%A1cticas.svg";
export const complaintIcon =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/ICONOS+SISTEMA_Canal+de+denuncia.svg";
export const governanceIcon = "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/Icon_Governance.png";
export const beneficiaryControllerIcon =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/ICONOS+de+m%C3%B3dulos+LECOSY_Beneficiario+Controlador-08.svg";
export const logo_Lecosy_Blanco = "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/logo-lecosy-blanco.png";
export const logo_Isotipo_Blanco =
   "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/isotipo/Lecosy_icono+blanco.png";
export const logo_Lecosy = "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/logo-lecosy.png";
export const link = "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/ICONOS+DE+ENLACE+Y+CORREO_e.png";
export const mail = "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/ICONOS+DE+ENLACE+Y+CORREO_c.png";
export const qr = "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/icons/qr.png";
