import React, { useContext, useState } from "react";
import {
   CircularProgress,
   IconButton,
   MenuItem,
   Stack,
   TextField,
   Typography,
   Box,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableHead,
   TablePagination,
   TableRow,
   TableSortLabel,
   Paper,
   Tooltip,
   Collapse,
   styled,
   Chip,
   Popover,
   Grid,
   Button,
   Checkbox,
} from "@mui/material";
import { visuallyHidden } from "@mui/utils";
import { Order, getComparator, stableSort } from "../const/globalConst";
import { Add, DeleteForever, KeyboardArrowDown, KeyboardArrowUp, Refresh } from "@mui/icons-material";
import { EmptyDataTableCell } from "./EmptyDataTableCell";
import BorderColorIcon from "@mui/icons-material/BorderColor";
import { SupportContext } from "../context/supportContext";
import TableComponentContentMap from "./TableComponentHeader/TableComponentContentMap";

const ListItem = styled("li")(({ theme }) => ({
   margin: theme.spacing(0.5),
}));

type headerType =
   | "string"
   | "group"
   | "date"
   | "number"
   | "button"
   | "popover"
   | "image"
   | "status"
   | "boolean"
   | "check"
   | "collapsible"
   | "array"
   | "chips"
   | "arrayRole";

type headerAlign = "right" | "left" | "inherit" | "center" | "justify";

type filterType = "select" | "text" | "number" | "date";

export interface HeadCell {
   field: string;
   headerName: string;
   width?: number;
   type: headerType;
   align?: headerAlign;
   icon?: React.ReactNode;
   onClick?: (e, row, group?: boolean) => void;
}

interface EnhancedTableProps {
   onRequestSort: (event: React.MouseEvent<unknown>, property: string) => void;
   order: Order;
   orderBy: string;
   headers: HeadCell[];
   headerColor?: boolean;
   disable?: boolean;
   numSelected?: number;
   rowCount?: number;
   onSelectAllClick?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

function EnhancedTableHead(props: EnhancedTableProps) {
   const { order, orderBy, onRequestSort, numSelected, rowCount, onSelectAllClick } = props;
   const createSortHandler = (newOrderBy: string) => (event: React.MouseEvent<unknown>) => {
      onRequestSort(event, newOrderBy);
   };

   return (
      <TableHead>
         <TableRow sx={{ bgcolor: props.headerColor ? (props.disable ? "whitesmoke" : "#162c44") : "white" }}>
            {props.headers.map((headCell) => {
               const validate =
                  headCell.field === "action" ||
                  headCell.field === "type" ||
                  headCell.field === "metadata" ||
                  headCell.field === "sheet" ||
                  headCell.field === "file";
               if (headCell.field === "check")
                  return (
                     <>
                        <TableCell
                           key={headCell.field}
                           align={headCell.align ? headCell.align : "center"}
                           sortDirection={orderBy === headCell.field ? order : false}
                           width={headCell.width}
                           sx={{ py: props.disable ? 0 : 1.5, px: 1.5 }}
                        >
                           {!props.disable ? (
                              <Checkbox
                                 indeterminate={numSelected > 0 && numSelected < rowCount}
                                 checked={rowCount > 0 && numSelected === rowCount}
                                 onChange={onSelectAllClick}
                                 inputProps={{
                                    "aria-label": "select all desserts",
                                 }}
                                 sx={{
                                    "& .MuiSvgIcon-root": {
                                       fill: "white",
                                    },
                                 }}
                              />
                           ) : (
                              <Box width={headCell.width}></Box>
                           )}
                        </TableCell>
                     </>
                  );
               else
                  return (
                     <TableCell
                        key={headCell.field}
                        align={headCell.align ? headCell.align : "center"}
                        sortDirection={orderBy === headCell.field ? order : false}
                        width={headCell.width}
                        sx={{ py: props.disable ? 0 : 1.5, px: 1.5 }}
                     >
                        {!props.disable ? (
                           <TableSortLabel
                              sx={{
                                 ".MuiTableSortLabel-icon": {
                                    color: props.headerColor ? "#f2f4f4 !important" : "#64748B !important",
                                 },
                              }}
                              hideSortIcon={headCell.field === "icon" || headCell.field === "logo" || validate}
                              active={validate ? false : orderBy === headCell.field}
                              direction={orderBy === headCell.field ? order : "asc"}
                              onClick={validate ? () => {} : createSortHandler(headCell.field)}
                              style={{ width: headCell.width }}
                           >
                              <Typography
                                 sx={{
                                    color: props.headerColor ? "white" : "#162c44",
                                 }}
                                 fontWeight={600}
                                 variant="subtitle2"
                              >
                                 {headCell.headerName}
                              </Typography>
                              {orderBy === headCell.field ? (
                                 <Box component="span" sx={visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                 </Box>
                              ) : null}
                           </TableSortLabel>
                        ) : (
                           <Box width={headCell.width}></Box>
                        )}
                     </TableCell>
                  );
            })}
         </TableRow>
      </TableHead>
   );
}

export interface TableComponentFilter {
   id: string;
   label: string;
   type: filterType;
   allOption: string;
   data: string[];
}

export interface TableComponentProps {
   headers: HeadCell[];
   rows?: any[];
   selectedRows?: readonly string[];
   setSelected?: Function;
   defaultColumnToOrder: string;
   defaultOrder: Order;
   defaultRowsPerPage: number;
   rowsPerPageOptions: number[];
   icon?: any;
   loader: boolean;
   emptyDataText: string;
   onClick?: (e, row, group?) => void;
   disableBorders?: boolean;
   dense?: boolean;
   filters?: TableComponentFilter[];
   filtersInitialState?: any;
   showBadges?: boolean;
   checkboxSelection?: boolean;
   multiselect?: boolean;
   collapsible?: boolean;
   opened?: string;
   setOpened?: Function;
   group?: boolean;
   upRows?: any[];
   cursor?: string;
   onClickCollapsible?: (e, row, companyData) => void;
   onDeleteCollapsible?: (e, row) => void;
   headerColor?: boolean;
   disableHeader?: boolean;
}

export const TableComponent = (props: TableComponentProps) => {
   const [order, setOrder] = useState<Order>(props.defaultOrder);
   const [orderBy, setOrderBy] = useState(props.defaultColumnToOrder);
   const [page, setPage] = useState(0);
   const [rowsPerPage, setRowsPerPage] = useState(props.defaultRowsPerPage);
   const [filters, setFilters] = useState(props.filtersInitialState);
   const [anchorEl, setAnchorEl] = useState(null);
   const [isHovered, setIsHovered] = useState(null);
   const [isOn, setIsOn] = useState(false);
   const { setSupportOpen, supportOpen, setUpdateData } = useContext(SupportContext);

   const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
      setAnchorEl(event.currentTarget);
   };

   const handlePopoverClose = () => {
      setIsHovered(null);
      setAnchorEl(null);
   };

   const handleRequestSort = (event: React.MouseEvent<unknown>, newOrderBy: string) => {
      const isAsc = orderBy === newOrderBy && order === "asc";
      const toggledOrder = isAsc ? "desc" : "asc";
      setOrder(toggledOrder);
      setOrderBy(newOrderBy);
   };

   const handleChangePage = (event: unknown, newPage: number) => {
      setPage(newPage);
   };

   const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
      const updatedRowsPerPage = parseInt(event.target.value, 10);
      setRowsPerPage(updatedRowsPerPage);
      setPage(0);
   };

   const filteredRows = (rows: any[]) => {
      let newFilters: any[] = rows;
      if (props.filters) {
         for (const e of props.filters) {
            if (filters[e.id] !== e.allOption) {
               if (e.type === "select") {
                  newFilters = newFilters.filter((row) => row[e.id].includes(filters[e.id]));
               }
               if (e.type === "text" || e.type === "number" || e.type === "date") {
                  newFilters = newFilters.filter((row) => row[e.id].startsWith(filters[e.id]));
               }
            }
         }
      }
      return newFilters;
   };

   const handleFilters = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, fieldId: string) => {
      setFilters({ ...filters, [fieldId]: e.target.value });
      setPage(0);
   };

   const handleClick = (event: React.MouseEvent<unknown>, row: any) => {
      if (props.multiselect) {
         const selectedIndex = props.selectedRows.indexOf(row._id || row.id);
         let newSelected: readonly string[] = [];

         if (selectedIndex === -1) {
            newSelected = newSelected.concat(props.selectedRows, row._id || row.id);
         } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(props.selectedRows.slice(1));
         } else if (selectedIndex === props.selectedRows.length - 1) {
            newSelected = newSelected.concat(props.selectedRows.slice(0, -1));
         } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
               props.selectedRows.slice(0, selectedIndex),
               props.selectedRows.slice(selectedIndex + 1)
            );
         }
         props.setSelected(newSelected);
      } else {
         props.setSelected([row._id || row.id]);
         props.onClick(event, row);
      }
   };

   const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.checked) {
         const newSelected = props.rows.map((n) => n._id);
         props.setSelected(newSelected);
         return;
      }
      props.setSelected([]);
   };

   const isSelected = (name: string) => (props.selectedRows ? props.selectedRows.indexOf(name) !== -1 : false);

   // Avoid a layout jump when reaching the last page with empty rows.
   // const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filteredRows(props.rows).length) : 0;

   const allRows = props.upRows ? [...props.upRows, ...props.rows] : props.rows;
   return (
      <Box sx={{ width: "100%" }}>
         {props.filters ? (
            <Stack
               direction={"row"}
               spacing={1.5}
               sx={{
                  display: "flex",
                  bgcolor: "white",
                  p: "8px 16px",
                  borderRadius: 1,
                  boxShadow: props.disableBorders ? 0 : 1,
                  alignItems: "center",
                  mb: 1,
               }}
            >
               <Typography>Filtros</Typography>
               {props.filters.map((field, i) => {
                  if (field.type === "select") {
                     return (
                        <TextField
                           key={`${field.id}-filter-${i}`}
                           fullWidth
                           select
                           value={filters[field.id]}
                           onChange={(e) => handleFilters(e, field.id)}
                           label={field.label}
                           size="small"
                        >
                           {field.data.map((element, i) => (
                              <MenuItem key={`${element}-${i}`} value={element}>
                                 {element}
                              </MenuItem>
                           ))}
                           <MenuItem value={field.allOption}>{field.allOption}</MenuItem>
                        </TextField>
                     );
                  }
                  if (field.type === "text") {
                     return (
                        <TextField
                           key={`${field.id}-filter-${i}`}
                           fullWidth
                           value={filters[field.id]}
                           onChange={(e) => handleFilters(e, field.id)}
                           label={field.label}
                           size="small"
                        />
                     );
                  }
                  if (field.type === "number") {
                     return (
                        <TextField
                           key={`${field.id}-filter-${i}`}
                           fullWidth
                           type="number"
                           value={filters[field.id]}
                           onChange={(e) => handleFilters(e, field.id)}
                           label={field.label}
                           size="small"
                        />
                     );
                  }
                  if (field.type === "date") {
                     return (
                        <TextField
                           key={`${field.id}-filter-${i}`}
                           fullWidth
                           type="date"
                           value={filters[field.id]}
                           onChange={(e) => handleFilters(e, field.id)}
                           label={filters[field.id] === "" ? "" : field.label}
                           size="small"
                        />
                     );
                  }
                  return <></>;
               })}

               <IconButton
                  onClick={() => setFilters(props.filtersInitialState)}
                  size="small"
                  disabled={filters === props.filtersInitialState}
               >
                  <Tooltip title="Restablecer filtros">
                     <Refresh />
                  </Tooltip>
               </IconButton>
            </Stack>
         ) : (
            <></>
         )}
         <TableContainer
            component={Paper}
            sx={{ boxShadow: 0, border: props.disableBorders ? 0 : 1, borderColor: "#D8D8D8" }}
         >
            <Table sx={{ minWidth: 650 }} aria-labelledby="tableTitle" size={props.dense ? "small" : "medium"}>
               {!props.disableHeader && (
                  <EnhancedTableHead
                     order={order}
                     orderBy={orderBy}
                     onRequestSort={handleRequestSort}
                     headers={props.headers}
                     headerColor={props.headerColor}
                     rowCount={props.rows.length}
                     onSelectAllClick={handleSelectAllClick}
                     numSelected={props.selectedRows ? props.selectedRows.length : 0}
                  />
               )}
               <TableBody>
                  {props.loader ? (
                     <TableRow>
                        <TableCell align="center" colSpan={props.headers ? props.headers.length : 0}>
                           <CircularProgress size={24} />
                        </TableCell>
                     </TableRow>
                  ) : props.rows.length > 0 ? (
                     <>
                        {stableSort(
                           filteredRows(allRows).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage), //cambiar aqui para ver como agregar las filas que no se deben editar
                           getComparator(order, orderBy)
                        ).map((row: any, index) => {
                           return (
                              <React.Fragment>
                                 <CreateRow
                                    rowInside={row}
                                    index={index}
                                    props={props}
                                    isSelected={isSelected}
                                    isOn={isOn}
                                    handleClick={handleClick}
                                    setIsHovered={setIsHovered}
                                    isHovered={isHovered}
                                    handlePopoverOpen={handlePopoverOpen}
                                    handlePopoverClose={handlePopoverClose}
                                    anchorEl={anchorEl}
                                    setIsOn={setIsOn}
                                    collapse={false}
                                 />
                                 {props.collapsible ? (
                                    <TableRow sx={{ bgcolor: "#F5F5F5" }}>
                                       <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
                                          <Collapse in={props.opened === row.id} timeout="auto" unmountOnExit>
                                             <Box sx={{ margin: 2, bgcolor: "#F5F5F5" }}>
                                                <Grid container spacing={1}>
                                                   <Grid item xs={4}>
                                                      <Typography sx={{ ml: 2, color: "#64748B" }}>Módulos</Typography>
                                                   </Grid>
                                                   <Grid
                                                      item
                                                      xs={4}
                                                      sx={{
                                                         color: "#64748B",
                                                      }}
                                                   >
                                                      <Typography align="center">Roles asignados</Typography>
                                                   </Grid>
                                                   <Grid item xs={4}>
                                                      <Typography align="right" sx={{ mr: 2, color: "#64748B" }}>
                                                         Modificar
                                                      </Typography>
                                                   </Grid>
                                                   {row.modules.map((data) => (
                                                      <Grid item xs={12}>
                                                         <Box sx={{ bgcolor: "white", p: 1, borderRadius: 5 }}>
                                                            <Grid
                                                               container
                                                               spacing={2}
                                                               sx={{
                                                                  display: "flex",
                                                                  alignItems: "center",
                                                               }}
                                                            >
                                                               {Object.keys(data).map((key) => {
                                                                  if (key === "company") return null;
                                                                  return (
                                                                     <Grid item xs={4}>
                                                                        {typeof data[key] === "string" ? (
                                                                           <Box
                                                                              sx={{
                                                                                 display: "flex",
                                                                                 alignItems: "center",
                                                                                 minHeight: "100%",
                                                                                 ml: 2,
                                                                              }}
                                                                           >
                                                                              <Typography>{data[key]}</Typography>
                                                                           </Box>
                                                                        ) : key === "company" ? null : typeof data[
                                                                             key
                                                                          ] === typeof [] ? (
                                                                           data[key].length > 0 ? (
                                                                              <Box
                                                                                 sx={{
                                                                                    display: "flex",
                                                                                    alignItems: "center",
                                                                                    justifyContent: "center",
                                                                                    flexWrap: "wrap",
                                                                                    listStyle: "none",
                                                                                    m: 0,
                                                                                    maxWidth: 600,
                                                                                 }}
                                                                              >
                                                                                 {data[key].map((role) => {
                                                                                    return (
                                                                                       <ListItem key={role._id}>
                                                                                          <Chip
                                                                                             sx={{
                                                                                                bgcolor: "#64748B",
                                                                                                color: "white",
                                                                                             }}
                                                                                             label={
                                                                                                data.module ===
                                                                                                   "Gobierno corporativo" &&
                                                                                                role.name !==
                                                                                                   "Usuario de implementación" &&
                                                                                                role.name !==
                                                                                                   "Coordinador de gobierno corporativo"
                                                                                                   ? role.chargeName
                                                                                                   : role.name
                                                                                             }
                                                                                          />
                                                                                       </ListItem>
                                                                                    );
                                                                                 })}
                                                                              </Box>
                                                                           ) : (
                                                                              <Box
                                                                                 sx={{
                                                                                    display: "flex",
                                                                                    justifyContent: "center",
                                                                                 }}
                                                                              >
                                                                                 <Typography>Ninguno</Typography>
                                                                              </Box>
                                                                           )
                                                                        ) : null}
                                                                     </Grid>
                                                                  );
                                                               })}
                                                               <Grid item xs={4}>
                                                                  <Box
                                                                     sx={{
                                                                        display: "flex",
                                                                        justifyContent: "flex-end",
                                                                        mr: 2,
                                                                     }}
                                                                  >
                                                                     <IconButton
                                                                        aria-label=""
                                                                        onClick={(e) =>
                                                                           props.onClickCollapsible(
                                                                              e,
                                                                              data,
                                                                              row.modules
                                                                           )
                                                                        }
                                                                     >
                                                                        <BorderColorIcon />
                                                                     </IconButton>
                                                                  </Box>
                                                               </Grid>
                                                            </Grid>
                                                         </Box>
                                                      </Grid>
                                                   ))}
                                                   <Grid item xs={12}>
                                                      <Box
                                                         sx={{
                                                            display: "flex",
                                                            bgcolor: "#D9D9D980",
                                                            p: 2,
                                                            borderRadius: 5,
                                                            "&:hover": {
                                                               bgcolor: "white",
                                                            },
                                                            cursor: "pointer",
                                                         }}
                                                         onClick={() => {
                                                            setSupportOpen(!supportOpen);
                                                            setUpdateData(true);
                                                         }}
                                                      >
                                                         <Typography sx={{ mx: 1 }}>Actualizar módulos</Typography>
                                                         <Add />
                                                      </Box>
                                                   </Grid>
                                                </Grid>
                                                <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
                                                   <Button onClick={(e) => props.onDeleteCollapsible(e, row.modules)}>
                                                      <DeleteForever />
                                                      Eliminar empresa
                                                   </Button>
                                                </Box>
                                             </Box>
                                          </Collapse>
                                       </TableCell>
                                    </TableRow>
                                 ) : null}
                              </React.Fragment>
                           );
                        })}
                     </>
                  ) : (
                     <TableRow>
                        <EmptyDataTableCell text={props.emptyDataText} />
                     </TableRow>
                  )}
               </TableBody>
            </Table>
         </TableContainer>
         <TablePagination
            rowsPerPageOptions={props.rowsPerPageOptions}
            component="div"
            count={filteredRows(allRows).length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
         />
      </Box>
   );
};

function CreateRow({
   rowInside,
   index,
   props,
   isSelected,
   isOn,
   handleClick,
   setIsHovered,
   isHovered,
   handlePopoverOpen,
   handlePopoverClose,
   anchorEl,
   setIsOn,
   collapse,
}) {
   const [open, setOpen] = useState(false);
   const isUpRow = props.upRows?.includes(rowInside);
   const isItemSelected = isSelected(rowInside._id || rowInside.id);
   const labelId = `enhanced-table-checkbox-${index}`;
   return (
      <>
         <TableRow
            role="checkbox" //cambiar
            onClick={(e) =>
               rowInside.collapsible
                  ? setOpen(!open)
                  : isOn
                  ? null
                  : isUpRow
                  ? null
                  : props.icon
                  ? null
                  : props.checkboxSelection
                  ? handleClick(e, rowInside)
                  : props.collapsible
                  ? props.setOpened(rowInside.id === props.opened ? "" : rowInside.id)
                  : props.onClick(e, rowInside)
            }
            aria-checked={isItemSelected}
            key={`vr-${index}`}
            selected={isItemSelected}
            sx={{
               cursor: isUpRow ? "default" : "pointer",
               bgcolor: collapse ? "#f7f7f7" : isUpRow ? "#E4E4E9" : "auto",
               borderLeft: props.showBadges && rowInside.incoming ? 2 : 0,
               borderLeftColor: props.showBadges && rowInside.incoming ? "#162C44" : "",
               "&:hover": {
                  bgcolor: "#E4E4E7",
               },
            }}
         >
            {props.headers.map((header, index) => {
               return (
                  <TableCell
                     id={labelId}
                     align={
                        header.field === "category" || header.field === "body" || header.align === "left"
                           ? "left"
                           : header.type === "collapsible" || header.align === "right"
                           ? "right"
                           : "center"
                     }
                     key={`tc${index}`}
                     // style={{ paddingBottom: 0, paddingTop: 0, padding: 0 }}
                     style={{
                        padding: 10,
                     }}
                     sx={{
                        borderLeft: collapse && index === 0 ? 2 : "",
                        borderLeftColor: "#162c44",
                     }}
                     // padding={props.checkboxSelection ? "checkbox" : "normal"}
                  >
                     {header.type === "popover" ? (
                        <>
                           {`${rowInside[header.field][0]}`}
                           {rowInside[header.field].length - 1 > 0 ? (
                              <IconButton
                                 onMouseEnter={() => setIsHovered(`${rowInside._id}${rowInside[header.field]}`)}
                                 sx={{ p: 0, mx: 1, bgcolor: "#F5F5F5" }}
                              >
                                 <Box
                                    sx={{ borderRadius: 5, px: 1 }}
                                    display={"flex"}
                                    aria-owns={
                                       isHovered === `${rowInside._id}${rowInside[header.field]}`
                                          ? `${rowInside._id}${rowInside[header.field]}}`
                                          : undefined
                                    }
                                    aria-haspopup="true"
                                    onMouseEnter={handlePopoverOpen}
                                    onMouseLeave={handlePopoverClose}
                                 >
                                    <Typography fontWeight={600}>{`+${rowInside[header.field].length - 1}`}</Typography>

                                    <Popover
                                       id={`${rowInside._id}${rowInside[header.field]}`}
                                       sx={{
                                          pointerEvents: "none",
                                          boxShadow: "none",
                                       }}
                                       open={isHovered === `${rowInside._id}${rowInside[header.field]}`}
                                       anchorEl={anchorEl}
                                       anchorOrigin={{
                                          vertical: "bottom",
                                          horizontal: "left",
                                       }}
                                       transformOrigin={{
                                          vertical: "top",
                                          horizontal: "left",
                                       }}
                                       onClose={handlePopoverClose}
                                       disableRestoreFocus
                                    >
                                       <Box p={1}>
                                          {rowInside[header.field].slice(1).map((c) => (
                                             <Typography key={c} fontSize={"14px"}>
                                                {c}
                                             </Typography>
                                          ))}
                                       </Box>
                                    </Popover>
                                 </Box>
                              </IconButton>
                           ) : null}
                        </>
                     ) : (
                        TableComponentContentMap(rowInside, props, {
                           isUpRow,
                           isItemSelected,
                           labelId,
                           header,
                           setIsOn,
                           onClick: handleClick,
                           rowData: rowInside,
                        })
                     )}
                  </TableCell>
               );
            })}
         </TableRow>
         {rowInside.collapsible && (
            <TableRow>
               <TableCell style={{ paddingBottom: 0, paddingTop: 0, padding: 0 }} colSpan={8}>
                  <Collapse in={open} timeout="auto" unmountOnExit sx={{ m: 0, p: 0 }}>
                     <Box>
                        <Table size="small" aria-label="purchases">
                           <EnhancedTableHead
                              order={"asc"}
                              orderBy={"name"}
                              onRequestSort={() => {}}
                              headers={props.headers}
                              headerColor={props.headerColor}
                              disable
                           />
                           <TableBody>
                              {rowInside.collapsible.map((childRow) => (
                                 <CreateRow
                                    key={childRow.name}
                                    rowInside={childRow}
                                    index={index}
                                    props={props}
                                    isSelected={isSelected}
                                    isOn={isOn}
                                    handleClick={handleClick}
                                    setIsHovered={setIsHovered}
                                    isHovered={isHovered}
                                    handlePopoverOpen={handlePopoverOpen}
                                    handlePopoverClose={handlePopoverClose}
                                    anchorEl={anchorEl}
                                    setIsOn={setIsOn}
                                    collapse
                                 />
                              ))}
                           </TableBody>
                        </Table>
                     </Box>
                  </Collapse>
               </TableCell>
            </TableRow>
         )}
      </>
   );
}
