import { createContext, useState } from "react";

interface ISupportContext {
   supportOpen: boolean;
   setSupportOpen: any;
   updateData?: boolean;
   setUpdateData?: any;
}

export const SupportContext = createContext<ISupportContext>({
   supportOpen: false,
   setSupportOpen: undefined,
   updateData: false,
   setUpdateData: undefined,
});

export const SupportDrawerProvider = ({ children }) => {
   const [supportOpen, setSupportOpen] = useState(false);
   const [updateData, setUpdateData] = useState(false);

   return (
      <SupportContext.Provider value={{ supportOpen, setSupportOpen, updateData, setUpdateData }}>
         {children}
      </SupportContext.Provider>
   );
};
