import { Box, Button, Grid, IconButton, List, ListItem, Stack, Typography } from "@mui/material";
import { CorporateDataContext } from "../../../../context/governanceContext/corporateDataContext";
import { Form, Formik } from "formik";
import { Check, Delete } from "@mui/icons-material";
import { useContext, useState } from "react";
import { InputTextField } from "../../../Inputs/InputTextField";
import { seriesSchema } from "../../../../lib/validations/inputSchemas";

export const SeriesManagment = () => {
   const { createSerie, series } = useContext(CorporateDataContext);
   const [seed, setSeed] = useState(0);
   return (
      <Box width={"100%"} px={2} py={1}>
         <Grid container>
            <Grid item xs={4}>
               <Typography fontWeight={600} color={"#162c44"} textAlign={"center"}>
                  Nombres
               </Typography>
            </Grid>
            <Grid item xs={4}>
               <Typography fontWeight={600} color={"#162c44"} textAlign={"center"}>
                  Valor nominal
               </Typography>
            </Grid>
            <Grid item xs={4}>
               <Typography fontWeight={600} color={"#162c44"} textAlign={"center"}>
                  Acciones
               </Typography>
            </Grid>
         </Grid>
         <List sx={{ maxHeight: "310px", overflowY: "auto" }} key={seed}>
            {series.length > 0 && series.map((s, i) => <SeriesObject s={s} i={i} key={s.title} setSeed={setSeed} />)}
         </List>
         <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", mt: 1 }}>
            <Button onClick={() => createSerie()} variant="contained">
               Agregar serie nueva
            </Button>
         </Grid>
      </Box>
   );
};

const SeriesObject = ({ s, i, setSeed }) => {
   const { updateSeries, deleteSerie } = useContext(CorporateDataContext);
   const [editable, setEditable] = useState(false);

   const handleSeries = (values) => {
      const data = { title: values.title, nominalValue: Number(values.nominalValue) };
      updateSeries(data, i);
      setEditable(false);
      setSeed((s) => s + 1);
   };
   return (
      <Formik
         initialValues={{
            title: s.title,
            nominalValue: s.nominalValue,
         }}
         onSubmit={handleSeries}
         validationSchema={seriesSchema}
      >
         <Form>
            <ListItem sx={{ borderTop: 1, borderColor: "#D8D8D8", py: 1 }} onDoubleClick={() => setEditable(true)}>
               <Grid container>
                  <Grid item xs={4} alignContent={"center"}>
                     {editable ? (
                        <InputTextField
                           id="title"
                           name="title"
                           type="text"
                           size={"small"}
                           variant={"standard"}
                           centeredText
                           sx={{ mx: 5 }}
                        />
                     ) : (
                        <Typography fontWeight={600} color={"#162c44"} textAlign={"center"}>
                           {s.title}
                        </Typography>
                     )}
                  </Grid>
                  <Grid item xs={4} alignContent={"center"}>
                     {editable ? (
                        <InputTextField
                           id="nominalValue"
                           name="nominalValue"
                           type="text"
                           size={"small"}
                           variant={"standard"}
                           centeredText
                           sx={{ mx: 5 }}
                        />
                     ) : (
                        <Typography fontWeight={600} color={"#162c44"} textAlign={"center"}>
                           {s.nominalValue}
                        </Typography>
                     )}
                  </Grid>
                  <Grid item xs={4}>
                     <Stack
                        height={"100%"}
                        spacing={5}
                        direction={"row"}
                        display={"flex"}
                        justifyContent={"center"}
                        alignItems={"center"}
                     >
                        {editable ? (
                           <IconButton type="submit">
                              <Check sx={{ color: "#162c44" }} />
                           </IconButton>
                        ) : (
                           <IconButton onClick={() => deleteSerie(i, setSeed)}>
                              <Delete sx={{ color: "#162c44" }} />
                           </IconButton>
                        )}
                     </Stack>
                  </Grid>
               </Grid>
            </ListItem>
         </Form>
      </Formik>
   );
};
