import { Home, Group, Store, AdminPanelSettings } from "@mui/icons-material";
import {
   Avatar,
   Box,
   CircularProgress,
   Divider,
   List,
   ListItem,
   ListItemButton,
   ListItemIcon,
   ListItemText,
   Modal,
   Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { getUrlS3 } from "../../../lib/usersBEClient";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

export const GroupScreen = () => {
   const { user, isImplementationUser } = useContext(UserContext);
   const [openLogo, setOpenLogo] = useState(false);
   const [logo, setLogo] = useState("");
   const location = useLocation();
   const navigate = useNavigate();

   const menu = isImplementationUser()
      ? [
           {
              name: "Servicios",
              icon: <AdminPanelSettings sx={{ color: "#2d4357" }} />,
              route: "servicios",
           },
        ]
      : [
           {
              name: "Inicio",
              icon: <Home sx={{ color: "#2d4357" }} />,
              route: "inicio",
           },
           {
              name: "Usuarios",
              icon: <Group sx={{ color: "#2d4357" }} />,
              route: "usuarios",
           },
           {
              name: "Empresas",
              icon: <Store sx={{ color: "#2d4357" }} />,
              route: "empresas",
           },
           {
              name: "Servicios",
              icon: <AdminPanelSettings sx={{ color: "#2d4357" }} />,
              route: "servicios",
           },
        ];

   const getGroupLogo = async () => {
      const url = await getUrlS3("images-lecosy", { folder: `group/${user.group._id}` }, "logo.png");
      setLogo(url);
      return url;
   };

   useEffect(() => {
      if (user.group) getGroupLogo();
      // eslint-disable-next-line
   }, [user]);

   return (
      <Box minWidth={"100%"} width={"100%"} sx={{ display: "flex", columnGap: 2, mt: 2 }}>
         {user.group ? (
            <>
               <Box sx={{ flex: 1 }}>
                  <Box bgcolor={"white"} borderRadius={1} minWidth={230} boxShadow={2}>
                     <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={3}>
                        <Avatar
                           sx={{
                              width: 100,
                              height: 100,
                              fontSize: 30,
                              ":hover": { cursor: "pointer" },
                              border: 1,
                              borderColor: "#E3E2E2",
                           }}
                           alt={user.group.name.substring(0, 1)}
                           src={logo}
                           onClick={() => {
                              setOpenLogo(true);
                           }}
                           imgProps={{ sx: { objectFit: "contain" } }}
                        />
                        <Modal
                           open={openLogo}
                           onClose={() => {
                              setOpenLogo(false);
                           }}
                           sx={{ display: "flex" }}
                        >
                           <Box sx={{ m: "auto", maxWidth: "60%" }}>
                              <Box component={"img"} width={"100%"} maxHeight={600} src={logo} />
                           </Box>
                        </Modal>
                        <Typography fontWeight={400} mt={0.5}>
                           {user.group.name}
                        </Typography>
                     </Box>
                     <Divider flexItem sx={{ my: 1 }} />
                     <Box>
                        <Typography mx={2} alignSelf="flex-start" fontWeight={500} variant="h6">
                           Menú
                        </Typography>
                        <List>
                           {menu.map((item, i) => (
                              <ListItem key={i} disablePadding sx={{ py: 0.5, px: 1 }}>
                                 <ListItemButton
                                    selected={location.pathname.includes(item.route)}
                                    onClick={() => navigate(item.route)}
                                    sx={{ borderRadius: 3 }}
                                 >
                                    <ListItemIcon>{item.icon}</ListItemIcon>
                                    <ListItemText primary={item.name} />
                                 </ListItemButton>
                              </ListItem>
                           ))}
                        </List>
                     </Box>
                  </Box>
               </Box>
               <Box
                  sx={{
                     flex: 4,
                     display: "flex",
                     flexDirection: "column",
                     rowGap: 2,
                  }}
               >
                  <Outlet />
               </Box>
            </>
         ) : (
            <Box sx={{ display: "flex", justifyContent: "center", width: "100%" }}>
               <CircularProgress />
            </Box>
         )}
      </Box>
   );
};
