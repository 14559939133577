import { Box, Divider, Stack, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { DoughnutChart } from "../../../charts/doughnutChart";
import { useContext, useMemo, useRef } from "react";
import { EvaluationContext } from "../../../../context/governanceContext/evaluationContext";

export const CouncilStepperPercentage = ({ steps }) => {
   const { activeCouncil, responsesByCouncil, total } = useContext(EvaluationContext);
   const chartRef = useRef(null);

   const activeStep = useMemo(() => {
      if (activeCouncil) {
         for (let i = 0; i < steps.length; i++) {
            const element = steps[i];
            if (element._id === activeCouncil._id) {
               return i;
            }
         }
      }
      return 0;
   }, [activeCouncil]);

   return (
      <Stack
         sx={{
            bgcolor: "#F5F5F5",
            flex: 1.5,
            p: 2,
            rowGap: 2,
            borderRight: "1px solid #D9D9D9",
         }}
         alignItems={"center"}
      >
         <Typography fontWeight={600} textAlign={"center"}>
            Desempeño de la estructura de gobierno
         </Typography>
         <Box sx={{ width: "100%" }}>
            <Divider />
         </Box>
         <Box sx={{ width: 150, height: 150 }} key={total(steps)}>
            <DoughnutChart
               scores={[total(steps) - responsesByCouncil.length, responsesByCouncil.length]}
               labels={["Sin completar", "Completado"]}
               colors={["#8a95a0", "#3663C1"]}
               chartRef={chartRef}
               cutout={60}
               removeBorder
               maintainAspectRatio
               borderRadius
            />
         </Box>
         <Box sx={{ maxHeight: 300, overflowY: "auto" }}>
            <Stepper
               activeStep={activeStep}
               alternativeLabel
               sx={{
                  display: "inline",
                  position: "relative",
               }}
               connector={<></>}
            >
               {steps.map((step, index) => (
                  <Step key={step.title} sx={{ py: 1 }}>
                     <StepLabel>{step.title}</StepLabel>
                     {index !== steps.length - 1 && (
                        <Stack direction={"column"} alignItems={"center"}>
                           <Divider sx={{ py: 2, mt: 1, borderLeftWidth: 2 }} orientation="vertical" />
                        </Stack>
                     )}
                  </Step>
               ))}
            </Stepper>
         </Box>
      </Stack>
   );
};
