import { Box, Button, CircularProgress, InputAdornment, Typography } from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { useContext, useMemo, useState } from "react";
import {
   CalendarMonth,
   HourglassBottom,
   HourglassTop,
   LocationOnOutlined,
   PeopleAltOutlined,
} from "@mui/icons-material";
import { TagsSelector } from "../Inputs/TagsSelector";
import { CustomInputStyle } from "../Inputs/InputStyled";
import { UsersSelector } from "../Inputs/UsersSelector";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { IGovernanceBody } from "../../../types/governance.types";
import dayjs from "dayjs";
import { eventCreateSchema } from "../../../lib/validations/GobCop/inputSchemas";
import { InputTextField } from "../../Inputs/InputTextField";
import { useGetUsersByGovernance } from "../../../hooks/gob-corp/SchedulerScreen/index.hooks";
import { CreateEventB, useGetResourceSelected } from "../../../lib/gobCorpBEClient";
import { SnackBarContext } from "../../../context/snackBarContext";
import { UserContext } from "../../../context/userContext";
import { SchedulerHelpers } from "@aldabil/react-scheduler/types";
import { ExternGuests } from "../Inputs/ExternGuests";
import { getDateAndTimeFromISODate, toISOString } from "../../../const/globalConst";

interface ICreateEventProps {
   scheduler?: SchedulerHelpers;
   setOpen?: Function;
   edit?: boolean;
   initialValues?: any;
   closeScheduler?: boolean;
}

const useGetInitialValues = (event) => {
   const [isLoading, setIsLoading] = useState(true);

   const initialValues = useMemo(() => {
      if (event) {
         setIsLoading(true);
         const initialValuesC = {
            type: "Evento",
            title: event.title,
            tags: event.tags,
            date: dayjs(event.date),
            start: dayjs(event.start),
            end: dayjs(event.end),
            location: event.location,
            meetingURL: event.meetingURL,
            users: event.usersRegistry.map((userR) => {
               return { _id: userR.user, ...userR };
            }),
            externs: event.externs,
            extern: "",
            specialty: "",
            usersRegistry: event.usersRegistry,
         };
         setIsLoading(false);

         return initialValuesC;
      } else {
         setIsLoading(false);
      }
   }, []);

   return { initialValues, isLoading };
};

export const CreateEvent = (props: ICreateEventProps) => {
   const { initialValues, isLoading } = useGetInitialValues(props.initialValues);
   const { gobernanceBody, userDetails, setRefetch, refetch, companySelected } = useContext(GovernanceContext);
   const { showSnackBar } = useContext(SnackBarContext);
   const resourceId = useGetResourceSelected();
   const [gobernanceSelected, setGobernanceSelected] = useState<IGovernanceBody>(
      props.edit
         ? {
              _id: props.initialValues.governance,
              title: props.initialValues.governanceTitle,
              users: props.initialValues.governanceUsers,
           }
         : null
   );
   const { loading: loadingUsers, users } = useGetUsersByGovernance(gobernanceSelected ? gobernanceSelected : null);
   const { user } = useContext(UserContext);
   const { mutate: createEvent, isLoading: isLoadingCreateEvent } = CreateEventB(resourceId, companySelected);

   const handleSubmit = async (values, actions) => {
      if (props.edit) {
      } else {
         createEvent(
            {
               date: values.date,
               files: [],
               finishTime: toISOString(values.end.$d),
               startTime: toISOString(values.start.$d),
               location: values.location,
               title: values.title,
               usersNotFormated: values.users,
               ownerNotFormated: {
                  _id: user.id,
                  email: user.email,
                  fistName: user.firstName,
                  lastName: user.lastName,
               },
               tags: values.tags,
               company: companySelected,
               meetingURL: values.meetingURL,
               externs: values.externs.map((extern) => extern.email),
            },
            {
               onSuccess: async () => {
                  showSnackBar("Evento creado", false);
                  if (props.scheduler) {
                     props.scheduler.close();
                  } else {
                     props.setOpen(0);
                  }
                  actions.resetForm();
                  setRefetch(!refetch);
               },
               onError: (error: any) => {
                  showSnackBar("Error al crear event", true);
                  return null;
               },
            }
         );
      }
   };

   return (
      <Box sx={{ maxWidth: 700 }}>
         <Formik
            initialValues={
               props.edit
                  ? initialValues
                  : {
                       type: "Evento",
                       title: "",
                       tags: [],
                       date: null,
                       start: null,
                       end: null,
                       location: "",
                       meetingURL: "",
                       users: [],
                       externs: [],
                       extern: "",
                       specialty: "",
                    }
            }
            onSubmit={handleSubmit}
            validationSchema={eventCreateSchema}
         >
            {(formProps: FormikProps<any>) => {
               return (
                  <Form>
                     <Box sx={{ width: "100%", py: 4, px: 6, maxHeight: 500, overflowY: "scroll" }}>
                        <Box sx={{ display: "flex", flexDirection: "column", rowGap: 2 }}>
                           <InputTextField
                              id="title"
                              name="title"
                              type="text"
                              size="small"
                              fullWidth
                              placeholder="Agregar título"
                              sx={{ height: 40, mb: 2 }}
                              label="Titulo del evento"
                           />
                           <Box sx={{ display: "flex", columnGap: 1 }}>
                              <CustomInputStyle
                                 icon={CalendarMonth}
                                 inputProps={{
                                    id: "date",
                                    name: "date",
                                    fullWidth: true,
                                    unstyled: true,
                                    format: "DD/MM/YYYY",
                                    minDate: dayjs(new Date()),
                                    disablePast: true,
                                    onChange: (e) => {
                                       formProps.setFieldValue("date", dayjs(e.$d));
                                    },
                                 }}
                                 type="date"
                                 label="Fecha"
                              />
                              <CustomInputStyle
                                 icon={HourglassTop}
                                 inputProps={{
                                    id: "start",
                                    name: "start",
                                    fullWidth: true,
                                    unstyled: true,
                                    minDate: formProps.values.date,
                                    disableIgnoringDatePartForTimeValidation: true,
                                    onChange: (e) => {
                                       formProps.setFieldValue("start", dayjs(e.$d));
                                    },
                                 }}
                                 type="time"
                                 label="Hora inicio"
                              />
                              <CustomInputStyle
                                 icon={HourglassBottom}
                                 inputProps={{
                                    id: "end",
                                    name: "end",
                                    type: "time",
                                    fullWidth: true,
                                    unstyled: true,
                                    minDate: formProps.values.date,
                                    disableIgnoringDatePartForTimeValidation: true,
                                    onChange: (e) => {
                                       formProps.setFieldValue("end", dayjs(e.$d));
                                    },
                                 }}
                                 type="time"
                                 label="Hora fin"
                              />
                           </Box>
                           <CustomInputStyle
                              iconDesign={<LocationOnOutlined sx={{ color: "gray" }} />}
                              inputProps={{
                                 id: "location",
                                 name: "location",
                                 type: "text",
                                 fullWidth: true,
                                 sx: {
                                    height: 40,
                                 },
                                 size: "small",
                                 placeholder: "Sala de reuniones A",
                                 InputProps: {
                                    startAdornment: (
                                       <InputAdornment position="start">
                                          <LocationOnOutlined />
                                       </InputAdornment>
                                    ),
                                 },
                              }}
                              unstyled
                              label="Lugar"
                           />
                           <UsersSelector
                              icon={<PeopleAltOutlined sx={{ color: "gray" }} />}
                              inputProps={{
                                 id: "users",
                                 name: "users",
                              }}
                              label={"Invitados"}
                              options={gobernanceBody.filter(
                                 (governance) =>
                                    governance.title !== "PANEL DE USUARIO" &&
                                    governance.title.toLocaleLowerCase() !== "asamblea de accionistas"
                              )}
                              gobernanceSelected={gobernanceSelected}
                              setGobernanceSelected={setGobernanceSelected}
                              users={users}
                              selectedUsers={formProps.values.users}
                              setUsersSelected={(user) => {
                                 let selectedUsers = formProps.values.users;
                                 if (selectedUsers.some((userSelected) => userSelected._id === user._id)) {
                                    const newUsers = selectedUsers.filter(
                                       (userSelected) => userSelected._id !== user._id
                                    );
                                    formProps.setFieldValue("users", newUsers);
                                    return;
                                 } else {
                                    const newUsers = [...selectedUsers, user];
                                    formProps.setFieldValue("users", newUsers);
                                    return;
                                 }
                              }}
                              selectAllUsers={(users) => {
                                 formProps.setFieldValue("users", users);
                              }}
                              deSelectAllUsers={() => formProps.setFieldValue("users", [])}
                              loadingUsers={loadingUsers}
                           />
                           <ExternGuests />

                           <Typography variant="body2" fontWeight={600}>
                              Asignar etiqueta
                           </Typography>
                           <TagsSelector
                              tags={userDetails.tags}
                              valuesTags={formProps.values.tags}
                              setFieldValue={(e) => {
                                 const newValues = formProps.values.tags;
                                 if (newValues.filter((tag) => tag._id === e._id).length > 0) return;
                                 newValues.push(e);
                                 formProps.setFieldValue("tags", newValues);
                              }}
                              deleteValue={(e) => {
                                 const newValues = formProps.values.tags.filter((tag) => tag._id !== e);
                                 formProps.setFieldValue("tags", newValues);
                              }}
                           />

                           <Typography variant="body2" fontWeight={600}>
                              Agregar acceso a videoconferencia
                           </Typography>
                           <InputTextField
                              type="string"
                              unstyled
                              fullWidth
                              id="meetingURL"
                              name="meetingURL"
                              placeholder="Acceso URL"
                              gray
                           />
                        </Box>
                     </Box>
                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "flex-end",
                           bgcolor: "#F3F3F3",
                           p: 2,
                           borderBottomRightRadius: 5,
                           borderBottomLeftRadius: 5,
                        }}
                     >
                        <Button
                           sx={{ color: "grey" }}
                           onClick={() => (props.scheduler ? props.scheduler.close() : props.setOpen(0))}
                        >
                           Cancelar
                        </Button>
                        <Button type="submit" sx={{ height: 40 }}>
                           {isLoadingCreateEvent ? <CircularProgress size={30} /> : "Guardar"}
                        </Button>
                     </Box>
                  </Form>
               );
            }}
         </Formik>
      </Box>
   );
};
