import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getUsersByIds } from "../../lib/usersBEClient";
import { Box, Typography } from "@mui/material";
import { MyFiles } from "../../components/Gobierno corporativo/MyFilesScreen/MyFiles";
import { Filters } from "../../components/Gobierno corporativo/MyFilesScreen/SubComponents/Filters";
import { AudioFile, Folder, InsertDriveFile, InsertPhoto, Movie, PictureAsPdf, Link } from "@mui/icons-material";
import { getDateAndTimeFromISODate } from "../../const/globalConst";
import { FilesContext } from "../../context/governanceContext/filesContext";
import dayjs from "dayjs";
import { getAllPersonalFiles } from "../../lib/gobCorpBEClient";
import { GovernanceContext } from "../../context/governanceContext/governanceContext";

export const getIconByType = (type: string) => {
   switch (type) {
      case "application/pdf":
         return <PictureAsPdf sx={{ color: "red" }} />;
      case "image/png":
         return <InsertPhoto />;
      case "image/jpeg":
         return <InsertPhoto />;
      case "audio/ogg":
         return <AudioFile />;
      case "audio/mpeg":
         return <AudioFile />;
      case "video/mp4":
         return <Movie />;
      case "application/url":
         return <Link />;
      default:
         return <InsertDriveFile sx={{ color: "black", fontSize: 25 }} />;
   }
};

const useGetFiles = () => {
   const { id } = useParams();
   const [isLoading, setIsLoading] = useState(true);
   const [folders, setFolders] = useState([]);
   const [users, setUsers] = useState([]);
   const [reload, setReload] = useState(null);
   const { startDate, endDate, confirmModal } = useContext(FilesContext);
   const { companySelected } = useContext(GovernanceContext);

   useEffect(() => {
      const fetchFolders = async () => {
         try {
            setIsLoading(true);
            if (!companySelected) {
               console.error("companySelected is undefined");
               return;
            }
            const foldersData = await getAllPersonalFiles(companySelected);
            if (!foldersData) {
               console.error("foldersData is undefined");
               return;
            }
            const usersIds = foldersData.map((files) => files.user);
            const usersNames = await getUsersByIds(usersIds);
            setUsers(usersNames);
            setFolders(foldersData);
         } catch (error) {
            console.error("Error fetching folders:", error);
         } finally {
            setIsLoading(false);
         }
      };
      fetchFolders();
   }, [id, reload, companySelected]);

   const filteredData = useMemo(() => {
      const data = [];
      for (const iterator of folders) {
         const user = users.find((user) => user._id === iterator.user);
         if (!user) continue;
         data.push({
            toVerified: iterator._id,
            type: "carpeta",
            name: (
               <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                  <Folder sx={{ color: "#F1B44C", fontSize: 25 }} />
                  <Typography variant="subtitle2">
                     {user.firstName} {user.lastName}
                  </Typography>
               </Box>
            ),
            updatedAt: getDateAndTimeFromISODate(iterator.updatedAt),
            owner: `${user.firstName} ${user.lastName}`,
            ownerId: iterator.user,
            size: "---",
            originalName: user.firstName + " " + user.lastName,
         });
      }
      return data;
   }, [folders, users]);

   const processedData = useMemo(() => {
      if (confirmModal && startDate !== null && endDate !== null) {
         const filtered = filteredData.filter(
            (file) => dayjs(new Date(file.createdAt)) >= startDate && dayjs(new Date(file.createdAt)) <= endDate
         );
         return filtered;
      }
      return filteredData;
   }, [filteredData, confirmModal, startDate, endDate]);

   return { isLoading, processedData, setReload, reload };
};

export const FolderVerificationScreen = () => {
   const { isLoading, processedData, setReload, reload } = useGetFiles();
   return (
      <>
         <Filters />
         <MyFiles files={processedData} isLoading={isLoading} setReload={setReload} reload={reload} />
      </>
   );
};
