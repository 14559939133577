import { Box, Divider, Typography } from "@mui/material";
import React, { useContext } from "react";
import Card from "@mui/material/Card";
import InsightsComponent from "./InsightsComponent";
import CarouselComponent from "../../../CarouselComponent";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";

export const GuidesRender = ({ isUserPanel }) => {
   const { savedInsights, guides } = useContext(GovernanceContext);

   const manual = {
      title: "Manual de gobierno corporativo",
      date: "",
      image: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/manualGobiernoCorporativo.png",
      link: "https://images-lecosy.s3.us-west-2.amazonaws.com/manuals/Manual+de+usuario+de+Gobierno+Corporativo.pdf",
      category: "Guías y herramientas",
   };

   return (
      <Card
         sx={{
            gridArea: isUserPanel ? "insights2" : "footer2",
            borderRadius: 2,
            py: 2,
         }}
      >
         <Box sx={{ px: 3 }}>
            <Typography color={"#162c44"} fontSize={20} fontWeight={700}>
               Recomendaciones clave
            </Typography>
            <Divider sx={{ my: 1 }} />
         </Box>
         <CarouselComponent maxElements={isUserPanel ? 1 : 2} rows={isUserPanel ? 2 : 1}>
            <InsightsComponent insight={manual} disableFavorite />
            {guides.length > 0 &&
               savedInsights &&
               guides.map((insight, index) => (
                  <InsightsComponent
                     insight={insight}
                     key={index}
                     protectedGuide
                     saved={savedInsights?.findIndex((s) => s.fileDirection === insight.link) >= 0 ? true : false}
                  />
               ))}
         </CarouselComponent>
      </Card>
   );
};
