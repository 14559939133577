import { SnackBarComponent } from "../SnackBarComponent";
import { SupportDrawer } from "../Drawers/SupportDrawer";
import { ComplaintStepperProvider } from "../../context/complaintStepperContext";
import MiniDrawer from "../../screens/DrawerAppScreenNew";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { useCallback, useContext, useEffect, useState } from "react";
import { Box, CircularProgress, Typography } from "@mui/material";
import { GetUserByUid } from "../../lib/usersBEClient";
import { UserContext } from "../../context/userContext";

export const ProtectedRoute = () => {
   const [isLoading, setIsLoading] = useState(false);
   const location = useLocation();
   const navigate = useNavigate();
   const { setUser, loggedIn, isAuthenticated, setIsAuthenticated, pendingCharges, getPendingCharges, skipCharges } =
      useContext(UserContext);

   const checkAuthentication = useCallback(async () => {
      if (isLoading) return;
      setIsLoading(true);
      try {
         await GetUserByUid()
            // .then(async ({ data }) => {
            //    if (!data) {
            //       setIsAuthenticated(false);
            //       navigate("/login");
            //    } else if (!data.validPwd) {
            //       navigate("/validar-contrasena");
            //    } else {
            //       const pendingCharges = await getPendingCharges(data.id);
            //       setUser({ auth: true, ...data });
            //       setIsAuthenticated(true);
            //       if (pendingCharges.length > 0) {
            //          navigate("/charges");
            //       } else {
            //          if (location.pathname.includes("charges")) {
            //             navigate("/inicio");
            //          }
            //          if (location.pathname.includes("login")) {
            //             navigate("/inicio");
            //          }
            //       }
            //    }
            // })
            .then(({ data }) => {
               if (!data) {
                  setIsAuthenticated(false);
                  navigate("/login");
               } else if (!data.validPwd) {
                  navigate("/validar-contrasena");
               } else {
                  setUser({ auth: true, ...data });
                  setIsAuthenticated(true);
                  if (location.pathname.includes("login") || location.pathname === "/") {
                     navigate("/inicio");
                  }
               }
            })
            .catch((err) => {
               setIsAuthenticated(false);
               navigate("/login");
            });
      } catch (err) {
         setIsAuthenticated(false);
         navigate("/login");
      }
      setIsLoading(false);
   }, [loggedIn]);

   useEffect(() => {
      checkAuthentication();
   }, [checkAuthentication]);

   // useEffect(() => {
   // if (pendingCharges.length === 0) {
   //    navigate("/inicio");
   // }
   // }, [pendingCharges]);

   if (isLoading) {
      return (
         <Box
            sx={{
               height: "100vh",
               width: "100vw",
               display: "flex",
               justifyContent: "center",
               alignItems: "center",
               flexDirection: "column",
            }}
         >
            <Typography>Cargando, espere un momento...</Typography>
            <CircularProgress sx={{ color: "#162c44" }} />
         </Box>
      );
   }
   if ((isAuthenticated && pendingCharges.length === 0) || (isAuthenticated && skipCharges)) {
      return (
         <>
            <ComplaintStepperProvider>
               <MiniDrawer user={null} />
               <SnackBarComponent />
               <SupportDrawer toolbar={true} />
            </ComplaintStepperProvider>
         </>
      );
   } else {
      return <Outlet />;
   }
};
