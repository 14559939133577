import { Box, Button, Chip, FormHelperText, IconButton, Tooltip, Typography } from "@mui/material";
import { InputTextField } from "../../Inputs/InputTextField";
import { Add, Delete } from "@mui/icons-material";
import { useFormikContext } from "formik";

export const ExternGuests = () => {
   const { setFieldValue, values: formikValues, errors, setFieldError } = useFormikContext();

   return (
      <>
         <Typography variant="body2" fontWeight={600}>
            Invitados externos
         </Typography>
         <Box
            sx={{
               display: "flex",
               justifyContent: "space-between",
               alignItems: "center",
               columnGap: 2,
            }}
         >
            <Box sx={{ flex: 2 }}>
               <InputTextField
                  type="string"
                  fullWidth
                  id="extern"
                  name="extern"
                  placeholder="correo@correo.com.mx"
                  size="small"
               />
            </Box>
            <Box sx={{ flex: 1 }}>
               {/* <Typography variant="body2" fontWeight={600} sx={{ my: 4 }}>
                  Area de especialidad
               </Typography> */}
               <InputTextField
                  type="string"
                  fullWidth
                  id="specialty"
                  name="specialty"
                  placeholder="Área de especialidad"
                  size="small"
               />
            </Box>
            <Box>
               <Button
                  variant="outlined"
                  onClick={() => {
                     const validate = formikValues["externs"].some(
                        (value) =>
                           value.email === formikValues["extern"] || value.specialty === formikValues["specialty"]
                     );
                     if (validate) {
                        setFieldError("externs", "El correo/celular ya se encuentra agregado");
                        return;
                     }
                     if (formikValues["extern"] === "" || formikValues["specialty"] === "") {
                        return;
                     }
                     const valuesFormated = {
                        email: formikValues["extern"],
                        specialty: formikValues["specialty"],
                     };
                     const values = [...formikValues["externs"], valuesFormated];
                     setFieldValue("externs", values, true);

                     setFieldValue("specialty", "");
                     setFieldValue("extern", "");
                  }}
               >
                  <Typography>Agregar</Typography>
                  <Add />
               </Button>
            </Box>
         </Box>
         <Box sx={{ display: "flex", alignItems: "center", columnGap: 1, flexWrap: "wrap", rowGap: 1 }}>
            {formikValues["externs"] &&
               formikValues["externs"].map((ext, i) => {
                  if (i < 3) {
                     return (
                        <Tooltip title={ext.email} key={i}>
                           <Chip
                              label={
                                 <>
                                    {ext.email.slice(0, 15) + "..."}
                                    <IconButton
                                       onClick={() => {
                                          const values = formikValues["externs"].filter(
                                             (value) => value.email !== ext.email
                                          );

                                          setFieldValue("externs", values);
                                       }}
                                    >
                                       <Delete />
                                    </IconButton>
                                 </>
                              }
                           />
                        </Tooltip>
                     );
                  } else if (i == 3) {
                     return (
                        <Tooltip
                           title={
                              <Box>
                                 {formikValues["externs"].map((ext, i) => {
                                    if (i >= 3) return <Typography>{ext.email}</Typography>;
                                 })}
                              </Box>
                           }
                           key={i}
                        >
                           <Chip label={<Typography>{+formikValues["externs"].length - i}</Typography>} />
                        </Tooltip>
                     );
                  }
               })}
            <FormHelperText error={errors["externs"] ? true : false}>
               <Typography variant="caption">{errors["externs"] ? "Correo/area incorrecto" : ""}</Typography>
            </FormHelperText>
         </Box>
      </>
   );
};
