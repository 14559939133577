import { Box, Button, Stack, Typography } from "@mui/material";
import { Form, Formik, useFormikContext } from "formik";
import { InputRadioButton } from "../../Inputs/InputRadioButton";
import { InputCheckBox } from "../../Inputs/InputCheckBox";
import { useContext, useState } from "react";
import { UpdateVotes } from "../../../lib/gobCorpBEClient";
import { GovernanceSessionContext } from "../../../context/governanceContext/governanceSessionContext";

export const VoteModal = ({ vote, user, setOpenModalCreateModal }) => {
   const { socket, session, additionalVotes, setAdditionalVotesSeed, setSessionSeed, valuesFromBill, affairsArray } =
      useContext(GovernanceSessionContext);
   const [nullVote, setNullVote] = useState(false);
   const [handleError, setHandleError] = useState(false);
   const { mutate } = UpdateVotes();

   const handleVotation = (voteValue, userId) => {
      const tempBill = valuesFromBill;
      tempBill[`vote_user_${userId}_affair_${vote.orderId}`] = voteValue === "Si";
      setSessionSeed((s) => s + 1);
      socket.emit("refresh-affair-votes", { session: session, order: tempBill });
      setOpenModalCreateModal(false);
   };
   const handleAbstentionVote = (userId) => {
      const tempBill = valuesFromBill;
      tempBill[`abstentionVote_user_${userId}_affair_${vote.orderId}`] = true;
      setSessionSeed((s) => s + 1);
      socket.emit("refresh-affair-votes", { session: session, order: tempBill });
      setOpenModalCreateModal(false);
   };

   function addVoteToState(values, userVote) {
      const tempVotes = additionalVotes;
      const foundIndex = tempVotes.findIndex((vindex: any) => vindex._id === values._id);
      if (foundIndex === -1) tempVotes.push(values);
      else tempVotes[foundIndex] = values;
      setAdditionalVotesSeed((s) => s + 1);
      if (values.affair) {
         const foundAffair = affairsArray.find((affair) => affair.vote === vote._id);
         const tempBill = valuesFromBill;
         if (userVote.voteData.agreed || userVote.voteData.disagreed)
            tempBill[`vote_user_${user.id}_affair_${foundAffair.orderId}`] = userVote.voteData.hasOwnProperty("agreed");
         else if (userVote.voteData.nullVote) {
            tempBill[`abstentionVote_user_${user.id}_affair_${foundAffair.orderId}`] = true;
         }
         socket.emit("refresh-affair-votes", { session: session, additionalVotes: tempVotes, order: tempBill });
      } else
         socket.emit("refresh-additional-votes", {
            sessionId: session._id,
            additionalVotes: tempVotes,
         });
   }
   const handleSubmit = (values) => {
      if (vote.orderId) {
         if (values["nullVote"] === false && values["vote"] === "") {
            setHandleError(true);
            return;
         }
         if (values["vote"] === "Si" || values["vote"] === "No") return handleVotation(values["vote"], user.id);
         else if (values["nullVote"]) return handleAbstentionVote(user.id);
      }
      let userVote = {};
      if (values["nullVote"]) {
         userVote = {
            votationId: vote._id,
            voteData: {
               _id: vote._id,
               nullVote: 1,
               votes: [{ userId: user.id, userName: user.firstName + " " + user.lastName, vote: "nullVote" }],
            },
         };
      } else if (values["vote"] === "Si") {
         userVote = {
            votationId: vote._id,
            voteData: {
               agreed: 1,
               votes: [{ userId: user.id, userName: user.firstName + " " + user.lastName, vote: "agreed" }],
            },
         };
      } else if (values["vote"] === "No") {
         userVote = {
            votationId: vote._id,
            voteData: {
               disagreed: 1,
               votes: [{ userId: user.id, userName: user.firstName + " " + user.lastName, vote: "disagreed" }],
            },
         };
      }
      if (Object.keys(userVote).length === 0) return;
      mutate(userVote, {
         onError: (error: any) => {
            console.error(error);
         },
         onSuccess: (data) => {
            addVoteToState(data, userVote);
            setOpenModalCreateModal(false);
         },
      });
   };
   const FormObserver: React.FC = (e) => {
      const { values } = useFormikContext();
      if (values["nullVote"] === true) {
         setNullVote(true);
      } else {
         setNullVote(false);
      }

      return null;
   };
   if (!vote) return;
   return (
      <Formik initialValues={{ vote: "", nullVote: false }} onSubmit={handleSubmit}>
         <Form>
            <FormObserver />
            <Box
               sx={{
                  py: 1,
                  bgcolor: "#152c44",
                  borderTopLeftRadius: 15,
                  borderTopRightRadius: 15,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
               }}
            >
               <Typography sx={{ color: "white", ml: 4, fontWeight: 600 }}>Votación</Typography>
            </Box>
            <Stack
               spacing={3}
               sx={{
                  bgcolor: "background.paper",
                  py: 3,
                  px: 6,
                  borderBottomLeftRadius: 15,
                  borderBottomRightRadius: 15,
               }}
            >
               <Typography sx={{ fontWeight: 600, color: "#64748B", fontSize: 16 }}>{vote.title}</Typography>
               <Stack spacing={1}>
                  <Typography sx={{ fontSize: 14 }}>Seleccione la respuesta:</Typography>

                  <InputRadioButton
                     id={"vote"}
                     name={"vote"}
                     row={false}
                     label1="De acuerdo"
                     label2="En desacuerdo"
                     sx={{ disabled: nullVote }}
                  />
                  <InputCheckBox
                     id={"nullVote"}
                     label={"Abstenerse del voto"}
                     name={"nullVote"}
                     sx={{ fontSize: 8 }}
                     size="small"
                  />
               </Stack>
               <Box>
                  {handleError && (
                     <Typography sx={{ fontSize: 12, color: "red", textAlign: "center" }}>
                        Por favor seleccione una opción
                     </Typography>
                  )}
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                     <Button
                        variant="contained"
                        type="submit"
                        sx={{ bgcolor: "#2D4357", color: "white", width: "70%" }}
                     >
                        Enviar respuesta
                     </Button>
                  </Box>
               </Box>
            </Stack>
         </Form>
      </Formik>
   );
};
