import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useContext, useState } from "react";
import { Button, Grid, MenuItem } from "@mui/material";
import { InputTextField } from "../../../Inputs/InputTextField";
import { Form, Formik, FormikProps } from "formik";
import { InputSelectField } from "../../../Inputs/InputSelectField";
import DeleteIcon from "@mui/icons-material/Delete";
import { ProfessionalDataSchema } from "../../../../lib/validations/inputSchemas";
import {
   deleteProfesionalDataRecord,
   modifyRecord,
   updateRecordsProfesionalDataById,
} from "../../../../lib/gobCorpBEClient";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { CreateProfileContext } from "../../../../context/beneficiaryContext/createProfileContext";
import { CustomModalComponent } from "../../../CustomModalComponent";

interface ModalAddStudyComponentProps {
   open: boolean;
   setOpen: Function;
   degree: string;
   edit?: boolean;
   data: any;
   experienceRecord?: boolean;
   profDataId: string;
   setSeed: Function;
   isNewUser?: boolean;
}

export const ModalAddStudyComponent = (props: ModalAddStudyComponentProps) => {
   const { showSnackBar } = useContext(SnackBarContext);
   const [isLoading, setIsLoading] = useState<boolean>(false);

   const {
      laboralExperience,
      setLaboralExperience,
      professionalStudies,
      setProfessionalStudies,
      postgraduate,
      setPostgraduate,
      certificates,
      setCertificates,
   } = useContext(CreateProfileContext);
   const itemToModify = props.data;
   const handleSubmit = async (value) => {
      setIsLoading(true);
      let data = {};
      try {
         if (props.isNewUser) {
            if (props.experienceRecord)
               data = {
                  title: value.title,
                  institution: value.institution,
                  startTime: value.startTime,
                  endTime: value.endTime,
               };
            else
               data = {
                  title: value.title,
                  institution: value.institution,
                  startTime: value.startTime,
                  endTime: value.endTime,
                  degree: value.degree,
               };
         } else {
            if (props.experienceRecord) {
               data = {
                  experienceRecord: [
                     {
                        title: value.title,
                        institution: value.institution,
                        startTime: value.startTime,
                        endTime: value.endTime,
                     },
                  ],
               };
            } else {
               data = {
                  studyRecord: [
                     {
                        title: value.title,
                        institution: value.institution,
                        startTime: value.startTime,
                        endTime: value.endTime,
                        degree: value.degree,
                     },
                  ],
               };
            }
         }
         if (props.edit) {
            if (props.isNewUser) {
               if (props.experienceRecord) {
                  const laboralExperienceModify = putNewItemInArray(laboralExperience, data);
                  setLaboralExperience(laboralExperienceModify);
               } else {
                  switch (props.degree) {
                     case "Estudios profesionales":
                        const professionalStudiesModify = putNewItemInArray(professionalStudies, data);
                        setProfessionalStudies(professionalStudiesModify);
                        break;
                     case "Posgrado":
                        const postgraduateModify = putNewItemInArray(postgraduate, data);
                        setPostgraduate(postgraduateModify);
                        break;
                     case "Diplomados, cursos y certificaciones":
                        const certificatesModify = putNewItemInArray(certificates, data);
                        setCertificates(certificatesModify);
                        break;
                  }
               }
            } else {
               if (!props.isNewUser) await modifyRecord(props.profDataId, data, props.data._id);
            }
         } else {
            if (props.isNewUser) {
               if (props.experienceRecord) {
                  setLaboralExperience([...laboralExperience, data]);
               } else {
                  switch (props.degree) {
                     case "Estudios profesionales":
                        setProfessionalStudies((s) => [...s, data]);
                        break;
                     case "Posgrado":
                        setPostgraduate((s) => [...s, data]);
                        break;
                     case "Diplomados, cursos y certificaciones":
                        setCertificates((s) => [...s, data]);
                        break;
                  }
               }
            } else {
               if (!props.isNewUser) await updateRecordsProfesionalDataById(props.profDataId, data);
            }
         }
         showSnackBar("Dato modificado con éxito", false);
         props.setSeed(Math.random());
         setTimeout(() => {
            setIsLoading(false);
            props.setOpen(false);
         }, 1500);
      } catch (error) {
         console.log(error);
         setIsLoading(false);
         showSnackBar("Error al modificar el dato", true);
      }
   };

   const putNewItemInArray = (array, data) => {
      return array.map((se) => {
         if (se === itemToModify) return data;
         else return se;
      });
   };

   const handleDelete = async () => {
      setIsLoading(true);
      try {
         if (!props.isNewUser) await deleteProfesionalDataRecord(props.profDataId, props.data._id);
         showSnackBar("Dato eliminado con éxito", false);
         props.setSeed(Math.random());
         setTimeout(() => {
            setIsLoading(false);
            props.setOpen(false);
         }, 1500);
      } catch (error) {
         console.log(error);
         setIsLoading(false);
         showSnackBar("Error al eliminar el dato", true);
      }
   };
   if (!props.degree && !props.data) return;
   return !props.edit ? (
      <CustomModalComponent
         title={`Agregar nuevo ${!props.experienceRecord ? props.degree.toLowerCase() : ""}`}
         open={props.open}
         setOpen={props.setOpen}
      >
         <Formik
            initialValues={{
               degree: props.degree,
               title: "",
               institution: "",
               startTime: "",
               endTime: "",
            }}
            onSubmit={handleSubmit}
            validationSchema={ProfessionalDataSchema}
         >
            {(formProps: FormikProps<any>) => (
               <Form>
                  <Box sx={{ display: "flex", justifyContent: "center" }}>
                     <Grid container sx={{ p: 3, maxWidth: 600 }} rowGap={4}>
                        <Grid container>
                           <Typography>Nombre de {props.degree}</Typography>
                           <Grid item xs={12}>
                              <InputTextField id="title" name="title" type="text" fullWidth size="small" />
                           </Grid>
                        </Grid>
                        <Grid container>
                           <Typography>Institución</Typography>
                           <Grid item xs={12}>
                              <InputTextField id="institution" name="institution" type="text" fullWidth size="small" />
                           </Grid>
                        </Grid>
                        <Grid container justifyContent={"flex-start"} alignItems={"center"} rowGap={2}>
                           <Grid item xs={7}>
                              <Typography>Fecha de inicio:</Typography>
                           </Grid>
                           <Grid item xs={5}>
                              <InputTextField id="startTime" name="startTime" type="date" fullWidth size="small" />
                           </Grid>
                           <Grid item xs={7}>
                              <Typography>Fecha de terminación:</Typography>
                           </Grid>
                           <Grid item xs={5}>
                              <InputTextField id="endTime" name="endTime" type="date" fullWidth size="small" />
                           </Grid>
                        </Grid>
                        {props.experienceRecord ? (
                           <></>
                        ) : (
                           <Grid container>
                              <Grid item xs={7}>
                                 <Typography>Tipo de estudio:</Typography>
                              </Grid>
                              <Grid item xs={5}>
                                 <InputSelectField
                                    id="degree"
                                    labelId="degree"
                                    name="degree"
                                    fullWidth
                                    label=""
                                    size="small"
                                    disabled={true}
                                 >
                                    <MenuItem key={props.degree} value={props.degree}>
                                       {props.degree}
                                    </MenuItem>
                                 </InputSelectField>
                              </Grid>
                           </Grid>
                        )}
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "space-between" }}>
                           <Button variant="outlined" disabled={isLoading} onClick={() => props.setOpen(false)}>
                              Cancelar
                           </Button>
                           <Button
                              variant="contained"
                              type="submit"
                              disabled={isLoading}
                              onClick={() => formProps.submitForm()}
                           >
                              Guardar información
                           </Button>
                        </Grid>
                     </Grid>
                  </Box>
               </Form>
            )}
         </Formik>
      </CustomModalComponent>
   ) : (
      <CustomModalComponent
         title={`Modificar información ${!props.experienceRecord ? props.data.degree.toLowerCase() : ""}`}
         open={props.open}
         setOpen={props.setOpen}
      >
         <Formik
            initialValues={{
               degree: !props.experienceRecord ? props.data.degree : "",
               title: props.data.title,
               institution: props.data.institution,
               startTime: props.data.startTime.toString().split("T")[0],
               endTime: props.data.endTime.toString().split("T")[0],
            }}
            onSubmit={handleSubmit}
            validationSchema={ProfessionalDataSchema}
         >
            {(formProps: FormikProps<any>) => (
               <Form>
                  <Box sx={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
                     <Grid container sx={{ p: 3, maxWidth: 600 }} rowGap={4}>
                        <Grid container>
                           <Typography>
                              Nombre de{" "}
                              {!props.experienceRecord ? props.data.degree.toLowerCase() : "experiencia profesional"}
                           </Typography>
                           <Grid item xs={12}>
                              <InputTextField id="title" name="title" type="text" fullWidth size="small" />
                           </Grid>
                        </Grid>
                        <Grid container>
                           <Typography>Institución</Typography>
                           <Grid item xs={12}>
                              <InputTextField id="institution" name="institution" type="text" fullWidth size="small" />
                           </Grid>
                        </Grid>
                        <Grid container justifyContent={"flex-start"} alignItems={"center"} rowGap={2}>
                           <Grid item xs={7}>
                              <Typography>Fecha de inicio:</Typography>
                           </Grid>
                           <Grid item xs={5}>
                              <InputTextField id="startTime" name="startTime" type="date" fullWidth size="small" />
                           </Grid>
                           <Grid item xs={7}>
                              <Typography>Fecha de terminación:</Typography>
                           </Grid>
                           <Grid item xs={5}>
                              <InputTextField id="endTime" name="endTime" type="date" fullWidth size="small" />
                           </Grid>
                        </Grid>
                        {props.experienceRecord ? (
                           <></>
                        ) : (
                           <Grid container>
                              <Grid item xs={7}>
                                 <Typography>Tipo de estudio:</Typography>
                              </Grid>
                              <Grid item xs={5}>
                                 <InputSelectField
                                    id="degree"
                                    labelId="degree"
                                    name="degree"
                                    fullWidth
                                    label=""
                                    size="small"
                                    disabled={true}
                                 >
                                    <MenuItem key={props.data.degree} value={props.data.degree}>
                                       {props.data.degree}
                                    </MenuItem>
                                 </InputSelectField>
                              </Grid>
                           </Grid>
                        )}
                     </Grid>
                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "space-between",
                           width: "100%",
                           px: 6,
                           pb: 2,
                        }}
                     >
                        {!props.isNewUser && (
                           <Box>
                              <Button
                                 color="error"
                                 variant="outlined"
                                 disabled={isLoading}
                                 type="button"
                                 onClick={handleDelete}
                              >
                                 <Typography fontSize={14}>Eliminar</Typography> <DeleteIcon />
                              </Button>
                           </Box>
                        )}

                        <Button
                           variant="outlined"
                           disabled={isLoading}
                           type="button"
                           onClick={() => props.setOpen(false)}
                        >
                           Cancelar
                        </Button>
                        <Button
                           variant="contained"
                           type="submit"
                           disabled={isLoading}
                           onClick={() => formProps.submitForm()}
                        >
                           Guardar información
                        </Button>
                     </Box>
                  </Box>
               </Form>
            )}
         </Formik>
      </CustomModalComponent>
   );
};
