import { StarBorder, Star } from "@mui/icons-material";
import { Box, Card, CardMedia, Stack, Typography, IconButton } from "@mui/material";
import { useContext, useState } from "react";
import { VerifyInsightFolderByName, DeleteFileWithBody } from "../../../../lib/usersBEClient";
import { UserContext } from "../../../../context/userContext";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { UpdatePersonalSpaceByUserId } from "../../../../lib/gobCorpBEClient";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";
import { replaceCharacterWithSpace } from "../../../../const/globalConst";
import { useParams } from "react-router-dom";

export function openProtectedBlog(url) {
   const currentDate = new Date();
   const currentMinute = currentDate.getMinutes();
   const secretKey = currentMinute % 10;
   const inputString = `sqWUYqVbilaiLBiS`;
   let encryptedString = "";
   for (let i = 0; i < inputString.length; i++) {
      let charCode = inputString.charCodeAt(i);
      if (charCode >= 65 && charCode <= 90) {
         encryptedString += String.fromCharCode(((charCode - 65 + secretKey) % 26) + 65);
      } else if (charCode >= 97 && charCode <= 122) {
         encryptedString += String.fromCharCode(((charCode - 97 + secretKey) % 26) + 97);
      } else {
         encryptedString += inputString[i];
      }
   }
   const urlWToken = `${url}?lecosy=${encodeURIComponent(encryptedString)}`;
   return urlWToken;
}

interface InsightsComponentProps {
   insight: any;
   disableFavorite?: boolean;
   protectedGuide?: boolean;
   saved?: boolean;
}

function InsightsComponent(props: InsightsComponentProps) {
   const { insight, disableFavorite, protectedGuide, saved } = props;
   const [favorite, setFavorite] = useState(saved ? false : true);
   const { user } = useContext(UserContext);
   const { mutate } = VerifyInsightFolderByName();
   const { showSnackBar } = useContext(SnackBarContext);
   const { mutate: deleteFile } = DeleteFileWithBody();
   const { mutate: addToMainFolder } = UpdatePersonalSpaceByUserId();
   const { companyId } = useParams();

   const saveLink = () => {
      if (favorite) {
         mutate(
            {
               title: replaceCharacterWithSpace(insight.title),
               userId: user.id,
               insightUrl: insight.link,
               protectedGuide,
            },
            {
               onError: async (error) => {
                  console.error(error);
                  showSnackBar("Error al eliminar", false);
               },
               onSuccess: async (data) => {
                  if (data.createdInsight) {
                     addToMainFolder(
                        {
                           files: [data.createdInsight.createFile.folder],
                           userId: user.id,
                        },
                        {
                           onError: (error: any) => {
                              console.error(error);
                              showSnackBar(
                                 `Error al crear la carpeta de ${protectedGuide ? "recomendación clave" : "insight"}.`,
                                 true
                              );
                           },
                        }
                     );
                  }
                  showSnackBar(
                     `${protectedGuide ? "Recomendación clave" : "Insight"} guardada en favoritos correctamente`,
                     false
                  );
               },
            }
         );
      } else {
         deleteFile(
            {
               userId: user.id,
               fileDirection: insight.link,
            },
            {
               onError: (error: any) => {
                  showSnackBar(`Error al eliminar ${protectedGuide ? "recomendación clave" : "insight"}.`, true);
               },
               onSuccess: () => {
                  showSnackBar(
                     `${protectedGuide ? "Recomendación clave" : "Insight"} eliminada de favoritos correctamente`,
                     false
                  );
               },
            }
         );
      }
   };

   const cardStyles = {
      display: "flex",
      height: 90,
      borderRadius: 2.5,
      border: 1,
      borderColor: "#EEEEEF",
      m: 1,
   };

   return (
      <Card sx={cardStyles}>
         <CardMedia
            component="img"
            image={insight.image}
            alt={insight.title}
            sx={{ width: 110, cursor: "pointer" }}
            onClick={() => window.open(protectedGuide ? openProtectedBlog(insight.link) : insight.link)}
         />
         <Stack
            sx={{
               px: 2,
               justifyContent: "center",
               flex: 1,
            }}
         >
            <Box
               sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
               }}
            >
               <Typography
                  fontSize={10}
                  color={"#9E9E9E"}
                  sx={{
                     overflow: "hidden",
                     textOverflow: "ellipsis",
                     display: "-webkit-box",
                     WebkitLineClamp: "1",
                     WebkitBoxOrient: "vertical",
                  }}
               >
                  {insight.category}
               </Typography>
               {!disableFavorite && (
                  <IconButton
                     sx={{ p: 0, mr: -1, mt: -1 }}
                     onClick={() => {
                        setFavorite(!favorite);
                        saveLink();
                     }}
                  >
                     {favorite ? (
                        <StarBorder sx={{ fontSize: 15, color: "#FDC748" }} />
                     ) : (
                        <Star sx={{ fontSize: 15, color: "#FDC748" }} />
                     )}
                  </IconButton>
               )}
            </Box>
            <Typography
               fontSize={14}
               fontWeight={600}
               sx={{
                  cursor: "pointer",
                  ":hover": { color: "#162c4495" },
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  display: "-webkit-box",
                  WebkitLineClamp: "2",
                  WebkitBoxOrient: "vertical",
               }}
               onClick={() => {
                  window.open(protectedGuide ? openProtectedBlog(insight.link) : insight.link);
               }}
            >
               {replaceCharacterWithSpace(insight.title)}
            </Typography>
            <Typography fontSize={12} color={"#7D8DA6"}>
               {insight.date}
            </Typography>
         </Stack>
      </Card>
   );
}

export default InsightsComponent;
