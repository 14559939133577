import { Box } from "@mui/material";
import { SchedulerComponent } from "../../components/Gobierno corporativo/SchedulerScreen/SchedulerComponent";

export const formatName = (name: string) => {
   return name.charAt(0).toUpperCase() + name.slice(1);
};

export const SchedulerScreen = () => {
   return (
      <Box sx={{ display: "flex", columnGap: 2, flexDirection: { sm: "column", md: "row" } }}>
         <SchedulerComponent />
      </Box>
   );
};
