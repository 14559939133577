import { Badge, Typography } from "@mui/material";
import {
   CampaignOutlined,
   ContentPasteSearch,
   Email,
   InsertChartOutlinedSharp,
   DrawOutlined,
} from "@mui/icons-material";
import { useContext } from "react";
import { UserContext } from "../context/userContext";
import { ListItemComponent } from "./ListItemComponent";
import { ComplaintContext } from "../context/complaintContext";

const menuOptions = [
   {
      lable: "Búzon de entrada",
      icon: <Email sx={{ color: "#162c44" }} />,
      submenu: [
         {
            lable: "Denuncias en proceso",
            path: "/canal-denuncias/recepcion-denuncias/denuncias-proceso",
         },
         {
            lable: "Denuncias finalizadas",
            path: "/canal-denuncias/recepcion-denuncias/denuncias-finalizadas",
         },
      ],
   },
   {
      lable: "Firmas pendientes",
      path: "/canal-denuncias/firmas",
      icon: <DrawOutlined sx={{ color: "#162c44" }} />,
   },
   {
      lable: "Campañas y difusión",
      path: "/canal-denuncias/difusion/plan-difusion",
      icon: <CampaignOutlined sx={{ color: "#162c44" }} />,
   },
   // {
   //    lable: "Reportes y estadísticas",
   //    path: "/canal-denuncias/estadisticas-reportes/estadisticas",
   //    icon: <InsertChartOutlinedSharp sx={{ color: "#162c44" }} />,
   // },
   {
      lable: "Banco de consultas",
      path: "/canal-denuncias/banco-consultas",
      icon: <ContentPasteSearch sx={{ color: "#162c44" }} />,
   },
];

interface ComplaintChannelMenuProps {
   open: boolean;
   setOpen: Function;
}

export const ComplaintChannelMenu = (props: ComplaintChannelMenuProps) => {
   const { verifyAccess } = useContext(UserContext);
   const { NewComplaints } = useContext(ComplaintContext);

   const firmasPermission = verifyAccess("Firmas");
   const difusionPermission = verifyAccess("Difusión-Reportes-Banco");
   const denunciasPermission = verifyAccess("Denuncias externas");

   return (
      <div>
         <Typography sx={{ px: 3, fontWeight: 520, color: "gray", fontSize: 14, pt: 2, pb: 1 }}>
            {props.open ? "CANAL DE DENUNCIAS" : "C"}
         </Typography>
         {menuOptions.map((item, index) => {
            if (
               (index === 1 && !firmasPermission) ||
               (index === 0 && !denunciasPermission) ||
               (index >= 2 && !difusionPermission)
            ) {
               return null;
            }

            return (
               <>
                  {NewComplaints > 0 && index === 0 ? (
                     <Badge badgeContent={NewComplaints} color="warning">
                        <ListItemComponent
                           item={item}
                           index={index}
                           open={props.open}
                           setOpen={props.setOpen}
                           key={index}
                        />
                     </Badge>
                  ) : (
                     <ListItemComponent
                        item={item}
                        index={index}
                        open={props.open}
                        setOpen={props.setOpen}
                        key={index}
                     />
                  )}
               </>
            );
         })}
      </div>
   );
};
