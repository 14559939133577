import { useCallback, useContext, useEffect, useState } from "react";
import { UserContext } from "../context/userContext";
import CarouselComponent, { CarouselContentType } from "./CarouselComponent";
import { getUrlS3 } from "../lib/usersBEClient";
import { Avatar, Box, Typography } from "@mui/material";

type CompaniesStatisticsTableProps = {
   selectedCompany: Function;
   filterName: string;
   orientation: boolean;
   maxComponentNumber?: number;
};
export const CompaniesCarousel = (props: CompaniesStatisticsTableProps) => {
   const { selectedCompany, filterName, orientation, maxComponentNumber = 3 } = props;
   const { user } = useContext(UserContext);
   const [companiesData, setCompaniesData] = useState([]);

   const fetchedUrl = async (selectedCompany) => {
      const companyurl = await getUrlS3("images-lecosy", { folder: `${selectedCompany._id}` }, "logo.png");
      return companyurl;
   };

   const getCompaniesByFilter = useCallback(
      async (companies) => {
         if (companies.length > 0) {
            const companiesFound: CarouselContentType[] = [];
            for (const company of companies) {
               if (company.person_details.comercialName.includes(filterName) || filterName === "") {
                  const logo = await fetchedUrl(company);
                  companiesFound.push({ imageSrc: logo, title: company.person_details.comercialName, object: company });
               }
            }
            setCompaniesData(companiesFound);
         }
      },
      [filterName]
   );

   useEffect(() => {
      getCompaniesByFilter(user.companies);
   }, []);

   return (
      <CarouselComponent
         maxElements={maxComponentNumber}
         vertical={orientation}
         hideSlide={companiesData.length < maxComponentNumber}
      >
         {companiesData.map((company, i) => {
            return (
               <div key={`cc-${i}`}>
                  <Box
                     sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        flexDirection: orientation ? "row-reverse" : "column",
                        py: orientation ? (companiesData.length > 3 ? 0.2 : 1) : 0,
                        px: orientation ? 3 : 0,
                     }}
                  >
                     <Typography textAlign={"end"} variant={"subtitle2"} sx={{ px: 0.5, fontWeight: "bold" }}>
                        {company.title}
                     </Typography>
                     <Box
                        sx={{
                           display: "flex",
                           alignItems: "center",
                        }}
                     >
                        <div
                           onClick={() => {
                              return !orientation ? selectedCompany(company.object) : undefined;
                           }}
                        >
                           <Avatar
                              src={company.imageSrc}
                              sx={{
                                 width: orientation ? 75 : 120,
                                 height: orientation ? 75 : 120,
                                 "&:hover": {
                                    width: orientation ? 75 : 123,
                                    height: orientation ? 75 : 123,
                                 },
                                 border: 1,
                                 borderColor: "#E3E2E2",
                              }}
                              imgProps={{ sx: { objectFit: "contain" } }}
                           />
                        </div>
                     </Box>
                  </Box>
               </div>
            );
         })}
      </CarouselComponent>
   );
};
