import {
   Box,
   Button,
   CircularProgress,
   IconButton,
   List,
   ListItem,
   ListItemButton,
   ListItemText,
   Stack,
   Typography,
} from "@mui/material";
import { useContext, useEffect, useMemo, useState } from "react";
import {
   MoveFileQuery,
   getFoldersAndFilesById,
   getFoldersById,
   useGetResourceSelected,
} from "../../../lib/usersBEClient";
import { getIconByType } from "../../../screens/Gobierno corporativo/FolderScreen";
import { getDateAndTimeFromISODate } from "../../../const/globalConst";
import { ArrowBack, Folder } from "@mui/icons-material";
import { GovernanceContext } from "../../../context/governanceContext/governanceContext";
import { SnackBarContext } from "../../../context/snackBarContext";
import { UserContext } from "../../../context/userContext";
import { ConfirmationModal } from "../../ConfirmationModal";
import { ChatContext } from "../../../context/governanceContext/ChatContext";
import { useParams } from "react-router-dom";

const useGetFiles = () => {
   const { personalSpace, isLoading: isLoadingDetails } = useContext(GovernanceContext);
   const { companyId } = useParams();
   const [isLoading, setIsLoading] = useState(true);
   const [id, setId] = useState<any>([{ _id: personalSpace.files }]);
   const [files, setFiles] = useState([]);

   const fetchFolders = async (lastId) => {
      setIsLoading(true);
      const response = await getFoldersAndFilesById(lastId, companyId);
      setFiles([...response.folder.children, ...response.files]);
      setIsLoading(false);
   };

   useEffect(() => {
      const fetchFoldersFirstTime = async () => {
         setIsLoading(true);
         const response = await getFoldersById(personalSpace.files);
         const initFolder = response.find((file) => file.name === "Mis documentos");
         const responseOpenFolder = await getFoldersAndFilesById(initFolder?._id, companyId);
         setFiles([...responseOpenFolder.folder.children, ...responseOpenFolder.files]);
         setIsLoading(false);
      };

      if (!isLoadingDetails && id.length === 1) {
         fetchFoldersFirstTime();
      }
   }, [id.length, isLoadingDetails, personalSpace, companyId]);

   const processedData = useMemo(() => {
      const data = [];
      for (const iterator of files) {
         if (iterator.size) {
            const name = iterator.name.split(".");
            name.pop();

            data.push({
               ...iterator,
               name: (
                  <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                     {getIconByType(iterator.type)}
                     <Typography variant="subtitle2">{iterator.name}</Typography>
                  </Box>
               ),
               originalName: name.join("."),
               updatedAt: getDateAndTimeFromISODate(iterator.updatedAt),
               owner: iterator.owner.firstName + " " + iterator.owner.lastName,
               size: (iterator.size / 1024).toFixed(2) + " Kb",
            });
         } else {
            data.push({
               ...iterator,
               type: "carpeta",
               name: (
                  <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                     <Folder sx={{ color: "#F1B44C", fontSize: 25 }} />
                     <Typography variant="subtitle2">{iterator.name}</Typography>
                  </Box>
               ),
               updatedAt: getDateAndTimeFromISODate(iterator.updatedAt),
               size: "---",
               originalName: iterator.name,
            });
         }
      }
      return data;
   }, [files]);

   return { isLoading, files, setId, id, fetchFolders, processedData };
};

export const SelectFile = ({
   selectedFile,
   setOpenModal,
   reload,
   setReload,
   handleClose,
   setFile = null,
   chat,
}: {
   selectedFile: any;
   setOpenModal: Function;
   reload: string;
   setReload: string;
   handleClose: Function;
   setFile?: Function;
   chat?: boolean;
}) => {
   const { processedData, isLoading, setId, id, fetchFolders } = useGetFiles();
   const lastSelected = id[id.length - 1];
   const { showSnackBar } = useContext(SnackBarContext);
   const { setFileSelected } = useContext(ChatContext);

   const handleClickFolder = async (folderId) => {
      try {
         await fetchFolders(folderId);
         setId((prev) => [...prev, { _id: folderId }]);
      } catch (error) {
         console.error("Error fetching folder:", error);
      }
   };

   const handleGoBack = async () => {
      if (id.length > 1) {
         const newId = id.slice(0, -1);
         const lastFolderId = newId[newId.length - 1]._id;
         try {
            await fetchFolders(lastFolderId);
            setId(newId);
         } catch (error) {
            console.error("Error fetching folder:", error);
         }
      }
   };

   return (
      <Stack p={2}>
         {id.length > 1 ? (
            <Box sx={{ py: 1, display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
               <IconButton onClick={handleGoBack}>
                  <ArrowBack />
               </IconButton>
               <Typography>{lastSelected.originalName}</Typography>
            </Box>
         ) : null}
         <Box sx={{ width: 600, height: 400, overflow: "auto" }}>
            <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
               {isLoading ? (
                  <CircularProgress />
               ) : (
                  <List sx={{ flex: 1 }}>
                     {processedData.map((item) => (
                        <ListItemButton
                           key={item._id}
                           onClick={() => {
                              if (item.type === "carpeta") {
                                 handleClickFolder(item._id);
                              } else {
                                 if (setFile && !chat) {
                                    setFile(item);
                                 }
                                 if (chat) {
                                    setFileSelected(item);
                                 }
                                 showSnackBar("El archivo fue seleccionado correctamente.", false);
                                 setOpenModal(false);
                              }
                           }}
                        >
                           <ListItem>
                              <ListItemText primary={item.name} />
                           </ListItem>
                        </ListItemButton>
                     ))}
                  </List>
               )}
            </Box>
         </Box>
         <Box sx={{ display: "flex", columnGap: 1 }}>
            <Button variant="outlined" fullWidth onClick={() => setOpenModal(false)}>
               Cancelar
            </Button>
         </Box>
      </Stack>
   );
};
