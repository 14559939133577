import { Document, Font, Image, Page, StyleSheet, Text, View } from "@react-pdf/renderer";

export const ProclamationAssemblyTemplate = (
   type,
   proclamationNumber,
   companyName,
   start,
   day,
   provider,
   location,
   representative,
   affairs,
   daysToConfirm,
   quorum,
   image
) => {
   const date = new Date(day);
   const startDate = new Date(start);
   const today = new Date();
   const confirmationDay = new Date();
   confirmationDay.setDate(startDate.getDate() - daysToConfirm);

   return (
      <Document>
         <Page style={styles.body}>
            <Image
               style={{
                  height: 40,
                  width: "auto",
                  position: "absolute",
                  top: 50,
                  left: 75,
               }}
               fixed
               src={image}
            />

            <Text style={styles.title}>{proclamationNumber.toUpperCase()} CONVOCATORIA</Text>
            <Text style={styles.title}>ASAMBLEA {type.toUpperCase()} DE ACCIONISTAS</Text>
            <Text style={{ textAlign: "center", fontSize: 11, paddingBottom: 20 }}>DE {companyName.toUpperCase()}</Text>

            <Text style={styles.text}>
               De conformidad con los estatutos sociales de {companyName.toUpperCase()} y con los Artículos 178, 181 y
               182, y demás aplicables de la Ley General de Sociedades Mercantiles según corresponda, se convoca a los
               accionistas de la sociedad a asamblea general {type.toUpperCase()} de accionistas, la cual se celebrará
               de manera híbrida, el próximo{" "}
               {date.toLocaleDateString("es-MX", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
               })}{" "}
               a las{" "}
               {startDate.toLocaleTimeString("es-MX", {
                  hour: "2-digit",
                  minute: "2-digit",
                  hour12: true,
               })}
               , en {location} y por medio de la plataforma de videollamadas {provider}, con el propósito de tratar los
               asuntos siguientes:
            </Text>
            {/* ORDEN DEL DÍA */}
            <Text style={[styles.title, { marginVertical: 10 }]}>ORDEN DEL DÍA</Text>
            <View
               style={{
                  display: "flex",
                  flexDirection: "row",
                  backgroundColor: "#A9A9A9",
                  paddingVertical: 3,
                  borderTop: "3px solid #162c44",
               }}
            >
               <Text
                  style={{
                     fontSize: "11",
                     flex: 1,
                     textAlign: "center",
                     borderRight: "1px solid #ffffff",
                     color: "white",
                     fontWeight: "bold",
                  }}
               >
                  ASUNTO
               </Text>
               <Text style={{ fontSize: "11", flex: 2, textAlign: "center", color: "white", fontWeight: "bold" }}>
                  DESCRIPCIÓN DEL ASUNTO
               </Text>
            </View>
            {affairs.map((affair) => (
               <View
                  style={{
                     display: "flex",
                     flexDirection: "row",
                     backgroundColor: "#EFEFEF",
                     paddingVertical: 3,
                  }}
               >
                  <Text style={{ fontSize: "11", flex: 1, textAlign: "center", borderRight: "1px solid #ffffff" }}>
                     {affair.affair.title}
                  </Text>
                  <Text style={{ fontSize: "11", flex: 2, textAlign: "center" }}>{affair.description}</Text>
               </View>
            ))}

            {/* QUÓRUM */}
            <Text style={[styles.title, { marginVertical: "10" }]}>QUÓRUM</Text>
            <Text style={styles.text}>
               Conforme a lo establecido en nuestros estatutos, el quórum mínimo requerido para la válida celebración de
               la Asamblea {type} es del {quorum}% del capital social. Este quórum se determina tomando en cuenta la
               representación de los accionistas presentes o debidamente representados en relación con el total del
               capital social de la empresa. Además, es imperativo que todos los propietarios exhiban sus títulos
               nominativos de sus acciones al momento de su participación en la asamblea, o bien solicitar el libro de
               socios a la administración de la sociedad, a fin de acreditar la calidad y sus derechos como accionistas
               habilitados para tomar decisiones, garantizar la legalidad y validez de las decisiones que se tomen
               durante la asamblea.
            </Text>
            <Text style={[styles.text, { marginVertical: 10 }]}>
               En caso de que no se logre este quórum, se informará sobre las acciones a seguir según lo establecido en
               la ley y los estatutos de la sociedad.
            </Text>
            <View style={styles.pageNumber} fixed>
               <Text
                  style={{
                     fontSize: "11",
                     textAlign: "center",
                     marginTop: "60",
                     borderBottom: 1,
                     marginHorizontal: "60",
                  }}
               ></Text>
               <Text
                  style={{ color: "black", fontSize: "11", textAlign: "right", marginTop: "5", marginHorizontal: "60" }}
               >
                  {companyName.toUpperCase()}
               </Text>
            </View>

            {/* PARTICIPACIÓN */}
            <Text style={styles.title} break>
               PARTICIPACIÓN
            </Text>
            <Text style={[styles.text, { marginTop: 10 }]}>
               Se ruega la puntual asistencia de todos los accionistas y deberá confirmar su participación antes del{" "}
               {date.toLocaleDateString("es-MX", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
               })}
               , a través de la notificación enviada por el sistema de gestión de Gobierno Corporativo. La confirmación
               previa facilitará la planificación y logística de la sesión, asegurando una asamblea eficiente y
               efectiva.
            </Text>
            <Text style={[styles.text, { marginTop: 10 }]}>
               Se recuerda a los accionistas que podrán asistir personalmente o hacerse representar por medio de
               apoderado legal, de acuerdo con los términos y condiciones establecidos en la ley y en los estatutos de
               la sociedad.
            </Text>
            <Text style={[styles.text, { marginVertical: 10 }]}>
               Se insta a los accionistas a revisar detenidamente los requisitos y procedimientos para la
               representación, asegurándose de cumplir con todas las formalidades necesarias.
            </Text>

            {/* DOCUMENTACIÓN */}
            <Text style={[styles.title, { marginTop: "10" }]}>DOCUMENTACIÓN</Text>
            <Text style={[styles.text, { marginTop: 10 }]}>
               Se pone a disposición de los accionistas la documentación pertinente, la cual estará disponible para su
               consulta en el domicilio social de la empresa a partir del{" "}
               {today.toLocaleDateString("es-MX", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
               })}
               , así como por nuestro sistema de gestión de Gobierno Corporativo. Esta documentación incluirá cualquier
               documento relevante para los temas a tratar en la asamblea.
            </Text>
            <Text style={[styles.text, { marginTop: 10 }]}>
               Se fomenta a los accionistas a revisar esta información con anticipación para estar debidamente
               informados y preparados para participar en las discusiones durante la asamblea.
            </Text>
            <Text style={[styles.text, { marginVertical: 10 }]}>
               En términos del 183 de la Ley General de Sociedades Mercantiles y Estatutos de la Sociedad, se convoca a
               la asamblea presente a través de su {representative.type.split(" ")[0].toUpperCase()} de la sociedad,
               facultado para ello.
            </Text>

            <Text style={{ fontSize: "11", textAlign: "center", marginTop: "30" }}>
               Hermosillo, Sonora, México a{" "}
               {today.toLocaleDateString("es-MX", {
                  day: "2-digit",
                  month: "long",
                  year: "numeric",
               })}
            </Text>

            <Text style={{ fontSize: "10", textAlign: "center", marginTop: "45" }}>
               {representative.name.toUpperCase()}
            </Text>
            <Text
               style={{
                  fontSize: "9",
                  textAlign: "center",
                  marginTop: "5",
                  fontFamily: "open-sans-bold",
                  fontWeight: "bold",
               }}
            >
               {representative.type.toUpperCase()}
            </Text>
         </Page>
      </Document>
   );
};

Font.register({
   family: "open-sans",
   src: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-Regular.ttf",
});

Font.register({
   family: "open-sans-bold",
   src: "https://images-lecosy.s3.us-west-2.amazonaws.com/assets/fonts/OpenSans-Bold.ttf",
});

const styles = StyleSheet.create({
   body: {
      paddingTop: 100,
      paddingBottom: 65,
      paddingHorizontal: 75,
      fontFamily: "open-sans",
   },
   title: {
      fontSize: 12,
      fontFamily: "open-sans-bold",
      fontWeight: "bold",
      textAlign: "center",
   },
   text: {
      fontSize: 11,
      textAlign: "justify",
      lineHeight: 1.8,
   },
   image: {
      marginVertical: 15,
      marginHorizontal: 100,
   },
   pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 50,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
   },
});
