import { useContext } from "react";
import { Box, Button } from "@mui/material";
import { ComplaintStepperContext } from "../../../context/complaintStepperContext";
import { DocumentsTableNew } from "../DocumentsTableNew";

export const ComplainantStep = () => {
   const { complaint, isLastStep, setOpenCompleteAllSteps, setOpenCompleteDialog, checkDocuments } =
      useContext(ComplaintStepperContext);
   return (
      <Box sx={{ display: "flex", flexDirection: "column", minHeight: "50vh" }}>
         <Box sx={{ flex: 2 }}>
            <DocumentsTableNew
               rows={
                  complaint.complaintType === "anonima"
                     ? [
                          {
                             document: "Denunciante anonimo",
                             templateLink: `entrevista/4`,
                             documentName: ["constancia-anonimo"],
                             fileHeader: "Añadir documento de denunciante anonimo",
                          },
                       ]
                     : [
                          {
                             document: "Notificación de entrevista",
                             templateLink: `notificacion/denunciante/1`,
                             documentName: ["constancia-incomparecencia-denunciante", "entrevista-denunciante"],
                             fileHeader: "Añadir documento de notificación al denunciante",
                          },
                          {
                             document: "Entrevista",
                             templateLink: `entrevista/3`,
                             documentName: ["entrevista-denunciante", "constancia-incomparecencia-denunciante"],
                             fileHeader: "Añadir documento de entrevista al denunciante",
                          },
                          {
                             document: "Constancia de incomparecencia",
                             templateLink: `incomparecencia/3`,
                             documentName: ["constancia-incomparecencia-denunciante", "entrevista-denunciante"],
                             fileHeader: "Añadir documento de constancia de incomparecencia",
                          },
                       ]
               }
            />
         </Box>
         <Box
            sx={{
               display: "flex",
               flexDirection: "row",
               alignItems: "center",
               flex: 1,
               mt: 2,
               mr: 2,
            }}
         >
            <Box sx={{ flex: "1 1 auto" }} />
            <Box sx={{ maxHeight: 30 }}>
               {isLastStep() ? (
                  <Button
                     disabled={!checkDocuments("denunciante")}
                     onClick={() => setOpenCompleteAllSteps(true)}
                     variant="outlined"
                  >
                     Finalizar investigación
                  </Button>
               ) : (
                  <Button
                     disabled={!checkDocuments("denunciante")}
                     onClick={() => setOpenCompleteDialog(true)}
                     variant="outlined"
                  >
                     {`Completar denunciante`}
                  </Button>
               )}
            </Box>
         </Box>
      </Box>
   );
};
