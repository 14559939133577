import {
   Box,
   Button,
   Chip,
   CircularProgress,
   Collapse,
   Divider,
   ListItemText,
   MenuItem,
   Stack,
   Typography,
} from "@mui/material";
import { UserMap } from "./UserMap";
import { useContext, useEffect, useState } from "react";
import {
   ShareFileById,
   ShareFolderById,
   getFileById,
   getFoldersById,
   useGetResourceSelected,
} from "../../../../lib/usersBEClient";
import { Form, Formik, FormikProps } from "formik";
import { UsersSelector } from "../../Inputs/UsersSelector";
import { PeopleAltOutlined } from "@mui/icons-material";
import { InputSelectField } from "../../../Inputs/InputSelectField";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";
import { IGovernanceBody } from "../../../../types/governance.types";
import { useGetUsersByGovernance } from "../../../../hooks/gob-corp/SchedulerScreen/index.hooks";

export const options = ["Consultor", "Editor"];

const useGetDetailsById = (id: string, folder: boolean) => {
   const [fileDetails, setFileDetails] = useState(null);
   const [isLoading, setIsLoading] = useState(true);

   useEffect(() => {
      const fetchFileInfo = async () => {
         setIsLoading(true);
         const response = await getFileById(id);
         setFileDetails(response);
         setIsLoading(false);
      };

      const fetchFolderInfo = async () => {
         setIsLoading(true);
         const response = await getFoldersById([id]);
         setFileDetails(response[0]);
         setIsLoading(false);
      };

      if (folder) {
         fetchFolderInfo();
      } else {
         fetchFileInfo();
      }
   }, [id]);

   return { fileDetails, isLoading, setFileDetails };
};

export const ShareFile = ({ fileId, setOpen, reload, setReload, folder, handleClose }) => {
   const { fileDetails, isLoading, setFileDetails } = useGetDetailsById(fileId, folder);
   const resourceId = useGetResourceSelected();
   const { gobernanceBody, companySelected } = useContext(GovernanceContext);
   const { mutate: shareFile } = ShareFileById(fileId, resourceId, companySelected);
   const { mutate: shareFolder } = ShareFolderById(fileId, resourceId, companySelected);
   const { showSnackBar } = useContext(SnackBarContext);
   const [gobernanceSelected, setGobernanceSelected] = useState<IGovernanceBody>(null);
   const { loading: loadingUsers, users } = useGetUsersByGovernance(gobernanceSelected ? gobernanceSelected : null);
   const [isLoadingCreate, setIsLoadingCreate] = useState(false);

   const handleShareFile = async (values) => {
      setIsLoadingCreate(true);
      let formatedData = [];
      for (const iterator of values.users) {
         const object = {
            user: iterator._id,
            permission: values.permission,
         };
         formatedData.push(object);
      }

      const concat = formatedData.concat(fileDetails.shared);
      if (folder) {
         shareFolder(
            {
               shared: concat,
               new: formatedData,
            },
            {
               onError: (error: any) => {
                  showSnackBar("Error al compartir carpeta.", true);
               },
               onSuccess: () => {
                  showSnackBar("La carpeta fue compartida correctamente.", false);
                  setIsLoadingCreate(false);
                  setOpen(false);
                  setReload(!reload);
                  handleClose();
               },
            }
         );
      } else {
         shareFile(
            {
               shared: concat,
               new: formatedData,
            },
            {
               onError: (error: any) => {
                  showSnackBar("Error al compartir archivo.", true);
               },
               onSuccess: () => {
                  showSnackBar("El archivo fue compartido correctamente.", false);
                  setIsLoadingCreate(false);
                  setOpen(false);
                  setReload(!reload);
                  handleClose();
               },
            }
         );
      }
   };

   return (
      <Box sx={{ p: 2, minWidth: 400 }}>
         <Formik
            initialValues={{
               users: [],
               permission: 1,
            }}
            onSubmit={handleShareFile}
         >
            {(formProps: FormikProps<any>) => {
               return (
                  <Form>
                     <Stack rowGap={1}>
                        <Stack>
                           <Typography> Personas que tienen acceso</Typography>
                           {!isLoading ? (
                              <>
                                 <UserMap
                                    userData={fileDetails.owner}
                                    permission={""}
                                    owner={true}
                                    fileId={fileId}
                                    setOpen={setOpen}
                                    setFileDetails={setFileDetails}
                                    folder={folder}
                                 />
                                 {fileDetails.shared.map((user) => (
                                    <UserMap
                                       userData={user.user}
                                       permission={user.permission}
                                       owner={false}
                                       fileId={fileId}
                                       setOpen={setOpen}
                                       setFileDetails={setFileDetails}
                                       folder={folder}
                                    />
                                 ))}
                              </>
                           ) : (
                              <CircularProgress sx={{ m: 1 }} />
                           )}
                        </Stack>

                        <Divider />
                        <Typography>Añadir personas </Typography>
                        <UsersSelector
                           icon={<PeopleAltOutlined sx={{ color: "gray" }} />}
                           inputProps={{
                              id: "users",
                              name: "users",
                           }}
                           label={"Contactos"}
                           options={gobernanceBody.filter(
                              (governance) =>
                                 governance.title !== "PANEL DE USUARIO" &&
                                 governance.title.toLocaleLowerCase() !== "asamblea de accionistas"
                           )}
                           gobernanceSelected={gobernanceSelected}
                           setGobernanceSelected={setGobernanceSelected}
                           users={users}
                           selectedUsers={formProps.values.users}
                           setUsersSelected={(user) => {
                              let selectedUsers = formProps.values.users;
                              if (selectedUsers.some((userSelected) => userSelected._id === user._id)) {
                                 const newUsers = selectedUsers.filter((userSelected) => userSelected._id !== user._id);
                                 formProps.setFieldValue("users", newUsers);
                                 return;
                              } else {
                                 const newUsers = [...selectedUsers, user];
                                 formProps.setFieldValue("users", newUsers);
                                 return;
                              }
                           }}
                           selectAllUsers={(users) => {
                              formProps.setFieldValue("users", users);
                           }}
                           deSelectAllUsers={() => formProps.setFieldValue("users", [])}
                           loadingUsers={loadingUsers}
                        />
                        <Collapse unmountOnExit in={formProps.values.users.length > 0}>
                           <Stack rowGap={1}>
                              <Box>
                                 {formProps.values.users.map((user) => (
                                    <Chip
                                       label={user.firstName}
                                       onDelete={() =>
                                          formProps.setFieldValue(
                                             "users",
                                             formProps.values.users.filter((userN) => userN._id !== user._id)
                                          )
                                       }
                                    />
                                 ))}
                              </Box>
                              <Box
                                 sx={{
                                    display: "flex",
                                    flexDirection: "column",
                                    rowGap: 1,
                                    width: "100%",
                                 }}
                              >
                                 <Typography variant="body2" fontWeight={600}>
                                    Acceso
                                 </Typography>
                                 <InputSelectField
                                    fullWidth={true}
                                    labelId="modules-select-label"
                                    id="permission"
                                    name="permission"
                                    label=""
                                    variant="outlined"
                                    size={"small"}
                                    onChange={(e) => {
                                       formProps.setFieldValue("permission", e.target.value);
                                    }}
                                 >
                                    {options.map((e, i) => (
                                       <MenuItem key={e} value={i + 1}>
                                          <ListItemText primary={e} />
                                       </MenuItem>
                                    ))}
                                 </InputSelectField>
                              </Box>
                           </Stack>
                           <Box sx={{ display: "flex", columnGap: 1 }}>
                              <Button variant="contained" fullWidth onClick={() => setOpen(false)}>
                                 Cancelar
                              </Button>
                              <Button variant="contained" fullWidth type="submit">
                                 {isLoadingCreate ? <CircularProgress sx={{ color: "white" }} /> : "Modificar"}
                              </Button>
                           </Box>
                        </Collapse>
                     </Stack>
                  </Form>
               );
            }}
         </Formik>
      </Box>
   );
};
