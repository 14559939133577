import { PictureAsPdf } from "@mui/icons-material";
import {
   Box,
   Button,
   CircularProgress,
   Container,
   Divider,
   Stack,
   Table,
   TableBody,
   TableCell,
   TableContainer,
   TableRow,
   Typography,
} from "@mui/material";
import { getDateFromISODate } from "../../../../const/globalConst";
import { getUrlS3 } from "../../../../lib/usersBEClient";
import { useContext, useMemo } from "react";
import { UserContext } from "../../../../context/userContext";
import { DocumentationUploadMembers } from "./Subcomponent/DocumentationUploadMembers";

const GetDocumentation = (memberId, memberCharge) => {
   const isShareholder = useMemo(() => memberCharge.includes("Accionista"), [memberCharge]);

   return { isShareholder };
};

export const Documentation = ({ data, memberId = null, memberCharge = null }) => {
   const { isShareholder } = GetDocumentation(memberId, memberCharge);
   const { user, isCompanyAdmin } = useContext(UserContext);

   const getFile = async (fileName: string) => {
      const fileData1: string = await getUrlS3(
         "files-lecosy",
         {
            folder: `gc/users/${data.userDetails.user}/files`,
         },
         fileName
      );
      window.open(fileData1, "_blank", "noopener,noreferrer");
   };
   return (
      <Container
         sx={{ display: "flex", flexDirection: "column", width: "100%", overflow: "auto", height: "60vh", py: 3 }}
      >
         <Box sx={{ pb: 2 }}>
            <Typography variant="h6" fontWeight={"bold"} color={"#64748B"}>
               DOCUMENTACIÓN
            </Typography>
         </Box>
         {isCompanyAdmin ? (
            <DocumentationUploadMembers isShareholder={isShareholder} memberId={memberId} />
         ) : (
            <>
               {data?.personalData ? (
                  <Stack spacing={2} sx={{ px: 2, py: 1 }}>
                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "space-between",
                           alignItems: "center",
                        }}
                     >
                        <Box
                           sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                           }}
                        >
                           <PictureAsPdf sx={{ mr: 2, color: "#162c44" }} />
                           <Stack sx={{ display: "flex", flexDirection: "column" }}>
                              <Typography variant="body1" color={"#152C44"}>
                                 Credencial de elector
                              </Typography>
                              {data.personalData?.credencialElector && (
                                 <Typography variant="caption">
                                    Fecha de modificación:{" "}
                                    {getDateFromISODate(data.personalData?.credencialElector?.updatedAt)}
                                 </Typography>
                              )}
                           </Stack>
                        </Box>
                        <Box>
                           {data.personalData?.credencialElector ? (
                              <Button
                                 sx={{ bgcolor: "#F3F3F3", borderRadius: 2.5, height: 25 }}
                                 onClick={() => getFile(data.personalData?.credencialElector?.name)}
                              >
                                 Abrir
                              </Button>
                           ) : (
                              <Typography variant="caption">No se encontró documentación</Typography>
                           )}
                        </Box>
                     </Box>
                     <Divider />
                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "space-between",
                           alignItems: "center",
                        }}
                     >
                        <Box
                           sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                           }}
                        >
                           <PictureAsPdf sx={{ mr: 2, color: "#162c44" }} />
                           <Stack sx={{ display: "flex", flexDirection: "column" }}>
                              <Typography variant="body1" color={"#152C44"}>
                                 Título profesional
                              </Typography>
                              {data.personalData?.tituloProfesional && (
                                 <Typography variant="caption">
                                    Fecha de modificación:{" "}
                                    {getDateFromISODate(data.personalData?.tituloProfesional?.updatedAt)}
                                 </Typography>
                              )}
                           </Stack>
                        </Box>
                        <Box>
                           {data.personalData?.tituloProfesional ? (
                              <Button
                                 sx={{ bgcolor: "#F3F3F3", borderRadius: 2.5, height: 25 }}
                                 onClick={() => getFile(data.personalData?.tituloProfesional?.name)}
                              >
                                 Abrir
                              </Button>
                           ) : (
                              <Typography variant="caption">No se encontró documentación</Typography>
                           )}
                        </Box>
                     </Box>
                     <Divider />
                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "space-between",
                           alignItems: "center",
                        }}
                     >
                        <Box
                           sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                           }}
                        >
                           <PictureAsPdf sx={{ mr: 2, color: "#162c44" }} />
                           <Stack sx={{ display: "flex", flexDirection: "column" }}>
                              <Typography variant="body1" color={"#152C44"}>
                                 Cédula profesional
                              </Typography>
                              {data.personalData?.cedulaProfesional && (
                                 <Typography variant="caption">
                                    Fecha de modificación:{" "}
                                    {getDateFromISODate(data.personalData?.cedulaProfesional?.updatedAt)}
                                 </Typography>
                              )}
                           </Stack>
                        </Box>
                        <Box>
                           {data.personalData?.cedulaProfesional ? (
                              <Button
                                 sx={{ bgcolor: "#F3F3F3", borderRadius: 2.5, height: 25 }}
                                 onClick={() => getFile(data.personalData?.cedulaProfesional?.name)}
                              >
                                 Abrir
                              </Button>
                           ) : (
                              <Typography variant="caption">No se encontró documentación</Typography>
                           )}
                        </Box>
                     </Box>
                     <Divider />
                     <Box
                        sx={{
                           display: "flex",
                           justifyContent: "space-between",
                           alignItems: "center",
                        }}
                     >
                        <Box
                           sx={{
                              display: "flex",
                              flexDirection: "row",
                              alignItems: "center",
                           }}
                        >
                           <PictureAsPdf sx={{ mr: 2, color: "#162c44" }} />
                           <Stack sx={{ display: "flex", flexDirection: "column" }}>
                              <Typography variant="body1" color={"#152C44"}>
                                 Curriculum vitae o semblanza personal
                              </Typography>
                              {data.personalData?.cv && (
                                 <Typography variant="caption">
                                    Fecha de modificación: {getDateFromISODate(data.personalData?.cv?.updatedAt)}
                                 </Typography>
                              )}
                           </Stack>
                        </Box>
                        <Box>
                           {data.personalData?.cv ? (
                              <Button
                                 sx={{ bgcolor: "#F3F3F3", borderRadius: 2.5, height: 25 }}
                                 onClick={() => getFile(data.personalData?.cv?.name)}
                              >
                                 Abrir
                              </Button>
                           ) : (
                              <Typography variant="caption">No se encontró documentación</Typography>
                           )}
                        </Box>
                     </Box>
                  </Stack>
               ) : data === undefined ? (
                  <CircularProgress />
               ) : (
                  <Typography>No existe documentación</Typography>
               )}
            </>
         )}
      </Container>
   );
};
