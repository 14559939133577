import { Avatar, Box, CircularProgress, IconButton } from "@mui/material";
import { useContext, useRef, useState } from "react";
import { UserContext } from "../../context/userContext";
import { SnackBarContext } from "../../context/snackBarContext";
import { UseGetPresignedURLUpload, uploadFileToS3 } from "../../lib/s3Client";
import { Edit } from "@mui/icons-material";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";

export const ProfilePictureComponent = () => {
   const { user, userProfilePicture, setUserProfilePicture } = useContext(UserContext);
   const inputRef: any = useRef(null);
   const { showSnackBar } = useContext(SnackBarContext);
   const [isLoading, setIsLoading] = useState(false);

   const handleImageChange = async (event) => {
      const tempPic = userProfilePicture;
      setIsLoading(true);
      try {
         if (event.target.files && event.target.files[0]) {
            if (event.target.files.length === 0) {
               inputRef.current.value = null;
               setIsLoading(false);
               return showSnackBar("Favor de adjuntar un documento", true);
            }
            if (
               event.target.files[0].type !== "image/png" &&
               event.target.files[0].type !== "image/jpg" &&
               event.target.files[0].type !== "image/jpeg" &&
               event.target.files[0].type !== "image/webp"
            ) {
               inputRef.current.value = null;
               setIsLoading(false);
               return showSnackBar("El archivo no es una imagen", true);
            }
            if (event.target.files[0].size > 3000000) {
               inputRef.current.value = null;
               setIsLoading(false);
               return showSnackBar("El archivo no puede pesar más de 3MB", true);
            }
            const fileUrl = await UseGetPresignedURLUpload({
               bucket: "files-lecosy",
               folder: `users/${user.id}`,
               name: "profile.png",
            });
            await uploadFileToS3(fileUrl.data, event.target.files[0]);
            const reader = new FileReader();
            reader.onload = (e) => setUserProfilePicture(e.target.result);
            reader.readAsDataURL(event.target.files[0]);
            showSnackBar("La foto de perfil se ha actualizado correctamente", false);
         }
      } catch (error) {
         setUserProfilePicture(tempPic);
         showSnackBar("Error al actualizar foto de perfil", false);
      }
      setIsLoading(false);
   };

   return (
      <div style={{ position: "relative", display: "inline-block" }}>
         <Avatar
            key={userProfilePicture}
            src={userProfilePicture}
            sx={{
               width: 180,
               height: 180,
               border: 1,
               borderColor: "#E3E2E2",
               bgcolor: !userProfilePicture ? "#D9D9D9" : "transparent",
            }}
            imgProps={{ sx: { objectFit: "cover" } }}
         >
            <AddPhotoAlternateIcon sx={{ fontSize: 65, color: "#A3A3A3" }} />
         </Avatar>
         <input
            type="file"
            ref={inputRef}
            style={{ display: "none", cursor: "pointer" }}
            accept="image/*"
            onChange={handleImageChange}
         />
         <Box
            sx={{
               bgcolor: "#3F5063",
               borderRadius: 5,
               position: "absolute",
               bottom: 10,
               right: 10,
               p: 0.5,
               ":hover": { bgcolor: "#3F5063" },
            }}
         >
            {isLoading ? (
               <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", p: 1 }}>
                  <CircularProgress sx={{ color: "white" }} size={18} />
               </Box>
            ) : (
               <IconButton onClick={() => inputRef.current.click()}>
                  <Edit sx={{ color: "white", fontSize: 18 }} />
               </IconButton>
            )}
         </Box>
      </div>
   );
};
