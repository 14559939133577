import { imageLogo } from "./globalConst";

export const signatures = (complimentOfficer: string, internAuditor: string, generalDirection: string) => {
   return {
      margin: [25, 0, 0, 0],
      table: {
         widths: [200, 200],
         heights: 40,
         body: [
            [
               {
                  table: {
                     widths: [130, 100],
                     heights: [50, 10, 10],

                     body: [
                        [
                           {
                              text: "",
                              fontSize: 9,
                              border: [false, false, false, false],
                           },
                        ],
                        [
                           {
                              text: "Oficial de cumplimiento",
                              alignment: "center",
                              fontSize: 9,
                              border: [false, true, false, false],
                           },
                        ],
                        [
                           {
                              text: `C. ${complimentOfficer}`,
                              alignment: "center",
                              fontSize: 9,
                              bold: true,
                              border: [false, false, false, false],
                           },
                        ],
                     ],
                  },
               },
               {
                  margin: [60, 0, 0, 0],
                  table: {
                     widths: [130, 100],
                     heights: [50, 10, 10],
                     body: [
                        [
                           {
                              text: "",
                              fontSize: 9,
                              border: [false, false, false, false],
                           },
                        ],
                        [
                           {
                              text: "Auditor Interno",
                              alignment: "center",
                              fontSize: 9,
                              border: [false, true, false, false],
                           },
                        ],
                        [
                           {
                              text: `C. ${internAuditor}`,
                              alignment: "center",
                              fontSize: 9,
                              bold: true,
                              border: [false, false, false, false],
                           },
                        ],
                     ],
                  },
               },
            ],
            [
               {
                  colSpan: 2,
                  margin: [135, 0, 0, 0],
                  table: {
                     widths: [130, 100],
                     heights: [50, 10, 10],
                     body: [
                        [
                           {
                              text: "",
                              fontSize: 9,
                              border: [false, false, false, false],
                           },
                        ],
                        [
                           {
                              text: "Dirección General",
                              alignment: "center",
                              fontSize: 9,
                              border: [false, true, false, false],
                           },
                        ],
                        [
                           {
                              text: `C. ${generalDirection}`,
                              alignment: "center",
                              fontSize: 9,
                              bold: true,
                              border: [false, false, false, false],
                           },
                        ],
                     ],
                  },
               },
            ],
         ],
      },
      layout: "noBorders",
   };
};

export function header(name: string) {
   return {
      columns: [
         {
            image: imageLogo,
            width: 100,
            absolutePosition: { x: 50, y: 30 },
         },
         {
            canvas: [
               {
                  type: "rect",
                  x: 100,
                  y: 55,
                  w: 450,
                  h: 4,
                  color: "#152c44",
               },
            ],
         },
         {
            text: name,
            bold: true,
            fontSize: 15,
            color: "#152c44",
            absolutePosition: { x: 220, y: 35 },
         },
      ],
   };
}

export const headerSpeech = (header: string) => {
   return [
      {
         image: imageLogo,
         width: 100,
         absolutePosition: { x: 50, y: 30 },
      },
      {
         canvas: [
            {
               type: "rect",
               x: 178,
               y: 14,
               w: 450,
               h: 4,
               color: "#152c44",
            },
         ],
      },
      {
         text: `${header}`,

         bold: true,
         fontSize: 15,
         color: "#152c44",
         absolutePosition: { x: 218, y: 35 },
      },
   ];
};

export function footer(evidence: boolean) {
   if (!evidence) {
      return [
         {
            margin: [90, 50, 90, 10],
            table: {
               widths: [398],
               heights: [2],
               body: [
                  [
                     {
                        text: "(Nombre completo y firma)",
                        border: [false, true, false, false],
                        alignment: "center",
                        fontSize: 10.5,
                     },
                  ],
                  [
                     {
                        text: "Está entrevista se apega a la debida confidencialidad como evidencia de la denuncia.",
                        border: [],
                        alignment: "center",
                        fontSize: 10,
                        color: "#A0A0A0",
                        margin: [0, 10],
                     },
                  ],
               ],
            },
         },
      ];
   } else {
      return {};
   }
}

export const textDefinition = (
   text: any,
   margin: number[],
   bold = false,
   color?: string,
   fontSize?: number,
   pageBreak = false,
   width?: number
) => {
   return {
      text: `${text}`,
      fontSize: fontSize ? fontSize : 11,
      color: color ? color : "#00000",
      bold: bold,
      alignment: "justify",
      margin: margin,
      lineHeight: 1.1,
      width: width,
      pageBreak: pageBreak ? "after" : "",
   };
};
