import {
   Box,
   CircularProgress,
   IconButton,
   InputAdornment,
   List,
   ListItem,
   ListItemButton,
   Stack,
   TextField,
   Typography,
} from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getFilesByFilterId, getUrlS3GC } from "../../../../lib/usersBEClient";
import { Close, InsertDriveFile, Search } from "@mui/icons-material";
import { useNavigate, useParams } from "react-router-dom";
import { DateFilter } from "./DateFilter";
import { FileVisualizer } from "./FileVisualizer";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";

const useFilter = () => {
   const { id } = useParams();
   const [text, setText] = useState("");
   const [data, setData] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [reload, setReload] = useState(false);

   useEffect(() => {
      const fetchData = async () => {
         setIsLoading(true);
         const response = await getFilesByFilterId(text, id);
         setData(response);
         setIsLoading(false);
      };
      if (text !== "") fetchData();
      // eslint-disable-next-line
   }, [reload]);

   return { data, text, setText, setReload, reload, isLoading };
};

export const Filters = () => {
   const { setText, data, setReload, reload, isLoading, text } = useFilter();
   const [hidden, setHidden] = useState(true);
   const [audio, setAudio] = useState(false);
   const navigate = useNavigate();
   const [selectedFile, setSelectedFile] = useState(null);
   const [fileNames, setFileNames] = useState("");
   const [openPDF, setOpenPDF] = useState(false);
   const [openImage, setOpenImage] = useState(false);
   const [openVideo, setOpenVideo] = useState(false);
   const [openOther, setOpenOther] = useState(false);
   const [pdfUrl, setPdfUrl] = useState("");
   const { companySelected } = useContext(GovernanceContext);

   const handleChange = (event) => {
      setText(event.target.value);
      setHidden(true);
   };

   const handleSearch = () => {
      setReload(!reload);
      setHidden(false);
   };

   const getFileSignedUrl = async (folder: string, fileKey: string, fileType: string) => {
      const imagesUrlResponse = await getUrlS3GC("files-lecosy", { folder: folder }, fileKey);
      if (fileType === "application/pdf") {
         setOpenPDF(true);
      } else if (fileType.startsWith("image")) {
         setOpenImage(true);
      } else if (fileType.startsWith("video")) {
         setOpenVideo(true);
         setAudio(false);
      } else if (fileType.startsWith("audio")) {
         setOpenVideo(true);
         setAudio(true);
      } else {
         setOpenOther(true);
      }
      setFileNames(fileKey);
      setPdfUrl(imagesUrlResponse);
   };

   return (
      <Box sx={{ display: "flex", columnGap: 2, alignItems: "center" }}>
         <DateFilter />
         <Box sx={{ flex: 3, position: "relative" }}>
            <TextField
               fullWidth
               size="small"
               onChange={handleChange}
               id="SearchBar"
               sx={{ bgcolor: "white" }}
               label="Buscar"
               InputProps={{
                  endAdornment: (
                     <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleSearch} edge="end">
                           <Search />
                        </IconButton>
                     </InputAdornment>
                  ),
               }}
            />
            {(text !== "" && !hidden) || isLoading ? (
               <Box
                  sx={{
                     bgcolor: "white",
                     borderRadius: 2,
                     boxShadow: 1,
                     p: 1,
                     position: "absolute",
                     zIndex: 1,
                     width: "100%",
                     m: 0,
                  }}
               >
                  {isLoading ? (
                     <CircularProgress />
                  ) : data.length > 0 ? (
                     <Box sx={{ overflowY: "auto", maxHeight: 300 }}>
                        <Box sx={{ display: "flex", justifyContent: "flex-end", px: 1 }}>
                           <IconButton onClick={() => setHidden(true)}>
                              <Close sx={{ color: "red" }} />
                           </IconButton>
                        </Box>
                        <List disablePadding dense>
                           {data.map((value) => (
                              <ListItem
                                 disablePadding
                                 onClick={() => {
                                    setSelectedFile(value);
                                    getFileSignedUrl(value.fileDirection, value.name, value.type);
                                 }}
                              >
                                 <ListItemButton>
                                    <Box
                                       sx={{
                                          display: "flex",
                                          justifyContent: "space-between",
                                          width: "100%",
                                          alignItems: "center",
                                       }}
                                    >
                                       <Stack>
                                          <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                                             <InsertDriveFile sx={{ color: "black", fontSize: 25 }} />
                                             <Typography variant="subtitle2">{value.name}</Typography>
                                          </Box>
                                          <Typography variant="caption">
                                             {value.owner.firstName + " " + value.owner.lastName}
                                          </Typography>
                                       </Stack>
                                       <Typography
                                          variant="subtitle2"
                                          onClick={(event) => {
                                             if (value.governingBody) {
                                                navigate(
                                                   `/gobierno-corporativo/${companySelected}/${value.governingBody}/documentos/folder/${value.folder._id}`
                                                );
                                                setHidden(true);
                                                event.stopPropagation();
                                             } else {
                                                navigate(
                                                   `/gobierno-corporativo/${companySelected}/mi-cuenta/archivos/folder/${value.folder._id}`
                                                );
                                                setHidden(true);
                                                event.stopPropagation();
                                             }
                                          }}
                                          sx={{
                                             textDecoration: "underline",
                                             "&:hover": {
                                                fontWeight: 600,
                                             },
                                          }}
                                       >
                                          Directorio: {value.folder.name}
                                       </Typography>
                                    </Box>
                                 </ListItemButton>
                              </ListItem>
                           ))}
                        </List>
                     </Box>
                  ) : (
                     <Typography align="center">Sin resultados</Typography>
                  )}
               </Box>
            ) : null}
         </Box>
         <FileVisualizer
            audio={audio}
            fileNames={fileNames}
            pdfUrl={pdfUrl}
            setOpenImage={setOpenImage}
            setOpenOther={setOpenOther}
            setOpenPDF={setOpenPDF}
            setOpenVideo={setOpenVideo}
            openImage={openImage}
            openOther={openOther}
            openPDF={openPDF}
            openVideo={openVideo}
            selectedFile={selectedFile}
         />
      </Box>
   );
};
