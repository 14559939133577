import { Box, TextField } from "@mui/material";
import { useFetchData } from "./NotesContainer";
import { useState } from "react";

export const NoteTextFilterComponent = () => {
   const [value, setValue] = useState("");
   const { setTextToFilter } = useFetchData();

   return (
      <>
         <Box sx={{ display: "flex", flex: 1, mx: 2 }}>
            <TextField
               type="text"
               onChange={(e) => {
                  setValue(e.target.value);
                  setTextToFilter(e.target.value);
               }}
               value={value}
               size="small"
               fullWidth
               sx={{ bgcolor: "white" }}
               label="Buscar"
            />
         </Box>
      </>
   );
};
