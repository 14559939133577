import { Avatar, Box, Checkbox, Grid, TextField, Typography } from "@mui/material";
import { useContext, useState } from "react";
import { EvaluationContext } from "../../../../context/governanceContext/evaluationContext";
import { UserContext } from "../../../../context/userContext";

export const QuestionComponent = ({ question, index }) => {
   const { addNewResponse, activeCouncil, activePersonEvaluation, evaluation } = useContext(EvaluationContext);
   const { user } = useContext(UserContext);
   const [response, setResponse] = useState(0);

   const handleChange = (number: number) => {
      setResponse(number);
      if (activePersonEvaluation) {
         addNewResponse({
            question: question._id,
            rangeAnswer: number,
            governance: activeCouncil._id,
            user: user.id,
            toPerson: true,
            memberEvaluation: activePersonEvaluation.user,
            performanceEvaluation: evaluation._id,
         });
      } else {
         addNewResponse({
            question: question._id,
            rangeAnswer: number,
            governance: activeCouncil._id,
            user: user.id,
            toPerson: false,
            performanceEvaluation: evaluation._id,
         });
      }
   };

   const handleChangeText = (event) => {
      const value = event.target.value;
      if (value === "") return;
      if (activePersonEvaluation) {
         addNewResponse({
            question: question._id,
            answer: value,
            governance: activeCouncil._id,
            user: user.id,
            toPerson: true,
            memberEvaluation: activePersonEvaluation.user,
            performanceEvaluation: evaluation._id,
         });
      } else {
         addNewResponse({
            question: question._id,
            answer: value,
            governance: activeCouncil._id,
            user: user.id,
            toPerson: false,
            performanceEvaluation: evaluation._id,
         });
      }
   };

   return (
      <Grid container sx={{ border: "1px solid #D9D9D9", my: 3 }}>
         <Grid
            item
            container
            xs={12}
            sx={{ border: "1px solid #D9D9D9", p: 1 }}
            justifyContent="center"
            alignItems="center"
         >
            <Grid item xs={1}>
               <Box sx={{ display: "center", justifyContent: "center" }}>
                  <Avatar sx={{ color: "#162c44", bgcolor: "#CBD5E0", width: 24, height: 24, fontSize: 12 }}>
                     {index}
                  </Avatar>
               </Box>
            </Grid>
            <Grid item xs={11} sx={{ px: 2 }}>
               <Box>{question.question}</Box>
            </Grid>
         </Grid>
         <Grid item container xs={12} justifyContent="center" alignItems="center" sx={{ p: 2 }}>
            {question.graphType === "additional" ? (
               <TextField fullWidth multiline rows={2} onBlur={handleChangeText} />
            ) : (
               <>
                  <ResponseComponent number={1} selected={response} handleChange={handleChange} />
                  <ResponseComponent number={2} selected={response} handleChange={handleChange} />
                  <ResponseComponent number={3} selected={response} handleChange={handleChange} />
                  <ResponseComponent number={4} selected={response} handleChange={handleChange} />
                  <ResponseComponent number={5} selected={response} handleChange={handleChange} />
                  <ResponseComponent number={6} selected={response} handleChange={handleChange} />
               </>
            )}
         </Grid>
      </Grid>
   );
};

const ResponseComponent = ({ number, selected, handleChange }) => {
   return (
      <Grid item xs={2}>
         <Box sx={{ display: "center", justifyContent: "center", alignItems: "center", columnGap: 1 }}>
            <Typography>{number}</Typography>
            <Checkbox checked={selected === number} onChange={() => handleChange(number)} />
         </Box>
      </Grid>
   );
};
