import { Box, Typography, Divider } from "@mui/material";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useContext } from "react";
import { UserContext } from "../context/userContext";

interface NotificationBarProps {
   head: string | React.ReactNode;
   content?: React.ReactNode;
}

export const NotificationBar = (props: NotificationBarProps) => {
   const { setPath } = useContext(UserContext);
   return (
      <>
         <Box
            sx={{
               display: "flex",
               px: 3,
               borderRadius: 4,
               justifyContent: "space-between",
            }}
         >
            <Box sx={{ display: "flex", alignItems: "center" }}>
               <Button
                  variant="contained"
                  sx={{ minWidth: 5, height: 30, backgroundColor: "#142c44" }}
                  onClick={() => setPath(-1)}
               >
                  <ArrowBackIcon sx={{ width: 20 }} />
               </Button>
               <Typography variant="h6" sx={{ ml: 1 }}>
                  {props.head}
               </Typography>
            </Box>
            {props.content && props.content}
         </Box>
         <Divider sx={{ my: 1 }} variant="middle" />
      </>
   );
};
