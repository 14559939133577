import { Box } from "@mui/material";
import { AgreementsAndCommitmentsComponent } from "../../components/Gobierno corporativo/AgreementsAndCommitmentsScreen/AgreementsAndCommitmentsComponent";

export const AgreementsAndCommitmentsScreen = () => {
   return (
      <Box
         sx={{
            width: "100%",
         }}
      >
         <AgreementsAndCommitmentsComponent />
      </Box>
   );
};
