import { Typography, Box } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../context/userContext";
import { ListItemComponent } from "./ListItemComponent";
import { beneficiaryControllerIcon, governanceIcon } from "../assets/icons/DrawerAppScreenIcons";

const menuOptions = [
   {
      lable: "Gobierno corporativo",
      path: "/gobierno-corporativo",
      icon: <img src={governanceIcon} width={25} height={25} />,
   },
   {
      lable: "Beneficiario controlador",
      path: "/beneficiario-controlador",
      icon: <img src={beneficiaryControllerIcon} width={25} height={25} />,
   },
];

interface ComplaintChannelMenuProps {
   open: boolean;
   setOpen: Function;
}

export const GovernanceMenu = (props: ComplaintChannelMenuProps) => {
   const { openCompanySelector, setOpenCompanySelector, verifyAccess } = useContext(UserContext);
   const gcPermission = verifyAccess("Gobierno corporativo", true);
   const beneficiaryPermission = verifyAccess("Beneficiario controlador", true);

   return (
      <>
         <Box>
            <Typography sx={{ px: 3, fontWeight: 520, color: "gray", fontSize: 14, pt: 2, pb: 1 }}>
               {props.open ? "SISTEMA DE GOBIERNO" : "G"}
            </Typography>
            {menuOptions.map((item, index) => {
               if ((index === 0 && !gcPermission) || (index === 1 && !beneficiaryPermission)) {
                  return null;
               }

               return (
                  <ListItemComponent
                     item={item}
                     index={index}
                     open={props.open}
                     setOpen={props.setOpen}
                     key={index}
                     openModal={openCompanySelector}
                     setOpenModal={setOpenCompanySelector}
                  />
               );
            })}
         </Box>
      </>
   );
};
