import { Box, Grid, MenuItem, Button, Typography, Stack } from "@mui/material";
import { Formik, FormikProps } from "formik";
import { Form } from "react-router-dom";
import { BasicInformationSchema } from "../../../../lib/validations/inputSchemas";
import { InputSelectField } from "../../../Inputs/InputSelectField";
import { InputTextField } from "../../../Inputs/InputTextField";
import { useContext } from "react";
import { UserContext } from "../../../../context/userContext";
import { ProfilePictureComponent } from "../../../../screens/UserInformation/ProfilePictureComponent";
import { SnackBarContext } from "../../../../context/snackBarContext";
import { CreateProfileContext } from "../../../../context/beneficiaryContext/createProfileContext";

const gridRow = {
   display: "flex",
   alignItems: "center",
};

const genere = ["hombre", "mujer", "indefinido"];

export const NewUserBasicInformationForm = () => {
   const { user, userProfilePicture } = useContext(UserContext);
   const { handleNext, setNewUserDetails: setUserDetails } = useContext(CreateProfileContext);
   const { showSnackBar } = useContext(SnackBarContext);

   const initialValues = {
      userName: user.firstName,
      userLastName: user.lastName,
      email: user.email,
      gender: "",
      phone: user.phoneNumber,
      dob: "",
      birthLocation: "",
      address: "",
   };

   const handleSubmit = (values) => {
      if (!userProfilePicture) return showSnackBar("Favor de adjuntar fotografía", true);
      handleNext();
      setUserDetails(values);
   };

   return (
      <Box>
         <Formik initialValues={initialValues} validationSchema={BasicInformationSchema} onSubmit={handleSubmit}>
            {(formProps: FormikProps<any>) => (
               <Form>
                  <Box
                     sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "space-between",
                        py: 1,
                     }}
                  >
                     <Grid
                        container
                        rowSpacing={2}
                        columnSpacing={2}
                        sx={{
                           display: "flex",
                           alignItems: "center",
                           px: 6,
                           overflowY: "auto",
                           height: "50vh",
                        }}
                     >
                        <Grid item xs={2} sx={gridRow}>
                           <Typography sx={{ color: "#787486", fontWeight: 600, fontSize: 15 }}>Nombre</Typography>
                        </Grid>
                        <Grid item xs={10}>
                           <InputTextField
                              id="userName"
                              label="Nombre"
                              name="userName"
                              type="string"
                              size={"small"}
                              disabled
                              fullWidth
                              sx={{ maxWidth: 500 }}
                           />
                        </Grid>
                        <Grid item xs={2} sx={gridRow}>
                           <Typography sx={{ color: "#787486", fontWeight: 600, fontSize: 15 }}>Apellido</Typography>
                        </Grid>
                        <Grid item xs={10}>
                           <InputTextField
                              id="userLastName"
                              label="Apellido"
                              name="userLastName"
                              type="string"
                              size={"small"}
                              disabled
                              fullWidth
                              sx={{ maxWidth: 500 }}
                           />
                        </Grid>
                        <Grid item xs={2} sx={gridRow}>
                           <Typography sx={{ color: "#787486", fontWeight: 600, fontSize: 15 }}>
                              Correo electrónico
                           </Typography>
                        </Grid>
                        <Grid item xs={10}>
                           <InputTextField
                              id="email"
                              label="Correo electrónico"
                              name="email"
                              type="string"
                              size={"small"}
                              disabled
                              fullWidth
                              sx={{ maxWidth: 500 }}
                           />
                        </Grid>
                        <Grid item xs={2} sx={gridRow}>
                           <Typography sx={{ color: "#787486", fontWeight: 600, fontSize: 15 }}>Género</Typography>
                        </Grid>
                        <Grid item xs={10}>
                           <InputSelectField
                              label="Genero"
                              labelId="gender"
                              id="gender"
                              name="gender"
                              variant="outlined"
                              size={"small"}
                              fullWidth
                              sx={{ maxWidth: 500 }}
                           >
                              {genere.map((e, index) => (
                                 <MenuItem key={index} value={e}>
                                    {e.charAt(0).toUpperCase() + e.slice(1)}
                                 </MenuItem>
                              ))}
                           </InputSelectField>
                        </Grid>
                        <Grid item xs={2} sx={gridRow}>
                           <Typography sx={{ color: "#787486", fontWeight: 600, fontSize: 15 }}>
                              Número de celular
                           </Typography>
                        </Grid>
                        <Grid item xs={10}>
                           <InputTextField
                              id="phone"
                              label="Número de celular"
                              name="phone"
                              type="string"
                              size={"small"}
                              fullWidth
                              disabled
                              sx={{ maxWidth: 500 }}
                           />
                        </Grid>
                        <Grid item xs={2} sx={gridRow}>
                           <Typography sx={{ color: "#787486", fontWeight: 600, fontSize: 15 }}>
                              Fecha de nacimiento
                           </Typography>
                        </Grid>
                        <Grid item xs={10}>
                           <InputTextField
                              id="dob"
                              name="dob"
                              type="date"
                              size={"small"}
                              fullWidth
                              sx={{ maxWidth: 500 }}
                           />
                        </Grid>
                        <Grid item xs={2} sx={gridRow}>
                           <Typography sx={{ color: "#787486", fontWeight: 600, fontSize: 15 }}>
                              Lugar de nacimiento
                           </Typography>
                        </Grid>
                        <Grid item xs={10}>
                           <InputTextField
                              id="birthLocation"
                              label="Lugar de nacimiento"
                              name="birthLocation"
                              type="string"
                              size={"small"}
                              fullWidth
                              sx={{ maxWidth: 500 }}
                           />
                        </Grid>
                        <Grid item xs={2} sx={gridRow}>
                           <Typography sx={{ color: "#787486", fontWeight: 600, fontSize: 15 }}>Domicilio</Typography>
                        </Grid>
                        <Grid item xs={10}>
                           <InputTextField
                              id="address"
                              label="Domicilio"
                              name="address"
                              type="string"
                              size={"small"}
                              fullWidth
                              sx={{ maxWidth: 500 }}
                           />
                        </Grid>
                        <Box sx={{ position: "relative", display: "inline-block", flex: 1, mx: 2, my: 4 }}>
                           <Box justifyContent={"center"} alignItems={"center"} display={"flex"}>
                              <ProfilePictureComponent />
                           </Box>
                           <Box sx={{ position: "absolute", top: 0, left: 0 }}>
                              <Typography sx={{ color: "#787486", fontWeight: 600, fontSize: 15 }}>
                                 Adjuntar fotografía
                              </Typography>
                           </Box>
                        </Box>
                     </Grid>
                     <Stack
                        sx={{
                           alignItems: "center",
                           py: 2,
                        }}
                     >
                        <Button variant="contained" onClick={() => formProps.submitForm()} sx={{ width: 300 }}>
                           Continuar
                        </Button>
                     </Stack>
                  </Box>
               </Form>
            )}
         </Formik>
      </Box>
   );
};
