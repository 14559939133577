import {
   Box,
   Button,
   CircularProgress,
   Collapse,
   Divider,
   Grid,
   IconButton,
   Stack,
   Toolbar,
   Tooltip,
   Typography,
} from "@mui/material";
import { Form, Formik, FormikProps } from "formik";
import { InputTextField } from "../Inputs/InputTextField";
import { ChevronRight, Close, Delete, MoreVert } from "@mui/icons-material";
import { useContext, useEffect, useMemo, useState } from "react";
import { userGoverningBody } from "../../lib/validations/inputSchemas";
import { SnackBarContext } from "../../context/snackBarContext";
import { CargoSelectorComponent } from "./CargoSelectorComponent";
import { UserContext } from "../../context/userContext";
import { CharacterSelectComponent } from "../Inputs/CharacterSelectComponent";
import { deleteUserGovernBody, modifyGovernBodyUser, replaceUserOnGovernBody } from "../../lib/gobCorpBEClient";
import { ConfirmationModal } from "../ConfirmationModal";
import { UserSearchSelector } from "../Inputs/UserSearchSelector";
import {
   deleteGovernRol,
   getAllResources,
   getGroupUsers,
   getRoleByCharge,
   getUsersDetails,
   moveGovernBodyRole,
   updateGovernBodyRole,
} from "../../lib/usersBEClient";
import { BodyUserFormat } from "./GoverningBodiesComponent";

interface EditGCUserDrawerProps {
   company?: string;
   group?: string;
   userData: any;
   state: boolean;
   setState: Function;
   bodyUsers: BodyUserFormat[];
   bodyData: any;
   setSeed: Function;
}

const UsefetchCompanyUsers = (bodyUsers, group) => {
   const { companySelected } = useContext(UserContext);
   const [isLoading, setIsLoading] = useState(false);
   const [users, setUsers] = useState([]);

   useEffect(() => {
      const fetchCompanyUsers = async () => {
         setIsLoading(true);
         let usersData;
         if (group) usersData = await getGroupUsers(group);
         else if (companySelected)
            usersData = [...companySelected.company_details.users, companySelected.company_details.admin];

         const users = (usersData as any[]).map((user) => ({
            _id: user._id,
            firstName: user.firstName,
            lastName: user.lastName,
            email: user.email,
            phoneNumber: user.phoneNumber,
         }));

         const usersIds = users.map((u) => u._id);
         const usersDetails = await getUsersDetails(usersIds);

         let usersFormat = [];
         for (let t = 0; t < users.length; t++) {
            usersFormat.push({
               ...usersDetails.find((e) => e.user === users[t]._id),
               ...users[t],
            });
         }
         const bodyUsersIds = bodyUsers.map((e) => e.user);
         const filterUsers: BodyUserFormat[] = usersFormat.filter(
            (user) => user.disabled === false && !bodyUsersIds.includes(user._id)
         );
         setUsers(filterUsers);
         setIsLoading(false);
      };
      fetchCompanyUsers();
   }, [bodyUsers]);

   return { users, isLoading };
};

interface implementationData {
   check: boolean;
   id: string;
}

export const EditGCUserDrawer = (props: EditGCUserDrawerProps) => {
   const { users, isLoading } = UsefetchCompanyUsers(props.bodyUsers, props.group);
   const { showSnackBar } = useContext(SnackBarContext);
   const [replacement, setReplacement] = useState(false);
   const [openUserDeleteCollapse, setOpenUserDeleteCollapse] = useState(false);
   const [submitLoading, setSubmitLoading] = useState(false);
   const [loadingDelete, setLoadingDelete] = useState(false);

   const implementationData = useMemo(() => {
      if (props.userData.chargeName === "Usuario de implementacion") {
         const data: implementationData = { check: true, id: props.userData.charge };
         return data;
      } else {
         const data: implementationData = { check: false, id: "" };
         return data;
      }
   }, [props.userData]);

   const oldUser = useMemo(() => {
      if (replacement) {
         const id = props.userData.userId;
         return id;
      }
   }, [replacement]);

   function handleOpen() {
      props.setState(!props.state);
   }

   const handleDetele = async () => {
      try {
         const resourceResponse = await getAllResources();
         const resourceId = resourceResponse.find((r) => r.name === "Mi Lecosy")._id;
         if (!resourceId) return showSnackBar("Error al eliminado usuario", true);
         const deleteUser = await deleteUserGovernBody(
            props.userData.bodyId,
            props.userData.userId,
            props.group ?? props.company,
            resourceId
         );
         await deleteGovernRol(deleteUser.user, deleteUser.charge, props.company, props.userData.bodyId);
         setLoadingDelete(false);
         props.setState(false);
         showSnackBar("Usuario borrado correctamente", false);
         props.setSeed(Math.random());
      } catch (error) {
         console.log(error);
         showSnackBar("Error al borrar usuario", true);
      }
   };

   const handleCancel = () => {
      replacement ? setReplacement(false) : props.setState(false);
   };

   const handleSubmit = async (values) => {
      try {
         setSubmitLoading(true);
         const resourceResponse = await getAllResources();
         const resourceId = resourceResponse.find((r) => r.name === "Mi Lecosy")._id;
         if (!resourceId) return showSnackBar("Error al eliminado usuario", true);
         if (replacement) {
            //reemplazo de user en el gobBody
            const userData = {
               user: values.userId,
               charge: values.charge,
               characters: values.characters,
            };
            await replaceUserOnGovernBody(props.bodyData.bodyId, userData, oldUser);

            const roleId = await getRoleByCharge(values.charge);
            // agregar rol data a usuario
            const roleData = {
               role: roleId._id,
               company: props.company,
               group: props.group,
               user: values.userId,
            };
            await moveGovernBodyRole(roleData, oldUser, props.bodyData.bodyId);
         } else {
            if (implementationData.check) {
               const data = {
                  user: values.userId,
                  charge: values.charge,
                  characters: values.characters,
               };
               await modifyGovernBodyUser(props.bodyData.bodyId, data, props.group ?? props.company, resourceId);

               const roleId = await getRoleByCharge(values.charge);

               const roleData = {
                  role: roleId._id,
                  company: props.company,
                  user: values.userId,
                  implementationId: implementationData.id,
               };
               await updateGovernBodyRole(roleData);
            } else {
               const data = {
                  user: values.userId,
                  characters: values.characters,
               };
               await modifyGovernBodyUser(props.bodyData.bodyId, data, props.group ?? props.company, resourceId);
            }
         }
         props.setState(!props.state);
         setSubmitLoading(false);
         showSnackBar(replacement ? "Usuario reemplazado correctamente" : "Usuario editado correctamente", false);
         if (props.setSeed) props.setSeed(Math.random());
      } catch (error) {
         console.log(error);
         showSnackBar(replacement ? "Error al reemplazar usuario" : "Error al editar usuario", true);
      }
   };

   return (
      <>
         <Toolbar />
         <Box sx={{ display: "flex", maxHeight: "100vh", height: "100%", width: 450 }}>
            <Box
               display="flex"
               alignItems="center"
               onClick={handleOpen}
               sx={{
                  "&:hover": {
                     bgcolor: "#F7F7F7",
                  },
               }}
            >
               <ChevronRight fontSize="small" />
            </Box>
            <Box sx={{ display: "flex", flexDirection: "column", mt: 2 }}>
               <Box
                  sx={{
                     display: "flex",
                     justifyContent: "space-between",
                     alignItems: "center",
                     mt: 1,
                  }}
               >
                  <Typography variant="h5" sx={{ pl: 1 }}>
                     {replacement ? "Reemplazo de usuario" : "Modifica información de usuario"}
                  </Typography>
                  {props.userData.chargeName.toLowerCase().includes("presidente") ||
                  props.userData.chargeName.includes("Secretario") ||
                  replacement ||
                  props.bodyUsers.length === 1 ? null : (
                     <IconButton onClick={() => setOpenUserDeleteCollapse(!openUserDeleteCollapse)}>
                        {!openUserDeleteCollapse ? <MoreVert /> : <Close />}
                     </IconButton>
                  )}
               </Box>
               {props.userData.chargeName.toLowerCase().includes("presidente") ||
               props.userData.chargeName.includes("Secretario") ||
               replacement ||
               props.bodyUsers.length === 1 ? null : (
                  <Box sx={{ display: "flex", justifyContent: "flex-end", m: 1 }}>
                     <Collapse in={openUserDeleteCollapse}>
                        <Button
                           color="error"
                           onClick={() => {
                              setLoadingDelete(true);
                           }}
                           variant="outlined"
                        >
                           <Typography>Eliminar usuario</Typography>
                           <Delete />
                        </Button>
                     </Collapse>
                  </Box>
               )}
               <Divider />
               <Box sx={{ p: 1, mx: 1 }}>
                  <Formik
                     initialValues={{
                        userId: props.userData.userId,
                        firstName: props.userData.firstName,
                        lastName: props.userData.lastName,
                        phoneNumber: props.userData.phoneNumber,
                        email: props.userData.email,
                        characters: props.userData.characters,
                        charge: props.userData.charge,
                        specialP: props.userData.specialPermission,
                     }}
                     onSubmit={handleSubmit}
                     validationSchema={userGoverningBody}
                  >
                     {(formProps: FormikProps<any>) => (
                        <Form>
                           <Grid container sx={{ my: 2 }}>
                              {replacement ? (
                                 <Grid item xs={12} sx={{ p: 1 }}>
                                    <UserSearchSelector
                                       users={users}
                                       isLoading={isLoading}
                                       label="Añadir usuario existente"
                                       setSelectedUser={(user) => {
                                          formProps.setFieldValue("userId", user._id);
                                          formProps.setFieldValue("firstName", user.firstName);
                                          formProps.setFieldValue("lastName", user.lastName);
                                          formProps.setFieldValue("phoneNumber", user.phoneNumber);
                                          formProps.setFieldValue("email", user.email);
                                       }}
                                    />
                                 </Grid>
                              ) : null}

                              <Grid item xs={12} sx={{ p: 1, my: 0.5 }}>
                                 <InputTextField
                                    fullWidth
                                    id="firstName"
                                    type="text"
                                    name="firstName"
                                    size="small"
                                    label="Nombre(s)"
                                    disabled
                                 />
                              </Grid>
                              <Grid item xs={12} sx={{ p: 1, my: 0.5 }}>
                                 <InputTextField
                                    fullWidth
                                    id="lastName"
                                    type="text"
                                    name="lastName"
                                    size="small"
                                    label="Apellido(s)"
                                    disabled
                                 />
                              </Grid>
                              <Grid item xs={12} sx={{ p: 1, my: 0.5 }}>
                                 <InputTextField
                                    fullWidth
                                    id="phoneNumber"
                                    type="text"
                                    name="phoneNumber"
                                    size="small"
                                    label="Telefono"
                                    disabled
                                 />
                              </Grid>
                              <Grid item xs={12} sx={{ p: 1, my: 0.5 }}>
                                 <InputTextField
                                    fullWidth
                                    id="email"
                                    type="email"
                                    name="email"
                                    size="small"
                                    label="Correo electronico"
                                    disabled
                                 />
                              </Grid>

                              <Grid item xs={12} sx={{ p: 1 }}>
                                 <CargoSelectorComponent
                                    governData={props.bodyData}
                                    isLoading={isLoading}
                                    label="Cargo que desempeña"
                                    setSelectedRole={(role) => {
                                       if (!role) {
                                          formProps.setFieldValue("charge", props.userData.charge);
                                       } else {
                                          formProps.setFieldValue("charge", role._id);
                                       }
                                    }}
                                    companyUsers={users}
                                    governUsers={props.bodyUsers}
                                    disabled={!implementationData.check}
                                    implementationUser={implementationData.check}
                                    chargeName={props.userData.chargeName}
                                 />
                              </Grid>
                              {/* <Grid item xs={12}>
                                 <InputCheckBox
                                    id="specialP"
                                    name="specialP"
                                    label={
                                       <Stack direction={"row"} spacing={0.5}>
                                          <Typography fontSize={"13px"}>Otorgar permisos especiales</Typography>
                                          <Tooltip title={"Validar acuerdos, crear minutas"} sx={{ color: "#162c44" }}>
                                             <InfoIcon fontSize="small" />
                                          </Tooltip>
                                       </Stack>
                                    }
                                    fullWidth
                                 />
                              </Grid> */}
                              <Grid item xs={12} sx={{ my: 1 }}>
                                 <CharacterSelectComponent
                                    label="Caracteres"
                                    id="characters"
                                    name="characters"
                                    setCharacters={(c) => {
                                       formProps.setFieldValue("characters", typeof c === "string" ? c.split(",") : c);
                                    }}
                                 />
                              </Grid>
                              {replacement ? null : (
                                 <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
                                    <Button
                                       variant="contained"
                                       onClick={() => {
                                          setReplacement(true);
                                       }}
                                    >
                                       Reemplazar usuario
                                    </Button>
                                 </Grid>
                              )}
                           </Grid>
                           <Box sx={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
                              <Button
                                 variant="contained"
                                 onClick={() => {
                                    handleCancel();
                                 }}
                                 sx={{
                                    color: "black",
                                    height: 35,
                                    bgcolor: "#E5E6EB",
                                    boxShadow: 0,
                                    ":hover": { bgcolor: "#E5E6EB", boxShadow: 0 },
                                    mr: 2,
                                 }}
                              >
                                 {replacement ? "Cancelar reemplazo" : "Cancelar"}
                              </Button>
                              <Button
                                 variant="contained"
                                 type="submit"
                                 sx={{ height: 35, ":hover": { bgcolor: "success" } }}
                              >
                                 {!submitLoading ? "Guardar" : <CircularProgress size={24} color="success" />}
                              </Button>
                           </Box>
                        </Form>
                     )}
                  </Formik>
               </Box>
            </Box>
         </Box>
         <ConfirmationModal
            open={loadingDelete}
            setOpen={setLoadingDelete}
            title="Eliminar del comité"
            onConfirm={handleDetele}
            body={
               <Typography sx={{ textAlign: "center" }}>
                  {`¿Está seguro que desea eliminar a ${props.userData.firstName} ${props.userData.lastName} de ${props.userData.bodyTitle}?`}
               </Typography>
            }
            cancelButton={false}
         />
      </>
   );
};
