import { Box, Button, CircularProgress, Container, Stack } from "@mui/material";
import { NotificationBar } from "../../components/notificationsBar";
import { ProcessComplaintsTable } from "../../containers/processComplaintsTable";
import VerifyUserSession from "../../hooks/verifyUserSession";
import { getUrlS3 } from "../../lib/usersBEClient";
import { useContext } from "react";
import { ComplaintContext } from "../../context/complaintContext";

export const ProcessComplaintsScreen = () => {
   VerifyUserSession();
   const { loadingComplaints } = useContext(ComplaintContext);

   const handleDownloadDocument = async (documentName: string) => {
      const policesResponse = await getUrlS3("files-lecosy", { folder: `common` }, documentName);
      window.open(policesResponse, "_blank");
   };

   return (
      <Box sx={{ display: "flex", flexDirection: "column", p: 2 }}>
         <NotificationBar
            head="Denuncias en proceso"
            content={
               <Box sx={{ flexGrow: 0 }}>
                  <Stack direction={"row"} spacing={1}>
                     <Button variant="outlined" onClick={() => handleDownloadDocument("manual.pdf")}>
                        Manual
                     </Button>
                     <Button variant="outlined" onClick={() => handleDownloadDocument("polices.pdf")}>
                        Políticas del canal de denuncias
                     </Button>
                  </Stack>
               </Box>
            }
         />
         <Container maxWidth="xl">
            {!loadingComplaints ? (
               <Box sx={{ m: 1 }}>
                  <ProcessComplaintsTable />
               </Box>
            ) : (
               <Box width={"100%"} display={"flex"} justifyContent={"center"} py={2}>
                  <CircularProgress size={26} />
               </Box>
            )}
         </Container>
      </Box>
   );
};
