import { createRoutesFromElements, createBrowserRouter, RouterProvider } from "react-router-dom";
import CircularProgress from "@mui/material/CircularProgress";
import { Box, Typography } from "@mui/material";
import { useExternManagementRoutes } from "./routes/externManagementRoutes";

export default function App() {
   const externManagementRoutes = useExternManagementRoutes();

   const router = createBrowserRouter(createRoutesFromElements([externManagementRoutes]));

   return (
      <RouterProvider
         router={router}
         fallbackElement={
            <Box
               sx={{
                  height: "100vh",
                  width: "100vw",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
               }}
            >
               <Typography>Cargando, espere un momento...</Typography>
               <CircularProgress sx={{ color: "#162c44" }} />
            </Box>
         }
      />
   );
}
