import React, { useState } from "react";
import { Button, CircularProgress, Grid, Modal, Collapse, Typography, Box } from "@mui/material";
import DescriptionIcon from "@mui/icons-material/Description";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

interface ConfirmationModalProps {
   open: boolean;
   setOpen: Function;
   title?: string;
   body?: React.ReactNode;
   onConfirm: (event: React.MouseEvent<HTMLButtonElement>) => void;
   onCancel?: (event: React.MouseEvent<HTMLButtonElement>) => void;
   cancelButton?: boolean;
   buttonText?: string;
   isLoading?: boolean;
   documents?: string[];
   textJustify?: any;
   generic?: boolean;
   nota?: React.ReactNode;
   hideButtons?: boolean;
}

export const ConfirmationModal = (props: ConfirmationModalProps) => {
   const [docsList, setDocsList] = useState<boolean>(false);
   const handleClose = () => props.setOpen(false);

   return (
      <div>
         <Modal
            open={props.open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ display: "flex" }}
         >
            <Box sx={{ bgcolor: "white", m: "auto", maxWidth: 450, py: 1, px: 2, borderRadius: 1 }}>
               <Grid container>
                  <Grid item xs={12} sx={topRow}>
                     <label htmlFor="text" style={{ fontSize: "20px", fontWeight: "bold" }}>
                        {props.generic ? "Confirmar cambios" : props.title}
                     </label>
                  </Grid>

                  <Grid item xs={12} sx={middleRow}>
                     {props.generic ? (
                        <Typography sx={{ mt: 2 }}>¿Está seguro que desea guardar los cambios realizados?</Typography>
                     ) : (
                        <Box
                           sx={{
                              minWidth: "100%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              mt: 2,
                           }}
                        >
                           {props.body}
                        </Box>
                     )}
                     {/* <label
                        htmlFor="text"
                        style={{
                           fontSize: "14px",
                           paddingLeft: 10,
                           paddingRight: 10,
                           textAlign: props.textJustify || "center",
                           paddingTop: 20,
                           paddingBottom: 20,
                        }}
                     >
                        
                     </label> */}
                     <label
                        htmlFor="text"
                        style={{
                           fontStyle: "italic",
                           fontSize: 13,
                           color: "red",
                           paddingLeft: 10,
                           paddingRight: 10,
                           textAlign: props.textJustify || "center",
                           paddingBottom: 20,
                        }}
                     >
                        {props.nota}
                     </label>
                  </Grid>
                  {props.documents ? (
                     <Box>
                        <Grid item xs={12} sx={{ px: 1 }}>
                           <Button
                              size="small"
                              onClick={() => setDocsList(!docsList)}
                              sx={{ "&:hover": { bgcolor: "transparent" } }}
                           >
                              {docsList ? <KeyboardArrowDownIcon /> : <ArrowForwardIosIcon fontSize="inherit" />}
                              <Typography variant="caption" sx={{ ml: 1, py: 1 }}>
                                 Ver documentos de la sección
                              </Typography>
                           </Button>
                        </Grid>
                        <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end", px: 2 }}>
                           <Collapse in={docsList} sx={{ overflowY: "scroll", maxHeight: 150 }}>
                              {props.documents.map((doc) => {
                                 return (
                                    <Box
                                       key={doc}
                                       sx={{
                                          display: "flex",
                                          flexDirection: "row",
                                          justifyContent: "initial",
                                          pb: 1.5,
                                       }}
                                    >
                                       <DescriptionIcon fontSize="small" color="action" sx={{ mr: 1 }} />
                                       <Typography variant="subtitle2">{doc}</Typography>
                                    </Box>
                                 );
                              })}
                           </Collapse>
                        </Grid>
                     </Box>
                  ) : (
                     <></>
                  )}
                  {props.hideButtons ? null : (
                     <Box sx={{ display: "flex", justifyContent: "space-between", minWidth: "100%", p: 1 }}>
                        {!props.cancelButton ? (
                           <Box>
                              <Button
                                 onClick={(e) => {
                                    props.onCancel && props.onCancel(e);
                                    handleClose();
                                 }}
                                 sx={{ height: "100%" }}
                                 variant="outlined"
                              >
                                 Cancelar
                              </Button>
                           </Box>
                        ) : null}
                        <Box>
                           <Button
                              sx={{ height: "100%" }}
                              variant="contained"
                              color="primary"
                              onClick={props.cancelButton ? handleClose : props.onConfirm}
                              disabled={props.isLoading}
                           >
                              {!props.isLoading ? (
                                 props.buttonText ?? "Confirmar"
                              ) : (
                                 <CircularProgress size={24} sx={{ color: "#162c44" }} />
                              )}
                           </Button>
                        </Box>
                     </Box>
                  )}
               </Grid>
            </Box>
         </Modal>
      </div>
   );
};

const topRow = {
   display: "flex",
   justifyContent: "center",
   alignItems: "center",
   height: "32%",
};

const middleRow = {
   display: "flex",
   flexDirection: "column",
   justifyContent: "center",
   alignItems: "center",
   border: "solid #EBEBEB",
   borderWidth: "thin 0px",
   height: "36%",
};
