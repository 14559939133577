import { Divider, IconButton, Stack, Typography, Box, InputAdornment } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import { ConversationComponent } from "./SubComponent/ConversationComponent";
import { useContext, useState } from "react";
import { CreateConversation } from "./SubComponent/CreateConversation";
import { ChatContext } from "../../../context/governanceContext/ChatContext";
import { Form, Formik } from "formik";
import { InputTextField } from "../../Inputs/InputTextField";

export const LeftBarComponent = () => {
   const [startChat, setStartChat] = useState(false);
   const [search, setSearch] = useState(false);
   const { setSearchInput, setFoundConversations, setFoundMessages, setFoundGroups } = useContext(ChatContext);

   const handleSubmitSearch = (values) => {
      setSearchInput(values.search);
   };

   return (
      <Stack
         sx={{
            bgcolor: "white",
            width: { xl: "19vw", lg: "24vw" },
            maxWidth: "600px",
            height: "72vh",
            flex: 1,
            borderRadius: 2,
            boxShadow: 1,
         }}
      >
         <Stack direction={"row"} sx={{ justifyContent: "space-between", alignItems: "center", px: 2, pt: 2 }}>
            <Typography fontWeight={600}>{startChat ? "Iniciar chat" : "Conversaciones"}</Typography>
            <Stack direction={"row"} spacing={1.5}>
               <IconButton
                  sx={{ bgcolor: "#F7F9FB", borderRadius: 3 }}
                  onClick={() => {
                     setSearch(!search);
                     setStartChat(false);
                     if (search) {
                        setFoundConversations(null);
                        setFoundMessages(null);
                        setFoundGroups(null);
                     }
                  }}
               >
                  <SearchIcon sx={{ color: "#162c44" }} />
               </IconButton>
               <IconButton
                  sx={{ bgcolor: "#F7F9FB", borderRadius: 3 }}
                  onClick={() => {
                     setStartChat(!startChat);
                     setSearch(false);
                  }}
               >
                  <GroupsOutlinedIcon sx={{ color: "#162c44" }} />
               </IconButton>
            </Stack>
         </Stack>
         <Divider sx={{ mt: 2, mx: 2 }} />
         {startChat ? (
            <CreateConversation />
         ) : search ? (
            <>
               <Box
                  sx={{
                     px: 2,
                     pt: 2,
                  }}
               >
                  <Formik
                     initialValues={{
                        search: "",
                     }}
                     onSubmit={handleSubmitSearch}
                  >
                     <Form>
                        <InputTextField
                           label="Buscar..."
                           id="search"
                           name="search"
                           type="text"
                           fullWidth
                           size="small"
                           InputProps={{
                              startAdornment: (
                                 <InputAdornment position="start">
                                    <SearchIcon />
                                 </InputAdornment>
                              ),
                           }}
                        />
                     </Form>
                  </Formik>
               </Box>
               <Box
                  sx={{
                     maxHeight: "100vh",
                     overflowY: "auto",
                     mb: 1,
                  }}
               >
                  <ConversationComponent searchedConversation={true} />
               </Box>
            </>
         ) : (
            <Box
               sx={{
                  maxHeight: "100vh",
                  overflowY: "auto",
                  mb: 1,
               }}
            >
               <ConversationComponent searchedConversation={false} />
            </Box>
         )}
      </Stack>
   );
};
