import ChartDataLabels from "chartjs-plugin-datalabels";
import {
   Chart as ChartJS,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   Title,
   Tooltip,
   Legend,
   Filler,
   ArcElement,
} from "chart.js";
import { Doughnut } from "react-chartjs-2";

ChartJS.register(
   ArcElement,
   CategoryScale,
   LinearScale,
   PointElement,
   LineElement,
   ChartDataLabels,
   Title,
   Tooltip,
   Legend,
   Filler
);

export const MultiSeriePieChart = (props) => {
   return (
      <Doughnut
         ref={props.chartRef}
         data={{
            datasets: [
               {
                  label: "Mis datos",
                  data: props.scores,
                  backgroundColor: props.colors,
                  borderRadius: props.disableBorderRadius ? 0 : 8,
                  normalized: true,
               },
            ],
            labels: props.labels,
         }}
         options={{
            plugins: {
               datalabels: {
                  display: false,
                  textStrokeWidth: 1,
               },
               tooltip: { position: "nearest", enabled: false },
               legend: { display: false },
            },

            responsive: true,
            maintainAspectRatio: false,
            cutout: props.cutout,
         }}
      />
   );
};
