import { Box, Container, Modal } from "@mui/material";
import React, { useCallback, useContext, useEffect } from "react";
import { NotificationBar } from "../../components/notificationsBar";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { UserContext } from "../../context/userContext";
import { CompanySelectorModal2 } from "../../drawer/CompanySelectorModal2";
import CreateProfileBeneficiary from "../../components/Beneficiary Controller/CreateProfileBeneficiary";
import { CreateProfileContext } from "../../context/beneficiaryContext/createProfileContext";
import BeneficiaryRequestsModal from "../../components/Beneficiary Controller/BeneficiaryRequestsModal";

export const MainScreenBeneficiary = () => {
   const { openCompanySelector, setOpenCompanySelector, companySelected, companies, getCompanyDetails } =
      useContext(UserContext);
   const { openProfileModal, setOpenProfileModal, requestModal, setRequestModal } = useContext(CreateProfileContext);
   const { companyId } = useParams();
   const navigate = useNavigate();

   const getSelectedCompany = useCallback(() => {
      if (companySelected?._id && !companyId) {
         if (companies.length > 1) {
            navigate(`/beneficiario-controlador`);
            return;
         } else {
            navigate(`/beneficiario-controlador/${companySelected._id}/inicio`);
            return;
         }
      }
      if (!companySelected && companies?.length > 0) {
         if (companyId) {
            const companyFound = companies.find((c) => c._id === companyId);
            getCompanyDetails(companyFound?._id);
         } else {
            getCompanyDetails(companies[0]?._id);
         }
      }
   }, [companySelected, companies, companyId]);

   useEffect(() => {
      getSelectedCompany();
   }, [getSelectedCompany]);

   return (
      <Box p={2}>
         <NotificationBar head="Beneficiario controlador" />
         <Container maxWidth="xl">
            <Outlet />
            <Modal open={openCompanySelector} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
               <CompanySelectorModal2 state={openCompanySelector} setState={setOpenCompanySelector} />
            </Modal>
            <CreateProfileBeneficiary open={openProfileModal && !openCompanySelector} setOpen={setOpenProfileModal} />
            <BeneficiaryRequestsModal
               open={requestModal && !openCompanySelector && !openProfileModal}
               setOpen={setRequestModal}
            />
         </Container>
      </Box>
   );
};
