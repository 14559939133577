import { Folder } from "@mui/icons-material";
import { Box, Card, Grid, Stack, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";
import { useContext } from "react";

export const Files = ({ folder, i, body, onClick }) => {
   const navigate = useNavigate();
   const {
      companySelected,
      setSelectedGovernance,
      gobernanceBody,
      selectedGovernance,
      setSelectedSection,
      setDocumentSelected,
   } = useContext(GovernanceContext);

   const handleClick = () => {
      if (body) {
         setSelectedSection("Gestión documental");
         setSelectedGovernance(gobernanceBody.find((governance) => governance._id === folder.governance));
         navigate(`/gobierno-corporativo/${companySelected}/${folder.governance}/documentos/inicio`);
      } else {
         if (selectedGovernance && selectedGovernance._id !== "0") {
            setDocumentSelected(folder);
            navigate(
               `/gobierno-corporativo/${companySelected}/${selectedGovernance._id}/documentos/folder/${folder._id}`
            );
         } else {
            setDocumentSelected(folder);
            navigate(`/gobierno-corporativo/${companySelected}/mi-cuenta/archivos/folder/${folder._id}`);
         }
      }
      if (onClick) onClick(folder.name); //pasar el nombre de la carpeta
   };

   return (
      <Grid item xs={3} key={i}>
         <Card
            sx={{
               px: 1,
               py: 2,
               borderRadius: 2,
               cursor: "pointer",
               "&:hover": {
                  bgcolor: "#f9f9f9",
               },
            }}
            onClick={handleClick}
         >
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
               <Folder sx={{ color: "#F1B44C", fontSize: 35 }} />
            </Box>
            <Stack sx={{ p: 0.5 }}>
               <Typography fontWeight={600} noWrap>
                  {folder.name}
               </Typography>
            </Stack>
         </Card>
      </Grid>
   );
};
