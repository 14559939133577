import { Box, Button, Divider, Stack, Typography } from "@mui/material";
import { HeadCell, TableComponent } from "../../../TableComponent";
import { useContext, useEffect, useState } from "react";
import rolesByService from "../../../../const/rolesByService.json";
import { editUserRolesInGroup, getGroupUsers, getUserByUserId } from "../../../../lib/usersBEClient";
import { UserContext } from "../../../../context/userContext";
import { CustomModalComponent } from "../../../CustomModalComponent";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AddUserToDenounceGroup from "./AddUserToComplaintGroup";
import DrawerComponent from "../../../DrawerComponent";
import { SnackBarContext } from "../../../../context/snackBarContext";

export const DenounceChannelMembersTable = () => {
   const [userArray, setUserArray] = useState([]);
   const [isLoading, setIsLoading] = useState(false);
   const [openModal, setOpenModal] = useState(false);
   const [seed, setSeed] = useState(0);
   const [openDrawer, setOpenDrawer] = useState(false);
   const [selectedUser, setSelectedUser] = useState({});
   const { user } = useContext(UserContext);
   const { showSnackBar } = useContext(SnackBarContext);

   const usersColumns: HeadCell[] = [
      { field: "username", headerName: "Nombre", type: "string" },
      { field: "email", headerName: "Correo electrónico", type: "string" },
      { field: "phone", headerName: "Teléfono ", type: "string" },
      { field: "roles", headerName: "Rol", type: "popover" },
   ];

   const handleOpenModal = () => setOpenModal(true);

   const getGroupRoles = (roles: any[]) => {
      const rolesArray = roles
         .flatMap((role) => {
            if (!role.group) return;
            return role.roles.filter((r) => rolesByService.CD.includes(r.name)).map((r) => r.name);
         })
         .filter((v) => v);
      return Array.from(new Set(rolesArray));
   };

   useEffect(() => {
      const fetchUserData = async () => {
         setIsLoading(true);
         const groupId = user.group._id;
         const groupUsers = await getGroupUsers(groupId);
         const filteredGroupUsers = groupUsers.filter((userG) =>
            userG.role.some((role) => role.group === user.group._id)
         );
         const usersData = filteredGroupUsers
            .map((user) => ({
               _id: user._id,
               username: user.firstName + " " + user.lastName,
               email: user.email,
               phone: user.phoneNumber,
               roles: getGroupRoles(user.role),
            }))
            .filter((v) => v.roles?.length > 0);
         setUserArray(usersData);
         setIsLoading(false);
      };
      if (user.group?._id) fetchUserData();
   }, [user.group, userArray.length, seed]);

   const handleSelectedUser = (row) => {
      setSelectedUser(row);
      setOpenDrawer(true);
   };

   const handleDrawerSubmit = async (values) => {
      const foundUser = await getUserByUserId(values._id);
      const foundRoles = foundUser.user.role.find((role) => role.group).roles;
      const filteredCdRoles = foundRoles
         .filter((role) => !rolesByService.CD.includes(role.name))
         .map((role) => role._id);
      const data = await editUserRolesInGroup(
         user.group._id,
         {
            roles: filteredCdRoles,
            userId: values._id,
            roleName: values.roles,
         },
         "cd"
      );
      if (!data) return showSnackBar("Error al actualizar información", true);
      showSnackBar("El usuario fue modificado correctamente", false);
      setSeed((s) => s + 1);
      setOpenDrawer(false);
   };

   return (
      <Box
         sx={{
            justifyContent: "center",
            display: "flex",
            flexDirection: "column",
         }}
      >
         <Stack direction={"row"} justifyContent={"space-between"} alignItems={"center"} sx={{ p: 2, display: "flex" }}>
            <Typography sx={{ fontWeight: 700 }} alignSelf={"center"}>
               Usuarios del módulo
            </Typography>
            <Button variant="contained" onClick={handleOpenModal}>
               <AddCircleIcon sx={{ mr: 1 }} />
               Agregar usuario
            </Button>
         </Stack>
         <Divider sx={{ mt: 1, mb: 2, borderBottomWidth: 1 }} />
         <TableComponent
            defaultColumnToOrder="firstName"
            defaultOrder="asc"
            defaultRowsPerPage={3}
            rowsPerPageOptions={[5, 10, 20, 50]}
            headers={usersColumns}
            rows={userArray}
            onClick={(_e, r) => handleSelectedUser(r)}
            loader={isLoading}
            emptyDataText="No existen usuarios registrados en este módulo"
            disableBorders
         />
         <CustomModalComponent open={openModal} setOpen={setOpenModal} title="Agregar usuario">
            <AddUserToDenounceGroup
               setOpen={setOpenModal}
               existingUsersIds={userArray.map((user) => user._id)}
               setSeed={setSeed}
            />
         </CustomModalComponent>
         <DrawerComponent
            open={openDrawer}
            setOpen={setOpenDrawer}
            anchor="right"
            formObject={selectedUser}
            title="Información de usuario"
            labels={["", "Nombre de usuario", "Correo", "Teléfono", "Roles"]}
            disabled={[true, true, true, true, false]}
            onSubmit={handleDrawerSubmit}
            selectorOptions={rolesByService.CD}
         />
      </Box>
   );
};
