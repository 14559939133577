import UseCreateCharts from "../../../../../hooks/useCreateCharts";

const CommitmentEvaluationChart = ({ setSelectedCommitment, commiteInfo }) => {
   return UseCreateCharts({
      barThickness: 55,
      borderRadius: true,
      chartRef: undefined,
      fraction: true,
      type: "stack",
      colors: ["#162c44", "#FBBC05"],
      labels: Object.keys(commiteInfo).map((key) => key.replace("trimester", "T")),
      scores: [
         Object.keys(commiteInfo).map((key) => commiteInfo[key].filter((com) => com.status).length),
         Object.keys(commiteInfo).map((key) => commiteInfo[key].filter((com) => !com.status).length),
      ],
      labelNames: ["En tiempo y forma", "Fuera de tiempo y forma"],
      onClick: (e, i) => {
         if (i.length === 0) return;
         const key = Object.keys(commiteInfo)[i[0].index];
         setSelectedCommitment(commiteInfo[key]);
      },
   });
};

export default CommitmentEvaluationChart;
