import { useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { getUsersByIds } from "../../lib/usersBEClient";
import { Box, Typography } from "@mui/material";
import { Filters } from "../../components/Gobierno corporativo/MyFilesScreen/SubComponents/Filters";
import { PictureAsPdf } from "@mui/icons-material";
import { getDateAndTimeFromISODate } from "../../const/globalConst";
import { FilesContext } from "../../context/governanceContext/filesContext";
import dayjs from "dayjs";
import { getPersonalFilesById } from "../../lib/gobCorpBEClient";
import { MyFilesVerified } from "../../components/Gobierno corporativo/MyFilesScreen/MyFilesVerified";

type File = {
   name: string;
   verified?: boolean;
   updateFile?: boolean;
   isEmpty?: boolean;
   updatedAt?: string;
   size?: number;
   createdAt?: string;
   [key: string]: any;
};

type User = {
   firstName: string;
   lastName: string;
   _id: string;
};

type ProcessedFile = File & {
   displayName: JSX.Element;
   displayUpdatedAt: string;
   displaySize: string;
   displayType: string;
   disableActions: boolean;
};

export const getIconByType = (verified: boolean, updateFile?: boolean, isEmpty?: boolean) => {
   if (isEmpty) return <PictureAsPdf sx={{ color: "gray" }} />;
   if (verified === undefined) return <PictureAsPdf sx={{ color: "black" }} />;
   else if (updateFile === true) return <PictureAsPdf sx={{ color: "orange" }} />;
   else if (verified) return <PictureAsPdf sx={{ color: "green" }} />;
   else return <PictureAsPdf sx={{ color: "red" }} />;
};

const useGetFiles = () => {
   const { personalFilesId } = useParams<{ personalFilesId: string }>();
   const [isLoading, setIsLoading] = useState(true);
   const [files, setFiles] = useState<File[]>([]);
   const [user, setUser] = useState<User | null>(null);
   const [reload, setReload] = useState<any>(null);
   const { startDate, endDate, confirmModal } = useContext(FilesContext);
   const [seed, setSeed] = useState(0);
   const [filesWithoutFilter, setFilesWithoutFilter] = useState<any>(null);
   const digitalSign = files.find((file) => file.name.includes("firmaEscaneada"))?.name;

   const fileNames = useMemo(() => {
      return [
         "cv.pdf",
         "tituloProfesional.pdf",
         "cedulaProfesional.pdf",
         "historialCrediticio.pdf",
         "cartaRecomendacion1.pdf",
         "cartaRecomendacion2.pdf",
         "cartaRecomendacion3.pdf",
         "actaNacimiento.pdf",
         "credencialElector.pdf",
         digitalSign ? digitalSign : "firmaEscaneada.png",
         "comprobanteDomicilio.pdf",
         "buroLegal.pdf",
      ];
   }, [digitalSign]);

   useEffect(() => {
      const fetchFiles = async () => {
         try {
            setIsLoading(true);
            const filesData = await getPersonalFilesById(personalFilesId);
            if (!filesData || !filesData.user) {
               throw new Error("Invalid filesData structure");
            }
            const usersNames = await getUsersByIds(filesData.user);
            const [userName] = usersNames;
            setUser(userName);
            setFilesWithoutFilter(filesData);
         } catch (error) {
            console.error("Error fetching files or user data:", error);
         } finally {
            setIsLoading(false);
         }
      };
      fetchFiles();
   }, [personalFilesId, reload, seed]);

   useEffect(() => {
      if (!filesWithoutFilter) return;
      function filterByFiles(object: any) {
         const files: { [key: string]: File } = {};
         for (const key in object) {
            if (object[key] && typeof object[key] === "object" && object[key].hasOwnProperty("name")) {
               files[key] = object[key];
            }
         }
         return files;
      }
      const filesFiltered = filterByFiles(filesWithoutFilter);
      const arrayFiles = Object.values(filesFiltered);
      const arrayFilesNames = arrayFiles.map((file: any) => file.name);
      if (filesWithoutFilter.isShareholder) fileNames.push("tituloAccionario.pdf");
      const missingFiles = fileNames
         .filter((fileName) => !arrayFilesNames.includes(fileName))
         .map((fileName) => ({ name: fileName, isEmpty: true }));
      const arrayFilesWithMissingFiles = arrayFiles.concat(missingFiles);
      setFiles(arrayFilesWithMissingFiles);
      setIsLoading(false);
   }, [filesWithoutFilter, fileNames]);

   const filteredData = useMemo(() => {
      const data: ProcessedFile[] = [];
      for (const iterator of files) {
         const isPending = iterator.isEmpty;
         data.push({
            ...iterator,
            displayName: (
               <Box sx={{ display: "flex", alignItems: "center", columnGap: 2 }}>
                  {getIconByType(iterator.verified!, iterator.updateFile, iterator.isEmpty)}
                  <Typography variant="subtitle2">{iterator.name}</Typography>
               </Box>
            ),
            originalName: iterator.name.split(".")[0],
            displayUpdatedAt: isPending ? "Archivo pendiente de subir" : getDateAndTimeFromISODate(iterator.updatedAt!),
            displaySize: isPending ? "---" : (iterator.size! / 1024).toFixed(2) + " Kb",
            displayType: isPending ? "---" : iterator.name.split(".")[1].toUpperCase(),
            disableActions: isPending,
            ownerId: user._id,
         });
      }
      return data;
   }, [files, user]);

   const processedData = useMemo(() => {
      if (confirmModal && startDate !== null && endDate !== null) {
         const filtered = filteredData.filter(
            (file) => dayjs(new Date(file.createdAt!)) >= startDate && dayjs(new Date(file.createdAt!)) <= endDate
         );
         return filtered;
      }
      return filteredData;
   }, [filteredData, confirmModal, startDate, endDate]);

   const verifiedFiles = useMemo(() => processedData.filter((file) => file.verified), [processedData]);
   const unverifiedFiles = useMemo(() => processedData.filter((file) => !file.verified), [processedData]);
   return { isLoading, processedData, setReload, reload, setSeed, verifiedFiles, unverifiedFiles };
};

export default useGetFiles;

export const FilesVerificationScreen = () => {
   const { isLoading, unverifiedFiles, setReload, reload, setSeed } = useGetFiles();
   return (
      <>
         <Filters />
         <Box sx={{ py: 1 }}>
            <MyFilesVerified
               files={unverifiedFiles.map((file) => ({
                  ...file,
                  name: file.displayName,
                  updatedAt: file.displayUpdatedAt,
                  size: file.displaySize,
                  type: file.displayType,
               }))}
               isLoading={isLoading}
               setReload={setReload}
               reload={reload}
               setSeed={setSeed}
            />
         </Box>
      </>
   );
};

export const FilesVerifiedScreen = () => {
   const { isLoading, verifiedFiles, setReload, reload, setSeed } = useGetFiles();
   return (
      <>
         <Filters />
         <Box sx={{ py: 1 }}>
            <MyFilesVerified
               files={verifiedFiles.map((file) => ({
                  ...file,
                  name: file.displayName,
                  updatedAt: file.displayUpdatedAt,
                  size: file.displaySize,
                  type: file.displayType,
               }))}
               isLoading={isLoading}
               setReload={setReload}
               reload={reload}
               setSeed={setSeed}
            />
         </Box>
      </>
   );
};
