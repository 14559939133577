import { Close } from "@mui/icons-material";
import {
   Box,
   Button,
   FormControl,
   FormHelperText,
   IconButton,
   InputBase,
   List,
   ListItem,
   Typography,
} from "@mui/material";
import { useField } from "formik";

export const ListOfTexFields = ({ listOfData, setListOfData, inputProps }) => {
   const [_field, meta] = useField(inputProps);
   const handleChange = (e, index: number) => {
      let newValue = e.target.value;
      let newList = [...listOfData];
      newList[index] = newValue;
      setListOfData(inputProps.id, newList);
   };

   const handleDelete = (i: number) => {
      let newList = listOfData.filter((_data: string, index: number) => index !== i);
      setListOfData(inputProps.id, newList);
   };

   const renderInputs = () => {
      return listOfData.map((data: string, i: number) => {
         return (
            <ListItem disablePadding sx={{ display: "flex", my: 1, columnGap: 2 }}>
               <Typography fontWeight={600}>{i + 1}</Typography>
               <InputBase
                  value={data}
                  onChange={(e) => handleChange(e, i)}
                  fullWidth
                  size="small"
                  sx={{ fontSize: 15 }}
               />
               {listOfData.length !== 1 ? (
                  <IconButton
                     sx={{ p: 0 }}
                     onClick={() => {
                        handleDelete(i);
                     }}
                  >
                     <Close sx={{ fontSize: 16, color: "red" }} />
                  </IconButton>
               ) : null}
            </ListItem>
         );
      });
   };

   const handleAddInput = () => {
      setListOfData(inputProps.id, [...listOfData, ""]);
   };

   return (
      <>
         <FormControl fullWidth>
            <Box
               sx={{
                  bgcolor: "#F6F7FB",
                  p: 1,
                  borderRadius: 2,
                  border: meta.error && meta.touched ? 1 : 0,
                  borderColor: meta.error && meta.touched ? "red" : "transparent",
               }}
            >
               <List sx={{ maxHeight: 150, overflowY: "auto" }} disablePadding>
                  {renderInputs()}
               </List>
               <Box pt={1}>
                  <Button onClick={handleAddInput} variant="outlined" size="small">
                     Agregar linea
                  </Button>
               </Box>
            </Box>

            <FormHelperText error={meta.error && meta.touched ? true : false}>
               {meta.error && meta.touched ? meta.error : null}
            </FormHelperText>
         </FormControl>
      </>
   );
};
