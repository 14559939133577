import { Box } from "@mui/system";
import { NotesContainer } from "../../components/Gobierno corporativo/NotesScreen/NotesContainer";

export const MyNotesScreen = () => {
   return (
      <Box sx={{ width: "100%" }}>
         <NotesContainer />
      </Box>
   );
};
