export const style = {
   position: "absolute" as "absolute",
   top: "50%",
   left: "50%",
   transform: "translate(-50%, -50%)",
   width: 700,
   bgcolor: "background.paper",
   boxShadow: 24,
   borderRadius: 2,
};

export const mainCharges = [
   "Usuario de implementacion",
   "Coordinador de gobierno corporativo",
   "Presidente del consejo de administración",
   "Presidente de comité de apoyo",
   "Secretario del consejo de administración",
   "Presidente de comité directivo",
   "Secretario de comité de apoyo",
   "Secretario de comité directivo",
   "Vicepresidente del consejo de administración",
   "Vicepresidente de comité de apoyo",
   "Vicepresidente de comité directivo",
];
