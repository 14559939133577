import { Box, Chip, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useField } from "formik";

interface CharacterSelectComponentProps {
   label: string;
   setCharacters: Function;
   id: string;
   name: string;
}

export const CharacterSelectComponent = (props: CharacterSelectComponentProps) => {
   const [field, _meta] = useField(props);

   const characterTypes = ["Patrimonial", "Relacionado", "Independiente"];

   const allowedCombinations = [
      ["Patrimonial"],
      ["Patrimonial", "Relacionado"],
      ["Patrimonial", "Independiente"],
      ["Relacionado"],
      ["Independiente"],
   ];

   const isValidCombination = (selectedOptions) => {
      for (const combination of allowedCombinations) {
         if (
            selectedOptions.length === combination.length &&
            selectedOptions.every((option) => combination.includes(option))
         ) {
            return true;
         }
      }
      return false;
   };

   return (
      <>
         <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <Typography variant="body2" fontWeight={600} sx={{ ml: 1 }}>
               {props.label}
            </Typography>
            <FormControl sx={{ m: 1 }}>
               <Select
                  {...field}
                  id={props.id}
                  name={props.name}
                  multiple
                  size="small"
                  fullWidth
                  onChange={(e) => {
                     const selectedValue = e.target.value;
                     if (isValidCombination(selectedValue)) {
                        props.setCharacters(selectedValue);
                     }
                  }}
                  renderValue={(c: any) => (
                     <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                        {c.map((c, i) => (
                           <Chip key={i} label={c} />
                        ))}
                     </Box>
                  )}
               >
                  {characterTypes.map((c, i) => (
                     <MenuItem key={i} value={c}>
                        {c}
                     </MenuItem>
                  ))}
               </Select>
            </FormControl>
         </Box>
      </>
   );
};
