import { Box, Divider, IconButton, Typography } from "@mui/material";
import { useContext, useMemo } from "react";
import { ExternalGovernanceSessionContext } from "../../../context/governanceContext/externalSessionContext";
import { SessionMemberExternal } from "./SessionMemberExternal";
import { Circle, Close } from "@mui/icons-material";

export const GetSessionMembers = () => {
   const { membersWithCharge, session, usersOnline } = useContext(ExternalGovernanceSessionContext);
   let interUsers = useMemo(() => membersWithCharge.filter((member) => member.firstName), [membersWithCharge]);

   const usersWithStatus = useMemo(() => {
      let memberMap = {};
      session.usersRegistry.forEach((member) => {
         memberMap[member.user] = member.status;
         memberMap[member.user] = member.status;
      });

      const userWithStatusNotInOrder = membersWithCharge.map((member) => {
         return {
            ...member,
            status: memberMap[member._id] ? memberMap[member._id] : member.status,
            profilePic: session.usersRegistry.find((user) => user.user === member._id)?.picture,
         };
      });

      return userWithStatusNotInOrder.sort((a, b) => {
         const statusA = a.status.toUpperCase();
         const statusB = b.status.toUpperCase();
         if (statusA < statusB) return -1;
         if (statusA > statusB) return 1;
         return 0;
      });
   }, [membersWithCharge]);

   const attendedNumber = useMemo(() => {
      const attendedList = session.usersRegistry.filter((user) =>
         interUsers.some((userIntern) => userIntern._id === user.user && usersOnline.includes(userIntern._id))
      );
      const attendedListExternals = session.externs.filter((extern) => usersOnline.includes(extern.user));
      return attendedList.length + attendedListExternals.length;
   }, [interUsers, session, usersOnline]);

   return { usersWithStatus, attendedNumber };
};

export const SessionMembersExternal = (props: { online?: boolean; handleCloseProp?: Function }) => {
   const { session, externalMemberUsers, usersOnline } = useContext(ExternalGovernanceSessionContext);
   const { usersWithStatus, attendedNumber } = GetSessionMembers();

   return (
      <>
         {/* //MOBILE */}
         <Box sx={{ display: { xs: "block", lg: "none" }, bgcolor: "white", borderRadius: 5 }}>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
               <Box
                  sx={{
                     display: "flex",
                     alignItems: "center",
                     p: 2,
                     columnGap: 2,
                  }}
               >
                  <Typography sx={{ fontWeight: 600, fontSize: 18, color: "#7D8592" }}>
                     {props.online ? "Asistentes" : "Ausentes"}
                  </Typography>
                  <Circle sx={{ color: props.online ? "#4CAF50" : "#E81919", fontSize: 16 }} />
               </Box>
               <Box sx={{ alignSelf: "center", pr: 2 }}>
                  <IconButton onClick={() => props.handleCloseProp()}>
                     <Close sx={{ color: "#162c4470" }} />
                  </IconButton>
               </Box>
            </Box>

            <Divider sx={{ color: "#EBEBEB", border: 1 }} />
            <Box
               sx={{
                  height: "60vh",
                  overflowY: "auto",
                  "::-webkit-scrollbar": {
                     display: "none",
                  },
               }}
            >
               {usersWithStatus &&
                  usersWithStatus
                     .concat(externalMemberUsers as any)
                     .filter((user) =>
                        props.online
                           ? usersOnline.includes(user.user || user._id)
                           : !usersOnline.includes(user.user || user._id)
                     )
                     .map((member, index) => <SessionMemberExternal key={index} member={member} />)}
            </Box>
         </Box>

         {/* //COMPUTER */}
         <Box sx={{ display: { xs: "none", lg: "block" } }}>
            <Box
               sx={{
                  bgcolor: "#F8F8F9",
                  border: 1,
                  borderColor: "#E0E0E0",
                  p: 2,
               }}
            >
               <Box sx={{ display: "flex", justifyContent: "space-between", alignItem: "center" }}>
                  <Typography sx={{ fontWeight: 600 }}>Invitados</Typography>
               </Box>
               <Box sx={{ display: "flex", gap: 2 }}>
                  <Typography sx={{ fontSize: 12, color: "#64748B" }}>{attendedNumber} confirmados</Typography>
                  <Typography sx={{ fontSize: 12, color: "#64748B" }}>
                     {session.usersRegistry.length + session.externs.length - attendedNumber} sin confirmar
                  </Typography>
               </Box>
            </Box>
            <Box
               sx={{
                  bgcolor: "white",
                  border: 0.65,
                  borderTop: 0,
                  borderColor: "#CBD5E0",
                  height: 800,
                  overflowY: "auto",
               }}
            >
               {usersWithStatus &&
                  usersWithStatus
                     .concat(externalMemberUsers as any)
                     .map((member, index) => <SessionMemberExternal key={index} member={member} />)}
            </Box>
         </Box>
      </>
   );
};
