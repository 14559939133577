import { Box, Tab, Tabs } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { HeadCell, TableComponent } from "../TableComponent";
import { getDateFromISODate } from "../../const/globalConst";
import { UserContext } from "../../context/userContext";
import { getBranchesByCompanyId } from "../../lib/usersBEClient";

const branchesHeaders: HeadCell[] = [
   { field: "name", headerName: "Nombre", type: "string" },
   { field: "city", headerName: "Ciudad", type: "string" },
   { field: "state", headerName: "Estado", type: "string" },
   { field: "address", headerName: "Dirección", type: "string" },
   { field: "phoneNumber", headerName: "Número celular", type: "string" },
   { field: "createdAt", headerName: "Fecha de creación", type: "string" },
];

export const CompanyBranchesTable = () => {
   const [isLoading, setIsLoading] = useState(true);
   const [branchesData, setBranchesData] = useState<any[]>([]);
   const { companySelected } = useContext(UserContext);

   useEffect(() => {
      const getAllInfo = async () => {
         setIsLoading(true);
         const branches = await getBranchesByCompanyId(companySelected._id);
         setBranchesData(branches.data);
         setIsLoading(false);
      };
      if (companySelected) getAllInfo();
      // eslint-disable-next-line
   }, [companySelected]);

   const rows =
      !isLoading &&
      branchesData.map((row) => ({
         name: row.name,
         city: row.city,
         state: row.state,
         address: row.address + ", " + row.zipCode,
         phoneNumber: row.phoneNumber,
         createdAt: getDateFromISODate(row.createdAt),
      }));

   return (
      branchesData.length > 0 && (
         <Box sx={{ bgcolor: "white", boxShadow: 2, mt: 2, borderRadius: 1 }}>
            <Tabs value={0}>
               <Tab sx={{ ml: 2, fontSize: 14, fontWeight: 600 }} label="Sucursales" />
            </Tabs>
            <TableComponent
               defaultColumnToOrder="name"
               defaultOrder="asc"
               defaultRowsPerPage={3}
               headers={branchesHeaders}
               loader={isLoading}
               rows={rows}
               rowsPerPageOptions={[3, 5, 10]}
               onClick={() => {}}
               emptyDataText="Esta empresa aún no tiene sucursales"
               disableBorders
            />
         </Box>
      )
   );
};
