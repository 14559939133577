import { useRef } from "react";
import { useGetSessionsInfo } from "../../../../../hooks/gob-corp/governmentBody/useGetSessionsAttendanceInfo";
import UseCreateCharts from "../../../../../hooks/useCreateCharts";

const SessionInfoChart = () => {
   const chartRef = useRef(null);
   const calendarInfo = useGetSessionsInfo();
   const scores = [
      Object.keys(calendarInfo).map(
         (trimester) => calendarInfo[trimester].filter((session) => session.completed).length
      ),
      Object.keys(calendarInfo).map((trimester) => calendarInfo[trimester].length),
   ];
   return UseCreateCharts({
      type: "line",
      xTitle: "Periodos anuales",
      yTitle: "Cantidad de sesiones",
      chartRef: chartRef,
      intersection: true,
      tooltipColorBox: false,
      colors: ["#152C44", "#5E77F9"],
      labels: Object.keys(calendarInfo).map((trimester) => trimester.replace("trimester", "Trimestre ")),
      labelNames: ["Sesiones realizadas", "Total de sesiones convocadas"],
      scores: scores,
      tension: 0.3,
      pointRadius: 5,
      pointColor: true,
   });
};

export default SessionInfoChart;
