import {
   Cancel,
   CheckCircle,
   FiberManualRecord,
   GroupAddOutlined,
   Launch,
   LocalOffer,
   LocationOnOutlined,
   PeopleAltOutlined,
} from "@mui/icons-material";
import { Box, Grid, Stack, Typography } from "@mui/material";
import { useContext, useEffect, useState } from "react";
import { getAffairsByIdsSession } from "../../../../lib/gobCorpBEClient";
import { IEventsS } from "../SchedulerComponent";
import LinkIcon from "@mui/icons-material/Link";
import { GovernanceContext } from "../../../../context/governanceContext/governanceContext";

interface IPropsEventViewer {
   event: IEventsS;
   handleOpenModal?: (event: IEventsS, type: number) => void;
   modal?: boolean;
   users?: any[];
   handleClose?: (event: IEventsS, type: number) => void;
}

const useFetchAffairs = (affairs, assembly) => {
   const [affairsFound, setAffairsFound] = useState([]);
   const [isLoading, setIsLoading] = useState(true);
   useEffect(() => {
      const fetchAffairs = async () => {
         setIsLoading(true);
         const response = await getAffairsByIdsSession(affairs);
         setAffairsFound(response);
         setIsLoading(false);
      };
      if (assembly && affairs.length > 0) {
         fetchAffairs();
      }
   }, []);
   return { affairsFound, isLoading };
};

export const ShortEventViewer = (props: IPropsEventViewer) => {
   const { consultiveGroupCompanies } = useContext(GovernanceContext);
   const { affairsFound, isLoading } = useFetchAffairs(
      props.event.order ? props.event.order.map((affair: any) => affair.affair) : [],
      props.event.assembly
   );
   const hasUsersRegistry = props.event.usersRegistry && props.event.usersRegistry.length > 0;
   const users = hasUsersRegistry ? props.event.usersRegistry.map((user) => user.user) : props.event.users;

   return (
      <Stack spacing={props.modal ? 1.5 : 0.5}>
         <Typography fontSize={20} fontWeight={600}>{`${props.event?.billCode ?? ""} ${props.event.title}`}</Typography>
         <Box sx={{ display: "flex", columnGap: 1, justifyContent: "space-between" }}>
            <Box
               sx={{
                  display: "flex",
                  columnGap: 1,
                  alignItems: "center",
               }}
            >
               <Typography fontSize={13}>
                  {props.event.start.toLocaleDateString("es-MX", {
                     month: "long",
                     day: "numeric",
                     year: "numeric",
                  })}
               </Typography>
               <Typography fontSize={13}>
                  {props.event.start.toLocaleTimeString("es-MX", {
                     timeStyle: "short",
                     hour12: true,
                  })}
               </Typography>
               -
               <Typography fontSize={13}>
                  {props.event.end.toLocaleTimeString("es-MX", {
                     timeStyle: "short",
                     hour12: true,
                  })}
               </Typography>
            </Box>
         </Box>
         {props.event.location && (
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
               <LocationOnOutlined />
               <Typography fontSize={13}>{props.event.location}</Typography>
            </Box>
         )}
         {props.event.provider && (
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
               <LinkIcon />
               <Typography fontSize={13}>{props.event.provider}</Typography>
            </Box>
         )}
         {props.event.meetingURL && (
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
               <Launch />
               <Typography fontSize={13}>{props.event.meetingURL}</Typography>
            </Box>
         )}
         {props.event.order && props.modal && (
            <Stack spacing={1}>
               <Typography fontWeight={600} fontSize={13}>
                  Orden del día
               </Typography>
               <Box>
                  <Grid container spacing={0.2}>
                     <Grid item xs={props.event.group ? 4 : 5}>
                        <Typography
                           fontSize={13}
                           sx={{
                              bgcolor: "#F0F0F0",
                              display: "flex",
                              justifyContent: "center",
                              p: 0.5,
                              borderRadius: 1,
                           }}
                        >
                           Asunto
                        </Typography>
                     </Grid>
                     <Grid item xs={props.event.group ? 5 : 7}>
                        <Typography
                           fontSize={13}
                           sx={{
                              bgcolor: "#F0F0F0",
                              display: "flex",
                              justifyContent: "center",
                              p: 0.5,
                              borderRadius: 1,
                           }}
                        >
                           Descripción
                        </Typography>
                     </Grid>
                     {props.event.group && (
                        <Grid item xs={3}>
                           <Typography
                              fontSize={13}
                              sx={{
                                 bgcolor: "#F0F0F0",
                                 display: "flex",
                                 justifyContent: "center",
                                 p: 0.5,
                                 borderRadius: 1,
                              }}
                           >
                              Empresa(s)
                           </Typography>
                        </Grid>
                     )}

                     {props.event.order.length > 0 && !isLoading && props.event.assembly
                        ? props.event.order.map((affair: any, i) => (
                             <>
                                <Grid item xs={5}>
                                   <Typography fontSize={13} sx={{ bgcolor: "#FAFAFA", p: 1, height: "100%" }}>
                                      {affairsFound.find((affairS) => affairS._id === affair.affair)["title"]}
                                   </Typography>
                                </Grid>
                                <Grid item xs={7}>
                                   <Typography fontSize={13} sx={{ bgcolor: "#FAFAFA", p: 1, height: "100%" }}>
                                      {affair.description}
                                   </Typography>
                                </Grid>
                             </>
                          ))
                        : props.event.order.map((affair: any, i) => (
                             <>
                                <Grid item xs={props.event.group ? 4 : 5} sx={{ bgcolor: "#FAFAFA" }}>
                                   <Typography fontSize={13} sx={{ p: 1, height: "100%" }}>
                                      {affair.affair}
                                   </Typography>
                                </Grid>
                                <Grid item xs={props.event.group ? 5 : 7} sx={{ bgcolor: "#FAFAFA" }}>
                                   <Typography fontSize={13} sx={{ p: 1, height: "100%" }}>
                                      {affair.description}
                                   </Typography>
                                </Grid>
                                {props.event.group && (
                                   <Grid item xs={3} sx={{ bgcolor: "#FAFAFA" }}>
                                      <Stack sx={{ p: 1, height: "100%", alignItems: "center" }}>
                                         {affair.companies.map((c) => {
                                            return (
                                               <Typography fontSize={13}>
                                                  {consultiveGroupCompanies.find((com) => com._id === c)?.person_details
                                                     ?.comercialName || ""}
                                               </Typography>
                                            );
                                         })}
                                      </Stack>
                                   </Grid>
                                )}
                             </>
                          ))}
                  </Grid>
               </Box>
            </Stack>
         )}

         {/* {props.event.files && props.event.files.length > 0 && props.modal && (
            <Stack spacing={1} sx={{ columnGap: 0.5 }}>
               <Typography fontSize={13} fontWeight={600}>
                  Archivos registrados
               </Typography>
               <Stack spacing={1} sx={{ pl: 4 }}>
                  {props.event.files.map((file, i) => (
                     <Box sx={{ display: "flex", columnGap: 1 }}>
                        <PictureAsPdf sx={{ color: "#162c4490" }} />
                        <Typography fontSize={13} key={`file-${file.fileName}`}>
                           {file.fileName.substring(file.fileName.indexOf("_") + 1)}
                        </Typography>
                     </Box>
                  ))}
               </Stack>
               <Box sx={{ display: "flex", columnGap: 1 }}>
                  <Description sx={{ color: "#162c4490" }} />
                  <Typography fontSize={13}>{"Minuta"}</Typography>
               </Box>
               <Box sx={{ display: "flex", columnGap: 1 }}>
                  <Description sx={{ color: "#162c4490" }} />
                  <Typography fontSize={13}>{"Convocatoria"}</Typography>
               </Box>
            </Stack>
         )} */}

         {users.length > 0 && props.modal && (
            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: 1,
               }}
            >
               <PeopleAltOutlined />
               <Box
                  sx={{
                     gap: 1,
                     display: "flex",
                     alignItems: "center",
                     flexWrap: "wrap",
                  }}
               >
                  {users.map((userId) => {
                     const userFound = props.users && props.users.find((user) => user._id === userId);
                     const userFromRegistry =
                        hasUsersRegistry && props.event.usersRegistry.find((u) => u.user === userFound._id);
                     const status = userFromRegistry?.status;
                     return (
                        <Box
                           sx={{
                              bgcolor: "#F3F3F3",
                              px: 1,
                              borderRadius: 1,
                              display: "flex",
                              alignItems: "center",
                              columnGap: 1,
                           }}
                        >
                           {userFromRegistry &&
                              (status === "APPROVED" ? (
                                 <CheckCircle sx={{ color: "green", fontSize: 16 }} />
                              ) : status === "CANCELLED" ? (
                                 <Cancel sx={{ color: "red", fontSize: 16 }} />
                              ) : null)}
                           <Typography fontSize={13}>
                              {userFound && `${userFound.firstName} ${userFound.lastName}`}
                           </Typography>
                        </Box>
                     );
                  })}
               </Box>
            </Box>
         )}
         {props.event.externs?.length > 0 && props.modal && (
            <Box
               sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: 1,
               }}
            >
               <GroupAddOutlined />
               <Box
                  sx={{
                     gap: 1,
                     display: "flex",
                     alignItems: "center",
                     flexWrap: "wrap",
                  }}
               >
                  {props.event.externs.map((extern: any) => {
                     return (
                        <Box
                           sx={{
                              bgcolor: "#F3F3F3",
                              px: 1,
                              borderRadius: 1,
                              display: "flex",
                              alignItems: "center",
                              columnGap: 1,
                           }}
                        >
                           {extern?.status === "APPROVED" ? (
                              <CheckCircle sx={{ color: "green", fontSize: 16 }} />
                           ) : extern?.status === "CANCELLED" ? (
                              <Cancel sx={{ color: "red", fontSize: 16 }} />
                           ) : null}
                           <Typography fontSize={13}>{extern?.email || extern}</Typography>
                        </Box>
                     );
                  })}
               </Box>
            </Box>
         )}

         {props.event.tags && props.event.tags.length > 0 && (
            <Box sx={{ display: "flex", alignItems: "center", columnGap: 1 }}>
               <LocalOffer />
               {props.event.tags.map((tags) => (
                  <Box
                     key={tags.title}
                     sx={{
                        bgcolor: "#F3F3F3",
                        borderRadius: 1,
                        px: 0.5,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        columnGap: 0.5,
                     }}
                  >
                     <FiberManualRecord sx={{ fontSize: 10, color: tags.color }} />
                     <Typography fontSize={13}>{tags.title}</Typography>
                  </Box>
               ))}
            </Box>
         )}
      </Stack>
   );
};
